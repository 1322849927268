import { useEffect, useState } from "react";
import PropTypes from "prop-types";
const pageLimitOptions = [10, 25, 50, 100];
const Pagination = (props) => {
  Pagination.propTypes = {
    total: PropTypes.number,
    limit: PropTypes.number !== "NaN" ? PropTypes.number : "",
    currentPage: PropTypes.any,
    updateLimit: PropTypes.func,
    updatePage: PropTypes.func,
    from: PropTypes.number,
    to: PropTypes.number,
  };

  const [pages, setPages] = useState([1]);

  useEffect(() => {
    if (props.total) {
      let min = props.currentPage - 2;
      let max = Math.ceil(props.total / props.limit);
      if (min <= 0) {
        min = 1;
      }
      if (props.currentPage < max - 2) {
        max = props.currentPage + 2;
      }
      let page = [];
      for (let i = min; i <= max; i++) {
        page.push(i);
      }
      setPages(page);
    }
  }, [props.total, props.limit, props.currentPage]);

  return (
    <div className="table-pagination">
      {props?.total !== 0 &&
        props?.total !== undefined &&
        props?.total !== null && (
          <>
            <div className="limiter">
              Showing{" "}
              <select
                onChange={(element) => props.updateLimit(element)}
                defaultValue={props.limit !== "NaN" ? props.limit : 0}
              >
                {pageLimitOptions.map((opt) => {
                  return <option key={opt}>{opt}</option>;
                })}
              </select>{" "}
              Records per page
              {props.from &&
                props.to &&
                props.total &&
                ` | Showing ${props.from} to ${props.to} from ${props.total} Records`}
            </div>

            <div className="pager">
              {props.currentPage !== 1 ? (
                <>
                  <button onClick={() => props.updatePage(1)}>
                    <i className="fa fa-angle-double-left"></i> First
                  </button>
                  <button
                    onClick={() => props.updatePage(props.currentPage - 1)}
                  >
                    <i className="fa fa-angle-left"></i> Prev
                  </button>
                </>
              ) : null}
              <ul className="pages">
                {pages.map((page, index) => {
                  return (
                    <li
                      onClick={() => props.updatePage(page)}
                      key={"apge" + index}
                      className={page === props.currentPage ? "active" : ""}
                    >
                      <button>{pages?.length === 1 ? "" : page}</button>
                    </li>
                  );
                })}
              </ul>
              {props.currentPage !== Math.ceil(props.total / props.limit) &&
              props?.total > props?.to ? (
                <>
                  <button
                    onClick={() => props.updatePage(props.currentPage + 1)}
                  >
                    Next <i className="fa fa-angle-right"></i>
                  </button>
                  <button
                    onClick={() =>
                      props.updatePage(Math.ceil(props.total / props.limit))
                    }
                  >
                    Last <i className="fa fa-angle-double-right"></i>
                  </button>
                </>
              ) : null}
            </div>
          </>
        )}
    </div>
  );
};

export default Pagination;
