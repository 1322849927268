import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { API, get, imageDefaultPrefixPath } from "../../config";

const UserProfile = () => {
  const userName = localStorage.getItem("Name");
  const businessName = localStorage?.getItem("BusinessName");
  // const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");

  const logout = () => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(() => {
        var error = {};
        error["bt_error"] =
          "There is some issue with request, please try after some time.";
        this.setState({ errors: error });
      });
  };

  const params = useParams();
  const businessId = params?.business_id;
  const profilePhoto = useMemo(() => localStorage.getItem("ProfilePhoto"), []);
  return (
    <div className="userprofile">
      <li className="nav-item dropdown">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {!profilePhoto || profilePhoto === "null" ? (
              <span>
                {roleType === "Builder"
                  ? (businessName
                      ? businessName
                      : userName
                      ? userName
                      : ""
                    ).charAt(0)
                  : (userName ? userName : "").charAt(0)}
              </span>
            ) : (
              <img
                src={`${imageDefaultPrefixPath}${localStorage.getItem(
                  "ProfilePhoto"
                )}`}
                alt=""
              />
            )}{" "}
            {roleType === "Builder"
              ? businessName
                ? businessName
                : userName
                ? userName
                : ""
              : userName
              ? userName
              : ""}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item>Help</Dropdown.Item> */}
            <Dropdown.Item
              href="/user-settings?tab=myprofile"
              target={businessId && "_blank"}
            >
              My Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </div>
  );
};

export default UserProfile;
