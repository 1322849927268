import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, get } from "../../../../config";
import {
  formatAccountNumber,
  formatBSBNumber,
  formatName,
  formatNumber,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";

const ExpandPayments = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [paymentsList, setPaymentsList] = useState([]);

  const fetchPayments = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_PAYMENTS}/${id}`);
      setLoading(false);
      const paymentsList = data?.data;
      setPaymentsList(paymentsList);
      return data.data;
    } catch (error) {
      setLoading(false);
      setPaymentsList([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const paymentsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paymentsList[i]?.id,
        id: paymentsList[i]?.id,
        name: paymentsList[i]?.account_name
          ? paymentsList[i]?.account_name
          : paymentsList[i]?.contact?.account_name
          ? `${paymentsList[i]?.contact?.account_name} (${formatName(
              paymentsList[i]?.contact?.first_name,
              paymentsList[i]?.contact?.last_name
            )})`
          : paymentsList[i]?.retention_account?.account_name
          ? paymentsList[i]?.retention_account?.account_name + " (Retention)"
          : "",
        bank_name: paymentsList[i]?.bank?.abbreviation
          ? paymentsList[i]?.bank?.abbreviation
          : "-",
        bsb: paymentsList[i]?.bsb ? formatBSBNumber(paymentsList[i]?.bsb) : "",
        account_number: paymentsList[i]?.account_number
          ? formatAccountNumber(paymentsList[i]?.account_number)
          : "",

        amount: paymentsList[i]?.amount
          ? "$" + formatNumber(Number(paymentsList[i]?.amount))
          : `$0.00`,
        description: paymentsList[i]?.description
          ? paymentsList[i]?.description
          : "",
      });
    }
    return items;
  };
  const payments = paymentsGenerator(paymentsList?.length);

  const columns = [
    {
      dataField: "name",
      text: "Account Name",
      style: { width: "25%" },
    },
    {
      dataField: "bank_name",
      text: "Bank Name",
      style: { width: "15%" },
    },
    {
      dataField: "bsb",
      text: "BSB",
      style: { width: "15%" },
    },
    {
      dataField: "account_number",
      text: "Account Number",
      style: { width: "15%" },
    },
    {
      dataField: "amount",
      text: "Amount",
      style: { width: "20%" },
    },
    {
      dataField: "description",
      text: "Description",
      style: { width: "25%" },
    },
  ];

  return (
    <>
      <div className="content-details">
        {/* <div className="table-top-btn">
          <div className="search-section"></div>
          <div className="table-btn"></div>
        </div> */}
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            keyField="id"
            remote
            data={payments}
            columns={columns}
            // onTableChange={handleTableChange}
            // noDataIndication="No Data Found"
          />
        </div>
      </div>
    </>
  );
};

export default ExpandPayments;
