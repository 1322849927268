import { ErrorMessage, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../config";
import Spin from "../../../common/Spin";
import { AddContactForRecolocileTransactionSchema } from "../../../common/Validation";

const AddNewContactModal = ({
  addNewContact,
  handleAddNewContact,
  contactType,
  businessId,
  lastName,
  // setCreatedContactId,
  handleContactList,
  handleContactNameSelection,
  addContactItem,
  addContactItemID,
  bankTransactionData,
}) => {
  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState({
    first_name: "",
    last_name: lastName ? lastName : "",
    contact_type:
      contactType === "customer" ? 1 : contactType === "supplier" ? 2 : "",
  });

  const ContactTypeListing = [
    { label: "Customer", value: 1 },
    { label: "Supplier", value: 2 },
    { label: "Contractor", value: 3 },
  ];

  const contactTypeValue = ContactTypeListing?.find(
    (item) => item?.value === parseInt(contactData?.contact_type)
  );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.contact_type = value?.contact_type?.value;

    formData.append("builder_project_id", businessId);
    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("contact_type", value?.contact_type);

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.CONTACT_Add}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleContactList();
        handleContactNameSelection(
          data?.data?.id,
          addContactItemID,
          addContactItem,
          bankTransactionData
        );
        handleAddNewContact();
      }
      return data?.data;
    } catch (error) {
      setContactData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={addNewContact}
        onHide={handleAddNewContact}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add New Contact
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              first_name: contactData?.first_name,
              last_name: contactData?.last_name,
              contact_type: contactTypeValue,
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddContactForRecolocileTransactionSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className="entry-section"
                  >
                    <div className="form-box">
                      <div className="form-group half-width">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          value={values?.first_name ? values?.first_name : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="first_name" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Last Name
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          value={values?.last_name ? values?.last_name : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="last_name" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Contact Type
                        </label>
                        <Select
                          name="contact_type"
                          value={
                            typeof values?.contact_type === "object"
                              ? values?.contact_type
                              : typeof values?.contact_type === "number"
                              ? ContactTypeListing?.find(
                                  (item) =>
                                    item?.value ===
                                    parseInt(values?.contact_type)
                                )
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue(`contact_type`, e ? e : "");
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={ContactTypeListing}
                          placeholder="Select Contact Type"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="contact_type" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          handleAddNewContact();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewContactModal;
