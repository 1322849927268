import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = ({ message, children }) => {
  const tooltip = <Tooltip id="tooltip">{message}</Tooltip>;

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 500, hide: 200 }}
      overlay={tooltip}
      // trigger={["hover"]}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
