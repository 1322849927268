/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import {
  formatAccountNumber,
  formatBSBNumber,
  formatName,
  formatNumber,
  formatSubcontractorName,
  MaxLimit,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import {
  Button,
  FormControl,
  Form,
  Tooltip,
  OverlayTrigger,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenerateAbaApprovalModal from "./GenerateAbaApprovalModal";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import ExpandPayments from "../Payments/components/ExpandPayments";
// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import CalenderView from "../../common/CalenderView";
import NumberFormat from "react-number-format";
import ReactInputMask from "react-input-mask";
import ReactSelect from "react-select";
import { useBankList } from "../sub-contractors/components/SubContractorHelper";
import { DatePicker } from "rsuite";
import CustomTooltip from "../../common/CustomTooltip";

const ABA = ({ projectId, trust_type, projectData, ProjectReadOnly }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = useParams();
  const navigate = useNavigate();
  const trustType = params?.trust_type;
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const search = query.get("search");
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [approvalModal, setApprovalModal] = useState(false);
  const [ABAs, setABAs] = useState();
  const tableRef = useRef();
  const [showCalender, setShowCalender] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [abaDate, setABADate] = useState();

  const [generateModal, setGenerateModal] = useState(false);
  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [entryData, setEntryData] = useState();
  const [itemData, setItemData] = useState();

  const [transactionDate, setTransactionDate] = useState(today);

  const [errors, setErrors] = useState([
    {
      bsb: "",
      account_number: "",
      amount: "",
      description: "",
    },
  ]);

  const toggleGenerateModal = () => {
    setGenerateModal(!generateModal);
  };

  const toggleEditConfirmModal = () => {
    setEditConfirmModal(!editConfirmModal);
  };

  const toggleEditModal = () => {
    if (editModal) {
      setEntryData();
      setTransactionDate(today);
      setErrors([
        {
          amount: "",
          bsb: "",
          account_number: "",
          description: "",
        },
      ]);
    }
    setEditModal(!editModal);
  };
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [notSelectedRowIds, setNotSelectedRowIds] = useState([]);

  // *API Call for Fetching Generate .ABA File List - Start
  const preocessABAList = (data) => {
    return data.map((ele) => {
      const totalGrossAmount = (remittance, invoice_amount, disbursement) => {
        let total = 0;
        total =
          (remittance ? Number(parseFloat(remittance)) : 0.0) +
          (invoice_amount ? Number(parseFloat(invoice_amount)) : 0.0) +
          (disbursement ? Number(parseFloat(disbursement)) : 0.0);
        return total;
      };

      const totalFees = (remittance, invoice_amount) => {
        let total = 0;
        total =
          (remittance ? Number(parseFloat(remittance)) : 0.0) +
          (invoice_amount ? Number(parseFloat(invoice_amount)) : 0.0);
        return total;
      };

      return {
        ...ele,
        name: formatName(
          ele?.reconciliation?.customer?.first_name,
          ele?.reconciliation?.customer?.last_name
        ),
        account_name:
          ele?.reconciliation?.customer?.customer_bank?.account_name,
        account_number:
          ele?.reconciliation?.customer?.customer_bank?.account_number,
        bsb: ele?.reconciliation?.customer?.customer_bank?.bsb,
        gross_amount: totalGrossAmount(
          ele?.reconciliation?.remittance,
          ele?.reconciliation?.invoice_amount,
          ele?.reconciliation?.disbursement
        ),
        fees: totalFees(
          ele?.reconciliation?.invoice_amount,
          ele?.reconciliation?.disbursement
        ),
      };
    });
  };

  const fetchABA = useCallback(async () => {
    try {
      setLoading(true);
      tableRef.current.rowExpandContext.state.expanded = [];
      const { data } = await get(
        `${
          API.GET_ABA_LIST
        }?builder_project_id=${projectId}&trust_type=${trust_type}&page=${page}&limit=${limit}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&aba_file=1&search=${
          searchParam ? searchParam : ""
          // }&date=${abaDate ? moment(abaDate).format("DD-MM-YYYY") : ""}`
        }&date=${abaDate ? abaDate : ""}`
      );
      const ABAData = preocessABAList(data.data.data);
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      let idArray = [];
      for (let i = 0; i < ABAData?.length; i++) {
        if (abaDate) {
          if (abaDate) {
            idArray.push(ABAData[i]?.id);
          }
        }
      }
      setSelectedRowIds(idArray);
      setABAs(ABAData);

      const notSelected = [];
      for (let i = 0; i < ABAData?.length; i++) {
        if (!abaDate) {
          notSelected.push(ABAData[i]?.id);
        }
      }
      if (!abaDate) {
        setNotSelectedRowIds(notSelected);
      } else {
        setNotSelectedRowIds([]);
      }
    } catch (e) {
      setABAs([]);
      setLoading(false);
    }
  }, [
    limit,
    page,
    projectId,
    trust_type,
    searchParam,
    sortField,
    sortOrder,
    abaDate,
  ]);

  useEffect(() => {
    fetchABA();
  }, [fetchABA]);
  // *API Call for Fetching Generate .ABA File List - End

  // *API Call for Edit .ABA - Start

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleAmountChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = e.target.value
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? 0
          : "";
        // if (itemData?.total_amount) {
        //   if (
        //     Number((itemData?.total_amount).toString().replace(/,/g, "")) <
        //     Number(value)
        //   ) {
        //     errors[index].amount = "Enter amount less than total amount.";
        //   } else {
        //     errors[index].amount = "";
        //   }
        // } else {
        //   errors[index].amount = "Enter amount less than total amount.";
        // }
        return {
          ...obj,
          amount: value,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAmountBlur = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""))
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? obj?.payment_type !== 1
            ? 0
            : ""
          : "";

        // if (itemData?.total_amount) {
        //   if (
        //     Number((itemData?.total_amount).toString().replace(/,/g, "")) <
        //     Number(value)
        //   ) {
        //     errors[index].amount = "Enter amount less than total amount.";
        //   } else {
        //     errors[index].amount = "";
        //   }
        // } else {
        //   errors[index].amount = "Enter amount less than total amount.";
        // }
        return {
          ...obj,
          amount: value ? value : 0,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleBSBChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/-/g, ""));
        return {
          ...obj,
          bsb: value,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleBSBBlur = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/-/g, ""));
        return {
          ...obj,
          bsb: value ? padWithLeadingZeros(Number(value), 6) : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAccountNumberChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`);
        return {
          ...obj,
          account_number: value,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAccountNumberBlur = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`);
        return {
          ...obj,
          account_number: value ? padWithLeadingZeros(Number(value), 9) : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleDescription = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          description: e.target.value,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleBlurDescription = async (data, index, e) => {
    let regex = new RegExp(/^[^!@)(^%$<>][a-zA-Z\s\d.,#/&-]*$/);
    // const newArr = data.map((obj, i) => {
    //   if (i === index) {
    //     const value = Number(`${e.target.value}`);
    //     return {
    //       ...obj,
    //       account_number: value ? padWithLeadingZeros(Number(value), 9) : "",
    //     };
    //   }
    //   return obj;
    // });
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index] = "Enter valid Description.";
      } else {
        errors[index] = null;
      }
    } else {
      toast.error("Something went wrong.");
    }
  };
  // *API Call for Edit .ABA - End

  // *Generate .ABA File List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const abaListGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: ABAs[i]?.id,
        id: ABAs[i]?.id,
        transaction_date: ABAs[i]?.transaction_date
          ? ABAs[i]?.transaction_date
          : "",
        // name: ABAs[i]?.contact?.first_name
        //   ? ABAs[i]?.contact?.account_name ===
        //     formatName(
        //       ABAs[i]?.contact?.first_name,
        //       ABAs[i]?.contact?.last_name
        //     )
        //     ? formatName(
        //         ABAs[i]?.contact?.first_name,
        //         ABAs[i]?.contact?.last_name
        //       )
        //     : `${formatName(
        //         ABAs[i]?.contact?.first_name,
        //         ABAs[i]?.contact?.last_name
        //       )} (${ABAs[i]?.contact?.account_name})`
        //   : ABAs[i]?.contact?.business_name
        //   ? ABAs[i]?.contact?.account_name === ABAs[i]?.contact?.business_name
        //     ? ABAs[i]?.contact?.business_name
        //     : `${ABAs[i]?.contact?.business_name} (${ABAs[i]?.contact?.account_name})`
        //   : ABAs[i]?.contact?.account_name ===
        //     formatName(
        //       ABAs[i]?.contact?.first_name,
        //       ABAs[i]?.contact?.last_name
        //     )
        //   ? formatName(
        //       ABAs[i]?.contact?.first_name,
        //       ABAs[i]?.contact?.last_name
        //     )
        //   : `${formatName(
        //       ABAs[i]?.contact?.first_name,
        //       ABAs[i]?.contact?.last_name
        //     )} (${ABAs[i]?.contact?.account_name})`,
        name: formatSubcontractorName(ABAs[i]?.contact),
        bsb: ABAs[i]?.contact?.bsb
          ? formatBSBNumber(ABAs[i]?.contact?.bsb)
          : "-",
        account_number: ABAs[i]?.contact?.account_number
          ? formatAccountNumber(ABAs[i]?.contact?.account_number)
          : "-",

        total_amount: ABAs[i]?.total_amount
          ? "$" + formatNumber(Number(ABAs[i]?.total_amount))
          : `$0.00`,
        retention_amount: ABAs[i]?.retention_amount
          ? "$" + formatNumber(Number(ABAs[i]?.retention_amount))
          : `$0.00`,
        net_amount: ABAs[i]?.net_amount
          ? "$" + formatNumber(Number(ABAs[i]?.net_amount))
          : `$0.00`,
        note: ABAs[i]?.note ? ABAs[i]?.note : "",
        action: (
          <div style={{ textAlign: "center" }}>
            <span>
              {/* <FontAwesomeIcon
                icon={faUndo}
                size="1x"
                onClick={() => handleUndo(ABAs[i]?.id)}
              /> */}

              <CustomTooltip message={"Edit"}>
                <span>
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="1x"
                    onClick={() => handleEdit(ABAs[i])}
                  />
                </span>
              </CustomTooltip>
            </span>
          </div>
        ),
      });
    }
    return items;
  };

  const abaListData = abaListGenerator(ABAs?.length);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "transaction_date",
      text: "Payment Date",
      style: { width: "13%" },
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "bsb",
      text: "BSB",
    },
    {
      dataField: "account_number",
      text: "Account Number",
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
    },
    {
      dataField: "retention_amount",
      text: "Retention",
    },
    {
      dataField: "net_amount",
      text: "Net Amount",
    },
    {
      dataField: "note",
      text: "Note",
    },
    {
      text: "Action",
      dataField: "action",
      width: "5%",
      className: "centeralign",
    },
  ];

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const selectRow = {
    mode: "checkbox",
    // hideSelectAll: abaDate ? false : true,
    clickToSelect: false,
    selected: selectedRowIds,
    // nonSelectable: notSelectedRowIds,
    onSelectAll: (row, isSelect) => {
      // if (abaDate) {
      if (ABAs?.length > 0) {
        if (row) {
          const ids =
            isSelect &&
            isSelect?.length > 0 &&
            isSelect?.map((item) => {
              return item?.key;
            });
          setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
        } else {
          const removableData =
            isSelect &&
            isSelect?.length > 0 &&
            isSelect.map(function (data) {
              return data?.key;
            });
          setSelectedRowIds(
            selectedRowIds
              ? selectedRowIds.filter((item) => !removableData.includes(item))
              : []
          );
        }
      }
      // }
    },
    onSelect: (row, isSelect) => {
      // if (abaDate) {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.key]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.key));
      }
      // } else {
      //   if (isSelect) {
      //     setSelectedRowIds((keys) => [...keys, row?.key]);
      //     setABADate(moment(row?.transaction_date, "DD-MM-YYYY").format("DD-MM-YYYY"));
      //   }
      // }
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const expandRow = {
    renderer: (row) => (
      <>
        {" "}
        <ExpandPayments id={row?.id} />{" "}
      </>
    ),
    // onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    // expanded: id,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };
  // *Generate .ABA File List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  // *API Call for Generate selected .ABA File - Start
  const handleGenerateAba = async (type, fileNote) => {
    if (!selectedRowIds.length) {
      toast.error("Please select rows");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);
      formData.append("note", fileNote);
      if (selectedRowIds?.length > 0) {
        for (let i = 0; i < selectedRowIds?.length; i++) {
          formData.append(`aba_ids[${i}]`, selectedRowIds[i]);
        }
      }
      if (type === 1) {
        formData.append("type", type);
      }
      const { data } = await fileUpload(API.GENERATE_ABA, formData, {});
      setSelectedRowIds([]);
      setABADate();
      setApprovalModal(false);
      setGenerateModal(false);
      setLoading(false);
      toast.success(data.message);
      if (data) {
        fetchABA();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const generateFile = async () => {
    if (!selectedRowIds.length) {
      toast.error("Please select rows");
      return;
    }
    setApprovalModal(true);
  };

  const handleModalClose = () => {
    setApprovalModal(!approvalModal);
  };
  // *API Call for Generate selected .ABA File - End

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/aba?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }&tab=generate-aba&aba_file=1`,
      {
        replace: true,
      }
    );
  }, [
    navigate,
    limit,
    searchParam,
    page,
    sortField,
    sortOrder,
    projectId,
    trustType,
  ]);

  const [totalAmount, setTotalAmount] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalSelectedValue = (selectedRowData) => {
    let amount = 0;
    for (let k = 0; k < selectedRowData.length; k++) {
      amount =
        amount +
        ABAs?.find((item) => item?.id === selectedRowData[k])?.total_amount;
    }
    setTotalAmount(amount);
  };

  useEffect(() => {
    totalSelectedValue(selectedRowIds);
  }, [selectedRowIds, totalSelectedValue]);

  const tooltip = (
    <Tooltip id="tooltip">
      Please select a date and records to generate aba files.
    </Tooltip>
  );

  const toggleCalenderView = () => {
    setShowCalender(!showCalender);
  };

  // *API Call for Fetching ABA Transactions List - Start
  const fetchABATransactionsDetail = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await get(`${API.GET_PAYMENTS}/${id}`);
      if (data?.status === 200) {
        const expand = data?.data;
        let errorObj = [];
        for (let i = 0; i < data?.data?.length; i++) {
          data.data[i].payment_type = data?.data?.[i].retention_id ? 2 : 1;
          if (!data.data[i].bank_id && data?.data[i]?.contact?.is_pta === 3) {
            data.data[i].bank_id = "principal";
          }

          errorObj.push({
            bsb: "",
            account_number: "",
            amount: "",
            description: "",
          });
        }
        setErrors(errorObj);
        var TransactionDateParts =
          expand[0]?.payment?.transaction_date.split("-");
        var TransactionDate = new Date(
          +TransactionDateParts[2],
          TransactionDateParts[1] - 1,
          +TransactionDateParts[0]
        );
        setTransactionDate(TransactionDate);
        setEntryData(expand);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setEntryData();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, []);
  // *API Call for Fetching ABA Transactions List - End

  const handleEditToggle = () => {
    if (
      itemData?.total_amount !=
      Number(
        calculateAmount(entryData?.filter((item) => item?.payment_type === 1)) +
          calculateAmount(entryData?.filter((item) => item?.payment_type === 2))
      ).toFixed(2)
    ) {
      toggleEditConfirmModal();
    } else {
      handleEditABA();
    }
  };

  const handleEditABA = async () => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append(`trust_type`, trust_type);
    formData.append("_method", "put");
    // formData.append(
    //   `transaction_date`,
    //   transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""
    // );
    formData.append(
      `transaction_date`,
      transactionDate
        ? String(transactionDate)?.includes("-")
          ? transactionDate
          : moment(transactionDate).format("DD-MM-YYYY")
        : ""
    );

    entryData?.forEach((item, index) => {
      formData.append(
        `payment_details[${index}][account_name]`,
        item?.account_name
          ? item?.account_name
          : item?.retention_account?.account_name
          ? item?.retention_account?.account_name + " (Retention)"
          : ""
      );
      formData.append(
        `payment_details[${index}][bank_id]`,
        item?.bank_id
          ? item?.bank_id === "principal"
            ? ""
            : item?.bank_id
          : ""
      );
      formData.append(
        `payment_details[${index}][bsb]`,
        item?.bsb ? item?.bsb : ""
      );
      formData.append(
        `payment_details[${index}][description]`,
        item?.description ? item?.description : ""
      );
      formData.append(
        `payment_details[${index}][account_number]`,
        item?.account_number ? item?.account_number : ""
      );
      formData.append(`payment_details[${index}][amount]`, item?.amount);
      formData.append(
        `payment_details[${index}][payment_type]`,
        item?.payment_type ? item?.payment_type : ""
      );
      formData.append(
        `payment_details[${index}][id]`,
        item?.id ? item?.id : ""
      );
    });

    try {
      setLoading(true);
      const response = await fileUpload(
        `${API.GET_PAYMENTS}/${itemData?.id}`,
        formData,
        {}
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        setEditConfirmModal(false);
        fetchABA();
        toggleEditModal();
        setEntryData();
        setItemData();
      }
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const handleEdit = async (item) => {
    setItemData(item);
    if (editModal) {
      setEntryData();
      setItemData();
    } else {
      fetchABATransactionsDetail(item?.id);
    }
    toggleEditModal(!editModal);
  };

  const calculateAmount = (data) => {
    let sum = 0;
    for (let i = 0; i < data?.length; i++) {
      sum = sum + data[i]?.amount;
    }
    return sum ? Number(sum) : 0;
  };

  const checkDisable = () => {
    for (let checkIndex = 0; checkIndex < entryData?.length; checkIndex++) {
      if (
        !(
          entryData?.[checkIndex]?.account_name ||
          entryData?.[checkIndex]?.retention_account
        ) ||
        // !entryData?.[checkIndex]?.bank_id ||
        !entryData?.[checkIndex]?.bsb ||
        !entryData?.[checkIndex]?.description ||
        !entryData?.[checkIndex]?.account_number ||
        (!entryData?.[checkIndex]?.amount &&
          entryData?.[checkIndex]?.payment_type === 1)
        // ||
        // itemData?.total_amount !=
        //   Number(calculateAmount(
        //     entryData?.filter((item) => item?.payment_type === 1)
        //   ) +
        //     calculateAmount(
        //       entryData?.filter((item) => item?.payment_type === 2)
        //     )).toFixed(2)
      ) {
        return true;
      }
    }
    return false;
  };

  const AddRetention = () => {
    let retentionObj = {
      payment_type: 2,
      account_name: projectData?.rta_project_id
        ? projectData?.rta_project?.rta_contractor?.account_name
          ? projectData?.rta_project?.rta_contractor?.account_name
          : "Retention Trust Account"
        : projectData?.rta_contractor?.account_name
        ? projectData?.rta_contractor?.account_name
        : "Retention Trust Account",
      bsb: projectData?.rta_project_id
        ? projectData?.rta_project?.rta_contractor?.bsb
          ? projectData?.rta_project?.rta_contractor?.bsb
          : "000000"
        : projectData?.rta_contractor?.bsb
        ? projectData?.rta_contractor?.bsb
        : "000000",
      description:
        "To take up the payments of retentions to the retention trust account (retained amount)",
      account_number: projectData?.rta_project_id
        ? projectData?.rta_project?.rta_contractor?.account_number
          ? projectData?.rta_project?.rta_contractor?.account_number
          : "000000001"
        : projectData?.rta_contractor?.account_number
        ? projectData?.rta_contractor?.account_number
        : "000000001",
      amount: "",
      bank_id: projectData?.rta_project_id
        ? projectData?.rta_project?.rta_contractor?.bank_id
        : projectData?.rta_contractor?.bank_id
        ? projectData?.rta_contractor?.bank_id
        : "",
    };
    setEntryData([...entryData, retentionObj]);
    setErrors([
      ...errors,
      {
        account_name: "",
        bsb: "",
        description: "",
        bank_id: "",
        account_number: "",
        amount: "",
      },
    ]);
  };

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const bankListing = bankList
    ? [
        ...bankList,
        { label: "Don't have a Principal A/C", value: "principal" },
        { label: "Don't have a Retention Trust A/C", value: "" },
      ]
    : [];

  const handleBank = (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        if (e?.value === "principal" || e?.value === "") {
          return {
            ...obj,
            bank_id: e?.value,
            bsb: "000-000",
            account_number: "000000001",
          };
        } else {
          return {
            ...obj,
            bank_id: e?.value,
          };
        }
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAccountName = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          account_name: e.target.value,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  return (
    <>
      <div
        className="table-top-btn archived-transactions-date-range"
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <div className="date-clear-body">
            {/* <DatePicker
              name="transaction_date"
              value={abaDate ? abaDate : ""}
              placeholder="Select Date to Generate ABA"
              unclearbale={false}
              onChange={(e) => {
                setABADate(e);
                tableRef.current.selectionContext.selected = [];
                setSelectedRowIds([]);
              }}
            ></DatePicker> */}
            <DatePicker
              cleanable={true}
              onClean={() => {
                setABADate("");
                tableRef.current.selectionContext.selected = [];
                setSelectedRowIds([]);
              }}
              onChange={(e) => {
                setABADate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
                tableRef.current.selectionContext.selected = [];
                setSelectedRowIds([]);
              }}
              format="dd-MM-yyyy"
              value={
                abaDate
                  ? String(abaDate)?.includes("-")
                    ? new Date(
                        String(abaDate)?.split("-")[2],
                        String(abaDate)?.split("-")[1] - 1,
                        String(abaDate)?.split("-")[0]
                      )
                    : new Date(abaDate)
                  : null
              }
              className="rs-date-body"
              placeholder="Select Date to Generate ABA"
              placement="autoVerticalStart"
            />
            {/* <FontAwesomeIcon
              className="clear-button-selection"
              icon={faXmark}
              size="1x"
              onClick={() => {
                tableRef.current.selectionContext.selected = [];
                setSelectedRowIds([]);
                setABADate();
              }}
            /> */}
          </div>
          <div
            className="search-section"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Form>
              <FormControl
                onChange={handleSearchChange}
                type="text"
                value={searchParamData}
                placeholder="Search Generate ABA"
              />
              {searchParamData ? (
                <Button
                  className="btn-close"
                  onClick={() => {
                    setSearchParamData("");
                    setSearchParam("");
                  }}
                >
                  x
                </Button>
              ) : (
                <Button className="fa fa-search">Search</Button>
              )}
            </Form>
            <div className="aba-info">
              <DropdownButton className="info-icon">
                <Dropdown.Item>
                  <p>Please select a date and records to generate aba files.</p>
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              padding: "0px 12px",
              fontSize: "16px",
              height: "28px",
              lineHeight: "28px",
            }}
          >
            Selected Total:{" "}
            {totalAmount ? `$${formatNumber(totalAmount)}` : "$0.00"}
          </div>
          <div
            style={{
              padding: "0px 12px",
              fontSize: "16px",
              height: "28px",
              lineHeight: "28px",
              marginLeft: "15px",
            }}
          >
            Selected Record: {selectedRowIds.length}
          </div>
          {!ProjectReadOnly ? (
            selectedRowIds?.length === 0 ? (
              <OverlayTrigger
                placement="top"
                overlay={tooltip}
                trigger={["hover"]}
              >
                <Button
                  variant="primary"
                  onClick={generateFile}
                  style={{
                    padding: "10px 15px",
                    marginLeft: "15px",
                  }}
                  disabled={true}
                >
                  Generate .ABA
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                variant="primary"
                onClick={generateFile}
                style={{
                  padding: "10px 15px",
                  marginLeft: "15px",
                }}
              >
                Generate .ABA
              </Button>
            )
          ) : (
            <></>
          )}
          <Button
            style={{
              padding: "10px 15px",
              marginLeft: "15px",
            }}
            variant="primary"
            onClick={toggleCalenderView}
          >
            Calendar View
          </Button>
        </div>
      </div>
      <div className="cms-page audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="key"
          remote
          ref={tableRef}
          data={abaListData}
          columns={columns}
          selectRow={selectRow}
          expandRow={expandRow}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={Number(page)}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
      {editModal && (
        <Modal
          size="lg"
          show={editModal}
          onHide={() => {
            toggleEditModal();
          }}
          dialogClassName="review-popup small-review payment-edit-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Edit Payment Details</Modal.Header>
          <Modal.Body>
            <>
              {loading && <Spin />}
              <div
                className={"jounal-entry-card"}
                style={{
                  marginBottom: "0px",
                }}
              >
                <span className="journal-entry-status">
                  <div className="journal-entry">
                    <div className="payment-schedule-header-item">
                      <p>
                        <span className="required">*</span>Payment Date
                      </p>
                      {/* <DatePicker
                        name="transaction_date"
                        value={transactionDate ? transactionDate : ""}
                        onChange={(e) => {
                          setTransactionDate(e);
                        }}
                        // disabled={[[tomorrow, false]]}
                      /> */}
                      <DatePicker
                        cleanable={false}
                        name="transaction_date"
                        onChange={(e) =>
                          setTransactionDate(moment(e, "DD-MM-YYYY"))
                        }
                        format="dd-MM-yyyy"
                        value={
                          transactionDate
                            ? String(transactionDate)?.includes("-")
                              ? new Date(
                                  String(transactionDate)?.split("-")[2],
                                  String(transactionDate)?.split("-")[1] - 1,
                                  String(transactionDate)?.split("-")[0]
                                )
                              : new Date(transactionDate)
                            : null
                        }
                        className="rs-date-body"
                        placeholder="Select Date"
                        placement="autoVerticalStart"
                        disabledDate={(date) => moment(date).isAfter(moment())}
                      />
                    </div>
                    {/* <p>
                      Total Amount
                      <i>
                        {itemData?.total_amount
                          ? formatNumber(Number(itemData?.total_amount))
                          : 0.0}
                      </i>
                    </p> */}
                    {Number(itemData?.total_amount) !=
                    Number(
                      calculateAmount(
                        entryData?.filter((item) => item?.payment_type === 1)
                      ) +
                        calculateAmount(
                          entryData?.filter((item) => item?.payment_type === 2)
                        )
                    ).toFixed(2) ? (
                      <>
                        <p>
                          Old Total Amount
                          <i>
                            {itemData?.total_amount
                              ? formatNumber(Number(itemData?.total_amount))
                              : 0.0}
                          </i>
                        </p>
                        <p>
                          New Total Amount
                          <i>
                            {itemData?.total_amount
                              ? // ? formatNumber(Number(itemData?.total_amount))
                                formatNumber(
                                  Number(
                                    calculateAmount(
                                      entryData?.filter(
                                        (item) => item?.payment_type === 1
                                      )
                                    ) +
                                      calculateAmount(
                                        entryData?.filter(
                                          (item) => item?.payment_type === 2
                                        )
                                      )
                                  )
                                )
                              : 0.0}
                          </i>
                        </p>
                      </>
                    ) : (
                      <p>
                        Total Amount
                        <i>
                          {itemData?.total_amount
                            ? formatNumber(Number(itemData?.total_amount))
                            : 0.0}
                        </i>
                      </p>
                    )}
                    <p>
                      Net Amount
                      <i>
                        {entryData?.filter((item) => item?.payment_type === 1)
                          ?.length > 0
                          ? formatNumber(
                              calculateAmount(
                                entryData?.filter(
                                  (item) => item?.payment_type === 1
                                )
                              )
                            )
                          : "0.00"}
                      </i>
                    </p>
                    <p>
                      Retention Amount
                      <i>
                        {entryData?.filter((item) => item?.payment_type === 2)
                          ?.length > 0
                          ? formatNumber(
                              calculateAmount(
                                entryData?.filter(
                                  (item) => item?.payment_type === 2
                                )
                              )
                            )
                          : "0.00"}
                      </i>
                    </p>
                  </div>
                  {entryData?.find((item) => item?.payment_type === 2) ? (
                    <></>
                  ) : (
                    <div>
                      <Button onClick={() => AddRetention()}>
                        Add Amount to be Retented
                      </Button>
                    </div>
                  )}
                </span>
                <div className="table table-bordered journal-table">
                  <div className="react-bootstrap-table journal-entries-table payment-edit-table">
                    <table
                      className="table-content"
                      style={{
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "18%" }}>
                            Bank Account Name
                          </th>
                          <th scope="col" style={{ width: "15%" }}>
                            Bank Name
                          </th>
                          <th scope="col" style={{ width: "5%" }}>
                            BSB
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            Account Number
                          </th>
                          <th scope="col" style={{ width: "12%" }}>
                            Amount
                          </th>
                          <th scope="col" style={{ width: "20%" }}>
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {entryData &&
                          entryData?.length > 0 &&
                          entryData?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className="payment-schedule-add-row"
                              >
                                <td>
                                  <input
                                    type="text"
                                    name="account_name"
                                    value={
                                      item?.account_name
                                        ? item?.account_name
                                        : item?.retention_account?.account_name
                                        ? item?.retention_account
                                            ?.account_name + "(Retention)"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleAccountName(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <ReactSelect
                                    name="bank_id"
                                    value={
                                      item?.bank_id
                                        ? bankListing?.find(
                                            (bankItem) =>
                                              bankItem?.value === item?.bank_id
                                          )
                                        : item?.payment_type === 2
                                        ? bankListing?.find(
                                            (bankItem) => bankItem?.value === ""
                                          )
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleBank(entryData, index, e);
                                    }}
                                    options={bankListing}
                                    placeholder="Select Bank"
                                    className="bankdropdown"
                                    classNamePrefix="selectbox"
                                    isLoading={bankLoading}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "grey",
                                      },
                                    })}
                                  />
                                </td>
                                <td>
                                  <ReactInputMask
                                    type="text"
                                    name="bsb"
                                    value={item?.bsb ? item?.bsb : ""}
                                    onChange={(e) => {
                                      handleBSBChange(entryData, index, e);
                                    }}
                                    onBlur={(e) => {
                                      handleBSBBlur(entryData, index, e);
                                    }}
                                    className="edit-payment-bsb"
                                    mask="999-999"
                                    maskChar={null}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <div className="text-danger">
                                    {errors?.[index]?.bsb
                                      ? errors?.[index]?.bsb
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <ReactInputMask
                                    type="text"
                                    name="account_number"
                                    value={
                                      item?.account_number
                                        ? item?.account_number
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleAccountNumberChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleAccountNumberBlur(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    mask="999999999"
                                    maskChar={null}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <div className="text-danger">
                                    {errors?.[index]?.account_number
                                      ? errors?.[index]?.account_number
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <NumberFormat
                                    type="text"
                                    name="amount"
                                    value={item?.amount}
                                    onChange={(e) => {
                                      handleAmountChange(entryData, index, e);
                                    }}
                                    onBlur={(e) => {
                                      handleAmountBlur(entryData, index, e);
                                    }}
                                    className={`${
                                      !item?.amount && item?.payment_type === 1
                                        ? "error-input-cell"
                                        : ""
                                    }`}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <div className="text-danger">
                                    {errors?.[index]?.amount
                                      ? errors?.[index]?.amount
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <Form.Group>
                                    <textarea
                                      name="description"
                                      placeholder="Add Description"
                                      rows={2}
                                      value={
                                        item?.description
                                          ? item?.description
                                          : ""
                                      }
                                      onChange={(e) => {
                                        handleDescription(entryData, index, e);
                                        handleBlurDescription(
                                          entryData,
                                          index,
                                          e
                                        );
                                      }}
                                    />
                                    <span className="error">
                                      {errors?.[index]?.description
                                        ? errors?.[index]?.description
                                        : ""}
                                    </span>
                                  </Form.Group>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleEditToggle()}
                  disabled={checkDisable()}
                >
                  Save
                </Button>
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => toggleEditModal()}
                >
                  Cancel
                </Button>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
      {editConfirmModal && (
        <Modal
          size="lg"
          show={editConfirmModal}
          onHide={() => {
            toggleEditConfirmModal();
          }}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Warning !
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Do you want to change the total payment sum from{" "}
              {formatNumber(itemData?.total_amount)} to{" "}
              {formatNumber(
                Number(
                  calculateAmount(
                    entryData?.filter((item) => item?.payment_type === 1)
                  ) +
                    calculateAmount(
                      entryData?.filter((item) => item?.payment_type === 2)
                    )
                )
              )}
              ?
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleEditABA();
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleEditConfirmModal();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {approvalModal && (
        <GenerateAbaApprovalModal
          showModal={approvalModal}
          handleModalClose={handleModalClose}
          handleSubmit={handleGenerateAba}
          loading={loading}
          toggleGenerateModal={toggleGenerateModal}
          generateModal={generateModal}
        />
      )}
      {showCalender && (
        <Modal
          show={showCalender}
          onHide={toggleCalenderView}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Calender View
          </Modal.Header>
          <Modal.Body>
            <CalenderView projectData={projectData} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ABA;
