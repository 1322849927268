import moment from "moment";
import numbro from "numbro";
var CryptoJS = require("crypto-js");
const key = "60fu0TX0heuBb0oLE5HnN7Mo72SFKrb70cLcdpmDYFU1WdPU";

// export const formatNumber = (value, delimiter = 0) => {
//   try {
//     if (isNaN(unformatNumber(value))) {
//       return "";
//     }
//     let format = numbro(value).format({
//       thousandSeparated: true,
//       negative: "parenthesis",
//       mantissa: delimiter,
//     });
//     return format;
//   } catch {
//     return "";
//   }
// };

export const GSTClaculate = (value, tax) => {
  return tax === 0
    ? 0
    : formatNumber(Number(Number(value) - (Number(value) / 1.1) * 0.1));
};

export const GSTClaculateWithouComma = (value, tax) => {
  return tax === 0 ? 0 : Number((Number(value) / 1.1) * 0.1);
};

export const ExGSTClaculate = (value, tax) => {
  return tax === 0
    ? formatNumber(Number(value))
    : formatNumber(Number(Number(value) - (Number(value) / 1.1) * 0.1));
};

export const ExGSTClaculateWithouComma = (value, tax) => {
  return tax === 0
    ? value
    : Number(Number(value) - (Number(value) / 1.1) * 0.1);
};

export const unformatNumber = (value) => {
  return numbro.unformat(value);
};

/**
 *
 * @param {Phone Number} phone
 * @returns {Formated Phone Number}
 */
export const formatPhone = (phone) => {
  // let format =
  //   phone.slice(0, 3) === "+61"
  //     ? phone.slice(0, 3) +
  //       " " +
  //       phone.slice(3, 4) +
  //       " " +
  //       phone.slice(4, 8) +
  //       " " +
  //       phone.slice(8, 12)
  //     : "+61 " +
  //       phone.slice(0, 1) +
  //       " " +
  //       phone.slice(1, 5) +
  //       " " +
  //       phone.slice(5, 9);
  let phoneData = phone;
  if (phoneData.slice(0, 3) === "+61") {
    phoneData = phoneData?.split("+61")[1];
  }
  if (phoneData.length < 10) {
    phoneData = `0${Number(phoneData)}`; // Pad with leading zeros to make it 10 digits
  }
  let format = `${String(phoneData).substring(0, 2)} ${String(
    phoneData
  ).substring(2, 6)} ${String(phoneData).substring(6)}`;
  return format;
};

/**
 *
 * @param {Mobile Number} mobile
 * @returns {Formated Mobile Number}
 */
export const formatMobile = (mobile) => {
  // let format =
  //   mobile.slice(0, 3) === "+61"
  //     ? mobile.slice(0, 3) +
  //       " " +
  //       mobile.slice(3, 6) +
  //       " " +
  //       mobile.slice(6, 9) +
  //       " " +
  //       mobile.slice(9, 12)
  //     : "+61 " +
  //       mobile.slice(0, 3) +
  //       " " +
  //       mobile.slice(3, 6) +
  //       " " +
  //       mobile.slice(6, 9);
  let mobileData = mobile;
  if (mobileData.slice(0, 3) === "+61") {
    mobileData = mobileData?.split("+61")[1];
  }
  if (mobileData.length < 10) {
    mobileData = `0${Number(mobileData)}`; // Pad with leading zeros to make it 10 digits
  }
  let format = `${String(mobileData).substring(0, 2)} ${String(
    mobileData
  ).substring(2, 6)} ${String(mobileData).substring(6)}`;
  return format;
};

/**
 *
 * @param {ABN Number} abn
 * @returns {Formated ABN Number}
 */
export const formatABN = (abn) => {
  let abnString = abn.toString();
  let format =
    abnString.slice(0, 2) +
    " " +
    abnString.slice(2, 5) +
    " " +
    abnString.slice(5, 8) +
    " " +
    abnString.slice(8, 11);

  return format;
};

/**
 *
 * @param {First Name} firstName
 * @param {Last Name} lastName
 * @returns {Formated Full Name}
 */
export const formatName = (firstName, lastName) => {
  let format =
    firstName && lastName
      ? firstName + " " + lastName
      : !firstName && lastName
      ? lastName
      : !lastName && firstName
      ? firstName
      : "";
  // let format = firstName ? firstName + " " + lastName : lastName;
  return format;
};

export const chartOfAccountFormat = (element) => {
  let format =
    element?.name +
    (element?.account_type?.name
      ? " (" + element?.account_type?.name + ")"
      : "") +
    (element?.code ? " - " + element?.code.toString() : "") +
    (element?.sub_code ? "." + element?.sub_code.toString() : "");
  return format;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @returns {Formated Office Name}
 */
export const formatOfficeName = (street, suburb) => {
  let format = street
    ? street + (suburb ? ", " + suburb : "")
    : suburb
    ? suburb
    : "";
  return format;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @param {State Name} state
 * @param {Postal Code} code
 * @returns {Formated Full Address}
 */
export const formatFullAddress = (street, suburb, state, code) => {
  let format = (
    <p>
      {street && street + " "} {suburb && suburb + " "} {state && state + " "}{" "}
      {code && code + " "}
    </p>
  );

  return format;
};

export const checkIfFileExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

export const formatAccountNumber = (number) => {
  // const formatedNumber = "XXXX XXXX " + number?.toString().slice(-4);
  const formatedNumber = number?.toString();
  return formatedNumber;
};

export const formatBSBNumber = (number) => {
  // const formatedNumber = "XXX-" + number?.toString().slice(-3);
  const formatedNumber = number?.toString();
  return formatedNumber;
};

export const formatNumber = (number) => {
  const formatedNumber = number
    ? number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : 0.0;

  return formatedNumber;
};

export const formatNumberWithoutComma = (number) => {
  const formatedNumber = parseFloat(number)
    ? parseFloat(number).toFixed(2)
    : 0.0;

  return formatedNumber;
};

/**
 *
 * @param {Street Name} street
 * @param {Suburb Name} suburb
 * @param {State Name} state
 * @param {Postal Code} code
 * @returns
 */
export const formatedAddress = (street, suburb, state, code) => {
  if (!street && !suburb && !state && !code) return "-";
  let address = "";
  if (street) address = `${street}${code || state || suburb ? ", " : ""}`;
  if (suburb) address += `${suburb}${code || state ? ", " : ""}`;
  if (state) address += `${state}${code ? ", " : ""}`;
  if (code) address += `${formatedPostalCode(code)}`;

  return address;
};

const today = new Date();
export const pastFinancialdate =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

export const lastTwoMonth = [
  new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  new Date(),
];

export const formatedPostalCode = (n) => {
  const length = n ? n.toString().length : "";

  const format = length
    ? length === 4
      ? n
      : length === 3
      ? `0${n}`
      : length === 2
      ? `00${n}`
      : length === 1
      ? `000${n}`
      : ""
    : "";

  return format;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

// const today = new Date();
let tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
export const disabledFutureDates = [[tomorrow, false]];

const pastYear = new Date();
pastYear.setFullYear(pastYear.getFullYear() - 1);
const past2Year = new Date();
past2Year.setFullYear(past2Year.getFullYear() - 2);
// const past1days = new Date();
// past1days.setDate(past1days.getDate() - 1);
const past6days = new Date();
past6days.setDate(past6days.getDate() - 6);
const past13days = new Date();
past13days.setDate(past13days.getDate() - 13);
export const periods = [
  // { children: "Last 2 days", value: [past1days, new Date()] },
  { children: "Last Week", value: [past6days, new Date()] },
  { children: "Last 2 Weeks", value: [past13days, new Date()] },
  { children: "Last 12 Months", value: [pastYear, new Date()] },
  { children: "Last 24 Months", value: [past2Year, new Date()] },
];

export const removeString = (string, from, to) => {
  return [...string].filter((_, i) => i < from || i > to).join("");
};

const MAX_LIMIT_OF_NUMBER = 1000000000000000;
export const MaxLimit = (values) => {
  const { formattedValue, floatValue } = values;
  if (floatValue == null) {
    return formattedValue === "";
  } else {
    return floatValue < MAX_LIMIT_OF_NUMBER && floatValue >= 0;
  }
};

export const NegativeAmountConverter = (number) => {
  return (
    <>
      {number && Math.sign(number?.toFixed(2)) === -1
        ? "-$" +
          number

            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            ?.slice(1)
        : "$" +
          number?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
    </>
  );
};

export const DDMMYYYYFormat = (data) => {
  return `${data[2]}-${data[1]}-${data[0]}`;
};

export const encrypt = (data) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(data, key).toString();
  return ciphertext;
};

export const decrypt = (ciphertext) => {
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(ciphertext, key);
  var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};

export const ExcelDateFormat = (data) => {
  return data
    ? data?.toString()?.includes("/")
      ? data?.split("/")?.join("-")
      : data
    : "";
};

export const formatSubcontractorName = (data) => {
  let format =
    data?.business_name ===
    (data?.first_name && data?.last_name
      ? data?.first_name + " " + data?.last_name
      : !data?.first_name && data?.last_name
      ? data?.last_name
      : "")
      ? data?.business_name
      : data?.business_name
      ? `${data?.business_name} (${
          data?.first_name && data?.last_name
            ? data?.first_name + " " + data?.last_name
            : !data?.first_name && data?.last_name
            ? data?.last_name
            : ""
        })`
      : data?.first_name && data?.last_name
      ? data?.first_name + " " + data?.last_name
      : !data?.first_name && data?.last_name
      ? data?.last_name
      : "";

  // let format = firstName ? firstName + " " + lastName : lastName;
  return format;
};

function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const maskPostalCode = (num) => {
  return padWithLeadingZeros(num, 4)
}
