import moment from "moment";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackButton from "../../../components/Form/BackButton";
import ReconcileItem from "./ReconcileItem";
import Slider from "react-slick";
import { imageDefaultPrefixPath } from "../../../config";
import MonthlyBankReconciliation from "./MonthlyBankReconciliation";
import AuditTrail from "./AuditTrail";
import BankTransactions from "./BankTransactions";
import NewReconcileTransactions from "./NewReconcileTransactions";
import { useProjectBar } from "../../common/useProjectBar";
import { formatNumber } from "../../common/Misc";

const tabsKey = {
  "reconcile-transactions": 1,
  "reconcile-item": 2,
  "under-review": 3,
  "monthly-bank-reconciliation": 4,
  "bank-transactions": 5,
  "audit-trail": 6,
};

const ReconcileTransactionsTab = () => {
  const location = useLocation();

  const today = new Date();
  const defaultDateRange =
    parseInt(moment(today).format("MM")) === 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : parseInt(moment(today).format("MM")) > 7
      ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
      : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

  let stringForm = location?.state?.form?.split("-");
  let startDateParams = location?.state?.startDate?.split("-");
  let endDateParams = location?.state?.endDate?.split("-");
  const [valueRange, setValueRange] = useState(
    stringForm
      ? [
          new Date(stringForm[2], stringForm[1] - 1, 1),
          new Date(stringForm[2], stringForm[1], 0),
        ]
      : startDateParams
      ? [
          new Date(
            startDateParams[2],
            startDateParams[1] - 1,
            startDateParams[0]
          ),
          new Date(endDateParams[2], endDateParams[1] - 1, endDateParams[0]),
        ]
      : defaultDateRange
  );

  const params = useParams();
  const trustType = params?.trust_type === "project" ? 1 : 0;

  const [balanceInfo, setBalanceInfo] = useState([]);
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "reconcile-transactions";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Reconcile Transactions"
      : parseInt(defaultActiveKey) === 3
      ? "Under Review"
      : parseInt(defaultActiveKey) === 4
      ? "Monthly Bank Reconciliation"
      : parseInt(defaultActiveKey) === 5
      ? "Bank Transactions"
      : parseInt(defaultActiveKey) === 6
      ? "Audit Trail"
      : "Reconciled Items"
  );
  // const [projectName, setProjectName] = useState();
  // const [projectLogo, setProjectLogo] = useState();
  // const [builderId, setBuilderId] = useState(null);

  // useEffect(() => {
  //   getProjectData(projectId, setProjectName, setProjectLogo, setBuilderId);
  // }, [projectId]);

  // useEffect(() => {
  //   if (projectLogo && typeof projectLogo === "string") {
  //     checkIfFileExists(`${imageDefaultPrefixPath}${projectLogo}`, (exists) => {
  //       if (exists) {
  //         setProjectLogo(projectLogo);
  //       } else {
  //         setProjectLogo("");
  //       }
  //     });
  //   } else {
  //     setProjectLogo("");
  //   }
  // }, [projectLogo]);

  const { ProjectLogo, ProjectName, ProjectReadOnly, builderId } =
    useProjectBar();

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    loop: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 30000,
    slidesToShow: balanceInfo?.length > 2 ? 3 : balanceInfo?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {trustType === 0
                ? "Reconcile RTA Transactions"
                : "Reconcile PTA Transactions"}
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>

            <div className="content-details">
              {balanceInfo && balanceInfo?.length > 0 && (
                <div className="firm-details">
                  <div className="firm-summary">
                    <Slider {...settings}>
                      {balanceInfo?.map((item, index) => {
                        return (
                          <div className="account-wrapper" key={index}>
                            <h4 style={{ margin: 0 }}>
                              {item?.accountName
                                ? item?.accountName
                                : "Account Name"}
                              {item?.provider?.trust_type === 1
                                ? " (Project Trust Account)"
                                : " (Retention Trust Account)"}
                            </h4>
                            <div className="bank-detail">
                              <img
                                src={item?.provider?.logo}
                                className="account-logo"
                                alt=""
                              />
                              <p>
                                {item?.provider?.providerName
                                  ? item?.provider?.providerName
                                  : "Bank Name"}{" "}
                                <i>
                                  {`(${
                                    item?.accountNumber
                                      ? item?.accountNumber
                                      : ""
                                  })`}
                                </i>
                              </p>
                            </div>
                            <div className="reconciliation-block">
                              <div className="balance">
                                <h6>Statement Balance</h6>
                                <h5>
                                  {item?.balance_amount
                                    ? "$" +
                                      formatNumber(Number(item?.balance_amount))
                                    : "$0.00"}
                                </h5>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: "12px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Balance till date{" "}
                                  {item?.lastUpdated
                                    ? moment(
                                        item?.lastUpdated,
                                        "DD-MM-YYYY HH:mm:ss"
                                      ).format("DD-MM-YYYY")
                                    : ""}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              )}

              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li
                    className={
                      activeTab === "Reconcile Transactions" ? "active" : ""
                    }
                  >
                    <Link key={1}>Reconcile Transactions</Link>
                  </li>
                  <li
                    className={activeTab === "Reconciled Items" ? "active" : ""}
                  >
                    <Link key={2}>Reconciled Items</Link>
                  </li>
                  <li className={activeTab === "Under Review" ? "active" : ""}>
                    <Link key={3}>Under Review</Link>
                  </li>
                  <li
                    className={
                      activeTab === "Bank Transactions" ? "active" : ""
                    }
                  >
                    <Link key={5}>Bank Transactions</Link>
                  </li>
                  <li className={activeTab === "Audit Trail" ? "active" : ""}>
                    <Link key={6}>Audit Trail</Link>
                  </li>
                  <li
                    className={
                      activeTab === "Monthly Bank Reconciliation"
                        ? "active"
                        : ""
                    }
                  >
                    <Link key={4}>Monthly Bank Reconciliation</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Reconcile Transactions" && (
                  // <ReconcileTransactions
                  //   setBalanceInfo={setBalanceInfo}
                  //   projectName={projectName}
                  //   builderId={builderId}
                  // />
                  <NewReconcileTransactions
                    setBalanceInfo={setBalanceInfo}
                    projectName={ProjectName}
                    builderId={builderId}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
                {activeTab === "Reconciled Items" && (
                  <ReconcileItem
                    setBalanceInfo={setBalanceInfo}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
                {activeTab === "Under Review" && (
                  // <ReconcileTransactions
                  //   isUnderReviewed={true}
                  //   setBalanceInfo={setBalanceInfo}
                  //   projectName={projectName}
                  //   builderId={builderId}
                  // 
                  // />
                  <NewReconcileTransactions
                    isUnderReviewed={true}
                    setBalanceInfo={setBalanceInfo}
                    projectName={ProjectName}
                    builderId={builderId}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
                {activeTab === "Monthly Bank Reconciliation" && (
                  <MonthlyBankReconciliation
                    projectName={ProjectName}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
                {activeTab === "Audit Trail" && (
                  <AuditTrail
                    setBalanceInfo={setBalanceInfo}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
                {activeTab === "Bank Transactions" && (
                  <BankTransactions
                    setBalanceInfo={setBalanceInfo}
                    setValueRange={setValueRange}
                    valueRange={valueRange}
                    ProjectReadOnly={ProjectReadOnly}                  
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReconcileTransactionsTab;
