import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, defaultLimitPagination, get } from "../../../config";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
// import { debounce } from "lodash";
import BuilderActionButton from "./components/BuilderActionButton";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import BackButton from "../../../components/Form/BackButton";
import { formatMobile, formatName, formatPhone } from "../../common/Misc";
import Projects from "../Projects/Projects";
import AddProject from "../Projects/AddProject";

const Builders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [buildersList, setBuildersList] = useState([]);
  const [builderLoading, setBuilderLoading] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const handleDelete = () => {
    fetchBuilders();
  };

  /**
   * Fetch Builder API
   */
  const fetchBuilders = useCallback(async () => {
    try {
      setBuilderLoading(true);
      const { data } = await get(
        `${API.GET_BUILDERS}?limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}`
      );
      setBuilderLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const buildersList = data.data.data;
      setBuildersList(buildersList);
      return data.data;
    } catch (error) {
      setBuildersList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setBuilderLoading(false);
      } else {
        toast.error(error?.message);
        setBuilderLoading(false);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField]);

  /**
   *
   * @param {Number of Total Records} quantity
   * @returns builders List
   */
  const buildersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: buildersList[i]?.id,
        builder_id: buildersList[i]?.builder?.id
          ? buildersList[i]?.builder?.id
          : "",
        name: formatName(
          buildersList[i]?.first_name,
          buildersList[i]?.last_name
        ),
        email: buildersList[i]?.email,
        phone: buildersList[i]?.phone
          ? formatPhone(buildersList[i]?.phone)
          : "-",
        mobile: buildersList[i]?.mobile
          ? formatMobile(buildersList[i]?.mobile)
          : "-",
        crn: buildersList[i]?.builder?.crn
          ? buildersList[i]?.builder?.crn
          : "-",
        action: (
          <BuilderActionButton
            id={buildersList[i]?.id}
            builderId={buildersList[i]?.builder?.id}
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };
  const builders = buildersGenerator(buildersList?.length);

  /**
   * Table Heading Data
   */
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      sort: true,
    },
    {
      dataField: "crn",
      text: "CRN",
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
  ];

  /**
   * Table Change For Sorting
   */
  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    navigate(
      `/builders?limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  useEffect(() => {
    fetchBuilders();
  }, [fetchBuilders]);

  /**
   * Search
   */
  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchParam(value);
    setPage(1);
  };

  /**
   * Builders Limit Per Page
   */
  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  /**
   * Pagination
   */
  const handleChangePage = (page) => {
    setPage(page);
  };

  /**
   * Expand Row
   */
  const expandRow = {
    renderer: (row) => (
      <>
        {" "}
        <Projects id={row?.builder_id} />{" "}
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };
  const toggleProjectModal = () => {
    setShowProject(!showProject);
  };
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Builders
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => onSearchChange(e)}
                    type="text"
                    value={searchParam}
                    placeholder="Search Builder"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              <div className="table-btn builders">
                <Button
                  variant="primary"
                  onClick={() => navigate("/builders/add")}
                >
                  Add Builder
                </Button>
                <Button variant="primary" onClick={toggleProjectModal}>
                  Add Project
                </Button>
              </div>
            </div>
            <div className="custom-table">
              {builderLoading && <Spin />}
              <BootStrapTable
                keyField="builder_id"
                remote
                data={builders}
                columns={columns}
                onTableChange={handleTableChange}
                expandRow={expandRow}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
      {showProject && (
        <AddProject
          toggleProjectModal={toggleProjectModal}
          showProject={showProject}
        />
      )}
    </div>
  );
};

export default Builders;
