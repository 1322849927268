/* eslint-disable eqeqeq */
import { useState, useCallback, useEffect } from "react";
import Pagination from "../../../common/Pagination";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import DocumentRevision from "./components/DocumentRevision";
import axios from "axios";
import {
  API,
  defaultLimitPagination,
  // fileUpload,
  get,
  imageDefaultPrefixPath,
} from "../../../../config";
import { toast } from "react-toastify";
import DocumentListActionButton from "./components/DocumentListActionButton";

const DocumentList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const projectId = params?.project_id;
  const query = new URLSearchParams(location.search);
  const [documentList, setDocumentList] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const searchParam = query.get("search");

  const defaultOption = [
    { label: "Copies Of Contracts", value: 1 },
    { label: "Any Contract Variation/Amendment Documents", value: 2 },
    { label: "Payment Claims/ Schedules Given And Received", value: 3 },
    { label: "Supporting Documents", value: 4 },
    { label: "Others", value: 5 },
  ];

  const fetchDocumentList = useCallback(async () => {
    if (projectId) {
      try {
        const { data } = await get(
          `${API.GET_DOCUMENT_LIST}?builder_project_id=${
            projectId ? projectId : ""
          }&limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}`
        );
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setDocumentList(data?.data?.data);
      } catch (error) {
        setDocumentList([]);

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, searchParam, limit, page]);

  const handleDownload = async (value) => {
    try {
      const response = await axios.get(`${imageDefaultPrefixPath}${value}`, {
        responseType: "blob",
      });
      const fileData = response.data;
      const url = window.URL.createObjectURL(new Blob([fileData]));

      var a = document.createElement("a");
      a.href = url;
      var extention = value.split(".");
      var file = value.split("/");
      a.setAttribute(
        "download",
        file[file.length - 1] || "document" + extention
      );
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      toast.error("File does not exist.");
    }
  };
  const handleView = async (value) => {
    window.open(`${imageDefaultPrefixPath}${value}`, "_blank", "noreferrer");
  };

  // const handleDelete = () => {
  //   fetchDocumentList();
  // };

  const documentListGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: documentList[i]?.id,
        document: documentList[i]?.document,
        document_type: defaultOption
          ? defaultOption.map((item) => {
              if (item.value == Number(documentList[i]?.document_type)) {
                return item?.label;
              } else {
                return "";
              }
            })
          : "",

        action: (
          <>
            <DocumentListActionButton
              id={documentList[i]?.id}
              handleDownload={() => handleDownload(documentList[i]?.document)}
              handleView={() => handleView(documentList[i]?.document)}
            />
          </>
        ),
      });
    }
    return items;
  };

  const documentData = documentListGenerator(documentList?.length);

  const expandRow = {
    renderer: (row) => (
      <>
        <DocumentRevision id={row?.key} />{" "}
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const columns = [
    {
      dataField: "document_type",
      text: "Document Types",
    },
    {
      dataField: "document",
      text: "Document",
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "97px" },
    },
  ];

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    // navigate(`/projects/${projectId}/settings?tab=documents&child_tab=list`, {
    //   replace: true,
    // });
    navigate(`/projects/${projectId}/documents?tab=documents&child_tab=list`, {
      replace: true,
    });
  }, [projectId, navigate]);

  useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  return (
    <>
      <div className="custom-table tab-table">
        {/* {accountLoading && <Spin />} */}
        <BootstrapTable
          keyField="key"
          remote
          data={documentData}
          columns={columns}
          expandRow={expandRow}
          noDataIndication="No Data Found"
        />
        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={page}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
    </>
  );
};
export default DocumentList;
