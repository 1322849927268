import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";
import { API, fileUpload, get } from "../../../../config";
// import {
//   useBankList,
//   useAllBankList,
// } from "../../../Builders/Projects/components/ProjectsHelper";
import { AddTrusteeSchema } from "../../../common/Validation";
import Spin from "../../../common/Spin";
import { useCallback } from "react";

const Trustee = ({ setProjectLogo, setProjectName, getProjectData, ProjectReadOnly }) => {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [id, setId] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const params = useParams();
  const projectId = params?.project_id;
  const navigate = useNavigate();

  const fetchProjectData = useCallback(
    async (projectID) => {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.GET_WITHDRAWAL_DETAILS}/trustee?builder_project_id=${projectID}`
        );
        setProjectData(data?.data);
        if (data?.data?.id) {
          setId(data?.data?.id);
        } else {
          setId(null);
        }
        navigate(`/projects/${projectID}/settings?tab=trustee`, {
          replace: true,
        });
        setLoading(false);
      } catch (e) {
        setProjectData(null);
        setLoading(false);
      }
    },
    [navigate]
  );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    // value.trustee_opening_balance = Number(
    //   `${value?.trustee_opening_balance}`.replace(/,/g, "")
    // );
    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    formData.append(
      "last_name",
      value?.entity_name ? value?.entity_name : ""
    );
    formData.append(
      "business_name",
      value?.entity_name ? value?.entity_name : ""
    );
    formData.append("email", value?.email ? value?.email : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("abn", value?.abn ? value?.abn : "");
    formData.append("qbcc_number", value?.qbcc_number ? value?.qbcc_number : "");

    // formData.append(
    //   "account_name",
    //   value?.trustee_account_name ? value?.trustee_account_name : ""
    // );
    // formData.append("bsb", value?.trustee_bsb ? value?.trustee_bsb : "");
    // formData.append(
    //   "account_number",
    //   value?.trustee_account_number ? value?.trustee_account_number : ""
    // );
    // formData.append(
    //   "bank_id",
    //   value?.trustee_bank_id ? value?.trustee_bank_id?.value : ""
    // );
    formData.append("builder_project_id", projectId);
    // if (id === null) {
      formData.append("is_pta", "4");
    // }
    // formData.append(
    //   "apca_id",
    //   value?.trustee_apca_id ? value?.trustee_apca_id : ""
    // );
    // formData.append(
    //   "opening_balance",
    //   value?.trustee_opening_balance ? value?.trustee_opening_balance : 0
    // );
    if (id !== null) {
      formData.append("_method", "put");
    }
    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.WITHDRAWAL}${id !== null ? `/${id}` : ""}`,
        formData
      );
      toast.success(data?.message);

      setLoading(false);
      if (data) {
        if (projectId) {
          setProjectData(null);
          // navigate(`/projects/${projectId}/settings?tab=project-settings`);
          fetchProjectData(projectId);
          getProjectData(projectId, setProjectName, setProjectLogo);
        }
      }
      return data?.data;
    } catch (error) {
      setProjectData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProjectData(projectId);
  }, [projectId, fetchProjectData]);

  //Bank
  // const { bankLoading, bankList = [] } = useBankList();
  // const { allBankLoading, allBankList = [] } = useAllBankList();

  // const bankListing = bankList ? bankList : [];
  // const allBanks = allBankList ? allBankList : [];

  // const trusteeBank = allBanks?.find(
  //   (item) => item?.value === projectData?.bank_id
  // );

  return (
    <>
      <Formik
        initialValues={{
          entity_name: projectData?.last_name
            ? projectData?.last_name
            : "",
          abn: projectData?.abn ? projectData?.abn : "",
          mobile: projectData?.mobile ? projectData?.mobile : "",
          phone: projectData?.phone ? projectData?.phone : "",
          email: projectData?.email ? projectData?.email : "",
          qbcc_number: projectData?.qbcc_number ? projectData?.qbcc_number : "",
          // trustee_account_name: projectData?.account_name
          //   ? projectData?.account_name
          //   : "",
          // trustee_bsb: projectData?.bsb ? projectData?.bsb : "",
          // trustee_account_number: projectData?.account_number
          //   ? projectData?.account_number
          //   : "",
          // trustee_bank_id: trusteeBank ? trusteeBank : "",
          // trustee_apca_id: projectData?.apca_id ? projectData?.apca_id : "",
          // trustee_opening_balance: projectData?.opening_balance
          //   ? projectData?.opening_balance
          //   : 0
        }}
        enableReinitialize
        innerRef={formValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={AddTrusteeSchema}
      >
        {({
          values,
          setErrors,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              {loading && <Spin />}
              <Form
                values={values}
                errors={errors}
                setErrors={setErrors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                // bankListing={bankListing}
                // allBanks={allBanks}
                // bankLoading={bankLoading}
                // allBankLoading={allBankLoading}
                projectData={projectData}
                projectId={projectId}
                setLoading={setLoading}
                ProjectReadOnly={ProjectReadOnly}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Trustee;
