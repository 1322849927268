import React, { useState } from "react";
import {
  Navigate,
  // useLocation,
  useParams,
} from "react-router-dom";
import Header from "./components/Header/Index";
import ProjectHeader from "./components/Header/ProjectIndex";
import SidebarNav from "./components/Header/SidebarNav";
import { API, get, idelTime } from "./config";
import useIdleTimeout from "./components/useIdleTimeout";
import { Modal, Button } from "react-bootstrap";
// import SubscriptionNotFound from "./pages/common/SubscriptionNotFound";
// import { decrypt } from "./pages/common/Misc";

const ProtectedRoute = ({
  notSubscription = false,
  component: Component,
  ...rest
}) => {
  const params = useParams();
  const token = localStorage.getItem("bt-auth-token");
  // const location = useLocation();

  const projectId = params?.project_id;
  const tfaCheck = localStorage.getItem("bt-auth-tfa");
  // let subscription =
  //   localStorage.getItem("bt-subscription") &&
  //   decrypt(localStorage.getItem("bt-subscription"));
  // const role = localStorage.getItem("bt-role");

  // Idle Logic start
  const logout = () => {
    get(API.LOGOUT)
      .then((response) => {
        if (response.status === 200) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch(() => {
        var error = {};
        error["bt_error"] =
          "There is some issue with request, please try after some time.";
        this.setState({ errors: error });
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
    localStorage.clear();
  };

  // Here idleTime is in minutes
  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: idelTime,
  });

  const handleLogout = () => {
    idleTimer.reset();
    logout();
    setOpenModal(false);
  };

  // Idle Logic end
  if (token) {
    if (tfaCheck === "2" || tfaCheck === "-1") {
      if (projectId) {
        return (
          <>
            <div className="project-section">
              <ProjectHeader />
              <SidebarNav />
              <Component {...rest} />
              {/* {notSubscription === true || parseInt(subscription) === 1 || location.pathname === "/projects" ? (
                <Component {...rest} />
              ) : (
                <SubscriptionNotFound />
              )} */}
              {openModal && (
                <Modal
                  show={openModal}
                  onHide={handleLogout}
                  size="lg"
                  dialogClassName="modal-50w small-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  className="project-section"
                  centered
                >
                  <Modal.Header closeButton style={{ marginBottom: "10px" }}>
                    Your session is expired
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ margin: "0 10px" }}>
                      <p>
                        Your session is expired. You are automatically signed
                        out.
                      </p>
                      <p>
                        Expired due to the reason that user is idle for{" "}
                        {idelTime} mins.
                      </p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      className="reset"
                      onClick={handleLogout}
                    >
                      Login Again
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </>
        );
      } else {
        return (
          <>
            <Header />
            <Component {...rest} />
            {/* <div className="header-blocks">
            <header>
              <Sitelogo />
              <HeaderNav />
              <UserProfile />
            </header>
            <div className="header-bottom-block"></div>
          </div> */}
            {/* <HeaderNav
        // setIsVisible={setIsVisible}
        // setSelectedCustomer={setSelectedCustomer}
        /> */}
            {/* {notSubscription === true ||
            parseInt(subscription) === 1 ||
            role === "Admin" ? (
              <Component {...rest} />
            ) : (
              <SubscriptionNotFound />
            )} */}
            {/* {isVisible && (
          <CustomerDetailPopup
            customerId={selectedCustomer}
            toggleShowModal={() => setIsVisible(false)}
          />
        )} */}
            {openModal && (
              <Modal
                show={openModal}
                // onHide={stay}
                onHide={handleLogout}
                size="lg"
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                className="project-section"
                centered
              >
                <Modal.Header closeButton style={{ marginBottom: "10px" }}>
                  Your session is expired
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Your session is expired. You are automatically signed out.
                  </p>
                  <p>
                    Expired due to the reason that user is idle for {idelTime}{" "}
                    mins.
                  </p>
                </Modal.Body>
                <Modal.Footer>
                  {/* <Button variant="secondary" className="reset" onClick={stay}>
                  Stay signed in
                </Button> */}
                  <Button
                    variant="secondary"
                    className="reset"
                    onClick={handleLogout}
                  >
                    Login Again
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </>
        );
      }
    } else {
      return <Navigate to="/2fa" />;
    }
  } else {
    return <Navigate to="/#" />;
  }
};

export default ProtectedRoute;
