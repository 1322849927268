import React from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Spin from "../../common/Spin";
import Pagination from "../../common/Pagination";

const VariationPaymentSchedule = ({
  showVariation,
  toggleCloseModal,
  variationColumns,
  variationData,
  invoiceNumber,
  loading,
  total,
  limit,
  page,
  handleUpdateLimit,
  handleChangePage,
  from,
  to
}) => {
  return (
    <>
      {showVariation && (
        <Modal
          size="lg"
          show={showVariation}
          onHide={toggleCloseModal}
          dialogClassName="larg-popup journal-modal review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Payment Schedule Variation {`(${invoiceNumber})`}</Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            <>
            {loading && <Spin />}
              <div>
                <BootstrapTable
                  keyField="key"
                  data={variationData}
                  columns={variationColumns}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default VariationPaymentSchedule;
