import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { AddChartOfAccountSchema } from "../../../common/Validation";
import Spin from "../../../common/Spin";
import { API, fileUpload, get } from "../../../../config";
const AddNewAccountModal = ({
  handleAddNewAccount,
  businessId,
  addAccountItemID,
  addNewAccount,
  accountName,
  addAccountItem,
  handleNewAccountSelection,
  bankTransactionData,
  handleAccountChange,
  setSplitGst2,
  setSplitAccountId,
  setSplitGst,
}) => {
  const [loading, setLoading] = useState(false);

  const [accountData, setAccountData] = useState();

  const [accountTpesLoading, setAccountTpesLoading] = useState();
  const [accountTypesList, setAccountTypesList] = useState();

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (error) {
      setAccountTpesLoading(false);
      setAccountTypesList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, []);

  const accuntingList = accountTypesList?.map((item) => {
    const { name: label, id: value, ...rest } = item;
    return { label, value, ...rest };
  });
  const accountTypeValue = accuntingList?.find(
    (item) => item?.value === accountData?.account_type_id
  );

  const typeList = [
    { label: "Debit", value: "0" },
    { label: "Credit", value: "1" },
  ];
  const typeValue = typeList?.find(
    (item) => parseInt(item?.value) === accountData?.type
  );

  // const taxList = [
  //   { label: "GST 10%", value: "1" },
  //   { label: "GST Excluded", value: "0" },
  // ];
  const taxList = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];
  const taxValue = taxList?.find(
    (item) => parseInt(item?.value) === accountData?.tax
  );

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.account_type_id = value?.account_type_id?.value
      ? value?.account_type_id?.value
      : "";
    value.type = value?.type?.value ? parseInt(value?.type?.value) : "";
    value.tax = value?.tax?.value ? parseInt(value?.tax?.value) : "";
    value.code = value?.code ? parseInt(value?.code) : "";
    value.sub_code = value?.sub_code ? parseInt(value?.sub_code) : "";

    formData.append("builder_project_id", businessId);
    formData.append("account_type_id", value?.account_type_id);
    formData.append("type", value?.type);
    formData.append("tax", value?.tax);
    formData.append("code", value?.code);
    formData.append("sub_code", value?.sub_code);
    formData.append("name", value?.name ? value?.name : "");
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ACCOUNT_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleAddNewAccount();
        handleAccountChange();
        setSplitGst(data?.data?.tax);
        setSplitAccountId(data?.data?.id);
        setSplitGst2(data?.data?.tax);
        addAccountItemID &&
          handleNewAccountSelection(
            data?.data,
            addAccountItemID,
            addAccountItem,
            bankTransactionData
          );
      }
      return data?.data;
    } catch (error) {
      setAccountData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={addNewAccount}
        onHide={handleAddNewAccount}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Add New Account
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              account_type_id: accountTypeValue,
              type: typeValue,
              tax: taxValue,
              name: accountData?.name ? accountData?.name : accountName,
              code: accountData?.code,
              sub_code: accountData?.sub_code,
              description: accountData?.description,
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddChartOfAccountSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}

                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className="entry-section"
                  >
                    <div className="form-box">
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Account Type
                        </label>
                        <Select
                          // type="select"
                          name="account_type_id"
                          value={
                            values?.account_type_id
                              ? values?.account_type_id
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue(`account_type_id`, e ? e : "");
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={accuntingList}
                          placeholder="Select Account Type"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                          isLoading={accountTpesLoading}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="account_type_id" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Type
                        </label>
                        <Select
                          name="type"
                          // type="select"
                          value={values?.type ? values?.type : ""}
                          onChange={(e) => {
                            setFieldValue(`type`, e ? e : "");
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={typeList}
                          placeholder="Select Type"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="type" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Tax
                        </label>

                        <Select
                          name="tax"
                          // type="select"
                          value={values?.tax ? values?.tax : ""}
                          onChange={(e) => {
                            setFieldValue(`tax`, e ? e : "");
                            // handleChange(e);
                          }}
                          onBlur={handleBlur}
                          options={taxList}
                          placeholder="Select Tax"
                          classNamePrefix="selectbox"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />

                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="tax" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={values?.name ? values?.name : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="name" />
                        </span>
                      </div>

                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Code
                        </label>
                        <InputMask
                          type="text"
                          name="code"
                          value={values?.code ? values?.code : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          mask="9999"
                          maskChar={null}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="code" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>Sub Code</label>
                        <InputMask
                          type="text"
                          name="sub_code"
                          value={values?.sub_code ? values?.sub_code : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          mask="9999"
                          maskChar={null}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="sub_code" />
                        </span>
                      </div>
                      <div className="form-group full-width">
                        <label>Description</label>
                        <textarea
                          type="text"
                          name="description"
                          value={values?.description ? values?.description : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          style={{
                            borderRadius: "10px",
                            margin: "0 0 15px",
                            minHeight: "70px",
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button type="submit">Save</Button>
                      <Button
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          handleAddNewAccount();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAccountModal;
