import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilePdf, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import {
  chartOfAccountFormat,
  formatABN,
  formatedAddress,
  formatName,
  formatNumber,
  formatPhone,
  formatSubcontractorName,
  MaxLimit,
} from "../../../common/Misc";
import { ErrorMessage, Formik } from "formik";
import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import ChartOfAccountSelect from "../../../../pages/common/ChartOfAccountSelect";
import { AddReceiptSchema } from "../../../common/Validation";
import {
  API,
  fileUpload,
  get,
  imageDefaultPrefixPath,
  // s3DefaultPath,
} from "../../../../config";
import { toast } from "react-toastify";
import Spin from "../../../common/Spin";
import ContractorSelectWithTA from "../../../common/ContractorSelectWithTA";
import NumberFormat from "react-number-format";
import axios from "axios";
import CustomTooltip from "../../../common/CustomTooltip";
// import ReceiptEditModal from "./ReceiptEditModal";
// import ReceiptDetailsModal from "./ReceiptDetailsModal";

const ReceiptActionButton = ({
  record,
  fetchReceipts,
  projectId,
  trust_type,
  ProjectName,
  file,
  id,
  ProjectReadOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const [accountContactLoading, setAccountContactLoading] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const toggleShowEditModal = () => {
    setShowEdit(!showEdit);
  };

  const [show, setShow] = useState(false);
  const toggleShowModal = () => {
    setShow(!show);
  };

  const [showUndo, setShowUndo] = useState(false);

  const toggleUndoModal = () => {
    setShowUndo(!showUndo);
  };

  const handleFormSubmit = async (value) => {
    value.amount = value?.amount
      ? Number(`${value?.amount}`.replace(/,/g, ""))
      : 0;
    const formData = new FormData();
    formData.append("builder_project_id", projectId);
    // formData.append(
    //   "transaction_date",
    //   value?.transaction_date
    //     ? moment(value?.transaction_date).format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append(
      "transaction_date",
      value?.transaction_date ? value?.transaction_date : ""
    );
    formData.append("note", value?.note ? value?.note : "");
    formData.append("payer_name", value?.payer_name ? value?.payer_name : "");
    formData.append(
      "chart_of_account_id",
      value?.chart_of_account_id ? value?.chart_of_account_id?.id : ""
    );
    formData.append(
      "contact_id",
      value?.contact_id ? value?.contact_id?.id : ""
    );
    formData.append("amount", value?.amount ? value?.amount : 0);
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );
    formData.append("_method", "PUT");

    try {
      setLoading(true);
      const response = await fileUpload(
        `${API.CREATE_RECIEPT}/${record?.id}`,
        formData,
        {}
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        toggleShowEditModal();
        fetchReceipts();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const fetchAccountbasedOnContact = async (search) => {
    try {
      setAccountContactLoading(true);
      const response = await get(
        `${API.GET_ACCOUNT_DATA}?builder_project_id=${projectId}&search=${
          search ? search : ""
        }`
      );
      let account =
        response?.data?.data?.data && response?.data?.data?.data?.length > 0
          ? response?.data?.data?.data.map((element) => {
              return {
                id: element.id,
                label:
                  element?.name +
                  (element?.account_type?.name
                    ? " (" + element?.account_type?.name + ")"
                    : "") +
                  (element?.code ? " - " + element?.code.toString() : "") +
                  (element?.sub_code ? "." + element?.sub_code.toString() : ""),
                tax: element?.tax,
              };
            })
          : [];
      setAccountContactLoading(false);
      return account[0];
    } catch (error) {
      setAccountContactLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleReceiptUndo = async (id) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("module", "receipt");
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append(`trust_type`, trust_type);
      formData.append("ids[0]", id);
      const { data } = await fileUpload(
        `${API.JOURNAL_ENTRIES_UNDO}`,
        formData
      );
      setLoading(false);
      toast.success(data?.message);
      fetchReceipts();
      toggleUndoModal();
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  return (
    <span>
      {record?.is_reversed === 0 &&
        record?.is_reconciled === 0 &&
        !ProjectReadOnly && (
          <>
            <CustomTooltip message={"Undo"}>
              <span>
                <FontAwesomeIcon
                  icon={faUndo}
                  size="1x"
                  onClick={() => {
                    toggleUndoModal();
                  }}
                />
              </span>
            </CustomTooltip>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          </>
        )}

      <CustomTooltip message={"View"}>
        <span>
          <FontAwesomeIcon onClick={toggleShowModal} icon={faEye} size="1x" />
        </span>
      </CustomTooltip>
      {trust_type === 0 && (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <CustomTooltip message={"Download Receipt"}>
            <span>
              <FontAwesomeIcon
                icon={faFilePdf}
                size="1x"
                onClick={() => {
                  downloadLetter(file);
                }}
              />
            </span>
          </CustomTooltip>
        </>
      )}
      {trust_type === 1 && (
        <>
          {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <FontAwesomeIcon
            onClick={toggleShowEditModal}
            icon={faEdit}
            size="1x"
          /> */}
        </>
      )}
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={toggleShowModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Receipt Details</Modal.Header>
          <Modal.Body>
            {trust_type === 1 ? (
              <>
                <div className="modal-content">
                  <div className="modal-content-details">
                    <p>
                      <strong>Transaction Date:</strong>{" "}
                      {record?.transaction_date
                        ? record?.transaction_date
                        : "-"}
                    </p>
                    <p>
                      <strong>Payer Name:</strong>{" "}
                      {record?.payer_name ? record?.payer_name : ProjectName}
                    </p>
                    <p>
                      <strong>Amount:</strong>{" "}
                      {record?.amount
                        ? "$" + formatNumber(Number(record?.amount))
                        : `$0.00`}
                    </p>
                    <p>
                      <strong>File Note:</strong>{" "}
                      {record?.note ? record?.note : "-"}
                    </p>
                    <p>
                      <strong>Account Name:</strong>{" "}
                      {record?.chart_of_account
                        ? chartOfAccountFormat(record?.chart_of_account)
                        : "-"}
                    </p>
                    <p>
                      <strong>Description:</strong>{" "}
                      {record?.description ? record?.description : "-"}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="payment-schedule-view"
                  style={{ height: "90vh", overflow: "auto" }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      // margin: "50px 30px",
                      margin: "30px",
                    }}
                  >
                    <table cellpadding="0" cellspacing="0" width="100%">
                      <tr>
                        <td style={{ width: "50%" }}></td>
                        <td style={{ width: "50%", textAlign: "right" }}>
                          {record?.builder_project?.builder?.logo ? (
                            <img
                              src={
                                imageDefaultPrefixPath +
                                record?.builder_project?.builder?.logo
                              }
                              style={{ height: "100px", width: "auto" }}
                              alt=""
                            />
                          ) : record?.builder_project?.builder?.builder_details
                              ?.profile_pic ? (
                            <img
                              src={
                                imageDefaultPrefixPath +
                                record?.builder_project?.builder
                                  ?.builder_details?.profile_pic
                              }
                              style={{ height: "100px", width: "auto" }}
                              alt=""
                            />
                          ) : (
                            <></>
                          )}
                          {/* <p>
                          <strong>
                            {record?.builder_project?.builder?.builder_details
                              ? formatName(
                                  record?.builder_project?.builder
                                    ?.builder_details?.first_name,
                                  record?.builder_project?.builder
                                    ?.builder_details?.last_name
                                )
                              : "NA"}
                          </strong>
                        </p> */}
                          <p>
                            <strong>
                              {record?.builder_project?.builder?.business_name
                                ? record?.builder_project?.builder
                                    ?.business_name
                                : "-"}
                            </strong>
                          </p>
                          <p>
                            ABN/ACN:{" "}
                            {record?.builder_project?.builder?.abn
                              ? formatABN(record?.builder_project?.builder?.abn)
                              : "-"}
                          </p>
                          <p>
                            {formatedAddress(
                              record?.builder_project?.builder?.street,
                              record?.builder_project?.builder?.suburb?.name,
                              record?.builder_project?.builder?.state
                                ?.short_name,
                              record?.builder_project?.builder?.postal_code
                            )}
                          </p>
                          <p>
                            Phone:{" "}
                            {record?.builder_project?.builder?.builder_details
                              ?.phone
                              ? formatPhone(
                                  record?.builder_project?.builder
                                    ?.builder_details?.phone
                                )
                              : "-"}
                          </p>
                        </td>
                      </tr>
                    </table>

                    <div
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        fontSize: "18px",
                      }}
                    >
                      Payment Advice
                    </div>
                    <div
                      style={{
                        textTransform: "uppercase",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Deposit into retention trust account
                    </div>

                    <p style={{ marginBottom: "10px" }}>
                      This is to advise that funds will be electronically
                      transferred into the retention trust account for payments
                      as detailed below.
                    </p>

                    <table
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style={{ marginBottom: "50px" }}
                    >
                      <tr>
                        <td style={{ width: "75%" }}>
                          <p>
                            <strong>
                              {" "}
                              {record?.contact
                                ? formatName(
                                    record?.contact?.first_name,
                                    record?.contact?.last_name
                                  )
                                : "-"}
                            </strong>
                          </p>
                          <p>
                            {record?.contact?.business_name
                              ? record?.contact?.business_name
                              : ""}
                          </p>
                          <p>
                            ABN/ACN:{" "}
                            {record?.contact?.abn
                              ? formatABN(record?.contact?.abn)
                              : "-"}
                          </p>
                          <p>
                            {formatedAddress(
                              record?.contact?.street,
                              record?.contact?.suburb?.name,
                              record?.contact?.state?.short_name,
                              record?.contact?.postal_code
                            )}
                          </p>
                          <p>
                            Phone:{" "}
                            {record?.contact?.phone
                              ? formatPhone(record?.contact?.phone)
                              : "-"}
                          </p>
                        </td>
                        <td style={{ width: "25%" }}></td>
                      </tr>
                    </table>
                    <table cellpadding="0" cellspacing="0" width="100%">
                      <tr>
                        <td width="60%;">
                          {/* @if( !empty($project->rta_project_id) )
                                @php
                                    $bank_name = $project->rtaProject->rtaContractor->bank->abbreviation ?? '';
                                    $account_name = $project->rtaProject->rtaContractor->account_name ?? '';
                                    $bsb = $project->rtaProject->rtaContractor->bsb ?? '';
                                    $account_number = $project->rtaProject->rtaContractor->account_number ?? '';
                                @endphp
                            @else
                                @php
                                    $bank_name = $project->rtaContractor->bank->abbreviation ?? '';
                                    $account_name = $project->rtaContractor->account_name ?? '';
                                    $bsb = $project->rtaContractor->bsb ?? '';
                                    $account_number = $project->rtaContractor->account_number ?? '';
                                @endphp
                            @endif */}
                          <strong>Payment Date:</strong>{" "}
                          {record?.transaction_date
                            ? record?.transaction_date?.split("-")?.join("/")
                            : "-"}
                          <br />
                          <span>
                            <strong>EFT Reference Number:</strong> {}
                          </span>
                          <br />
                          <br />
                          <strong>Financial Institute:</strong>{" "}
                          {record?.builder_project?.rta_project_id
                            ? record?.builder_project?.rta_project
                                ?.rta_contractor?.bank?.abbreviation
                            : record?.builder_project?.rta_contractor?.bank
                                ?.abbreviation}
                          <br />
                          <strong>Bank Account Name:</strong>{" "}
                          {record?.builder_project?.rta_project_id
                            ? record?.builder_project?.rta_project
                                ?.rta_contractor?.account_name
                            : record?.builder_project?.rta_contractor
                                ?.account_name}
                          <br />
                          <strong>BSB:</strong>{" "}
                          {record?.builder_project?.rta_project_id
                            ? record?.builder_project?.rta_project
                                ?.rta_contractor?.bsb
                            : record?.builder_project?.rta_contractor?.bsb}
                          <br />
                          <strong>Account Number:</strong>{" "}
                          {record?.builder_project?.rta_project_id
                            ? record?.builder_project?.rta_project
                                ?.rta_contractor?.account_number
                            : record?.builder_project?.rta_contractor
                                ?.account_number}
                        </td>
                        <td width="40%">
                          <strong>Total Retentions Deposited:</strong> $
                          {record?.amount
                            ? formatNumber(Number(record?.amount))
                            : "0.00"}
                          <br />
                          <br />
                          <strong>
                            Total retentions withheld
                            <br />
                            for the beneficiary for the
                            <br />
                            contract after the deposit:
                          </strong>{" "}
                          $
                          {record?.closing_balance_rta
                            ? formatNumber(Number(record?.closing_balance_rta))
                            : "0.00"}
                        </td>
                      </tr>
                    </table>
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style={{ marginTop: "50px" }}
                    >
                      <tr>
                        <td
                          style={{
                            width: "25%",
                            fontWeight: "bold",
                            borderBottom: "#CCC 1px solid",
                            background: "#F0F0F0",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          Payment Claim Date
                        </td>
                        <td
                          style={{
                            width: "25%",
                            fontWeight: "bold",
                            borderBottom: "#CCC 1px solid",
                            background: "#F0F0F0",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                            textAlign: "right",
                          }}
                        >
                          Reference Number
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            borderBottom: "#CCC 1px solid",
                            background: "#F0F0F0",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                            textAlign: "right",
                          }}
                        >
                          Cash Retention Withheld
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            borderBottom: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          {record?.transaction_date
                            ? record?.transaction_date?.split("-")?.join("/")
                            : "-"}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            borderBottom: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          {record?.payment_reference_number
                            ? `${record?.payment_reference_number}(P)`
                            : "-"}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            borderBottom: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          $
                          {record?.amount
                            ? formatNumber(Number(record?.amount))
                            : "0.00"}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "left",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        ></td>
                        <td
                          style={{
                            textAlign: "right",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          <strong>Total Amount:</strong>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            borderTop: "#CCC 1px solid",
                            padding: "10px",
                          }}
                        >
                          <strong>
                            $
                            {record?.amount
                              ? formatNumber(Number(record?.amount))
                              : "0.00"}
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
      <Modal
        size="lg"
        show={showEdit}
        onHide={toggleShowEditModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Edit Receipt</Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              transaction_date: record?.transaction_date
                ? new Date(moment(record?.transaction_date, "DD-MM-YYYY"))
                : "",
              note: record?.note ? record?.note : "",
              chart_of_account_id: record?.chart_of_account
                ? {
                    id: record?.chart_of_account.id,
                    label:
                      record?.chart_of_account?.name +
                      (record?.chart_of_account?.account_type?.name
                        ? " (" +
                          record?.chart_of_account?.account_type?.name +
                          ")"
                        : "") +
                      (record?.chart_of_account?.code
                        ? " - " + record?.chart_of_account?.code.toString()
                        : "") +
                      (record?.chart_of_account?.sub_code
                        ? "." + record?.chart_of_account?.sub_code.toString()
                        : ""),
                    tax: record?.chart_of_account?.tax,
                  }
                : "",
              contact_id: record?.contact ? record?.contact : null,
              payer_name: record?.payer_name ? record?.payer_name : "",
              amount: record?.amount ? record?.amount : "",
              description: record?.description ? record?.description : "",
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddReceiptSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || accountContactLoading) && <Spin />}
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className="entry-section"
                  >
                    <div className="form-box">
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Transaction Date
                        </label>
                        {/* <DatePicker
                          name="transaction_date"
                          value={
                            values?.transaction_date
                              ? values?.transaction_date
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue("transaction_date", e);
                          }}
                        /> */}
                        <DatePicker
                          cleanable={false}
                          name="transaction_date"
                          onChange={(e) =>
                            setFieldValue(
                              "transaction_date",
                              moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                            )
                          }
                          format="dd-MM-yyyy"
                          value={
                            values?.transaction_date
                              ? String(values?.transaction_date)?.includes("-")
                                ? new Date(
                                    String(values?.transaction_date)?.split(
                                      "-"
                                    )[2],
                                    String(values?.transaction_date)?.split(
                                      "-"
                                    )[1] - 1,
                                    String(values?.transaction_date)?.split(
                                      "-"
                                    )[0]
                                  )
                                : new Date(values?.transaction_date)
                              : null
                          }
                          className="rs-date-body"
                          placeholder="Select Date"
                          placement="autoVerticalStart"
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="transaction_date" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>File Note
                        </label>
                        <input
                          type="text"
                          name="note"
                          value={values?.note ? values?.note : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="note" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Contractor
                        </label>
                        <ContractorSelectWithTA
                          regionName={"1"}
                          name="contact_id"
                          business={true}
                          onChange={async (contact) => {
                            let accountData =
                              contact?.is_pta === 1
                                ? await fetchAccountbasedOnContact("2001")
                                : contact?.is_pta === 0
                                ? await fetchAccountbasedOnContact("2003")
                                : contact?.is_pta === 3
                                ? await fetchAccountbasedOnContact("2102")
                                : contact?.contact_type
                                ? await fetchAccountbasedOnContact(
                                    contact?.contact_type?.chart_of_account
                                      ?.name
                                  )
                                : await fetchAccountbasedOnContact("3049");
                            setFieldValue("contact_id", contact);
                            setFieldValue("chart_of_account_id", accountData);
                            setFieldValue(
                              "payer_name",
                              contact?.is_pta === 0
                                ? `${formatName(
                                    contact?.first_name,
                                    contact?.last_name
                                  )} (RTA)`
                                : contact?.is_pta === 1
                                ? `${formatName(
                                    contact?.first_name,
                                    contact?.last_name
                                  )} (PTA)`
                                : contact?.is_pta === 2
                                ? `${formatName(
                                    contact?.first_name,
                                    contact?.last_name
                                  )} (Withdrawal/General Business)`
                                : contact?.is_pta === 3
                                ? `${formatName(
                                    contact?.first_name,
                                    contact?.last_name
                                  )} (Principal)`
                                : formatSubcontractorName(contact)
                            );
                          }}
                          projectId={projectId}
                          value={values?.contact_id ? values?.contact_id : null}
                          placeholder={"Select Contractor"}
                          isClearable={false}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="contact_id" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Account
                        </label>
                        <ChartOfAccountSelect
                          regionName={"1"}
                          name="chart_of_account_id"
                          onChange={(e) => {
                            setFieldValue("chart_of_account_id", e);
                          }}
                          projectId={projectId}
                          value={
                            values?.chart_of_account_id
                              ? values?.chart_of_account_id
                              : null
                          }
                          placeholder={"Select Account"}
                          isClearable={false}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="chart_of_account_id" />
                        </span>
                      </div>
                      {/* <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Payer Name
                        </label>
                        <input
                          // disabled
                          type="text"
                          name="payer_name"
                          value={values?.payer_name ? values?.payer_name : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="payer_name" />
                        </span>
                      </div> */}
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Amount
                        </label>
                        <NumberFormat
                          required
                          type="text"
                          value={values?.amount ? values?.amount : ""}
                          onChange={(e) => {
                            setFieldValue("amount", e.target.value);
                          }}
                          isAllowed={(values) => MaxLimit(values)}
                          onBlur={handleBlur}
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="amount" />
                        </span>
                      </div>
                      <div className="form-group half-width">
                        <label>
                          <span className="required">*</span>Description
                        </label>
                        <textarea
                          type="text"
                          name="description"
                          value={values?.description ? values?.description : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          rows={1}
                        />
                        <span
                          className="text-danger"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          <ErrorMessage name="description" />
                        </span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button
                        type="Button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          toggleShowEditModal();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit">Save</Button>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {showUndo && (
        <Modal
          size="lg"
          show={showUndo}
          onHide={toggleUndoModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Undo Receipt
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are your sure you want to undo this receipt?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleUndoModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleReceiptUndo(id);
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </span>
  );
};

export default ReceiptActionButton;
