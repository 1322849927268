import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CancelButton from "../../../../components/Form/CancleButton";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import SuburbSelect from "../../../common/SuburbSelect";
// import NumberFormat from "react-number-format";
// import { MaxLimit } from "../../../common/Misc";
import ContractorTypeSelect from "../../../common/ContractorTypeSelect";
import NumberFormat from "react-number-format";
// import { FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEye,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../../common/CustomTooltip";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
  bankListing,
  bankLoading,
  projectId,
  edit = false,
  notImported = false
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    bankListing: PropTypes.any,
    bankLoading: PropTypes.any,
    projectId: PropTypes.any,
    edit: PropTypes.any,
    notImported: PropTypes.any
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    isChecked === true && setFieldValue("shipping_street", values?.street);
    isChecked === true && setFieldValue("shipping_state_id", values?.state_id);
    isChecked === true &&
      setFieldValue("shipping_suburb_id", values?.suburb_id);
    isChecked === true &&
      setFieldValue("shipping_postal_code", values?.postal_code);
  }, [
    isChecked,
    setFieldValue,
    values?.postal_code,
    values?.street,
    values?.state_id,
    values?.suburb_id,
  ]);

  const handleView = async (value) => {
    window.open(`${value}`, "_blank", "noreferrer");
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card">
        <h3>Personal Details</h3>
        <div className="form-box">
          {/* <div class="form-box"> */}
          <div className="form-group half-width builder-firm">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={values?.first_name ? values?.first_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="first_name" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>
              <span className="required">*</span>Last Name
            </label>
            <input
              type="text"
              name="last_name"
              value={values?.last_name ? values?.last_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="last_name" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>Phone No.</label>
            <InputMask
              type="text"
              name="phone"
              value={values?.phone ? values?.phone : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 9999 9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="phone" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>Mobile No.</label>
            <InputMask
              type="text"
              name="mobile"
              value={values?.mobile ? values?.mobile : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 9999 9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="mobile" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={values?.email ? values?.email : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="email" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>
              <span className="required">*</span>Business Name
            </label>
            <input
              type="text"
              name="business_name"
              value={values?.business_name ? values?.business_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="business_name" />
            </span>
          </div>
          <div className="form-group half-width builder-firm aba-input-field">
            <label>
              <span className="required">*</span>ABN
            </label>
            <InputMask
              type="text"
              name="abn"
              value={values?.abn ? values?.abn : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 999 999 999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <div className="icon">
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="aba-tooltip"
                onClick={() =>
                  window.open(
                    values?.abn
                      ? `https://abr.business.gov.au/ABN/View?id=${values?.abn.replace(
                          / /g,
                          ""
                        )}`
                      : `https://abr.business.gov.au`,
                    "_blank"
                  )
                }
              />
            </div>
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="abn" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>Contractor Type</label>
            <ContractorTypeSelect
              name="contact_type_id"
              onChange={(e) => {
                setFieldValue(`contact_type_id`, e ? e : "");
              }}
              classNamePrefix="selectbox"
              projectId={projectId}
              // defaultValue={null}
              value={values?.contact_type_id ? values?.contact_type_id : null}
              placeholder="Select Contractor Type"
              isClearable={true}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="contact_type_id" />
            </span>
          </div>
          <div className="form-group half-width builder-firm">
            <label>License Number</label>
            <input
              type="text"
              name="qbcc_number"
              value={values?.qbcc_number ? values?.qbcc_number : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="qbcc_number" />
            </span>
          </div>
          {/* </div> */}
          {/* <div className="form-box"> */}
          <div className="address-box">
            <h5>Postal Address</h5>
            <div className="form-group half-width builder-firm">
              <label>Address</label>
              <input
                type="text"
                name="street"
                value={values?.street ? values?.street : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="street" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Select State</label>
              {/* <AsyncPaginate loadOptions={stateListing} /> */}
              <Select
                name="state_id"
                value={values?.state_id ? values?.state_id : ""}
                onChange={(e) => {
                  setFieldValue(`state_id`, e ? e : "");
                  setFieldValue(`suburb_id`, "");
                }}
                onBlur={handleBlur}
                options={stateListing}
                placeholder="Select State"
                classNamePrefix="selectbox"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
                isClearable
                isLoading={stateLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="state_id" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Select Suburb</label>

              <SuburbSelect
                name="suburb_id"
                regionName={values?.state_id}
                onChange={(e) => {
                  setFieldValue("suburb_id", e ? e : "");
                }}
                value={values?.suburb_id ? values?.suburb_id : null}
                placeholder={"Select Suburb"}
                isClearable={true}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="suburb_id" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Postal Code</label>
              <InputMask
                type="text"
                name="postal_code"
                value={values?.postal_code ? values?.postal_code : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="postal_code" />
              </span>
            </div>
          </div>
          <div className="address-box">
            <h5>Street Address</h5>
            <div className="form-group" style={{ width: "100%", margin: 0 }}>
              <label
                className="form-lable"
                style={{
                  width: "auto",
                  display: "flex",
                  flexFlow: "wrap",
                  gap: "5px",
                  fontWeight: "bold",
                  maxWidth: "fit-content",
                }}
              >
                <input
                  type="checkbox"
                  name="same_address"
                  checked={isChecked}
                  onBlur={handleBlur}
                  onClick={(e) => {
                    setIsChecked(!isChecked);
                  }}
                  onChange={handleChange}
                  value="10"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  style={{ width: "auto" }}
                />{" "}
                Same as Postal Address
              </label>
            </div>

            <div className="form-group half-width builder-firm">
              <label>Address</label>
              <input
                type="text"
                name="shipping_street"
                value={values?.shipping_street ? values?.shipping_street : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={isChecked === true ? true : false}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="shipping_street" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Select State</label>
              <Select
                name="shipping_state_id"
                value={
                  values?.shipping_state_id ? values?.shipping_state_id : ""
                }
                onChange={(e) => {
                  setFieldValue(`shipping_state_id`, e ? e : "");
                  setFieldValue(`shipping_suburb_id`, "");
                }}
                onBlur={handleBlur}
                options={stateListing}
                placeholder="Select State"
                classNamePrefix="selectbox"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
                isClearable
                isLoading={stateLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                isDisabled={isChecked === true ? true : false}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="shipping_state_id" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Select Suburb</label>

              <SuburbSelect
                name="shipping_suburb_id"
                regionName={values?.shipping_state_id}
                onChange={(e) => {
                  setFieldValue("shipping_suburb_id", e ? e : "");
                }}
                value={
                  values?.shipping_suburb_id ? values?.shipping_suburb_id : null
                }
                placeholder={"Select Suburb"}
                isClearable={true}
                isDisabled={isChecked === true ? true : false}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="shipping_suburb_id" />
              </span>
            </div>
            <div className="form-group half-width builder-firm">
              <label>Postal Code</label>
              <InputMask
                type="text"
                name="shipping_postal_code"
                value={
                  values?.shipping_postal_code
                    ? values?.shipping_postal_code
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isChecked === true ? true : false}
                mask="9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="shipping_postal_code" />
              </span>
            </div>
          </div>

          <div className="project-bank-details-box">
            <h5>Bank Account Details</h5>
            <div className="form-group penta-width builder-firm">
              <label>Bank Name</label>
              <Select
                name="bank_id"
                value={values?.bank_id ? values?.bank_id : ""}
                onChange={(e) => {
                  setFieldValue(`bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={bankListing}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isClearable
                isLoading={bankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="bank_id" />
              </span>
            </div>
            <div className="form-group penta-width builder-firm">
              <label>
                <span className="required">*</span>
                Bank Account Name
              </label>
              <input
                type="text"
                name="account_name"
                value={values?.account_name ? values?.account_name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="account_name" />
              </span>
            </div>
            <div className="form-group penta-width builder-firm">
              <label>
                <span className="required">*</span>BSB
              </label>
              <InputMask
                type="text"
                name="bsb"
                value={values?.bsb ? values?.bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="bsb" />
              </span>
            </div>
            <div className="form-group penta-width builder-firm">
              <label>
                <span className="required">*</span>
                Account Number
              </label>
              <InputMask
                type="text"
                name="account_number"
                value={values?.account_number ? values?.account_number : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="account_number" />
              </span>
            </div>
            {/* <div className="form-group penta-width builder-firm">
              <label>
                Opening Balance($)
              </label>

              <NumberFormat
                type="text"
                name="opening_balance"
                value={values?.opening_balance}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={true}
                isAllowed={(values) => MaxLimit(values)}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="opening_balance" />
              </span>
            </div> */}
          </div>
          {/* </div> */}
        </div>
      </div>
      <div className="entry-card full-card">
        <h3>Subcontractor Contract</h3>
        <div className="form-box">
          <div className="form-group penta-width builder-firm">
            <label>Contract Upload</label>
            <div className="subcontractor-agreement">
              <div className="upload-div">
                <div className="modal-body">
                  {!values?.contract_file && (
                    <div className="upload-file">
                      <input
                        accept={
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/jpg, image/x-png, .doc, .docx, .pdf"
                        }
                        type="file"
                        name="documents"
                        className="form-control"
                        id="documents"
                        onChange={(e) => {
                          e.currentTarget.files[0]?.type ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                          e.currentTarget.files[0]?.type ===
                            "application/vnd.ms-excel" ||
                          e.currentTarget.files[0]?.type === "image/jpeg" ||
                          e.currentTarget.files[0]?.type === "image/jpg" ||
                          e.currentTarget.files[0]?.type === "image/png" ||
                          e.currentTarget.files[0]?.type ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                          e.currentTarget.files[0]?.type ===
                            "application/msword" ||
                          e.currentTarget.files[0]?.type === "application/pdf"
                            ? setFieldValue(
                                "contract_file",
                                e.currentTarget.files[0]
                              )
                            : toast.error(
                                "Please upload .pdf, .doc, docx, .xls, .xlsx, .jpeg, .jpg or   .png only."
                              );
                        }}
                      />
                      <span>
                        <p className="ant-upload-drag-icon">
                          <FontAwesomeIcon icon={faUpload} size="3x" />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </span>
                    </div>
                  )}
                  {values?.contract_file && (
                    <div className="file-names">
                      <p>{`${
                        values?.contract_file?.name
                          ? values?.contract_file?.name
                          : values?.contract_file?.split("/")?.slice(-1)[0]
                      }`}</p>
                      <div className="remove">
                        {values?.contract_file?.name ? (
                          <></>
                        ) : (
                          edit && (
                            <>
                              <CustomTooltip message={"View"}>
                                <span>
                                  <FontAwesomeIcon
                                    icon={faEye}
                                    size="1x"
                                    onClick={() =>
                                      handleView(values?.contract_file)
                                    }
                                    data-toggle="modal"
                                    data-target="#account"
                                  />
                                </span>
                              </CustomTooltip>
                              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                            </>
                          )
                        )}
                        <CustomTooltip message={"Remove"}>
                          <span>
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                setFieldValue("contract_file", "");
                              }}
                            />
                          </span>
                        </CustomTooltip>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group penta-width builder-firm">
            <label>Contract Value($)</label>
            <NumberFormat
              type="text"
              name="contract_value"
              value={values?.contract_value}
              onChange={handleChange}
              onBlur={(e) => {
                if (e?.target?.value === "") {
                  setFieldValue("retention_value", "");
                  setFieldValue("retention_precentage", "");
                } else {
                  if (values?.retention_value && values?.retention_precentage) {
                    let retention_value =
                      (Number(e?.target?.value?.replace(/,/g, "")) *
                        Number(
                          values?.retention_precentage
                            ?.toString()
                            ?.replace("%", "")
                        )) /
                      100;
                    setFieldValue("retention_value", retention_value);
                    setFieldValue(
                      "retention_precentage",
                      (100 *
                        Number(
                          retention_value?.toString()?.replace(/,/g, "")
                        )) /
                        Number(e?.target?.value?.replace(/,/g, ""))
                    );
                  }
                }
                setFieldValue("contract_value", e?.target?.value);
              }}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="contract_value" />
            </span>
          </div>
          <div className="form-group penta-width builder-firm">
            <label>Retention Percentage(%)</label>
            <NumberFormat
              type="text"
              name="retention_precentage"
              suffix={"%"}
              decimalScale={2}
              placeholder="Enter Retention %"
              value={
                values?.retention_precentage ? values?.retention_precentage : ""
              }
              onChange={handleChange}
              onBlur={(e) => {
                setFieldValue("retention_precentage", e?.target?.value);
                if (e?.target?.value === "") {
                  setFieldValue("retention_value", "");
                } else {
                  setFieldValue(
                    "retention_value",
                    (Number(
                      values?.contract_value?.toString()?.replace(/,/g, "")
                    ) *
                      Number(e?.target?.value?.replace("%", ""))) /
                      100
                  );
                }
              }}
              disabled={!values?.contract_value}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (floatValue) {
                  return floatValue <= 100;
                } else {
                  return true;
                }
              }}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_value" />
            </span>
          </div>
          <div className="form-group penta-width builder-firm">
            <label>Retention Amount($)</label>
            <NumberFormat
              type="text"
              name="retention_value"
              value={values?.retention_value}
              onChange={handleChange}
              onBlur={(e) => {
                setFieldValue("retention_value", e?.target?.value);
                if (e?.target?.value === "") {
                  setFieldValue("retention_precentage", "");
                } else {
                  setFieldValue(
                    "retention_precentage",
                    (100 * Number(e?.target?.value?.replace(/,/g, ""))) /
                      Number(
                        values?.contract_value?.toString()?.replace(/,/g, "")
                      )
                  );
                }
              }}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              autoComplete="off"
              disabled={!values?.contract_value}
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_value" />
            </span>
          </div>
        </div>
      </div>
      <div className="action-box">
        <button type="submit">Save</button>
        <CancelButton />
      </div>
    </form>
  );
};

export default Form;
