import React from "react";
import { Modal } from "react-bootstrap";
import SearchContractor from "./SearchContractor";

const SearchContractorForList = ({
  trustType,
  close,
  transaction_type,
  ItemId,
  searchParams,
  Item,
  getBankStatementReconcileData,
  projectId,
  bankReconcilationId,
  searchRecord,
  showSearchPopUp,
  splitContractor,
  setSplitContractor,
}) => {
  return (
    <div>
      <Modal
        footer={null}
        closable
        onCancel={close}
        show={showSearchPopUp}
        // onHide={stay}
        onHide={close}
        size="lg"
        dialogClassName="review-popup small-review "
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section reconcile-list-modal"
        centered
      >
        <Modal.Header closeButton style={{ marginBottom: "10px" }}>
          Select Contractor
        </Modal.Header>
        <>
          <Modal.Body>
            <div className="reconcile-list">
              <SearchContractor
                searchParams={searchParams}
                transaction_type={transaction_type}
                ItemId={ItemId}
                Item={Item}
                close={close}
                getBankStatementReconcileData={getBankStatementReconcileData}
                bankReconcilationId={bankReconcilationId}
                projectId={projectId}
                trustType={trustType}
                splitContractor={splitContractor}
                setSplitContractor={setSplitContractor}
              />
            </div>
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
};

export default SearchContractorForList;
