import { API, get } from "../../../../config";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";

export const getProjectDetail = async (projectId) => {
  try {
    const { data } = await get(`${API.PROJECT_DETAILS}/` + projectId);
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const getProjectDetailWithHeadProject = async (projectId) => {
  try {
    const { data } = await get(`${API.PROJECT_DETAILS}/` + projectId);
    return data;
  } catch (e) {
    return null;
  }
};

export const getProjectPopupDetail = async (projectId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.PROJECT_DETAILS}/${projectId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.PROJECT_DETAILS}/${projectId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useAllBankList = () => {
  const [allBankList, setAllBankList] = useState([]);
  const [allBankLoading, setAllBankLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setAllBankLoading(true);
      const { data } = await get(`${API.GET_BANK_LIST}?all=1`);
      setAllBankLoading(false);
      const accountList =
        data?.data?.data && data?.data?.data?.length > 0
          ? data?.data?.data?.map((item) => {
              const list = {
                label: item?.abbreviation + "-" + item?.name,
                value: item?.id,
                rest: item,
              };
              return list;
            })
          : [];
      setAllBankList(accountList);
    } catch (e) {
      setAllBankList([]);
      setAllBankLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);
  return {
    allBankList,
    allBankLoading,
  };
};
export const useBankList = () => {
  const [bankList, setBankList] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.GET_BANK_LIST}`);
      setBankLoading(false);
      const accountList =
        data?.data?.data && data?.data?.data?.length > 0
          ? data?.data?.data?.map((item) => {
              const list = {
                label: item?.abbreviation + "-" + item?.name,
                value: item?.id,
                rest: item,
              };
              return list;
            })
          : [];
      setBankList(accountList);
    } catch (e) {
      setBankList([]);
      setBankLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    bankList,
    bankLoading,
  };
};
