import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, defaultLimitPagination, get } from "../../../../config";
import Spin from "../../../common/Spin";
import moment from "moment";
// import { Tooltip } from "react-bootstrap";
import Pagination from "../../../common/Pagination";
import BankTransactionActionButton from "./BankTransactionActionButton";
import { formatNumber } from "../../../common/Misc";
import CustomTooltip from "../../../common/CustomTooltip";

const BankTransactionByBatch = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [bankTransactionData, setBankTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const fetchBankTransactionsByBatch = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_BY_BATCHES}/` +
          id +
          `?limit=${limit ? limit : defaultLimitPagination}&page=${
            page ? page : 1
          }`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setBankTransactionData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setBankTransactionData([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [id, limit, page]);

  const handleDelete = () => {
    fetchBankTransactionsByBatch();
  };

  const bankTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionData[i]?.id,
        date: bankTransactionData[i]?.transactionDate
          ? moment(
              bankTransactionData[i]?.transactionDate,
              "DD-MM-YYYY"
            ).format("DD-MM-YYYY")
          : "-",
        description: bankTransactionData[i]?.description
          ? bankTransactionData[i]?.description
          : "-",
        credit:
          bankTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              {"$" + formatNumber(Number(bankTransactionData[i]?.amount))}
            </span>
          ) : (
            "-"
          ),
        debit:
          bankTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              {"$" + formatNumber(Number(bankTransactionData[i]?.amount))}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: bankTransactionData[i]?.source
          ? bankTransactionData[i]?.source
          : "-",
        action: bankTransactionData[i]?.deleted_at ? (
          ""
        ) : (
          <BankTransactionActionButton
            id={bankTransactionData[i]?.id}
            handleDelete={handleDelete}
          />
        ),
        archived: bankTransactionData[i]?.deleted_at ? (
          <div style={{ textAlign: "center" }}>
            <CustomTooltip message={"Archived"}>
              <span>
                <span color={"red"} key={"red"}>
                  {String.fromCodePoint("0x0001F6AB")}
                </span>
              </span>
            </CustomTooltip>
          </div>
        ) : (
          ""
        ),
      });
    }
    return items;
  };

  const bankTransaction = bankTransactionGenerator(bankTransactionData?.length);

  useEffect(() => {
    fetchBankTransactionsByBatch();
  }, [fetchBankTransactionsByBatch]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "date",
      text: "Date",
      // sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      // sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      // sort: true,
    },
    {
      dataField: "bank_transaction_source",
      text: "Bank Transaction Source	",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      // sort: true,
      className: "text-center",
      style: { width: "127px", textAlign: "center" },
    },
    {
      dataField: "archived",
      text: "",
      style: { textAlign: "center" },
      // sort: true,
    },
  ];
  return (
    <>
      <div className="content-details">
        <div className="cms-page audit-statement">
          {loading && <Spin />}
          <BootstrapTable
            keyField="batch_id"
            remote
            data={bankTransaction}
            columns={columns}
            // onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default BankTransactionByBatch;
