import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import Spin from "../../common/Spin";
import { MonthPicker } from "@semcore/date-picker";
import { useParams } from "react-router-dom";
import moment from "moment";
import { formatNumber } from "../../common/Misc";
import { Button } from "react-bootstrap";
import { getProjectData } from "../components/ProjectHelper";

const TrialBalanceReport = ({ dateRangeReport, setDateRangeReport }) => {
  const params = useParams();
  const trustType = params?.trust_type === "project" ? 1 : 0;
  const projectId = params?.project_id;
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);
  const [clientsReportData, setClientsReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportTrialBalanceReportLoading, setExportTrialBalanceReportLoading] =
    useState(false);
  const [valueRange, setValueRange] = useState(moment());

  // const monthRangeDisplay =
  //   moment(valueRange[0]).format("yyyy") ===
  //   moment(valueRange[1]).format("yyyy")
  //     ? moment(valueRange[0]).format("MMM") ===
  //       moment(valueRange[1]).format("MMM")
  //       ? `${moment(valueRange[0]).format("MMM")} ${moment(
  //           valueRange[0]
  //         ).format("yyyy")}`
  //       : `${moment(valueRange[0]).format("MMM")}-${moment(
  //           valueRange[1]
  //         ).format("MMM")}, ${moment(valueRange[0]).format("yyyy")}`
  //     : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
  //         "yyyy"
  //       )} - ${moment(valueRange[1]).format("MMM")} ${moment(
  //         valueRange[1]
  //       ).format("yyyy")}`;

  const getClientsReportData = useCallback(async () => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      // formData.append(
      //   "start_month",
      //   `${
      //     moment(valueRange[0]).format("MM-YYYY")
      //       ? moment(valueRange[0]).format("MM-YYYY")
      //       : ""
      //   }`
      // );
      // formData.append(
      //   "end_month",
      //   `${
      //     moment(valueRange[1]).format("MM-YYYY")
      //       ? moment(valueRange[1]).format("MM-YYYY")
      //       : ""
      //   }`
      // );
      formData.append(
        "end_month",
        `${
          moment(valueRange).format("MM-YYYY")
            ? moment(valueRange).format("MM-YYYY")
            : ""
        }`
      );
      formData.append("export", 0);
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trustType);

      const { data } = await fileUpload(
        `${API.GET_TRIAL_BALANCE_DATA}`,
        formData
      );
      let totalDebit = 0;
      let totalCredit = 0;
      // let builderProjectData = {
      //   bussiness_name: data?.data?.project?.project_name,
      //   debit_amount:
      //     Number(data.data.project.closing_balance) > 0
      //       ? String(data.data.project.closing_balance)
      //       : 0,
      //   credit_amount:
      //     Number(data.data.project.closing_balance) < 0
      //       ? String(data.data.project.closing_balance * -1)
      //       : 0,
      //   type: "firm",
      // };
      // if (Number(data.data.project.closing_balance) > 0) {
      //   builderProjectData.debit_amount = String(
      //     data.data.project.closing_balance
      //   );
      //   totalDebit += Number(data.data.project.closing_balance);
      // } else if (Number(data.data.project.closing_balance) < 0) {
      //   builderProjectData.credit_amount = String(
      //     data.data.project.closing_balance * -1
      //   );
      //   totalCredit += Number(data.data.project.closing_balance * -1);
      // }
      let customerReportData = [];
      if (data?.data?.contacts && data?.data?.contacts.length > 0) {
        customerReportData = data?.data?.contacts.map((item) => {
          // totalCredit += item?.closing_balance ? parseFloat(item?.closing_balance) : 0;
          let obj = {
            ...item,
          };

          obj.debit_amount =
            Number(item.closing_balance) > 0 ? String(item.closing_balance) : 0;
          obj.credit_amount =
            Number(item.closing_balance) < 0
              ? String(item.closing_balance * -1)
              : 0;
          if (Number(item.closing_balance) > 0) {
            totalDebit += Number(item?.closing_balance);
          } else {
            totalCredit += Number(item?.closing_balance * -1);
          }
          return obj;
        });
      }
      let totalData = {
        total_name: "Total",
        total_debit_amount: totalDebit,
        total_credit_amount: totalCredit,
      };
      setClientsReportData([...[].concat(customerReportData), totalData]);
      setIsLoading(false);
    } catch (error) {
      const { data } = error.response;
      setClientsReportData([]);
      setIsLoading(false);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [valueRange, projectId, trustType]);

  useEffect(() => {
    getClientsReportData();
  }, [getClientsReportData]);

  const trialBalanceDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: clientsReportData[i]?.total_name
          ? clientsReportData[i]?.total_name
          : clientsReportData[i]?.net_name
          ? clientsReportData[i]?.net_name
          : clientsReportData[i]?.id
          ? clientsReportData[i]?.id +
            String(clientsReportData[i]?.is_bank_reconciliation)
          : "not-expand-row",
        batch_id: clientsReportData[i]?.total_name ? (
          <div>2</div>
        ) : clientsReportData[i]?.id ? (
          clientsReportData[i]?.id
        ) : (
          ""
        ),
        is_bank_reconciliation: clientsReportData[i]?.is_bank_reconciliation,
        is_cash_transaction: clientsReportData[i]?.is_cash_transaction,
        account: (
          <div
            className={
              clientsReportData[i]?.total_name === "Total"
                ? ""
                : "all-client-name"
            }
          >
            {`${
              clientsReportData[i]?.name
                ? clientsReportData[i]?.name
                : clientsReportData[i]?.business_name
                ? clientsReportData[i]?.business_name
                : clientsReportData[i]?.total_name
                ? clientsReportData[i]?.total_name
                : ""
            }`}
          </div>
        ),
        type: clientsReportData[i]?.type,
        credit: clientsReportData[i]?.total_credit_amount ? (
          `$${formatNumber(Number(clientsReportData[i]?.total_credit_amount))}`
        ) : clientsReportData[i]?.credit_amount ? (
          <span>
            ${formatNumber(Number(clientsReportData[i]?.credit_amount))}
          </span>
        ) : (
          "$0.00"
        ),

        debit: clientsReportData[i]?.total_debit_amount ? (
          `$${formatNumber(Number(clientsReportData[i]?.total_debit_amount))}`
        ) : clientsReportData[i]?.debit_amount ? (
          <span>
            ${formatNumber(Number(clientsReportData[i]?.debit_amount))}
          </span>
        ) : (
          "$0.00"
        ),
      });
    }
    return items;
  };
  const trialBalanceData = trialBalanceDataGenerator(clientsReportData?.length);

  const columns = [
    {
      dataField: "account",
      text: "Account",
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      classes: "trial-balance-debit-cell",
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      classes: "trial-balance-credit-cell",
      headerAlign: (column, colIndex) => "right",
    },
  ];

  let currentDate = new Date();
  let nextMonth = currentDate;
  nextMonth.setMonth(currentDate.getMonth() + 1);
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      children: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
    },
    {
      children: "This Quarter",
      value: quarterRange,
    },
    {
      children: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
    },
    {
      children: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
    },
    {
      children: "Last Quarter",
      value: perviousQuarterRange,
    },
    {
      children: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
    },
  ];

  const handleExportTrialBalanceReport = async () => {
    try {
      setExportTrialBalanceReportLoading(true);
      const formData = new FormData();
      // formData.append(
      //   "start_month",
      //   `${
      //     moment(valueRange[0]).format("MM-YYYY")
      //       ? moment(valueRange[0]).format("MM-YYYY")
      //       : ""
      //   }`
      // );
      // formData.append(
      //   "end_month",
      //   `${
      //     moment(valueRange[1]).format("MM-YYYY")
      //       ? moment(valueRange[1]).format("MM-YYYY")
      //       : ""
      //   }`
      // );
      formData.append(
        "end_month",
        `${
          moment(valueRange).format("MM-YYYY")
            ? moment(valueRange).format("MM-YYYY")
            : ""
        }`
      );
      formData.append("export", 1);
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trustType);
      const { data } = await fileUpload(
        `${API.GET_TRIAL_BALANCE_DATA}`,
        formData
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportTrialBalanceReportLoading(false);
      }
    } catch (e) {
      setExportTrialBalanceReportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page trial-balance-report">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trustType === 0 ? "Retention " : "Project Trust "}Trial Balance
            Report
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details trial-balance-report-details">
            <div className="table-top-btn trial-btn report-header-row">
              <div className="report-name report-period-name">
                <span>
                  {" "}
                  Trial Balance For the period{" "}
                  {`${moment(valueRange).format("MMM")} ${moment(
                    valueRange
                  ).format("yyyy")}`}{" "}
                  (Net Movement)
                </span>
              </div>
              <div className="table-btn">
                {/* <Dropdown autoClose="outside">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportTrialBalanceReportLoading
                      ? "Loading…"
                      : "Export Trial Balance"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>HandiLedger</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "handiLedger"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "handiLedger"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>Final Accounts</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "finalAccounts"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "finalAccounts"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <Button
                  onClick={() => {
                    handleExportTrialBalanceReport();
                  }}
                >
                  {exportTrialBalanceReportLoading ? "Loading..." : "Export"}
                  {/* Export */}
                </Button>
                <div
                  className="summary-filter"
                  style={{
                    padding: "0",
                    border: "none",
                    marginTop: "0px",
                    marginLeft: 20,
                  }}
                >
                  <MonthPicker
                    value={valueRange}
                    onChange={(date) => {
                      setClientsReportData([]);
                      if (date) {
                        setValueRange(date);
                        // if (date[0]?.getMonth() === date[1]?.getMonth()) {
                        //   setDateRangeReport([
                        //     date[0],
                        //     new Date(
                        //       date[1]?.getUTCFullYear(),
                        //       date[1]?.getMonth() + 1,
                        //       0
                        //     ),
                        //   ]);
                        // } else {
                        //   setDateRangeReport([
                        //     date[0],
                        //     new Date(
                        //       date[1]?.getUTCFullYear(),
                        //       date[1]?.getMonth() + 1,
                        //       0
                        //     ),
                        //   ]);
                        // }
                      }
                    }}
                    disabled={[[nextMonth, false]]}
                    periods={dateFilterRange}
                    unclearable={true}
                    className="datefilter"
                    // disabled={disabledFutureDate}
                  />
                </div>
              </div>
            </div>

            <div className="table-wrapper trial-balance-table">
              <div className="custom-table">
                {isLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  columns={columns}
                  // expandRow={expandRow}
                  data={trialBalanceData}
                  noDataIndication="No Data Found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialBalanceReport;
