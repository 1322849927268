import { toast } from "react-toastify";
import { API, get } from "../../../config";

export const getImportLogDetail = async (importLogId, projectId) => {
  try {
    const { data } = await get(`${API.IMPORT_LOGS}/` + importLogId + `?builder_project_id=${
          projectId ? projectId : ""
        }`);
    return data.data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
    return null;
  }
};
