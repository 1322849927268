import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ArchivedTransactions from "./ArchivedTransactions";
import ImportHistory from "./ImportHistory";

const tabsKey = {
  "import-history": 1,
  "archived-transactions": 2,
};

const AuditTrail = ({ setBalanceInfo, ProjectReadOnly }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("child_tab") || "import-history";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Import History"
      : "Archived Transactions"
  );

  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(event?.target?.innerText);
          }}
        >
          <li className={activeTab === "Import History" ? "active" : ""}>
            <Link key={1}>Import History</Link>
          </li>
          <li className={activeTab === "Archived Transactions" ? "active" : ""}>
            <Link key={2}>Archived Transactions</Link>
          </li>
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === "Import History" && (
          <ImportHistory setBalanceInfo={setBalanceInfo} ProjectReadOnly={ProjectReadOnly}/>
        )}
        {activeTab === "Archived Transactions" && (
          <ArchivedTransactions setBalanceInfo={setBalanceInfo} ProjectReadOnly={ProjectReadOnly}/>
        )}
      </div>
    </>
  );
};
export default AuditTrail;
