import { faEdit, faEye, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import SubContractorDelete from "./SubContractorDelete";
import SubContractorDetailsPopup from "./SubContractorDetailsPopup";
import CustomTooltip from "../../../common/CustomTooltip";

const SubContractorActionButton = ({
  id,
  handleDelete,
  ProjectReadOnly,
  toggleSingleSyncOptionModal,
  xeroConnection,
}) => {
  // const [showDelete, setShowDelete] = useState(false);
  const [showView, setShowView] = useState(false);
  const params = useParams();
  const projectId = params?.project_id;

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  // const toggleDeleteModal = () => {
  //   setShowDelete(!showDelete);
  // };
  return (
    <>
      <span>
        <CustomTooltip message={"View"}>
          <span>
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#contractorDetail"
            />
          </span>
        </CustomTooltip>
        {!ProjectReadOnly && (
          <>
            <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
            <CustomTooltip message={"Edit"}>
              <span>
                <a href={`/projects/${projectId}/sub-contractors/edit/${id}`}>
                  <FontAwesomeIcon icon={faEdit} size="1x" />
                </a>
              </span>
            </CustomTooltip>
          </>
        )}
        {!ProjectReadOnly &&
          (xeroConnection ? (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <CustomTooltip message={"Sync"}>
                <span>
                  <FontAwesomeIcon
                    icon={faSync}
                    size="1x"
                    onClick={() => toggleSingleSyncOptionModal(id)}
                  />
                </span>
              </CustomTooltip>
            </>
          ) : (
            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <CustomTooltip message={"Please connect with xero to sync."}>
                <span>
                  <FontAwesomeIcon
                    icon={faSync}
                    size="1x"
                    className="disabled-font-awesome"
                    disabled
                    onClick={() => toggleSingleSyncOptionModal(id)}
                  />
                </span>
              </CustomTooltip>
            </>
          ))}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <FontAwesomeIcon
          onClick={toggleDeleteModal}
          icon={faTrash}
          size="1x"
          data-toggle="modal"
          data-target="#deleteContractor"
        /> */}
      </span>

      {/* {showDelete && (
        <SubContractorDelete
          contractorId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )} */}

      {showView && (
        <SubContractorDetailsPopup
          contractorId={id}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}
    </>
  );
};

export default SubContractorActionButton;
