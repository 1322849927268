/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { API } from "../../../config";
import { get } from "underscore";

export const openingBalanceColumns = (handleDelete, ProjectReadOnly) => {
  return ProjectReadOnly
    ? [
        {
          dataField: "name",
          text: "Name",
          className: "fullname",
          width: "50%",
        },
        {
          dataField: "opening_balance",
          text: "Opening Balance",
          className: "centeralign",
          width: "30%",
        },
      ]
    : [
        {
          dataField: "name",
          text: "Name",
          className: "fullname",
          width: "50%",
        },
        {
          dataField: "opening_balance",
          text: "Opening Balance",
          className: "centeralign",
          width: "30%",
        },
        {
          dataField: "action",
          text: "Action",
          width: "20%",
        },
      ];
};

export const useRelatedTo = () => {
  const [relatedList, setRelatedList] = useState([]);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRelatedLoading(true);
      const { data } = await get(API.GET_CUSTOMERLIST);
      setRelatedLoading(false);
      setRelatedList(data.data.data);
    } catch (e) {
      setRelatedList([]);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    relatedList,
    relatedLoading,
  };
};

export const getOpeningBalanceDetail = async (id) => {
  try {
    const { data } = await get(`${API.OPENING_BALANCE}/` + id);
    return data.data;
  } catch (e) {
    return null;
  }
};
