import React, { useCallback, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import Sitelogo from "../../../../components/Header/Logo";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  yodleeConfigName,
  yodleeFastLink,
  // yodleeDefaultImg,
} from "../../../../config";
import Spin from "../../../common/Spin";
import {
  getProviderBankDetail,
  getYodleeAccessToken,
} from "./components/IntegrationsHelper";
import { YodleeModalForConnectBank } from "./components/YodleeModalForConnectBank";
import { faBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";

const ConnectBank = () => {
  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const projectId = query.get("builder_project_id");
  const params = useParams();
  const projectId = params?.project_id;

  const [loading, setLoading] = useState(false);
  const [openYodlee, setOpenYodlee] = useState(false);
  const [fastLinkData, setFastLinkData] = useState(false);

  const [providerProjectBankData, setProviderProjectBankData] = useState(null);
  const [providerRetentionBankData, setProviderRetentionBankData] =
    useState(null);
  const [accountType, setAccountType] = useState();

  const providerBankData = () => {
    setProviderProjectBankData(null);
    setProviderRetentionBankData(null);
  };

  const getProviderBankData = useCallback(async () => {
    try {
      setLoading(true);
      providerBankData();
      const data = await getProviderBankDetail(projectId);
      data?.data && data?.data?.length > 0
        ? data?.data?.forEach((element) => {
            element?.trust_type === 1 && setProviderProjectBankData(element);
            // : setProviderProjectBankData(null);
            element?.trust_type === 0 && setProviderRetentionBankData(element);
            // : setProviderRetentionBankData(null);
          })
        : providerBankData();

      setLoading(false);
    } catch (e) {
      setProviderProjectBankData(null);
      setProviderRetentionBankData(null);
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    getProviderBankData();
  }, [getProviderBankData]);

  const handleYodleeModal = () => {
    setOpenYodlee(false);
  };

  const handleYodleeControle = async (value) => {
    const config = {
      params: { configName: "" },
    };
    try {
      setAccountType(value);
      setLoading(true);
      const yodleeAccessToken = await getYodleeAccessToken(projectId);
      let accessToken = yodleeAccessToken?.token?.accessToken;
      config.fastLinkURL = yodleeFastLink;
      config.accessToken = `Bearer ${accessToken}`;
      config.params.configName = yodleeConfigName;
      setLoading(false);
      setOpenYodlee(true);
      setFastLinkData(config);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="project-section">
        <div className="header-blocks">
          <header>
            <Sitelogo />
          </header>
          <div className="header-bottom-block"></div>
        </div>

        <div className="cms-page">
          <div className="page-content-block">
            <div className="full-content-block">
              <h1 className="page-title">
                Connect Bank
                <div className="page-title-right"></div>
              </h1>
              <div className="content-details connect-bank">
                {!providerProjectBankData ? (
                  <div className="bank-block">
                    <FontAwesomeIcon icon={faBank} size="5x" />
                    <p>Project Trust</p>
                    <Button
                      onClick={() => {
                        handleYodleeControle("project");
                      }}
                    >
                      Connect Bank
                    </Button>
                  </div>
                ) : (
                  <div className="bank-block">
                    <FontAwesomeIcon icon={faBank} size="5x" />
                    <p>Project Trust</p>
                    <Button disabled>Connected</Button>
                  </div>
                )}

                {!providerRetentionBankData ? (
                  <div className="bank-block">
                    <FontAwesomeIcon icon={faBank} size="5x" />
                    <p>Retention Trust</p>
                    <Button
                      onClick={() => {
                        handleYodleeControle("retention");
                      }}
                    >
                      Connect Bank
                    </Button>
                  </div>
                ) : (
                  <div className="bank-block">
                    <FontAwesomeIcon icon={faBank} size="5x" />
                    <p>Retention Trust</p>
                    <Button disabled>Connected</Button>
                  </div>
                )}

                {loading && <Spin />}
                {window.fastlink.close()}
                {openYodlee && <Modal
                  size="lg"
                  show={openYodlee}
                  onHide={handleYodleeModal}
                  dialogClassName="modal-50w medium-popup review-popup small-review"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header className="mb-0" closeButton>
                    Auto Bank Feeds Account Integration
                  </Modal.Header>
                  <Modal.Body>
                    <YodleeModalForConnectBank
                      handleYodleeModal={handleYodleeModal}
                      fastLinkData={fastLinkData}
                      projectId={projectId}
                      setOpenYodleeLoading={setLoading}
                      accountType={accountType}
                      getProviderBankData={getProviderBankData}
                    />
                  </Modal.Body>
                </Modal>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectBank;
