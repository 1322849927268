import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";
import { toast } from "react-toastify";

const ProcoreProjectSelect = (props) => {
  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    setCompanyName(props?.companyName?.value);
  }, [props?.companyName?.value]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    try {
      const search = searchQuery?.length >= 3 ? searchQuery : "";
      const response = await get(
        `${API.PROCORE_PROJECT_LIST}?companyId=${companyName}&limit=${defaultLimitPagination}&page=${
          page && !search ? page : 1
        }&search=${search ? search : ""}&include_free_companies=true`
      );
      if(response?.data?.data?.current_page === 1){
        toast.success(response?.data?.message);
      }

      const responseJSON = response?.data?.data?.data
        ? response?.data?.data?.data
        : [];

      const totalLength = loadedOptions?.length
        ? loadedOptions?.length + responseJSON?.length
        : responseJSON?.length;

      return {
        options: responseJSON,
        hasMore: totalLength < response?.data?.data?.total,
        additional: {
          page: search ? 2 : page + 1,
        },
      };
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
      return {};
    }
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(companyName)}
      value={props.value || ""}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {option?.name}{" "}
          <span
            style={
              option?.status_name === "Active"
                ? {
                    background: "#0DA500",
                    color: "white",
                    marginLeft: "auto",
                    padding: "4px 6px",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }
                : {
                    background: "#F00",
                    color: "white",
                    marginLeft: "auto",
                    padding: "4px 6px",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }
            }
          >
            {option?.status_name === "Active" ? "Active" : "InActive"}
          </span>
        </div>
      )}
      name={props.name}
      onChange={onChange}
      isSearchable={true}
      classNamePrefix="selectbox"
      placeholder={props?.placeholder}
      additional={{
        page: 1,
      }}
      isDisabled={props.isDisabled}
      isClearable={props.isClearable}
    />
  );
};

ProcoreProjectSelect.propTypes = {
  companyName: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default ProcoreProjectSelect;
