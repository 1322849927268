import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import { DatePicker } from "rsuite";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleHolidaysModal,
  tomorrow,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleHolidaysModal: PropTypes.any,
    officeListing: PropTypes.any,
    officeLoading: PropTypes.any,
    roleLoading: PropTypes.any,
    roleListing: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group tetra-width">
          <label>
            <span className="required">*</span>Date
          </label>
          {/* <DatePicker
            name="date"
            value={values?.date ? values?.date : ""}
            onChange={(e) => {
              setFieldValue("date", e);
            }}
            // disabled={[[tomorrow, false]]}
          /> */}
          <DatePicker
            cleanable={true}
            onClean={() => {
              setFieldValue("date", "");
            }}
            onChange={(e) =>
              setFieldValue(
                "date",
                moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
              )
            }
            format="dd-MM-yyyy"
            value={
              values?.date
                ? String(values?.date)?.includes("-")
                  ? new Date(
                      String(values?.date)?.split("-")[2],
                      String(values?.date)?.split("-")[1] - 1,
                      String(values?.date)?.split("-")[0]
                    )
                  : new Date(values?.date)
                : null
            }
            className="rs-date-body"
            placeholder="Select Date"
            placement="autoVerticalStart"
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="date" />
          </span>
        </div>
        <div className="form-group full-width">
          <label>
            <span className="required">*</span>Name
          </label>
          <input
            type="text"
            name="name"
            value={values?.name ? values?.name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="name" />
          </span>
        </div>
        <div className="form-group full-width">
          <label>
            {/* <span className="required">*</span> */}
            Description
          </label>
          <textarea
            type="text"
            name="description"
            value={values?.description ? values?.description : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
            style={{
              borderRadius: "10px",
              margin: "0 0 15px",
              minHeight: "70px",
            }}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="description" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          type="Button"
          onClick={() => {
            toggleHolidaysModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
