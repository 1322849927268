import React from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Spin from "../../common/Spin";
import cellEditFactory from "react-bootstrap-table2-editor";
import { formatNumber } from "../../common/Misc";

const ExcelEditorPaymentSchedule = ({
  handleExcelModal,
  selectRow,
  ExcelContactColumns,
  handleExcelTableChange,
  excelData,
  handleExcelClose,
  ref,
  showExcelModal,
  isImportLoading,
  selectedRow,
  handleUpload,
}) => {
  const calculateTotalClaimed = () => {
    let claimed_amount = 0;
    for (let i = 0; i < selectedRow.length; i++) {
      let row = excelData.find(
        (item) => item.key === selectedRow[i] && item.schedule_group === 0
      );
      if (row !== undefined) {
        claimed_amount = claimed_amount + Number(row?.claimed_amount);
      }
    }
    return claimed_amount;
  };

  const calculateTotalApproved = () => {
    let approved_amount = 0;
    for (let i = 0; i < selectedRow.length; i++) {
      let row = excelData.find(
        (item) => item.key === selectedRow[i] && item.schedule_group === 0
      );
      if (row !== undefined) {
        approved_amount = approved_amount + Number(row?.approved_amount);
      }
    }
    return approved_amount;
  };
  return (
    <Modal
      // size="xl"
      show={showExcelModal}
      onHide={handleExcelClose}
      dialogClassName="full-popup large-popup project-section"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Import Payment Scheduled
      </Modal.Header>
      <Modal.Body>
        <div className="excel-table-list" style={{ padding: "20px" }}>
          {isImportLoading && <Spin />}
          <BootstrapTable
            keyField="key"
            selectRow={selectRow}
            ref={ref}
            remote={{ cellEdit: true }}
            data={excelData ? excelData : []}
            columns={ExcelContactColumns}
            noDataIndication="No Data Found"
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              timeToCloseMessage: 30000,
            })}
            onTableChange={handleExcelTableChange}
          />
          <div className="excel-list-footer">
            <Button
              variant="primary"
              onClick={handleUpload}
              data-toggle="modal"
              data-target="#business"
              disabled={selectedRow.length === 0 ? true : false}
            >
              Import
            </Button>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div>
                  Total Claimed Amount: <span><b>${formatNumber(calculateTotalClaimed())}</b></span>
                </div>
                <div>
                  Total Approved Amount: <span><b>${formatNumber(calculateTotalApproved())}</b></span>
                </div>
                <div>Selected Records: {selectedRow?.length}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExcelEditorPaymentSchedule;
