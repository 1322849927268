import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { imageDefaultPrefixPath, ProjectStatus } from "../../../../config";
import ImageCropModal from "../../../common/ImageCropModal";
import SuburbSelect from "../../../common/SuburbSelect";
import ProjectSelect from "../../../common/ProjectSelect";
// import { DatePicker } from "@semcore/date-picker";
import NumberFormat from "react-number-format";
import { getProjectPopupDetail } from "./ProjectsHelper";
import { MaxLimit } from "../../../common/Misc";
import BuilderSelect from "../../../common/BuilderSelect";
import { MultiSelect } from "primereact/multiselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { DatePicker } from "rsuite";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleProjectModal,
  stateListing,
  stateLoading,
  bankListing,
  bankLoading,
  builderId,
  totalProject,
  projectId,
  setLoading,
  builderId1,
  setBuilderId1,
  totalProjects,
  allBankLoading,
  allBanks,
  officeListing,
  officeLoading,
  selectedOffices,
  setSelectedOffices,
  userListing,
  userLoading,
  setSelectedUsers,
  selectedUsers,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleProjectModal: PropTypes.any,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    bankListing: PropTypes.any,
    bankLoading: PropTypes.any,
    builderId: PropTypes.any,
    totalProject: PropTypes.any,
    projectId: PropTypes.any,
    setLoading: PropTypes.any,
    builderId1: PropTypes.any,
    setBuilderId1: PropTypes.any,
    totalProjects: PropTypes.any,
    allBankLoading: PropTypes.any,
    allBanks: PropTypes.any,
  };

  const [RTADisable, setRTADisable] = useState(false);

  const [showModal, setShowModal] = useState(false);
  // const [startDate, setStartDate] = useState(values?.start_date);

  const profilePic = values?.logo ? values?.logo : "";

  const [selectedFile, setSelectedFile] = useState(profilePic);
  const [imageType, setImageType] = useState();
  const imageInputRef = React.useRef();

  useEffect(() => {
    values?.logo &&
      typeof values?.logo === "string" &&
      setSelectedFile(values?.logo);
  }, [values]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  const handleRemoveIcon = () => {
    setSelectedFile("");
    imageInputRef.current.value = "";
  };

  const handleRTAChange = async (project) => {
    if (project) {
      setRTADisable(true);
      setFieldValue("rta_project", project);
      setLoading(true);
      const projectDetailData = await getProjectPopupDetail(project?.id, true);
      setFieldValue(
        "retention_account_name",
        projectDetailData?.rta_contractor?.account_name
          ? projectDetailData?.rta_contractor?.account_name
          : ""
      );
      setFieldValue(
        "retention_bsb",
        projectDetailData?.rta_contractor?.bsb
          ? projectDetailData?.rta_contractor?.bsb
          : ""
      );
      setFieldValue(
        "retention_account_number",
        projectDetailData?.rta_contractor?.account_number
          ? projectDetailData?.rta_contractor?.account_number
          : ""
      );
      setFieldValue(
        "retention_bank_id",
        projectDetailData?.rta_contractor?.bank
          ? {
              label:
                projectDetailData?.rta_contractor?.bank?.abbreviation +
                "-" +
                projectDetailData?.rta_contractor?.bank?.name,
              value: projectDetailData?.rta_contractor?.bank?.id,
              rest: projectDetailData?.rta_contractor?.bank,
            }
          : null
      );
      setFieldValue(
        "retention_transaction_start_date",
        projectDetailData?.rta_contractor?.transaction_start_date
          ? projectDetailData?.rta_contractor?.transaction_start_date
          : ""
      );
      setFieldValue(
        "retention_apca_id",
        projectDetailData?.rta_contractor?.apca_id
          ? projectDetailData?.rta_contractor?.apca_id
          : "000000"
      );
      setFieldValue(
        "retention_opening_balance",
        projectDetailData?.rta_contractor?.opening_balance
          ? projectDetailData?.rta_contractor?.opening_balance
          : 0
      );
      setLoading(false);
    } else {
      setRTADisable(false);
      setFieldValue("retention_transaction_start_date", "");
      setFieldValue("retention_apca_id", "");
      setFieldValue("retention_opening_balance", "");
      setFieldValue("rta_project", null);
      setFieldValue("retention_account_name", "");
      setFieldValue("retention_bsb", "");
      setFieldValue("retention_account_number", "");
      setFieldValue("retention_bank_id", null);
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleOfficeChange = (e, setFieldValue) => {
    setFieldValue("office_ids", e.value);
    setSelectedOffices(e.value);
  };

  const filterOfficeInputRef = useRef();

  const handleOfficeApplyFilter = () => {
    filterOfficeInputRef.current.hide();
  };

  const officeResetFunction = () => {
    setSelectedOffices([]);
    filterOfficeInputRef && filterOfficeInputRef.current.focus();
    filterOfficeInputRef.current.hide();
  };

  const filterUserInputRef = useRef();

  const handleUserApplyFilter = () => {
    filterUserInputRef.current.hide();
  };

  const userResetFunction = () => {
    setSelectedUsers([]);
    filterUserInputRef && filterUserInputRef.current.focus();
    filterUserInputRef.current.hide();
  };

  const handleUserChange = (e, setFieldValue) => {
    setFieldValue("user_ids", e.value);
    setSelectedUsers(e.value);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="modal-content-details justify-center">
        <div className="profile full">
          <div className="form-group half-width profile-photo">
            <input
              accept="image/*"
              type="file"
              name="logo"
              className="form-control"
              id="file"
              ref={imageInputRef}
              onChange={(e) => {
                e.currentTarget.files[0].type &&
                  setImageType(e.currentTarget.files[0].type);
                e.currentTarget.files[0] && setShowModal(true);
                // setFieldValue(`logo`, e.currentTarget.files[0]);
                setSelectedFile(URL.createObjectURL(e.currentTarget.files[0]));
                // handleChange(e);
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            {selectedFile &&
              (selectedFile === `${imageDefaultPrefixPath}null` ? (
                <></>
              ) : selectedFile === `${imageDefaultPrefixPath}undefined` ? (
                <></>
              ) : selectedFile === `${imageDefaultPrefixPath}` ? (
                <></>
              ) : (
                <div className="previewImage d-flex">
                  {/* {setFieldValue("logo", selectedFile)} */}

                  <img
                    className="display-image"
                    src={selectedFile}
                    alt="selectedFile"
                  />
                  {/* <span
                    className="remove-image"
                    onClick={() => {
                      handleRemoveIcon(setFieldValue);
                      setFieldValue("logo", null);
                    }}
                  >
                    Remove Logo
                    <i className="fa fa-times" aria-hidden="true" />
                  </span> */}
                  <div className="photo-upload-action d-flex">
                    <div className="edit-photo-input">
                      <input
                        accept="image/*"
                        type="file"
                        name="profile_pic"
                        className="form-control"
                        id="file"
                        ref={imageInputRef}
                        onChange={(e) => {
                          e.currentTarget.files[0].type &&
                            setImageType(e.currentTarget.files[0].type);
                          e.currentTarget.files[0] && setShowModal(true);
                          setSelectedFile(
                            URL.createObjectURL(e.currentTarget.files[0])
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        handleRemoveIcon(setFieldValue);
                        setFieldValue("logo", null);
                      }}
                    />
                  </div>
                </div>
              ))}

            {!selectedFile && <span className="guide_leb">Add your Logo</span>}
          </div>
        </div>
      </div>

      <div className="form-box">
        {builderId ? (
          ""
        ) : (
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Builder Select
            </label>
            <BuilderSelect
              regionName={"0"}
              onChange={(e) => {
                setBuilderId1(e?.builder?.id);
              }}
              name="builder"
              placeholder={"Select Builder"}
              isClearable={false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="builder" />
            </span>
          </div>
        )}
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Project Name
          </label>
          <input
            type="text"
            name="project_name"
            value={values?.project_name ? values?.project_name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="project_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Email
          </label>
          <input
            type="text"
            name="email"
            value={values?.email ? values?.email : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Phone No.</label>
          <InputMask
            type="text"
            name="phone"
            value={values?.phone ? values?.phone : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="99 9999 9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="phone" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Mobile No.</label>
          <InputMask
            type="text"
            name="mobile"
            value={values?.mobile ? values?.mobile : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="99 9999 9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="mobile" />
          </span>
        </div>

        <div className="form-group half-width aba-input-field">
          <label>ABN</label>
          <InputMask
            type="text"
            name="abn"
            value={values?.abn ? values?.abn : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="99 999 999 999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <div className="icon">
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className="aba-tooltip"
              onClick={() =>
                window.open(
                  values?.abn
                    ? `https://abr.business.gov.au/ABN/View?id=${values?.abn.replace(
                        / /g,
                        ""
                      )}`
                    : `https://abr.business.gov.au`,
                  "_blank"
                )
              }
            />
          </div>
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="abn" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>Office</label>
          <MultiSelect
            value={selectedOffices}
            selectedItemsLabel={`${selectedOffices?.length} Office(s) Selected`}
            name="office_ids"
            onChange={(e) => {
              handleOfficeChange(e, setFieldValue);
            }}
            options={
              officeListing
                ? officeListing
                : { label: "Add New Office", value: "new" }
            }
            optionLabel="label"
            // filter
            placeholder="Select Office"
            maxSelectedLabels={0}
            ref={filterOfficeInputRef}
            panelFooterTemplate={
              <>
                <hr />
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    handleOfficeApplyFilter();
                  }}
                >
                  OK
                </Button>
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    officeResetFunction();
                  }}
                >
                  Reset
                </Button>
              </>
            }
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_ids" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>User</label>
          <MultiSelect
            value={selectedUsers}
            selectedItemsLabel={`${selectedUsers?.length} User(s) Selected`}
            name="user_ids"
            onChange={(e) => {
              handleUserChange(e, setFieldValue);
            }}
            options={userListing}
            optionLabel="label"
            // filter
            placeholder="Select User"
            maxSelectedLabels={0}
            ref={filterUserInputRef}
            panelFooterTemplate={
              <>
                <hr />
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    handleUserApplyFilter();
                  }}
                >
                  OK
                </Button>
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    userResetFunction();
                  }}
                >
                  Reset
                </Button>
              </>
            }
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_ids" />
          </span>
        </div>

        <div className="address-box">
          <h5>Project Location</h5>
          <div className="form-group half-width">
            <label>Address</label>
            <input
              type="text"
              name="street"
              value={values?.street ? values?.street : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="street" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Select State</label>
            <Select
              name="state_id"
              value={values?.state_id ? values?.state_id : ""}
              onChange={(e) => {
                setFieldValue(`state_id`, e ? e : "");
                setFieldValue(`suburb_id`, "");
              }}
              onBlur={handleBlur}
              options={stateListing}
              placeholder="Select State"
              classNamePrefix="selectbox"
              isClearable
              isLoading={stateLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />

            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="state_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Select Suburb</label>

            <SuburbSelect
              name="suburb_id"
              regionName={values?.state_id}
              onChange={(e) => {
                setFieldValue("suburb_id", e ? e : "");
              }}
              value={values?.suburb_id ? values?.suburb_id : null}
              placeholder={"Select Suburb"}
              isClearable={true}
            />

            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="suburb_id" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Postal Code</label>
            <InputMask
              type="text"
              name="postal_code"
              value={values?.postal_code ? values?.postal_code : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="postal_code" />
            </span>
          </div>
        </div>

        <div className="project-bank-details-box">
          <h5>Bank Account Details</h5>

          <h6>Project Trust Bank Account</h6>

          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Bank Name
            </label>
            <Select
              name="project_bank_id"
              value={values?.project_bank_id ? values?.project_bank_id : ""}
              onChange={(e) => {
                setFieldValue(`project_bank_id`, e ? e : "");
                setFieldValue(
                  `project_apca_id`,
                  padWithLeadingZeros(
                    Number(e?.rest?.apca_id ? e?.rest?.apca_id : 0),
                    6
                  )
                );
              }}
              onBlur={handleBlur}
              // options={bankListing}
              options={
                bankListing?.length > 0
                  ? bankListing?.filter((item) => item.value !== "")
                  : []
              }
              placeholder="Select Bank"
              classNamePrefix="selectbox"
              isLoading={bankLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_bank_id" />
            </span>
          </div>

          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Bank Account Name
            </label>
            <input
              type="text"
              name="project_account_name"
              value={
                values?.project_account_name ? values?.project_account_name : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_account_name" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>BSB
            </label>
            <InputMask
              type="text"
              name="project_bsb"
              value={values?.project_bsb ? values?.project_bsb : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="999-999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_bsb" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Account Number
            </label>
            <InputMask
              type="text"
              name="project_account_number"
              value={
                values?.project_account_number
                  ? values?.project_account_number
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              mask="999999999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_account_number" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>APCA ID
              <div className="apca-info">
                <DropdownButton className="info-icon">
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`project_apca_id`, "000000");
                      }}
                    >
                      ANZ - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`project_apca_id`, "000000");
                      }}
                    >
                      Westpac - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`project_apca_id`, "000000");
                      }}
                    >
                      NAB - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`project_apca_id`, "301500");
                      }}
                    >
                      CBA - 301500
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`project_apca_id`, "651966");
                      }}
                    >
                      BBL - 651966
                    </p>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </label>
            <InputMask
              mask="999999"
              name="project_apca_id"
              value={values?.project_apca_id ? values?.project_apca_id : ""}
              placeholder="APCA ID"
              maskChar=""
              onChange={(e) => {
                setFieldValue(
                  `project_apca_id`,
                  e?.target?.value ? e?.target?.value : ""
                );
              }}
              onBlur={(e) => {
                setFieldValue(
                  `project_apca_id`,
                  e?.target?.value
                    ? padWithLeadingZeros(e?.target?.value, 6)
                    : ""
                );
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_apca_id" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              {/* <span className="required">*</span> */}
              Opening Balance($)
            </label>
            <NumberFormat
              type="text"
              name="project_opening_balance"
              value={values?.project_opening_balance}
              onChange={handleChange}
              onBlur={handleBlur}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              isAllowed={(values) => MaxLimit(values)}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_opening_balance" />
            </span>
          </div>
          <hr className="hr-content" />
          <h6>Retention Trust Bank Account</h6>
          {totalProject && totalProject > 0 ? (
            <React.Fragment>
              <div className="form-group half-width retention-bank-details">
                <label>Select existing project's RTA</label>
                <ProjectSelect
                  name="rta_project"
                  regionName={builderId ? builderId : "0"}
                  onChange={(project) => {
                    handleRTAChange(project);
                  }}
                  value={values?.rta_project ? values?.rta_project : null}
                  placeholder={"Select Project"}
                  className="selectbox"
                  builderId={builderId}
                  isClearable={true}
                  rta_project={1}
                  projectId={projectId}
                />
              </div>
              {/* <p>OR</p> */}
              <hr className="hr-text" data-content="OR" />
            </React.Fragment>
          ) : (
            <></>
          )}
          {totalProjects && totalProjects > 0 ? (
            <React.Fragment>
              <div className="form-group half-width retention-bank-details">
                <label>Select existing project's RTA</label>
                <ProjectSelect
                  name="rta_project"
                  regionName={builderId1 ? builderId1 : "0"}
                  onChange={(project) => {
                    handleRTAChange(project);
                  }}
                  value={values?.rta_project ? values?.rta_project : null}
                  placeholder={"Select Project"}
                  className="selectbox"
                  builderId={builderId1}
                  isClearable={true}
                  rta_project={1}
                  projectId={projectId}
                />
              </div>
              {/* <p>OR</p> */}
              <hr className="hr-text" data-content="OR" />
            </React.Fragment>
          ) : (
            <></>
          )}
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Bank Name
            </label>
            <Select
              name="retention_bank_id"
              value={values?.retention_bank_id ? values?.retention_bank_id : ""}
              onChange={(e) => {
                setFieldValue(`retention_bank_id`, e ? e : "");
                setFieldValue(
                  `retention_apca_id`,
                  padWithLeadingZeros(
                    Number(e?.rest?.apca_id ? e?.rest?.apca_id : 0),
                    6
                  )
                );
                if (e?.label === "Don't have a Retention Trust A/C") {
                  setFieldValue(
                    `retention_account_name`,
                    "Retention Trust Account"
                  );
                  setFieldValue(`retention_bsb`, "000000");
                  setFieldValue(`retention_account_number`, "000000001");
                  setFieldValue(`retention_apca_id`, "000000");
                  setFieldValue(`retention_opening_balance`, "0");
                }
              }}
              onBlur={handleBlur}
              options={bankListing}
              placeholder="Select Bank"
              classNamePrefix="selectbox"
              isLoading={bankLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "grey",
                },
              })}
              isDisabled={RTADisable || values?.rta_project ? true : false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_bank_id" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Bank Account Name
            </label>
            <input
              type="text"
              name="retention_account_name"
              value={
                values?.retention_account_name
                  ? values?.retention_account_name
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={RTADisable || values?.rta_project ? true : false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_account_name" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>BSB
            </label>
            <InputMask
              type="text"
              name="retention_bsb"
              value={values?.retention_bsb ? values?.retention_bsb : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="999-999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={RTADisable || values?.rta_project ? true : false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_bsb" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>Account Number
            </label>
            <InputMask
              type="text"
              name="retention_account_number"
              value={
                values?.retention_account_number
                  ? values?.retention_account_number
                  : ""
              }
              onChange={handleChange}
              onBlur={handleBlur}
              mask="999999999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              disabled={RTADisable || values?.rta_project ? true : false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_account_number" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              <span className="required">*</span>APCA ID
              <div className="apca-info">
                <DropdownButton className="info-icon">
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`retention_apca_id`, "000000");
                      }}
                    >
                      ANZ - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`retention_apca_id`, "000000");
                      }}
                    >
                      Westpac - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`retention_apca_id`, "000000");
                      }}
                    >
                      NAB - 000000
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`retention_apca_id`, "301500");
                      }}
                    >
                      CBA - 301500
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <p
                      onClick={() => {
                        setFieldValue(`retention_apca_id`, "651966");
                      }}
                    >
                      BBL - 651966
                    </p>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </label>
            <InputMask
              mask="999999"
              name="retention_apca_id"
              value={values?.retention_apca_id ? values?.retention_apca_id : ""}
              placeholder="APCA ID"
              maskChar=""
              onChange={(e) => {
                setFieldValue(
                  `retention_apca_id`,
                  e?.target?.value ? e?.target?.value : ""
                );
              }}
              isDisabled={RTADisable || values?.rta_project ? true : false}
              onBlur={(e) => {
                setFieldValue(
                  `retention_apca_id`,
                  e?.target?.value
                    ? padWithLeadingZeros(e?.target?.value, 6)
                    : ""
                );
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_apca_id" />
            </span>
          </div>
          <div className="form-group tri-width">
            <label>
              {/* <span className="required">*</span> */}
              Opening Balance($)
            </label>
            <NumberFormat
              type="text"
              name="retention_opening_balance"
              value={values?.retention_opening_balance}
              onChange={handleChange}
              onBlur={handleBlur}
              decimalScale={2}
              isAllowed={(values) => MaxLimit(values)}
              allowNegative={false}
              thousandSeparator={true}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              // isDisabled={RTADisable || values?.rta_project ? true : false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="retention_opening_balance" />
            </span>
          </div>
        </div>
        <div className="key-metrics-box">
          <h5>Key Metrics</h5>
          <div className="form-group half-width">
            <label>Project Start Date</label>
            {/* <DatePicker
              name="start_date"
              value={values?.start_date ? values?.start_date : ""}
              onChange={(e) => {
                setFieldValue("start_date", e);
                setStartDate(e);
              }}
              placement="top"
            /> */}
            <DatePicker
              cleanable={true}
              onClean={() => {
                setFieldValue("start_date", "");
              }}
              onChange={(e) => {
                setFieldValue(
                  "start_date",
                  moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                );
                // setStartDate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
              }}
              format="dd-MM-yyyy"
              value={
                values?.start_date
                  ? String(values?.start_date)?.includes("-")
                    ? new Date(
                        String(values?.start_date)?.split("-")[2],
                        String(values?.start_date)?.split("-")[1] - 1,
                        String(values?.start_date)?.split("-")[0]
                      )
                    : new Date(values?.start_date)
                  : null
              }
              className="rs-date-body"
              placeholder="Select Start Date"
              placement="autoVerticalStart"
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="start_date" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>Project End Date</label>
            {/* <DatePicker
              name="end_date"
              value={values?.end_date ? values?.end_date : ""}
              disabled={[[startDate, true]]}
              onChange={(e) => {
                setFieldValue("end_date", e);
                // setEndDate(e);
              }}
              placement="top"
            /> */}
            <DatePicker
              cleanable={true}
              onClean={() => {
                setFieldValue("end_date", "");
              }}
              onChange={(e) =>
                setFieldValue(
                  "end_date",
                  moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                )
              }
              format="dd-MM-yyyy"
              value={
                values?.end_date
                  ? String(values?.end_date)?.includes("-")
                    ? new Date(
                        String(values?.end_date)?.split("-")[2],
                        String(values?.end_date)?.split("-")[1] - 1,
                        String(values?.end_date)?.split("-")[0]
                      )
                    : new Date(values?.end_date)
                  : null
              }
              disabledDate={(date) =>
                moment(date).isBefore(moment(values?.start_date, "DD-MM-YYYY"))
              }
              className="rs-date-body"
              placeholder="Select End Date"
              placement="autoVerticalStart"
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="end_date" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Estimated Project Cost($)
            </label>
            <NumberFormat
              type="text"
              name="estimated_cost"
              value={values?.estimated_cost}
              onChange={handleChange}
              onBlur={handleBlur}
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={true}
              autoComplete="off"
              isAllowed={(values) => MaxLimit(values)}
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="estimated_cost" />
            </span>
          </div>
          <div className="form-group half-width">
            <label>
              <span className="required">*</span>Project Status
            </label>
            <Select
              name="project_status"
              value={values?.project_status ? values?.project_status : ""}
              onChange={(e) => {
                setFieldValue(`project_status`, e ? e : "");
              }}
              onBlur={handleBlur}
              options={ProjectStatus}
              placeholder="Select Project Status"
              classNamePrefix="selectbox"
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_status" />
            </span>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          type="Button"
          onClick={() => {
            toggleProjectModal();
          }}
        >
          Cancel
        </Button>
      </div>
      {showModal && (
        <ImageCropModal
          imageType={imageType}
          selectedFile={selectedFile}
          setShowModal={setShowModal}
          setSelectedFile={setSelectedFile}
          showModal={showModal}
          setFieldValue={setFieldValue}
        />
      )}
    </form>
  );
};

export default Form;
