import { toast } from "react-toastify";
import { API, deleteCall, get, post } from "../../../../../config";
import { useCallback, useState } from "react";

export const getProjectDetail = async (projectId) => {
  try {
    const { data } = await get(`${API.PROJECT_DETAILS}/` + projectId);
    return data.data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

export const getProviderBankDetail = async (projectId) => {
  try {
    const { data } = await get(
      `${API.GET_PROVIDERS_BANK}?builder_project_id=${projectId}`
    );
    return data;
  } catch (e) {
    const errors = e.response?.data?.errors;
    Object.keys(errors).forEach((key) => {
      toast.error(errors[key][0]);
    });
  }
};

export const getYodleeAccessToken = async (projectId) => {
  try {
    const { data } = await post(`${API.YODLEE_GET_TOKEN}`, {
      builder_project_id: projectId,
    });
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const createUserBasiq = async (projectId) => {
  try {
    const { data } = await post(`${API.BASIQ_CREATE_USER}`, {
      builder_project_id: projectId,
    });
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const disconnectProviderBank = async (projectId, providerId) => {
  try {
    const { data } = await get(
      `${API.PROVIDER_BANK_DISCONNECT}?builder_project_id=${projectId}&provider_id=${providerId}`
    );
    return data;
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
  }
};

export const disconnectProCore = async () => {
  try {
    const { data } = await deleteCall(API.PROCORE_DISCONNECT);
    return data;
  } catch (e) {
    return null;
  }
};

export const disconnectXero = async (builderId) => {
  try {
    const { data } = await deleteCall(`${API.XERO_DISCONNECT}${builderId && '?builder_id=' + builderId}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const fetchAccountConnectionData = async (builderId) => {
  try {
    const { data } = await get(
      `${API.GENERAL_SETTING_INTEGRATION}?domain=${window.location.origin}${builderId && '&builder_id=' + builderId}`
    );
    return data?.data;
  } catch (e) {
    return null;
  }
};

export const useProcoreCompanyList = () => {
  const [procoreCompanyList, setProcoreCompanyList] = useState([]);
  const [procoreCompanyLoading, setProcoreCompanyLoading] = useState(false);
  const fetchCompany = useCallback(async () => {
    try {
      setProcoreCompanyLoading(true);
      const { data } = await get(
        `${API.PROCORE_COMPANY_LIST}?page=1&limit=-1&include_free_companies=true`
      );
      setProcoreCompanyLoading(false);
      setProcoreCompanyList(data?.data);
      toast.success(data?.message);
    } catch (error) {
      setProcoreCompanyList([]);
      setProcoreCompanyLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, []);

  return {
    procoreCompanyList,
    procoreCompanyLoading,
    fetchCompany
  };
};
