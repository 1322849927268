import React, { useRef } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import {
  getUserDetail,
  useOfficeList,
  useProjectList,
  // useRoleList,
} from "./components/BuilderUserHelper";
import { useEffect } from "react";
import Spin from "../../common/Spin";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { AddBuilderUserSchema } from "../../common/Validation";
import { formatOfficeName } from "../../common/Misc";

const EditBuilderUser = ({ userId, toggleUserModal, showUser, builderId }) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  //Office List
  const { officeLoading, officeList = [] } = useOfficeList(builderId);
  const officeListing = officeList?.map((item) => {
    const name = formatOfficeName(item?.street, item?.suburb?.name);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  // const office = officeListing?.find(
  //   (item) =>
  //     item?.value ===
  //     (userData?.office_id ? userData?.office_id : userData?.staff?.office_id)
  // );

  // const { roleLoading, roleList = [] } = useRoleList();
  // const roleListing = roleList?.map((item) => {
  //   const name = item?.name;
  //   const id = item?.id;
  //   const items = { name, id };
  //   const { name: label, id: value, ...rest } = items;
  //   return { label, value, ...rest };
  // });
  // const role = roleListing?.find((item) => item?.value === userData?.role_id);

  //Project List
  const { projectLoading, projectList = [] } = useProjectList(builderId);
  const projectListing = projectList?.map((item) => {
    const name = item?.project_name;
    const id = item?.id;
    const items = { name, id, ...item };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });
  // const projectListData = projectListing?.find(
  //   (item) =>
  //     item?.value ===
  //     (userData?.project_id
  //       ? userData?.project_id
  //       : userData?.staff?.project_id)
  // );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.role_id = value?.role_id?.value ? value?.role_id?.value : "";
    value.office_id = value?.office_id?.value ? value?.office_id?.value : "";
    value.is_admin_user = value?.is_admin_user === true ? 1 : 0;

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append("role_id", value?.role_id ? value?.role_id : "");
    // formData.append("office_id", value?.office_id ? value?.office_id : "");
    formData.append("is_admin_user", value?.is_admin_user);
    if (selectedOffices) {
      if (selectedOffices.length > 0) {
        for (let i = 0; i < selectedOffices.length; i++) {
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }
    if (selectedProjects) {
      if (selectedProjects.length > 0) {
        for (let i = 0; i < selectedProjects.length; i++) {
          formData.append(`builder_project_ids[${i}]`, selectedProjects[i]);
        }
      }
    }
    formData.append("_method", "put");

    builderId && formData.append("builder_id", builderId);

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BUILDER_USER}/${userId}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleUserModal();
        setSelectedProjects([]);
        setSelectedOffices([]);
      }
      return data?.data;
    } catch (error) {
      setUserData(value);
      setLoading(false);
      setSelectedProjects([]);
      setSelectedOffices([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const data = await getUserDetail(userId);
        setUserData(data);
        setSelectedProjects(data?.builder_project_ids);
        setSelectedOffices(data?.office_ids);
        setLoading(false);
      } catch (e) {
        setUserData(null);
      }
    }
    fetchData();
  }, [userId]);

  return (
    <>
      <Modal
        size="lg"
        show={showUser}
        onHide={toggleUserModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          User Details
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              first_name: userData?.first_name ? userData?.first_name : "",
              last_name: userData?.last_name ? userData?.last_name : "",
              email: userData?.email ? userData?.email : "",
              // role_id: role,
              // office_id: office,
              is_admin_user: userData?.is_admin_user
                ? userData?.is_admin_user
                : userData?.staff?.is_admin_user
                ? userData?.staff?.is_admin_user === 1
                  ? true
                  : false
                : false,
              office_ids: selectedOffices,
              builder_project_ids: selectedProjects,
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddBuilderUserSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || projectLoading || officeLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleUserModal={toggleUserModal}
                    // roleListing={roleListing}
                    // roleLoading={roleLoading}
                    officeListing={officeListing}
                    officeLoading={officeLoading}
                    projectListing={projectListing}
                    projectLoading={projectLoading}
                    selectedOffices={selectedOffices}
                    setSelectedOffices={setSelectedOffices}
                    selectedProjects={selectedProjects}
                    setSelectedProjects={setSelectedProjects}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditBuilderUser;
