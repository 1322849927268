import React, { useState } from "react";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../config.js";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../common/Spin.js";
import { useParams } from "react-router-dom";

const DeleteOpeningBalance = ({
  id,
  toggleDeleteModal,
  type,
  handleDelete,
  showDelete,
}) => {
  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [loading, setLoading] = useState(false);
  const handleOpeningBalanceDelete = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("trust_type", trust_type);
      formData.append("sub_contractor_id", id);
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append("_method", "DELETE");
      const { data } = await fileUpload(`${API.OPENING_BALANCE}`, formData);
      toast.success(data.message);
      setLoading(false);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        size="lg"
        show={showDelete}
        onHide={toggleDeleteModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Delete Subcontractor OpeningBalance
        </Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="modal-body">
            <p>Are your sure you want to delete this opening balance?</p>
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                toggleDeleteModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={handleOpeningBalanceDelete}
            >
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteOpeningBalance;
