import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { API, deleteCall } from "../../../config";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../common/Spin";

const NotImportedDelete = ({ handleDelete, id }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const handleNotImportedDelete = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.IMPORT_LOGS}/${id}`);
      setLoading(false);
      toast.success(data.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      const { data } = e;
      toggleDeleteModal();
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  };
  return (
    <>
      <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
      <FontAwesomeIcon onClick={toggleDeleteModal} icon={faTrash} size="1x" />
      {showDelete && (
        <>
          <Modal
            size="lg"
            show={showDelete}
            onHide={toggleDeleteModal}
            dialogClassName="modal-50w small-popup review-popup small-review"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="mb-0" closeButton>
              Delete Holidays
            </Modal.Header>
            <Modal.Body>
              {loading && <Spin />}
              <div className="modal-body">
                Are your sure you want to delete permanently this not imported
                log?
              </div>
              <div className="modal-footer">
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => {
                    toggleDeleteModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    handleNotImportedDelete();
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default NotImportedDelete;
