import React, { useState, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { API, defaultLimitPagination, get } from "../../../config";
import moment from "moment";
import Spin from "../../common/Spin";

import Pagination from "../../common/Pagination";
import { formatABN, formatMobile, formatPhone } from "../../common/Misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import ImportLogDetail from "./ImportLogDetail";
import NotImportedDelete from "./NotImportedDelete";
import { Button, Form, FormControl } from "react-bootstrap";
import { debounce } from "underscore";

const NotImportedLogs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [notImportLogs, setNotImportLogs] = useState({});
  const [loading, setLoading] = useState(false);
  const [notImportLogId, setNotImportLogId] = useState();
  const [importLogModal, setImportLogModal] = useState(false);
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const toggleShowModal = () => {
    setImportLogModal(!importLogModal);
  };

  const handleDetails = (id) => {
    setNotImportLogId(id);
    setImportLogModal(true);
  };

  // *API Call for Import Logs List - Start
  const fetchNotImportedLogs = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.IMPORT_LOGS}?builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}&limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&status=0&search=${searchParam ? searchParam : ""}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setTotal(data?.data?.total);
      setNotImportLogs(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [projectId, trust_type, limit, page, searchParam]);

  useEffect(() => {
    fetchNotImportedLogs();
  }, [fetchNotImportedLogs]);
  // *API Call for Import Logs List - End

  useEffect(() => {
    navigate(
      `/projects/${projectId}/contact-import-logs?tab=imported&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${searchParam ? searchParam : ""}`
    );
  }, [navigate, projectId, page, limit, trustType, searchParam]);

  const phoneAndMobile = (string) => {
    if (string.phone && string.mobile) {
      return formatPhone(string.phone) + " / " + formatMobile(string.mobile);
    } else {
      if (string.phone) {
        return formatPhone(string.phone);
      } else {
        if (string.mobile) {
          return formatMobile(string.mobile);
        } else {
          return "-";
        }
      }
    }
  };

  const handleDelete = () => {
    fetchNotImportedLogs();
  };

  // *Import Logs List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const notImportLogsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      let contactDetails = JSON.parse(notImportLogs[i]?.contact_details);
      let phone = contactDetails?.Phones?.find(
        (item) => item?.PhoneType === "DEFAULT"
      )?.PhoneNumber;
      let mobile = contactDetails?.Phones?.find(
        (item) => item?.PhoneType === "MOBILE"
      )?.PhoneNumber;
      let contactPhone = {
        phone: phone,
        mobile: mobile,
      };

      items.push({
        key: notImportLogs[i]?.id,
        id: notImportLogs[i]?.id ? notImportLogs[i]?.id : "",
        name: contactDetails?.Name
          ? `${contactDetails?.Name} ${
              contactDetails?.FirstName && contactDetails?.LastName
                ? "(" +
                  contactDetails?.FirstName +
                  " " +
                  contactDetails?.LastName +
                  ")"
                : !contactDetails?.FirstName && contactDetails?.LastName
                ? "(" + contactDetails?.LastName + ")"
                : ""
            }`
          : contactDetails?.FirstName && contactDetails?.LastName
          ? contactDetails?.FirstName + " " + contactDetails?.LastName
          : !contactDetails?.FirstName && contactDetails?.LastName
          ? contactDetails?.LastName
          : "",
        import_from: notImportLogs[i]?.import_from
          ? notImportLogs[i]?.xero_tenant_name
            ? notImportLogs[i]?.import_from +
              `(${notImportLogs[i]?.xero_tenant_name})`
            : notImportLogs[i]?.import_from
          : "",
        email: contactDetails?.EmailAddress
          ? contactDetails?.EmailAddress
          : "-",
        phone: phoneAndMobile(contactPhone),
        abn: contactDetails?.TaxNumber ? formatABN(contactDetails?.TaxNumber) : "",
        reason: notImportLogs[i]?.description ? notImportLogs[i]?.description : "-",
        import_date: (
          <span>
            {notImportLogs[i]?.updated_at
              ? moment(
                  notImportLogs[i]?.updated_at,
                  "DD-MM-YYYY HH:mm:SS"
                ).format("DD-MM-YYYY")
              : notImportLogs[i]?.created_at
              ? moment(
                  notImportLogs[i]?.created_at,
                  "DD-MM-YYYY HH:mm:SS"
                ).format("DD-MM-YYYY")
              : ""}
          </span>
        ),
        action: (
          <>
            <FontAwesomeIcon
              icon={faEye}
              size="1x"
              onClick={() => handleDetails(notImportLogs[i]?.id)}
            />

            <NotImportedDelete
              handleDelete={handleDelete}
              id={notImportLogs[i]?.id}
            />

            <>
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              <Link
                to={{
                  pathname: `/projects/${projectId}/contact-import-logs/edit`,
                  search: `?id=${notImportLogs[i]?.id}`,
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </Link>
            </>
          </>
        ),
      });
    }
    return items;
  };

  const notImportLogsData = notImportLogsGenerator(notImportLogs?.length);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      style: { width: "20%" },
    },
    {
      dataField: "import_from",
      text: "Import From",
      style: { width: "10%" },
    },
    {
      dataField: "email",
      text: "Email",
      style: { width: "15%" },
    },
    {
      dataField: "phone",
      text: "Phone / Mobile",
      style: { width: "15%" },
    },
    {
      dataField: "abn",
      text: "ABN",
      style: { width: "10%" },
    },
    { dataField: "reason", text: "Reason", style: { width: "20%" } },
    {
      dataField: "import_date",
      text: "Import Date",
      style: { width: "10%" },
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  // *Import Logs List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  return (
    <>
    <div className="table-top-btn">
        <div className="search-section">
          <Form>
            <FormControl
              onChange={(e) => handleSearchChange(e)}
              type="text"
              value={searchParamData}
              placeholder="Search Not Imported Logs"
            />
            <Button className="fa fa-search">Search</Button>
          </Form>
        </div>
      </div>
      <div className="audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="batch_id"
          remote
          data={notImportLogsData}
          columns={columns}
          noDataIndication="No Data Found"
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={page}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>

      {importLogModal && (
        <ImportLogDetail
          toggleShowModal={toggleShowModal}
          importLogId={notImportLogId}
          importLogModal={importLogModal}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default NotImportedLogs;
