import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * 60 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    setIdle(true);
    localStorage.clear();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 5000,
    crossTab: true,
    syncTimers: 200
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

export default useIdleTimeout;
