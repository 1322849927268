import React, { useCallback, useState } from "react";
import { API, deleteCall } from "../../../../config";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../../common/Spin";

const ReconcileTransactionDelete = ({
  itemId,
  toggleDeleteModal,
  handleDelete,
  showDelete,
}) => {
  const [loading, setLoading] = useState(false);

  const handleReconcileTransactionDelete = useCallback(async () => {
    try {
      setLoading(true);
      deleteCall(`${API.TRANSACTION_DELETE}/${itemId}`).then((response) => {
        toast.success(response?.data?.message);
        if (response?.data?.errors?.bt_error) {
          toast.error(response?.data?.errors?.bt_error[0]);
        }
        if (response?.status === 200) {
          handleDelete();
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      toast.error(e?.data?.message);
    }
  }, [handleDelete, itemId]);

  return (
    <Modal
      size="lg"
      show={showDelete}
      onHide={toggleDeleteModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="project-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Delete Reconcile Transaction
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are your sure you want to delete this reconcile transaction?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleDeleteModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleReconcileTransactionDelete();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReconcileTransactionDelete;
