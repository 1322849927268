import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import { API, get } from "../../../config";
import { formatFullAddress, formatName } from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import { debounce } from "lodash";
import BuilderUserActionButton from "./components/BuilderUserActionButton";
import AddBuilderUser from "./AddBuilderUser";

const BuilderUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [usersList, setUsersList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || 10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showUser, setShowUser] = useState(false);

  const builderId = localStorage.getItem("builderId");

  // *API Call for Geting Users - Start
  const fetchUsers = useCallback(async () => {
    try {
      setUserLoading(true);
      const { data } = await get(
        `${API.GET_BUILDER_USER}?limit=${limit ? limit : 10}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}${
          builderId ? "&builder_id=" + builderId : ""
        }`
      );
      setUserLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const usersList = data?.data?.data;
      setUsersList(usersList);
      return 0;
    } catch (error) {
      setUserLoading(false);
      setUsersList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField, builderId]);

  const userModal = () => {
    fetchUsers();
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  // *API Call for Geting Users - End

  // *Users Table Row and Column Generation, Filtering and Sorting of Record - Start
  const usersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: usersList[i]?.id,
        user_id: usersList[i]?.id ? usersList[i]?.id : "",
        name: formatName(usersList[i]?.first_name, usersList[i]?.last_name),
        email: usersList[i]?.email ? usersList[i]?.email : "-",
        // role: usersList[i]?.role?.name ? usersList[i]?.role?.name : "-",
        admin_user: usersList[i]?.staff?.is_admin_user === 1 ? "Yes" : "No",
        address: formatFullAddress(
          usersList[i]?.staff?.office?.street,
          usersList[i]?.staff?.office?.suburb?.name,
          usersList[i]?.staff?.office?.state?.short_name,
          usersList[i]?.staff?.office?.postal_code
        ),
        action: (
          <BuilderUserActionButton
            id={usersList[i]?.id}
            userModal={userModal}
            builderId={builderId}
          />
        ),
      });
    }
    return items;
  };
  const usersData = usersGenerator(usersList?.length);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    // {
    //   dataField: "role",
    //   text: "Role",
    // },
    // {
    //   dataField: "admin_user",
    //   text: "Admin User",
    // },
    // {
    //   dataField: "address",
    //   text: "Address",
    // },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchParam(value);
    setPage(1);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Users Table Row and Column Generation, Filtering and Sorting of Record - End

  useEffect(() => {
    navigate(
      `/builder-users?limit=${limit ? limit : 10}&page=${
        page ? page : 1
      }&search=${searchParam ? searchParam : ""}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  const toggleUserModal = () => {
    showUser && userModal();
    setShowUser(!showUser);
  };

  return (
    <>
      {" "}
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Users
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => debounce(onSearchChange(e), 300)}
                      type="text"
                      value={searchParam}
                      placeholder="Search User"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleUserModal}>
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {userLoading && <Spin />}
                <BootstrapTable
                  keyField="user_id"
                  remote
                  data={usersData}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUser && (
        <AddBuilderUser
          toggleUserModal={toggleUserModal}
          userModal={userModal}
          showUser={showUser}
          builderId={builderId}
        />
      )}
    </>
  );
};

export default BuilderUsers;
