import { toast } from "react-toastify";
import { API, get } from "../../../../config";

export const getRolesPopupDetail = async (rolesId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_ROLE_LIST}/${rolesId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_ROLE_LIST}/${rolesId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const getRolesDetail = async (rolesId) => {
  try {
    const { data } = await get(`${API.GET_ROLE_LIST}/` + rolesId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const roleTypeOption = [
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Accounting Firm",
    value: "Accounting Firm"
  },
  {
    label: "Builder",
    value: "Builder"
  }
]

export const roleStatus = [
  {
    label: "Active",
    value: 1
  },
  {
    label: "Deactive",
    value: 0
  }
]
