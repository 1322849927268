import React, { useEffect, useState } from "react";

import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import { checkIfFileExists, formatNumber, MaxLimit } from "../../common/Misc";
import BackButton from "../../../components/Form/BackButton";
import { getProjectData } from "../components/ProjectHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import CancelButton from "../../../components/Form/CancleButton";
import { Button, Form, Modal } from "react-bootstrap";

// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import NumberFormat from "react-number-format";
import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import { getContractorDetail } from "../sub-contractors/components/SubContractorHelper";
import { DatePicker } from "rsuite";
// import { getContractorDetail } from "../sub-contractors/components/SubContractorHelper";

const defaulDescriptionForContractor =
  "Decrease beneficial interest to contractors from the trust account - Payment Claim #";

const defaulDescriptionForRetention =
  "To take up the payments of retentions to the retention trust account (retained amount) - Payment Claim #";

const AddPaymentSchedule = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [finalSubminData, setFinalSubminData] = useState([]);
  const [show, setShow] = useState(false);

  const projectId = params?.project_id;

  const [loading, setLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoiceNumberError, setInvoiceNumberError] = useState();

  const defaulEntryData = [
    {
      scheduled_date: "",
      // description: `${defaulDescriptionForContractor?.split("#")[0]}#${
      //   invoiceNumber ? invoiceNumber : ""
      // }`,
      description: "",
      // claimed_amount: "",
      schedule_amount: "",
      amount_withheld: "",
      reason: "",
    },
  ];

  // const newEntryData = {
  //   scheduled_date: "",
  //   // description: `${defaulDescriptionForContractor?.split("#")[0]}#${
  //   //   invoiceNumber ? invoiceNumber : ""
  //   // }`,
  //   description: "",
  //   // claimed_amount: "",
  //   schedule_amount: "",
  //   amount_withheld: "",
  //   reason: "",
  // };

  const [entryData, setEntryData] = useState(defaulEntryData);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState(defaulEntryData);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const yestarday = new Date(today);
  yestarday.setDate(yestarday.getDate() - 1);
  const [invoiceDate, setInvoiceDate] = useState(today);
  const [dueDate, setDueDate] = useState();
  const [claimedAmount, setClaimedAmount] = useState();

  const [approvedAmount, setApprovedAmount] = useState();
  const [approvedDate, setApprovedDate] = useState(today);
  const [receivedDate, setReceivedDate] = useState(today);
  // const [approvedReason, setApprovedReason] = useState();

  const [totalAmountWithheld, setTotalAmountWithheld] = useState(0);
  const [totalClaimedAmount, setTotalClaimedAmount] = useState(0);
  const [totalScheduleAmount, setTotalScheduleAmount] = useState(0);
  const [disableState, setDisableState] = useState(true);

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  const [setBuilderId] = useState();
  const [contractorDetails, setContractorDetails] = useState();
  // const [previousdate, setPreviousDate] = useState(yestarday);
  const [contractorData, setContractorData] = useState();

  const trust_type = params?.trust_type === "project" ? 1 : 0;

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo, setBuilderId);
  }, [projectId, setBuilderId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  // const handleAddEntry = (data, errorsData, index) => {
  //   setEntryData([...data, newEntryData]);
  //   setErrors([...errorsData, newEntryData]);
  // };

  // const handleDeleteEntry = (data, errorsData, index) => {
  //   data.splice(index, 1);
  //   errorsData.splice(index, 1);
  //   setEntryData([...data]);
  //   setErrors([...errorsData]);
  // };

  const handleDescriptionChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleScheduleAmountChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""));
        if (approvedAmount) {
          if (Number(approvedAmount.replace(/,/g, "")) < value) {
            errors[index].schedule_amount =
              "Enter amount less than approved amount";
          } else {
            errors[index].schedule_amount = null;
          }
        } else {
          errors[index].schedule_amount =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          schedule_amount: value,
          description: value
            ? obj?.description
              ? `${obj?.description?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
              : `${defaulDescriptionForContractor?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  // const handleClaimedAmountChange = async (data, index, e) => {
  //   const newArr = data.map((obj, i) => {
  //     if (i === index) {
  //       const value = Number(`${e.target.value}`.replace(/,/g, ""));
  //       return {
  //         ...obj,
  //         claimed_amount: value,
  //       };
  //     }
  //     return obj;
  //   });
  //   setEntryData(newArr);
  // };

  const handleScheduledDateChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      let invoice_date = moment(invoiceDate, "DD-MM-YYYY");
      let due_date = moment(dueDate, "DD-MM-YYYY");
      let date = moment(e, "DD-MM-YYYY");
      if (i === index) {
        if (invoiceDate && dueDate) {
          if (
            moment(date?.format("YYYY-MM-DD")).isSameOrAfter(
              invoice_date?.format("YYYY-MM-DD")
            ) <= date &&
            moment(date?.format("YYYY-MM-DD")).isSameOrBefore(
              due_date?.format("YYYY-MM-DD")
            )
          ) {
            errors[index].scheduled_date = "";
            return {
              ...obj,
              scheduled_date: e,
            };
          } else {
            errors[index].scheduled_date =
              "Selected payment date cannot be greater then due date.";
            return {
              ...obj,
              scheduled_date: e,
            };
          }
        } else {
          setDueDate(e);
          return {
            ...obj,
            scheduled_date: e,
          };
        }
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAmountWithheldChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""));

        if (approvedAmount) {
          if (Number(approvedAmount.replace(/,/g, "")) < Number(value)) {
            errors[index].amount_withheld =
              "Enter amount less than approved amount.";
          } else {
            errors[index].amount_withheld = null;
          }
        } else {
          errors[index].amount_withheld =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          amount_withheld: value,
          reason: value
            ? obj?.reason
              ? `${obj?.reason?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
              : `${defaulDescriptionForRetention?.split("#")[0]}#${
                  invoiceNumber ? invoiceNumber : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleReasonChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, reason: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const getContractor = async (contractorId) => {
    try {
      setLoading(true);
      const data = await getContractorDetail(contractorId);
      const contractorData = data ? data : {};
      setContractorData(data);
      if (contractorData?.id) {
        if (
          contractorData?.account_name &&
          contractorData?.account_number &&
          contractorData?.bsb
        ) {
          setDisableState(true);
        } else {
          setDisableState(false);
          toast.error("Please add bank details of selected contractor.");
        }
      }
      setLoading(false);
    } catch (e) {
      setContractorData();
      toast.error("Something went wrong.");
    }
  };

  const handleContractorSelect = async (contractor) => {
    setContractorDetails(contractor);
    getContractor(contractor?.id, contractor);
  };

  useEffect(() => {
    // let claimedAmountTotal = 0;
    let scheduleAmountTotal = 0;
    let amountWithheldTotal = 0;
    entryData &&
      entryData?.length > 0 &&
      entryData?.map((item) => {
        // claimedAmountTotal += item?.claimed_amount
        //   ? Number(item?.claimed_amount)
        //   : 0;
        scheduleAmountTotal += item?.schedule_amount
          ? Number(item?.schedule_amount)
          : 0;
        amountWithheldTotal += item?.amount_withheld
          ? Number(item?.amount_withheld)
          : 0;
        return 0;
      });
    if (claimedAmount) {
      setTotalClaimedAmount(
        formatNumber(Number(claimedAmount.replace(/,/g, "")))
      );
    }
    setTotalScheduleAmount(formatNumber(Number(scheduleAmountTotal)));
    setTotalAmountWithheld(formatNumber(Number(amountWithheldTotal)));
  }, [entryData, claimedAmount]);

  const handleSubmitPost = async (values) => {
    const formData = new FormData();
    formData.append(
      `contact_id`,
      contractorDetails?.id ? contractorDetails?.id : ""
    );
    formData.append(`invoice_number`, invoiceNumber ? invoiceNumber : "");
    formData.append(`claimed_amount`, claimedAmount ? claimedAmount : "");
    formData.append(
      "invoice_date",
      invoiceDate ? moment(invoiceDate).format("DD-MM-YYYY") : ""
    );
    formData.append(
      "due_date",
      dueDate ? moment(dueDate).format("DD-MM-YYYY") : ""
    );
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append(
      "received_date",
      receivedDate ? moment(receivedDate).format("DD-MM-YYYY") : ""
    );
    formData.append(
      "approved_date",
      approvedDate ? moment(approvedDate).format("DD-MM-YYYY") : ""
    );
    formData.append(
      "approved_amount",
      approvedAmount ? Number(`${approvedAmount}`?.replace(/,/g, "")) : ""
    );
    // formData.append(`difference_reason`, approvedReason ? approvedReason : "");
    // formData.append(
    //   `difference`,
    //   approvedAmount && claimedAmount
    //     ? Number(claimedAmount.replace(/,/g, "")) -
    //         Number(approvedAmount.replace(/,/g, ""))
    //     : 0.0
    // );
    formData.append(
      `total_claimed_amount`,
      totalClaimedAmount
        ? Number(`${totalClaimedAmount}`?.replace(/,/g, ""))
        : ""
    );
    formData.append(
      `total_scheduled_amount`,
      totalScheduleAmount
        ? Number(`${totalScheduleAmount}`?.replace(/,/g, ""))
        : ""
    );
    formData.append(
      `total_amount_withheld`,
      totalAmountWithheld
        ? Number(`${totalAmountWithheld}`?.replace(/,/g, ""))
        : ""
    );
    formData.append(`trust_type`, trust_type);

    values?.forEach((item, index) => {
      if (item) {
        formData.append(
          `paymentScheduleItems[${index}][description]`,
          item?.description ? item?.description : ""
        );
        formData.append(
          `paymentScheduleItems[${index}][claimed_amount]`,
          item?.schedule_amount || item?.amount_withheld
            ? Number(
                Number(item?.schedule_amount ? item?.schedule_amount : 0) +
                  Number(item?.amount_withheld ? item?.amount_withheld : 0)
              )
            : ""
        );
        formData.append(
          `paymentScheduleItems[${index}][scheduled_date]`,
          item?.scheduled_date
            ? moment(item?.scheduled_date).format("DD-MM-YYYY")
            : ""
        );
        formData.append(
          `paymentScheduleItems[${index}][scheduled_amount]`,
          item?.schedule_amount ? item?.schedule_amount : ""
        );
        formData.append(
          `paymentScheduleItems[${index}][amount_withheld]`,
          item?.amount_withheld ? item?.amount_withheld : ""
        );
        formData.append(
          `paymentScheduleItems[${index}][withheld_description]`,
          item?.reason ? item?.reason : ""
        );
      }
    });

    try {
      setLoading(true);
      const response = await fileUpload(
        `${API.PAYMENT_SCHEDULES}`,
        formData,
        {}
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const postDisabled = (values) => {
    if (values && values?.length > 0) {
      const checkData = values?.map((item, index) => {
        // let totalclaimed = Number(item?.schedule_amount) + Number(item?.amount_withheld);
        if (item?.amount_withheld && item?.schedule_amount) {
          if (
            item?.description &&
            item?.scheduled_date &&
            item?.schedule_amount &&
            item?.amount_withheld &&
            // item?.claimed_amount &&
            !errors[index].scheduled_date &&
            item?.reason &&
            disableState &&
            // Number(item?.claimed_amount) === Number(totalclaimed.toFixed(2)) &&
            Number(approvedAmount?.replace(/,/g, "")).toFixed(2) ===
              (
                (totalScheduleAmount !== 0
                  ? Number(totalScheduleAmount?.replace(/,/g, ""))
                  : 0) +
                (totalAmountWithheld !== 0
                  ? Number(totalAmountWithheld?.replace(/,/g, ""))
                  : 0)
              ).toFixed(2) &&
            claimedAmount &&
            dueDate &&
            invoiceDate &&
            approvedDate &&
            receivedDate &&
            approvedAmount &&
            // approvedReason &&
            invoiceNumber &&
            contractorDetails
          ) {
            return false;
          } else {
            return true;
          }
        } else if (item?.schedule_amount) {
          if (
            item?.description &&
            // item?.claimed_amount &&
            // Number(item?.claimed_amount) === Number(totalclaimed.toFixed(2)) &&
            claimedAmount &&
            !errors[index].scheduled_date &&
            Number(approvedAmount?.replace(/,/g, "")).toFixed(2) ===
              (
                (totalScheduleAmount !== 0
                  ? Number(totalScheduleAmount?.replace(/,/g, ""))
                  : 0) +
                (totalAmountWithheld !== 0
                  ? Number(totalAmountWithheld?.replace(/,/g, ""))
                  : 0)
              ).toFixed(2) &&
            item?.scheduled_date &&
            item?.schedule_amount &&
            disableState &&
            dueDate &&
            invoiceDate &&
            approvedDate &&
            receivedDate &&
            approvedAmount &&
            // approvedReason &&
            invoiceNumber &&
            contractorDetails
          ) {
            return false;
          } else {
            return true;
          }
        } else if (item?.amount_withheld) {
          if (
            // item?.description &&
            item?.scheduled_date &&
            item?.amount_withheld &&
            // item?.claimed_amount &&
            !errors[index].scheduled_date &&
            item?.reason &&
            disableState &&
            // Number(item?.claimed_amount) === Number(totalclaimed.toFixed(2)) &&
            Number(approvedAmount?.replace(/,/g, "")).toFixed(2) ===
              (
                (totalScheduleAmount !== 0
                  ? Number(totalScheduleAmount?.replace(/,/g, ""))
                  : 0) +
                (totalAmountWithheld !== 0
                  ? Number(totalAmountWithheld?.replace(/,/g, ""))
                  : 0)
              ).toFixed(2) &&
            claimedAmount &&
            dueDate &&
            invoiceDate &&
            approvedDate &&
            receivedDate &&
            approvedAmount &&
            // approvedReason &&
            invoiceNumber &&
            contractorDetails
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) ? true : false;
    } else {
      return true;
    }
  };

  const handlePost = async (finalData) => {
    setShow(true);
    setFinalSubminData(finalData);
  };

  const handleConfirmModal = () => {
    setShow(false);
    setFinalSubminData([]);
  };

  useEffect(() => {
    if (errors) {
      //eslint-disable-next-line
      errors?.map((i, index) => {
        if (approvedAmount) {
          if (entryData[index].schedule_amount) {
            if (
              Number(approvedAmount?.replace(/,/g, "")) <
              entryData[index].schedule_amount
            ) {
              errors[index].schedule_amount =
                "Enter amount less than claimed amount";
            } else {
              errors[index].schedule_amount = null;
            }
          }
          if (entryData[index].amount_withheld) {
            if (
              Number(approvedAmount?.replace(/,/g, "")) <
              entryData[index].amount_withheld
            ) {
              errors[index].amount_withheld =
                "Enter amount less than claimed amount";
            } else {
              errors[index].amount_withheld = null;
            }
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [approvedAmount]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              New Payment Schedule
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            {loading && <Spin />}
            <div className="content-details payment-schedule-page">
              <div className={"jounal-entry-card"}>
                <span className="journal-entry-status">
                  <Form>
                    <div className="payment-schedule-header-left-side">
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Payment To
                        </p>
                        <ContractorSelectWithTA
                          regionName={"0"}
                          projectId={projectId}
                          value={contractorDetails ? contractorDetails : null}
                          onChange={(contractor) => {
                            handleContractorSelect(contractor);
                          }}
                          business={true}
                          className="contactname-select selectbox"
                          placeholder="Select Subcontractor"
                          isClearable={false}
                        />
                        {!disableState && contractorDetails && (
                          <div className="bank-details">
                            <p className="text-danger">
                              {/* Subcontractor does not have bank details */}
                              <Link
                                to={{
                                  pathname: `/projects/${projectId}/sub-contractors/edit/${contractorDetails?.id}`,
                                }}
                                className="update-bank-text"
                              >
                                Update bank details
                              </Link>
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Claim/Invoice
                          Number
                        </p>
                        {/* <NumberFormat */}
                        <input
                          type="text"
                          name={`invoiceNumber`}
                          value={invoiceNumber ? invoiceNumber : ""}
                          onChange={(e) => {
                            setInvoiceNumber(e?.target?.value);
                          }}
                          onBlur={(e) => {
                            let regex = /[^a-zA-Z0-9]/g;
                            if (regex.test(e?.target?.value)) {
                              setInvoiceNumberError(
                                "Spaces and Special Characters are not allowed"
                              );
                              setInvoiceNumber();
                            } else {
                              setInvoiceNumberError("");
                              setInvoiceNumber(e?.target?.value);
                              setEntryData(
                                entryData?.map((i) => {
                                  return {
                                    scheduled_date: i.scheduled_date,
                                    description: i.description
                                      ? i.description?.split("#")[0] +
                                        "#" +
                                        e?.target?.value
                                      : "",
                                    claimed_amount: i.claimed_amount,
                                    schedule_amount: i.schedule_amount,
                                    amount_withheld: i.amount_withheld,
                                    reason: i.reason
                                      ? i.reason?.split("#")[0] +
                                        "#" +
                                        e?.target?.value
                                      : "",
                                  };
                                })
                              );
                            }
                          }}
                          // decimalSeparator=" "
                          // decimalScale={0}
                          placeholder="Enter Number"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                        {invoiceNumberError && (
                          <div
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            {invoiceNumberError}
                          </div>
                        )}
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Claim/Invoice Date
                        </p>
                        {/* <DatePicker
                          name="transaction_date"
                          value={invoiceDate ? invoiceDate : ""}
                          onChange={(e) => {
                            setInvoiceDate(e);
                            let date = moment(e)?._d;
                            setPreviousDate(date.setDate(date.getDate() - 1));
                          }}
                          disabled={[[tomorrow, false]]}
                        /> */}
                        <DatePicker
                          cleanable={false}
                          onChange={(e) => {
                            setInvoiceDate(moment(e, "DD-MM-YYYY"));
                            // let date = moment(e)?._d;
                            // setPreviousDate(date);
                          }}
                          format="dd-MM-yyyy"
                          value={
                            invoiceDate
                              ? String(invoiceDate)?.includes("-")
                                ? new Date(
                                    String(invoiceDate)?.split("-")[2],
                                    String(invoiceDate)?.split("-")[1] - 1,
                                    String(invoiceDate)?.split("-")[0]
                                  )
                                : new Date(invoiceDate)
                              : null
                          }
                          className="rs-date-body"
                          placeholder="Select Date"
                          placement="autoVerticalStart"
                          disabledDate={(date) =>
                            moment(date).isAfter(moment())
                          }
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Due Date
                        </p>
                        {/* <DatePicker
                          name="due_date"
                          value={dueDate ? dueDate : ""}
                          disabled={[[false, previousdate]]}
                          onChange={(e) => {
                            setDueDate(e);
                            if (entryData[0]?.scheduled_date) {
                              if (entryData[0]?.scheduled_date >= e) {
                                handleScheduledDateChange(entryData, 0, e);
                              }
                            } else {
                              handleScheduledDateChange(entryData, 0, e);
                            }
                          }}
                        /> */}
                        <DatePicker
                          cleanable={false}
                          onChange={(e) => {
                            setDueDate(moment(e, "DD-MM-YYYY"));
                          }}
                          format="dd-MM-yyyy"
                          value={
                            dueDate
                              ? String(dueDate)?.includes("-")
                                ? new Date(
                                    String(dueDate)?.split("-")[2],
                                    String(dueDate)?.split("-")[1] - 1,
                                    String(dueDate)?.split("-")[0]
                                  )
                                : new Date(dueDate)
                              : null
                          }
                          className="rs-date-body"
                          placeholder="Select Date"
                          placement="autoVerticalStart"
                          disabledDate={(date) =>
                            moment(date).isBefore(
                              moment(invoiceDate, "DD-MM-YYYY")
                            )
                          }
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Claimed Amount (Inc. GST)
                        </p>
                        <NumberFormat
                          type="text"
                          name="claimed_amount"
                          value={claimedAmount ? claimedAmount : ""}
                          onChange={(e) => {
                            setClaimedAmount(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (!approvedAmount) {
                              setApprovedAmount(e.target.value);
                            }
                          }}
                          placeholder="Enter Amount"
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          isAllowed={(values) => MaxLimit(values)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>
                          Received / Transaction Date
                        </p>
                        <DatePicker
                          cleanable={false}
                          onChange={(e) => {
                            if (invoiceDate && dueDate) {
                              if (
                                moment(invoiceDate, "DD-MM-YYYY") <= e &&
                                e <= moment(dueDate, "DD-MM-YYYY")
                              ) {
                                setReceivedDate(moment(e, "DD-MM-YYYY"));
                              } else {
                                toast.error("Not valid received date");
                                setReceivedDate(invoiceDate);
                              }
                            } else {
                              setReceivedDate(moment(e, "DD-MM-YYYY"));
                              setDueDate(moment(e, "DD-MM-YYYY"));
                            }
                          }}
                          format="dd-MM-yyyy"
                          value={
                            receivedDate
                              ? String(receivedDate)?.includes("-")
                                ? new Date(
                                    String(receivedDate)?.split("-")[2],
                                    String(receivedDate)?.split("-")[1] - 1,
                                    String(receivedDate)?.split("-")[0]
                                  )
                                : new Date(receivedDate)
                              : null
                          }
                          className="rs-date-body"
                          placeholder="Select Date"
                          placement="autoVerticalStart"
                          disabledDate={(date) =>
                            moment(date).isAfter(moment())
                          }
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>
                          Approval Date
                        </p>
                        <DatePicker
                          cleanable={false}
                          onChange={(e) => {
                            if (invoiceDate && dueDate) {
                              if (
                                moment(invoiceDate, "DD-MM-YYYY") <= e &&
                                e <= moment(dueDate, "DD-MM-YYYY")
                              ) {
                                setApprovedDate(moment(e, "DD-MM-YYYY"));
                              } else {
                                toast.error("Not valid approval date");
                                setApprovedDate(invoiceDate);
                              }
                            } else {
                              setApprovedDate(moment(e, "DD-MM-YYYY"));
                              setDueDate(moment(e, "DD-MM-YYYY"));
                            }
                          }}
                          format="dd-MM-yyyy"
                          value={
                            approvedDate
                              ? String(approvedDate)?.includes("-")
                                ? new Date(
                                    String(approvedDate)?.split("-")[2],
                                    String(approvedDate)?.split("-")[1] - 1,
                                    String(approvedDate)?.split("-")[0]
                                  )
                                : new Date(approvedDate)
                              : null
                          }
                          className="rs-date-body"
                          placeholder="Select Date"
                          placement="autoVerticalStart"
                          disabledDate={(date) =>
                            moment(date).isAfter(moment())
                          }
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Approved Amount (Inc. GST)
                        </p>
                        <NumberFormat
                          type="text"
                          name="approved_amount"
                          value={approvedAmount ? approvedAmount : ""}
                          onChange={(e) => {
                            setApprovedAmount(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (
                              // eslint-disable-next-line eqeqeq
                              Number(`${e.target.value}`?.replace(/,/g, "")) ==
                              0
                            ) {
                              setApprovedAmount();
                              toast.error(
                                "Approved amount should not be zero."
                              );
                            } else if (
                              Number(`${e.target.value}`?.replace(/,/g, "")) <=
                              Number(`${claimedAmount}`?.replace(/,/g, ""))
                            ) {
                              setApprovedAmount(e.target.value);
                            } else {
                              setApprovedAmount();
                              toast.error(
                                "Approved amount should not be greater than claimed amount."
                              );
                            }
                          }}
                          placeholder="Enter Amount"
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          isAllowed={(values) => MaxLimit(values)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div>
                      {/* <div className="payment-schedule-header-item">
                        <p>Difference Reason</p>
                        <input
                          type="text"
                          placeholder="Enter Difference Reason"
                          name={`difference_reason`}
                          value={approvedReason ? approvedReason : ""}
                          onChange={(e) => {
                            setApprovedReason(e?.target?.value);
                          }}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div> */}
                      <div className="payment-schedule-header-item">
                        <p>Today Date</p>
                        <span>{moment(new Date()).format("DD-MM-YYYY")}</span>
                      </div>
                    </div>
                  </Form>
                </span>
                <span className="journal-entry-status">
                  <div className="journal-entry">
                    <p>
                      Claimed Amount
                      <i>{totalClaimedAmount ? totalClaimedAmount : 0.0}</i>
                    </p>
                    <p>
                      Approved Amount
                      <i>
                        {approvedAmount
                          ? formatNumber(
                              Number(approvedAmount.replace(/,/g, ""))
                            )
                          : 0.0}
                      </i>
                    </p>
                    <p>
                      Payment Amount<i>{totalScheduleAmount}</i>
                    </p>
                    <p>
                      Amount withheld<i>{totalAmountWithheld}</i>
                    </p>
                    {/* <p>
                      Difference Amount
                      <i>
                        {approvedAmount && claimedAmount
                          ? formatNumber(
                              Number(claimedAmount.replace(/,/g, "")) -
                                Number(approvedAmount.replace(/,/g, ""))
                            )
                          : 0.0}
                      </i>
                    </p> */}
                  </div>
                  {contractorData && (
                    <div className="journal-entry">
                      {" "}
                      {/* <p>
                          Contract value
                          <i>
                            {contractorData?.contract_value
                              ? formatNumber(
                                  Number(contractorData?.contract_value)
                                )
                              : "0.00"}
                          </i>
                        </p>
                        <p>
                          Retention Percentage
                          <i>
                            {contractorData?.retention_percentage
                              ? `${Number(
                                  contractorData?.retention_percentage
                                )}%`
                              : "0%"}
                          </i>
                        </p> */}
                      <p>
                        Retention Amount
                        <i>
                          {contractorData?.retention_amount
                            ? formatNumber(
                                Number(contractorData?.retention_amount)
                              )
                            : "0.00"}
                        </i>
                      </p>{" "}
                      <p>
                        Balance in RTA
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          Before:{" "}
                          <i>
                            {contractorData?.closing_balance_rta
                              ? formatNumber(
                                  Number(contractorData?.closing_balance_rta)
                                )
                              : "0.00"}
                          </i>
                        </div>
                      </p>{" "}
                      <p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginTop: "25px",
                          }}
                        >
                          After:{" "}
                          <i>
                            {formatNumber(
                              Number(
                                contractorData?.closing_balance_rta
                                  ? contractorData?.closing_balance_rta
                                  : 0
                              ) +
                                Number(
                                  `${totalAmountWithheld}`.replace(/,/g, "")
                                )
                            )}
                          </i>
                        </div>
                      </p>{" "}
                    </div>
                  )}
                </span>
                <div className="table table-bordered journal-table">
                  <div className="react-bootstrap-table journal-entries-table">
                    <table className="table-content">
                      <thead>
                        <tr>
                          {/* <th scope="col">
                            <span className="required">*</span>Claimed Amount
                        </th> */}
                          <th
                            scope="col"
                            style={{
                              borderRightColor: "#c1bbbb",
                            }}
                          >
                            <span className="required">*</span>Payment Date
                          </th>
                          <th scope="col">
                            {/* <span className="required">*</span> */}
                            Amount to be paid (Inc. GST)
                          </th>
                          <th
                            scope="col"
                            style={{
                              borderRightColor: "#c1bbbb",
                            }}
                          >
                            {/* <span className="required">*</span> */}
                            Description
                          </th>
                          <th scope="col">Amount Withheld in RTA</th>
                          <th scope="col">Reason for Withholding</th>
                          {/* <th scope="col"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {entryData &&
                          entryData?.length > 0 &&
                          entryData?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className="payment-schedule-add-row"
                              >
                                {/* <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    type="text"
                                    name="claimed_amount"
                                    value={
                                      item?.claimed_amount
                                        ? item?.claimed_amount
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleClaimedAmountChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.claimed_amount
                                      ? errors[index]?.claimed_amount
                                      : ""}
                                  </span>
                                </td> */}

                                <td
                                  style={{ width: "4%" }}
                                  className="payment-scheduled-date-body"
                                >
                                  {/* <DatePicker
                                    name="scheduled_date"
                                    value={
                                      item?.scheduled_date
                                        ? item?.scheduled_date
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleScheduledDateChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    disabled={[[false, previousdate]]}
                                  /> */}
                                  <DatePicker
                                    name="scheduled_date"
                                    cleanable={false}
                                    onChange={(e) => {
                                      handleScheduledDateChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    format="dd-MM-yyyy"
                                    value={
                                      item?.scheduled_date
                                        ? String(
                                            item?.scheduled_date
                                          )?.includes("-")
                                          ? new Date(
                                              String(
                                                item?.scheduled_date
                                              )?.split("-")[2],
                                              String(
                                                item?.scheduled_date
                                              )?.split("-")[1] - 1,
                                              String(
                                                item?.scheduled_date
                                              )?.split("-")[0]
                                            )
                                          : new Date(item?.scheduled_date)
                                        : null
                                    }
                                    className="rs-date-body"
                                    placeholder="Select Date"
                                    placement="autoVerticalStart"
                                    disabledDate={(date) =>
                                      moment(date).isBefore(
                                        moment(invoiceDate, "DD-MM-YYYY")
                                      )
                                    }
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.scheduled_date
                                      ? errors[index]?.scheduled_date
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "12%" }}>
                                  <NumberFormat
                                    type="text"
                                    name="schedule_amount"
                                    value={
                                      item?.schedule_amount
                                        ? item?.schedule_amount
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleScheduleAmountChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.schedule_amount
                                      ? errors[index]?.schedule_amount
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "16%" }}>
                                  <textarea
                                    type="text"
                                    name={`description`}
                                    value={
                                      item?.description ? item?.description : ""
                                    }
                                    onChange={(e) => {
                                      handleDescriptionChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  {/* <span className="text-danger">
                                    {errors[index]?.description
                                      ? errors[index]?.description
                                      : ""}
                                  </span> */}
                                </td>

                                <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    type="text"
                                    name="amount_withheld"
                                    value={
                                      item?.amount_withheld
                                        ? item?.amount_withheld
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleAmountWithheldChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.amount_withheld
                                      ? errors[index]?.amount_withheld
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "10%" }}>
                                  <textarea
                                    type="text"
                                    name={`reason`}
                                    value={item?.reason ? item?.reason : ""}
                                    disabled={
                                      item?.amount_withheld ? false : true
                                    }
                                    onChange={(e) => {
                                      handleReasonChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.reason
                                      ? errors[index]?.reason
                                      : ""}
                                  </span>
                                </td>

                                {/* <td style={{ width: "4%" }}>
                                  {index + 1 === entryData?.length ? (
                                    <>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleAddEntry(
                                            entryData,
                                            errors,
                                            index
                                          );
                                        }}
                                        icon={faAdd}
                                        size="1x"
                                      />
                                      {index > 0 && (
                                        <>
                                          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              handleDeleteEntry(
                                                entryData,
                                                errors,
                                                index
                                              );
                                            }}
                                            icon={faTrash}
                                            size="1x"
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    index > 1 && (
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleDeleteEntry(
                                            entryData,
                                            errors,
                                            index
                                          );
                                        }}
                                        icon={faTrash}
                                        size="1x"
                                      />
                                    )
                                  )}
                                </td> */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="action-box">
                  <Button
                    disabled={postDisabled(entryData)}
                    // onClick={() => {
                    //   handleSubmit(entryData);
                    // }}
                    onClick={() => {
                      handlePost(entryData);
                    }}
                  >
                    Post
                  </Button>
                  <CancelButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={handleConfirmModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            New Payment Schedule
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <p>
                Are you sure, you would like to add this item to the payment
                schedule?
                <br />
                It will create a payment schedule. Next, You will have to go to
                payment instructions area to generate .aba file. It will then
                create a payment entry.
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleConfirmModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSubmitPost(finalSubminData);
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AddPaymentSchedule;
