import React, { useRef } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Spin from "../../common/Spin";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { AddRolesSchema } from "../../common/Validation";
import { roleStatus } from "./components/RolesHelper";

const AddRoles = ({ toggleRolesModal, showRoles }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [loading, setLoading] = useState(false);
  const [rolesData, setRolesData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    value.type = value?.type?.value;
    value.status = value?.status?.value;

    const formData = new FormData();
    formData.append("name", value?.name ? value?.name : "");
    formData.append("type", value?.type ? value?.type : "");
    formData.append("status", value?.status ? value?.status : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.GET_ROLE_LIST}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleRolesModal();
      }
      return data?.data;
    } catch (error) {
      setRolesData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        // size="lg"
        show={showRoles}
        onHide={toggleRolesModal}
        dialogClassName="small-review modal-role"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Role
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: rolesData?.name ? rolesData?.name : "",
              type: "",
              status: roleStatus?.find((item) => item?.value === 1)
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddRolesSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleRolesModal={toggleRolesModal}
                    tomorrow={tomorrow}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddRoles;
