import { API, get } from "../../../../../config";

export const getContractorTypeDetails = async (ContractorTypeId) => {
  try {
    const { data } = await get(
      `${API.CONTRACTOR_TYPE_DETAILS}/` + ContractorTypeId
    );
    return data.data;
  } catch (e) {
    return null;
  }
};
