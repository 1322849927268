import React, { useCallback, useEffect, useState } from "react";
import { API, defaultLimitPagination, fileUpload, get, imageDefaultPrefixPath } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import { debounce } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import AccountingActionButton from "./components/AccountingActionButton";
import AddChartOfAccounting from "./AddChartOfAccounting";
import BackButton from "../../../components/Form/BackButton";
import UploadFile from "../../common/UploadFile";
import { useProjectBar } from "../../common/useProjectBar";

const ChartOfAccounts = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [accountTpesLoading, setAccountTpesLoading] = useState(false);
  const [accountTypesList, setAccountTypesList] = useState([]);
  const accountFirstId = accountTypesList[0]?.id ? accountTypesList[0]?.id : "";
  const [accountId, setAccountId] = useState(accountFirstId);
  const params = useParams();

  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const projectId = params?.project_id;

  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();

  const accountFirstTab = accountTypesList[0]?.name
    .toLowerCase()
    .replace(/ /g, "-")
    ? accountTypesList[0]?.name.toLowerCase().replace(/ /g, "-")
    : "";

  const [accountTab, setAccountTab] = useState(accountFirstTab);
  useEffect(() => {
    setAccountId(accountTypesList[0]?.id);
    setAccountTab(accountTypesList[0]?.name.toLowerCase().replace(/ /g, "-"));
  }, [accountTypesList]);

  const [accountData, setAccountData] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [showAccount, setShowAccount] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const handleDelete = () => {
    fetchAccountData();
  };

  const fetchAccountTypes = useCallback(async () => {
    try {
      setAccountTpesLoading(true);
      const { data } = await get(`${API.GET_ACCOUNT_TYPES}`);
      setAccountTpesLoading(false);
      const accountTypesList = data.data;
      setAccountTypesList(accountTypesList);
      return data.data;
    } catch (error) {
      setAccountTpesLoading(false);
      setAccountTypesList([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, []);

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  const fetchAccountData = useCallback(async () => {
    if (projectId && accountId) {
      try {
        setAccountLoading(true);
        const { data } = await get(
          `${API.GET_ACCOUNT_DATA}?builder_project_id=${
            projectId ? projectId : ""
          }&account_type_id=${accountId ? accountId : ""}&search=${
            searchParam ? searchParam : ""
          }&limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}&sort_column=${
            sortField ? sortField : ""
          }&sort_order=${sortOrder ? sortOrder : ""}`
        );
        setAccountLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setAccountData(data?.data?.data);
      } catch (error) {
        setAccountLoading(false);
        setAccountData([]);

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [projectId, accountId, searchParam, limit, page, sortField, sortOrder]);

  const accountsDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: accountData[i]?.id,
        name: accountData[i]?.name,
        type: accountData[i]?.type === 0 ? "Debit" : "Credit",
        tax_rate:
          accountData[i]?.tax === 1
            ? "GST " +
              (accountData[i]?.gst_percentage
                ? accountData[i]?.gst_percentage
                : 0) +
              "%"
            : accountData[i]?.tax === 2
            ? "BAS Excluded"
            : accountData[i]?.tax === 3
            ? "GST on Imports"
            : "GST Excluded",
        code: accountData[i]?.code ? accountData[i]?.code : "-",
        sub_code: accountData[i]?.sub_code ? accountData[i]?.sub_code : "-",
        action: (
          <AccountingActionButton
            id={accountData[i]?.id}
            handleDelete={handleDelete}
            fetchAccountData={fetchAccountData}
          />
        ),
      });
    }
    return items;
  };
  const accountsData = accountsDataGenerator(accountData?.length);

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_ACCOUNT_TEMPLATE);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "chart-of-account.xls"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleImportModal = async () => {
    toast.error(
      "This functionality is under development and will be available very soon."
    );
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleUpload = async () => {
    if (files) {
      const formData = new FormData();

      formData.append("builder_project_id", projectId);
      formData.append("chart_of_account_file", files);

      const config = { headers: { "Content-Type": "multipart/form-data" } };

      try {
        setIsImportLoading(true);
        const { data } = await fileUpload(
          `${API.IMPORT_ACCOUNT}`,
          formData,
          config
        );
        setIsImportLoading(false);
        if (data) {
          toast.success(data?.message);
          fetchAccountData();
          handleImportClose();
        }
        return data?.data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    navigate(
      `?tab=${accountTab ? accountTab : ""}&search=${
        searchParam ? searchParam : ""
      }&limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}`
    );
  }, [searchParam, limit, page, accountTab, navigate]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  const columns = ProjectReadOnly
    ? [
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "code",
          text: "Code",
          sort: true,
        },
        {
          dataField: "sub_code",
          text: "Sub Code",
          sort: true,
        },
        {
          dataField: "type",
          text: "Type",
          sort: true,
        },
        {
          dataField: "tax_rate",
          text: "Tax Rate",
        },
      ]
    : [
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "code",
          text: "Code",
          sort: true,
        },
        {
          dataField: "sub_code",
          text: "Sub Code",
          sort: true,
        },
        {
          dataField: "type",
          text: "Type",
          sort: true,
        },
        {
          dataField: "tax_rate",
          text: "Tax Rate",
        },
        {
          dataField: "action",
          text: "Action",
          align: "center",
          headerAlign: "center",
          hidden: ProjectReadOnly,
          style: { width: "97px" },
        },
      ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  const toggleAccountModal = () => {
    setShowAccount(!showAccount);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Chart Of Accounts
              <div className="page-title-right">
                {ProjectLogo && (
                  <img
                    src={`${imageDefaultPrefixPath}${ProjectLogo}`}
                    alt="selectedFile"
                  />
                )}{" "}
                {ProjectName}
              </div>
            </h1>
            <div className="content-details">
              <div
                className="table-top-btn"
                style={{ alignItems: "flex-start" }}
              >
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Chart Of Account"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                {!ProjectReadOnly && <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={toggleAccountModal}
                    data-toggle="modal"
                    data-target="#account"
                  >
                    Add New
                  </Button>
                  <div className="border-btn">
                    <DropdownButton className="info-icon">
                      <Dropdown.Item>
                        <p>Classification - Required</p>
                        <p>Code - Required, 4 Digit</p>
                        <p>Sub Code - Optional, 4 Digit</p>
                        <p>Description - Optional</p>
                        <p>Type - Required</p>
                        <p>Tax - Required</p>
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="link"
                      disabled={isLoadingDownload}
                      onClick={() => handleDownload()}
                      className="download-template"
                    >
                      Download Template
                    </Button>
                    <Button variant="primary" onClick={handleImportModal}>
                      Upload Chart Of Accounts
                    </Button>
                  </div>
                  <div className="border-btn">
                    <div className="table-btn">
                      <Button
                        variant="primary"
                        onClick={() => {
                          toast.error(
                            "This functionality is under development and will be available very soon."
                          );
                        }}
                      >
                        Restore
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          toast.error(
                            "This functionality is under development and will be available very soon."
                          );
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>}
              </div>
              <div className="table-top-btn">
                <ul className="nav nav-tabs">
                  {accountTpesLoading && <Spin />}
                  {accountTypesList &&
                    accountTypesList?.length > 0 &&
                    accountTypesList?.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={accountId === item?.id ? "active" : ""}
                        >
                          <Link
                            key={item?.id}
                            onClick={() => {
                              setAccountId(item?.id);
                              setAccountTab(
                                item?.name.toLowerCase().replace(/ /g, "-")
                              );
                              setPage(1);
                            }}
                          >
                            {item?.name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="custom-table tab-table">
                {accountLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  data={accountsData}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />

                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAccount && (
        <AddChartOfAccounting
          projectId={projectId}
          toggleAccountModal={toggleAccountModal}
          fetchAccountData={fetchAccountData}
          showAccount={showAccount}
        />
      )}
      {showImport && (
        <Modal
          size="lg"
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Chart Of Account
          </Modal.Header>
          <Modal.Body>
            <div>
              <UploadFile
                projectId={projectId}
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleUpload}
                handleCancel={handleImportClose}
                acceptFileFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ChartOfAccounts;
