/* eslint-disable eqeqeq */
import BackButton from "../../../components/Form/BackButton";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
// import ChartOfAccountSelect from "../../common/ChartOfAccountSelect";
import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import { getProjectData } from "../components/ProjectHelper";
import { checkIfFileExists, formatName, MaxLimit } from "../../common/Misc";
import { API, fileUpload, get, imageDefaultPrefixPath } from "../../../config";
import Spin from "../../common/Spin";
import { useLocation } from "react-router-dom";
// import { useCallback } from "react";
// import { getPrincipalDetail } from "../settings/Principal/component/PrincipalHelper";
import NumberFormat from "react-number-format";
import { DatePicker } from "rsuite";
import { searchContractorPTADetail } from "../sub-contractors/components/SubContractorHelper";

const RecordReceipt = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileNote, setFileNote] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  // const [transactionDate, setTransactionDate] = useState(state?.transactionDate ? state?.transactionDate : new Date());
  const [transactionDate, setTransactionDate] = useState(
    state?.transactionDate
      ? moment(state?.transactionDate).format("DD-MM-YYYY")
      : moment().format("DD-MM-YYYY")
  );
  const [accountContactLoading, setAccountContactLoading] = useState(false);
  // const [amountErrors, setAmountErrors] = useState({});
  // const [descriptionErrors, setDescriptionErrors] = useState({});
  // const [payerErrors, setPayerErrors] = useState({});

  // const [principalData, setPrincipalData] = useState();
  // const [defaultEntryData, setDefaultEntryData] = useState([
  //   {
  //     chart_of_account_id: "",
  //     contact_id: "",
  //     payer_name: "",
  //     amount: "",
  //     description: "",
  //   },
  // ]);

  const defaultEntryData = {
    chart_of_account_id: "",
    contact_id: "",
    payer_name: "",
    amount: "",
    description: "",
  };

  // const newEntryData = {
  //   chart_of_account_id: "",
  //   contact_id: "",
  //   payer_name: "",
  //   amount: "",
  //   description: "",
  // };

  const [finalData, setFinalData] = useState([defaultEntryData]);

  // useEffect(() => {
  //   setFinalData([defaultEntryData]);
  // }, [defaultEntryData]);

  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();

  const SearchContractorPTA = async (type) => {
    try {
      setLoading(true);
      const data = await searchContractorPTADetail(type, projectId);
      const contractorData = data ? data : {};
      let accountData =
        contractorData?.is_pta === 1
          ? await fetchAccountbasedOnContact("2001")
          : contractorData?.is_pta === 0
          ? await fetchAccountbasedOnContact("2003")
          : contractorData?.is_pta === 3
          ? await fetchAccountbasedOnContact("2102")
          : contractorData?.contact_type
          ? await fetchAccountbasedOnContact(
              contractorData?.contact_type?.chart_of_account?.name
            )
          : await fetchAccountbasedOnContact("0510");
      setFinalData([
        {
          ...defaultEntryData,
          contact_id: contractorData,
          payer_name:
            contractorData?.is_pta === 0
              ? `${formatName(
                  contractorData?.first_name,
                  contractorData?.last_name
                )} (RTA)`
              : contractorData?.is_pta === 1
              ? `${formatName(
                  contractorData?.first_name,
                  contractorData?.last_name
                )} (PTA)`
              : contractorData?.is_pta === 2
              ? `${formatName(
                  contractorData?.first_name,
                  contractorData?.last_name
                )} (Withdrawal/General Business)`
              : contractorData?.is_pta === 3
              ? `${formatName(
                  contractorData?.first_name,
                  contractorData?.last_name
                )} (Principal)`
              : formatName(
                  contractorData?.first_name,
                  contractorData?.last_name
                ),
          chart_of_account: accountData
            ? accountData
            : contractorData?.chart_of_account,
          chart_of_account_id: accountData
            ? accountData?.id
            : contractorData?.chart_of_account?.id,
        },
      ]);
      setLoading(false);
    } catch (e) {
      setFinalData([]);
      toast.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (
      state?.type != null &&
      state?.type != undefined &&
      state?.type != "null"
    ) {
      SearchContractorPTA(state?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.type]);

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  useEffect(() => {
    navigate(`/projects/${projectId}/${params?.trust_type}/receipts/add`, {
      replace: true,
    });
  }, [navigate, projectId, params?.trust_type]);

  // const fetchPrincipalData = useCallback(async (projectID) => {
  //   try {
  //     setLoading(true);
  //     const data = await getPrincipalDetail(projectID);
  //     if (data) {
  //       setDefaultEntryData([
  //         {
  //           chart_of_account_id: "",
  //           payer_name: data?.name ? data?.name : "",
  //           amount: "",
  //           description: "",
  //         },
  //       ]);
  //       setPrincipalData(data);
  //     }

  //     setLoading(false);
  //   } catch (e) {
  //     setPrincipalData(null);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchPrincipalData(projectId);
  // }, [projectId, fetchPrincipalData]);

  // eslint-disable-next-line no-unused-vars
  const handleNewAccountSelection = (account, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          chart_of_account: account,
          chart_of_account_id: account?.id,
        };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const fetchAccountbasedOnContact = async (search) => {
    try {
      setAccountContactLoading(true);
      const response = await get(
        `${API.GET_ACCOUNT_DATA}?builder_project_id=${projectId}&search=${
          search ? search : ""
        }`
      );
      let account =
        response?.data?.data?.data && response?.data?.data?.data?.length > 0
          ? response?.data?.data?.data.map((element) => {
              return {
                id: element.id,
                label:
                  element?.name +
                  (element?.account_type?.name
                    ? " (" + element?.account_type?.name + ")"
                    : "") +
                  (element?.code ? " - " + element?.code.toString() : "") +
                  (element?.sub_code ? "." + element?.sub_code.toString() : ""),
                tax: element?.tax,
              };
            })
          : [];
      setAccountContactLoading(false);
      return account[0];
    } catch (error) {
      setAccountContactLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleNewContactSelection = async (contact, index, finalData) => {
    let accountData =
      contact?.is_pta === 1
        ? await fetchAccountbasedOnContact("2001")
        : contact?.is_pta === 0
        ? await fetchAccountbasedOnContact("2003")
        : contact?.is_pta === 3
        ? await fetchAccountbasedOnContact("2102")
        : contact?.contact_type
        ? await fetchAccountbasedOnContact(
            contact?.contact_type?.chart_of_account?.name
          )
        : await fetchAccountbasedOnContact("0510");
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          contact_id: contact,
          payer_name:
            contact?.is_pta === 0
              ? `${formatName(contact?.first_name, contact?.last_name)} (RTA)`
              : contact?.is_pta === 1
              ? `${formatName(contact?.first_name, contact?.last_name)} (PTA)`
              : contact?.is_pta === 2
              ? `${formatName(
                  contact?.first_name,
                  contact?.last_name
                )} (Withdrawal/General Business)`
              : contact?.is_pta === 3
              ? `${formatName(
                  contact?.first_name,
                  contact?.last_name
                )} (Principal)`
              : formatName(contact?.first_name, contact?.last_name),
          chart_of_account: accountData ? accountData : obj?.chart_of_account,
          chart_of_account_id: accountData
            ? accountData?.id
            : obj?.chart_of_account?.id,
        };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  // const handleBlurPayer = (e, index) => {
  //   let errors = payerErrors;
  //   let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
  //   if (e) {
  //     const checkNote = regex.exec(e.target.value) ?? false;
  //     if (e.target.value && checkNote === false) {
  //       errors[index] = "Enter valid Payer Name.";
  //     } else {
  //       errors[index] = null;
  //     }
  //   } else {
  //     toast.error("Something went wrong.");
  //   }
  //   setPayerErrors({ ...errors });
  // };

  // const handlePayer = (e, index, finalData) => {
  //   const newArr = finalData.map((obj, i) => {
  //     if (i === index) {
  //       return { ...obj, payer_name: e.target.value };
  //     }
  //     return obj;
  //   });
  //   setFinalData(newArr);
  // };

  const handleAmount = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""));
        return { ...obj, amount: value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  // const handleBlurDescription = (e, index) => {
  //   let errors = descriptionErrors;
  //   let regex = new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/);
  //   if (e) {
  //     const checkNote = regex.exec(e.target.value) ?? false;
  //     if (e.target.value && checkNote === false) {
  //       errors[index] = "Enter valid Description.";
  //     } else {
  //       errors[index] = null;
  //     }
  //   } else {
  //     toast.error("Something went wrong.");
  //   }
  //   setDescriptionErrors({ ...errors });
  // };

  const handleDescription = (e, index, finalData) => {
    const newArr = finalData.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setFinalData(newArr);
  };

  const handlePostDisabled = (finalData) => {
    // const amountError = Object.values(amountErrors);
    // const checkAmountError = Object.values(
    //   amountError.filter((item, index) => amountError.indexOf(item) === index)
    // );

    // const descriptionError = Object.values(descriptionErrors);
    // const checkDescriptionError = Object.values(
    //   descriptionError.filter(
    //     (item, index) => descriptionError.indexOf(item) === index
    //   )
    // );

    const checkData = finalData?.map((element, index) => {
      console.log(element);
      if (
        // (checkAmountError[index] === null ||
        //   checkAmountError[index] === undefined) &&
        // (checkDescriptionError[index] === null ||
        //   checkDescriptionError[index] === undefined) &&
        element?.chart_of_account_id &&
        element?.contact_id &&
        element?.payer_name &&
        element?.amount &&
        element?.description
      ) {
        return false;
      } else {
        return true;
      }
    });
    return checkData?.find((item) => item === true) ||
      !transactionDate ||
      !fileNote
      ? true
      : false;
  };

  const handlePost = async (finalData) => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId);
    formData.append("trust_type", trust_type);
    // formData.append(
    //   "transaction_date",
    //   moment(transactionDate).format("DD-MM-YYYY")
    // );
    formData.append("transaction_date", transactionDate ? transactionDate : "");
    formData.append("note", fileNote ? fileNote : "");
    formData.append("invoice_claim_number", claimNumber ? claimNumber : "");
    finalData?.forEach((item, index) => {
      if (item) {
        formData.append(
          `receipts[${index}][chart_of_account_id]`,
          item?.chart_of_account_id ? item?.chart_of_account_id : ""
        );
        formData.append(
          `receipts[${index}][contact_id]`,
          item?.contact_id ? item?.contact_id?.id : ""
        );
        formData.append(
          `receipts[${index}][payer_name]`,
          item?.payer_name ? item?.payer_name : ""
        );
        formData.append(
          `receipts[${index}][amount]`,
          item?.amount ? item?.amount : 0
        );
        formData.append(
          `receipts[${index}][description]`,
          item?.description ? item?.description : ""
        );
      }
    });

    try {
      setLoading(true);
      const response = await fileUpload(`${API.CREATE_RECIEPT}`, formData, {});
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        setFinalData([]);
        setTransactionDate(moment());
        setFileNote("");
        setClaimNumber("");
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  // const handleAddEntry = (data) => {
  //   setLoading(false);
  //   setFinalData([...data, newEntryData]);
  // };
  // const handleDeleteEntry = (data, index) => {
  //   data.splice(index, 1);
  //   setFinalData([...data]);
  // };

  // const handleContractorSelect = (contractor, index, finalData) => {
  //   const newArr = finalData.map((obj, i) => {
  //     if (i === index) {
  //       return {
  //         ...obj,
  //         contractor: contractor,
  //         contractor_id: contractor?.id,
  //       };
  //     }
  //     return obj;
  //   });
  //   setFinalData(newArr);
  // };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            New Project Trust Receipt
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details refund-process">
            <div className="table-top-btn"></div>
            <div style={{ width: "100%", height: "89%" }}>
              <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
                <div id="myGrid" className="ag-theme-alpine ato-grid">
                  {(loading || accountContactLoading) && <Spin />}
                  <Form>
                    <div className="form-box">
                      <div className="refund-process-box">
                        <Container>
                          <Container>
                            <Row className="refund-top-details record-payment">
                              <Col
                                xs={12}
                                md={2}
                                className="add-record-receipt"
                              >
                                <strong>Transaction Date</strong>
                                <Form.Group className="datepicker">
                                  {/* <DatePicker
                                    name="transaction_date"
                                    value={
                                      transactionDate ? transactionDate : ""
                                    }
                                    onChange={(e) => {
                                      setTransactionDate(e);
                                    }}
                                  /> */}
                                  <DatePicker
                                    cleanable={false}
                                    name="transaction_date"
                                    onChange={(e) =>
                                      setTransactionDate(
                                        moment(e, "DD-MM-YYYY").format(
                                          "DD-MM-YYYY"
                                        )
                                      )
                                    }
                                    format="dd-MM-yyyy"
                                    value={
                                      transactionDate
                                        ? String(transactionDate)?.includes("-")
                                          ? new Date(
                                              String(transactionDate)?.split(
                                                "-"
                                              )[2],
                                              String(transactionDate)?.split(
                                                "-"
                                              )[1] - 1,
                                              String(transactionDate)?.split(
                                                "-"
                                              )[0]
                                            )
                                          : new Date(transactionDate)
                                        : null
                                    }
                                    className="rs-date-body"
                                    placeholder="Select Date"
                                    placement="autoVerticalStart"
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                xs={12}
                                md={3}
                                className="add-record-receipt"
                              >
                                <strong>Invoice Claim Number</strong>
                                <Form.Control
                                  type="text"
                                  className="left-text-align"
                                  required
                                  maxLength={150}
                                  value={claimNumber ? claimNumber : ""}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        /^[^!@#)(^%$<>a-zA-Z\s.,/&-][\d]*$/
                                      ),
                                      message:
                                        "Claim Number can contain letters",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setClaimNumber(e.target.value);
                                  }}
                                ></Form.Control>
                              </Col>
                              <Col
                                xs={12}
                                md={3}
                                className="add-record-receipt"
                              >
                                <strong>
                                  <span className="required">*</span>File Note
                                </strong>
                                <Form.Control
                                  type="text"
                                  className="left-text-align"
                                  required
                                  maxLength={150}
                                  value={fileNote ? fileNote : ""}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                                      ),
                                      message:
                                        "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                                    },
                                  ]}
                                  onChange={(e) => {
                                    setFileNote(e.target.value);
                                  }}
                                ></Form.Control>
                              </Col>
                              <Col
                                xs={12}
                                md={7}
                                className="add-record-receipt"
                              ></Col>
                            </Row>
                            <Row className="refund-card-box record-payment">
                              <Col xs={6} md={12}>
                                <div className="refund-card">
                                  <label>Receipt Details</label>
                                  <div className="refund-card-content">
                                    <div className="refund-card-content-label refund-card-title row col-md-12">
                                      <h6 className="col-md-3 center-align">
                                        {/* Contractor Name */}
                                        Payer Name
                                      </h6>
                                      {/* <h6 className="col-md-3 center-align">
                                        Account
                                      </h6> */}
                                      {/* <h6 className="col-md-3 center-align">
                                        Payer Name
                                      </h6> */}
                                      <h6 className="col-md-2 center-align">
                                        {trust_type
                                          ? `Amount (Inc. GST)`
                                          : `Amount`}
                                      </h6>
                                      <h6 className="col-md-3 center-align">
                                        Description
                                      </h6>
                                    </div>
                                    <div className="refund-card-content-row">
                                      {finalData &&
                                        finalData?.map((item, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="refund-card-content-label row col-md-12"
                                            >
                                              <div className="col-md-3 refund-column">
                                                <ContractorSelectWithTA
                                                  regionName={index.toString()}
                                                  name="contact_id"
                                                  onChange={(contact) => {
                                                    handleNewContactSelection(
                                                      contact,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  business={true}
                                                  className="contactname-select selectbox"
                                                  projectId={projectId}
                                                  value={
                                                    item?.contact_id
                                                      ? item?.contact_id
                                                      : null
                                                  }
                                                  placeholder={
                                                    "Select Payer Name"
                                                  }
                                                  isClearable={false}
                                                />
                                              </div>
                                              {/* <div className="col-md-3 refund-column">
                                                <ChartOfAccountSelect
                                                  name="chart_of_account_id"
                                                  onChange={(account) => {
                                                    handleNewAccountSelection(
                                                      account,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  className="contactname-select"
                                                  projectId={projectId}
                                                  value={
                                                    item?.chart_of_account
                                                      ? item?.chart_of_account
                                                      : null
                                                  }
                                                  placeholder={"Select Account"}
                                                  isClearable={false}
                                                />
                                              </div> */}

                                              {/* <div className="col-md-3 refund-column">
                                                <Form.Group>
                                                  <Form.Control
                                                    // disabled
                                                    type="text"
                                                    placeholder="Payer Name"
                                                    value={
                                                      item?.payer_name
                                                        ? item?.payer_name
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handlePayer(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                      handleBlurPayer(e, index);
                                                    }}
                                                    style={{
                                                      border:
                                                        "1px solid #d9d9d9",
                                                      padding: "5px 10px",
                                                      fontSize: "14px",
                                                      width: "100%",
                                                      color: "#000",
                                                    }}
                                                  />
                                                </Form.Group>
                                              </div> */}
                                              <div className="col-md-2 refund-column">
                                                <NumberFormat
                                                  required
                                                  type="text"
                                                  placeholder="Add Amount"
                                                  value={
                                                    item?.amount
                                                      ? item?.amount
                                                      : ""
                                                  }
                                                  isAllowed={(values) =>
                                                    MaxLimit(values)
                                                  }
                                                  onChange={(e) => {
                                                    handleAmount(
                                                      e,
                                                      index,
                                                      finalData
                                                    );
                                                  }}
                                                  decimalScale={2}
                                                  allowNegative={false}
                                                  thousandSeparator={true}
                                                  autoComplete="off"
                                                  onKeyPress={(e) => {
                                                    e.which === 13 &&
                                                      e.preventDefault();
                                                  }}
                                                  style={{
                                                    border: "1px solid #d9d9d9",
                                                    padding: "8px 10px",
                                                    fontSize: "14px",
                                                    width: "100%",
                                                    color: "#000",
                                                    borderRadius: "10px",
                                                    textAlign: "left",
                                                    letterSpacing: "0px",
                                                    lineHeight: "1",
                                                  }}
                                                />
                                                {/* <span className="text-danger">
                                                  {amountErrors[index]
                                                    ? amountErrors[index]
                                                    : ""}
                                                </span> */}
                                              </div>
                                              <div className="col-md-3 refund-column">
                                                <Form.Group>
                                                  <textarea
                                                    name="description"
                                                    placeholder="Add Description"
                                                    rows={2}
                                                    value={
                                                      item?.description
                                                        ? item?.description
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleDescription(
                                                        e,
                                                        index,
                                                        finalData
                                                      );
                                                      // handleBlurDescription(
                                                      //   e,
                                                      //   index
                                                      // );
                                                    }}
                                                  />
                                                  {/* <span className="error">
                                                    {descriptionErrors[index]
                                                      ? descriptionErrors[index]
                                                      : ""}
                                                  </span> */}
                                                </Form.Group>
                                              </div>
                                              <div className="col-md-1 receipt-column">
                                                {/* {index + 1 ===
                                                finalData?.length ? (
                                                  <>
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleAddEntry(
                                                          finalData,
                                                          index
                                                        );
                                                      }}
                                                      icon={faAdd}
                                                      size="1x"
                                                    />
                                                    {index > 0 && (
                                                      <>
                                                        <span></span>
                                                        &nbsp;&nbsp;/&nbsp;&nbsp;
                                                        <FontAwesomeIcon
                                                          onClick={() => {
                                                            handleDeleteEntry(
                                                              finalData,
                                                              index
                                                            );
                                                          }}
                                                          icon={faTrash}
                                                          size="1x"
                                                        />
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <FontAwesomeIcon
                                                      onClick={() => {
                                                        handleDeleteEntry(
                                                          finalData,
                                                          index
                                                        );
                                                      }}
                                                      icon={faTrash}
                                                      size="1x"
                                                    />
                                                  </>
                                                )} */}
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                    <div className="refund-card-content-btn-small">
                                      {console.log(
                                        handlePostDisabled(finalData)
                                      )}
                                      <Button
                                        className="btn-small"
                                        disabled={handlePostDisabled(finalData)}
                                        onClick={() => {
                                          handlePost(finalData);
                                        }}
                                      >
                                        Post
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Container>
                      </div>
                    </div>
                  </Form>
                  {/* </Spin> */}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default RecordReceipt;
