import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import {
  disabledFutureDates,
  formatName,
  pastFinancialdate,
  periods,
} from "../../common/Misc";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import { toast } from "react-toastify";
import { DateRangePicker } from "@semcore/date-picker";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../common/CustomTooltip";

const ArchivedTransactions = ({ setBalanceInfo, ProjectReadOnly }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const defaultFrom = query.get("from");
  const defaultTo = query.get("to");
  const [archivedTransactionData, setArchivedTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [deletedItem, setDeletedItem] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const [valueRange, setValueRange] = useState(
    defaultFrom && defaultTo
      ? [
          new Date(moment(defaultFrom, "DD-MM-YYYY")),
          new Date(moment(defaultTo, "DD-MM-YYYY")),
        ]
      : pastFinancialdate
  );

  // *API Call for Fetching Archived Transaction List - Start
  const fetchArchivedTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_LIST}?builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}&deleted=1&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&from=${
          valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
        }&to=${valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""}`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setBalanceInfo(data?.accounts);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setArchivedTransactionData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=audit-trail&child_tab=archived-transactions&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&from=${
        valueRange ? moment(valueRange[0]).format("DD-MM-YYYY") : ""
      }&to=${valueRange ? moment(valueRange[1]).format("DD-MM-YYYY") : ""}`
    );
  }, [
    projectId,
    limit,
    page,
    navigate,
    setBalanceInfo,
    trustType,
    trust_type,
    valueRange,
  ]);

  useEffect(() => {
    fetchArchivedTransactions();
  }, [fetchArchivedTransactions]);
  // *API Call for Fetching Archived Transaction List - End

  // *Archived Transaction List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const archivedTransactionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: archivedTransactionData[i]?.id,
        date: archivedTransactionData[i]?.date
          ? moment(archivedTransactionData[i]?.date, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            )
          : "-",
        description: archivedTransactionData[i]?.description
          ? archivedTransactionData[i]?.description
          : "-",
        credit:
          archivedTransactionData[i]?.baseType === 1 ? (
            <span className="rag-green">
              $
              {archivedTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        debit:
          archivedTransactionData[i]?.baseType === 0 ? (
            <span className="rag-red">
              $
              {archivedTransactionData[i]?.amount?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            "-"
          ),
        bank_transaction_source: archivedTransactionData[i]?.source
          ? archivedTransactionData[i]?.source
          : "-",
        archived_by: archivedTransactionData[i]?.user
          ? formatName(
              archivedTransactionData[i]?.user?.first_name,
              archivedTransactionData[i]?.user?.last_name
            )
          : "-",
        action: (
          <div style={{ textAlign: "center" }}>
            <CustomTooltip message={"Delete"}>
              <span>
                <span>
                  <FontAwesomeIcon
                    onClick={() =>
                      toggleDeleteModal(archivedTransactionData[i]?.id)
                    }
                    icon={faTrash}
                    size="1x"
                  />
                </span>
              </span>
            </CustomTooltip>
          </div>
        ),
      });
    }
    return items;
  };

  const archivedTransactions = archivedTransactionGenerator(
    archivedTransactionData?.length
  );

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = ProjectReadOnly
    ? [
        {
          dataField: "date",
          text: "Date",
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "credit",
          text: "Credit",
        },
        {
          dataField: "debit",
          text: "Debit",
        },
        {
          dataField: "archived_by",
          text: "Archived By",
        },
        {
          dataField: "bank_transaction_source",
          text: "Bank Transaction Source",
        },
      ]
    : [
        {
          dataField: "date",
          text: "Date",
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "credit",
          text: "Credit",
        },
        {
          dataField: "debit",
          text: "Debit",
        },
        {
          dataField: "archived_by",
          text: "Archived By",
        },
        {
          dataField: "bank_transaction_source",
          text: "Bank Transaction Source",
        },
        {
          dataField: "action",
          text: "Action",
        },
      ];

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelectAll: (row, isSelect) => {
      if (row) {
        const ids =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect?.map((item) => {
            return item?.key;
          });
        setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
      } else {
        const removableData =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect.map(function (data) {
            return data?.key;
          });
        setSelectedRowIds(
          selectedRowIds
            ? selectedRowIds.filter((item) => !removableData.includes(item))
            : []
        );
      }
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.key]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.key));
      }
    },
  };
  // *Archived Transaction List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  // *API Call for Archived Transaction Permanent Delete - Start
  const toggleDeleteModal = (id) => {
    setShowDelete(!showDelete);
    setDeletedItem(id);
  };

  const toggleDeleteAllModal = () => {
    setShowDeleteAll(!showDeleteAll);
  };

  const handleDeleteTransaction = async () => {
    if (deletedItem) {
      const formData = new FormData();
      formData.append(`ids[0]`, deletedItem);
      formData.append("builder_project_id", projectId);
      await fileUpload(`${API.PERMANETALY_DELETE_BANK_STATEMENT}`, formData)
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.errors?.myna_error) {
            toast.error(response?.data?.errors?.myna_error[0]);
          }
          if (response?.status === 200) {
            fetchArchivedTransactions();
            setTimeout(() => {
              setDeletedItem("");
              setShowDelete(!showDelete);
            }, 700);
          }
        })
        .catch((e) => {
          const { data } = e.response;
          toast.error(
            data.errors && data.errors.myna_error && data.errors.myna_error[0]
          );
          toast.error(e?.data?.message);
        });
    }
  };

  const handleDeleteAll = async () => {
    const formData = new FormData();
    if (selectedRowIds?.length > 0) {
      for (let i = 0; i < selectedRowIds?.length; i++) {
        formData.append(`ids[${i}]`, selectedRowIds[i]);
      }
    }
    formData.append("builder_project_id", projectId);
    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.PERMANETALY_DELETE_BANK_STATEMENT}`,
        formData
      );
      setLoading(false);
      if (data) {
        setSelectedRowIds([]);
        toast.success(data?.message);
        toggleDeleteAllModal();
        fetchArchivedTransactions();
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleDateRange = (e) => {
    setValueRange(e);
    setPage(1);
  };
  // *API Call for Archived Transaction Permanent Delete - End

  return (
    <>
      <div
        className="table-top-btn archived-transactions-date-range"
        style={{
          justifyContent: "end",
        }}
      >
        <Form>
          <DateRangePicker
            placeholder="Select Date Range"
            value={valueRange}
            onChange={(e) => handleDateRange(e)}
            disabled={disabledFutureDates}
            periods={periods}
          />
        </Form>
        {!ProjectReadOnly && (
          <Button
            variant="primary"
            onClick={toggleDeleteAllModal}
            style={{
              padding: "10px 15px",
              marginLeft: "15px",
            }}
          >
            {selectedRowIds?.length > 0 ? "Delete Selected" : "Delete All"}
          </Button>
        )}
      </div>
      <div className="cms-page audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="key"
          remote
          data={archivedTransactions}
          columns={columns}
          selectRow={selectRow}
          noDataIndication="No Data Found"
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={Number(page)}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
      {showDeleteAll && (
        <Modal
          size="lg"
          show={showDeleteAll}
          onHide={toggleDeleteAllModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header>Delete all Bank Transactions</Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              <p>
                Are you sure you want to delete{" "}
                {selectedRowIds?.length === 0 ? "all" : "selected"} bank
                transactions permanently?
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteAllModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleDeleteAll();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showDelete &&
        <Modal
          show={showDelete && deletedItem}
          onHide={() => toggleDeleteModal("")}
          size="lg"
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header>Bank Transaction</Modal.Header>
          <div className="modal-body" style={{ textAlign: "center" }}>
            <p>
              Are your sure you want to delete this transaction permanently?
            </p>
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={handleDeleteTransaction}
            >
              OK
            </Button>
          </div>
        </Modal>
      }
    </>
  );
};

export default ArchivedTransactions;
