import React, { useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { useEffect } from "react";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleUserModal,
  officeListing,
  officeLoading,
  roleLoading,
  roleListing,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleUserModal: PropTypes.any,
    officeListing: PropTypes.any,
    officeLoading: PropTypes.any,
    roleLoading: PropTypes.any,
    roleListing: PropTypes.any,
  };

  const [isChecked, setIsChecked] = useState(values?.is_admin_user);

  useEffect(() => {
    setIsChecked(values?.is_admin_user);
  }, [values?.is_admin_user]);

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>First Name
          </label>
          <input
            type="text"
            name="first_name"
            value={values?.first_name ? values?.first_name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="first_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Last Name
          </label>
          <input
            type="text"
            name="last_name"
            value={values?.last_name ? values?.last_name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="last_name" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Email
          </label>
          <input
            type="text"
            name="email"
            value={values?.email ? values?.email : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="email" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Role
          </label>

          <Select
            name="role_id"
            value={values?.role_id ? values?.role_id : ""}
            onChange={(e) => {
              setFieldValue(`role_id`, e ? e : "");
            }}
            onBlur={handleBlur}
            options={roleListing}
            classNamePrefix="selectbox"
            placeholder="Select Role"
            isLoading={roleLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="role_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Office
          </label>

          <Select
            name="office_id"
            value={values?.office_id ? values?.office_id : ""}
            onChange={(e) => {
              setFieldValue(`office_id`, e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={officeListing}
            classNamePrefix="selectbox"
            placeholder="Select Office"
            isLoading={officeLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <input
            type="checkbox"
            name="is_admin_user"
            value={values?.is_admin_user}
            checked={isChecked}
            onClick={(e) => {
              setIsChecked(!isChecked);
              setFieldValue("is_admin_user", !isChecked);
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />{" "}
          <label>Is Admin User?</label>
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="is_admin_user" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          type="Button"
          onClick={() => {
            toggleUserModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
