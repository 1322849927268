import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import BackButton from "../../../components/Form/BackButton";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import { MonthRangePicker } from "@semcore/date-picker";

import {
  checkIfFileExists,
  formatNumber,
  pastFinancialdate,
} from "../../common/Misc";
import { getProjectData } from "../components/ProjectHelper";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Spin from "../../common/Spin";

const GeneralLedgerReport = () => {
  const [finalData, setFinalData] = useState();
  const params = useParams();
  const trustType = params?.trust_type === "project" ? 1 : 0;
  const projectId = params?.project_id;
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [valueRange, setValueRange] = useState(pastFinancialdate);
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);
  const fetchGeneralLedgerReportData = useCallback(async () => {
    if (projectId) {
      try {
        const formData = new FormData();

        formData.append(`builder_project_id`, projectId ? projectId : "");
        formData.append(`trust_type`, trustType);
        formData.append(
          `from`,
          moment(valueRange[0]).format("MM-YYYY")
            ? moment(valueRange[0]).format("MM-YYYY")
            : ""
        );
        formData.append(
          `to`,
          moment(valueRange[1]).format("MM-YYYY")
            ? moment(valueRange[1]).format("MM-YYYY")
            : ""
        );

        setLoading(true);
        const { data } = await fileUpload(
          `${API.GENERAL_LEDGER_REPORT}`,
          formData
        );
        setFinalData(data?.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [projectId, trustType, valueRange]);

  const handleExport = async () => {
    if (projectId) {
      try {
        setExportLoading(true);
        const formData = new FormData();

        formData.append(`builder_project_id`, projectId ? projectId : "");
        formData.append(`trust_type`, trustType);
        formData.append(`export`, 1);
        formData.append(
          `from`,
          moment(valueRange[0]).format("MM-YYYY")
            ? moment(valueRange[0]).format("MM-YYYY")
            : ""
        );
        formData.append(
          `to`,
          moment(valueRange[1]).format("MM-YYYY")
            ? moment(valueRange[1]).format("MM-YYYY")
            : ""
        );

        // setLoading(true);
        const { data } = await fileUpload(
          `${API.GENERAL_LEDGER_REPORT}`,
          formData
        );
        const export_report_path = data?.data?.file;
        if (export_report_path) {
          var a = document.createElement("a");
          a.href = export_report_path;
          var file = export_report_path.split("/");
          a.setAttribute(
            "download",
            file[file.length - 1] || "transactionsreport.pdf"
          );
          a.setAttribute("target", "_blank");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          setExportLoading(false);
        }
      } catch (e) {
        setExportLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  useEffect(() => {
    fetchGeneralLedgerReportData();
  }, [fetchGeneralLedgerReportData]);

  let tomorrow = new Date();
  tomorrow.setMonth(tomorrow.getMonth() + 1);

  return (
    <div className="cms-page">
      {/* <Workinprogrees /> */}
      {loading && <Spin />}
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trustType === 0
              ? "RTA General Ledger Report"
              : "PTA General Ledger Report"}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <div className="">
              <div className="table-top-picker">
                <MonthRangePicker
                  value={valueRange}
                  onChange={(date) => {
                    setValueRange(date);
                  }}
                  className="datefilter"
                  disabled={[[tomorrow, false]]}
                />
                <Button onClick={() => handleExport()}>
                  {exportLoading ? "Loading…" : "Export"}
                </Button>
              </div>
            </div>
            <div className="react-bootstrap-table">
              <table className="table table-bordered receipt-table">
                <thead>
                  <tr>
                    <th
                      colSpan={6}
                      style={{
                        textAlign: "center",
                        color: "white",
                      }}
                      className="highlighted-main-row"
                    >
                      Trust Account Ledger for{" "}
                      {trustType === 1
                        ? "Project Trust Account"
                        : "Retention Trust Account"}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ borderRadius: "0px" }}>Date</th>
                    <th>Reference/ID</th>
                    <th>Transaction Details</th>
                    <th>Corresponding Account</th>
                    <th>Debit(Credit)</th>
                    <th style={{ borderRadius: "0px" }}>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {finalData?.map((data, i) => {
                    return (
                      <>
                        {i > 0 && (
                          <tr>
                            <td colSpan={6}></td>
                          </tr>
                        )}
                        <tr>
                          <td></td>
                          <td></td>
                          <td style={{ fontWeight: "bold" }}>{data?.name}</td>
                          <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>
                            Opening Balance
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {data?.opening_balance
                              ? data?.opening_balance < 0
                                ? `$${formatNumber(
                                    Number(data?.opening_balance) * -1
                                  )}`
                                : `($${formatNumber(
                                    Number(data?.opening_balance)
                                  )})`
                              : "$0.00"}
                          </td>
                        </tr>
                        {data?.transactions?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{item?.transaction_date}</td>
                                <td>{item?.audit_number}</td>
                                <td>{item?.description}</td>
                                <td>{item?.corresponding_account[0]?.name}</td>
                                <td>
                                  {item?.transaction_type === 0
                                    ? `$${formatNumber(Number(item?.amount))}`
                                    : `($${formatNumber(
                                        Number(item?.amount)
                                      )})`}
                                </td>
                                <td>
                                  {item?.closing_balance
                                    ? item?.closing_balance > 0
                                      ? `($${formatNumber(
                                          Number(item?.closing_balance)
                                        )})`
                                      : `$${formatNumber(
                                          Math.abs(
                                            Number(item?.closing_balance)
                                          )
                                        )}`
                                    : "$0.00"}
                                </td>
                              </tr>
                              {data?.transactions?.length - 1 === index && (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td
                                    colSpan={2}
                                    style={{ textAlign: "right", fontWeight: "bold" }}
                                  >
                                    Closing Balance
                                  </td>
                                  <td style={{ fontWeight: "bold" }}>
                                    {item?.closing_balance
                                      ? item?.closing_balance > 0
                                        ? `($${formatNumber(
                                            Number(item?.closing_balance)
                                          )})`
                                        : `$${formatNumber(
                                            Math.abs(
                                              Number(item?.closing_balance)
                                            )
                                          )}`
                                      : "$0.00"}
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralLedgerReport;
