import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { API, defaultLimitPagination, get } from "../../../config";
import moment from "moment";
import Spin from "../../common/Spin";
import BankTransactionByBatch from "./components/BankTransactionByBatch";

import UserDetailPopup from "./components/UserDetailsPopup";
import Pagination from "../../common/Pagination";

const ImportHistory = ({ setBalanceInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [bankTransactionBatches, setBankTransactionBatches] = useState({});
  const [loading, setLoading] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [userId, setUserId] = useState([]);
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const toggleShowModal = () => {
    setUserModal(false);
  };

  // *API Call for Bank Transaction Batches List - Start
  const fetchBankTransactionsBatches = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.BANK_TRANSACTION_BATCHES}?builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}&limit=${limit ? limit : ""}&page=${
          page ? page : ""
        }`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setTotal(data?.data?.total);
      setBalanceInfo(data?.accounts);
      setBankTransactionBatches(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [projectId, setBalanceInfo, limit, page, trust_type]);

  useEffect(() => {
    fetchBankTransactionsBatches();
  }, [fetchBankTransactionsBatches]);
  // *API Call for Bank Transaction Batches List - End

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=audit-trail&child_tab=import-history&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}`
    );
  }, [navigate, projectId, page, limit, trustType]);

  // *Bank Transaction Batches List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const bankTransactionBatchesGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: bankTransactionBatches[i]?.id,
        batch_id: bankTransactionBatches[i]?.id
          ? bankTransactionBatches[i]?.id
          : "",
        date: bankTransactionBatches[i]?.created_at
          ? moment(
              bankTransactionBatches[i]?.created_at,
              "DD-MM-YYYY HH:mm:ss"
            ).format("DD-MM-YYYY")
          : "-",
        description: bankTransactionBatches[i]?.description ? (
          <span
            onClick={(e) => {
              const id = e?.target?.attributes?.["data-id"];
              setUserModal(true);
              setUserId(id.value);
            }}
            data-toggle="modal"
            data-target="#userDetail"
            dangerouslySetInnerHTML={{
              __html: bankTransactionBatches[i]?.description,
            }}
          ></span>
        ) : (
          "-"
        ),
        total_records: bankTransactionBatches[i]?.total_records
          ? bankTransactionBatches[i]?.total_records.toLocaleString("en-US")
          : "",
      });
    }
    return items;
  };

  const bankTransactionBatchesData = bankTransactionBatchesGenerator(
    bankTransactionBatches?.length
  );

  const columns = [
    {
      dataField: "date",
      text: "Date",
      style: { width: "15%" },
    },
    {
      dataField: "description",
      text: "Description",
      style: { width: "70%" },
    },
    {
      dataField: "total_records",
      text: "Number Of Records",
      align: "right",
      style: { width: "15%" },
    },
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const expandRow = {
    renderer: (row) => (
      <>
        <BankTransactionByBatch id={row?.batch_id} />
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };
  // *Bank Transaction Batches List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  return (
    <>
      <div className="audit-statement">
        {loading && <Spin />}
        <BootstrapTable
          keyField="batch_id"
          remote
          data={bankTransactionBatchesData}
          columns={columns}
          noDataIndication="No Data Found"
          expandRow={expandRow}
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={page}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>

      {userModal && (
        <UserDetailPopup
          id={userId}
          toggleShowModal={toggleShowModal}
          userModal={userModal}
        />
      )}
    </>
  );
};

export default ImportHistory;
