import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { API, get, post } from "../../config";
import "../../components/Authentication/authentication.css";
import RegisterAuthentication from "../../components/Authentication/RegisterAuthentication";
import ConfirmAuthentication from "../../components/Authentication/ConfirmAuthentication";
import AddBackupEmail from "../../components/Authentication/AddBackupEmail";
import ConfirmBackupEmail from "../../components/Authentication/ConfirmBackupEmail";

const TwoFactorRegister = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(0);
  const [enterCode, setEnterCode] = useState(false);
  const [enterEmail, setEnterEmail] = useState(false);
  const [qrImage, setQrImage] = useState(null);
  const [secretKey, setSecretKey] = useState(null);
  const [backupEmail, setBackupEmail] = useState("");

  // *API for 2FA Register - Start
  useEffect(() => {
    post(API.GOOGLE_2FA_REGISTER)
      .then((response) => {
        setQrImage(response.data.QR_Image);
        setSecretKey(response.data.secret);
      })
      .catch(() => {});
  }, []);
  // *API for 2FA Register - End

  // *Verfiy 2FA Code and Email Code using API - Start
  const verifyAuthentication = () => {
    setCurrent(current + 1);
  };

  const submitEmail = () => {
    setEnterEmail(true);
  };

  const verifyEmail = async () => {
    localStorage.setItem("bt-auth-tfa", 2);
    navigate("/builders", { replace: true });
    if (!localStorage.getItem("Name")) {
      let userData = await get(API.GET_USER_PROFILE);
      if (userData) {
        const { first_name, profile_pic, last_name, id, role } =
          userData?.data?.data;
        localStorage.setItem("Name", first_name ? first_name : last_name);
        localStorage.setItem("ProfilePhoto", profile_pic);
        localStorage.setItem("role", role?.name);
        localStorage.setItem("roleType", role?.type);
        localStorage.setItem("role-slug", role?.slug);
        localStorage.setItem("userId", id);
        localStorage.setItem("EmailVerification", 1);
        localStorage.setItem("bt-auth-tfa", 2);
        if (role?.type === "Builder") {
          navigate("/projects", {
            replace: true,
          });
        } else {
          navigate("/builders", {
            replace: true,
          });
        }
      } else {
        var error = {};

        error["bt_error"] =
          "There is some issue with request, please try after some time.";
      }
    } else {
      if (searchParams.get("page") === "back") {
        localStorage.setItem("bt-auth-tfa", 2);
        navigate(-1);
      }
    }
  };
  // *Verfiy 2FA Code and Email Code using API - End

  const contentStepOne = () => {
    return (
      <>
        {!enterCode ? (
          <RegisterAuthentication
            qrImage={qrImage}
            secretKey={secretKey}
            setEnterCode={setEnterCode}
          />
        ) : (
          <>
            <ConfirmAuthentication
              submitButton={verifyAuthentication}
              verifyUrl={API.GOOGLE_2FA_VERIFY}
            />
            <Link
              onClick={() => {
                setEnterEmail(false);
                setEnterCode(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </Link>
          </>
        )}
      </>
    );
  };
  const contentStepTwo = () => {
    return (
      <>
        {!enterEmail ? (
          <AddBackupEmail
            backupEmail={backupEmail}
            setBackupEmail={setBackupEmail}
            submitEmail={submitEmail}
          />
        ) : (
          <>
            <ConfirmBackupEmail
              backupEmail={backupEmail}
              submitButton={verifyEmail}
              verifyUrl={API.VERIFY_BACKUP_EMAIL}
            />

            <Link
              onClick={() => {
                setEnterEmail(false);
              }}
            >
              I didn&apos;t receive a code please resend
            </Link>
          </>
        )}
      </>
    );
  };

  const steps = [
    {
      title: "Set up app",
      content: contentStepOne(),
    },
    {
      title: "Add a backup",
      content: contentStepTwo(),
    },
  ];

  if (!qrImage) return null;
  return (
    <>
      <div className="tfa-content">
        <div className="steps-content">{steps[current].content}</div>
      </div>
    </>
  );
};

export default TwoFactorRegister;
