// import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
// import Select from "react-select";
import ChartOfAccountSelect from "../../../common/ChartOfAccountSelect";
// import ContractorSelectWithTA from "../../../common/ContractorSelectWithTA";
import { formatName, formatSubcontractorName } from "../../../common/Misc";

const NewExpandedRowForReconcileTransaction = ({
  projectId,
  data,
  handleAccountSelection,
  GSTOptions,
  handleGSTSelection,
  handleContactNameSelection,
  handleDescription,
  handleBlurDescription,
  handleAmount,
  handleBlurAmount,
  handleSplitAddAccount,
  setSplitDescription,
  splitGstVal,
  setSplitGst,
  setSplit1Amount,
  setSplit2Amount,
  handleSplitOkDisable,
  handleSubmitSplit,
  split2Amount,
  split1Amount,
  handleReconcileDisable,
  handleSplitDisable,
  splitIcon,
  itemId,
  isUnderReviewed,
  selectedItems,
  handleCheckBox,
  splitDescription,
  setSplitGstVal,
  splitGst,
  handleSplitAddContractor,
  splitContractor,
  //retention-list-view
  trust_type,
  dataForList,
  showSearchIndexsForList,
  searchRecord,
  setSearchRecord,
  setBankReconcilationId,
  setShowSearchIndexsForList,
  setShowSearchPopUp,
}) => {
  const listViewDataGenerator = (quantity) => {
    const items = [];
    for (let i = 1; i < quantity; i++) {
      items.push({
        key: data?.id,

        check: "",
        date: "",
        description: "",
        debit: "",
        credit: "",
        review_notes: "",
        //retention-list-view
        project: "",

        account:
          data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[1]
            ?.chart_of_account_id ? (
            <ChartOfAccountSelect
              regionName={i.toString()}
              name="chart_of_account_id"
              onChange={(account) =>
                handleAccountSelection(
                  account,
                  data?.bank_transaction_data?.bank_reconciliation[1].id,
                  data?.bank_transaction_data?.bank_reconciliation[1],
                  data
                )
              }
              className={"contactname-select"}
              projectId={
                data?.bank_transaction_data?.bank_reconciliation[0]
                  ?.builder_project_id
              }
              value={
                data?.bank_transaction_data?.bank_reconciliation[1]
                  ?.chart_of_account
                  ? {
                      id: data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account?.id
                        ? data?.bank_transaction_data?.bank_reconciliation[1]
                            ?.chart_of_account?.id
                        : null,
                      label:
                        data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account?.name +
                        (data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account?.account_type?.name
                          ? " (" +
                            data?.bank_transaction_data?.bank_reconciliation[1]
                              ?.chart_of_account?.account_type?.name +
                            ")"
                          : "") +
                        (data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account?.code
                          ? " - " +
                            data?.bank_transaction_data?.bank_reconciliation[1]?.chart_of_account?.code.toString()
                          : "") +
                        (data?.bank_transaction_data?.bank_reconciliation[1]
                          ?.chart_of_account?.sub_code
                          ? "." +
                            data?.bank_transaction_data?.bank_reconciliation[1]?.chart_of_account?.sub_code.toString()
                          : ""),
                      tax: data?.bank_transaction_data?.bank_reconciliation[1]
                        ?.chart_of_account?.tax,
                    }
                  : null
              }
              placeholder={"Select Account"}
              isClearable={false}
            />
          ) : (
            <ChartOfAccountSelect
              regionName={i.toString()}
              name="chart_of_account_id"
              onChange={(account) => handleSplitAddAccount(account)}
              className={"contactname-select"}
              projectId={
                data?.bank_transaction_data?.bank_reconciliation[0]
                  ?.builder_project_id
              }
              placeholder={"Select Account"}
              isClearable={false}
            />
          ),
        contact_name: (
          <>
            <input
              regionName={i.toString()}
              placeholder="Select Contractor"
              className="contactname-select-split"
              readOnly
              style={{
                border: "1px solid #d9d9d9",
                padding: "5px 15px",
                fontSize: "14px",
                width: "calc(100%)",
                borderRadius: "6px",
              }}
              onClick={() => {
                const oldIndex = [...showSearchIndexsForList];
                if (
                  showSearchIndexsForList.indexOf(
                    data?.bank_transaction_data.id
                  ) === -1
                ) {
                  oldIndex.push(data?.bank_transaction_data.id);
                } else {
                  oldIndex.splice(
                    showSearchIndexsForList.indexOf(
                      data?.bank_transaction_data.id
                    ),
                    1
                  );
                }
                setShowSearchIndexsForList(oldIndex);
                setSearchRecord(data?.bank_transaction_data);
                setShowSearchPopUp(true);
                setBankReconcilationId(
                  data?.bank_transaction_data?.bank_reconciliation[i]?.id
                );
              }}
              defaultValue={
                splitContractor
                  ? splitContractor?.is_pta === 0
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (RTA)`
                    : splitContractor?.is_pta === 1
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (PTA)`
                    : splitContractor?.is_pta === 2
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Withdrawal/General Business)`
                    : splitContractor?.is_pta === 3
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Principal)`
                    : splitContractor?.is_pta === 4
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Trustee)`
                    : formatSubcontractorName(splitContractor)
                  : data?.bank_transaction_data?.bank_reconciliation[i]?.contact
                  ? data?.bank_transaction_data?.bank_reconciliation[i]?.contact
                      ?.is_pta === 0
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (RTA)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 1
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (PTA)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 2
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Withdrawal/General Business)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 3
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Principal)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 4
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Trustee)`
                    : formatSubcontractorName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact
                      )
                  : null
              }
              value={
                splitContractor
                  ? splitContractor?.is_pta === 0
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (RTA)`
                    : splitContractor?.is_pta === 1
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (PTA)`
                    : splitContractor?.is_pta === 2
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Withdrawal/General Business)`
                    : splitContractor?.is_pta === 3
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Principal)`
                    : splitContractor?.is_pta === 4
                    ? `${formatName(
                        splitContractor?.first_name,
                        splitContractor?.last_name
                      )} (Trustee)`
                    : formatSubcontractorName(splitContractor)
                  : data?.bank_transaction_data?.bank_reconciliation[i]?.contact
                  ? data?.bank_transaction_data?.bank_reconciliation[i]?.contact
                      ?.is_pta === 0
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (RTA)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 1
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (PTA)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 2
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Withdrawal/General Business)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 3
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Principal)`
                    : data?.bank_transaction_data?.bank_reconciliation[i]
                        ?.contact?.is_pta === 4
                    ? `${formatName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.first_name,
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact?.last_name
                      )} (Trustee)`
                    : formatSubcontractorName(
                        data?.bank_transaction_data?.bank_reconciliation[i]
                          ?.contact
                      )
                  : null
              }
            />
          </>
        ),
        comment:
          // data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[i].description ? (
            <Form.Control
              required
              type="text"
              id={data?.bank_transaction_data?.bank_reconciliation[i].id}
              placeholder="Add Description"
              value={
                data?.bank_transaction_data?.bank_reconciliation[i].description
                  ? data?.bank_transaction_data?.bank_reconciliation[i]
                      .description
                  : ""
              }
              onChange={(e) => {
                handleDescription(e, data?.bank_transaction_data);
              }}
              onBlur={() =>
                handleBlurDescription(
                  data?.bank_transaction_data?.bank_reconciliation[i].id,
                  data?.bank_transaction_data?.bank_reconciliation[i],
                  data,
                  data?.bank_transaction_data?.bank_reconciliation[i]
                    ?.chart_of_account_id
                )
              }
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ) : (
            <Form.Control
              required
              type="text"
              placeholder="Add Description"
              onChange={(e) => {
                setSplitDescription(e.target.value);
              }}
              value={splitDescription ? splitDescription : ""}
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Description can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ),
        amount:
          // data?.bank_transaction_data?.bank_reconciliation?.length === 2 &&
          data?.bank_transaction_data?.bank_reconciliation[i].amount ? (
            <Form.Control
              required
              type="text"
              id={data?.bank_transaction_data?.bank_reconciliation[i].id}
              placeholder="Add Amount"
              value={
                data?.bank_transaction_data?.bank_reconciliation[i].amount
                  ? data?.bank_transaction_data?.bank_reconciliation[i].amount
                  : ""
              }
              onChange={(e) => {
                handleAmount(e, data?.bank_transaction_data);
              }}
              onBlur={() =>
                handleBlurAmount(
                  data?.bank_transaction_data?.bank_reconciliation[i].id,
                  data?.bank_transaction_data?.bank_reconciliation[i],
                  data?.bank_transaction_data,
                  data?.bank_transaction_data?.bank_reconciliation[i]
                    ?.chart_of_account_id
                )
              }
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ) : (
            <Form.Control
              required
              type="text"
              placeholder="Add Amount"
              value={split2Amount ? split2Amount : ""}
              onChange={(e) => {
                setSplit2Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(data?.bank_transaction_data?.amount)
                      ? data?.bank_transaction_data?.amount
                      : e.target.value
                      ? e.target.value
                      : 0
                    : split2Amount
                );

                setSplit1Amount(
                  /^\d*(\.\d{0,2})?$/.test(e.target.value) === true
                    ? (parseFloat(e.target.value)
                        ? parseFloat(e.target.value)
                        : 0) > parseFloat(data?.bank_transaction_data?.amount)
                      ? 0.0
                      : (
                          parseFloat(data?.bank_transaction_data?.amount) -
                          (parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0)
                        ).toFixed(2)
                    : split1Amount
                );
              }}
              rules={[
                {
                  pattern: new RegExp(/^([0-9]+$)([.]?)/),
                  message: "Amount can contain numbers & dot(.)",
                },
              ]}
              style={{
                border: "1px solid #d9d9d9",
                padding: "8px 15px",
                fontSize: "14px",
              }}
            />
          ),
      });
    }
    return items;
  };

  const listViewData = listViewDataGenerator(
    data?.bank_transaction_data?.bank_reconciliation?.length
  );

  const columns = isUnderReviewed
    ? trust_type === 0
      ? [
          {
            dataField: "check",
            text: "",
            headerClasses: "table-header",
          },
          {
            dataField: "date",
            text: "Date",
            headerClasses: "table-header",
          },
          {
            dataField: "description",
            text: "Description",
            headerClasses: "table-header",
          },
          {
            dataField: "debit",
            text: "Debit",
            headerClasses: "table-header",
          },
          {
            dataField: "credit",
            text: "Credit",
            headerClasses: "table-header",
          },
          {
            dataField: "review_notes",
            text: "Review Notes",
            headerClasses: "table-header",
          },
          {
            dataField: "contact_name",
            text: "Subcontractor/Beneficiary",
            headerClasses: "table-header",
          },
          // {
          //   dataField: "account",
          //   text: "Account",
          //   headerClasses: "table-header",
          // },
          {
            dataField: "comment",
            text: "Comment",
            headerClasses: "table-header",
          },
          {
            dataField: "amount",
            text: "Amount",
            headerClasses: "table-header",
          },
          {
            dataField: "action",
            text: "Action",
            headerClasses: "table-header",
          },
        ]
      : [
          {
            dataField: "check",
            text: "",
            headerClasses: "table-header",
          },
          {
            dataField: "date",
            text: "Date",
            headerClasses: "table-header",
          },
          {
            dataField: "description",
            text: "Description",
            headerClasses: "table-header",
          },
          {
            dataField: "debit",
            text: "Debit",
            headerClasses: "table-header",
          },
          {
            dataField: "credit",
            text: "Credit",
            headerClasses: "table-header",
          },
          {
            dataField: "review_notes",
            text: "Review Notes",
            headerClasses: "table-header",
          },
          {
            dataField: "contact_name",
            text: "Subcontractor/Beneficiary",
            headerClasses: "table-header",
          },
          // {
          //   dataField: "account",
          //   text: "Account",
          //   headerClasses: "table-header",
          // },
          {
            dataField: "comment",
            text: "Comment",
            headerClasses: "table-header",
          },
          {
            dataField: "amount",
            text: "Amount",
            headerClasses: "table-header",
          },
          {
            dataField: "action",
            text: "Action",
            headerClasses: "table-header",
          },
        ]
    : trust_type === 0
    ? [
        {
          dataField: "check",
          text: "",
          headerClasses: "table-header",
        },
        {
          dataField: "date",
          text: "Date",
          headerClasses: "table-header",
        },
        {
          dataField: "description",
          text: "Description",
          headerClasses: "table-header",
        },
        {
          dataField: "debit",
          text: "Debit",
          headerClasses: "table-header",
        },
        {
          dataField: "credit",
          text: "Credit",
          headerClasses: "table-header",
        },
        {
          dataField: "contact_name",
          text: "Subcontractor/Beneficiary",
          headerClasses: "table-header",
        },
        // {
        //   dataField: "account",
        //   text: "Account",
        //   headerClasses: "table-header",
        // },
        {
          dataField: "comment",
          text: "Comment",
          headerClasses: "table-header",
        },
        {
          dataField: "amount",
          text: "Amount",
          headerClasses: "table-header",
        },
        {
          dataField: "action",
          text: "Action",
          headerClasses: "table-header",
        },
      ]
    : [
        {
          dataField: "check",
          text: "",
          headerClasses: "table-header",
        },
        {
          dataField: "date",
          text: "Date",
          headerClasses: "table-header",
        },
        {
          dataField: "description",
          text: "Description",
          headerClasses: "table-header",
        },
        {
          dataField: "debit",
          text: "Debit",
          headerClasses: "table-header",
        },
        {
          dataField: "credit",
          text: "Credit",
          headerClasses: "table-header",
        },
        {
          dataField: "contact_name",
          text: "Subcontractor/Beneficiary",
          headerClasses: "table-header",
        },
        // {
        //   dataField: "account",
        //   text: "Account",
        //   headerClasses: "table-header",
        // },
        {
          dataField: "comment",
          text: "Comment",
          headerClasses: "table-header",
        },
        {
          dataField: "amount",
          text: "Amount",
          headerClasses: "table-header",
        },
        {
          dataField: "action",
          text: "Action",
          headerClasses: "table-header",
        },
      ];

  return (
    <>
      <BootstrapTable
        keyField="key"
        remote
        data={listViewData}
        columns={columns}
        expandRow={false}
        noDataIndication="No Data Found"
      />
    </>
  );
};

export default NewExpandedRowForReconcileTransaction;
