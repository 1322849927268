import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, deleteCall } from "../../../../config";
import Spin from "../../../common/Spin";

const BuilderUserDelete = ({
  userId,
  toggleDeleteModal,
  handleDelete,
  showDelete
}) => {
  const [loading, setLoading] = useState(false);
  const handleUserDelete = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.BUILDER_USER}/${userId}`);
      setLoading(false);
      toast.success(data?.message);
      toggleDeleteModal();
      handleDelete();
    } catch (e) {
      setLoading(false);
      return null;
    }
  };
  return (
    <Modal
      size="lg"
      show={showDelete}
      onHide={toggleDeleteModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Delete User
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are your sure you want to delete this user?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleDeleteModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleUserDelete();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BuilderUserDelete;
