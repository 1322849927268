import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../components/Form/BackButton";
import { API, defaultLimitPagination, get } from "../../config";
import Pagination from "../common/Pagination";
import Spin from "../common/Spin";
import { debounce } from "lodash";
import OfficeActionButton from "./components/OfficeActionButton";
import AddOffice from "./AddOffice";

const Offices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [officesList, setOfficesList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");

  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showOffice, setShowOffice] = useState(false);

  // *API Call for Geting Offices - Start
  const fetchOffices = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(
        `${API.GET_OFFICE}?limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}`
      );
      setOfficeLoading(false);
      setLimit(data?.data?.offices?.per_page);
      setPage(data?.data?.offices?.current_page);
      setTotal(data?.data?.offices?.total);
      setFrom(data?.data?.offices?.from);
      setTo(data?.data?.offices?.to);
      const officesList = data?.data?.offices?.data;
      setOfficesList(officesList);
      return 0;
    } catch (error) {
      setOfficeLoading(false);
      setOfficesList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField]);

  const officeModal = () => {
    fetchOffices();
  };

  useEffect(() => {
    fetchOffices();
  }, [fetchOffices]);
  // *API Call for Geting Offices - End

  // *Offices Table Row and Column Generation, Filtering and Sorting of Record - Start
  const usersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: officesList[i]?.id,
        office_id: officesList[i]?.id ? officesList[i]?.id : "",
        suburb: officesList[i]?.suburb?.name
          ? officesList[i]?.suburb?.name
          : "-",
        state: officesList[i]?.state?.name ? officesList[i]?.state?.name : "-",
        street: officesList[i]?.street ? officesList[i]?.street : "-",
        postal_code: officesList[i]?.postal_code
          ? officesList[i]?.postal_code
          : "-",
        action: (
          <OfficeActionButton
            id={officesList[i]?.id}
            officeModal={officeModal}
          />
        ),
      });
    }
    return items;
  };
  const officesData = usersGenerator(officesList?.length);

  const columns = [
    {
      dataField: "street",
      text: "Street",
      sort: true,
    },
    {
      dataField: "suburb",
      text: "Suburb",
      // sort: true,
    },
    {
      dataField: "state",
      text: "State",
      // sort: true,
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      // sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Offices Table Row and Column Generation, Filtering and Sorting of Record - End

  useEffect(() => {
    navigate(
      `/offices?limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  const toggleOfficeModal = () => {
    showOffice && officeModal();
    setShowOffice(!showOffice);
  };

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Offices
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={(e) => handleSearchChange(e)}
                      type="text"
                      value={searchParamData}
                      placeholder="Search Offices"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleOfficeModal}>
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {officeLoading && <Spin />}
                <BootstrapTable
                  keyField="office_id"
                  remote
                  data={officesData}
                  columns={columns}
                  onTableChange={handleTableChange}
                  // expandRow={expandRow}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOffice && (
        <AddOffice
          toggleOfficeModal={toggleOfficeModal}
          officeModal={officeModal}
          showOffice={showOffice}
        />
      )}
    </>
  );
};

export default Offices;
