import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReconcileItemUndo from "./ReconcileItemUndo";
import CustomTooltip from "../../../common/CustomTooltip";

const ReconcileItemActionButton = ({ id, handleUndo, projectId }) => {
  const [showUndo, setShowUndo] = useState(false);

  const toggleShowModal = () => {
    handleUndo();
    setShowUndo(false);
  };

  const toggleUndoModal = () => {
    setShowUndo(!showUndo);
  };
  return (
    <>
      <span>
        <CustomTooltip message={"Undo"}>
          <span>
            <FontAwesomeIcon
              onClick={toggleUndoModal}
              icon={faUndo}
              size="1x"
              data-toggle="modal"
              data-target="#undoReconcileItem"
            />
          </span>
        </CustomTooltip>
      </span>

      {showUndo && (
        <ReconcileItemUndo
          itemId={id}
          toggleUndoModal={toggleUndoModal}
          handleUndo={toggleShowModal}
          showUndo={showUndo}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default ReconcileItemActionButton;
