import React from "react";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../../common/Spin";
import ReactSelect from "react-select";

const SyncContractor = ({
  syncOptionModal,
  toggleSyncOptionModal,
  loading,
  tentantLoading = false,
  syncOptionData = [],
  syncOptionId,
  setSyncOptionId,
  handleSyncData,
}) => {
  return (
    <>
      <Modal
        show={syncOptionModal}
        onHide={toggleSyncOptionModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="xpm-modal"
      >
        <Modal.Header closeButton>Xero Organisation</Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="modal-body" style={{ textAlign: "center" }}>
            <ReactSelect
              name="tenant_id"
              onChange={(e) => {
                setSyncOptionId(e);
              }}
              options={syncOptionData}
              placeholder="Select Xero Organisation"
              classNamePrefix="selectbox"
              isClearable={false}
              isLoading={tentantLoading}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="btn btn-secondary"
              onClick={toggleSyncOptionModal}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              disabled={syncOptionId ? false : true}
              onClick={() => {
                handleSyncData(syncOptionId);
              }}
            >
              OK
            </Button>
          </div>
          {/* </Spin> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SyncContractor;
