import React, { useEffect, useState } from "react";

import { API, fileUpload, get, imageDefaultPrefixPath } from "../../../config";
import {
  checkIfFileExists,
  // ExGSTClaculateWithouComma,
  formatNumber,
  // GSTClaculateWithouComma,
  MaxLimit,
} from "../../common/Misc";
import BackButton from "../../../components/Form/BackButton";
import { getProjectData } from "../components/ProjectHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import CancelButton from "../../../components/Form/CancleButton";
import { Button } from "react-bootstrap";

// import { DatePicker } from "@semcore/date-picker";
import ChartOfAccountSelect from "../../common/ChartOfAccountSelect";
import ContractorSelectWithTA from "../../common/ContractorSelectWithTA";
import moment from "moment";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import NumberFormat from "react-number-format";
import { DatePicker } from "rsuite";

const defaulEntryData = [
  {
    account: "",
    description: "",
    // gst: "",
    debit: "",
    credit: "",
    // tax: "",
    // exTax: "",
    contact: "",
  },
  {
    account: "",
    description: "",
    // gst: "",
    debit: "",
    credit: "",
    // tax: "",
    // exTax: "",
    contact: "",
  },
];

const newEntryData = {
  account: "",
  description: "",
  // gst: "",
  debit: "",
  credit: "",
  // tax: "",
  // exTax: "",
  contact: "",
  // notes: "",
};

const AddJournalEntry = () => {
  const GSTClaculateWithouComma = (value, tax) => {
    return tax === 1 ? Number((Number(value) / 1.1) * 0.1) : 0;
  };

  const ExGSTClaculateWithouComma = (value, tax) => {
    return tax === 1
      ? Number(Number(value) - (Number(value) / 1.1) * 0.1)
      : value;
  };

  const params = useParams();
  const navigate = useNavigate();

  const projectId = params?.project_id;

  const [loading, setLoading] = useState(false);

  const [entryData, setEntryData] = useState(defaulEntryData);
  const [errors, setErrors] = useState(defaulEntryData);

  const today = new Date();
  const [transactionDate, setTransactionDate] = useState(today);

  const [totalDeference, setTotalDeference] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [projectName, setProjectName] = useState();
  const [projectLogo, setProjectLogo] = useState();
  // const [refNumber, setRefNumber] = useState();
  // const [cashTransaction, setCashTransaction] = useState(false);
  const [period, setPeriod] = useState();
  const [accountContactLoading, setAccountContactLoading] = useState(false);
  const location = useLocation();
  const batch_ID = location?.state?.lastBatchId;
  const [batchID, setBatchID] = useState(batch_ID);
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (projectLogo && typeof projectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${projectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(projectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [projectLogo]);

  // const taxList = [
  //   { label: "GST 10%", value: 1 },
  //   { label: "No GST", value: 0 },
  // ];

  const taxList = [
    { label: "GST 10%", value: 1 },
    { label: "GST Excluded", value: 0 },
    { label: "BAS Excluded", value: 2 },
    { label: "GST on Imports", value: 3 },
  ];

  const handleAddEntry = (data, errorsData, index) => {
    setEntryData([...data, newEntryData]);
    setErrors([...errorsData, newEntryData]);
  };

  const handleDeleteEntry = (data, errorsData, index) => {
    data.splice(index, 1);
    errorsData.splice(index, 1);
    setEntryData([...data]);
    setErrors([...errorsData]);
  };

  const handleAccountChange = async (data, index, e) => {
    const amount = data[index]?.debit
      ? Number(data[index]?.debit)
      : data[index]?.credit
      ? Number(data[index]?.credit)
      : 0;
    const exTax = ExGSTClaculateWithouComma(amount, e?.tax);

    let finaltax = 0;
    if (amount > 0) {
      finaltax = GSTClaculateWithouComma(amount, e?.tax);
    } else {
      finaltax = 0;
    }

    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          account: e,
          gst: taxList?.find(
            (item) => parseInt(item?.value) === parseInt(e?.tax)
          ),
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2),
        };
      }
      return obj;
    });

    setEntryData(newArr);
  };

  const handleDescriptionChange = async (data, index, e) => {
    let regex = new RegExp(/^[^!@)(^%$<>][a-zA-Z\s\d.,#/&-]*$/);
    if (e) {
      const checkNote = regex.exec(e.target.value) ?? false;
      if (e.target.value && checkNote === false) {
        errors[index].description = "Enter valid Description.";
      } else {
        errors[index].description = null;
      }
    }
    setErrors([...errors]);

    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  // const handleGSTChange = async (data, index, e) => {
  //   const amount = data[index]?.debit
  //     ? Number(data[index]?.debit)
  //     : data[index]?.credit
  //     ? Number(data[index]?.credit)
  //     : 0;
  //   const exTax = ExGSTClaculateWithouComma(amount, e?.value);

  //   let finaltax = 0;
  //   if (amount > 0) {
  //     finaltax = GSTClaculateWithouComma(amount, e?.value);
  //   } else {
  //     finaltax = 0;
  //   }

  //   const newArr = data.map((obj, i) => {
  //     if (i === index) {
  //       return {
  //         ...obj,
  //         gst: e,
  //         tax: finaltax ? finaltax.toFixed(2) : 0,
  //         exTax: Number(exTax).toFixed(2),
  //       };
  //     }
  //     return obj;
  //   });
  //   setEntryData(newArr);
  // };

  // const handleDebitBlur = async (data, index, e) => {
  //   const value = Number(`${e.target.value}`.replace(/,/g, ""));
  //   const amount = e ? Number(value).toFixed(2) : 0;
  //   const tax = data[index]?.gst?.value === 1 ? 1 : 0;
  //   const exTax = ExGSTClaculateWithouComma(amount, tax);

  //   let finaltax = 0;
  //   if (amount > 0) {
  //     finaltax = GSTClaculateWithouComma(amount, tax);
  //   } else {
  //     finaltax = 0;
  //   }

  //   const newArr = data.map((obj, i) => {
  //     if (i === index) {
  //       return {
  //         ...obj,
  //         debit: amount !== "NaN" ? amount : 0,
  //         credit: "",
  //         tax: finaltax ? finaltax.toFixed(2) : 0,
  //         exTax: Number(exTax).toFixed(2),
  //       };
  //     }
  //     return obj;
  //   });
  //   setEntryData(newArr);
  // };
  const handleDebitChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""));
        const amount = e ? Number(value).toFixed(2) : 0;
        const tax = data[index]?.gst?.value;
        const exTax = ExGSTClaculateWithouComma(amount, tax);

        // return {
        //   ...obj,
        //   debit: value,
        //   credit: "",
        // };
        let finaltax = 0;
        if (amount > 0 && parseInt(tax) === 1) {
          finaltax = GSTClaculateWithouComma(amount, tax);
        } else {
          finaltax = 0;
        }
        return {
          ...obj,
          debit: value,
          credit: "",
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2),
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  // const handleCreditBlur = async (data, index, e) => {
  //   const value = Number(`${e.target.value}`.replace(/,/g, ""));
  //   const amount = e ? Number(value).toFixed(2) : 0;
  //   const tax = data[index]?.gst?.value === 1 ? 1 : 0;
  //   const exTax = ExGSTClaculateWithouComma(amount, tax);

  //   let finaltax = 0;
  //   if (amount > 0) {
  //     finaltax = GSTClaculateWithouComma(amount, tax);
  //   } else {
  //     finaltax = 0;
  //   }

  //   const newArr = data.map((obj, i) => {
  //     if (i === index) {
  //       return {
  //         ...obj,
  //         credit: amount !== "NaN" ? amount : 0,
  //         debit: "",
  //         tax: finaltax ? finaltax.toFixed(2) : 0,
  //         exTax: Number(exTax).toFixed(2),
  //       };
  //     }
  //     return obj;
  //   });
  //   setEntryData(newArr);
  // };
  const handleCreditChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""));
        const amount = e ? Number(value).toFixed(2) : 0;
        const tax = data[index]?.gst?.value;
        const exTax = ExGSTClaculateWithouComma(amount, tax);

        // return {
        //   ...obj,
        //   credit: value,
        //   debit: "",
        // };
        let finaltax = 0;
        if (amount > 0) {
          finaltax = GSTClaculateWithouComma(amount, tax);
        } else {
          finaltax = 0;
        }

        return {
          ...obj,
          credit: value,
          debit: "",
          tax: finaltax ? finaltax.toFixed(2) : 0,
          exTax: Number(exTax).toFixed(2),
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const fetchAccountbasedOnContact = async (search) => {
    try {
      setAccountContactLoading(true);
      const response = await get(
        `${API.GET_ACCOUNT_DATA}?builder_project_id=${projectId}&search=${
          search ? search : ""
        }`
      );
      let account =
        response?.data?.data?.data && response?.data?.data?.data?.length > 0
          ? response?.data?.data?.data.map((element) => {
              return {
                id: element.id,
                label:
                  element?.name +
                  (element?.account_type?.name
                    ? " (" + element?.account_type?.name + ")"
                    : "") +
                  (element?.code ? " - " + element?.code.toString() : "") +
                  (element?.sub_code ? "." + element?.sub_code.toString() : ""),
                tax: element?.tax,
              };
            })
          : [];
      setAccountContactLoading(false);
      return account[0];
    } catch (error) {
      setAccountContactLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleContactChange = async (data, index, e) => {
    let accountData =
      e?.is_pta === 1
        ? await fetchAccountbasedOnContact("2001")
        : e?.is_pta === 0
        ? await fetchAccountbasedOnContact("2003")
        : e?.is_pta === 3
        ? await fetchAccountbasedOnContact("2102")
        : e?.contact_type
        ? await fetchAccountbasedOnContact(
            e?.contact_type?.chart_of_account?.name
          )
        : await fetchAccountbasedOnContact("3049");
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return {
          ...obj,
          contact: e,
          account: accountData ? accountData : obj?.account,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  useEffect(() => {
    let debitTotal = 0;
    let creditTotal = 0;
    entryData &&
      entryData?.length > 0 &&
      entryData?.map((item) => {
        debitTotal += item?.debit ? Number(item?.debit) : 0;
        creditTotal += item?.credit ? Number(item?.credit) : 0;
        return 0;
      });
    setTotalDebit(formatNumber(Number(debitTotal)));
    setTotalCredit(formatNumber(Number(creditTotal)));
    let deference = debitTotal - creditTotal;
    setTotalDeference(formatNumber(Number(deference)));
  }, [entryData]);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append(
      "transaction_date",
      transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : ""
    );
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append("period", period ? period : "");
    // formData.append("reference_number", refNumber ? refNumber : "");
    formData.append("batch_id", batchID);
    formData.append("trust_type", trust_type);

    values?.forEach((item, index) => {
      if (item) {
        const debit = Number(`${item?.debit}`.replace(/,/g, ""));
        const credit = Number(`${item?.credit}`.replace(/,/g, ""));
        // const tax = Number(`${item?.tax}`.replace(/,/g, ""));
        // const exTax = Number(`${item?.exTax}`.replace(/,/g, ''));
        formData.append(
          `journalEntries[${index}][chart_of_account_id]`,
          item?.account?.id ? item?.account?.id : ""
        );

        formData.append(
          `journalEntries[${index}][contact_id]`,
          item?.contact?.id ? item?.contact?.id : ""
        );
        formData.append(
          `journalEntries[${index}][transaction_type]`,
          item?.credit ? 1 : 0
        );

        formData.append(
          `journalEntries[${index}][amount]`,
          credit ? credit : debit
        );
        // formData.append(
        //   `journalEntries[${index}][gst_type]`,
        //   item?.gst?.value ? item?.gst?.value : 0
        // );
        // formData.append(`journalEntries[${index}][gst_amount]`, tax ? tax : 0);
        // formData.append(`journalEntries[${index}][net_amount]`, exTax);
        formData.append(
          `journalEntries[${index}][description]`,
          item?.description ? item?.description : ""
        );
      }
    });

    try {
      setLoading(true);
      const response = await fileUpload(`${API.JOURNAL_ENTRIES}`, formData, {});
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errors = error?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const postDisabled = (values) => {
    if (values && values?.length > 1) {
      const checkData = values?.map((item, index) => {
        if (
          item?.account &&
          item?.contact &&
          (item?.debit || item?.credit) &&
          // item?.gst &&
          item?.description &&
          !errors[index]?.description &&
          totalDeference === 0 &&
          totalDebit !== 0
        ) {
          return false;
        } else {
          return true;
        }
      });
      return checkData?.find((item) => item === true) || !transactionDate
        ? true
        : false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    var month = moment(transactionDate).month() + 1;
    if (month === 7) {
      setPeriod(1);
    } else if (month === 8) {
      setPeriod(2);
    } else if (month === 9) {
      setPeriod(3);
    } else if (month === 10) {
      setPeriod(4);
    } else if (month === 11) {
      setPeriod(5);
    } else if (month === 12) {
      setPeriod(6);
    } else if (month === 1) {
      setPeriod(7);
    } else if (month === 2) {
      setPeriod(8);
    } else if (month === 3) {
      setPeriod(9);
    } else if (month === 4) {
      setPeriod(10);
    } else if (month === 5) {
      setPeriod(11);
    } else {
      setPeriod(12);
    }
  }, [transactionDate]);

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              New Journal Entry For PTA
              <div className="page-title-right">
                {projectLogo && (
                  <img src={`${imageDefaultPrefixPath}${projectLogo}`} alt="" />
                )}{" "}
                {projectName}
              </div>
            </h1>
            {(loading || accountContactLoading) && <Spin />}
            <div className="content-details jounal_entry">
              <div
                className={
                  totalDeference === "0.00" && totalDebit !== 0
                    ? "jounal-entry-card-completed"
                    : "jounal-entry-card"
                }
              >
                <span className="journal-entry-status">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="journal-table journal-table-header">
                        <label>
                          <span className="required">*</span>Batch No.
                        </label>
                        <NumberFormat
                          type="text"
                          placeholder="Enter Batch No."
                          value={batchID}
                          onChange={(e) => {
                            setBatchID(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="journal-table journal-table-header">
                        <label>Reference No.</label>
                        <input
                          type="text"
                          placeholder="Enter Reference No."
                          value={refNumber}
                          onChange={(e) => {
                            setRefNumber(e?.target?.value);
                          }}
                        />
                      </div>
                    </div> */}

                    <div className="col-md-4">
                      <div className="journal-date new-transaction-modal">
                        <label>
                          <span className="required">*</span>Date:
                        </label>
                        <DatePicker
                          name="transaction_date"
                          value={transactionDate ? transactionDate : ""}
                          onChange={(e) => {
                            setTransactionDate(e);
                          }}
                          className="rs-date-body"
                          cleanable={false}
                          placeholder="Select Date"
                          format="dd-MM-yyyy"
                          disabledDate={(date) =>
                            moment(date).isAfter(moment())
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      {/* <div className="journal-table journal-table-header">
                        <label>Period:</label>
                        <input
                          type="text"
                          placeholder="Period"
                          value={period}
                          disabled
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="journal-entry">
                    <p>
                      Total Debit
                      <i>{totalDebit ? totalDebit : 0.0}</i>
                    </p>
                    <p>
                      Total Credit<i>{totalCredit}</i>
                    </p>
                    <p>
                      Difference<i>{totalDeference}</i>
                    </p>
                  </div>
                </span>
                <div className="table table-bordered journal-table">
                  <div className="react-bootstrap-table journal-entries-table">
                    <table className="table-content">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="required">*</span>Contact
                          </th>
                          <th scope="col">
                            <span className="required">*</span>Account
                          </th>
                          <th scope="col">
                            <span className="required">*</span>Debit
                          </th>
                          <th scope="col">
                            <span className="required">*</span>Credit
                          </th>
                          {/* <th scope="col">GST</th>
                          <th scope="col">Tax</th>
                          <th scope="col">Ex. GST Amount</th> */}
                          <th scope="col">
                            <span className="required">*</span>Description
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {entryData &&
                          entryData?.length > 0 &&
                          entryData?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ width: "18%" }}>
                                  <ContractorSelectWithTA
                                    regionName={"0"}
                                    projectId={projectId}
                                    value={item?.contact ? item?.contact : null}
                                    onChange={(e) => {
                                      handleContactChange(entryData, index, e);
                                    }}
                                    business={true}
                                    className="contactname-select"
                                    placeholder="Select Contact"
                                    isClearable={false}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.contact
                                      ? errors[index]?.contact
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "15%" }}>
                                  <ChartOfAccountSelect
                                    name="account"
                                    regionName={"0"}
                                    onChange={(e) => {
                                      handleAccountChange(entryData, index, e);
                                    }}
                                    className="contactname-select"
                                    projectId={projectId}
                                    value={item?.account ? item?.account : null}
                                    placeholder="Select Account"
                                    isClearable={false}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.account
                                      ? errors[index]?.account
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    type="text"
                                    name="debit"
                                    value={item?.debit ? item?.debit : ""}
                                    onChange={(e) => {
                                      handleDebitChange(entryData, index, e);
                                    }}
                                    // onBlur={(e) => {
                                    //   handleDebitBlur(entryData, index, e);
                                    // }}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.debit
                                      ? errors[index]?.debit
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    type="text"
                                    name="credit"
                                    value={item?.credit ? item?.credit : ""}
                                    onChange={(e) => {
                                      handleCreditChange(entryData, index, e);
                                    }}
                                    // onBlur={(e) => {
                                    //   handleCreditBlur(entryData, index, e);
                                    // }}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.credit
                                      ? errors[index]?.credit
                                      : ""}
                                  </span>
                                </td>

                                {/* <td style={{ width: "10%" }}>
                                  <Select
                                    name={`gst`}
                                    value={item?.gst ? item?.gst : ""}
                                    onChange={(e) => {
                                      handleGSTChange(entryData, index, e);
                                    }}
                                    options={taxList}
                                    placeholder="Select GST"
                                    classNamePrefix="selectbox"
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary: "grey",
                                      },
                                    })}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.gst
                                      ? errors[index]?.gst
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    disabled
                                    type="text"
                                    name={`tax`}
                                    value={item?.tax ? item?.tax : 0}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td style={{ width: "10%" }}>
                                  <NumberFormat
                                    disabled
                                    type="text"
                                    name={`exTax`}
                                    value={item?.exTax ? item?.exTax : 0}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td> */}

                                <td style={{ width: "11%" }}>
                                  <textarea
                                    type="text"
                                    name={`description`}
                                    value={
                                      item?.description ? item?.description : ""
                                    }
                                    onChange={(e) => {
                                      handleDescriptionChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.description
                                      ? errors[index]?.description
                                      : ""}
                                  </span>
                                </td>

                                <td style={{ width: "6%" }}>
                                  {index + 1 === entryData?.length ? (
                                    <>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleAddEntry(
                                            entryData,
                                            errors,
                                            index
                                          );
                                        }}
                                        icon={faAdd}
                                        size="1x"
                                      />
                                      {index > 1 && (
                                        <>
                                          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              handleDeleteEntry(
                                                entryData,
                                                errors,
                                                index
                                              );
                                            }}
                                            icon={faTrash}
                                            size="1x"
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    index > 1 && (
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleDeleteEntry(
                                            entryData,
                                            errors,
                                            index
                                          );
                                        }}
                                        icon={faTrash}
                                        size="1x"
                                      />
                                    )
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="action-box">
                  <Button
                    disabled={postDisabled(entryData)}
                    onClick={() => {
                      handleSubmit(entryData);
                    }}
                  >
                    Post
                  </Button>
                  <CancelButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJournalEntry;
