import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import BackButton from "../../../components/Form/BackButton";
// import { DateRangePicker } from "rsuite";
import { toast } from "react-toastify";
import { API, defaultLimitPagination, fileUpload, imageDefaultPrefixPath } from "../../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// import { debounce } from "lodash";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import {
  checkIfFileExists,
  disabledFutureDates,
  formatPhone,
  // formatName,
  // pastFinancialdate,
  periods,
} from "../../common/Misc";
import { getProjectDetail } from "../../Builders/Projects/components/ProjectsHelper";
import { DateRangePicker } from "@semcore/date-picker";
// import Workinprogrees from "../../../components/Workinprogrees";

// const defaultDateRange = [
//   new Date(
//     new Date().getFullYear(),
//     new Date().getMonth() - 6,
//     new Date().getDate()
//   ),
//   new Date(),
// ];

const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const ContactStatements = () => {
  const navigate = useNavigate();
  const params = useParams();

  const trustType = params?.trust_type;

  // const { afterToday } = DateRangePicker;

  const projectId = params?.project_id;
  // const projectName = localStorage.getItem("projectName");
  // const projectLogo = localStorage.getItem("projectLogo");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");

  // const Data = [
  //   {
  //     id: 1,
  //     name: "George",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  //   {
  //     id: 2,
  //     name: "Nirav q Patel",
  //     email: "contractor@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$35.00",
  //   },
  //   {
  //     id: 3,
  //     name: "Nirav q Patel",
  //     email: "contractor@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$35.00",
  //   },
  //   {
  //     id: 4,
  //     name: "suzzan",
  //     email: "contractor@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$35.00",
  //   },
  //   {
  //     id: 5,
  //     name: "Milli",
  //     email: "contractor@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$35.00",
  //   },
  //   {
  //     id: 6,
  //     name: "christon",
  //     email: "contractor@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$35.00",
  //   },
  //   {
  //     id: 7,
  //     name: "George",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  //   {
  //     id: 8,
  //     name: "Wilson",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  //   {
  //     id: 9,
  //     name: "David",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  //   {
  //     id: 10,
  //     name: "kevin",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  //   {
  //     id: 11,
  //     name: "Catchy",
  //     email: "George@yopmail.com",
  //     mobile: "+61 7 8946 4543",
  //     phone: "+61 7 8946 4543",
  //     closing_balance: "$12.00",
  //   },
  // ];

  const [contactStaementData, setContactStaementData] = useState();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  // const [valueRange, setValueRange] = useState(pastFinancialdate);

  const [dateValue, setDateValue] = useState(defaultDateRange);
  const [startDate, setStartDate] = useState(
    defaultDateRange ? moment(defaultDateRange[0]).format("DD-MM-YYYY") : ""
  );
  const [endDate, setEndDate] = useState(
    defaultDateRange ? moment(defaultDateRange[1]).format("DD-MM-YYYY") : ""
  );
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [ProjectName, setProjectName] = useState("");
  const [ProjectLogo, setProjectLogo] = useState("");

  // useEffect(() => {
  //   getProjectDetail(projectId, setProjectName, setProjectLogo);
  // }, [projectId]);

  useEffect(() => {
    const getProjectDetails = async () => {
      const data = await getProjectDetail(projectId);
      setProjectName(data?.project_name);
      setProjectLogo(data.logo);
    };
    getProjectDetails();
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  const fetchContactItem = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CONTRACTOR_STATEMENT_REPORT}?builder_project_id=${projectId}
        &limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }
        &from=${startDate ? startDate : ""}&to=${
          endDate ? endDate : ""
        }&trust_type=${trustType === "project" ? 1 : 0}&export=0`
      );
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setContactStaementData(data?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setContactStaementData([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [
    projectId,
    limit,
    page,
    endDate,
    startDate,
    searchParam,
    sortField,
    sortOrder,
    trustType,
  ]);

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/contractor-statement-report?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }&from=${startDate ? startDate : ""}&to=${endDate ? endDate : ""}`
    );
  }, [
    navigate,
    projectId,
    limit,
    searchParam,
    sortField,
    sortOrder,
    page,
    startDate,
    endDate,
    trustType,
  ]);

  const contactStatementGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: contactStaementData[i]?.id ? contactStaementData[i]?.id : "",
        contact_id: contactStaementData[i]?.id
          ? contactStaementData[i]?.id
          : "-",
        name: contactStaementData[i]?.name,
        //  formatName(
        //   contactStaementData[i]?.first_name,
        //   contactStaementData[i]?.last_name
        // )
        email: contactStaementData[i]?.email
          ? contactStaementData[i]?.email
          : "-",
        // mobile: contactStaementData[i]?.mobile
        //   ? contactStaementData[i]?.mobile
        //   : "-",
        phone: contactStaementData[i]?.phone
          ? formatPhone(contactStaementData[i]?.phone)
          : "-",
        closing_balance: contactStaementData[i]?.closing_balance
          ? contactStaementData[i]?.closing_balance > 0
            ? `($${contactStaementData[i]?.closing_balance.toFixed(2)})`
            : "$" + Math.abs(contactStaementData[i]?.closing_balance).toFixed(2)
          : "$0.00",
      });
    }
    return items;
  };

  const contactStatements = contactStatementGenerator(
    contactStaementData?.length
  );

  useEffect(() => {
    fetchContactItem();
  }, [fetchContactItem]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    // {
    //   dataField: "mobile",
    //   text: "Mobile Number",
    //   sort: true,
    // },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "closing_balance",
      text: "Closing Balance",
      headerStyle: {
        textAlign: "right",
      },
      style: {
        textAlign: "right",
      },
    },
  ];

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    onSelectAll: (row, isSelect) => {
      if (row) {
        const ids =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect?.map((item) => {
            return item?.contact_id;
          });

        setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
      } else {
        const removableData =
          isSelect &&
          isSelect?.length > 0 &&
          isSelect.map(function (data) {
            return data?.contact_id;
          });
        setSelectedRowIds(
          selectedRowIds
            ? selectedRowIds.filter((item) => !removableData.includes(item))
            : []
        );
      }
    },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.contact_id]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.contact_id));
      }
    },
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchParam(value);
    setPage(1);
  };

  const handleDateRange = (e) => {
    setDateValue(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleExport = async () => {
    try {
      setExportLoading(true);
      const formData = new FormData();
      // formData.append("contact_report", 1);
      // selectedRowIds?.forEach((id) => {
      //   if (id) {
      //     formData.append("contact_ids[]", id);
      //   }
      // });
      if (selectedRowIds?.length > 0) {
        for (let i = 0; i < selectedRowIds?.length; i++) {
          formData.append(`contact_ids[${i}]`, selectedRowIds[i]);
        }
      }

      const { data } = await fileUpload(
        `${
          API.CONTRACTOR_STATEMENT_REPORT
        }?builder_project_id=${projectId}&from=${
          startDate ? startDate : ""
        }&to=${endDate ? endDate : ""}&export=1&trust_type=${
          trustType === "project" ? 1 : 0
        }`,
        formData
      );
      const export_statement_path = data?.data?.file;

      if (export_statement_path) {
        var a = document.createElement("a");
        a.href = export_statement_path;
        var file = export_statement_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "contact_statement_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Contractor's Statement Report
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            {/* <Workinprogrees /> */}
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => onSearchChange(e)}
                    type="text"
                    value={searchParam}
                    placeholder="Search Contractor"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              <div className="table-btn" style={{ flexWrap: "nowrap" }}>
                <Form>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    value={dateValue}
                    onChange={(e) => handleDateRange(e)}
                    disabled={disabledFutureDates}
                    periods={periods}
                  />
                </Form>
                <Button
                  onClick={() => {
                    handleExport();
                  }}
                >
                  {exportLoading ? "Loading..." : "Export"}
                  {/* Export */}
                </Button>
              </div>
            </div>
            {/* <div className="table-top-picker"> */}
            {/* <MonthRangePicker
                value={valueRange}
                onChange={(date) => {
                  setValueRange(date);
                }}
                className="datefilter"
                disabled={disabledFutureDates}
              /> */}
            {/* <Form>
                <DateRangePicker
                  placeholder="Select Date Range"
                  value={dateValue}
                  onChange={(e) => handleDateRange(e)}
                  disabled={disabledFutureDates}
                  periods={periods}
                />
              </Form> */}
            {/* </div> */}
            {/* <div
              className="summary-filter"
              style={{
                padding: "0",
                marginTop: "0px",
                margin: "-15px 0 20px",
              }}
            >
              <Form>
                <DateRangePicker
                  placeholder="Select Date Range"
                  value={dateValue}
                  onChange={(e) => handleDateRange(e)}
                  disabled={disabledFutureDates}
                  periods={periods}
                />
              </Form>
            </div> */}
            <div className="custom-table">
              {loading && <Spin />}
              {/* valid keyField from dta field value must be passed for the selection of the row for futher changes use contact_id */}
              <BootstrapTable
                keyField="contact_id"
                remote
                data={contactStatements}
                columns={columns}
                selectRow={selectRow}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStatements;
