import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../../components/Form/BackButton";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../../config";
import { checkIfFileExists } from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { AddSearchTermSchema } from "../../../common/Validation";
import { getProjectData } from "../../components/ProjectHelper";
import Form from "./components/Form";
import { getContractorDetail } from "../../sub-contractors/components/SubContractorHelper";

const AddSearchTerm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;

  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const [projectName, setProjectName] = useState();
  const [projectLogo, setProjectLogo] = useState();
  const [contractorDetails, setContractorDetails] = useState();
  const [disableState, setDisableState] = useState(false);

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (projectLogo && typeof projectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${projectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(projectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [projectLogo]);

  const handleFormSubmit = async (value) => {
    value.chart_of_account_id = value?.chart_of_account_id
      ? value?.chart_of_account_id?.id
      : "";

    value.description = value?.description ? value?.description : "";

    const formData = new FormData();

    formData.append("builder_project_id", projectId);
    formData.append("term", value?.term);
    formData.append("chart_of_account_id", value?.chart_of_account_id);
    formData.append(
      "contact_id",
      contractorDetails?.id ? contractorDetails?.id : ""
    );

    if (value?.description) {
      formData.append("description", value?.description);
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.ADD_TERM}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        navigate("/projects/" + projectId + "/terms");
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const getContractor = async (contractorId) => {
    try {
      setLoading(true);
      const data = await getContractorDetail(contractorId);
      const contractorData = data ? data : {};
      if (contractorData?.id) {
        if (
          contractorData?.account_name &&
          contractorData?.account_number &&
          contractorData?.bsb
        ) {
          setDisableState(false);
        } else {
          setDisableState(true);
          toast.error("Please add bank details of selected contractor.");
        }
      }
      setLoading(false);
    } catch (e) {
      toast.error("Something went wrong.");
    }
  };

  const handleContractorSelect = async (contractor) => {
    setContractorDetails(contractor);
    getContractor(contractor?.id, contractor);
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            New Term
            <div className="page-title-right">
              {projectLogo && (
                <img src={`${imageDefaultPrefixPath}${projectLogo}`} alt="" />
              )}{" "}
              {projectName}
            </div>
          </h1>
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  term: "",
                  chart_of_account_id: "",
                  contact_id: "",
                  description: ""
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddSearchTermSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {loading && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        projectId={projectId}
                        handleContractorSelect={handleContractorSelect}
                        disableState={disableState}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSearchTerm;
