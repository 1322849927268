import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { API, fileUpload, get, imageDefaultPrefixPath } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import { AddProjectSchema } from "../../common/Validation";
import {
  useAllBankList,
  useBankList,
  useStateList,
} from "./components/ProjectsHelper";
import { Modal } from "react-bootstrap";
// import moment from "moment";
import { useEffect } from "react";
import { useOfficeList } from "../BuilderUsers/components/BuilderUserHelper";
import { formatName, formatOfficeName } from "../../common/Misc";
import { useUserList } from "../BuilderOffices/components/BuilderOfficeHelper";

const AddProject = ({
  toggleProjectModal,
  builderId,
  showProject,
  headProject,
  totalProject,
}) => {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [builderId1, setBuilderId1] = useState();
  const [totalProjects, setTotalprojects] = useState();

  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.logo = value?.image ? value?.image : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";

    value.project_opening_balance = Number(
      `${value?.project_opening_balance}`.replace(/,/g, "")
    );
    value.retention_opening_balance = Number(
      `${value?.retention_opening_balance}`.replace(/,/g, "")
    );
    value.estimated_cost = Number(`${value?.estimated_cost}`.replace(/,/g, ""));

    formData.append(
      "builder_id",
      builderId ? builderId : builderId1 ? builderId1 : null
    );

    formData.append(
      "project_name",
      value?.project_name ? value?.project_name : ""
    );
    formData.append("email", value?.email ? value?.email : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("abn", value?.abn ? value?.abn : "");

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id?.value : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id?.id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append("logo", value?.logo ? value?.logo : "");

    if (selectedOffices) {
      if (selectedOffices.length > 0) {
        for (let i = 0; i < selectedOffices.length; i++) {
          formData.append(`office_ids[${i}]`, selectedOffices[i]);
        }
      }
    }

    if (selectedUsers) {
      if (selectedUsers.length > 0) {
        for (let i = 0; i < selectedUsers.length; i++) {
          formData.append(`user_ids[${i}]`, selectedUsers[i]);
        }
      }
    }

    formData.append(
      "project_account_name",
      value?.project_account_name ? value?.project_account_name : ""
    );
    formData.append(
      "project_bsb",
      value?.project_bsb ? value?.project_bsb : ""
    );
    formData.append(
      "project_account_number",
      value?.project_account_number ? value?.project_account_number : ""
    );
    formData.append(
      "project_bank_id",
      value?.project_bank_id ? value?.project_bank_id?.value : ""
    );
    formData.append(
      "project_apca_id",
      value?.project_apca_id ? value?.project_apca_id : ""
    );
    formData.append(
      "project_opening_balance",
      value?.project_opening_balance ? value?.project_opening_balance : 0
    );

    value?.rta_project &&
      formData.append("rta_project_id", value?.rta_project?.id);

    if (!value?.rta_project) {
      formData.append(
        "retention_account_name",
        value?.retention_account_name ? value?.retention_account_name : ""
      );
      formData.append(
        "retention_bsb",
        value?.retention_bsb ? value?.retention_bsb : ""
      );
      formData.append(
        "retention_account_number",
        value?.retention_account_number ? value?.retention_account_number : ""
      );
      formData.append(
        "retention_bank_id",
        value?.retention_bank_id ? value?.retention_bank_id?.value : ""
      );
      formData.append(
        "retention_apca_id",
        value?.retention_apca_id ? value?.retention_apca_id : ""
      );
    }

    // formData.append(
    //   "retention_transaction_start_date",
    //   value?.retention_transaction_start_date
    //     ? moment(value?.retention_transaction_start_date, "DD-MM-YYYY").format(
    //         "DD-MM-YYYY"
    //       )
    //     : ""
    // );
    formData.append(
      "retention_transaction_start_date",
      value?.retention_transaction_start_date
        ? value?.retention_transaction_start_date
        : ""
    );
    formData.append(
      "retention_apca_id",
      value?.apca_id ? value?.apca_id : "000000"
    );

    formData.append(
      "retention_opening_balance",
      value?.retention_opening_balance ? value?.retention_opening_balance : 0
    );

    // formData.append(
    //   "start_date",
    //   value?.start_date
    //     ? moment(value?.start_date, "DD-MM-YYYY").format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append("start_date", value?.start_date ? value?.start_date : "");
    // formData.append(
    //   "end_date",
    //   value?.end_date
    //     ? moment(value?.end_date, "DD-MM-YYYY").format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append("end_date", value?.end_date ? value?.end_date : "");

    formData.append(
      "estimated_cost",
      value?.estimated_cost ? value?.estimated_cost : ""
    );
    formData.append(
      "project_status",
      value?.project_status ? value?.project_status?.value : null
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.PROJECT_ADD}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleProjectModal();
        setSelectedOffices([]);
        setSelectedUsers([]);
      }
      return data?.data;
    } catch (error) {
      setProjectData(value);
      setLoading(false);
      setSelectedOffices([]);
      setSelectedUsers([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === projectData?.state_id
  );

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const { allBankLoading, allBankList = [] } = useAllBankList();
  const bankListing = bankList
    ? [...bankList, { label: "Don't have a Retention Trust A/C", value: "" }]
    : [];
  const allBanks = allBankList ? allBankList : [];

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  //Office List
  const { officeLoading, officeList = [] } = useOfficeList(builderId);
  const officeListing = officeList?.map((item) => {
    const name = formatOfficeName(item?.street, item?.suburb?.name);
    const id = item?.id;
    const items = { name, id };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  //User List
  const { userLoading, userList = [] } = useUserList(builderId);
  const userListing = userList?.map((item) => {
    const name = formatName(item?.first_name, item?.last_name);
    const id = item?.id;
    const items = { name, id, ...item };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  const fetchProjectList = async (id) => {
    if (id) {
      try {
        const { data } = await get(
          `${API.GET_PROJECT}?builder_id=${id ? id : ""}`
        );
        const totalProjects = data?.data?.data?.length;
        setTotalprojects(totalProjects);
        return data.data;
      } catch (error) {
        setTotalprojects("");

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };
  useEffect(() => {
    fetchProjectList(builderId1);
  }, [builderId1]);

  return (
    <>
      <Modal
        size="lg"
        show={showProject}
        onHide={toggleProjectModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Project
        </Modal.Header>
        <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
          <div
            className="project-add-modal"
            style={{ padding: " 0", height: "90vh", overflow: "auto" }}
          >
            <Formik
              initialValues={{
                builder: builderId ? builderId : builderId1 ? builderId1 : "",
                project_name: projectData?.project_name,
                abn: projectData?.abn,
                mobile: projectData?.mobile,
                phone: projectData?.phone,
                email: projectData?.email,
                street: projectData?.street,
                postal_code: projectData?.postal_code,
                state_id: state,
                suburb_id: projectData?.suburb,
                logo: projectData?.logo
                  ? imageDefaultPrefixPath + projectData?.logo
                  : "",

                trustee_account_name: projectData?.trustee_account_name
                  ? projectData?.trustee_account_name
                  : "",
                trustee_bsb: projectData?.trustee_bsb
                  ? projectData?.trustee_bsb
                  : "",
                trustee_account_number: projectData?.trustee_account_number
                  ? projectData?.trustee_account_number
                  : "",
                trustee_bank_id: projectData?.trustee_bank_id
                  ? projectData?.trustee_bank_id
                  : "",
                trustee_opening_balance: projectData?.trustee_opening_balance
                  ? projectData?.trustee_opening_balance
                  : 0,

                project_account_name: projectData?.project_account_name
                  ? projectData?.project_account_name
                  : "",
                project_bsb: projectData?.project_bsb
                  ? projectData?.project_bsb
                  : "",
                project_account_number: projectData?.project_account_number
                  ? projectData?.project_account_number
                  : "",
                project_bank_id: projectData?.project_bank_id
                  ? projectData?.project_bank_id
                  : "",
                project_apca_id: projectData?.pta_contractor?.apca_id
                  ? padWithLeadingZeros(projectData?.pta_contractor?.apca_id, 6)
                  : "",
                project_opening_balance: projectData?.project_opening_balance
                  ? projectData?.project_opening_balance
                  : 0,

                retention_account_name: projectData?.retention_account_name
                  ? projectData?.retention_account_name
                  : "Retention Trust Account",
                retention_bsb: projectData?.retention_bsb
                  ? projectData?.retention_bsb
                  : "000000",
                retention_account_number: projectData?.retention_account_number
                  ? projectData?.retention_account_number
                  : "000000001",
                retention_bank_id: projectData?.retention_bank_id
                  ? projectData?.retention_bank_id
                  : bankListing?.find((i) => i.value === ""),
                retention_apca_id: projectData?.rta_contractor?.apca_id
                  ? padWithLeadingZeros(projectData?.rta_contractor?.apca_id, 6)
                  : "000000",
                retention_opening_balance:
                  projectData?.retention_opening_balance
                    ? projectData?.retention_opening_balance
                    : 0,

                // start_date: projectData?.start_date
                //   ? new Date(moment(projectData?.start_date, "DD-MM-YYYY"))
                //   : "",
                // end_date: projectData?.end_date
                //   ? new Date(moment(projectData?.end_date, "DD-MM-YYYY"))
                //   : "",
                start_date: projectData?.start_date
                  ? projectData?.start_date
                  : "",
                end_date: projectData?.end_date ? projectData?.end_date : "",
                estimated_cost: projectData?.estimated_cost
                  ? projectData?.estimated_cost
                  : 0,
                rta_project: null,
                project_status: projectData?.project_status
                  ? projectData?.project_status
                  : null,
              }}
              enableReinitialize
              innerRef={formValues}
              onSubmit={handleFormSubmit}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={AddProjectSchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <>
                    {loading && <Spin />}
                    <Form
                      values={values}
                      errors={errors}
                      setErrors={setErrors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      formValues={formValues}
                      overwrittenFields={overwrittenFields}
                      existingValues={existingValues}
                      toggleProjectModal={toggleProjectModal}
                      stateListing={stateListing}
                      stateLoading={stateLoading}
                      bankListing={bankListing}
                      bankLoading={bankLoading}
                      builderId={builderId}
                      totalProject={totalProject}
                      setLoading={setLoading}
                      setBuilderId1={setBuilderId1}
                      builderId1={builderId1}
                      totalProjects={totalProjects}
                      allBanks={allBanks}
                      allBankLoading={allBankLoading}
                      officeListing={officeListing}
                      officeLoading={officeLoading}
                      selectedOffices={selectedOffices}
                      setSelectedOffices={setSelectedOffices}
                      userListing={userListing}
                      userLoading={userLoading}
                      setSelectedUsers={setSelectedUsers}
                      selectedUsers={selectedUsers}
                    />
                  </>
                );
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProject;
