import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import {
  useStateList,
  useBankList,
  useSuburbList,
} from "../sub-contractors/components/SubContractorHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../sub-contractors/components/Form";
import Spin from "../../common/Spin";
import { AddContractorSchema } from "../../common/Validation";
import BackButton from "../../../components/Form/BackButton";
import {
  checkIfFileExists,
  formatedPostalCode,
  formatName,
} from "../../common/Misc";
import { getProjectData } from "../components/ProjectHelper";
import { getImportLogDetail } from "./ImportLogsHelper";

const NotImportedLogEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.project_id;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [importLogData, setImportLogDetail] = useState();
  const [contactDetail, setContactDetail] = useState(null);
  const formValues = useRef();
  const importLogId = query.get("id");
  let existingValues = [];
  let overwrittenFields = [];

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  const [suburbListing, setSuburbListing] = useState();
  const [states, setStates] = useState();
  const [shippingSuburbListing, setShippingSuburbListing] = useState();
  const [shippingStates, setShippingStates] = useState();

  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const importLogRes = await getImportLogDetail(importLogId);
      let contactDetails = JSON.parse(importLogRes?.contact_details);
      setContactDetail(contactDetails);
      setImportLogDetail(importLogRes);
      setLoading(false);
    }
    fetchData();
  }, [importLogId]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";
    value.opening_balance = Number(
      `${value?.opening_balance}`.replace(/,/g, "")
    );
    value.contract_value = Number(`${value?.contract_value}`.replace(/,/g, ""));

    value.retention_amount = Number(
      `${value?.retention_value}`.replace(/,/g, "")
    );

    value.retention_percentage = Number(
      `${value?.retention_precentage}`.replace("%", "")
    );

    value.contact_type_id = value?.contact_type_id?.id;
    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;
    value.bank_id = value?.bank_id?.value;

    value.shipping_state_id = value?.shipping_state_id?.value;
    value.shipping_suburb_id = value?.shipping_suburb_id?.id;

    formData.append("builder_project_id", projectId);
    formData.append("import_log_id", importLogId ? importLogId : "")
    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("mobile", value?.mobile ? value?.mobile : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "contact_type_id",
      value?.contact_type_id ? value?.contact_type_id : ""
    );
    formData.append(
      "qbcc_number",
      value?.qbcc_number ? value?.qbcc_number : ""
    );
    formData.append("abn", value?.abn);
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );
    formData.append(
      "shipping_street",
      value?.shipping_street ? value?.shipping_street : ""
    );
    formData.append(
      "shipping_state_id",
      value?.shipping_state_id ? value?.shipping_state_id : ""
    );
    formData.append(
      "shipping_suburb_id",
      value?.shipping_suburb_id ? value?.shipping_suburb_id : ""
    );
    formData.append(
      "shipping_postal_code",
      value?.shipping_postal_code ? value?.shipping_postal_code : ""
    );
    formData.append(
      "account_name",
      value?.account_name ? value?.account_name : ""
    );
    formData.append("bsb", value?.bsb ? value?.bsb : "");
    formData.append(
      "account_number",
      value?.account_number ? value?.account_number : ""
    );
    formData.append(
      "opening_balance",
      value?.opening_balance ? value?.opening_balance : 0
    );
    formData.append("bank_id", value?.bank_id ? value?.bank_id : "");

    formData.append(
      "contract_file",
      value?.contract_file ? value?.contract_file : ""
    );

    formData.append(
      "contract_value",
      value?.contract_value ? value?.contract_value : ""
    );

    formData.append(
      "retention_amount",
      value?.retention_amount ? value?.retention_amount : ""
    );
    formData.append(
      "retention_percentage",
      value?.retention_percentage ? value?.retention_percentage : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CONTRACTOR_Add}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        // navigate(-1);
        navigate("/projects/" + projectId + "/contact-import-logs");
      }
      return data?.data;
    } catch (error) {
      setImportLogDetail(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });

  let state = stateListing?.find((item) =>
    item?.label?.includes(
      contactDetail?.Addresses?.find((item) => item?.AddressType === "POBOX")
        ?.Region
    )
  );

  //ShippingState
  const shippingState = stateListing?.find((item) =>
    item?.label?.includes(
      contactDetail?.Addresses?.find((item) => item?.AddressType === "STREET")
        ?.Region
    )
  );

  // Suburb
  // eslint-disable-next-line no-unused-vars
  const { suburbLoading, suburbList = [] } = useSuburbList(
    contactDetail?.Addresses?.find((item) => item?.AddressType === "POBOX")
      ?.City
  );

  useEffect(() => {
    const suburbListings = suburbList?.map((item) => {
      return item;
    });
    if (!state) {
      let data = stateListing?.find(
        (item) => item?.value === suburbListings[0]?.state_id
      );
      setStates(data);
    }
    setSuburbListing(suburbListings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, suburbList]);

  // Shipping Suburb
  const shippingSuburb = useSuburbList(
    contactDetail?.Addresses?.find((item) => item?.AddressType === "POBOX")
      ?.City
  );

  useEffect(() => {
    const shippingSuburbListings = shippingSuburb?.suburbList?.map((item) => {
      return item;
    });
    if (!shippingState) {
      let data = stateListing?.find(
        (item) => item?.value === shippingSuburbListings[0]?.state_id
      );
      setShippingStates(data);
    }
    setShippingSuburbListing(shippingSuburbListings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingState, suburbList]);

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const bankListing = bankList ? bankList : [];
  const projectBank = bankListing?.find(
    (item) => item?.value === importLogData?.bank_id
  );
  const ContractorType = importLogData?.contact_type
    ? {
        id: importLogData?.contact_type?.id
          ? importLogData?.contact_type?.id
          : "",
        label: importLogData?.contact_type?.name,
      }
    : null;
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {formatName(importLogData?.first_name, importLogData?.last_name)}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <section>
              <Formik
                initialValues={{
                  first_name: contactDetail?.FirstName
                    ? contactDetail?.FirstName
                    : "",
                  last_name: contactDetail?.LastName
                    ? contactDetail?.LastName
                    : "",
                  phone: contactDetail?.Phones?.find(
                    (item) => item?.PhoneType === "DEFAULT"
                  )?.PhoneNumber
                    ? contactDetail?.Phones?.find(
                        (item) => item?.PhoneType === "DEFAULT"
                      )?.PhoneNumber
                    : "",
                  mobile: contactDetail?.Phones?.find(
                    (item) => item?.PhoneType === "MOBILE"
                  )?.PhoneNumber
                    ? contactDetail?.Phones?.find(
                        (item) => item?.PhoneType === "MOBILE"
                      )?.PhoneNumber
                    : "",
                  email: contactDetail?.EmailAddress
                    ? contactDetail?.EmailAddress
                    : "",
                  business_name: contactDetail?.Name ? contactDetail?.Name : "",
                  abn: contactDetail?.TaxNumber ? contactDetail?.TaxNumber : "",
                  contact_type_id: ContractorType ? ContractorType : null,
                  qbcc_number: importLogData?.qbcc_number,
                  street: contactDetail?.Addresses?.find(
                    (item) => item?.AddressType === "POBOX"
                  )?.AddressLine1
                    ? contactDetail?.Addresses?.find(
                        (item) => item?.AddressType === "POBOX"
                      )?.AddressLine1 +
                      (contactDetail?.Addresses?.find(
                        (item) => item?.AddressType === "POBOX"
                      )?.AddressLine2
                        ? "," +
                          contactDetail?.Addresses?.find(
                            (item) => item?.AddressType === "POBOX"
                          )?.AddressLine2 +
                          (contactDetail?.Addresses?.find(
                            (item) => item?.AddressType === "POBOX"
                          )?.AddressLine3
                            ? "," +
                              contactDetail?.Addresses?.find(
                                (item) => item?.AddressType === "POBOX"
                              )?.AddressLine3 +
                              (contactDetail?.Addresses?.find(
                                (item) => item?.AddressType === "POBOX"
                              )?.AddressLine4
                                ? "," +
                                  contactDetail?.Addresses?.find(
                                    (item) => item?.AddressType === "POBOX"
                                  )?.AddressLine4
                                : "")
                            : "")
                        : "")
                    : "",
                  state_id: state ? state : states ? states : "",
                  suburb_id: suburbListing ? suburbListing[0] : "",
                  postal_code: contactDetail?.Addresses?.find(
                    (item) => item?.AddressType === "POBOX"
                  )?.PostalCode
                    ? formatedPostalCode(
                        contactDetail?.Addresses?.find(
                          (item) => item?.AddressType === "POBOX"
                        )?.PostalCode
                      )
                    : "",

                  same_address: false,

                  shipping_street: contactDetail?.Addresses?.find(
                    (item) => item?.AddressType === "STREET"
                  )?.AddressLine1
                    ? contactDetail?.Addresses?.find(
                        (item) => item?.AddressType === "STREET"
                      )?.AddressLine1 +
                      (contactDetail?.Addresses?.find(
                        (item) => item?.AddressType === "STREET"
                      )?.AddressLine2
                        ? "," +
                          contactDetail?.Addresses?.find(
                            (item) => item?.AddressType === "STREET"
                          )?.AddressLine2 +
                          (contactDetail?.Addresses?.find(
                            (item) => item?.AddressType === "STREET"
                          )?.AddressLine3
                            ? "," +
                              contactDetail?.Addresses?.find(
                                (item) => item?.AddressType === "STREET"
                              )?.AddressLine3 +
                              (contactDetail?.Addresses?.find(
                                (item) => item?.AddressType === "STREET"
                              )?.AddressLine4
                                ? "," +
                                  contactDetail?.Addresses?.find(
                                    (item) => item?.AddressType === "STREET"
                                  )?.AddressLine4
                                : "")
                            : "")
                        : "")
                    : "",
                  shipping_state_id: shippingState
                    ? shippingState
                    : shippingStates
                    ? shippingStates
                    : "",
                  shipping_suburb_id: shippingSuburbListing
                    ? shippingSuburbListing[0]
                    : "",
                  shipping_postal_code: contactDetail?.Addresses?.find(
                    (item) => item?.AddressType === "STREET"
                  )?.PostalCode
                    ? formatedPostalCode(
                        contactDetail?.Addresses?.find(
                          (item) => item?.AddressType === "STREET"
                        )?.PostalCode
                      )
                    : "",
                  account_name: contactDetail?.BatchPayments?.BankAccountName
                    ? contactDetail?.BatchPayments?.BankAccountName
                    : "",
                  bsb:
                    contactDetail?.BatchPayments?.BankAccountNumber == null ||
                    contactDetail?.BatchPayments?.BankAccountNumber === "" ||
                    contactDetail?.BatchPayments?.BankAccountNumber?.length < 6
                      ? ""
                      : contactDetail?.BatchPayments?.BankAccountNumber?.toString()?.slice(
                          0,
                          6
                        ),
                  account_number:
                    contactDetail?.BatchPayments?.BankAccountNumber === null ||
                    contactDetail?.BatchPayments?.BankAccountNumber === "" ||
                    contactDetail?.BatchPayments?.BankAccountNumber <= 6
                      ? ""
                      : contactDetail?.BatchPayments?.BankAccountNumber?.toString()?.slice(
                          6
                        ),
                  opening_balance: importLogData?.opening_balance
                    ? importLogData?.opening_balance
                    : 0,
                  bank_id: projectBank ? projectBank : "",
                  contract_value: importLogData?.contract_value
                    ? importLogData?.contract_value
                    : "",
                  contract_file: importLogData?.contract_file
                    ? importLogData?.contract_file
                    : "",
                  retention_value: importLogData?.retention_amount
                    ? importLogData?.retention_amount
                    : "",
                  retention_precentage: importLogData?.retention_percentage
                    ? importLogData?.retention_percentage
                    : "",
                }}
                enableReinitialize
                innerRef={formValues}
                onSubmit={handleFormSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={AddContractorSchema}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {(loading || suburbLoading) && <Spin />}
                      <Form
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        formValues={formValues}
                        overwrittenFields={overwrittenFields}
                        existingValues={existingValues}
                        stateListing={stateListing}
                        stateLoading={stateLoading}
                        bankListing={bankListing}
                        bankLoading={bankLoading}
                        projectId={projectId}
                        edit={true}
                        notImported={true}
                      />
                    </>
                  );
                }}
              </Formik>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotImportedLogEdit;
