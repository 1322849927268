import React, { useState } from "react";
import "../../components/Authentication/authentication.css";
import { toast } from "react-toastify";
import { API, post } from "../../config";
import Spin from "../../pages/common/Spin";

const ConfirmAuthentication = (props) => {
  const { submitButton, verifyUrl } = props;
  const [validator, setValidator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  // *Verfiy Code and 2FA using API - Start
  const changeAuthenticationCode = (event) => {
    if (validator) {
      setValidator(false);
    }
    setVerifyCode(event.target.value);
  };

  const confirmCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    let params = { code: verifyCode };
    if (verifyUrl === API.DELETE_2FA) {
      params.id = 1;
    }
    post(verifyUrl, params)
      .then(() => {
        setValidator(false);
        submitButton();
        setLoading(false);
      })
      .catch(() => {
        toast.error("Authentication code is invalid");
        setLoading(false);
        setValidator(true);
      });
  };
  // *Verfiy Code and 2FA using API - End

  return (
    <form onSubmit={confirmCode}>
      <div className="tfa-content">
        {loading && <Spin />}
        <p>Enter the 6-digit code found in your authenticator app</p>
        <input
          autoFocus
          placeholder="Authentication Code"
          onChange={changeAuthenticationCode}
        />
      </div>
      <button type="submit">confirm</button>
    </form>
  );
};

export default ConfirmAuthentication;
