import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import {
  faEnvelope,
  faEye,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatABN,
  formatAccountNumber,
  formatBSBNumber,
  formatedAddress,
  formatName,
  formatNumber,
  formatPhone,
  formatSubcontractorName,
} from "../../common/Misc";
import { API, get, imageDefaultPrefixPath } from "../../../config";
import Spin from "../../common/Spin";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CustomTooltip from "../../common/CustomTooltip";

const ExpandABATransactions = ({ id, toggleShowModal, downloadLetter }) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [expandedData, setExpandedData] = useState([]);

  const [showPreview, setShowPreview] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [paymentId, setPaymentId] = useState();

  const [ABALoading, setABALoading] = useState(false);
  const [ABAReceiptData, setABAReceiptData] = useState();

  // *API Call for Fetching ABA Transactions List - Start
  const fetchABATransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_ABA_EXPAND_LIST}/${id}?page=${1}&limit=-1`
      );
      const expand = data?.data?.data;
      setExpandedData(expand);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setExpandedData();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchABATransactions();
  }, [fetchABATransactions]);
  // *API Call for Fetching ABA Transactions List - End

  // *ABA Transactions List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - Start
  const expandDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: expandedData[i]?.id,
        id: expandedData[i]?.id,
        transaction_date: expandedData[i]?.transaction_date
          ? expandedData[i]?.transaction_date
          : "",
        // name: expandedData[i]?.contact?.first_name
        //   ? expandedData[i]?.contact?.account_name ===
        //     formatName(
        //       expandedData[i]?.contact?.first_name,
        //       expandedData[i]?.contact?.last_name
        //     )
        //     ? formatName(
        //         expandedData[i]?.contact?.first_name,
        //         expandedData[i]?.contact?.last_name
        //       )
        //     : `${formatName(
        //         expandedData[i]?.contact?.first_name,
        //         expandedData[i]?.contact?.last_name
        //       )} (${expandedData[i]?.contact?.account_name})`
        //   : expandedData[i]?.contact?.business_name
        //   ? expandedData[i]?.contact?.account_name ===
        //     expandedData[i]?.contact?.business_name
        //     ? expandedData[i]?.contact?.business_name
        //     : `${expandedData[i]?.contact?.business_name} (${expandedData[i]?.contact?.account_name})`
        //   : expandedData[i]?.contact?.account_name ===
        //     formatName(
        //       expandedData[i]?.contact?.first_name,
        //       expandedData[i]?.contact?.last_name
        //     )
        //   ? formatName(
        //       expandedData[i]?.contact?.first_name,
        //       expandedData[i]?.contact?.last_name
        //     )
        //   : `${formatName(
        //       expandedData[i]?.contact?.first_name,
        //       expandedData[i]?.contact?.last_name
        //     )} (${expandedData[i]?.contact?.account_name})`,
        name: formatSubcontractorName(expandedData[i]?.contact),
        total_amount: expandedData[i]?.total_amount
          ? "$" + formatNumber(Number(expandedData[i]?.total_amount))
          : `$0.00`,
        retention_amount: expandedData[i]?.retention_amount
          ? "$" + formatNumber(Number(expandedData[i]?.retention_amount))
          : `$0.00`,
        net_amount: expandedData[i]?.net_amount
          ? "$" + formatNumber(Number(expandedData[i]?.net_amount))
          : `$0.00`,
        note: expandedData[i]?.note ? expandedData[i]?.note : "",
        receipt: (
          <>
            {expandedData[i]?.receipt_file ? (
              <>
                <CustomTooltip message={"Preview"}>
                  <span>
                    <FontAwesomeIcon
                      icon={faEye}
                      size="1x"
                      onClick={() => {
                        handleShowPreview(expandedData[i]?.id);
                      }}
                    />
                  </span>
                </CustomTooltip>
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <CustomTooltip message={"Download Receipt"}>
                  <span>
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      size="1x"
                      onClick={() => {
                        downloadLetter(expandedData[i]?.receipt_file);
                      }}
                    />
                  </span>
                </CustomTooltip>
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                {expandedData[i]?.contact?.email !== null ? (
                  <CustomTooltip message={"Email"}>
                    <span>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="1x"
                        onClick={() => {
                          handleSendEmail(expandedData[i]?.id);
                        }}
                      />
                    </span>
                  </CustomTooltip>
                ) : (
                  <CustomTooltip message={"Email address missing"}>
                    <span>
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        size="1x"
                        disabled
                        // style={{ pointerEvents: "none" }}
                        className="disabled-font-awesome"
                        onClick={() => {
                          handleSendEmail(expandedData[i]?.id);
                        }}
                      />
                    </span>
                  </CustomTooltip>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ),
      });
    }
    return items;
  };
  const expandDatas = expandDataGenerator(expandedData?.length);

  const columns = [
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      style: { width: "13%" },
      // sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      // style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "total_amount",
      text: "Total Amount",
      // style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "retention_amount",
      text: "Retention",
      // style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "net_amount",
      text: "Net Amount",
      // style: { width: "20%" },
      // sort: true,
    },
    {
      dataField: "note",
      text: "Note",
      // style: { width: "30%" },
      // sort: true,
    },
    {
      dataField: "receipt",
      text: "Receipt",
      align: "center",
      style: { width: "10%" },
      headerAlign: "center",
      // sort: true,
    },
  ];
  // *ABA Transactions List Table Row and Column Generation, Filtering and Sorting of Record, Select Row and Expand Row - End

  // *API Call for Fetching Receipt - Start
  const fetchABAReceipt = async (paymentId, action) => {
    if (action === "preview") {
      try {
        setABALoading(true);
        const { data } = await get(
          `${API.PAYMENT_ABA_RECEIPT}/${paymentId}/${action}?trust_type=${trust_type}`
        );
        setABAReceiptData(data?.data);
        setABALoading(false);
      } catch (error) {
        setABALoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    } else {
      try {
        setABALoading(true);
        const { data } = await get(
          `${API.PAYMENT_ABA_RECEIPT}/${paymentId}/${action}`
        );
        data?.message && toast.success(data?.message);
        handleSendEmail();
        setABALoading(false);
      } catch (error) {
        setABALoading(false);
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  const handleShowPreview = (paymentId) => {
    setShowPreview(!showPreview);
    paymentId ? fetchABAReceipt(paymentId, "preview") : setABAReceiptData();
  };

  const handleSendEmail = (paymentId) => {
    setShowSendEmail(!showSendEmail);
    setPaymentId(paymentId);
  };
  // *API Call for Fetching Receipt - End

  return (
    <>
      <div className="content-details">
        {/* <div className="table-top-btn">
          <div className="search-section"></div>
          <div className="table-btn"></div>
        </div> */}
        <div className="custom-table">
          {loading && <Spin />}
          <BootstrapTable
            keyField="id"
            remote
            data={expandDatas}
            columns={columns}
            // onTableChange={handleTableChange}
            // noDataIndication="No Data Found"
          />
        </div>
      </div>
      <Modal
        size="lg"
        show={showSendEmail}
        onHide={() => {
          handleSendEmail();
        }}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Send E-mail
        </Modal.Header>
        <Modal.Body>
          {ABALoading && <Spin />}
          <div className="modal-body">
            Are you sure you want to email this receipt to the beneficiary for
            the payment made from PTA?
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                paymentId && fetchABAReceipt(paymentId, "email");
              }}
            >
              Yes
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                handleSendEmail();
              }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showPreview && (
        <Modal
          size="lg"
          show={showPreview}
          onHide={() => {
            handleShowPreview();
          }}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Receipt
          </Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            <React.Fragment>
              {ABALoading && <Spin />}
              {trust_type === 1 ? (
                <div
                  style={{
                    padding: "15px 15px 15px 15px",
                    height: "90vh",
                    overflow: "auto",
                    fontSize: "14px",
                  }}
                  className="payment-schedule-view"
                >
                  {/* Builder Details */}
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td style={{ width: "50%" }}></td>
                      <td style={{ width: "50%", textAlign: "right" }}>
                        {ABAReceiptData?.builder_project?.builder?.logo ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              ABAReceiptData?.builder_project?.builder?.logo
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : ABAReceiptData?.builder_project?.builder
                            ?.builder_details?.profile_pic ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.profile_pic
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                        <p>
                          <strong>
                            {ABAReceiptData?.builder_project?.builder
                              ?.business_name
                              ? ABAReceiptData?.builder_project?.builder
                                  ?.business_name
                              : "NA"}
                          </strong>
                        </p>
                        <p>
                          ABN/ACN:{" "}
                          {ABAReceiptData?.builder_project?.builder?.abn
                            ? formatABN(
                                ABAReceiptData?.builder_project?.builder?.abn
                              )
                            : "NA"}
                        </p>
                        <p>
                          {formatedAddress(
                            ABAReceiptData?.builder_project?.builder?.street,
                            ABAReceiptData?.builder_project?.builder?.suburb
                              ?.name,
                            ABAReceiptData?.builder_project?.builder?.state
                              ?.short_name,
                            ABAReceiptData?.builder_project?.builder
                              ?.postal_code
                          )}
                        </p>
                        <p>
                          Phone:{" "}
                          {ABAReceiptData?.builder_project?.builder
                            ?.builder_details?.phone
                            ? formatPhone(
                                ABAReceiptData?.builder_project?.builder
                                  ?.builder_details?.phone
                              )
                            : "NA"}
                        </p>
                      </td>
                    </tr>
                  </table>
                  {/* --------------- */}
                  <div
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Payment Advice
                  </div>
                  <div
                    style={{
                      textTransform: "uppercase",
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    Withdrawal from project trust account
                  </div>
                  <p style={{ marginBottom: "10px" }}>
                    This is to advice that funds will be electronically
                    transferred to your account for payments as detailed below.
                  </p>

                  {/* Subcontractor Details */}
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px" }}
                  >
                    <tr>
                      <td style={{ width: "75%" }}>
                        <p>
                          <strong>
                            {ABAReceiptData?.contact
                              ? formatName(
                                  ABAReceiptData?.contact?.first_name,
                                  ABAReceiptData?.contact?.last_name
                                )
                              : "NA"}
                          </strong>
                        </p>
                        <p>
                          {ABAReceiptData?.contact?.business_name
                            ? ABAReceiptData?.contact?.business_name
                            : "NA"}
                        </p>
                        <p>
                          ABN/ACN:{" "}
                          {ABAReceiptData?.contact?.abn
                            ? formatABN(ABAReceiptData?.contact?.abn)
                            : "NA"}
                        </p>
                        <p>
                          {formatedAddress(
                            ABAReceiptData?.contact?.street,
                            ABAReceiptData?.contact?.suburb?.name,
                            ABAReceiptData?.contact?.state?.short_name,
                            ABAReceiptData?.contact?.postal_code
                          )}
                        </p>
                        <p>
                          Phone:{" "}
                          {ABAReceiptData?.contact?.phone
                            ? formatPhone(ABAReceiptData?.contact?.phone)
                            : "NA"}
                        </p>
                      </td>
                      <td style={{ width: "25%" }}></td>
                    </tr>
                  </table>
                  {/* ---------------------- */}

                  {/* Payment Details */}
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px" }}
                  >
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Payment Date:</strong>{" "}
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                      </td>
                      <td>
                        <strong>Total Amount Paid:</strong>{" "}
                        {ABAReceiptData?.total_paid_amount
                          ? "$" +
                            formatNumber(ABAReceiptData?.total_paid_amount)
                          : "$0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          width: "60%",
                          paddingBottom: "15px",
                        }}
                      >
                        <strong>EFT Reference Number:</strong>{" "}
                        {ABAReceiptData?.eft_reference
                          ? ABAReceiptData?.eft_reference
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Bank Account Name:</strong>{" "}
                        {ABAReceiptData?.contact?.account_name
                          ? ABAReceiptData?.contact?.account_name
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>BSB:</strong>{" "}
                        {ABAReceiptData?.contact?.bsb
                          ? formatBSBNumber(ABAReceiptData?.contact?.bsb)
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ width: "60px" }}>
                        <strong>Account Number:</strong>{" "}
                        {ABAReceiptData?.contact?.account_number
                          ? formatAccountNumber(
                              ABAReceiptData?.contact?.account_number
                            )
                          : "NA"}
                      </td>
                      <td></td>
                    </tr>
                  </table>

                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "50px", marginTop: "50px" }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "25%",
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        Payment Claim Date
                      </td>

                      <td
                        style={{
                          width: "25%",
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Reference Number
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Amount Paid
                      </td>
                      <td
                        style={{
                          fontWeight: "bold",
                          borderBottom: "#CCC 1px solid",
                          background: "#F0F0F0",
                          borderTop: "#CCC 1px solid",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        Cash Retention Withheld
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.payment?.payment_data.map((data) => {
                          return (
                            <>
                              {data?.retention_id &&
                                `${data?.payment_detail_id}(P)`}
                            </>
                          );
                        })}
                      </td>

                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.paid_amount
                          ? "$" + formatNumber(ABAReceiptData?.paid_amount)
                          : "$0.00"}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderBottom: "#CCC 1px solid",
                          padding: "10px",
                        }}
                      >
                        {ABAReceiptData?.retained_amount
                          ? "$" + formatNumber(ABAReceiptData?.retained_amount)
                          : "$0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      ></td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>Total Amount:</strong>
                      </td>

                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>
                          {ABAReceiptData?.paid_amount
                            ? "$" + formatNumber(ABAReceiptData?.paid_amount)
                            : "$0.00"}
                        </strong>
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "10px",
                          borderTop: "1px solid #000",
                        }}
                      >
                        <strong>
                          {ABAReceiptData?.retained_amount
                            ? "$" +
                              formatNumber(ABAReceiptData?.retained_amount)
                            : "$0.00"}
                        </strong>
                      </td>
                    </tr>
                  </table>

                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td width="60%;">
                        <strong>Payment Date:</strong>{" "}
                        {ABAReceiptData?.payment?.transaction_date
                          ? ABAReceiptData?.payment?.transaction_date
                          : ""}
                        <br />
                        <span>
                          <strong>EFT Reference Number:</strong>{" "}
                          {ABAReceiptData?.eft_reference
                            ? ABAReceiptData?.eft_reference
                            : ""}
                        </span>
                        <br />
                        <br />
                        <strong>Financial Institute:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.bank
                            ? ABAReceiptData?.builder_project?.rta_project
                                ?.rta_contractor?.bank?.abbreviation
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.bank
                          ? ABAReceiptData?.builder_project?.rta_contractor
                              ?.bank?.abbreviation
                          : "NA"}
                        <br />
                        <strong>Bank Account Name:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.account_name
                            ? ABAReceiptData?.builder_project?.rta_project
                                ?.rta_contractor?.account_name
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_name
                          ? ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_name
                          : "NA"}
                        <br />
                        <strong>BSB:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.bsb
                            ? formatBSBNumber(
                                ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bsb
                              )
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor?.bsb
                          ? formatBSBNumber(
                              ABAReceiptData?.builder_project?.rta_contractor
                                ?.bsb
                            )
                          : "NA"}
                        <br />
                        <strong>Account Number:</strong>{" "}
                        {ABAReceiptData?.builder_project?.rta_project_id
                          ? ABAReceiptData?.builder_project?.rta_project
                              ?.rta_contractor?.account_number
                            ? formatAccountNumber(
                                ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_number
                              )
                            : "NA"
                          : ABAReceiptData?.builder_project?.rta_contractor
                              ?.account_number
                          ? formatAccountNumber(
                              ABAReceiptData?.builder_project?.rta_contractor
                                ?.account_number
                            )
                          : "NA"}
                      </td>
                      <td width="40%">
                        <strong>Total Retentions Deposited:</strong>{" "}
                        {ABAReceiptData?.retained_amount
                          ? "$" + formatNumber(ABAReceiptData?.retained_amount)
                          : "$0.00"}
                        <br />
                        <br />
                        <strong>
                          Total retentions withheld
                          <br />
                          for the beneficiary for the
                          <br />
                          contract after the deposit:
                        </strong>{" "}
                        {ABAReceiptData?.closing_balance_rta
                          ? "$" +
                            formatNumber(ABAReceiptData?.closing_balance_rta)
                          : "$0.00"}
                      </td>
                    </tr>
                  </table>
                  {/* --------------- */}
                </div>
              ) : (
                <>
                  <div
                    className="payment-schedule-view"
                    style={{ height: "90vh", overflow: "auto" }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        // margin: "50px 30px",
                        margin: "15px",
                      }}
                    >
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td style={{ width: "50%" }}></td>
                          <td style={{ width: "50%", textAlign: "right" }}>
                            {ABAReceiptData?.builder_project?.builder?.logo ? (
                              <img
                                src={
                                  imageDefaultPrefixPath +
                                  ABAReceiptData?.builder_project?.builder?.logo
                                }
                                style={{ height: "100px", width: "auto" }}
                                alt=""
                              />
                            ) : ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.profile_pic ? (
                              <img
                                src={
                                  imageDefaultPrefixPath +
                                  ABAReceiptData?.builder_project?.builder
                                    ?.builder_details?.profile_pic
                                }
                                style={{ height: "100px", width: "auto" }}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                            <p>
                              <strong>
                                {ABAReceiptData?.builder_project?.builder
                                  ?.business_name
                                  ? ABAReceiptData?.builder_project?.builder
                                      ?.business_name
                                  : "-"}
                              </strong>
                            </p>
                            <p>
                              ABN/ACN:{" "}
                              {ABAReceiptData?.builder_project?.builder?.abn
                                ? formatABN(
                                    ABAReceiptData?.builder_project?.builder
                                      ?.abn
                                  )
                                : "-"}
                            </p>
                            <p>
                              {formatedAddress(
                                ABAReceiptData?.builder_project?.builder
                                  ?.street,
                                ABAReceiptData?.builder_project?.builder?.suburb
                                  ?.name,
                                ABAReceiptData?.builder_project?.builder?.state
                                  ?.short_name,
                                ABAReceiptData?.builder_project?.builder
                                  ?.postal_code
                              )}
                            </p>
                            <p>
                              Phone:{" "}
                              {ABAReceiptData?.builder_project?.builder
                                ?.builder_details?.phone
                                ? formatPhone(
                                    ABAReceiptData?.builder_project?.builder
                                      ?.builder_details?.phone
                                  )
                                : "-"}
                            </p>
                          </td>
                        </tr>
                      </table>

                      <div
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "700",
                          fontSize: "18px",
                        }}
                      >
                        Payment Advice
                      </div>
                      <div
                        style={{
                          textTransform: "uppercase",
                          marginTop: "10px",
                          marginBottom: "10px",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        Payment to advice beneficiary from retention trust
                        account
                      </div>

                      <p style={{ marginBottom: "10px" }}>
                        This is to advise that funds will be electronically
                        transferred into the retention trust account for
                        payments as detailed below.
                      </p>

                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style={{ marginBottom: "50px" }}
                      >
                        <tr>
                          <td style={{ width: "75%" }}>
                            <p>
                              <strong>
                                {" "}
                                {ABAReceiptData?.contact
                                  ? formatName(
                                      ABAReceiptData?.contact?.first_name,
                                      ABAReceiptData?.contact?.last_name
                                    )
                                  : "-"}
                              </strong>
                            </p>
                            <p>
                              {ABAReceiptData?.contact?.business_name
                                ? ABAReceiptData?.contact?.business_name
                                : ""}
                            </p>
                            <p>
                              ABN/ACN:{" "}
                              {ABAReceiptData?.contact?.abn
                                ? formatABN(ABAReceiptData?.contact?.abn)
                                : "-"}
                            </p>
                            <p>
                              {formatedAddress(
                                ABAReceiptData?.contact?.street,
                                ABAReceiptData?.contact?.suburb?.name,
                                ABAReceiptData?.contact?.state?.short_name,
                                ABAReceiptData?.contact?.postal_code
                              )}
                            </p>
                            <p>
                              Phone:{" "}
                              {ABAReceiptData?.contact?.phone
                                ? formatPhone(ABAReceiptData?.contact?.phone)
                                : "-"}
                            </p>
                          </td>
                          <td style={{ width: "25%" }}></td>
                        </tr>
                      </table>
                      <table cellpadding="0" cellspacing="0" width="100%">
                        <tr>
                          <td width="60%;">
                            <strong>Payment Date:</strong>{" "}
                            {ABAReceiptData?.payment?.transaction_date
                              ? ABAReceiptData?.payment?.transaction_date
                                  ?.split("-")
                                  ?.join("/")
                              : ""}
                            <br />
                            <span>
                              <strong>EFT Reference Number:</strong>{" "}
                              {ABAReceiptData?.eft_reference
                                ? ABAReceiptData?.eft_reference
                                : ""}
                            </span>
                            <br />
                            <br />
                            <strong>Financial Institute:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bank?.abbreviation
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.bank?.abbreviation}
                            <br />
                            <strong>Bank Account Name:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_name
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.account_name}
                            <br />
                            <strong>BSB:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.bsb
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.bsb}
                            <br />
                            <strong>Account Number:</strong>{" "}
                            {ABAReceiptData?.builder_project?.rta_project_id
                              ? ABAReceiptData?.builder_project?.rta_project
                                  ?.rta_contractor?.account_number
                              : ABAReceiptData?.builder_project?.rta_contractor
                                  ?.account_number}
                          </td>
                          <td width="40%">
                            <strong>Total Retentions Released:</strong>{" "}
                            {"$" +
                              formatNumber(ABAReceiptData?.total_paid_amount)}
                            <br />
                            <br />
                            <strong>
                              Total retentions withheld
                              <br />
                              for the beneficiary for the
                              <br />
                              contract after the deposit:
                            </strong>{" "}
                            $
                            {ABAReceiptData?.closing_balance_rta
                              ? formatNumber(
                                  Number(ABAReceiptData?.closing_balance_rta)
                                )
                              : "0.00"}
                          </td>
                        </tr>
                      </table>
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style={{ marginTop: "50px" }}
                      >
                        <tr>
                          <td
                            style={{
                              width: "25%",
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            Payment Claim Date
                          </td>
                          <td
                            style={{
                              width: "25%",
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                              textAlign: "right",
                            }}
                          >
                            Reference Number
                          </td>
                          <td
                            style={{
                              fontWeight: "bold",
                              borderBottom: "#CCC 1px solid",
                              background: "#F0F0F0",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                              textAlign: "right",
                            }}
                          >
                            Cash Retention Released
                          </td>
                        </tr>
                        {ABAReceiptData?.payment?.payment_data.map((item) => (
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              {ABAReceiptData?.payment?.transaction_date
                                ? ABAReceiptData?.payment?.transaction_date
                                    ?.split("-")
                                    ?.join("/")
                                : "-"}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              {`${item?.payment_detail_id}(P)`}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderBottom: "#CCC 1px solid",
                                padding: "10px",
                              }}
                            >
                              $
                              {item?.amount
                                ? formatNumber(Number(item?.amount))
                                : "0.00"}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: "right",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            <strong>Total Amount:</strong>
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderTop: "#CCC 1px solid",
                              padding: "10px",
                            }}
                          >
                            <strong>
                              $
                              {ABAReceiptData?.total_paid_amount
                                ? formatNumber(
                                    Number(ABAReceiptData?.total_paid_amount)
                                  )
                                : "0.00"}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ExpandABATransactions;
