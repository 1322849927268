/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { API, fileUpload, get } from "../../../../config";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { formatName, formatNumber } from "../../../common/Misc";
import BootstrapTable from "react-bootstrap-table-next";
import Spin from "../../../common/Spin";
import ExpandABATransactions from "../../ABA/ExpandABATransactions";
import axios from "axios";

const SearchContractor = ({
  trustType,
  close,
  transaction_type,
  ItemId,
  searchParams,
  Item,
  getBankStatementReconcileData,
  projectId,
  bankReconcilationId,
  splitContractor,
  setSplitContractor,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const trustTypeData = params?.trust_type;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState(
    searchParams ? searchParams : ""
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (searchParams) {
      transaction_type === 0
        ? getProcessRefundSearchData(searchParams)
        : getATOSearchData(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const ATOTableColumns = [
    {
      text: "Transaction Date",
      dataField: "transaction_date",
      width: "10%",
    },
    {
      dataField: "name",
      text: "Name",
      width: "50%",
    },
    {
      text: "Amount",
      dataField: "amount",
      width: "15%",
      className: "centeralign",
    },
    {
      text: "Description",
      dataField: "description",
      width: "40%",
    },
  ];

  const ProcessTableColumns = [
    {
      text: "Transaction Date",
      dataField: "transaction_date",
      width: "10%",
    },
    {
      dataField: "name",
      text: "Name",
      width: "50%",
    },
    {
      text: "Amount",
      dataField: "amount",
      width: "15%",
      className: "centeralign",
    },
    // {
    //   text: "Description",
    //   dataField: "description",
    //   width: "40%",
    // },
  ];

  const ABAProcessTableColumns = [
    // {
    //   dataField: "created_at",
    //   text: "Created Date",
    //   style: { width: "10%" },
    // },
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      style: { width: "15%" },
    },
    {
      dataField: "bank_name",
      text: "Bank Name",
      style: { width: "20%" },
    },
    {
      dataField: "description",
      text: "Description",
      style: { width: "35%" },
    },
    // {
    //   dataField: "total_amount",
    //   text: "Gross",
    //   style: { width: "30%" },
    // },
    {
      dataField: "total_amount",
      text: "Total Amount",
      style: { width: "10%" },
    },
    {
      dataField: "total_retention_amount",
      text: "Retention",
      style: { width: "10%" },
    },
    {
      dataField: "total_net_amount",
      text: "Net Amount",
      style: { width: "10%" },
    },
  ];

  const processUserList = (response) => {
    return response.map((user) => {
      return {
        ...user,
        key: user.record_type === 0 ? user.receipt_id : user.ato_id,
      };
    });
  };

  const processRefundList = (response) => {
    return response.map((refund) => {
      return {
        ...refund,
        key: refund.id,
      };
    });
  };

  const getATOSearchData = (searchKeyWord) => {
    try {
      setIsDataLoading(true);
      setSearchKeyWord(searchKeyWord);
      if (searchKeyWord !== "") {
        get(
          API.GET_RECEIPT +
            `?search=${searchKeyWord}&trust_type=${trustType}&builder_project_id=${projectId}&limit=-1&bank_reconciliation=1`
        ).then((response) => {
          setIsDataLoading(false);
          setData([]);
          const userData = processUserList(
            response.data.data.data ? response.data.data.data : []
          );
          setData(userData ? userData : []);
        });
      } else {
        setIsDataLoading(false);
        setData([]);
      }
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.bt_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const getProcessRefundSearchData = (searchKeyWord) => {
    try {
      setIsDataLoading(true);
      setSearchKeyWord(searchKeyWord);
      if (searchKeyWord !== "") {
        get(
          trustType === 1
            ? `${API.GET_ABA_LIST_DOWNLOAD}?search=${searchKeyWord}&builder_project_id=${projectId}&trust_type=${trustType}&limit=-1&aba_file=0&bank_reconciliation=1`
            : API.GET_PAYMENTS +
                `?search=${searchKeyWord}&trust_type=${trustType}&builder_project_id=${projectId}&limit=-1&bank_reconciliation=1&aba_file=0`
        ).then((response) => {
          setIsDataLoading(false);
          setData([]);
          const processData = processRefundList(
            response?.data?.data?.data ? response?.data?.data?.data : []
          );
          setData(processData ? processData : null);
        });
      } else {
        setIsDataLoading(false);
        setData([]);
      }
    } catch (e) {
      setData([]);
      const errorMessage = e.response?.data?.errors?.bt_error[0];
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  // *API Call for Download .ABA File - Start
  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };
  // *API Call for Download .ABA File - End

  const expandRow = {
    renderer: (row) => (
      <>
        {" "}
        <ExpandABATransactions id={row?.id} downloadLetter={downloadLetter} />
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const getNonSelectableRows = (data) => {
    let disable = [];
    for (let i = 0; i < data?.length; i++) {
      if (transaction_type === 0) {
        if (data[i]?.total_amount != Item?.amount) {
          disable.push(data[i]?.id);
        }
      } else {
        if (data[i]?.amount != Item?.amount) {
          disable.push(data[i]?.id);
        }
      }
    }
    return disable;
  };

  const selectRow = {
    mode: "radio", // single row selection
    clickToSelect: true,
    onSelect: (row) => {
      setSelectedItem(row);
    },
    nonSelectable: getNonSelectableRows(data),
  };
  const handleSaveSearch = async (record) => {
    try {
      setIsDataLoading(true);
      let selectedData;
      if (record) {
        selectedData = record;
      } else {
        selectedData = selectedItem;
      }
      if (bankReconcilationId) {
        const formData = new FormData();
        // formData.append("payment_id", selectedData.id);
        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trustType);
        formData.append("receipt_id", selectedData.id);
        if (record?.contact_id) {
          formData.append("contact_id", record?.contact_id);
        }
        formData.append("_method", "put");

        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankReconcilationId}`,
          formData,
          {}
        );
        if (response.status === 200) {
          setIsDataLoading(false);
          toast.success(response.data.message);
          getBankStatementReconcileData();
          close(true);
        }
      } else {
        setIsDataLoading(false);
        setSplitContractor(record?.contact);
        close();
      }
    } catch (error) {
      setIsDataLoading(false);
      if (error?.response) {
        const { data } = error?.response;
        if (data) {
          toast.error(data?.message);
          toast.error(
            data?.errors &&
              data?.errors?.bt_error &&
              data?.errors?.bt_error?.[0]
          );
        } else {
          toast.error("Something went wrong");
        }
      } else {
        console.log(error);
      }
    }
  };

  const handleSaveProcessRefundSearch = async (record) => {
    try {
      setIsDataLoading(true);
      let selectedData;
      if (record) {
        selectedData = record;
      } else {
        selectedData = selectedItem;
      }
      if (bankReconcilationId) {
        const formData = new FormData();
        if (trustType === 1) {
          formData.append("aba_file_id", selectedData.id);
        } else {
          formData.append("payment_id", selectedData.id);
        }
        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trustType);
        if (record?.contact_id) {
          formData.append("contact_id", record?.contact_id);
        }
        formData.append("_method", "put");
        const response = await fileUpload(
          `${API.UPDATE_BANK_RECONCILIATION}/${bankReconcilationId}`,
          formData,
          {}
        );
        if (response.status === 200) {
          setIsDataLoading(false);
          toast.success(response.data.message);
          getBankStatementReconcileData();
          close(true);
        }
      } else {
        setIsDataLoading(false);
        setSplitContractor(record?.contact);
        close();
      }
    } catch (error) {
      setIsDataLoading(false);
      if (error?.response) {
        const { data } = error?.response;
        if (data) {
          toast.error(data?.message);
          toast.error(
            data?.errors &&
              data?.errors?.bt_error &&
              data?.errors?.bt_error?.[0]
          );
        } else {
          toast.error("Something went wrong");
        }
      } else {
        console.log(error);
      }
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (!data.length) return true;
    if (!selectedItem) return true;
    return false;
  }, [data.length, selectedItem]);

  const redirectPage = (transaction_type) => {
    var dateParts = Item?.date.split("-");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    if (transaction_type === 0) {
      navigate(
        `/projects/${params?.project_id}/${trustTypeData}/payments/add`,
        {
          state: {
            transactionDate: dateObject,
            amount: Item.amount,
            description: Item.description,
          },
        }
      );
    } else {
      navigate(
        `/projects/${params?.project_id}/${trustTypeData}/receipts/add`,
        {
          state: {
            transactionDate: dateObject,
            amount: Item.amount,
            description: Item.description,
          },
        }
      );
    }
  };

  const receiptsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: data[i]?.id,
        id: data[i]?.id,
        transaction_date: data[i]?.transaction_date
          ? data[i]?.transaction_date
          : "",
        amount: data[i]?.amount
          ? "$" + formatNumber(Number(data[i]?.amount))
          : `$0.00`,
        name:
          data[i]?.contact?.is_pta === 0
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (RTA)`
            : data[i]?.contact?.is_pta === 1
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (PTA)`
            : data[i]?.contact?.is_pta === 2
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (Withdrawal/General Business)`
            : data[i]?.contact?.is_pta === 3
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (Principal)`
            : formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              ),
        description: data[i]?.description ? data[i]?.description : "",
        ...data[i],
      });
    }
    return items;
  };
  const receiptTableData = receiptsGenerator(data?.length);

  const paymentsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: data[i]?.id,
        id: data[i]?.id,
        transaction_date: data[i]?.transaction_date
          ? data[i]?.transaction_date
          : "",
        amount: data[i]?.total_amount
          ? "$" + formatNumber(Number(data[i]?.total_amount))
          : `$0.00`,
        name:
          data[i]?.contact?.is_pta === 0
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (RTA)`
            : data[i]?.contact?.is_pta === 1
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (PTA)`
            : data[i]?.contact?.is_pta === 2
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (Withdrawal/General Business)`
            : data[i]?.contact?.is_pta === 3
            ? `${formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              )} (Principal)`
            : formatName(
                data[i]?.contact?.first_name,
                data[i]?.contact?.last_name
              ),
        description: data[i]?.description ? data[i]?.description : "",
        ...data[i],
      });
    }
    return items;
  };
  const paymentTableData = paymentsGenerator(data?.length);

  const ABAFileGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: data[i]?.id,
        id: data[i]?.id,
        aba_file_id: data[i]?.aba_file_id,
        // created_at: data[i]?.created_at
        //   ? data[i]?.created_at?.split(" ")[0]
        //   : "",
        transaction_date: data[i]?.transaction_date
          ? data[i]?.transaction_date
          : "",
        // bsb: data[i]?.bsb ? data[i]?.bsb : "-",
        bank_name: data[i]?.bank_name ? data[i]?.bank_name : "-",
        description: data[i]?.description ? data[i]?.description : "-",
        total_amount: data[i]?.total_amount
          ? "$" + formatNumber(Number(data[i]?.total_amount))
          : `$0.00`,
        total_retention_amount: data[i]?.total_retention_amount
          ? "$" + formatNumber(Number(data[i]?.total_retention_amount))
          : `$0.00`,
        total_net_amount: data[i]?.total_net_amount
          ? "$" + formatNumber(Number(data[i]?.total_net_amount))
          : `$0.00`,
        // note: data[i]?.note ? data[i]?.note : "",
        file: data[i]?.file ? `${data[i]?.file?.split("/").slice(-1)}` : "",
        is_reversed: data[i]?.is_reversed,
      });
    }
    return items;
  };
  const abaFiles = ABAFileGenerator(data?.length);

  return (
    <>
      <div className="table-top-btn">
        <div className="searchbar">
          <input
            type="text"
            value={searchKeyWord}
            placeholder="Search"
            onChange={(e) => {
              setData([]);
              transaction_type === 0
                ? getProcessRefundSearchData(e.target.value)
                : getATOSearchData(e.target.value);
            }}
          />
        </div>
        <div className="table-btn search-contractor">
          {/* {data.length <= 0 ? ( */}
          <Button
            variant="secondary"
            size="large"
            onClick={() => redirectPage(transaction_type)}
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "14px",
            }}
          >
            {transaction_type === 0 ? "Add Trust Payment" : "Add Trust Receipt"}
          </Button>
          {/* ) : null} */}

          <Button
            variant="secondary"
            size="sm"
            onClick={close}
            style={{ fontSize: "14px" }}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              transaction_type === 0
                ? handleSaveProcessRefundSearch(selectedItem)
                : handleSaveSearch(selectedItem);
            }}
            size="sm"
            disabled={isButtonDisabled}
            style={{ fontSize: "14px" }}
          >
            OK
          </Button>
        </div>
      </div>
      {isDataLoading && <Spin />}
      {transaction_type === 0 && trustType === 1 ? (
        <BootstrapTable
          remote
          keyField="id"
          columns={ABAProcessTableColumns}
          data={abaFiles}
          loading={isDataLoading}
          selectRow={selectRow}
          noDataIndication="No Data Found"
          expandRow={expandRow}
        />
      ) : (
        <BootstrapTable
          remote
          keyField="id"
          columns={
            transaction_type === 0 ? ProcessTableColumns : ATOTableColumns
          }
          data={transaction_type === 0 ? paymentTableData : receiptTableData}
          loading={isDataLoading}
          selectRow={selectRow}
          noDataIndication="No Data Found"
        />
      )}
    </>
  );
};

export default SearchContractor;
