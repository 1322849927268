import { useEffect, useState } from "react";
import { checkIfFileExists } from "./Misc";
import { useParams } from "react-router-dom";
import { imageDefaultPrefixPath } from "../../config";
import { getProjectData } from "../Project/components/ProjectHelper";

export const useProjectBar = () => {
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  const [ProjectAccess, setProjectAccess] = useState();
  const [ProjectReadOnly, setProjectReadOnly] = useState();
  const [ProjectData, setProjectData] = useState(null);
  const [builderId, setBuilderId] = useState(null);

  const params = useParams();
  const projectId = params?.project_id;

  useEffect(() => {
    getProjectData(
      projectId,
      setProjectName,
      setProjectLogo,
      setBuilderId,
      setProjectData,
      setProjectReadOnly,
      setProjectAccess
    );
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    }
  }, [ProjectLogo, ProjectName]);

  return {
    ProjectAccess,
    ProjectName,
    ProjectLogo,
    ProjectReadOnly,
    builderId,
    ProjectData,
  };
};
