import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  // const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");
  const navigate = useNavigate();
  const handleNavigate = () => {
    roleType === "Builder"
      ? navigate("/projects")
      : roleType === "Accounting Firm"
      ? navigate("/builders")
      : navigate("/");
  };
  return (
    <div className="container">
      <div className="copy-container center-xy">
        <h1>Page Not Found</h1>
        <button onClick={handleNavigate}>
          {roleType === "Builder" ||
          roleType === "Accounting Firm"
            ? " Go to Dashboard"
            : "Go to Home"}
        </button>
      </div>
    </div>
  );
};
export default PageNotFound;
