import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../config";
import Spin from "../../common/Spin";

const RejectInvitation = ({
  id,
  showRejectInvitation,
  handleInvitations,
  toggleShowRejectInvitationModal,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRejectInvitation = async () => {
    const formData = new FormData();
    formData.append("status", 2);
    formData.append("_method", "PUT");
    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.INVITATIONS}/${id}`, formData);
      setLoading(false);
      toast.success(data?.message);
      handleInvitations();
      toggleShowRejectInvitationModal();
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  return (
    <Modal
      size="lg"
      show={showRejectInvitation}
      onHide={toggleShowRejectInvitationModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Reject Invitation
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are your sure you want to reject this invitation?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleShowRejectInvitationModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleRejectInvitation();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RejectInvitation;
