import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { MonthPicker } from "@semcore/date-picker";
import Spin from "../../common/Spin";
import {
  formatNumber,
  formatSubcontractorName,
  MaxLimit,
} from "../../common/Misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import NumberFormat from "react-number-format";
import CustomTooltip from "../../common/CustomTooltip";

const MonthlyBankReconciliation = ({ projectName }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const query = new URLSearchParams(location.search);
  const month = query.get("month");
  const today = Date.now();
  var makeDate = new Date(today);
  // makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
  const defaultDate = moment(makeDate).format("MM-YYYY");
  const [date, setDate] = useState(month || defaultDate);
  const [monthlyData, setMonthlyData] = useState();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const currentDate = new Date();
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);

  const [expandAddReceipt, setExpandAddReceipt] = useState(false);
  const [addReceipts, setAddReceipts] = useState([]);
  const [limitAddReceipts, setLimitAddReceipts] = useState(10);
  const [pagingDataAddReceipts, setPagingDataAddReceipts] = useState({
    current: 1,
  });

  const [expandAddStatement, setExpandAddStatement] = useState(false);
  const [addStatements, setAddStatements] = useState([]);
  const [limitAddStatements, setLimitAddStatements] = useState(10);
  const [pagingDataAddStatements, setPagingDataAddStatements] = useState({
    current: 1,
  });

  const [expandDeductPayment, setExpandDeductPayment] = useState(false);
  const [deductPayments, setDeductPayments] = useState([]);
  const [limitDeductPayments, setLimitDeductPayments] = useState(10);
  const [pagingDataDeductPayments, setPagingDataDeductPayments] = useState({
    current: 1,
  });

  const [expandDeductStatement, setExpandDeductStatement] = useState(false);
  const [deductStatements, setDeductStatements] = useState([]);
  const [limitDeductStatements, setLimitDeductStatements] = useState(10);
  const [pagingDataDeductStatements, setPagingDataDeductStatements] = useState({
    current: 1,
  });

  const [expandAllReceipt, setExpandAllReceipt] = useState(false);
  const [allReceipts, setAllReceipts] = useState([]);
  const [limitAllReceipts, setLimitAllReceipts] = useState(10);
  const [pagingDataAllReceipts, setPagingDataAllReceipts] = useState({
    current: 1,
  });

  const [expandAllPayment, setExpandAllPayment] = useState(false);
  const [allPayments, setAllPayments] = useState([]);
  const [limitAllPayments, setLimitAllPayments] = useState(10);
  const [pagingDataAllPayments, setPagingDataAllPayments] = useState({
    current: 1,
  });

  const [isExpandDataLoading, setIsExpandDataLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [aTotal, setATotal] = useState(0);
  const [bTotal, setBTotal] = useState(0);

  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [edit, setEdit] = useState();

  const toggleEditModal = () => {
    setEdit(!edit);
  };

  const updateMonthlyBalance = async (values) => {
    try {
      setIsUpdateLoading(true);
      let selectedDate =
        moment(date, "MM-YYYY").toDate() >=
          moment().startOf("month").toDate() &&
        moment().format("DD-MM-YYYY") >
          moment().endOf("month").format("DD-MM-YYYY")
          ? moment().subtract(1, "month").format("MM-YYYY")
          : moment(date, "MM-YYYY").format("MM-YYYY");
      setDate(selectedDate);
      values.amount = Number(`${values?.amount}`.replace(/,/g, ""));
      const reqObj = values;
      const formData = new FormData();
      formData.append("month", selectedDate);
      // formData.append('balance', amount);
      formData.append("balance", reqObj["amount"]);
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);

      const { data } = await fileUpload(
        `${API.CHANGE_MONTHLY_BANK_RECONCILIATION_BALANCE}`,
        formData
      );

      setIsUpdateLoading(false);
      if (data) {
        toast.success(data.message);
        toggleEditModal();
        fetchMonthlyBankReconciliationData();
      }
    } catch (error) {
      setIsUpdateLoading(false);
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(
          data.errors && data.errors.bt_error && data.errors.bt_error[0]
        );
      } else {
        toast.error("Something went wrong");
      }
      setIsDataLoading(false);
    }
  };

  const fetchMonthlyBankReconciliationData = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append("month", date);
      formData.append(`trust_type`, trust_type);
      const { data } = await fileUpload(
        `${API.MONTHLY_BANK_RECONCILIATION_REPORT}`,
        formData
      );
      setMonthlyData(data?.data);
      let monthlyData = data?.data;

      let sub_total =
        Number(
          monthlyData?.statement_balance ? monthlyData?.statement_balance : 0
        ) +
        Number(monthlyData?.transactionDepositNotStatement) +
        Number(monthlyData?.transactionWithdrawalStatements);
      setSubTotal(sub_total);

      let a_total =
        sub_total < 0
          ? sub_total +
            Number(
              Number(monthlyData?.transactionWithdrawalNotStatements) +
                Number(monthlyData?.transactionDepositStatement)
            )
          : sub_total +
            Number(
              Number(monthlyData?.transactionWithdrawalNotStatements) +
                Number(monthlyData?.transactionDepositStatement)
            ) *
              -1;

      setATotal(a_total);

      let b_total =
        Number(
          Number(
            monthlyData?.ptaAccountBalance ? monthlyData?.ptaAccountBalance : 0
          )
        ) +
        Number(monthlyData?.allTransactionDeposits) +
        Number(Number(monthlyData?.allTransactionWithdrawalStatements) * -1);

      setBTotal(b_total);

      setIsDataLoading(false);
      toast.success(data?.message);
    } catch (error) {
      const { data } = error.response;
      if (data) {
        toast.error(data?.message);
        toast.error(
          data.errors && data.errors.bt_error && data.errors.bt_error[0]
        );
      } else {
        toast.error("Something went wrong");
      }
      setIsDataLoading(false);
    }
  }, [date, projectId, trust_type]);

  useEffect(() => {
    fetchMonthlyBankReconciliationData();
  }, [fetchMonthlyBankReconciliationData]);

  const fetchMonthlyBankReconciliationReportEntries = useCallback(
    async (type, page = 1, limit = 10) => {
      try {
        setIsExpandDataLoading(true);
        const formData = new FormData();
        formData.append("builder_project_id", projectId ? projectId : "");
        formData.append(`trust_type`, trust_type);
        formData.append("month", date);
        formData.append("type", type);
        formData.append("page", page);
        formData.append("limit", limit);
        const { data } = await fileUpload(
          `${API.MONTHLY_BANK_RECONCILIATION_REPORT_ENTRIES}`,
          formData
        );
        if (data) {
          setIsExpandDataLoading(false);
          const { total, current_page, per_page, from, to } = data.data;
          if (type === "transactionDepositNotStatement") {
            setAddReceipts(data?.data.data);
            setPagingDataAddReceipts({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }

          if (type === "transactionWithdrawalStatements") {
            setAddStatements(data?.data.data);
            setPagingDataAddStatements({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }

          if (type === "transactionWithdrawalNotStatements") {
            setDeductPayments(data?.data.data);
            setPagingDataDeductPayments({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }

          if (type === "transactionDepositStatement") {
            setDeductStatements(data?.data.data);
            setPagingDataDeductStatements({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }

          if (type === "allTransactionDeposits") {
            setAllReceipts(data?.data.data);
            setPagingDataAllReceipts({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }

          if (type === "allTransactionWithdrawalStatements") {
            setAllPayments(data?.data.data);
            setPagingDataAllPayments({
              total,
              current: current_page,
              defaultPageSize: per_page,
              from: from,
              to: to,
            });
          }
        }
      } catch (error) {
        setIsExpandDataLoading(false);
        console.log("error :>> ", error);
      }
    },
    [date, projectId, trust_type]
  );

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/reconcile-transactions?tab=monthly-bank-reconciliation`
    );
  }, [navigate, projectId, trustType]);

  // Deposits in Cashbook but NOT in bank statement (Start)

  const toggleExpandRowAddReceipt = (value) => {
    if (value) {
      setExpandAddReceipt(!expandAddReceipt);
      setExpandAddStatement(false);
      setExpandAllPayment(false);
      setExpandAllReceipt(false);
      setExpandDeductPayment(false);
      setExpandDeductStatement(false);
    }
  };

  const handleChangeAddReceipts = async (e) => {
    const value = e.target.value;
    setAddReceipts();
    setLimitAddReceipts(value);
    fetchMonthlyBankReconciliationReportEntries(
      "transactionDepositNotStatement",
      pagingDataAddReceipts?.current,
      value
    );
  };

  const onPageChangeAddReceipt = (page) => {
    setAddReceipts();
    setPagingDataAddReceipts({ ...pagingDataAddReceipts, current: page });
    fetchMonthlyBankReconciliationReportEntries(
      "transactionDepositNotStatement",
      page,
      limitAddReceipts
    );
  };

  const ExpandDataGenerator = (type, quantity) => {
    const items = [];
    if (expandAddStatement || expandDeductStatement) {
      for (let i = 0; i < quantity; i++) {
        items.push({
          key: type[i]?.id,
          transactionDate: type[i]?.date
            ? moment(type[i]?.date, "DD-MM-YYYY").format("DD-MM-YYYY")
            : "-",
          description: type[i]?.description ? type[i]?.description : "-",
          credit:
            type[i]?.baseType === 1 ? (
              <span className="rag-green">
                {"$" + formatNumber(Number(type[i]?.amount))}
              </span>
            ) : (
              "-"
            ),
          debit:
            type[i]?.baseType === 0 ? (
              <span className="rag-red">
                {"$" + formatNumber(Number(type[i]?.amount))}
              </span>
            ) : (
              "-"
            ),
          bank_transaction_source: type[i]?.source ? type[i]?.source : "-",

          archived: type[i]?.deleted_at ? (
            <CustomTooltip message={"Archived"}>
              <span>
                <span color={"red"} key={"red"}>
                  {String.fromCodePoint("0x0001F6AB")}
                </span>
              </span>
            </CustomTooltip>
          ) : (
            ""
          ),

          // action: "",
        });
      }
    } else {
      for (let i = 0; i < quantity; i++) {
        items.push({
          key: type[i]?.id,
          transaction_date: type[i]?.transaction_date
            ? type[i]?.transaction_date
            : "-",
          // name: type[i]?.first_name
          //   ? formatName(type[i]?.first_name, type[i]?.last_name)
          //   : type[i]?.bussiness_name
          //   ? type[i]?.bussiness_name
          //   : type[i]?.last_name,
          name: formatSubcontractorName(type[i]),
          email: type[i]?.email ? type[i]?.email : "-",
          account_name: type[i]?.account_name ? type[i]?.account_name : "-",
          account_number: type[i]?.account_number
            ? type[i]?.account_number
            : "-",
          amount: type[i]?.amount
            ? `$${formatNumber(type[i]?.amount.toFixed(2))}`
            : "$0.00",
        });
      }
    }
    return items;
  };

  const expandAddReceiptData = ExpandDataGenerator(
    addReceipts,
    addReceipts?.length
  );

  // Deposits in Cashbook but NOT in bank statement (End)

  // Withdrawls on the bank statement that are NOT in Cashbook (Start)

  const toggleExpandRowAddStatement = (value) => {
    if (value) {
      setExpandAddReceipt(false);
      setExpandAddStatement(!expandAddStatement);
      setExpandAllPayment(false);
      setExpandAllReceipt(false);
      setExpandDeductPayment(false);
      setExpandDeductStatement(false);
    }
  };

  const handleChangeAddStatements = async (e) => {
    const value = e.target.value;
    setAddStatements();
    setLimitAddStatements(value);
    fetchMonthlyBankReconciliationReportEntries(
      "transactionWithdrawalStatements",
      1,
      value
    );
  };

  const onPageChangeAddStatement = (page) => {
    setAddStatements();
    setPagingDataAddStatements({ ...pagingDataAddStatements, current: page });
    fetchMonthlyBankReconciliationReportEntries(
      "transactionWithdrawalStatements",
      page,
      limitAddStatements
    );
  };

  const expandAddStatementData = ExpandDataGenerator(
    addStatements,
    addStatements?.length
  );

  // Withdrawls on the bank statement that are NOT in Cashbook (End)

  // Deduct: Withdrawls in the Cashbook but NOT in bank statement (Start)

  const toggleExpandRowDeductPayment = (value) => {
    if (value) {
      setExpandAddReceipt(false);
      setExpandAddStatement(false);
      setExpandAllPayment(false);
      setExpandAllReceipt(false);
      setExpandDeductPayment(!expandDeductPayment);
      setExpandDeductStatement(false);
    }
  };

  const handleChangeDeductPayments = async (e) => {
    const value = e.target.value;
    setDeductPayments();
    setLimitDeductPayments(value);
    fetchMonthlyBankReconciliationReportEntries(
      "transactionWithdrawalNotStatements",
      1,
      value
    );
  };

  const onPageChangeDeductPayment = (page) => {
    setDeductPayments();
    setPagingDataDeductPayments({ ...pagingDataDeductPayments, current: page });
    fetchMonthlyBankReconciliationReportEntries(
      "transactionWithdrawalNotStatements",
      page,
      limitDeductPayments
    );
  };

  const expandDeductPaymentData = ExpandDataGenerator(
    deductPayments,
    deductPayments?.length
  );

  // Deduct: Withdrawls in the Cashbook but NOT in bank statement (End)

  // Deduct: Deposits on the bank statement that are NOT in Cashbook (Start)

  const toggleExpandRowDeductStatement = (value) => {
    if (value) {
      setExpandAddReceipt(false);
      setExpandAddStatement(false);
      setExpandAllPayment(false);
      setExpandAllReceipt(false);
      setExpandDeductPayment(false);
      setExpandDeductStatement(!expandDeductStatement);
    }
  };

  const handleChangeDeductStatements = async (e) => {
    const value = e.target.value;
    setDeductStatements();
    setLimitDeductStatements(value);
    fetchMonthlyBankReconciliationReportEntries(
      "transactionDepositStatement",
      1,
      value
    );
  };

  const onPageChangeDeductStatement = (page) => {
    setDeductStatements();
    setPagingDataDeductStatements({
      ...pagingDataDeductStatements,
      current: page,
    });
    fetchMonthlyBankReconciliationReportEntries(
      "transactionDepositStatement",
      page,
      limitDeductStatements
    );
  };

  const expandDeductStatementData = ExpandDataGenerator(
    deductStatements,
    deductStatements?.length
  );

  // Deduct: Deposits on the bank statement that are NOT in Cashbook (End)

  // Add: All deposits recorded in the Cashbook for the month (Start)

  const toggleExpandRowAllReceipt = (value) => {
    if (value) {
      setExpandAddReceipt(false);
      setExpandAddStatement(false);
      setExpandAllPayment(false);
      setExpandAllReceipt(!expandAllReceipt);
      setExpandDeductPayment(false);
      setExpandDeductStatement(false);
    }
  };

  const handleChangeAllReceipts = async (e) => {
    const value = e.target.value;
    setAllReceipts();
    setLimitAllReceipts(value);
    fetchMonthlyBankReconciliationReportEntries(
      "allTransactionDeposits",
      1,
      value
    );
  };

  const onPageChangeAllReceipt = (page) => {
    setAllReceipts();
    setPagingDataAllReceipts({ ...pagingDataAllReceipts, current: page });
    fetchMonthlyBankReconciliationReportEntries(
      "allTransactionDeposits",
      page,
      limitAllReceipts
    );
  };

  const expandAllReceiptData = ExpandDataGenerator(
    allReceipts,
    allReceipts?.length
  );

  // Add: All deposits recorded in the Cashbook for the month (End)

  // Deduct: All withdrawls recorded in the Cashbook for the month (Start)

  const toggleExpandRowAllPayment = (value) => {
    if (value) {
      setExpandAddReceipt(false);
      setExpandAddStatement(false);
      setExpandAllPayment(!expandAllPayment);
      setExpandAllReceipt(false);
      setExpandDeductPayment(false);
      setExpandDeductStatement(false);
    }
  };

  const handleChangeAllPayments = async (e) => {
    const value = e.target.value;
    setAllPayments();
    setLimitAllPayments(value);
    fetchMonthlyBankReconciliationReportEntries(
      "allTransactionWithdrawalStatements",
      1,
      value
    );
  };

  const onPageChangeAllPayment = (page) => {
    setAllPayments();
    setPagingDataAllPayments({ ...pagingDataAllPayments, current: page });
    fetchMonthlyBankReconciliationReportEntries(
      "allTransactionWithdrawalStatements",
      page,
      limitAllPayments
    );
  };

  const expandAllPaymentData = ExpandDataGenerator(
    allPayments,
    allPayments?.length
  );

  // Deduct: All withdrawls recorded in the Cashbook for the month (End)

  function monthAddPadding(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const lastDate = (date) => {
    var data = date.split("-");
    let lastMonthDate = new Date(data[1], data[0], 0);
    return (
      lastMonthDate.getDate() +
      "/" +
      monthAddPadding(lastMonthDate.getMonth() + 1, 2) +
      "/" +
      lastMonthDate.getFullYear()
    );
  };

  const expandColumns = [
    {
      dataField: "transaction_date",
      text: "Transaction Date",
      style: { width: "12%" },
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "account_name",
      text: "Account Name",
    },
    {
      dataField: "account_number",
      text: "Account Number",
    },
    {
      dataField: "amount",
      text: "Amount",
      style: { width: "12%" },
    },
  ];

  useEffect(() => {
    if (expandAddReceipt) {
      fetchMonthlyBankReconciliationReportEntries(
        "transactionDepositNotStatement",
        1,
        limitAddReceipts
      );
    }
    if (expandAddStatement) {
      fetchMonthlyBankReconciliationReportEntries(
        "transactionWithdrawalStatements",
        1,
        limitAddStatements
      );
    }
    if (expandDeductPayment) {
      fetchMonthlyBankReconciliationReportEntries(
        "transactionWithdrawalNotStatements",
        1,
        limitDeductPayments
      );
    }
    if (expandDeductStatement) {
      fetchMonthlyBankReconciliationReportEntries(
        "transactionDepositStatement",
        1,
        limitDeductStatements
      );
    }
    if (expandAllReceipt) {
      fetchMonthlyBankReconciliationReportEntries(
        "allTransactionDeposits",
        1,
        limitAllReceipts
      );
    }
    if (expandAllPayment) {
      fetchMonthlyBankReconciliationReportEntries(
        "allTransactionWithdrawalStatements",
        1,
        limitAllPayments
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expandAddReceipt,
    expandAddStatement,
    expandDeductPayment,
    expandDeductStatement,
    expandAllReceipt,
    expandAllPayment,
    fetchMonthlyBankReconciliationReportEntries,
  ]);

  const handleExportReport = async () => {
    setExportLoading(true);
    try {
      const formData = new FormData();
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append("month", date);
      formData.append(`trust_type`, trust_type);
      formData.append("export", 1);

      const { data } = await fileUpload(
        `${API.MONTHLY_BANK_RECONCILIATION_REPORT}`,
        formData
      );

      const { file } = data?.data;

      if (file) {
        const response = await axios.get(`${file}`, {
          responseType: "blob",
        });
        const fileData = response?.data;

        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var files = file.split("/");
        a.setAttribute(
          "download",
          files[files.length - 1] || "monthly_bank_reconciliation.pdf"
        );
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (error) {
      setExportLoading(false);
      const { data } = error?.response;
      if (data) {
        toast.error(data?.message);
        toast.error(
          data?.errors && data?.errors.myna_error && data?.errors.myna_error[0]
        );
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  const expandColumns2 = [
    {
      dataField: "transactionDate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
    },
    {
      dataField: "debit",
      text: "Debit",
      sort: true,
    },
    {
      dataField: "bank_transaction_source",
      text: "Bank Transaction Source",
      // sort: true,
    },
    // {
    //   dataField: "archived",
    //   text: "Archived",
    //   style: { textAlign: "center" },
    //   // sort: true,
    // },
  ];

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block" style={{ display: "block" }}>
          <div style={{ textAlign: "right", width: "100%" }}>
            <div
              className="table-top-btn trial-btn monthly-bank-reconciliation-page"
              style={{ justifyContent: "end" }}
            >
              <div className="monthly-bank-reconciliation-btn">
                <MonthPicker
                  format="MM-YYYY"
                  picker="month"
                  value={
                    month == null
                      ? moment(date, "MM-YYYY")
                      : moment(month, "MM-YYYY")
                  }
                  onChange={(e) => {
                    setDate(moment(e).format("MM-YYYY"));
                    // let data = moment(e).format('MM-YYYY').split('-');
                    // setValueRange([new Date(data[1], data[0] - 1, 1), new Date(data[1], data[0], 0)]);
                  }}
                  disabled={[[nextMonth, false]]}
                  className="datefilter"
                  unclearable={true}
                />
              </div>
              <div>
                <Button
                  onClick={() => handleExportReport()}
                  disabled={exportLoading}
                  type="print"
                  style={{ marginLeft: "15px" }}
                >
                  {exportLoading ? "Loading…" : "Export"}
                </Button>
              </div>
            </div>
          </div>
          <div className="" style={{ textAlign: "center" }}>
            {(isDataLoading || isExpandDataLoading) && <Spin />}
            <div
              className="monthly-bank-reconciliation"
              style={{
                textAlign: "center",
                fontSize: "17px",
              }}
            >
              <div className="row">
                <div
                  className="col-md-12 center-align darkBlue-row border"
                  style={{
                    // background: "#06488b",
                    textAlign: "center",
                    color: "white",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  {projectName}
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "white",
                }}
              >
                <div className="col-md-2 center-align dark-row border">
                  Account Number
                </div>
                <div className="col-md-3 center-align dark-row border">
                  Account name
                </div>
                <div className="col-md-2 center-align dark-row border">
                  Date of reconcilation
                </div>
                <div className="col-md-2 center-align dark-row border">
                  End of month bank statement
                </div>
                <div className="col-md-3 center-align dark-row border">
                  End of month account balance($)
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-2 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    border: `1px solid #dee2e6!important`,
                  }}
                >
                  {monthlyData?.projectBankDetails?.account_number
                    ? monthlyData?.projectBankDetails?.account_number
                    : ""}
                </div>
                <div
                  className="col-md-3 center-align white-row border"
                  style={
                    {
                      // marginRight: "2px",
                    }
                  }
                >
                  {monthlyData?.projectBankDetails?.account_name
                    ? monthlyData?.projectBankDetails?.account_name
                    : ""}
                </div>
                <div
                  className="col-md-2 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {currentDate
                    ? currentDate.getDate() +
                      "/" +
                      monthAddPadding(currentDate.getMonth() + 1, 2) +
                      "/" +
                      currentDate.getFullYear()
                    : ""}
                </div>
                <div
                  className="col-md-2 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {date ? lastDate(date) : ""}
                </div>
                <div
                  className="col-md-3 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  <div className="monthly-amount-edit">
                    {monthlyData?.statement_balance
                      ? monthlyData?.statement_balance > 0
                        ? formatNumber(monthlyData?.statement_balance)
                        : `(${formatNumber(
                            monthlyData?.statement_balance * -1
                          )})`
                      : "0.00"}
                    <span className="amount-change-button">
                      {/* {!edit && ( */}
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => setEdit(true)}
                      />
                      {/* )} */}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "white",
                }}
              >
                <div className="col-md-7 center-align darkBlue-row border">
                  Reconciliation
                </div>
                <div className="col-md-5 center-align darkBlue-row border">
                  Value($)
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 center-align dark-row border"
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Bank Statement Reconcilation{" "}
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  Account balance on {date ? lastDate(date) : ""}
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.statement_balance
                    ? monthlyData?.statement_balance > 0
                      ? formatNumber(monthlyData?.statement_balance)
                      : `(${formatNumber(monthlyData?.statement_balance * -1)})`
                    : "0.00"}
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowAddReceipt(
                        monthlyData?.transactionDepositNotStatement
                      )
                    }
                  >
                    {expandAddReceipt ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Add: Deposits in Cashbook but NOT in bank statement
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.transactionDepositNotStatement
                    ? formatNumber(monthlyData?.transactionDepositNotStatement)
                    : "0.00"}
                </div>
              </div>
              {expandAddReceipt && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandAddReceiptData}
                      columns={expandColumns}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataAddReceipts?.total}
                      limit={parseInt(pagingDataAddReceipts?.defaultPageSize)}
                      currentPage={pagingDataAddReceipts?.current}
                      updateLimit={handleChangeAddReceipts}
                      updatePage={onPageChangeAddReceipt}
                      from={pagingDataAddReceipts?.from}
                      to={pagingDataAddReceipts?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowAddStatement(
                        monthlyData?.transactionWithdrawalStatements
                      )
                    }
                  >
                    {expandAddStatement ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Add: Withdrawls on the bank statement that are NOT in
                    Cashbook
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.transactionWithdrawalStatements
                    ? formatNumber(monthlyData?.transactionWithdrawalStatements)
                    : "0.00"}
                </div>
              </div>
              {expandAddStatement && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandAddStatementData}
                      columns={expandColumns2}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataAddStatements?.total}
                      limit={parseInt(pagingDataAddStatements?.defaultPageSize)}
                      currentPage={pagingDataAddStatements?.current}
                      updateLimit={handleChangeAddStatements}
                      updatePage={onPageChangeAddStatement}
                      from={pagingDataAddStatements?.from}
                      to={pagingDataAddStatements?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                    background: "#ccc",
                    fontWeight: "bold",
                  }}
                >
                  Subtotal
                </div>
                <div
                  className="col-md-5 center-align border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                    background: "#ccc",
                    fontWeight: "bold",
                  }}
                >
                  {subTotal
                    ? subTotal > 0
                      ? formatNumber(subTotal)
                      : `(${formatNumber(subTotal * -1)})`
                    : "0.00"}
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowDeductPayment(
                        monthlyData?.transactionWithdrawalNotStatements
                      )
                    }
                  >
                    {expandDeductPayment ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Deduct: Withdrawls in the Cashbook but NOT in bank statement
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.transactionWithdrawalNotStatements
                    ? formatNumber(
                        monthlyData?.transactionWithdrawalNotStatements
                      )
                    : "0.00"}
                </div>
              </div>
              {expandDeductPayment && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandDeductPaymentData}
                      columns={expandColumns}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataDeductPayments?.total}
                      limit={parseInt(
                        pagingDataDeductPayments?.defaultPageSize
                      )}
                      currentPage={pagingDataDeductPayments?.current}
                      updateLimit={handleChangeDeductPayments}
                      updatePage={onPageChangeDeductPayment}
                      from={pagingDataDeductPayments?.from}
                      to={pagingDataDeductPayments?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowDeductStatement(
                        monthlyData?.transactionDepositStatement
                      )
                    }
                  >
                    {expandDeductStatement ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Deduct: Deposits on the bank statement that are NOT in
                    Cashbook
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.transactionDepositStatement
                    ? formatNumber(monthlyData?.transactionDepositStatement)
                    : "0.00"}
                </div>
              </div>
              {expandDeductStatement && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandDeductStatementData}
                      columns={expandColumns2}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataDeductStatements?.total}
                      limit={parseInt(
                        pagingDataDeductStatements?.defaultPageSize
                      )}
                      currentPage={pagingDataDeductStatements?.current}
                      updateLimit={handleChangeDeductStatements}
                      updatePage={onPageChangeDeductStatement}
                      from={pagingDataDeductStatements?.from}
                      to={pagingDataDeductStatements?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                    background: "#ccc",
                  }}
                >
                  A: Expected balance on Cashbook
                </div>
                <div
                  className="col-md-5 center-align border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                    background: "#ccc",
                  }}
                >
                  {aTotal
                    ? aTotal > 0
                      ? formatNumber(aTotal)
                      : `(${formatNumber(aTotal * -1)})`
                    : "0.00"}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 center-align dark-row border"
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Cashbook Reconcilation
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  Balance of trust account at the start of the month, as shown
                  in the Cashbook{" "}
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.ptaAccountBalance
                    ? monthlyData?.ptaAccountBalance > 0
                      ? formatNumber(monthlyData?.ptaAccountBalance)
                      : `(${formatNumber(monthlyData?.ptaAccountBalance * -1)})`
                    : "0.00"}
                </div>
              </div>

              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowAllReceipt(
                        monthlyData?.allTransactionDeposits
                      )
                    }
                  >
                    {expandAllReceipt ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Add: All deposits recorded in the Cashbook for the month
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.allTransactionDeposits
                    ? formatNumber(monthlyData?.allTransactionDeposits)
                    : "0.00"}
                </div>
              </div>
              {expandAllReceipt && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandAllReceiptData}
                      columns={expandColumns}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataAllReceipts?.total}
                      limit={parseInt(pagingDataAllReceipts?.defaultPageSize)}
                      currentPage={pagingDataAllReceipts?.current}
                      updateLimit={handleChangeAllReceipts}
                      updatePage={onPageChangeAllReceipt}
                      from={pagingDataAllReceipts?.from}
                      to={pagingDataAllReceipts?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      toggleExpandRowAllPayment(
                        monthlyData?.allTransactionWithdrawalStatements
                      )
                    }
                  >
                    {expandAllPayment ? (
                      <FontAwesomeIcon icon={faCaretDown} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretRight} />
                    )}
                  </span>
                  <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                    Deduct: All withdrawls recorded in the Cashbook for the
                    month{" "}
                  </span>
                </div>
                <div
                  className="col-md-5 center-align white-row border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                  }}
                >
                  {monthlyData?.allTransactionWithdrawalStatements
                    ? formatNumber(
                        monthlyData?.allTransactionWithdrawalStatements
                      )
                    : "0.00"}
                </div>
              </div>
              {expandAllPayment && (
                <div className="row">
                  <div className="col-md-12 monthly-expand-table border">
                    <BootstrapTable
                      keyField="key"
                      remote
                      data={expandAllPaymentData}
                      columns={expandColumns}
                      noDataIndication="No Data Found"
                    />
                    <Pagination
                      total={pagingDataAllPayments?.total}
                      limit={parseInt(pagingDataAllPayments?.defaultPageSize)}
                      currentPage={pagingDataAllPayments?.current}
                      updateLimit={handleChangeAllPayments}
                      updatePage={onPageChangeAllPayment}
                      from={pagingDataAllPayments?.from}
                      to={pagingDataAllPayments?.to}
                    />
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                    background: "#ccc",
                  }}
                >
                  B: Balance of the trust account at the end of the month, as
                  shown in the Cashbook
                </div>
                <div
                  className="col-md-5 center-align border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                    background: "#ccc",
                  }}
                >
                  {bTotal
                    ? bTotal > 0
                      ? formatNumber(bTotal)
                      : `(${formatNumber(bTotal * -1)})`
                    : "0.00"}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 center-align dark-row border"
                  style={{
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Trust Account Ledger Trial Balance
                </div>
              </div>
              <div
                className="row"
                style={{
                  // marginBlock: "4px",
                  display: "flex",
                  color: "black",
                }}
              >
                <div
                  className="col-md-7 center-align border"
                  style={{
                    textAlign: "left",
                    // marginRight: "2px",
                    background: "#ccc",
                  }}
                >
                  C: Total balance held from the trust account ledger trial
                  balance statement for the month
                </div>
                <div
                  className="col-md-5 center-align border"
                  style={{
                    // marginRight: "2px",
                    textAlign: "right",
                    background: "#ccc",
                  }}
                >
                  {monthlyData?.trialBalance
                    ? monthlyData?.trialBalance > 0
                      ? formatNumber(Number(monthlyData?.trialBalance))
                      : `(${formatNumber(
                          Number(monthlyData?.trialBalance) * -1
                        )})`
                    : "0.00"}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-12 center-align dark-row border"
                  style={{
                    textAlign: "center",
                    color: "white",
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  A=B=C means the account is reconciled
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {edit && (
        <div>
          <Modal
            className="mid-modal supplier-modal"
            id="opening_balance_modal"
            show={edit}
            onHide={toggleEditModal}
            dialogClassName="modal-50w larg-popup review-popup small-review"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Edit Balance</Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  amount: monthlyData?.statement_balance
                    ? monthlyData?.statement_balance
                    : "0.00",
                }}
                name="supplierForm"
                enableReinitialize={true}
                onSubmit={(values) => {
                  updateMonthlyBalance(values);
                }}
                validateOnBlur={true}
                validateOnChange={true}
              >
                {({
                  values,
                  setErrors,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <>
                      {isUpdateLoading && <Spin />}

                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        className="entry-section"
                      >
                        <div className="form-box">
                          <div className="form-group full-width">
                            <label>Amount($)</label>
                            <NumberFormat
                              type="text"
                              name="amount"
                              value={values?.amount}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              decimalScale={2}
                              allowNegative={false}
                              thousandSeparator={true}
                              isAllowed={(values) => MaxLimit(values)}
                              autoComplete="off"
                              onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                              }}
                            />
                            <span
                              className="text-danger"
                              style={{ color: "red", fontSize: "20px" }}
                            >
                              <ErrorMessage name="amount" />
                            </span>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <Button
                            type="submit"
                            className="btn btn-primary"
                            disabled={values?.amount ? false : true}
                          >
                            Save
                          </Button>
                          <Button
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              toggleEditModal();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default MonthlyBankReconciliation;
