import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditRoles from "../EditRoles";
import Spin from "../../../common/Spin";
import { Switch } from "@material-ui/core";
import { API, fileUpload } from "../../../../config";
import { toast } from "react-toastify";
import CustomTooltip from "../../../common/CustomTooltip";

const RolesActionButton = ({
  id,
  rolesModal,
  statusMode,
  status,
  fetchRoles,
}) => {
  const [showRoles, setShowRoles] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleRolesModal = () => {
    showRoles && rolesModal();

    setShowRoles(!showRoles);
  };

  const handleRoleStatus = async (id, status) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("status", status ? 0 : 1);
      formData.append("id", id);

      const { data } = await fileUpload(`${API.ROLE_STATUS}`, formData, {});
      setLoading(false);
      if (data) {
        toast.success(data.message);
        fetchRoles();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <>
      {statusMode ? (
        <>
          <span className="verify-ps">
            {loading && <Spin />}
            <Switch
              // defaultChecked={status ? true : false}
              checked={status ? true : false}
              onChange={() => {
                handleRoleStatus(id, status);
              }}
            />
          </span>
        </>
      ) : (
        <CustomTooltip message={"Edit"}>
          <span>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={toggleRolesModal}
            />
          </span>
        </CustomTooltip>
      )}

      {showRoles && (
        <EditRoles
          rolesId={id}
          showRoles={showRoles}
          toggleRolesModal={toggleRolesModal}
        />
      )}
    </>
  );
};

export default RolesActionButton;
