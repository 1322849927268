import { toast } from "react-toastify";
import { API, get } from "../../../../config";

export const getHolidaysPopupDetail = async (holidaysId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_HOLIDAY}/${holidaysId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_HOLIDAY}/${holidaysId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const getHolidaysDetail = async (holidaysId) => {
  try {
    const { data } = await get(`${API.HOLIDAY}/` + holidaysId);
    return data.data;
  } catch (e) {
    return null;
  }
};
