import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath } from "../../../../config";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatName,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getBuilderPopupDetail } from "./BuilderHelper";

const BuilderDetailsPopup = ({ builderId, toggleShowModal, showView }) => {
  const [builderDetail, setBuilderDetail] = useState(null);
  const [builderLoading, setBuilderLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const builderDetailData = await getBuilderPopupDetail(builderId, true);
      setBuilderDetail(builderDetailData);
      setBuilderLoading(false);
    }
    fetchData();
  }, [builderId, setBuilderDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Builder Details
      </Modal.Header>
      <Modal.Body>
        {builderLoading ? (
          <Spin />
        ) : (
          <>
            <div className="modal-content-details justify-center">
              <div className="profile full">
                {builderDetail?.builder?.logo === null ||
                builderDetail?.builder?.logo === "" ? (
                  <span>{builderDetail?.first_name?.charAt(0)}</span>
                ) : (
                  <img
                    src={`${imageDefaultPrefixPath}${builderDetail?.builder?.logo}`}
                    alt=""
                  />
                )}
                <h5>
                  {builderDetail?.builder?.business_name
                    ? builderDetail?.builder?.business_name
                    : formatName(
                        builderDetail?.first_name,
                        builderDetail?.last_name
                      )}
                </h5>
              </div>
            </div>
            <div className="modal-content">
              <h3>Personal Details</h3>
              <div className="modal-content-details">
                <p>
                  <strong>First Name:</strong>{" "}
                  {builderDetail?.first_name ? builderDetail?.first_name : "-"}
                </p>
                <p>
                  <strong>Last Name:</strong> {builderDetail?.last_name}
                </p>
                <p>
                  <strong>Email Address:</strong> {builderDetail?.email}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {builderDetail?.phone
                    ? formatPhone(builderDetail?.phone)
                    : ""}
                </p>
                <p>
                  <strong>Mobile Number:</strong>{" "}
                  {builderDetail?.mobile
                    ? formatMobile(builderDetail?.mobile)
                    : "-"}
                </p>
                <p>
                  <strong>Business Name:</strong>{" "}
                  {builderDetail?.builder?.business_name
                    ? builderDetail?.builder?.business_name
                    : "-"}
                </p>
                <p>
                  <strong>ABN:</strong>{" "}
                  {builderDetail?.builder?.abn
                    ? formatABN(builderDetail?.builder?.abn)
                    : "-"}
                </p>
                <p>
                  <strong>CRN:</strong>{" "}
                  {builderDetail?.builder?.crn
                    ? builderDetail?.builder?.crn
                    : "-"}
                </p>
                <p>
                  <strong>Postal Address:</strong>{" "}
                  {formatedAddress(
                    builderDetail?.builder?.street,
                    builderDetail?.builder?.suburb?.name,
                    builderDetail?.builder?.state?.name,
                    builderDetail?.builder?.postal_code
                  )}
                </p>
                <p>
                  <strong>Street Address:</strong>{" "}
                  {formatedAddress(
                    builderDetail?.builder?.shipping_street,
                    builderDetail?.builder?.shipping_suburb?.name,
                    builderDetail?.builder?.shipping_state?.name,
                    builderDetail?.builder?.shipping_postal_code
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BuilderDetailsPopup;
