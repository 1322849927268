import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import Spin from "../../common/Spin";
import { useParams } from "react-router-dom";
import moment from "moment";
import { checkIfFileExists, formatNumber } from "../../common/Misc";
import { DateRangePicker } from "rsuite";
import BackButton from "../../../components/Form/BackButton";
import { getProjectData } from "../components/ProjectHelper";
import ReactSelect from "react-select";

const currentDate = new Date();

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1)
  );
}

const today = new Date();
const defaultDateRange =
  parseInt(moment(today).format("MM")) === 7
    ? [new Date(), new Date()]
    : parseInt(moment(today).format("MM")) > 7
    ? [new Date(new Date().getFullYear(), 6, 1), new Date()]
    : [new Date(new Date().getFullYear() - 1, 6, 1), new Date()];

const COAGeneralLedgerReport = ({
  dateRangeReport,
  setDateRangeReport,
  setAccountingType,
  accountingOptions,
  accountingType,
}) => {
  const params = useParams();
  // const { afterToday } = DateRangePicker;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const projectId = params?.project_id;
  // const { afterToday } = DateRangePicker;
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  const [generalLedgerLoading, setGeneralLedgerLoading] = useState(false);
  const [generalLedgerData, setGeneralLedgerData] = useState([]);
  // const [exportGeneralLedgerReportLoading, setExportGeneralLedgerReportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [dateValue, setDateValue] = useState(
    dateRangeReport ? dateRangeReport : defaultDateRange
  );
  const [startDate, setStartDate] = useState(
    dateRangeReport
      ? moment(dateRangeReport[0]).format("DD-MM-YYYY")
      : defaultDateRange
      ? moment(defaultDateRange[0]).format("DD-MM-YYYY")
      : ""
  );
  const [endDate, setEndDate] = useState(
    dateRangeReport
      ? moment(dateRangeReport[1]).format("DD-MM-YYYY")
      : defaultDateRange
      ? moment(defaultDateRange[1]).format("DD-MM-YYYY")
      : ""
  );

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      label: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Quarter",
      value: quarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Quarter",
      value: perviousQuarterRange,
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
    {
      label: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
      placement: "bottom",
      appearance: "default",
      closeOverlay: false,
    },
  ];

  const fetchGeneralLedgerData = useCallback(async () => {
    if (projectId) {
      try {
        setGeneralLedgerLoading(true);
        const formData = new FormData();
        formData.append("start_date", startDate ? startDate : "");
        formData.append("end_date", endDate ? endDate : "");
        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trust_type);
        formData.append("export", 0);
        trust_type === 1 && formData.append("accounting", accountingType);

        const { data } = await fileUpload(
          `${API.COA_GENERAL_LEDGER_REPORT}`,
          formData
        );

        setGeneralLedgerLoading(false);
        setGeneralLedgerData(data?.data);
      } catch (error) {
        const { data } = error.response;
        setGeneralLedgerLoading(false);
        setGeneralLedgerData([]);
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
  }, [projectId, trust_type, startDate, endDate, accountingType]);

  const generalLedgerDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      if (generalLedgerData[i]?.reports.length !== 0) {
        items.push({
          key: generalLedgerData[i]?.id
            ? `${generalLedgerData[i]?.id}-name`
            : "",
          date: (
            <div className="general-ledger-report-heading">
              {generalLedgerData[i]?.name ? generalLedgerData[i]?.name : ""}
            </div>
          ),
          source: <div className="report_blank_data"></div>,
          contact: <div className="report_blank_data"></div>,
          description: <div className="report_blank_data"></div>,
          debit: <div className="report_blank_data"></div>,
          credit: <div className="report_blank_data"></div>,
          gst_amount: <div className="report_blank_data"></div>,
          net_amount: <div className="report_blank_data"></div>,
        });
      }
      generalLedgerData[i]?.reports?.sort((a, b) => {
        a = a?.date.split("-");
        b = b?.date.split("-");
        return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
      });
      for (let j = 0; j < generalLedgerData[i]?.reports.length; j++) {
        items.push({
          key: generalLedgerData[i]?.id
            ? `${generalLedgerData[i]?.id}-item-${j}`
            : "",
          date: generalLedgerData[i]?.reports[j]?.date
            ? moment(
                generalLedgerData[i]?.reports[j]?.date,
                "DD-MM-YYYY"
              ).format("DD-MM-YYYY")
            : "-",
          name: generalLedgerData[i]?.name ? generalLedgerData[i]?.name : "",

          source: generalLedgerData[i]?.reports[j]?.source
            ? generalLedgerData[i]?.reports[j]?.source
            : "",

          description: generalLedgerData[i]?.reports[j]?.description
            ? generalLedgerData[i]?.reports[j]?.description
            : "",

          contact: generalLedgerData[i]?.reports[j]?.contact
            ? generalLedgerData[i]?.reports[j]?.contact
            : "",

          debit:
            generalLedgerData[i]?.reports[j]?.transaction_type === 0
              ? `$${formatNumber(
                  generalLedgerData[i]?.reports[j]?.debit_amount
                )}`
              : "-",

          credit:
            generalLedgerData[i]?.reports[j]?.transaction_type === 1
              ? `$${formatNumber(
                  generalLedgerData[i]?.reports[j]?.credit_amount
                )}`
              : "-",

          gst_amount: generalLedgerData[i]?.reports[j]?.gst_amount
            ? `$${formatNumber(generalLedgerData[i]?.reports[j]?.gst_amount)}`
            : "-",

          net_amount: generalLedgerData[i]?.reports[j]?.net_amount
            ? generalLedgerData[i]?.reports[j]?.net_amount < 0 ? `($${formatNumber(Math.abs(generalLedgerData[i]?.reports[j]?.net_amount))})`: `$${formatNumber(generalLedgerData[i]?.reports[j]?.net_amount)}`
            : "-",
        });
      }
      if (generalLedgerData[i]?.reports.length !== 0) {
        items.push({
          key: generalLedgerData[i]?.id
            ? `${generalLedgerData[i]?.id}-total`
            : "",
          date: (
            <div className="general-ledger-report-heading general-ledger-report-total-body report_blank_data">
              <div>
                {" "}
                <span className="general-ledger-report-heading-total">
                  Total
                </span>{" "}
                <span>
                  {generalLedgerData[i]?.name ? generalLedgerData[i]?.name : ""}
                </span>{" "}
              </div>
            </div>
          ),
          net_amount: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.net_total
                ? `$${formatNumber(generalLedgerData[i]?.net_total)}`
                : "$0.00"}
            </div>
          ),
          contact: <div className="report_blank_data"></div>,
          source: <div className="report_blank_data"></div>,
          description: <div className="report_blank_data"></div>,
          debit: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.debit_total
                ? `$${formatNumber(generalLedgerData[i]?.debit_total)}`
                : ""}
            </div>
          ),
          credit: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.credit_total
                ? `$${formatNumber(generalLedgerData[i]?.credit_total)}`
                : ""}
            </div>
          ),
          gst_amount: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.gst_total
                ? `$${formatNumber(generalLedgerData[i]?.gst_total)}`
                : ""}
            </div>
          ),
        });
      }
      if (generalLedgerData[i]?.reports.length !== 0) {
        let netMovement = generalLedgerData[i]?.net_total;
        items.push({
          key: generalLedgerData[i]?.id
            ? `${generalLedgerData[i]?.id}-net-movement`
            : "",
          date: (
            <div className="general-ledger-report-heading general-ledger-report-total-body report_blank_data">
              <div>
                {" "}
                <span className="general-ledger-report-heading-total">
                  Net
                </span>{" "}
                <span>Movement</span>{" "}
              </div>
            </div>
          ),
          net_amount: <div className=""></div>,
          description: <div className="report_blank_data"></div>,
          contact: <div className="report_blank_data"></div>,
          source: <div className="report_blank_data"></div>,
          debit: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.debit_total >=
              generalLedgerData[i]?.credit_total
                ? `$${formatNumber(Math.abs(netMovement)) !== 0 ? formatNumber(Math.abs(netMovement)) : '0.00'}`
                : ""}
            </div>
          ),
          credit: (
            <div className="report_blank_data report-total">
              {generalLedgerData[i]?.debit_total <=
              generalLedgerData[i]?.credit_total
                ? `$${formatNumber(Math.abs(netMovement)) !== 0 ? formatNumber(Math.abs(netMovement)) : '0.00'}`
                : ""}
            </div>
          ),
          gst_amount: <div className=""></div>,
        });
      }
      if (
        generalLedgerData[i]?.reports.length !== 0 &&
        i < generalLedgerData?.length - 1
      ) {
        items.push({
          key: generalLedgerData[i]?.id
            ? `${generalLedgerData[i]?.id}-blank-row`
            : "",
          date: <div className="report_blank_data"></div>,
          net_amount: <div className="report_blank_data"></div>,
          description: <div className="report_blank_data"></div>,
          contact: <div className="report_blank_data"></div>,
          source: <div className="report_blank_data"></div>,
          debit: <div className="report_blank_data"></div>,
          credit: <div className="report_blank_data"></div>,
          gst_amount: <div className="report_blank_data"></div>,
        });
      }
    }
    return items;
  };
  const generalLedgerReportData = generalLedgerDataGenerator(
    generalLedgerData?.length
  );

  useEffect(() => {
    fetchGeneralLedgerData();
  }, [fetchGeneralLedgerData]);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.date?.props?.className ===
        "general-ledger-report-heading general-ledger-report-total-body report_blank_data"
          ? { colSpan: "4" }
          : row?.date?.props?.className === "general-ledger-report-heading"
          ? { colSpan: "8" }
          : row?.date?.props?.className === "report_blank_data"
          ? { colSpan: "8" }
          : "",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "source",
      text: "Source",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.source?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "12%" };
      },
    },
    {
      dataField: "contact",
      text: "Contact",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.contact?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "9%" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.description?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.debit?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.credit?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "gst_amount",
      text: "GST",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.gst_amount?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "net_amount",
      text: "Net Amount",
      align: "right",
      headerAlign: (column, colIndex) => "right",
      attrs: (cell, row, col, rowIndex, colIndex) =>
        row?.net_amount?.props?.className === "report_blank_data" && {
          hidden: true,
        },
      headerStyle: () => {
        return { width: "14%" };
      },
    },
  ];

  const handleExportReport = async (format) => {
    try {
      setExportLoading(true);
      const formData = new FormData();
      formData.append("start_date", startDate ? startDate : "");
      formData.append("end_date", endDate ? endDate : "");
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);
      formData.append("export", 1);
      formData.append("format", format);
      trust_type === 1 && formData.append("accounting", accountingType);

      const { data } = await fileUpload(
        `${API.COA_GENERAL_LEDGER_REPORT}`,
        formData
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportLoading(false);
      }
    } catch (e) {
      setExportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleDateRange = (e) => {
    setDateValue(e);
    setDateRangeReport(e);
    setStartDate(e ? moment(e[0]).format("DD-MM-YYYY") : "");
    setEndDate(e ? moment(e[1]).format("DD-MM-YYYY") : "");
  };

  return (
    <div className="cms-page general-ledger-report-coa">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trust_type === 0
              ? "Financial Reports (RTA)"
              : "Financial Reports (PTA)"}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <div className="table-top-btn report-header-row">
              <div className="report-name report-period-name">
                <span>
                  {" "}
                  General Ledger
                  {startDate &&
                    endDate &&
                    ` For the period from ${startDate} to ${endDate}`}
                </span>
              </div>
              <div className="table-btn">
                <Dropdown autoClose="outside" style={{ marginRight: "15px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportLoading ? "Loading…" : "Export GL"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleExportReport("pdf")}>
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleExportReport("xlsx")}>
                      Excel
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  border: "none",
                  marginTop: "0px",
                  marginLeft: 20,
                }}
              >
                <Form>
                  <DateRangePicker
                    placeholder="Select Date Range"
                    cleanable={false}
                    onChange={(e) => handleDateRange(e)}
                    format="dd-MM-yyyy"
                    value={dateValue}
                    placement="bottomEnd"
                    ranges={dateFilterRange}
                    locale={{ ok: "Apply" }}
                  />
                </Form>
              </div>
              {trust_type === 1 && (
                <div
                  style={{
                    marginLeft: 15,
                  }}
                >
                  <ReactSelect
                    placeholder="Select Accounting Type"
                    isSearchable={false}
                    loading={accountingOptions.length === 0 ? true : false}
                    value={
                      accountingOptions?.find(
                        (item) => item?.value === parseInt(accountingType)
                      )
                        ? accountingOptions?.find(
                            (item) => item?.value === parseInt(accountingType)
                          )
                        : ""
                    }
                    onChange={(id) => {
                      if (id?.value !== accountingType) {
                        setGeneralLedgerData([]);
                      }
                      setAccountingType(id?.value);
                    }}
                    className="selectpicker contactname-select"
                    classNamePrefix="chartofselect"
                    options={accountingOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "5px",
                      colors: {
                        ...theme.colors,
                        primary: "grey",
                      },
                    })}
                  />
                </div>
              )}
            </div>

            <div className="">
              {generalLedgerLoading && <Spin />}
              <BootstrapTable
                keyField="key"
                remote
                data={generalLedgerReportData}
                columns={columns}
                noDataIndication="No Data Found"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COAGeneralLedgerReport;
