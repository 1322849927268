import React, { useState } from "react";
import { formatABN, MaxLimit } from "../../../common/Misc";
import { Link } from "react-router-dom";

import { Button, Form, Modal } from "react-bootstrap";

// import { DatePicker } from "@semcore/date-picker";
import moment from "moment";
import Spin from "../../../common/Spin";
import NumberFormat from "react-number-format";
import ContractorSelectWithTA from "../../../common/ContractorSelectWithTA";
import { toast } from "react-toastify";
import { DatePicker } from "rsuite";
// import ReactInputMask from "react-input-mask";

const AddPaymnetClaim = ({
  loading,
  projectId,
  contractorDetails,
  handleContractorSelect,
  disableState,
  invoiceNumber,
  setInvoiceNumber,
  setEntryData,
  entryData,
  claimedAmount,
  setClaimedAmount,
  invoiceDate,
  setInvoiceDate,
  setPreviousDate,
  tomorrow,
  dueDate,
  previousdate,
  setDueDate,
  setClaimABN,
  claimABN,
  setContractBusiness,
  contractBusiness,
  // totalClaimedAmount,
  // totalScheduleAmount,
  // totalAmountWithheld,
  // handleScheduledDateChange,
  // errors,
  // handleScheduleAmountChange,
  // handleDescriptionChange,
  // handleAmountWithheldChange,
  // handleReasonChange,
  show,
  handleConfirmModal,
  handleSubmitPost,
  finalSubminData,
  contractorData,
  edit,
  receiveDate,
  setReceiveDate,
  holidayList,
  // approvedAmount,
  // setApprovedAmount,
  // approvedDate,
  // setApprovedDate,
  // approvedReason,
  // setApprovedReason,
}) => {
  // let today = new Date().setHours(0, 0, 0, 0);
  const [errors, setError] = useState(null);
  const [showErrorReceivedDate, setShowErrorReceivedDate] = useState(null);

  const toggleErrorReceviedDate = () => {
    // if (showErrorReceivedDate) {
    //   setReceiveDate(today);
    // }
    // setShowErrorReceivedDate(!showErrorReceivedDate);
    setShowErrorReceivedDate(true);
  };

  function isBusinessDay(date, sortHoildayDate) {
    const dayOfWeek = date.getDay();
    const formattedDate = date
      .toISOString()
      .split("T")[0]
      ?.split("-")
      ?.reverse()
      ?.join("-");
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday (0) or Saturday (6)
    const queenslandPublicHolidays = sortHoildayDate;
    const isPublicHoliday = queenslandPublicHolidays.includes(formattedDate);
    return !isWeekend && !isPublicHoliday;
  }

  function getBusinessDays(startDate, endDateString, sortHoildayDate) {
    let count = 0;
    const [day, month, year] = startDate?.split("-");

    // Create a new Date object
    const currentDate = new Date(`${year}-${month}-${day}`);
    const [eDay, eMonth, eYear] = endDateString?.split("-");

    // Create a new Date object
    const endDate = new Date(`${eYear}-${eMonth}-${eDay}`);

    while (currentDate <= endDate) {
      if (isBusinessDay(currentDate, sortHoildayDate)) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  }

  const checkBusinessDays = (receiveDate, holidayList) => {
    let receiveDateValue = moment(receiveDate).format("DD-MM-YYYY");
    let todayDate = moment(new Date().setHours(0, 0, 0, 0)).format(
      "DD-MM-YYYY"
    );
    let holidaysDate = holidayList?.map((item) => item?.date);
    if (holidaysDate?.length > 0) {
      if (holidaysDate?.includes(todayDate)) {
      } else {
        holidaysDate.push(todayDate);
      }
    }
    let sortHoildayDate = holidaysDate.sort(function (a, b) {
      var aa = a.split("-").reverse().join(),
        bb = b.split("-").reverse().join();
      return aa < bb ? -1 : aa > bb ? 1 : 0;
    });
    let days = getBusinessDays(receiveDateValue, todayDate, sortHoildayDate);
    if (days > 3) {
      setError({
        message:
          "The transaction must be recorded within 3 business days. Kindly review the invoice received date.",
        count: days,
      });
      toggleErrorReceviedDate();
    } else {
      setShowErrorReceivedDate(false);
    }
  };

  return (
    <>
      <div>
        {loading && <Spin />}
        <div className="payment-schedule-page">
          <div className={"jounal-entry-card"}>
            <span className="journal-entry-status">
              <Form>
                <div className="payment-schedule-header-left-side">
                  <div className="payment-schedule-header-item form-group">
                    <p>
                      <span className="required">*</span>Payment Claim By
                    </p>
                    <ContractorSelectWithTA
                      regionName={"0"}
                      projectId={projectId}
                      value={contractorDetails ? contractorDetails : null}
                      onChange={(contractor) => {
                        handleContractorSelect(contractor);
                      }}
                      business={true}
                      className="contactname-select selectbox"
                      placeholder="Select Subcontractor"
                      isClearable={false}
                    />
                    {!disableState && contractorDetails && (
                      <div className="bank-details">
                        <p className="text-danger">
                          {/* Subcontractor does not have bank details */}
                          <Link
                            to={{
                              pathname: `/projects/${projectId}/sub-contractors/edit/${contractorDetails?.id}`,
                            }}
                            className="update-bank-text"
                          >
                            Update bank details
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                  {/* <div className="payment-schedule-header-item">
                    <p>
                      Business Name
                    </p>
                    <div>{contractBusiness ? contractBusiness : "-"}</div>
                  </div> */}
                  <div className="payment-schedule-header-item">
                    <p>
                      {/* <span className="required">*</span>ABN */}
                      ABN
                    </p>
                    {/* <ReactInputMask
                      type="text"
                      name="abn"
                      value={claimABN ? claimABN : ""}
                      onChange={(e) => {
                        setClaimABN(e)
                      }}
                      placeholder="Enter ABN"
                      mask="99 999 999 999"
                      maskChar={null}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                      disabled={true}
                    /> */}
                    <div>{claimABN ? formatABN(claimABN) : "-"}</div>
                  </div>
                  <div className="payment-schedule-header-item">
                    <p>
                      <span className="required">*</span>Claim/Invoice Number
                    </p>
                    {/* <NumberFormat */}
                    <input
                      type="text"
                      name={`invoiceNumber`}
                      value={invoiceNumber ? invoiceNumber : ""}
                      // onChange={(e) => {
                      //   setInvoiceNumber(e?.target?.value);
                      //   setEntryData(
                      //     entryData?.map((i) => {
                      //       return {
                      //         scheduled_date: i.scheduled_date,
                      //         description: i.description
                      //           ? i.description?.split("#")[0] +
                      //             "#" +
                      //             e?.target?.value
                      //           : "",
                      //         claimed_amount: i.claimed_amount,
                      //         schedule_amount: i.schedule_amount,
                      //         amount_withheld: i.amount_withheld,
                      //         reason: i.reason
                      //           ? i.reason?.split("#")[0] +
                      //             "#" +
                      //             e?.target?.value
                      //           : "",
                      //       };
                      //     })
                      //   );
                      // }}
                      onChange={(e) => {
                        setInvoiceNumber(e?.target?.value);
                      }}
                      onBlur={(e) => {
                        let regex = /[^a-zA-Z0-9]/g;
                        if (regex.test(e?.target?.value)) {
                          toast.error(
                            "Spaces and Special Characters are not allowed"
                          );
                          setInvoiceNumber();
                        } else {
                          setInvoiceNumber(e?.target?.value);
                          setEntryData(
                            entryData?.map((i) => {
                              return {
                                scheduled_date: i.scheduled_date,
                                description: i.description
                                  ? i.description?.split("#")[0] +
                                    "#" +
                                    e?.target?.value
                                  : "",
                                claimed_amount: i.claimed_amount,
                                schedule_amount: i.schedule_amount,
                                amount_withheld: i.amount_withheld,
                                reason: i.reason
                                  ? i.reason?.split("#")[0] +
                                    "#" +
                                    e?.target?.value
                                  : "",
                              };
                            })
                          );
                        }
                      }}
                      placeholder="Enter Number"
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="payment-schedule-header-item">
                    <p>
                      <span className="required">*</span>Claim/Invoice Date
                    </p>
                    {/* <DatePicker
                      name="transaction_date"
                      value={invoiceDate ? invoiceDate : ""}
                      onChange={(e) => {
                        setInvoiceDate(e);
                        let date = moment(e)?._d;
                        setPreviousDate(date.setDate(date.getDate() - 1));
                      }}
                      disabled={[[tomorrow, false]]}
                    /> */}
                    <DatePicker
                      cleanable={false}
                      onChange={(e) => {
                        setInvoiceDate(moment(e, "DD-MM-YYYY"));
                        // let date = moment(e)?._d;
                        // setPreviousDate(date);
                      }}
                      format="dd-MM-yyyy"
                      value={
                        invoiceDate
                          ? String(invoiceDate)?.includes("-")
                            ? new Date(
                                String(invoiceDate)?.split("-")[2],
                                String(invoiceDate)?.split("-")[1] - 1,
                                String(invoiceDate)?.split("-")[0]
                              )
                            : new Date(invoiceDate)
                          : null
                      }
                      className="rs-date-body"
                      placeholder="Select Date"
                      placement="autoVerticalStart"
                      disabledDate={(date) => moment(date).isAfter(moment())}
                    />
                  </div>
                  <div className="payment-schedule-header-item">
                    <p>
                      <span className="required">*</span>Due Date
                    </p>
                    {/* <DatePicker
                      name="due_date"
                      value={dueDate ? dueDate : ""}
                      disabled={[[false, previousdate]]}
                      onChange={(e) => {
                        setDueDate(e);
                      }}
                    /> */}
                    <DatePicker
                      cleanable={false}
                      onChange={(e) => {
                        setDueDate(moment(e, "DD-MM-YYYY"));
                      }}
                      format="dd-MM-yyyy"
                      value={
                        dueDate
                          ? String(dueDate)?.includes("-")
                            ? new Date(
                                String(dueDate)?.split("-")[2],
                                String(dueDate)?.split("-")[1] - 1,
                                String(dueDate)?.split("-")[0]
                              )
                            : new Date(dueDate)
                          : null
                      }
                      className="rs-date-body"
                      placeholder="Select Date"
                      placement="autoVerticalStart"
                      disabledDate={(date) =>
                        moment(date).isBefore(moment(invoiceDate, "DD-MM-YYYY"))
                      }
                    />
                  </div>
                  <div className="payment-schedule-header-item">
                    <p>
                      <span className="required">*</span>Claimed Amount (Inc.
                      GST)
                    </p>
                    <NumberFormat
                      type="text"
                      name="claimed_amount"
                      value={claimedAmount ? claimedAmount : ""}
                      onChange={(e) => {
                        setClaimedAmount(e.target.value);
                      }}
                      placeholder="Enter Amount"
                      decimalScale={2}
                      allowNegative={false}
                      thousandSeparator={true}
                      isAllowed={(values) => MaxLimit(values)}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                    />
                  </div>
                  {/* <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Approved Date
                        </p>
                        <DatePicker
                          name="approved_date"
                          value={approvedDate ? approvedDate : ""}
                          disabled={[[false, previousdate]]}
                          onChange={(e) => {
                            if (invoiceDate && dueDate) {
                              if (invoiceDate <= e && e <= dueDate) {
                                setApprovedDate(e);
                              } else {
                                toast.error(
                                  "Selected approved date cannot be greater then due date."
                                );
                                setApprovedDate(invoiceDate);
                              }
                            } else {
                              setApprovedDate(e);
                              setDueDate(e);
                            }
                          }}
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Approved Amount
                        </p>
                        <NumberFormat
                          type="text"
                          name="approved_amount"
                          value={approvedAmount ? approvedAmount : ""}
                          onChange={(e) => {
                            setApprovedAmount(e.target.value);
                          }}
                          onBlur={(e) => {
                            if (
                              Number(`${e.target.value}`?.replace(/,/g, "")) <=
                              Number(`${claimedAmount}`?.replace(/,/g, ""))
                            ) {
                              setApprovedAmount(e.target.value);
                            } else {
                              setApprovedAmount();
                              toast.error(
                                "Approved amount should not be greater than claimed amount."
                              );
                            }
                          }}
                          placeholder="Enter Amount"
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          isAllowed={(values) => MaxLimit(values)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span> Difference Reason
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Difference Reason"
                          name={`difference_reason`}
                          value={approvedReason ? approvedReason : ""}
                          onChange={(e) => {
                            setApprovedReason(e?.target?.value);
                          }}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div> */}
                  {!edit && (
                    <div className="payment-schedule-header-item">
                      <p>
                        <span className="required">*</span>Received Date
                      </p>
                      {/* <DatePicker
                        name="transaction_date"
                        value={receiveDate ? receiveDate : ""}
                        onChange={(e) => {
                          if (e <= dueDate) {
                            setReceiveDate(e);
                            checkBusinessDays(e, holidayList);
                          } else {
                            toast.error(
                              "Received Date should not greater than due date"
                            );
                            setReceiveDate();
                          }
                        }}
                        disabled={[[tomorrow, false]]}
                      /> */}
                      <DatePicker
                        cleanable={false}
                        name="transaction_date"
                        onChange={(e) => {
                          if (e <= dueDate) {
                            setReceiveDate(moment(e, "DD-MM-YYYY"));
                            checkBusinessDays(e, holidayList);
                          } else {
                            toast.error(
                              "Received Date should not greater than due date"
                            );
                            setReceiveDate("");
                          }
                        }}
                        format="dd-MM-yyyy"
                        value={
                          receiveDate
                            ? String(receiveDate)?.includes("-")
                              ? new Date(
                                  String(receiveDate)?.split("-")[2],
                                  String(receiveDate)?.split("-")[1] - 1,
                                  String(receiveDate)?.split("-")[0]
                                )
                              : new Date(receiveDate)
                            : null
                        }
                        className="rs-date-body"
                        placeholder="Select Date"
                        placement="autoVerticalStart"
                        disabledDate={(date) => moment(date).isAfter(moment())}
                      />
                    </div>
                  )}
                  <div className="payment-schedule-header-item">
                    <p>Today's Date</p>
                    <span>{moment(new Date()).format("DD-MM-YYYY")}</span>
                  </div>
                </div>
              </Form>
            </span>
            {/* <span className="journal-entry-status">
              <div className="journal-entry">
                <p>
                  Claimed Amount
                  <i>{totalClaimedAmount ? totalClaimedAmount : 0.0}</i>
                </p>
                <p>
                  Payment Amount<i>{totalScheduleAmount}</i>
                </p>
                <p>
                  Amount withheld<i>{totalAmountWithheld}</i>
                </p>
                {contractorData && (
                  <>
                    {" "}
                    <p>
                      Retention Amount
                      <i>
                        {contractorData?.retention_amount
                          ? formatNumber(
                              Number(contractorData?.retention_amount)
                            )
                          : "0.00"}
                      </i>
                    </p>{" "}
                    <p>
                      Balance in RTA
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Before:{" "}
                        <i>
                          {contractorData?.closing_balance_rta
                            ? formatNumber(
                                Number(contractorData?.closing_balance_rta)
                              )
                            : "0.00"}
                        </i>
                      </div>
                    </p>{" "}
                    <p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginTop: "25px",
                        }}
                      >
                        After:{" "}
                        <i>
                          {formatNumber(
                            Number(
                              contractorData?.closing_balance_rta
                                ? contractorData?.closing_balance_rta
                                : 0
                            ) +
                              Number(`${totalAmountWithheld}`.replace(/,/g, ""))
                          )}
                        </i>
                      </div>
                    </p>{" "}
                  </>
                )}
              </div>
            </span> */}
            {/* <div className="table table-bordered journal-table">
              <div
                className="react-bootstrap-table journal-entries-table"
                style={{
                  overflowX: "auto",
                  scrollbarWidth: "thin",
                  scrollBehavior: "auto",
                }}
              >
                <table className="table-content">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{
                          borderRightColor: "#c1bbbb",
                        }}
                      >
                        <span className="required">*</span>Scheduled Date
                      </th>
                      <th scope="col">
                        Scheduled Amount to be paid
                      </th>
                      <th
                        scope="col"
                        style={{
                          borderRightColor: "#c1bbbb",
                        }}
                      >
                        Description
                      </th>
                      <th scope="col">Amount Withheld in RTA</th>
                      <th scope="col">Reason for Withholding</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entryData &&
                      entryData?.length > 0 &&
                      entryData?.map((item, index) => {
                        return (
                          <tr key={index} className="payment-schedule-add-row">
                            <td
                              style={{ width: "4%" }}
                              className="payment-scheduled-date-body"
                            >
                              <DatePicker
                                name="scheduled_date"
                                value={
                                  item?.scheduled_date
                                    ? item?.scheduled_date
                                    : ""
                                }
                                onChange={(e) => {
                                  handleScheduledDateChange(
                                    entryData,
                                    index,
                                    e
                                  );
                                }}
                                disabled={[[false, previousdate]]}
                              />
                              <span className="text-danger">
                                {errors[index]?.scheduled_date
                                  ? errors[index]?.scheduled_date
                                  : ""}
                              </span>
                            </td>

                            <td style={{ width: "12%" }}>
                              <NumberFormat
                                type="text"
                                name="schedule_amount"
                                value={
                                  item?.schedule_amount
                                    ? item?.schedule_amount
                                    : ""
                                }
                                onChange={(e) => {
                                  handleScheduleAmountChange(
                                    entryData,
                                    index,
                                    e
                                  );
                                }}
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={true}
                                isAllowed={(values) => MaxLimit(values)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />
                              <span className="text-danger">
                                {errors[index]?.schedule_amount
                                  ? errors[index]?.schedule_amount
                                  : ""}
                              </span>
                            </td>

                            <td style={{ width: "16%" }}>
                              <textarea
                                type="text"
                                name={`description`}
                                value={
                                  item?.description ? item?.description : ""
                                }
                                onChange={(e) => {
                                  handleDescriptionChange(entryData, index, e);
                                }}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />
                            </td>

                            <td style={{ width: "10%" }}>
                              <NumberFormat
                                type="text"
                                name="amount_withheld"
                                value={
                                  item?.amount_withheld
                                    ? item?.amount_withheld
                                    : ""
                                }
                                onChange={(e) => {
                                  handleAmountWithheldChange(
                                    entryData,
                                    index,
                                    e
                                  );
                                }}
                                decimalScale={2}
                                allowNegative={false}
                                thousandSeparator={true}
                                isAllowed={(values) => MaxLimit(values)}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />
                              <span className="text-danger">
                                {errors[index]?.amount_withheld
                                  ? errors[index]?.amount_withheld
                                  : ""}
                              </span>
                            </td>

                            <td style={{ width: "10%" }}>
                              <textarea
                                type="text"
                                name={`reason`}
                                value={item?.reason ? item?.reason : ""}
                                disabled={item?.amount_withheld ? false : true}
                                onChange={(e) => {
                                  handleReasonChange(entryData, index, e);
                                }}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />
                              <span className="text-danger">
                                {errors[index]?.reason
                                  ? errors[index]?.reason
                                  : ""}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={handleConfirmModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            {showErrorReceivedDate ? "Warning" : edit ? "Edit Payment Claim" : "New Payment Claim"}
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <p>
                {showErrorReceivedDate
                  ? errors?.message
                  : edit
                  ? `Would you like to edit the payment claim amount?`
                  : // : `Have you verified the accuracy of payment claim information?`}
                    `Did you verify the claims data and would like to post it to GL?`}
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleConfirmModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleSubmitPost(finalSubminData);
                }}
              >
                Post
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* {showErrorReceivedDate && (
        <Modal
          size="lg"
          show={showErrorReceivedDate}
          onHide={toggleErrorReceviedDate}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Warning</Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div
              className="content-details"
              style={{ paddingBottom: "10px", paddingTop: "10px" }}
            >
              <p>{errors?.message}</p>
            </div>
            <div className="modal-footer">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  toggleErrorReceviedDate();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )} */}
    </>
  );
};

export default AddPaymnetClaim;
