import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageDefaultPrefixPath, ProjectStatus } from "../../../../config";
import {
  formatABN,
  formatAccountNumber,
  formatBSBNumber,
  formatedAddress,
  formatMobile,
  formatNumber,
  formatPhone,
} from "../../../common/Misc";
import Spin from "../../../common/Spin";
import { getProjectPopupDetail } from "./ProjectsHelper";

const ProjectDetailsPopup = ({ projectId, toggleShowModal, showView }) => {
  const [projectDetail, setProjectDetail] = useState(null);
  const [projectLoading, setProjectLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const projectDetailData = await getProjectPopupDetail(projectId, true);
      setProjectDetail(projectDetailData);
      setProjectLoading(false);
    }
    fetchData();
  }, [projectId, setProjectDetail]);

  return (
    <Modal
      size="lg"
      show={showView}
      onHide={toggleShowModal}
      dialogClassName="modal-50w larg-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Project Details
      </Modal.Header>
      <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
        <div style={{ height: "90vh", overflow: "auto" }}>
          {projectLoading ? (
            <Spin />
          ) : (
            <>
              <div className="modal-content-details justify-center">
                <div className="profile full">
                  {projectDetail?.logo === null ||
                  projectDetail?.logo === "" ? (
                    <span>{projectDetail?.project_name?.charAt(0)}</span>
                  ) : (
                    <img
                      src={`${imageDefaultPrefixPath}${projectDetail?.logo}`}
                      alt=""
                    />
                  )}

                  <h5>
                    {projectDetail?.project_name
                      ? projectDetail?.project_name
                      : ""}
                  </h5>
                </div>
              </div>
              <div className="modal-content">
                <h3>Personal Details</h3>
                <div className="modal-content-details">
                  <p>
                    <strong>Project Name:</strong> {projectDetail?.project_name}
                  </p>
                  <p>
                    <strong>Email Address:</strong>{" "}
                    {projectDetail?.email ? projectDetail?.email : "-"}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {projectDetail?.phone
                      ? formatPhone(projectDetail?.phone)
                      : "-"}
                  </p>
                  <p>
                    <strong>Mobile Number:</strong>{" "}
                    {projectDetail?.mobile
                      ? formatMobile(projectDetail?.mobile)
                      : "-"}
                  </p>
                  <p>
                    <strong>ABN:</strong>{" "}
                    {projectDetail?.abn ? formatABN(projectDetail?.abn) : "-"}
                  </p>
                  <p>
                    <strong>Address:</strong>{" "}
                    {formatedAddress(
                      projectDetail?.street,
                      projectDetail?.suburb?.name,
                      projectDetail?.state?.name,
                      projectDetail?.postal_code
                    )}
                  </p>
                </div>
              </div>
              <div className="modal-content">
                <h3>Project Trust Bank Account Details</h3>
                <div className="modal-content-details">
                  <p>
                    <strong>Bank Name:</strong>{" "}
                    {projectDetail?.pta_contractor?.bank
                      ? projectDetail?.pta_contractor?.bank?.abbreviation +
                        "-" +
                        projectDetail?.pta_contractor?.bank?.name
                      : "-"}
                  </p>
                  <p>
                    <strong>Bank Account Name:</strong>{" "}
                    {projectDetail?.pta_contractor?.account_name
                      ? projectDetail?.pta_contractor?.account_name
                      : "-"}
                  </p>
                  <p>
                    <strong>BSB:</strong>{" "}
                    {projectDetail?.pta_contractor?.bsb
                      ? formatBSBNumber(projectDetail?.pta_contractor?.bsb)
                      : "-"}
                  </p>
                  <p>
                    <strong>Account Number:</strong>{" "}
                    {projectDetail?.pta_contractor?.account_number
                      ? formatAccountNumber(
                          projectDetail?.pta_contractor?.account_number
                        )
                      : "-"}
                  </p>
                  <p>
                    <strong>Opening Balance:</strong>{" "}
                    {projectDetail?.pta_contractor?.opening_balance
                      ? "$" +
                        formatNumber(
                          Number(projectDetail?.pta_contractor?.opening_balance)
                        )
                      : "$0.00"}
                  </p>
                </div>
              </div>
              <div className="modal-content">
                <h3>Retention Trust Bank Account Details</h3>
                <div className="modal-content-details">
                  <p>
                    <strong>Bank Name:</strong>{" "}
                    {projectDetail?.rta_project_id
                      ? projectDetail?.rta_project?.rta_contractor?.bank
                        ? projectDetail?.rta_project?.rta_contractor?.bank
                            ?.abbreviation +
                          "-" +
                          projectDetail?.rta_project?.rta_contractor?.bank?.name
                        : "-"
                      : projectDetail?.rta_contractor?.bank
                      ? projectDetail?.rta_contractor?.bank?.abbreviation +
                        "-" +
                        projectDetail?.rta_contractor?.bank?.name
                      : "-"}
                  </p>
                  <p>
                    <strong>Bank Account Name:</strong>{" "}
                    {projectDetail?.rta_project_id
                      ? projectDetail?.rta_project?.rta_contractor?.account_name
                        ? projectDetail?.rta_project?.rta_contractor
                            ?.account_name
                        : "-"
                      : projectDetail?.rta_contractor?.account_name
                      ? projectDetail?.rta_contractor?.account_name
                      : "-"}
                  </p>
                  <p>
                    <strong>BSB:</strong>{" "}
                    {projectDetail?.rta_project_id
                      ? projectDetail?.rta_project?.rta_contractor?.bsb
                        ? formatBSBNumber(
                            projectDetail?.rta_project?.rta_contractor?.bsb
                          )
                        : "-"
                      : projectDetail?.rta_contractor?.bsb
                      ? formatBSBNumber(projectDetail?.rta_contractor?.bsb)
                      : "-"}
                  </p>
                  <p>
                    <strong>Account Number:</strong>{" "}
                    {projectDetail?.rta_project_id
                      ? projectDetail?.rta_project?.rta_contractor
                          ?.account_number
                        ? formatAccountNumber(
                            projectDetail?.rta_project?.rta_contractor
                              ?.account_number
                          )
                        : "-"
                      : projectDetail?.rta_contractor?.account_number
                      ? formatAccountNumber(
                          projectDetail?.rta_contractor?.account_number
                        )
                      : "-"}
                  </p>
                  <p>
                    <strong>Opening Balance:</strong>{" "}
                    {projectDetail?.rta_project_id
                      ? projectDetail?.rta_project?.rta_contractor
                          ?.opening_balance
                        ? "$" +
                          formatNumber(
                            projectDetail?.rta_project?.rta_contractor
                              ?.opening_balance
                          )
                        : "$0.00"
                      : projectDetail?.rta_contractor?.opening_balance
                      ? "$" +
                        formatNumber(
                          projectDetail?.rta_contractor?.opening_balance
                        )
                      : "$0.00"}
                  </p>
                </div>
              </div>
              <div className="modal-content">
                <h3>Key Metrics</h3>
                <div className="modal-content-details">
                  <p>
                    <strong>Project Start Date:</strong>{" "}
                    {projectDetail?.start_date
                      ? projectDetail?.start_date
                      : "-"}
                  </p>
                  <p>
                    <strong>Project End Date:</strong>{" "}
                    {projectDetail?.end_date ? projectDetail?.end_date : "-"}
                  </p>
                  <p>
                    <strong>Estimated Project Cost:</strong>{" "}
                    {projectDetail?.estimated_cost
                      ? "$" + formatNumber(projectDetail?.estimated_cost)
                      : "$0.00"}
                  </p>
                  <p>
                    <strong>Project Status:</strong>{" "}
                    {projectDetail?.project_status
                      ? ProjectStatus?.find(
                          (item) =>
                            item?.value ===
                            Number(projectDetail?.project_status)
                        )?.label
                      : "-"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProjectDetailsPopup;
