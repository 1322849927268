/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
// import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import DocumentListActionButton from "./DocumentListActionButton";
import axios from "axios";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { debounce } from "lodash";
import { API, defaultLimitPagination, get, imageDefaultPrefixPath } from "../../../../../config";
import Pagination from "../../../../common/Pagination";
import Spin from "../../../../common/Spin";
// import { formatPhone } from "../../../../common/Misc";
// import ProjectActionButton from "./components/ProjectActionButton";
// import AddProject from "./AddProject";

const DocumentRevision = ({ id }) => {
  const [revisionLoading, setRevisionLoading] = useState(false);
  const [revisionList, setRevisionList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  // eslint-disable-next-line no-unused-vars
  const [searchParam, setSearchParam] = useState("");

  const handleDelete = () => {
    documentRevisionList();
  };

  const defaultOption = [
    { label: "Copies Of Contracts", value: 1 },
    { label: "Any Contract Variation/Amendment Documents", value: 2 },
    { label: "Payment Claims/ Schedules Given And Received", value: 3 },
    { label: "Supporting Documents", value: 4 },
    { label: "Others", value: 5 },
  ];

  const documentRevisionList = useCallback(async () => {
    try {
      setRevisionLoading(true);
      const { data } = await get(
        `${API.GET_DOCUMENT_LIST}/${id ? id : ""}?limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setRevisionLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const projectList = data.data;
      setRevisionList(projectList);
      return data.data;
    } catch (error) {
      setRevisionLoading(false);
      setRevisionList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [id, limit, page, searchParam, sortOrder, sortField]);

  // const projectModal = () => {
  //   documentRevisionList();
  // };

  const DocumentRevisionGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        builder_project_id: revisionList[i]?.builder_project_id,
        created_at: revisionList[i]?.created_at,
        deleted_at: revisionList[i]?.deleted_at,

        document: revisionList[i]?.document ? revisionList[i]?.document : "-",

        document_type: defaultOption
          ? defaultOption.map((item) => {
              if (item.value == Number(revisionList[i]?.document_type)) {
                return item?.label;
              } else {
                return "";
              }
            })
          : "",

        id: revisionList[i]?.id ? revisionList[i]?.id : "0",
        revision: revisionList[i]?.revision ? revisionList[i]?.revision : "0",
        updated_at: revisionList[i]?.updated_at
          ? revisionList[i]?.updated_at
          : "-",
        action: (
          <DocumentListActionButton
            id={revisionList[i]?.id}
            handleDownload={() => handleDownload(revisionList[i]?.document)}
            handleView={() => handleView(revisionList[i]?.document)}
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };

  const handleDownload = async (value) => {
    try {
      const response = await axios.get(`${imageDefaultPrefixPath}${value}`, {
        responseType: "blob",
      });
      const fileData = response.data;
      const url = window.URL.createObjectURL(new Blob([fileData]));

      var a = document.createElement("a");
      a.href = url;
      var extention = value.split(".");
      var file = value.split("/");
      a.setAttribute(
        "download",
        file[file.length - 1] || "document" + extention
      );
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      toast.error("File does not exist.");
    }
  };
  const handleView = async (value) => {
    window.open(`${imageDefaultPrefixPath}${value}`, "_blank", "noreferrer");
  };

  const revision = DocumentRevisionGenerator(revisionList?.length);

  const RevisionColumns = [
    {
      dataField: "document_type",
      text: "Document Types",
      sort: true,
    },
    {
      dataField: "document",
      text: "Document",
      sort: true,
    },
    {
      dataField: "updated_at",
      text: "Date",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    documentRevisionList();
  }, [documentRevisionList]);

  //   const onSearchChange = (e) => {
  //     const value = e.target.value;
  //     setSearchParam(value);
  //     setPage(1);
  //   };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="content-details">
        {/* <div className="table-top-btn">
          <div className="search-section">
            <Form>
              <FormControl
                onChange={(e) => debounce(onSearchChange(e), 300)}
                type="text"
                value={searchParam}
                placeholder="Search Project"
              />
              <Button className="fa fa-search">Search</Button>
            </Form>
          </div>
        </div> */}
        <div className="custom-table">
          {revisionLoading && <Spin />}
          <BootstrapTable
            keyField="project_id"
            remote
            data={revision}
            columns={RevisionColumns}
            onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};
export default DocumentRevision;
