import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  API,
  defaultLimitPagination,
  fileUpload,
  get,
  imageDefaultPrefixPath,
} from "../../../config";
import Pagination from "../../common/Pagination";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import SubContractorActionButton from "./components/SubContractorActionButton";
import Spin from "../../common/Spin";
import BackButton from "../../../components/Form/BackButton";
import {
  formatABN,
  formatMobile,
  formatName,
  formatPhone,
} from "../../common/Misc";
import UploadFile from "../../common/UploadFile";
import * as XLSX from "xlsx";
import cellEditFactory from "react-bootstrap-table2-editor";
import {
  fetchAccountConnectionData,
  useProcoreCompanyList,
} from "../settings/integrations/components/IntegrationsHelper";
import proCoreLogo from "../../../assets/images/procore.svg";
import { ErrorMessage, Formik } from "formik";
import { AddProcoreImportSchema } from "../../common/Validation";
import ReactSelect from "react-select";
import ProcoreProjectSelect from "../../common/ProcoreProjectSelect";
import { useProjectBar } from "../../common/useProjectBar";
import ExpandSubContractorDetails from "./components/ExpandSubContractorDetails";
import XeroLogo from "../../../assets/images/xero-logo.png";
import SyncContractor from "./components/SyncContractor";
import CustomTooltip from "../../common/CustomTooltip";

const SubContractors = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [contractorsList, setContractorsList] = useState([]);
  const [contractorLoading, setContractorLoading] = useState(false);
  const [fetchContractorLoading, setFetchContractorLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const params = useParams();
  const projectId = params?.project_id;
  // const projectName = localStorage.getItem("projectName");
  // const projectLogo = localStorage.getItem("projectLogo");

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelContactData, setExcelContactData] = useState();

  const [procoreData, setProcoreData] = useState(null);
  const [showProcoreModal, setShowProcoreModal] = useState(false);

  const [xeroData, setXeroData] = useState(null);
  const [isImportFromXero, setIsImportFromXero] = useState(false);
  const [isTenantData, setIsTenantData] = useState(false);
  const [XPMLoading, setXPMLoading] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenantId, setTenantId] = useState();
  const xeroCode = query.get("code");

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [notSelectedRowIds, setNotSelectedRowIds] = useState([]);

  const [loading, setLoading] = useState(false);
  const [syncOptionModal, setSyncOptionModal] = useState(false);
  const [tentantLoading, setTentantLoading] = useState(false);
  const [syncOptionData, setSyncOptionData] = useState([]);
  const [syncOptionId, setSyncOptionId] = useState();
  const [contactId, setContactId] = useState();

  const { ProjectName, ProjectLogo, ProjectReadOnly, ProjectData } =
    useProjectBar();
  const builderId = localStorage.getItem("builderId")
    ? localStorage.getItem("builderId")
    : ProjectData?.builder_id
    ? ProjectData?.builder_id
    : "";

  const handleDelete = () => {
    fetchContractors();
  };

  const handleXeroModal = () => {
    setIsImportFromXero(!isImportFromXero);
  };

  const handlexero = async () => {
    try {
      setContractorLoading(true);
      // const formData = new FormData();
      // if (builderId) {
      //   formData.append("builder_id", builderId);
      // }
      // formData.append("builder_id", builderId);
      // const response = await fileUpload(`${API.XERO_REFRESH}`, formData);
      setContractorLoading(false);
      // if (response?.data?.status === 200) {
      try {
        setContractorLoading(true);
        const response = await get(
          `${API.XERO_TENANTS}${builderId && "?builder_id=" + builderId}`
        );
        setContractorLoading(false);
        if (response?.data?.status === 200) {
          toast.success(response?.data?.message);
          setIsTenantData(true);
          let tenantList = response?.data?.data?.map((item) => {
            const { tenantId: value, ...rest } = item;
            return {
              label: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {item?.tenantName}{" "}
                  {/* <span
                      style={
                        item?.is_active
                          ? {
                              background: "#0DA500",
                              color: "white",
                              marginLeft: "auto",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }
                          : {
                              background: "#F00",
                              color: "white",
                              marginLeft: "auto",
                              padding: "4px 6px",
                              borderRadius: "5px",
                              marginRight: "5px",
                            }
                      }
                    >
                      {item?.is_active ? "Active" : "InActive"}
                    </span> */}
                </div>
              ),
              value,
              ...rest,
            };
          });
          setTenantData(tenantList);
        }
      } catch (error) {
        setContractorLoading(false);
        handleXeroModal();
        toast.error(error?.message);
        toast.error(
          error.errors && error.errors.myna_error && error.errors.myna_error[0]
        );
      }
      // }
    } catch (e) {
      setContractorLoading(false);
      try {
        setContractorLoading(true);
        // const formData = new FormData();
        // if (builderId) {
        //   formData.append("builder_id", builderId);
        // }
        // formData.append("builder_id", builderId);
        // const response = await fileUpload(`${API.XERO_REFRESH}`, formData);
        setContractorLoading(false);
        // if (response?.data?.status === 200) {
        try {
          setContractorLoading(true);
          const response = await get(
            `${API.XERO_TENANTS}${builderId && "?builder_id=" + builderId}`
          );
          setContractorLoading(false);
          if (response?.data?.status === 200) {
            setIsTenantData(true);
            let tenantList = response?.data?.data?.map((item) => {
              const { tenantId: value, ...rest } = item;
              return {
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {item?.tenantName}{" "}
                    {/* <span
                        style={
                          item?.is_active
                            ? {
                                background: "#0DA500",
                                color: "white",
                                marginLeft: "auto",
                                padding: "4px 6px",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }
                            : {
                                background: "#F00",
                                color: "white",
                                marginLeft: "auto",
                                padding: "4px 6px",
                                borderRadius: "5px",
                                marginRight: "5px",
                              }
                        }
                      >
                        {item?.is_active ? "Active" : "InActive"}
                      </span> */}
                  </div>
                ),
                value,
                ...rest,
              };
            });
            setTenantData(tenantList);
          }
        } catch (error) {
          setContractorLoading(false);
          handleXeroModal();
          // const errors = error.response?.data?.errors;
          // Object.keys(errors).forEach((key) => {
          //   toast.error(errors[key][0]);
          // });
          toast.error(error?.message);
          toast.error(
            error.errors &&
              error.errors.myna_error &&
              error.errors.myna_error[0]
          );
        }
        // }
      } catch (error) {
        setContractorLoading(false);
        handleXeroModal();
        toast.error("Something went wrong");
        // const errors = error.response?.data?.errors;
        // Object.keys(errors).forEach((key) => {
        //   toast.error(errors[key][0]);
        // });
      }
    }
    // window.open(xeroURl, '_self');
  };

  const handleSubmiTenantData = async (id) => {
    if (id) {
      try {
        setContractorLoading(true);
        setXPMLoading(true);
        const formData = new FormData();
        formData.append("xero_tenant_id", id ? id?.value : "");
        formData.append("builder_id", builderId ? builderId : "");
        formData.append("builder_project_id", projectId ? projectId : "");
        const xeroClients = await fileUpload(`${API.XERO_CONTACTS}`, formData);
        setContractorLoading(false);
        setXPMLoading(false);
        if (xeroClients?.data?.status === 200) {
          toast.success(
            <>
              <p>{xeroClients?.data?.message}</p>
              <p>{xeroClients?.data?.data?.message}</p>
            </>
          );
          handleTenantModal();
          handleXeroModal();
          handleImportClose();
          fetchContractors();
        }
      } catch (e) {
        setContractorLoading(false);
        handleTenantModal();
        handleXeroModal();
        handleImportClose();
        setXPMLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
        // toast.error(e?.message);
        // toast.error(e.errors && e.errors.myna_error && e.errors.myna_error[0]);
      }
    }
  };

  const handleTenantModal = () => {
    setTenantId();
    setIsTenantData(!isTenantData);
  };

  useEffect(() => {
    async function fetchData() {
      if (xeroCode) {
        try {
          const formData = new FormData();
          formData.append("code", xeroCode ? xeroCode : "");
          formData.append("builder_id", builderId ? builderId : "");
          const { data } = await fileUpload(
            `${API.XERO_OAUTHREDIRECT}`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          if (data?.status === 200) {
            navigate(`${API.GET_CUSTOMERLIST}?search=&page=1`, {
              replace: true,
            });
            try {
              const formData = new FormData();
              if (builderId) {
                formData.append("builder_id", builderId);
              }
              formData.append("builder_id", builderId);
              const response = await fileUpload(
                `${API.XERO_REFRESH}`,
                formData
              );
              if (response?.data?.status === 200) {
                return null;
              }
            } catch (e) {
              try {
                const formData = new FormData();
                if (builderId) {
                  formData.append("builder_id", builderId);
                }
                formData.append("builder_id", builderId);
                const response = await fileUpload(
                  `${API.XERO_REFRESH}`,
                  formData
                );
                if (response?.data?.status === 200) {
                  return null;
                }
              } catch (error) {
                toast.error("Something went wrong");
                // const errors = error.response?.data?.errors;
                // Object.keys(errors).forEach((key) => {
                //   toast.error(errors[key][0]);
                // });
              }
            }
          }
        } catch (e) {
          const { data } = e.response;
          if (data) {
            toast.error(data?.message);
            toast.error(
              data.errors && data.errors.myna_error && data.errors.myna_error[0]
            );
          }
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderId, xeroCode]);

  const fetchContractors = useCallback(async () => {
    if (projectId) {
      try {
        setFetchContractorLoading(true);
        const { data } = await get(
          `${API.GET_CONTRACTORS}?limit=${
            limit ? limit : defaultLimitPagination
          }&page=${page ? page : 1}&search=${
            searchParam ? searchParam : ""
          }&sort_column=${sortField ? sortField : ""}&sort_order=${
            sortOrder ? sortOrder : ""
          }&builder_project_id=${projectId ? projectId : ""}`
        );

        setFetchContractorLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        const contractorsList = data.data.data;
        let notSelectedIds = [];
        for (let i = 0; i < contractorsList?.length; i++) {
          notSelectedIds.push(contractorsList[i]?.id);
        }
        setNotSelectedRowIds(notSelectedIds);
        setContractorsList(contractorsList);
        return data.data;
      } catch (error) {
        setFetchContractorLoading(false);
        setContractorsList([]);

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField, projectId]);

  const xeroConnection = async () => {
    setContractorLoading(true);
    const response = await fetchAccountConnectionData(builderId);
    setContractorLoading(false);
    if (response?.xero_integration_data?.connection_status === 1) {
      setXeroData(response?.xero_integration_data);
    } else {
      // toast.warn("Please connect the xero account");
      setXeroData();
      return;
    }
  };

  useEffect(() => {
    xeroConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSingleSyncOptionModal = async (id) => {
    setContactId(id);
    if (!syncOptionModal) {
      setContractorLoading(true);
      const response = await fetchAccountConnectionData(builderId);
      setContractorLoading(false);
      if (response?.xero_integration_data?.connection_status === 1) {
        setXeroData(response?.xero_integration_data);
        fetchSyncOptions();
      } else {
        toast.warn("Please connect the xero account");
        return;
      }
    }
    setSyncOptionModal(!syncOptionModal);
  };

  const contractorsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: contractorsList[i]?.id,
        expandData: contractorsList[i],
        name: formatName(
          contractorsList[i]?.first_name,
          contractorsList[i]?.last_name
        ),
        email: contractorsList[i]?.email,
        abn: contractorsList[i]?.abn ? formatABN(contractorsList[i]?.abn) : "-",
        phone: contractorsList[i]?.phone
          ? formatPhone(contractorsList[i]?.phone)
          : "-",
        mobile: contractorsList[i]?.mobile
          ? formatMobile(contractorsList[i]?.mobile)
          : "-",
        business_name: contractorsList[i]?.business_name
          ? contractorsList[i]?.business_name
          : "-",
        contact_type: contractorsList[i]?.contact_type?.name
          ? contractorsList[i]?.contact_type?.name
          : "-",
        // entity_type: contractorsList[i]?.entity_type
        //   ? contractorsList[i]?.entity_type
        //   : "-",
        source: contractorsList[i]?.import_from
          ? contractorsList[i]?.import_from
          : "Manual",
        action: (
          <SubContractorActionButton
            id={contractorsList[i]?.id}
            handleDelete={handleDelete}
            ProjectReadOnly={ProjectReadOnly}
            toggleSingleSyncOptionModal={toggleSingleSyncOptionModal}
            xeroConnection={xeroData?.connection_status === 1 ? true : false}
          />
        ),
      });
    }
    return items;
  };
  const contractors = contractorsGenerator(contractorsList?.length);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "abn",
      text: "ABN",
      // sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
      sort: true,
    },
    {
      dataField: "contact_type",
      text: "Contractor Type",
    },
    {
      dataField: "source",
      text: "Source",
    },
    // {
    //   dataField: "entity_type",
    //   text: "Entity Type",
    // },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <>
        <ExpandSubContractorDetails
          id={row?.key}
          projectId={projectId}
          expandData={row?.expandData}
        />
      </>
    ),
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: true,

    expandColumnRenderer: ({ expanded, rowKey }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    navigate(
      `?limit=${limit ? limit : defaultLimitPagination}&page=${
        page ? page : 1
      }&search=${searchParam ? searchParam : ""}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  useEffect(() => {
    fetchContractors();
  }, [fetchContractors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleDownload = async (sample) => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(
        `${
          API.DOWNLOAD_CONTRACTOR_TEMPLATE
        }?sample=${sample}&builder_project_id=${projectId ? projectId : ""}`
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute("download", file[file.length - 1] || "contractor.xls");
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleImportModal = async () => {
    try {
      setContractorLoading(true);
      const response = await fetchAccountConnectionData(builderId);
      setContractorLoading(false);
      setProcoreData(response?.procore_integration_data);
      setXeroData(response?.xero_integration_data);
    } catch (e) {
      setContractorLoading(false);
    } finally {
      setShowImport(true);
    }
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleExcelClose = () => {
    setSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ExcelContactColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "last_name",
      text: (
        <div>
          <span className="required">*</span>Last Name
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Last Name is Required",
          };
        }
        return true;
      },
    },
    {
      dataField: "phone",
      text: "Phone",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (newValue.split(" ").join("").length !== 10) {
            return {
              valid: false,
              message: "Please enter valid phone number",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (cell.split(" ").join("").length !== 10) {
            // toast.error("Enter 9 Digit Phone Number")
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "mobile",
      text: "Mobile",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (newValue.split(" ").join("").length !== 10) {
            return {
              valid: false,
              message: "Please enter valid mobile number",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (cell.split(" ").join("").length !== 10) {
            // toast.error("Enter 9 Digit Mobile Number")
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "email",
      text: "Email",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (
            !newValue
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return {
              valid: false,
              message: "Please enter a valid email address",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (
            !String(cell)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "business_name",
      text: (
        <div>
          <span className="required">*</span>Business Name
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (!/^[^!@#%^$<>][a-zA-Z\s\d.,&'/()-]*$/.test(newValue)) {
            return {
              valid: false,
              message:
                "Business name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-), parentheses(), slashes(/) & spaces.", // Display the error message when validation fails
            };
          }
        }
        return true; // If valid, return true
      },

      // Optionally add CSS classes for invalid input (e.g., to highlight the field in red)
      classes: (cell) => {
        if (cell && !/^[^!@#%^$<>][a-zA-Z\s\d.,&'/()-]*$/.test(cell)) {
          return "error-cell"; // Apply error styling
        }
        return "";
      },
    },
    {
      dataField: "abn",
      text: (
        <div>
          <span className="required">*</span>ABN
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (
            !String(newValue)
              .split(" ")
              .join("")
              .match(/^[\d]{11}$/)
          ) {
            return {
              valid: false,
              message: "Enter 11 Digit ABN Number",
            };
          }
          if (newValue.split(" ").join("").length === 11) {
            let newNumber = newValue.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return {
                valid: false,
                message: "Enter Valid ABN Number",
              };
            }
          }
        }
      },
      classes: (cell) => {
        if (cell) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (cell.split(" ").join("").length !== 11) {
            // toast.error("Enter 11 Digit ABN Number")
            return "error-cell";
          }
          if (cell.split(" ").join("").length === 11) {
            let newNumber = cell.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return "error-cell";
            }
          }
        }
      },
    },
    {
      dataField: "street",
      text: "Street",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (!/^[^!@#%^$<>][a-zA-Z\s\d.,&'/()-]*$/.test(newValue)) {
            return {
              valid: false,
              message:
                "Street name can contain letters, numbers, ampersand(&), dot(.), comma(,), apostrophe('), hyphen(-), parentheses(), slashes(/) & spaces.", // Display the error message when validation fails
            };
          }
        }
        return true; // If valid, return true
      },

      // Optionally add CSS classes for invalid input (e.g., to highlight the field in red)
      classes: (cell) => {
        if (cell && !/^[^!@#%^$<>][a-zA-Z\s\d.,&'/()-]*$/.test(cell)) {
          return "error-cell"; // Apply error styling
        }
        return "";
      },
    },
    {
      dataField: "suburb",
      text: "Suburb",
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "state",
      text: "State",
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "postal_code",
      text: "Postal Code",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (!newValue.match(/^[\d]{4}$/)) {
            return {
              valid: false,
              message: "Postal Code can contain 4 digit numbers.",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (!String(cell).match(/^[\d]{4}$/)) {
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "bank_name",
      text: "Bank Name",
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "account_name",
      text: "Account Name",
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "bsb",
      text: "BSB",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (String(newValue).match(/[\d]*$/)) {
            if (
              String(newValue).match("-")?.index === 3 ||
              !String(newValue).match("-")
            ) {
              if (String(newValue).match("-")) {
                if (newValue.split("-").join("").length > 6) {
                  return {
                    valid: false,
                    message: "Enter 6 Digit BSB Number",
                  };
                }
              } else {
                if (newValue.length > 6) {
                  return {
                    valid: false,
                    message: "Enter 6 Digit BSB Number",
                  };
                }
              }
            } else {
              return {
                valid: false,
                message: "Enter 6 Digit BSB Number",
              };
            }
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (String(cell).match(/[\d]*$/)) {
            if (
              String(cell).match("-")?.index === 3 ||
              !String(cell).match("-")
            ) {
              if (String(cell).match("-")) {
                if (String(cell).split("-").join("").length > 6) {
                  return "error-cell";
                }
              } else {
                if (String(cell).length > 6) {
                  return "error-cell";
                }
              }
            } else {
              return "error-cell";
            }
          }
        }
      },
    },
    {
      dataField: "account_number",
      text: "Account Number",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          if (newValue.length > 9) {
            return {
              valid: false,
              message: "Please enter vaild 9 digit Account Number",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (cell.length > 9) {
            return "error-cell";
          }
        }
      },
    },
  ];

  const [selectedRow, setSelectedRow] = useState([]);

  let singleSelectedRows = [];
  let allSelectedRows = [];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ["not-select"],
    selected: selectedRow,
    nonSelectableClasses: "not-selected-class",
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRow];
      if (isSelect) {
        singleSelectedRows = [...selectedRow, row?.key];
      } else {
        singleSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allSelectedRows]);
    },
  };

  const ref = useRef();

  const phoneFormat = (value) => {
    if (String(value)?.match(/\+61/)) {
      let numberWithout61 = String(value)?.split("+61")[1];
      let numberData = String(numberWithout61)?.split(" ")?.join("");

      if (numberData.length < 10) {
        numberData = `0${Number(numberData)}`; // Pad with leading zeros to make it 10 digits
      }

      return `${numberData.substring(0, 2)} ${numberData.substring(
        2,
        6
      )} ${numberData.substring(6)}`;
    } else {
      if (value) {
        let numberData = String(value)?.split(" ")?.join("");
        if (numberData.length < 10) {
          numberData = `0${Number(numberData)}`; // Pad with leading zeros to make it 10 digits
        }
        return `${String(numberData).substring(0, 2)} ${String(
          numberData
        ).substring(2, 6)} ${String(numberData).substring(6)}`;
      }
    }
  };

  function formatBSBNumber(num) {
    const numStr = num;
    if (numStr.length === 6) {
      return `${numStr.slice(0, 3)}-${numStr.slice(3)}`;
    } else {
      return "";
    }
  }

  const ExcelReader = () => {
    let f = files;
    const reader = new FileReader();
    let data = null;
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      let selectItem = [];
      const businessExcelGenerator = (quantity) => {
        const items = [];
        if (
          String(data[0][0]).replace("*", "") !== "First Name" ||
          String(data[0][1]).replace("*", "") !== "Last Name" ||
          String(data[0][2]).replace("*", "") !== "Phone" ||
          String(data[0][3]).replace("*", "") !== "Mobile" ||
          String(data[0][4]).replace("*", "") !== "Email" ||
          String(data[0][5]).replace("*", "") !== "Business Name" ||
          String(data[0][6]).replace("*", "") !== "ABN" ||
          String(data[0][7]).replace("*", "") !== "Street" ||
          String(data[0][8]).replace("*", "") !== "Suburb" ||
          String(data[0][9]).replace("*", "") !== "State" ||
          String(data[0][10]).replace("*", "") !== "Postal Code" ||
          String(data[0][11]).replace("*", "") !== "Bank Name" ||
          String(data[0][12]).replace("*", "") !== "Account Name" ||
          String(data[0][13]).replace("*", "") !== "BSB" ||
          String(data[0][14]).replace("*", "") !== "Account Number"
        ) {
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error(
            "Sorry, File is not in the given format, please download the latest format again"
          );
        } else {
          for (let i = 1; i < quantity; i++) {
            const formatBSB = data[i][13]
              ? formatBSBNumber(
                  padWithLeadingZeros(
                    data[i][13].toString()?.includes("-")
                      ? Number(data[i][13].toString()?.split("-").join(""))
                      : Number(data[i][13]),
                    6
                  )
                )
              : "";
            if (data[i].length > 0) {
              selectItem.push(i);
              items.push({
                key: i,
                first_name: data[i][0] ? data[i][0] : "",
                last_name: data[i][1] ? data[i][1] : "",
                phone: data[i][2] ? phoneFormat(data[i][2]) : "",
                mobile: data[i][3] ? phoneFormat(data[i][3]) : "",
                email: data[i][4] ? data[i][4] : "",
                business_name: data[i][5] ? data[i][5] : "",
                abn: data[i][6]
                  ? `${Number(String(data[i][6])?.split(" ")?.join(""))
                      .toLocaleString("en-US")
                      .replaceAll(",", " ")}`
                  : "",
                street: data[i][7] ? data[i][7] : "",
                suburb: data[i][8] ? data[i][8] : "",
                state: data[i][9] ? data[i][9] : "",
                postal_code: data[i][10] ? data[i][10] : "",
                // shipping_street: data[i][11] ? data[i][11] : "",
                // shipping_suburb: data[i][12] ? data[i][12] : "",
                // shipping_state: data[i][13] ? data[i][13] : "",
                // shipping_postal_code: data[i][14] ? data[i][14] : "",
                bank_name: data[i][11] ? data[i][11] : "",
                account_name: data[i][12] ? data[i][12] : "",
                bsb: formatBSB,
                account_number: data[i][14] ? data[i][14] : "",
              });
            }
          }
        }
        return items;
      };
      setSelectedRow(selectItem);
      setExcelContactData(businessExcelGenerator(data?.length));
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  }, [showExcelModal]);

  const handleExcelModal = () => {
    setShowExcelModal(true);
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleUpload = async () => {
    if (selectedRow.length > 1000) {
      toast.warn("Maximum limit to import contacts is 1000");
    } else {
      // if (files) {
      setShowExcelModal(true);
      const formData = new FormData();

      formData.append("builder_project_id", projectId);

      for (let i = 0; i < selectedRow.length; i++) {
        let row = excelContactData.find((item) => item.key === selectedRow[i]);
        let rowPhone = row.phone.split(" ");
        let rowMobile = row.mobile.split(" ");
        row.phone = rowPhone.join("");
        row.mobile = rowMobile.join("");
        row.abn = row.abn.split(" ").join("");
        row.account_number = padWithLeadingZeros(row.account_number, 9);
        row.bsb = row.bsb.split("-").join("");

        // formData.append(`contractors[${i}]`, JSON.stringify(row));
        Object.keys(row).forEach((key) => {
          formData.append(`contractors[${i}][${key}]`, row[key]);
        });
      }

      const config = { headers: { "Content-Type": "multipart/form-data" } };
      try {
        setIsImportLoading(true);
        const { data } = await fileUpload(
          `${API.IMPORT_CONTRACTOR}`,
          formData,
          config
        );
        setIsImportLoading(false);
        if (data) {
          toast.success(data?.message);
          setSelectedRow([]);
          handleExcelClose();
          fetchContractors();
          handleImportClose();
          if (data?.data?.file) {
            const export_report_path = data?.data?.file;
            if (export_report_path) {
              var a = document.createElement("a");
              a.href = export_report_path;
              var file = export_report_path.split("/");
              a.setAttribute(
                "download",
                file[file.length - 1] || "error-subcontractor.xls"
              );
              a.setAttribute("target", "_blank");
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setIsLoadingDownload(false);
            }
          }
        }
        return data?.data;
      } catch (error) {
        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          if (error?.response?.data?.data?.failedData.length > 0) {
            let failData = [];
            for (
              let i = 0;
              i < error?.response?.data?.data?.failedData.length;
              i++
            ) {
              let contactItem =
                error?.response?.data?.data?.failedData[i]?.data;
              if (contactItem?.phone) {
                contactItem.phone = `+${Number(contactItem?.phone)
                  .toLocaleString("en-US")
                  .replaceAll(",", " ")}`;
              } else {
                contactItem.phone = "";
              }
              if (contactItem?.mobile) {
                contactItem.mobile = `+${Number(contactItem?.mobile)
                  .toLocaleString("en-US")
                  .replaceAll(",", " ")}`;
              } else {
                contactItem.mobile = "";
              }
              if (contactItem?.abn) {
                contactItem.abn = `${Number(contactItem?.abn)
                  .toLocaleString("en-US")
                  .replaceAll(",", " ")}`;
              } else {
                contactItem.abn = "";
              }
              failData.push(contactItem);
            }
            setSelectedRow([]);
            setExcelContactData(failData);
          }
          fetchContractors();
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
        if (error?.response?.data?.errors) {
          if (error?.response?.data?.errors?.data?.file) {
            const export_report_path =
              error?.response?.data?.errors?.data?.file;
            if (export_report_path) {
              // eslint-disable-next-line no-redeclare
              var a = document.createElement("a");
              a.href = export_report_path;
              // eslint-disable-next-line no-redeclare
              var file = export_report_path.split("/");
              a.setAttribute(
                "download",
                file[file.length - 1] || "error-sub-contractor.xls"
              );
              a.setAttribute("target", "_blank");
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();
              setIsLoadingDownload(false);
            }
          }
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
          fetchContractors();
          setIsImportLoading(false);
        } else {
          toast.error(error?.message);
          setIsImportLoading(false);
        }
      }
      // }
    }
  };

  const handleExcelTableChange = async (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    const getWithPromiseAll = async () => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            if (dataField === "account_number") {
              newRow[dataField] = padWithLeadingZeros(Number(newValue), 9);
            } else if (dataField === "mobile" || dataField === "phone") {
              newRow[dataField] = phoneFormat(newValue);
            } else if (dataField === "abn") {
              newRow[dataField] = newValue
                ? `${Number(newValue.split(" ").join(""))
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`
                : "";
            } else if (dataField === "bsb") {
              if (String(newValue).match("-")) {
                newRow[dataField] =
                  padWithLeadingZeros(
                    Number(String(newValue).split("-").join("")),
                    6
                  ).slice(0, 3) +
                  "-" +
                  padWithLeadingZeros(
                    Number(String(newValue).split("-").join("")),
                    6
                  ).slice(3, 6);
              } else {
                newRow[dataField] =
                  padWithLeadingZeros(Number(newValue), 6).slice(0, 3) +
                  "-" +
                  padWithLeadingZeros(Number(newValue), 6).slice(3, 6);
              }
            } else {
              newRow[dataField] = newValue;
            }
            return newRow;
          }
          return row;
        })
      );
      return temp;
    };
    const result = await getWithPromiseAll();
    setExcelContactData(result);
  };

  const handleProcoreModal = () => {
    setShowProcoreModal(!showProcoreModal);
  };

  // Procore Company Listing
  const {
    procoreCompanyLoading,
    procoreCompanyList = [],
    fetchCompany,
  } = useProcoreCompanyList();
  const procoreCompanyListing = procoreCompanyList?.map((item) => {
    const { id: value, ...rest } = item;
    return {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {item?.name}{" "}
          <span
            style={
              item?.is_active
                ? {
                    background: "#0DA500",
                    color: "white",
                    marginLeft: "auto",
                    padding: "4px 6px",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }
                : {
                    background: "#F00",
                    color: "white",
                    marginLeft: "auto",
                    padding: "4px 6px",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }
            }
          >
            {item?.is_active ? "Active" : "InActive"}
          </span>
        </div>
      ),
      value,
      ...rest,
    };
  });

  const handleProcoreImport = async (value) => {
    try {
      setIsImportLoading(true);
      const { data } = await get(
        `${API.PROCORE_IMPORT_SUBCONTRACTOR}?projectId=${
          value?.procore_project_id?.id ? value?.procore_project_id?.id : ""
        }&builder_project_id=${projectId ? projectId : ""}`
      );
      setIsImportLoading(false);
      if (data) {
        toast.success(data?.message);
        handleImportClose();
        handleProcoreModal();
        fetchContractors();
      }
      return data?.data;
    } catch (error) {
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsImportLoading(false);
      } else {
        toast.error(error?.message);
        setIsImportLoading(false);
      }
    }
  };

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele !== value;
    });
  };

  const mainSelectRow = {
    mode: "checkbox",
    // hideSelectAll: abaDate ? false : true,
    clickToSelect: false,
    selected: selectedRowIds,
    nonSelectable: xeroData?.connection_status === 1 ? [] : notSelectedRowIds,
    // nonSelectable: notSelectedRowIds,
    onSelectAll: (row, isSelect) => {
      // if (abaDate) {
      if (contractorsList?.length > 0) {
        if (row) {
          const ids =
            isSelect &&
            isSelect?.length > 0 &&
            isSelect?.map((item) => {
              return item?.key;
            });
          setSelectedRowIds(selectedRowIds ? selectedRowIds.concat(ids) : ids);
        } else {
          const removableData =
            isSelect &&
            isSelect?.length > 0 &&
            isSelect.map(function (data) {
              return data?.key;
            });
          setSelectedRowIds(
            selectedRowIds
              ? selectedRowIds.filter((item) => !removableData.includes(item))
              : []
          );
        }
      }
      // }
    },
    onSelect: (row, isSelect) => {
      // if (abaDate) {
      if (isSelect) {
        setSelectedRowIds((keys) => [...keys, row?.key]);
      } else {
        setSelectedRowIds(arrayRemove(selectedRowIds, row?.key));
      }
      // } else {
      //   if (isSelect) {
      //     setSelectedRowIds((keys) => [...keys, row?.key]);
      //     setABADate(moment(row?.transaction_date, "DD-MM-YYYY").format("DD-MM-YYYY"));
      //   }
      // }
    },
  };

  const fetchSyncOptions = async () => {
    try {
      setTentantLoading(true);
      const response = await get(
        `${API.XERO_TENANTS}${builderId && "?builder_id=" + builderId}`
      );
      setTentantLoading(false);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        let tenantList = response?.data?.data?.map((item) => {
          const { tenantId: value, ...rest } = item;
          return {
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {item?.tenantName}{" "}
              </div>
            ),
            value,
            ...rest,
          };
        });
        setSyncOptionData(tenantList);
      }
    } catch (error) {
      setTentantLoading(false);
      toast.error(error?.message);
      toast.error(
        error.errors && error.errors.myna_error && error.errors.myna_error[0]
      );
    }
  };

  const toggleSyncOptionModal = async () => {
    if (!selectedRowIds.length && !contactId) {
      toast.error("Please select rows");
      return;
    }
    if (!syncOptionModal) {
      setContractorLoading(true);
      const response = await fetchAccountConnectionData(builderId);
      setContractorLoading(false);
      if (response?.xero_integration_data?.connection_status === 1) {
        setXeroData(response?.xero_integration_data);
        fetchSyncOptions();
      } else {
        toast.warn("Please connect the xero account");
        return;
      }
    }
    setSyncOptionModal(!syncOptionModal);
  };

  const handleSyncData = async (id) => {
    if (!contactId) {
      if (!selectedRowIds.length) {
        toast.error("Please select rows");
        return;
      }
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      formData.append("xero_tenant_id", id ? id?.value : "");
      if (contactId) {
        formData.append(`contact_ids[0]`, contactId);
      } else {
        if (selectedRowIds?.length > 0) {
          for (let i = 0; i < selectedRowIds?.length; i++) {
            formData.append(`contact_ids[${i}]`, selectedRowIds[i]);
          }
        }
      }
      const { data } = await fileUpload(API.CONTRACTOR_SYNC, formData, {});
      setSelectedRowIds([]);
      setSyncOptionModal(false);
      setContactId();
      setLoading(false);
      toast.success(data.message);
      if (data) {
        fetchContractors();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            {" "}
            <BackButton />
            Subcontractors(Beneficiaries)
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => handleSearchChange(e)}
                    type="text"
                    value={searchParamData}
                    placeholder="Search Subcontractor"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              {!ProjectReadOnly && (
                <div className="table-btn">
                  <div className="border-btn">
                    <DropdownButton className="info-icon">
                      <Dropdown.Item>
                        <p>Last Name - Required</p>
                        <p>Business Name - Required & Unique</p>
                        <p>Email - Required & Unique</p>
                        <p>ABN - Required & Unique</p>
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="link"
                      disabled={isLoadingDownload}
                      onClick={() => handleDownload(1)}
                      className="download-template"
                    >
                      Download Template
                    </Button>
                    <Button variant="primary" onClick={handleImportModal}>
                      Import Subcontractors
                    </Button>
                  </div>
                  <Button
                    variant="primary"
                    onClick={() =>
                      navigate(
                        "/projects/" + projectId + "/contact-import-logs"
                      )
                    }
                  >
                    Import Logs
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isLoadingDownload || contractors?.length === 0}
                    onClick={() => handleDownload(0)}
                    className="download-template"
                  >
                    Export
                  </Button>
                  {!ProjectReadOnly ? (
                    xeroData?.connection_status === 1 ? (
                      selectedRowIds?.length === 0 ? (
                        <CustomTooltip
                          message={"Please select record(s) to sync."}
                        >
                          <Button
                            variant="primary"
                            onClick={toggleSyncOptionModal}
                            disabled={true}
                          >
                            Sync
                          </Button>
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip message={"Sync"}>
                          <Button
                            variant="primary"
                            onClick={toggleSyncOptionModal}
                          >
                            Sync
                          </Button>
                        </CustomTooltip>
                      )
                    ) : (
                      <CustomTooltip message={"Please connect with xero to sync."}>
                        <Button
                          variant="primary"
                          onClick={toggleSyncOptionModal}
                          disabled={true}
                        >
                          Sync
                        </Button>
                      </CustomTooltip>
                    )
                  ) : (
                    <></>
                  )}
                  <Button
                    variant="primary"
                    onClick={() =>
                      navigate(
                        "/Projects/" + projectId + "/sub-contractors/add"
                      )
                    }
                  >
                    Add New
                  </Button>
                  {/* <Button variant="primary">
                  Import
                </Button> */}
                </div>
              )}
            </div>
            <div className="custom-table">
              {(contractorLoading || fetchContractorLoading) && <Spin />}

              <BootstrapTable
                keyField="key"
                remote
                data={contractors}
                expandRow={expandRow}
                selectRow={mainSelectRow}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
            {showImport && (
              <Modal
                size="lg"
                show={showImport}
                onHide={handleImportClose}
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                className="business-section"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Import Subcontractors
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <UploadFile
                      isLoading={isImportLoading}
                      setFiles={setFiles}
                      files={files}
                      handleUpload={handleExcelModal}
                      handleCancel={handleImportClose}
                      acceptFileFormat=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      ErrorMessage="csv, xls and xlsx"
                    />
                    {procoreData?.connection_status === 1 ||
                    xeroData?.connection_status === 1 ? (
                      <>
                        <p className="or">OR</p>
                        <h5>Import From</h5>
                        <span className="procore-and-xero">
                          {procoreData?.connection_status === 1 && (
                            <>
                              <CustomTooltip message={"Procore"}>
                                <span>
                                  <img
                                    src={proCoreLogo}
                                    onClick={() => {
                                      handleProcoreModal();
                                      fetchCompany();
                                    }}
                                    alt="Procore Logo"
                                    height={100}
                                    width={100}
                                  />
                                </span>
                              </CustomTooltip>
                              {/* <p>Procore</p> */}
                            </>
                          )}
                          {procoreData?.connection_status === 1 &&
                            xeroData?.connection_status === 1 && <h5>OR</h5>}
                          {xeroData?.connection_status === 1 && (
                            <CustomTooltip message={"Xero"}>
                              <span>
                                <img
                                  src={XeroLogo}
                                  onClick={handleXeroModal}
                                  alt="Xero"
                                  height={100}
                                  width={100}
                                ></img>
                              </span>
                            </CustomTooltip>
                          )}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {showProcoreModal && (
              <Modal
                show={showProcoreModal}
                onHide={handleProcoreModal}
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {" "}
                <Modal.Header className="mb-0" closeButton>
                  Import Subcontractors from Procore
                </Modal.Header>
                <Modal.Body>
                  <Formik
                    initialValues={{
                      procore_company_id: "",
                      procore_project_id: "",
                    }}
                    enableReinitialize={true}
                    onSubmit={(value) => {
                      handleProcoreImport(value);
                    }}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validationSchema={AddProcoreImportSchema}
                  >
                    {({
                      values,
                      setErrors,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <>
                          {isImportLoading && <Spin />}
                          <form
                            onSubmit={handleSubmit}
                            noValidate
                            className="entry-section"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                            }}
                          >
                            <div className="form-group half-width builder-firm">
                              <label>
                                <span className="required">*</span>Procore
                                Company
                              </label>
                              <ReactSelect
                                name="procore_company_id"
                                value={
                                  values?.procore_company_id
                                    ? values?.procore_company_id
                                    : ""
                                }
                                onChange={(e) => {
                                  setFieldValue(
                                    `procore_company_id`,
                                    e ? e : ""
                                  );
                                  setFieldValue(`procore_project_id`, "");
                                }}
                                onBlur={handleBlur}
                                options={procoreCompanyListing}
                                placeholder="Select Company"
                                classNamePrefix="selectbox"
                                isClearable={false}
                                isLoading={procoreCompanyLoading}
                                autoComplete="off"
                                onKeyPress={(e) => {
                                  e.which === 13 && e.preventDefault();
                                }}
                              />

                              <span
                                className="text-danger"
                                style={{ color: "red", fontSize: "20px" }}
                              >
                                <ErrorMessage name="procore_company_id" />
                              </span>
                            </div>
                            <div className="form-group half-width builder-firm">
                              <label>
                                <span className="required">*</span>Procore
                                Project
                              </label>

                              <ProcoreProjectSelect
                                name="procore_project_id"
                                companyName={values?.procore_company_id}
                                onChange={(e) => {
                                  setFieldValue(
                                    "procore_project_id",
                                    e ? e : ""
                                  );
                                }}
                                value={
                                  values?.procore_project_id
                                    ? values?.procore_project_id
                                    : null
                                }
                                placeholder={"Select Project"}
                                isClearable={false}
                                isDisabled={
                                  values?.procore_company_id ? false : true
                                }
                              />

                              <span
                                className="text-danger"
                                style={{ color: "red", fontSize: "20px" }}
                              >
                                <ErrorMessage name="procore_project_id" />
                              </span>
                            </div>
                            <div className="modal-footer">
                              <Button type="submit">Import</Button>
                              <Button
                                className="reset"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleProcoreModal}
                              >
                                Cancel
                              </Button>
                            </div>
                          </form>
                        </>
                      );
                    }}
                  </Formik>
                </Modal.Body>
              </Modal>
            )}
            {showExcelModal && (
              <Modal
                // size="xl"
                show={showExcelModal}
                onHide={handleExcelClose}
                dialogClassName="full-popup large-popup"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Import Subcontractors
                </Modal.Header>
                <Modal.Body>
                  <div className="excel-table-list" style={{ padding: "20px" }}>
                    {isImportLoading && <Spin />}
                    <BootstrapTable
                      keyField="key"
                      selectRow={selectRow}
                      ref={ref}
                      remote={{ cellEdit: true }}
                      data={excelContactData ? excelContactData : []}
                      columns={ExcelContactColumns}
                      noDataIndication="No Data Found"
                      cellEdit={cellEditFactory({
                        mode: "click",
                        blurToSave: true,
                        timeToCloseMessage: 30000,
                      })}
                      onTableChange={handleExcelTableChange}
                    />
                    <div className="excel-list-footer">
                      <Button
                        variant="primary"
                        onClick={() => handleUpload()}
                        data-toggle="modal"
                        data-target="#business"
                        disabled={selectedRow.length === 0 ? true : false}
                      >
                        Import
                      </Button>
                      <div>Selected Records: {selectedRow?.length}</div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}

            {isImportFromXero && (
              <Modal
                show={isImportFromXero}
                onHide={handleXeroModal}
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="mb-0" closeButton>
                  Confirmation!
                </Modal.Header>
                <Modal.Body>
                  {contractorLoading && <Spin />}
                  <div className="modal-body ">
                    <p>
                      Do you want to import/update client records from Xero?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <Button
                      type="Button"
                      className="btn btn-secondary"
                      onClick={handleXeroModal}
                    >
                      Cancel
                    </Button>
                    <Button className="btn btn-primary" onClick={handlexero}>
                      OK
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {isTenantData && (
              <Modal
                show={isTenantData}
                onHide={handleTenantModal}
                dialogClassName="modal-50w small-popup review-popup small-review"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="xpm-modal"
              >
                <Modal.Header closeButton>Xero Organisation</Modal.Header>
                <Modal.Body>
                  {XPMLoading && <Spin />}
                  <div className="modal-body" style={{ textAlign: "center" }}>
                    <ReactSelect
                      name="tenant_id"
                      onChange={(e) => {
                        setTenantId(e);
                      }}
                      options={tenantData}
                      placeholder="Select Xero Organisation"
                      classNamePrefix="selectbox"
                      isClearable={false}
                      isLoading={contractorLoading}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                    />
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn btn-secondary"
                      onClick={handleTenantModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn btn-primary"
                      disabled={tenantId ? false : true}
                      onClick={() => {
                        handleSubmiTenantData(tenantId);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                  {/* </Spin> */}
                </Modal.Body>
              </Modal>
            )}
            {syncOptionModal && (
              <SyncContractor
                syncOptionModal={syncOptionModal}
                toggleSyncOptionModal={toggleSyncOptionModal}
                loading={loading}
                tentantLoading={tentantLoading}
                syncOptionData={syncOptionData}
                syncOptionId={syncOptionId}
                setSyncOptionId={setSyncOptionId}
                handleSyncData={handleSyncData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubContractors;
