import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditBuilderUser from "../EditBuilderUser";
import BuilderUserDelete from "./BuilderUserDelete";
import CustomTooltip from "../../../common/CustomTooltip";

const BuilderUserActionButton = ({ id, userModal, builderId }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const toggleUserModal = () => {
    showUser && userModal();

    setShowUser(!showUser);
  };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <>
      <span>
        {/* <FontAwesomeIcon
          onClick={toggleShowModal}
          icon={faEye}
          size="1x"
          data-toggle="modal"
          data-target="#clientDetail"
        /> */}
        {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
        <CustomTooltip message={"Edit"}>
          <span>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={toggleUserModal}
            />
          </span>
        </CustomTooltip>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip message={"Delete"}>
          <span>
            <FontAwesomeIcon
              onClick={toggleDeleteModal}
              icon={faTrash}
              size="1x"
              data-toggle="modal"
              data-target="#deleteClient"
            />
          </span>
        </CustomTooltip>
      </span>

      {showDelete && (
        <BuilderUserDelete
          userId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={userModal}
          showDelete={showDelete}
          builderId={builderId}
        />
      )}

      {showUser && (
        <EditBuilderUser
          userId={id}
          showUser={showUser}
          toggleUserModal={toggleUserModal}
          builderId={builderId}
        />
      )}

      {/* {showView && (
        <UserDetailPopup
          showView={showView}
          userId={id}
          toggleShowModal={toggleShowModal}
        />
      )} */}
    </>
  );
};

export default BuilderUserActionButton;
