import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { roleStatus, roleTypeOption } from "./RolesHelper";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleRolesModal,
  tomorrow,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleRolesModal: PropTypes.any,
    officeListing: PropTypes.any,
    officeLoading: PropTypes.any,
    roleLoading: PropTypes.any,
    roleListing: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group full-width">
          <label>
            <span className="required">*</span>Role Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Enter Role Name"
            value={values?.name ? values?.name : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="name" />
          </span>
        </div>
        <div className="form-group full-width builder-firm">
          <label>
            <span className="required">*</span>Role Type
          </label>
          <Select
            name="type"
            value={values?.type ? values?.type : ""}
            onChange={(e) => {
              setFieldValue(`type`, e ? e : "");
            }}
            onBlur={handleBlur}
            options={roleTypeOption}
            placeholder="Select Role Type"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="type" />
          </span>
        </div>

        <div className="form-group full-width builder-firm">
          <label>
            <span className="required">*</span>Status
          </label>
          <Select
            name="status"
            value={values?.status ? values?.status : ""}
            onChange={(e) => {
              setFieldValue(`status`, e ? e : "");
            }}
            onBlur={handleBlur}
            options={roleStatus}
            placeholder="Select Status"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="status" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          type="Button"
          onClick={() => {
            toggleRolesModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
