import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../components/Form/BackButton";
import { API, defaultLimitPagination, get } from "../../../config";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import { debounce } from "lodash";
import HolidaysActionButton from "./components/HolidaysActionButton";
import AddHolidays from "./AddHolidays";

const Holidays = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [holidaysList, setHolidaysList] = useState([]);
  const [holidaysLoading, setHolidaysLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showHolidays, setShowHolidays] = useState(false);

  // *API Call for Geting Holidays - Start
  const fetchHolidays = useCallback(async () => {
    try {
      setHolidaysLoading(true);
      const { data } = await get(
        `${API.GET_HOLIDAY}?limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setHolidaysLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const holidayList = data?.data?.data;
      setHolidaysList(holidayList);
      return 0;
    } catch (error) {
      setHolidaysLoading(false);
      setHolidaysList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [limit, page, searchParam, sortOrder, sortField]);

  const holidaysModal = () => {
    fetchHolidays();
  };

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);
  // *API Call for Geting Holidays - End

  // *Holidays Table Row and Column Generation, Filtering and Sorting of Record - Start
  const holidaysGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: holidaysList[i]?.id,
        id: holidaysList[i]?.id ? holidaysList[i]?.id : "",
        date: holidaysList[i]?.date ? holidaysList[i]?.date : "",
        name: holidaysList[i]?.name ? holidaysList[i]?.name : "",
        description: holidaysList[i]?.description
          ? holidaysList[i]?.description
          : "",
        action: (
          <HolidaysActionButton
            id={holidaysList[i]?.id}
            holidaysModal={holidaysModal}
          />
        ),
      });
    }
    return items;
  };
  const holidaysData = holidaysGenerator(holidaysList?.length);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      className: "text-center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  // const onSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchParam(value);
  //   setPage(1);
  // };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Holidays Table Row and Column Generation, Filtering and Sorting of Record - End

  useEffect(() => {
    navigate(
      `/admin/holidays?limit=${limit ? limit : defaultLimitPagination}&page=${
        page ? page : 1
      }&search=${searchParam ? searchParam : ""}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, searchParam, page, sortField, sortOrder]);

  const toggleHolidaysModal = () => {
    showHolidays && holidaysModal();
    setShowHolidays(!showHolidays);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParam(nextValue);
    if (nextValue?.length >= 2 || nextValue?.length === 0) {
      debouncedSave(nextValue);
    }
  };



  return (
    <>
      {" "}
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              QLD Public Holidays
            </h1>
            <div className="content-details">
              <div className="table-top-btn">
                <div className="search-section">
                  <Form>
                    <FormControl
                      onChange={handleSearchChange}
                      type="text"
                      value={searchParam}
                      placeholder="Search Holidays"
                    />
                    <Button className="fa fa-search">Search</Button>
                  </Form>
                </div>
                <div className="table-btn">
                  <Button variant="primary" onClick={toggleHolidaysModal}>
                    Add New
                  </Button>
                </div>
              </div>
              <div className="custom-table">
                {holidaysLoading && <Spin />}
                <BootstrapTable
                  keyField="holidays_id"
                  remote
                  data={holidaysData}
                  columns={columns}
                  onTableChange={handleTableChange}
                  noDataIndication="No Data Found"
                />
                <Pagination
                  total={total}
                  limit={parseInt(limit)}
                  currentPage={page}
                  updateLimit={handleUpdateLimit}
                  updatePage={handleChangePage}
                  from={from}
                  to={to}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showHolidays && (
        <AddHolidays
          toggleHolidaysModal={toggleHolidaysModal}
          holidaysModal={holidaysModal}
          showHolidays={showHolidays}
        />
      )}
    </>
  );
};

export default Holidays;
