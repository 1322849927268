import React, { useCallback, useEffect, useState } from "react";
import { Calendar } from "rsuite";
import { API, get } from "../../config";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spin from "./Spin";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { formatNumber, formatSubcontractorName } from "./Misc";

const CalenderView = ({ projectData }) => {
  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const [loading, setLoading] = useState(true);
  const [calenderViewData, setCalenderViewData] = useState();
  const today = moment(new Date()).format("DD/MM/YYYY").split("/");
  const [month, setMonth] = useState(`${today[1]}-${today[2]}`);
  const [calenderViewListData, setCalenderViewListData] = useState([]);
  let currentDate = new Date();
  const [changeDate, setChangeDate] = useState(currentDate);

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const fetchPaymentSchedules = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_PAYMENTS}?builder_project_id=${projectId}&limit=-1&trust_type=${trust_type}&month=${month}`
      );
      setLoading(false);
      const paymentScheduleList = data.data.data;
      setCalenderViewData(paymentScheduleList);
      return data.data;
    } catch (error) {
      setLoading(false);
      setCalenderViewData([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [projectId, trust_type, month]);

  useEffect(() => {
    fetchPaymentSchedules();
  }, [fetchPaymentSchedules]);

  function getCalenderList(date) {
    let tempDate = moment(date).format("DD/MM/YYYY")?.split("/")?.join("-");
    const data = calenderViewData?.filter(
      (i) => i.transaction_date === tempDate && i.aba_file !== null
    );
    if (data) {
      return data?.map((i) => ({
        name: i?.aba_file?.file?.split("/").slice(-1),
        // aba_file: i?.aba_file?.file,
        ...i,
      }));
    } else {
      return [];
    }
  }

  function RenderSideView(list) {
    let TotalABA = 0;
    list?.map((item) => (TotalABA = TotalABA + Number(item?.total_amount)));
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5>ABA File Downloads</h5>
          <h6 style={{ fontSize: "16px" }}>
            {" "}
            Total: ${TotalABA ? formatNumber(TotalABA) : "0.00"}
          </h6>
        </div>

        <div className="side-list-calendar">
          {list?.length > 0 ? (
            <ul className="calendar-list">
              {list.map((item, index) => (
                <>
                  {index >= 0 && (
                    <div className="calender-aba-item-body">
                      <div>
                        {/* <p>
                          {item?.contact?.first_name
                            ? item?.contact?.account_name ===
                              formatName(
                                item?.contact?.first_name,
                                item?.contact?.last_name
                              )
                              ? formatName(
                                  item?.contact?.first_name,
                                  item?.contact?.last_name
                                )
                              : `${formatName(
                                  item?.contact?.first_name,
                                  item?.contact?.last_name
                                )} (${item?.contact?.account_name})`
                            : item?.contact?.business_name
                            ? item?.contact?.account_name ===
                              item?.contact?.business_name
                              ? item?.contact?.business_name
                              : `${item?.contact?.business_name} (${item?.contact?.account_name})`
                            : item?.contact?.account_name ===
                              formatName(
                                item?.contact?.first_name,
                                item?.contact?.last_name
                              )
                            ? formatName(
                                item?.contact?.first_name,
                                item?.contact?.last_name
                              )
                            : `${formatName(
                                item?.contact?.first_name,
                                item?.contact?.last_name
                              )} (${item?.contact?.account_name})`}
                        </p> */}
                        <p>{formatSubcontractorName(item?.contact)}</p>
                        <p
                          style={{
                            marginBottom: "0px",
                          }}
                        >
                          {item?.total_amount
                            ? "$" + formatNumber(Number(item?.total_amount))
                            : `$0.00`}
                        </p>
                      </div>
                      {item?.aba_file?.file && (
                        <button
                          variant="outline"
                          onClick={() => downloadLetter(item?.aba_file?.file)}
                          className="calendar-aba-download-btn"
                        >
                          Download
                        </button>
                      )}
                    </div>
                  )}
                </>
              ))}
            </ul>
          ) : (
            <>No Record</>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            <div>
              {" "}
              PTA Bank Balance: $
              {projectData?.pta_balance?.statement
                ? formatNumber(Number(projectData?.pta_balance?.statement))
                : "0.00"}
            </div>
            <div
              style={{
                margin: 0,
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {" "}
              Balance till date{" "}
              {projectData?.pta_balance?.statementDate
                ? moment(
                    projectData?.pta_balance?.statementDate,
                    "DD-MM-YYYY HH:mm:ss"
                  ).format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
          <div style={{ fontSize: "16px" }}>
            <div>
              RTA Bank Balance: $
              {projectData?.rta_balance?.statement
                ? formatNumber(Number(projectData?.rta_balance?.statement))
                : "0.00"}
            </div>
            <div
              style={{
                margin: 0,
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {" "}
              Balance till date{" "}
              {projectData?.rta_balance?.statementDate
                ? moment(
                    projectData?.rta_balance?.statementDate,
                    "DD-MM-YYYY HH:mm:ss"
                  ).format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderCell(date) {
    const list = getCalenderList(date);
    const displayList = list.filter((item, index) => index < 0);

    if (list.length) {
      const moreCount = list.length - displayList.length;
      const moreItem = (
        <li>
          <span>{moreCount} items</span>
        </li>
      );

      return (
        <ul className="calendar-list-cell">
          {displayList.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                downloadLetter(item.aba_file?.file);
              }}
            >
              {item.name}{" "}
              <span>
                <FontAwesomeIcon icon={faDownload} size="1x" />
              </span>
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    }

    return null;
  }

  function ListDataFunc(date) {
    const list = getCalenderList(date);
    setCalenderViewListData(list);
  }

  useEffect(() => {
    if (changeDate && !loading) {
      ListDataFunc(changeDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDate, loading]);

  return (
    <>
      {loading && <Spin />}
      <div
        className="calender-view"
        style={{
          display: "flex",
        }}
      >
        <Calendar
          bordered
          renderCell={renderCell}
          onMonthChange={(e) => {
            setCalenderViewListData([]);
            let temp = moment(e).format("DD/MM/YYYY").split("/");
            setMonth(`${temp[1]}-${temp[2]}`);
          }}
          onSelect={(date) => ListDataFunc(date)}
          onChange={(date) => setChangeDate(date)}
        />

        <div
          style={{
            padding: "0px 10px",
            flex: "1",
          }}
        >
          {RenderSideView(calenderViewListData)}
        </div>
      </div>
    </>
  );
};

export default CalenderView;
