import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootStrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../components/Form/BackButton";
import { API, defaultLimitPagination, get } from "../../config";
import { formatMobile, formatName, formatPhone } from "../common/Misc";
import Pagination from "../common/Pagination";
import Spin from "../common/Spin";
// import { debounce } from "lodash";

const SubscriptionsForAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  // const search = query.get("search");
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [subscriptionsList, setSubscriptionList] = useState([]);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  // const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  // *API Call for Subscription User in Admin - Start
  const fetchSubscriptions = useCallback(async () => {
    try {
      setSubscriptionLoading(true);
      const { data } = await get(
        `${API.GET_SUBSCRIPTION_FOR_ADMIN}?limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setSubscriptionLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const subscriptionsList = data.data.data;
      setSubscriptionList(subscriptionsList);
      return data.data;
    } catch (error) {
      setSubscriptionList([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setSubscriptionLoading(false);
      } else {
        toast.error(error?.message);
        setSubscriptionLoading(false);
      }
    }
  }, [limit, page, sortOrder, sortField]);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);
  // *API Call for Subscription User in Admin - End

  // *Subscription User Table Row and Column Generation, Filtering and Sorting of Record - Start
  const subscriptionsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: subscriptionsList[i]?.id,
        name: formatName(
          subscriptionsList[i]?.user?.first_name,
          subscriptionsList[i]?.user?.last_name
        ),
        email: subscriptionsList[i]?.user?.email
          ? subscriptionsList[i]?.user?.email
          : "",
        phone: subscriptionsList[i]?.user?.phone
          ? formatPhone(subscriptionsList[i]?.user?.phone)
          : "",
        mobile: subscriptionsList[i]?.user?.mobile
          ? formatMobile(subscriptionsList[i]?.user?.mobile)
          : "",
        role: subscriptionsList[i]?.user?.role?.name
          ? subscriptionsList[i]?.user?.role?.name
          : "",
      });
    }
    return items;
  };
  const subscriptions = subscriptionsGenerator(subscriptionsList?.length);

  useEffect(() => {
    navigate(
      `/admin/subscriptions?limit=${limit ? limit : defaultLimitPagination}&page=${
        page ? page : 1
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page, sortField, sortOrder]);

  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phone",
      text: "Phone No.",
    },
    {
      dataField: "mobile",
      text: "Mobile No.",
    },
    {
      dataField: "role",
      text: "Role",
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Subscription User Table Row and Column Generation, Filtering and Sorting of Record - End

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Subscriptions
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section"></div>
              <div className="table-btn">
                <Button variant="primary">Add New</Button>
              </div>
            </div>
            <div className="custom-table">
              {subscriptionLoading && <Spin />}
              <BootStrapTable
                keyField="id"
                remote
                data={subscriptions}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsForAdmin;
