import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import Select from "react-select";
// import { DatePicker } from "@semcore/date-picker";
import NumberFormat from "react-number-format";
import { MaxLimit } from "../../../common/Misc";
import moment from "moment";
import { DatePicker } from "rsuite";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleBankModal,
  accountData,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleBankModal: PropTypes.any,
    accountData: PropTypes.any,
  };

  const transactionType = [
    { label: "Credit", value: 1 },
    { label: "Debit", value: 0 },
  ];

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            Account
          </label>
          <Select
            name="account"
            value={values?.account ? values?.account : ""}
            onChange={(e) => {
              setFieldValue("account", e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={accountData}
            placeholder="Select Account"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="account" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Transaction Type
          </label>
          <Select
            // type="select"
            name="baseType"
            value={values?.baseType ? values?.baseType : ""}
            onChange={(e) => {
              setFieldValue("baseType", e ? e : "");
              // handleChange(e);
            }}
            onBlur={handleBlur}
            options={transactionType}
            placeholder="Select Transaction Type"
            classNamePrefix="selectbox"
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="baseType" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Transaction Date
          </label>
          {/* <DatePicker
            name="transactionDate"
            value={values?.transactionDate ? values?.transactionDate : ""}
            onChange={(e) => {
              setFieldValue("transactionDate", e);
            }}
            disabled={[[tomorrow, false]]}
          /> */}
          <DatePicker
            cleanable={false}
            name="transaction_date"
            onChange={(e) =>
              setFieldValue(
                "transactionDate",
                moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
              )
            }
            format="dd-MM-yyyy"
            value={
              values?.transactionDate
                ? String(values?.transactionDate)?.includes("-")
                  ? new Date(
                      String(values?.transactionDate)?.split("-")[2],
                      String(values?.transactionDate)?.split("-")[1] - 1,
                      String(values?.transactionDate)?.split("-")[0]
                    )
                  : new Date(values?.transactionDate)
                : null
            }
            className="rs-date-body"
            placeholder="Select Date"
            placement="autoVerticalStart"
            disabledDate={(date) => moment(date).isAfter(moment())}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="transactionDate" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Amount
          </label>
          <NumberFormat
            type="text"
            name="amount"
            value={values?.amount ? values?.amount : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={true}
            isAllowed={(values) => MaxLimit(values)}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="amount" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Description
          </label>
          <input
            type="text"
            name="description"
            value={values?.description ? values?.description : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="description" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleBankModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
