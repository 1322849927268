import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { formatNumber } from "../../../common/Misc";

const ExpandSubContractorDetails = ({ expandData }) => {

  const expandDataGenerator = () => {
    const items = [];
    items.push({
      key: expandData?.id,
      contract_value: expandData?.contract_value
        ? expandData?.contract_value
        : "-",
      retention_percentage: expandData?.retention_percentage
        ? `${expandData?.retention_percentage}%`
        : "-",
      retention_value: expandData?.retention_amount
        ? expandData?.retention_amount
        : "-",
      pta_closing_balance: expandData?.closing_balance_pta
        ? formatNumber(Number(expandData?.closing_balance_pta))
        : "-",
      rta_closing_balance: expandData?.closing_balance_rta
        ? formatNumber(Number(expandData?.closing_balance_rta))
        : "-",
    });
    return items;
  };
  const subExapndData = expandDataGenerator();

  const columns = [
    {
      dataField: "contract_value",
      text: "Contract Value",
      style: { width: "15%" },
    },
    {
      dataField: "retention_percentage",
      text: "Retention Percentage",
      style: { width: "15%" },
    },
    {
      dataField: "retention_value",
      text: "Retention Value",
      style: { width: "15%" },
    },
    {
      dataField: "pta_closing_balance",
      text: "Total Net Payment from PTA",
    },
    {
      dataField: "rta_closing_balance",
      text: "RTA Closing Balance",
    },
  ];

  return (
    <>
      <div className="content-details">
        <div className="custom-table journal-expand-table">
          <BootstrapTable
            keyField="key"
            data={subExapndData}
            columns={columns}
            noDataIndication="No Data Found"
          />
        </div>
      </div>
    </>
  );
};

export default ExpandSubContractorDetails;
