import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BackButton from "../../../../components/Form/BackButton";
import { API, defaultLimitPagination, get, imageDefaultPrefixPath } from "../../../../config";
import Pagination from "../../../common/Pagination";
import Spin from "../../../common/Spin";
import { debounce } from "lodash";
import SearchTermActionButton from "./components/SearchTermActionButton";
import { useProjectBar } from "../../../common/useProjectBar";
import { formatSubcontractorName } from "../../../common/Misc";

const SearchTerms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = useParams();
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const projectId = params?.project_id;
  // const trustType = params?.trust_type;
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [loading, setLoading] = useState(false);
  const [termsList, setTermsList] = useState([]);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  const handleDelete = () => {
    fetchTerms();
  };

  const fetchTerms = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_TERMS}?limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&builder_project_id=${
          projectId ? projectId : ""
        }`
      );
      navigate(
        `?limit=${limit ? limit : defaultLimitPagination}&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`,
        {
          replace: true,
        }
      );
      setLoading(false);
      setLimit(data?.data?.terms?.per_page);
      setPage(data?.data?.terms?.current_page);
      setTotal(data?.data?.terms?.total);
      setFrom(data?.data?.terms?.from);
      setTo(data?.data?.terms?.to);
      const customersList = data?.data?.terms?.data;
      setTermsList(customersList);
      return data.data;
    } catch (error) {
      setLoading(false);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [projectId, limit, navigate, page, searchParam, sortField, sortOrder]);

  const termsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: termsList[i]?.id,
        term: termsList[i]?.term,
        // account_name: termsList[i]?.chart_of_account?.name,
        account_name: termsList[i]?.chart_of_account
          ? termsList[i]?.chart_of_account?.name +
            (termsList[i]?.chart_of_account?.account_type?.name
              ? " (" + termsList[i]?.chart_of_account?.account_type?.name + ")"
              : "") +
            (termsList[i]?.chart_of_account?.code
              ? " - " + termsList[i]?.chart_of_account?.code.toString()
              : "") +
            (termsList[i]?.chart_of_account?.sub_code
              ? "." + termsList[i]?.chart_of_account?.sub_code.toString()
              : "")
          : "",
        // contact:
        //   termsList[i]?.contact?.first_name && termsList[i]?.contact?.last_name
        //     ? `${termsList[i]?.contact?.first_name} ${termsList[i]?.contact?.last_name}`
        //     : termsList[i]?.contact?.first_name
        //     ? termsList[i]?.contact?.first_name
        //     : termsList[i]?.contact?.last_name
        //     ? termsList[i]?.contact?.last_name
        //     : "-",
        contact: formatSubcontractorName(termsList[i]?.contact),
        description: termsList[i]?.description
          ? termsList[i]?.description
          : "-",
        // (termsList[i]?.chart_of_account?.tax === 1
        //   ? " - GST Inc."
        //   : " - GST Ex."),

        action: (
          <SearchTermActionButton
            id={termsList[i]?.id}
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };

  const terms = termsGenerator(termsList?.length);

  const columns = ProjectReadOnly
    ? [
        {
          dataField: "term",
          text: "Term",
          sort: true,
        },
        {
          dataField: "account_name",
          text: "Account Name",
        },
        {
          dataField: "contact",
          text: "Contractor Name",
        },
        {
          dataField: "description",
          text: "Description",
        },
      ]
    : [
        {
          dataField: "term",
          text: "Term",
          sort: true,
        },
        {
          dataField: "account_name",
          text: "Account Name",
        },
        {
          dataField: "contact",
          text: "Contractor Name",
        },
        {
          dataField: "description",
          text: "Description",
        },
        {
          dataField: "action",
          text: "Action",
          className: "text-center",
          style: { width: "127px" },
          align: "center",
          headerAlign: "center",
        },
      ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearchParam(value);
    setPage(1);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            Terms
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details">
            <div className="table-top-btn">
              <div className="search-section">
                <Form>
                  <FormControl
                    onChange={(e) => debounce(onSearchChange(e), 300)}
                    type="text"
                    value={searchParam}
                    placeholder="Search Terms"
                  />
                  <Button className="fa fa-search">Search</Button>
                </Form>
              </div>
              {!ProjectReadOnly && (
                <div className="table-btn">
                  <Button
                    variant="primary"
                    onClick={() =>
                      navigate("/projects/" + projectId + `/terms/add`)
                    }
                  >
                    Add New
                  </Button>
                </div>
              )}
            </div>
            <div className="custom-table">
              {loading && <Spin />}
              <BootstrapTable
                keyField="key"
                remote
                data={terms}
                columns={columns}
                onTableChange={handleTableChange}
                noDataIndication="No Data Found"
              />

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTerms;
