import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import AcceptInvitation from "./AcceptInvitation";
import RejectInvitation from "./RejectInvitation";

const InvitationsActionButton = ({ id, handleInvitations, status }) => {
  const [showAcceptInvitation, setShowAcceptInvitation] = useState(false);
  const [showRejectInvitation, setShowRejectInvitation] = useState(false);
  const invitationStatus = parseInt(status);

  const toggleShowAcceptInvitationModal = () => {
    setShowAcceptInvitation(!showAcceptInvitation);
  };

  const toggleShowRejectInvitationModal = () => {
    setShowRejectInvitation(!showRejectInvitation);
  };

  return (
    <>
      <span>
        {invitationStatus === 1 ? (
          <Button disabled>Accept</Button>
        ) : (
          <Button onClick={toggleShowAcceptInvitationModal}>Accept</Button>
        )}{" "}
        {invitationStatus === 2 ? (
          <Button disabled>Reject</Button>
        ) : (
          <Button onClick={toggleShowRejectInvitationModal}>Reject</Button>
        )}
        {showAcceptInvitation && (
          <AcceptInvitation
            id={id}
            handleInvitations={handleInvitations}
            showAcceptInvitation={showAcceptInvitation}
            toggleShowAcceptInvitationModal={toggleShowAcceptInvitationModal}
          />
        )}
        {showRejectInvitation && (
          <RejectInvitation
            id={id}
            handleInvitations={handleInvitations}
            showRejectInvitation={showRejectInvitation}
            toggleShowRejectInvitationModal={toggleShowRejectInvitationModal}
          />
        )}
      </span>
    </>
  );
};

export default InvitationsActionButton;
