import React from "react";
import BackButton from "../../components/Form/BackButton";
import { MonthRangePicker } from "@semcore/date-picker";
import { pastFinancialdate } from "../common/Misc";
import { useState } from "react";
import moment from "moment";

const today = new Date();

var disabledFutureDate = [];
for (let i = 1; i < 1000; i++) {
  disabledFutureDate.push(
    new Date(today.getFullYear(), today.getMonth() + i, 1)
  );
}

const ClientDashboard = () => {
  const [valueRange, setValueRange] = useState(pastFinancialdate);
  const monthRangeDisplay =
    moment(valueRange[0]).format("yyyy") ===
    moment(valueRange[1]).format("yyyy")
      ? `${moment(valueRange[0]).format("MMM")}-${moment(valueRange[1]).format(
          "MMM"
        )}, ${moment(valueRange[0]).format("yyyy")}`
      : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
          "yyyy"
        )} - ${moment(valueRange[1]).format("MMM")} ${moment(
          valueRange[1]
        ).format("yyyy")}`;

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <div className="page-title">
            <BackButton />
            Client Dashboard
          </div>
          <div className="dashboard-container">
            {/* {loading && <Spin />} */}

            <div className="row">
              <div className="card col-md-12">
                <>
                  <div className="summary-filter">
                    <MonthRangePicker
                      value={valueRange}
                      onChange={(date) => {
                        setValueRange(date);
                      }}
                      className="datefilter"
                      disabled={disabledFutureDate}
                    />
                  </div>
                </>
              </div>
              <div className="card col-md-8">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue Vs Expenses</h5>
                    </div>
                    {/* <div className="coa-charts">
                      <ResponsiveContainer
                        width={"100%"}
                        height={280}
                        debounce={50}
                      >
                        <BarChart
                          data={expRevChartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 45,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="month"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                          />
                          <YAxis />
                          <Tooltip content={<CustomExpRevTooltip />} />
                          <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            wrapperStyle={{
                              top: 250,
                            }}
                          />
                          <Bar dataKey="Revenue" barSize={20} fill="#012F4E" />
                          <Bar dataKey="Expenses" barSize={20} fill="#0DCAF0" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-md-4 active-cards">
                <div className="row">
                  <div
                    className="card col-md-6"
                    // onClick={() => {
                    //   navigate(`/business/${businessId}/contractors`);
                    // }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          {/* <h6></h6> */}
                          <h4>
                            0
                            {/* {contractorData?.count
                              ? contractorData?.count
                              : "0"} */}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    // onClick={() => {
                    //   navigate(`/business/${businessId}/customers`);
                    // }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          {/* <h6></h6> */}
                          <h4>
                            0
                            {/* {customerData?.count ? customerData?.count : "0"} */}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {customerData?.last_added
                            ? moment(
                                customerData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    // onClick={() => {
                    //   navigate(`/business/${businessId}/suppliers`);
                    // }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          {/* <h6></h6> */}
                          <h4>
                            0
                            {/* {supplierData?.count ? supplierData?.count : "0"} */}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {supplierData?.last_added
                            ? moment(
                                supplierData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-6"
                    // onClick={() => {
                    //   navigate(`/business/${businessId}/all-contacts`);
                    // }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          {/* <h6></h6> */}
                          <h4>
                            0
                            {/* {contactData?.count ? contactData?.count : "0"} */}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}
                          {/* {contactData?.last_added
                            ? moment(
                                contactData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Revenue</h5>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      {/* <ResponsiveContainer
                        width={"73%"}
                        height={400}
                        debounce={50}
                      >
                        <PieChart>
                          <Pie
                            data={revChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={3}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              const radius =
                                25 + innerRadius + (outerRadius - innerRadius);
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <text
                                  x={x}
                                  y={y}
                                  key={`cell-${index}`}
                                  fill={"#000000"}
                                  textAnchor={x > cx ? "start" : "end"}
                                  dominantBaseline="central"
                                >
                                  {value ? `${value}%` : "0.00"}
                                </text>
                              );
                            }}
                          >
                            {revChartData.map((entry, index) => (
                              <>
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              </>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForRev />} />
                        </PieChart>
                      </ResponsiveContainer> */}
                      <ul className="chart-legends">
                        {/* {revChartData &&
                          revChartData?.length > 0 &&
                          revChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {item?.name}
                              </p>
                            );
                          })} */}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "-17px",
                        float: "left",
                        width: "73%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {/* {totalRevenue.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Expenditures</h5>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period : </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      {/* <ResponsiveContainer
                        width={"73%"}
                        height={400}
                        debounce={50}
                      >
                        <PieChart>
                          <Pie
                            data={expChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={3}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              const radius =
                                25 + innerRadius + (outerRadius - innerRadius);
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <text
                                  x={x}
                                  y={y}
                                  key={`cell-${index}`}
                                  fill={"#000000"}
                                  textAnchor={x > cx ? "start" : "end"}
                                  dominantBaseline="central"
                                >
                                  {value ? `${value}%` : "0.00"}
                                </text>
                              );
                            }}
                          >
                            {expChartData.map((entry, index) => (
                              <>
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              </>
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForExp />} />
                        </PieChart>
                      </ResponsiveContainer> */}

                      <ul className="chart-legends">
                        {/* {expChartData &&
                          expChartData?.length > 0 &&
                          expChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {item?.name}
                              </p>
                            );
                          })} */}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "-17px",
                        float: "left",
                        width: "73%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {/* {totalExpenditures.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDashboard;
