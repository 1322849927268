import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import BuilderDetailsPopup from "./BuilderDetailsPopup";
import CustomTooltip from "../../../common/CustomTooltip";

const BuilderActionButton = ({ id }) => {
  const [showView, setShowView] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };
  return (
    <>
      <span>
        <CustomTooltip message={"View"}>
          <span>
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#clientDetail"
            />
          </span>
        </CustomTooltip>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip message={"Edit"}>
          <span>
            <Link to={{ pathname: `/builders/edit/${id}` }}>
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Link>
          </span>
        </CustomTooltip>
      </span>

      {showView && (
        <BuilderDetailsPopup
          showView={showView}
          builderId={id}
          toggleShowModal={toggleShowModal}
        />
      )}
    </>
  );
};

export default BuilderActionButton;
