import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EditProject from "../EditProject";
// import BusinessDelete from "./BusinessDelete";
import ProjectDetailsPopup from "./ProjectDetailsPopup";
import CustomTooltip from "../../../common/CustomTooltip";

const ProjectActionButton = ({
  id,
  handleDelete,
  builderId,
  projectModal,
  headProject,
  totalProject,
}) => {
  const [showView, setShowView] = useState(false);
  const [showProject, setShowProject] = useState(false);

  const toggleShowModal = () => {
    setShowView(!showView);
  };

  const toggleProjectModal = () => {
    showProject && projectModal();

    setShowProject(!showProject);
  };

  return (
    <>
      <span>
        <CustomTooltip message={"View"}>
          <span>
            <FontAwesomeIcon
              onClick={toggleShowModal}
              icon={faEye}
              size="1x"
              data-toggle="modal"
              data-target="#businessDetail"
            />
          </span>
        </CustomTooltip>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip message={"Edit"}>
          <span>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={toggleProjectModal}
              data-toggle="modal"
              data-target="#business"
            />
          </span>
        </CustomTooltip>
      </span>

      {showView && (
        <ProjectDetailsPopup
          projectId={id}
          toggleShowModal={toggleShowModal}
          showView={showView}
        />
      )}

      {showProject && (
        <EditProject
          projectId={id}
          showProject={showProject}
          builderId={builderId}
          toggleProjectModal={toggleProjectModal}
          headProject={headProject}
          totalProject={totalProject}
        />
      )}
    </>
  );
};

export default ProjectActionButton;
