import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import BankTransactionDelete from "./BankTransactionDelete";
import CustomTooltip from "../../../common/CustomTooltip";

const BankTransactionActionButton = ({
  id,
  handleDelete,
  reconciledStatus,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  // const [showView, setShowView] = useState(false);

  // const toggleShowModal = () => {
  //   setShowView(!showView);
  // };

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };
  return (
    <>
      {reconciledStatus !== 1 && (
        <span>
          <CustomTooltip message={"Delete"}>
            <span>
              <FontAwesomeIcon
                onClick={toggleDeleteModal}
                icon={faTrash}
                size="1x"
                data-toggle="modal"
                data-target="#deleteTransaction"
              />
            </span>
          </CustomTooltip>
        </span>
      )}

      {showDelete && (
        <BankTransactionDelete
          transactionId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
    </>
  );
};

export default BankTransactionActionButton;
