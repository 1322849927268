import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { Dropdown, DropdownButton } from "react-bootstrap";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  allBanks,
  allBankLoading,
  ProjectReadOnly,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    allBanks: PropTypes.any,
    allBankLoading: PropTypes.any,
    setLoading: PropTypes.any,
  };

  // function padWithLeadingZeros(num, totalLength) {
  //   return String(num).padStart(totalLength, '0');
  // }

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <h6>Withdrawal (General Business) Bank Account</h6>

      <div className="entry-card full-card myprofile-card">
        <div className="form-box" style={{ width: "100%" }}>
          {/* <div className="withdrawal-box"> */}
            <div className="form-group penta-width">
              <label>
                <span className="required">*</span>Bank Account Name
                <div className="apca-info info-text">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p>
                        Bank Account details of the head contractor where
                        beneficial interest / revenue goes
                      </p>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </label>
              <input
                type="text"
                name="withdrawal_account_name"
                value={
                  values?.withdrawal_account_name
                    ? values?.withdrawal_account_name
                    : ""
                }
                disabled={ProjectReadOnly}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="withdrawal_account_name" />
              </span>
            </div>
            <div className="form-group penta-width">
              <label>
                <span className="required">*</span>BSB
              </label>
              <InputMask
                type="text"
                name="withdrawal_bsb"
                value={values?.withdrawal_bsb ? values?.withdrawal_bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                disabled={ProjectReadOnly}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="withdrawal_bsb" />
              </span>
            </div>
            <div className="form-group penta-width">
              <label>
                <span className="required">*</span>Account Number
              </label>
              <InputMask
                type="text"
                name="withdrawal_account_number"
                value={
                  values?.withdrawal_account_number
                    ? values?.withdrawal_account_number
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                disabled={ProjectReadOnly}
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="withdrawal_account_number" />
              </span>
            </div>
            <div className="form-group penta-width">
              <label>
                <span className="required">*</span>Bank Name
              </label>
              <Select
                name="withdrawal_bank_id"
                value={
                  values?.withdrawal_bank_id ? values?.withdrawal_bank_id : ""
                }
                onChange={(e) => {
                  setFieldValue(`withdrawal_bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={allBanks}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={allBankLoading}
                autoComplete="off"
                isDisabled={ProjectReadOnly}
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="withdrawal_bank_id" />
              </span>
            </div>
            <div className="form-group penta-width">
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={values?.email ? values?.email : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={ProjectReadOnly}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="email" />
              </span>
            </div>
          {/* </div> */}
        </div>
      </div>
      <div
        className="entry-card full-card"
        style={{ clear: "left", float: "left", margin: 0 }}
      >
        {!ProjectReadOnly && (
          <div className="action-box">
            <button type="submit">Save</button>
          </div>
        )}
      </div>
    </form>
  );
};

export default Form;
