import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
const Congratulation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const redirectLogin = () => {
    if (location?.state?.islogin) {
      if (location?.state?.projectId) {
        navigate(
          `/projects/${location?.state?.projectId}/settings?tab=project-settings`,
          {
            replace: true,
          }
        );
      } else {
        navigate(
          "/user-settings?tab=subscriptions&childTab=current-subscription",
          { replace: true }
        );
      }
    } else {
      navigate("/");
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="congrats-block">
          <h3>Congratulations</h3>
          <p>{location?.state?.message ? location?.state?.message : ""}</p>
          <Button className="btn-primary btn" onClick={() => redirectLogin()}>
            {location?.state?.islogin ? "Let's Get Started" : "Login"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
