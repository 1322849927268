import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ProjectSubscription from "./ProjectSubscription";
import Spin from "../../../common/Spin";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../config";
import { useNavigate } from "react-router-dom";

const ProjectSubscriptionStatus = ({
  id,
  builderId,
  isSubscribe,
  fetchProjects,
  projectName,
}) => {
  const [showSubscription, setShowSubscription] = useState(false);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false);

  const navigate = useNavigate();

  // const userId = localStorage.getItem("userId");

  const toggleShowSubscription = () => {
    setShowSubscription(!showSubscription);
  };

  const toggleShowCancelSubscription = () => {
    setShowCancelSubscription(!showCancelSubscription);
  };

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);

    const formData = new FormData();
    if (id) {
      formData.append("builder_project_id", id);
    }

    await fileUpload(API.CANCEL_SUBSCRIPTION, formData)
      .then((data) => {
        setShowCancelSubscription(false);
        setCancelSubscriptionLoading(false);
        toast.success(data?.data?.message);
        fetchProjects();
      })
      .catch(() => {
        setShowCancelSubscription(false);
        setCancelSubscriptionLoading(false);
        toast.error("Something went wrong");
      });
  };
  return (
    <>
      {isSubscribe === 1 ? (
        <>
          {/* <span>
            <Button
              onClick={toggleShowCancelSubscription}
              className="project-subcription-button"
            >
              Cancel
            </Button>
          </span> */}
          <span>
            <Button
              onClick={() => {
                navigate(`/projects/${id}`, { replace: true });
              }}
              className="project-subcription-button"
            >
              Launch
            </Button>
          </span>
        </>
      ) : isSubscribe === 0 ? (
        <span>
          <Button
            onClick={toggleShowSubscription}
            className="project-subcription-button"
          >
            Renew
          </Button>
        </span>
      ) : (
        <span>
          <Button
            onClick={toggleShowSubscription}
            className="project-subcription-button"
          >
            Subscribe
          </Button>
        </span>
      )}
      {showSubscription && (
        <ProjectSubscription
          showSubscription={showSubscription}
          toggleShowSubscription={toggleShowSubscription}
          projectId={id}
          builderId={builderId}
          fetchProjects={fetchProjects}
        />
      )}

      {showCancelSubscription && (
        <Modal
          size="lg"
          show={showCancelSubscription}
          onHide={toggleShowCancelSubscription}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {cancelSubscriptionLoading && <Spin />}
          <Modal.Header className="mb-0" closeButton>
            Cancel Subscription
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              Are you sure you want to cancel the subscription of{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {projectName}
              </span>
              ?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={toggleShowCancelSubscription}
              >
                No
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => cancelSubscription()}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ProjectSubscriptionStatus;
