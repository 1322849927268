import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddEditOpeningBalance from "./AddEditOpeningBalance";
import { openingBalanceColumns } from "./OpeningBalanceHelper";
// import useOpeningBalanceList from "./useOpeningBalanceData";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../common/Pagination";
import OpeningActionButtons from "./OpeningBalanceActionButtons";
// import { getProjectData } from "../components/ProjectHelper";
import { formatNumber, formatSubcontractorName } from "../../common/Misc";
import { API, defaultLimitPagination, get, imageDefaultPrefixPath } from "../../../config";
import BackButton from "../../../components/Form/BackButton";
import Spin from "../../common/Spin";
import { toast } from "react-toastify";
import { useProjectBar } from "../../common/useProjectBar";
const OpeningBalance = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const params = useParams();
  const projectId = params?.project_id;
  const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;

  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [openingBalanceList, setOpeningBalanceList] = useState([]);
  const [totalOpeningBalanceData, setTotalOpeningBalanceData] = useState();
  const [isOpeningBalanceLoading, setIsOpeningBalanceLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [showFirm, setShowFirm] = useState(false);
  const [firmData, setFirmData] = useState({});

  const { ProjectName, ProjectLogo, ProjectReadOnly } = useProjectBar();

  let totalOpening = formatNumber(
    Math.abs(
      Number(totalOpeningBalanceData?.opening_balance) -
        Number(totalOpeningBalanceData?.running_balance)
    )
  );

  const fetchOpeningBalance = useCallback(async () => {
    try {
      setIsOpeningBalanceLoading(true);
      const { data } = await get(
        `${
          API.OPENING_BALANCE
        }?builder_project_id=${projectId}&trust_type=${trust_type}&page=${page}&limit=${limit}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}`
      );
      setIsOpeningBalanceLoading(false);

      setLimit(data?.data?.contractors?.per_page);
      setPage(data?.data?.contractors?.current_page);
      setTotal(data?.data?.contractors?.total);
      setFrom(data?.data?.contractors?.from);
      setTo(data?.data?.contractors?.to);
      const openingBalanceList = data.data.contractors.data;
      setOpeningBalanceList(openingBalanceList);
      setTotalOpeningBalanceData({
        opening_balance: trust_type
          ? data?.data?.pta_opening_balance
          : data?.data?.rta_opening_balance,
        ...data?.data,
      });
      return data.data;
    } catch (e) {
      const { data } = e;
      setIsOpeningBalanceLoading(false);
      setOpeningBalanceList([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [limit, page, projectId, sortField, sortOrder, trust_type]);

  useEffect(() => {
    fetchOpeningBalance();
  }, [fetchOpeningBalance]);

  const handleAddNew = () => {
    setShow(!show);
  };
  const handleDelete = () => {
    fetchOpeningBalance();
  };

  const handleClose = () => {
    setShow(!show);
    fetchOpeningBalance();
  };

  const handleEditFirm = (data) => {
    setFirmData(data);
    setShowFirm(true);
  };
  const handleCloseFirm = () => {
    setShowFirm(!showFirm);
    fetchOpeningBalance();
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const registeredUsersGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: openingBalanceList[i]?.id,
        id: openingBalanceList[i]?.id ? openingBalanceList[i]?.id : "",
        // name: (
        //   <>
        //     {openingBalanceList[i]?.first_name
        //       ? openingBalanceList[i]?.first_name +
        //         " " +
        //         openingBalanceList[i]?.last_name
        //       : openingBalanceList[i]?.business_name
        //       ? openingBalanceList[i]?.business_name
        //       : openingBalanceList[i]?.last_name}
        //   </>
        // ),
        name: (
          <>
            {formatSubcontractorName(openingBalanceList[i])}
          </>
        ),
        opening_balance: trust_type ? (
          openingBalanceList[i]?.opening_balance ? (
            <>
              {Number(openingBalanceList[i]) &&
              Math.sign(
                Number(openingBalanceList[i]?.opening_balance)?.toFixed(2)
              ) === -1
                ? "-" +
                  "$" +
                  formatNumber(
                    Number(openingBalanceList[i]?.opening_balance)
                      ?.toFixed(2)
                      .toString()
                      .slice(1)
                  )
                : "$" +
                  formatNumber(Number(openingBalanceList[i]?.opening_balance))}
            </>
          ) : (
            "$0.00"
          )
        ) : openingBalanceList[i]?.opening_balance_rta ? (
          <>
            {Number(openingBalanceList[i]) &&
            Math.sign(
              Number(openingBalanceList[i]?.opening_balance_rta)?.toFixed(2)
            ) === -1
              ? "-" +
                "$" +
                formatNumber(
                  Number(openingBalanceList[i]?.opening_balance_rta)
                    ?.toFixed(2)
                    .toString()
                    .slice(1)
                )
              : "$" +
                formatNumber(
                  Number(openingBalanceList[i]?.opening_balance_rta)
                )}
          </>
        ) : (
          "$0.00"
        ),

        action: (
          <OpeningActionButtons
            data={openingBalanceList[i]}
            id={openingBalanceList[i]?.id}
            type="customer"
            handleDelete={handleDelete}
          />
        ),
      });
    }
    return items;
  };
  const openingBalanceListData = registeredUsersGenerator(
    openingBalanceList?.length
  );

  useEffect(() => {
    navigate(
      `/projects/${projectId}/${trustType}/opening-balance?limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}`,
      {
        replace: true,
      }
    );
  }, [navigate, limit, page, sortField, sortOrder, projectId, trustType]);

  return (
    <div className="cms-page">
      {isOpeningBalanceLoading && <Spin />}
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trust_type === 1
              ? "Project Opening Balance"
              : "Retention Opening Balance"}

            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div
            className="content-details firm-trust"
            style={{ flexFlow: "wrap" }}
            // style={{ flexFlow: 'wrap', overflow: 'hidden' }}
          >
            <div className="firm-details">
              <div
                className={
                  formatNumber(totalOpeningBalanceData?.running_balance) ===
                  formatNumber(totalOpeningBalanceData?.opening_balance)
                    ? "firm-summary opening-balance opening-balance-card-matched"
                    : "firm-summary opening-balance opening-balance-card"
                }
              >
                <div className="customer-name opening-balance">
                  <h4 style={{ margin: 0 }}>
                    {ProjectName ? ProjectName : ""}
                  </h4>
                  {/* <h6 style={{ margin: 0 }}>
                    {totalOpeningBalanceData?.firm?.firm_email ? (
                      <a
                        className="mailto-tel"
                        href={`mailto:${totalOpeningBalanceData?.firm?.firm_email}`}
                      >
                        {totalOpeningBalanceData?.firm?.firm_email}
                      </a>
                    ) : (
                      ''
                    )}
                  </h6> */}
                </div>
                <div className="balance-details">
                  <div className="balance-block">
                    <div className="balance">
                      <h6>
                        {trust_type === 1
                          ? "Project Opening Balance"
                          : "Retention Opening Balance"}
                      </h6>
                      <h5>
                        {totalOpeningBalanceData?.opening_balance
                          ? "$" +
                            formatNumber(
                              totalOpeningBalanceData?.opening_balance
                            )
                          : "$0.00"}
                        <FontAwesomeIcon
                          icon={faEdit}
                          size="1x"
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            handleEditFirm(totalOpeningBalanceData);
                          }}
                        />
                      </h5>
                    </div>
                  </div>

                  <div className="balance-block">
                    <div className="running-balance">
                      <h6>Subcontractor&apos;s Cumulative Total</h6>
                      <h5>
                        {totalOpeningBalanceData?.running_balance
                          ? "$" +
                            formatNumber(
                              totalOpeningBalanceData?.running_balance
                            )
                          : "$0.00"}
                      </h5>
                    </div>
                  </div>
                  <div className="balance-block">
                    <div className="running-balance">
                      <h6>Difference(Yet To Enter)</h6>
                      <h5>
                        {totalOpening
                          ? "$" + formatNumber(totalOpening)
                          : "$0.00"}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="firm-details">
              <div className="firm-summary opening-balance">
                <div className="opening-balance-table">
                  <div className="table-top-btn">
                    {!ProjectReadOnly && (
                      <div className="table-btn">
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleAddNew();
                          }}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </div>
                  <BootstrapTable
                    keyField="id"
                    remote
                    data={openingBalanceListData}
                    columns={openingBalanceColumns(
                      handleDelete,
                      ProjectReadOnly
                    )}
                    onTableChange={handleTableChange}
                    noDataIndication="No Data Found"
                  />

                  <Pagination
                    total={total}
                    limit={parseInt(limit)}
                    currentPage={page}
                    updateLimit={handleUpdateLimit}
                    updatePage={handleChangePage}
                    from={from}
                    to={to}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="mid-modal supplier-modal opening-balance"
            id="opening_balance_modal"
            show={show}
            onHide={handleClose}
            dialogClassName="modal-50w larg-popup review-popup small-review"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Add Opening Balance</Modal.Header>
            <Modal.Body>
              <AddEditOpeningBalance
                data={{}}
                type="customer"
                onClose={handleClose}
              />
            </Modal.Body>
          </Modal>
          <Modal
            className="mid-modal supplier-modal"
            id="opening_balance_modal"
            show={showFirm}
            onHide={handleCloseFirm}
            dialogClassName="modal-50w larg-popup review-popup small-review"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>Opening Balance</Modal.Header>
            <Modal.Body>
              <AddEditOpeningBalance
                data={firmData}
                type="firm"
                onClose={handleCloseFirm}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default OpeningBalance;
