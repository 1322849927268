import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, defaultLimitPagination, fileUpload } from "../../../../config";
import Pagination from "../../../common/Pagination";
import Spin from "../../../common/Spin";
import { formatNumber } from "../../../common/Misc";

const ExpandedPLReport = ({
  projectId,
  id,
  row,
  startDate,
  endDate,
  balanceSheet = false,
  trust_type,
  accountingType,
  gstType = 0
}) => {
  const [expandPLLoading, setExpandPLLoading] = useState(false);
  const [expandPLData, setExpandPLData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const fetchPLExpandData = useCallback(async () => {
    const formData = new FormData();
    formData.append("chart_of_account_id", id);
    formData.append("builder_project_id", projectId);
    formData.append("trust_type", trust_type);
    trust_type === 1 && formData.append("accounting", accountingType);

    formData.append("start_date", startDate ? startDate : "");
    formData.append("end_date", endDate ? endDate : "");
    formData.append("export", 0);
    formData.append("limit", limit ? limit : defaultLimitPagination);
    formData.append("page", page ? page : 1);
    if(gstType){
      formData.append("gstType", gstType)
    }

    formData.append("sort_column", sortField ? sortField : "date");
    formData.append("sort_order", sortOrder ? sortOrder : "ASC");

    try {
      if (projectId) {
        setExpandPLLoading(true);
        const { data } = await fileUpload(
          `${
            balanceSheet
              ? API.GET_BALANCE_SHEET_ENTRIES_DATA
              : API.GET_PROFIT_AND_LOSS_ENTRIES_DATA
          }`,
          formData
        );
        setExpandPLLoading(false);
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setExpandPLData(data?.data?.data);
      }
    } catch (e) {
      const { data } = e;
      setExpandPLLoading(false);
      setExpandPLData([]);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [
    id,
    projectId,
    trust_type,
    accountingType,
    startDate,
    endDate,
    limit,
    page,
    sortField,
    sortOrder,
    balanceSheet,
    gstType
  ]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const PLDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      // let SplitDate = expandPLData[i]?.transaction_date?.split("-");
      // let date = `${SplitDate[2]}-${SplitDate[1]}-${SplitDate[0]}`;
      items.push({
        key: expandPLData[i]?.id,
        // transaction_id: expandPLData[i]?.bank_transaction?.transaction_id,
        account: `${
          expandPLData[i]?.chart_of_account?.code?.length < 4
            ? padWithLeadingZeros(
                Number(expandPLData[i]?.chart_of_account?.code?.length),
                4
              )
            : expandPLData[i]?.chart_of_account?.code
        }${
          expandPLData[i]?.chart_of_account?.sub_code
            ? `.${expandPLData[i]?.chart_of_account?.sub_code}`
            : ""
        }`,
        date: expandPLData[i]?.transaction_date,
        // source: expandPLData[i]?.source ? expandPLData[i]?.source : "-",
        description: expandPLData[i]?.description,
        tax_code:
          expandPLData[i]?.tax === 0
            ? "GST Excluded"
            : expandPLData[i]?.tax === 1
            ? "GST 10%"
            : expandPLData[i]?.tax === 2
            ? "BAS Excluded"
            : expandPLData[i]?.tax === 3
            ? "GST on Imports"
            : expandPLData[i]?.chart_of_account?.tax === 0
            ? "GST Excluded"
            : expandPLData[i]?.chart_of_account?.tax === 1
            ? "GST 10%"
            : expandPLData[i]?.chart_of_account?.tax === 2
            ? "BAS Excluded"
            : "GST on Imports",
        // amount: expandPLData[i]?.amount
        //   ? `$${formatNumber(expandPLData[i]?.amount)}`
        //   : "$0.00",
        net_amount: expandPLData[i]?.net_amount
          ? expandPLData[i]?.transaction_type === 1 && row?.key.includes('item-expense') ? `($${formatNumber(expandPLData[i]?.net_amount)})` : `$${formatNumber(expandPLData[i]?.net_amount)}`
          : "$0.00",
        // gst_amount: expandPLData[i]?.gst_amount
        //   ? `$${formatNumber(expandPLData[i]?.gst_amount)}`
        //   : "$0.00",
      });
    }
    return items;
  };
  const plData = PLDataGenerator(expandPLData?.length);

  const columns = [
    {
      dataField: "date",
      text: "Date",
      // sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "account",
      text: "Account",
      headerStyle: () => {
        return { width: "10%", textAlign: "center" };
      },
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "tax_code",
      text: "Tax Code",
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "net_amount",
      text: "Amount",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchPLExpandData();
  }, [fetchPLExpandData]);

  return (
    <>
      <div className="content-details">
        <div className="custom-table trial-balance-expand-table">
          {expandPLLoading && <Spin />}
          <BootstrapTable
            remote
            keyField="key"
            data={plData}
            columns={columns}
            noDataIndication="No Data Found"
            onTableChange={handleTableChange}
            // defaultSorted={defaultSorted}
          />
          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
    </>
  );
};

export default ExpandedPLReport;
