import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EditChartOfAccounting from "../EditChartOfAccounting";
import AccountingDelete from "./AccountingDelete";
import CustomTooltip from "../../../common/CustomTooltip";

const AccountingActionButton = ({ id, handleDelete, fetchAccountData }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const params = useParams();
  const projectId = params?.project_id;

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleAccountModal = () => {
    setShowAccount(!showAccount);
  };

  return (
    <>
      <span>
        <CustomTooltip message={"Edit"}>
          <span>
            <FontAwesomeIcon
              icon={faEdit}
              size="1x"
              onClick={toggleAccountModal}
              data-toggle="modal"
              data-target="#account"
            />
          </span>
        </CustomTooltip>
      </span>

      {showDelete && (
        <AccountingDelete
          accountingId={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
      {showAccount && (
        <EditChartOfAccounting
          accountingId={id}
          projectId={projectId}
          toggleAccountModal={toggleAccountModal}
          fetchAccountData={fetchAccountData}
          showAccount={showAccount}
        />
      )}
    </>
  );
};

export default AccountingActionButton;
