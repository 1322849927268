import React from "react";
import BackButton from "../components/Form/BackButton";
import Header from "../components/Header/Index";
import logo from "../assets/newimages/favicon.png";
import Sitelogo from "../components/Header/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const PartnerAgreement = () => {
  return (
    <>
      {localStorage.getItem("role") ? (
        <Header />
      ) : (
        <div className="header-blocks">
          <header>
            <Sitelogo />
            <div style={{ margin: "auto", marginRight: "15px" }}>
              <a href={`/`} style={{ marginRight: "15px", color: "white" }}>
                <FontAwesomeIcon icon={faUserCircle} />
                <span></span>&nbsp;&nbsp;Login
              </a>
            </div>
          </header>
          <div className="header-bottom-block"></div>
        </div>
      )}
      <div className="cms-page partner-agreement-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Partner Agreement
            </h1>

            <div className="content-details description-block">
              <h3>Introduction</h3>
              <p>
                This document constitutes a legally binding agreement between
                you (the “Partner” or “Provider,” collectively referred to as
                the “Participant(s)”) and us (“BuildTrust”). BuildTrust,{" "}
                <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://buildtrust.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  BuildTrust.com.au
                </a>
                , We, Us, Myna and Myna Australia Pty Ltd will be used
                interchangeably throughout and they all refer to the legal
                entity Myna Australia Pty Ltd, ACN/ABN 54 657 002 796. You/Your
                imply any user you authorise, your business and anyone you
                provide access to visit and use{" "}
                <img className="small-favicon" src={logo} alt="" />{" "}
                <a
                  href="http://buildtrust.com.au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  BuildTrust.com.au
                </a>{" "}
              </p>
              <p>
                It outlines the expectations of our partnership, including how
                we’ll collaborate and other vital elements of our business
                relationship. Although, it is a formal contract, we have aimed
                to make it straightforward and easy to understand.
              </p>
              <p>
                This agreement governs your involvement in our Software Partner
                Program (the &quot;Program&quot;) either as a Partner (Generally
                Accounting Firms) or as a Provider (Software Provider or White
                Label Service Recipient). You may only participate at one
                level—either as a Partner or a Provider—not both simultaneously.
                These terms are mandatory for participation, and by joining the
                Program, you agree to be bound by them.
              </p>
              <h3>Definitions</h3>
              <ul>
                <li>
                  <h5>Affiliate:</h5>
                  <p>
                    Refers to any entity that either controls, is controlled by,
                    or is under common control with the specified entity. For
                    this purpose, "control" means direct or indirect ownership
                    of more than 50% of the voting rights or equity interests in
                    the entity in question.
                  </p>
                </li>
                <li>
                  <h5>Agreement:</h5>
                  <p>
                    This refers to the BuildTrust Software Partner Program
                    Agreement, along with all relevant documents, policies, or
                    materials incorporated by reference.
                  </p>
                </li>
                <li>
                  <h5>Confidential Information:</h5>
                  <p>
                    Any data or information disclosed by one party (the
                    “Disclosing Party”) to the other (the “Receiving Party”)
                    that is marked or identified as confidential, either
                    verbally or in writing. Even if not explicitly labelled as
                    such, confidential information includes all proprietary data
                    regarding: (a) Clients, prospective Clients, and
                    Client-related data; (b) business strategies, marketing
                    plans, product designs, and technical specifications; (c)
                    financials, R&D efforts, and opportunities; except for any
                    information that (i) is already public without a breach,
                    (ii) was independently developed by the Receiving Party, or
                    (iii) was rightfully received from a third party.
                  </p>
                </li>
                <li>
                  <h5>Cross Sell:</h5>
                  <p>
                    A specific BuildTrust or Partner initiative allowing
                    Partners or Providers to receive Revenue Share from sales
                    involving a complementary BuildTrust or other Myna products
                    or services to existing Clients.
                  </p>
                </li>
                <li>
                  <h5>End User (Builder/Head Contractor or Subcontractor):</h5>
                  <p>
                    The ultimate recipient or authorized user of BuildTrust
                    services or products (e.g. Head Contractor / Builder).{" "}
                  </p>
                </li>
                <li>
                  <h5>Net Revenue:</h5>
                  <p>
                    The total monetary amounts paid by the End User or
                    Participant to BuildTrust for subscription services, minus
                    discounts, refunds, and applicable GST. This figure excludes
                    fees for services beyond the standard subscription.
                  </p>
                </li>
                <li>
                  <h5>Qualified Transaction:</h5>
                  <p>
                    A transaction eligible for Revenue Share, based on the
                    criteria established in the "Qualified Transactions" section
                    of this Agreement.
                  </p>
                </li>
                <li>
                  <h5>Revenue Share:</h5>
                  <p>
                    The percentage of Net Revenue due to the Partner or Provider
                    for facilitating a Qualified Transaction. The exact
                    percentage will depend on various factors as outlined in
                    this document as an annexure or communicated to you via
                    email. The most recent communication via email will override
                    all the previously communicated percentage share or
                    annexures.
                  </p>
                </li>
                <li>
                  <h5>Subscription Service:</h5>
                  <p>
                    The BuildTrust online platform, software and services,
                    accessed via designated URL (www.buildtrtust.com.au) that
                    includes all software and related offerings, excluding other
                    non-subscription products like White glove onboarding
                    services.
                  </p>
                </li>
              </ul>
              <h3>Non-Exclusivity</h3>
              <p>
                This Agreement does not grant exclusivity between you and
                BuildTrust. Both parties are free to engage with other partners
                or providers to promote or work with similar services and
                products, including third-party offerings.
              </p>
              <p>
                You may only participate in our Program as either a Partner
                (Generally Accounting Firms) or as a Provider (Software Provider
                or White Label Service Recipient) at any given time, but not
                both concurrently. Your participation in one does not guarantee
                acceptance or eligibility in the other program. Each program
                requires fulfillment of distinct criteria.
              </p>
              <h3>Your Rights and Obligations</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Rights of Partners and Providers</h5>
                  <p>
                    Subject to the terms set out in this Agreement, we grant you
                    a limited, non-transferable, non-exclusive license to:
                  </p>
                  <ol className="custom-list">
                    <li>
                      Promote and demonstrate BuildTrust Products to your
                      legitimate prospects and Clients.
                    </li>
                    <li>
                      Provide End Users (Builders, Head Contractors, or
                      Subcontractors) with access to BuildTrust Products,
                      provided these End Users agree to our Client Terms of Use.
                    </li>
                  </ol>
                  <p>
                    We may, at our discretion, offer sales support such as
                    participation in select Client calls. However, this support
                    is neither guaranteed nor obligatory on our part.
                  </p>
                </li>
                <li>
                  <h5>Rights of Partners and Providers</h5>
                  <p>
                    You are required to strictly adhere to the terms and
                    conditions laid out in this Agreement. If participating as a
                    Partner, compliance may include fulfilling obligations
                    related to your partner tier, such as purchasing by you or
                    your clients certain products or services from BuildTrust to
                    maintain eligibility.
                  </p>
                  <p>
                    If you access or use our developer tools, APIs, or related
                    software, you must follow the terms governing their use,
                    which will be available upon request.
                  </p>
                  <p>
                    Failure to comply with any terms contained in this document
                    or communicated via email may lead to termination of this
                    Agreement, as per the conditions set out in the Termination
                    section or any other applicable termination provisions.
                  </p>
                </li>
              </ol>
              <h3>Qualified Transactions</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Eligibility for Revenue Share</h5>
                  <p>
                    To qualify for Revenue Share, the transaction must meet the
                    criteria of a "Shared Subscription," registered via the
                    BuildTrust website. You are ineligible for Revenue Share for
                    transactions involving free trials, starter-level
                    subscriptions, read only licence, or sales of Other
                    Products.
                  </p>
                  <p>
                    Additionally, the following transactions do not qualify for
                    Revenue Share:
                  </p>
                  <ul className="custom-list">
                    <li>
                      Transactions involving outsourcing of trust accounting.{" "}
                    </li>
                    <li>
                      Transactions where the End User objects to or excludes
                      such commissions.
                    </li>
                    <li>
                      Transactions that occurred before your formal
                      participation in the Program or in cases where the End
                      User had pre-existing contracts with BuildTrust.
                    </li>
                  </ul>
                  <p>
                    Once a Shared Subscription qualifies as a legitimate
                    transaction, it becomes eligible for further cross-selling
                    or upselling by other Partners or Providers. However, you
                    will not receive compensation for any subsequent
                    transactions, including Cross-Sell or Upsell activities.
                  </p>
                </li>
                <li>
                  <h5>Subscription Registration</h5>
                  <p>
                    To create a Shared Subscription, you must submit a
                    registration form through our designated BuildTrust page and
                    subscribe to a project on any paid plans. BuildTrust
                    reserves the right to accept or reject any Subscription at
                    its sole discretion, prior to the transaction’s closure. A
                    Subscription will generally be accepted if, in BuildTrust's
                    reasonable assessment, the prospect:{" "}
                  </p>
                  <ol className="custom-list">
                    <li>
                      Is a new potential Client or qualifies for an upselling or
                      cross-selling opportunity.
                    </li>
                    <li>Is not an Affiliate of yours.</li>
                    <li>
                      Represents a Legitimate Prospect with valid and properly
                      obtained contact information.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>Engagement with Prospects and End Users</h5>
                  <p>
                    BuildTrust reserves the right to engage directly or
                    indirectly through contractors with prospects or End Users
                    you submit or refer. This may occur to facilitate sales
                    processes, fulfill contractual obligations, provide support,
                    or engage in marketing activities. We may also request your
                    collaboration during these interactions.
                  </p>
                  <p>
                    In cases where you place an order on behalf of an End User,
                    you will be responsible for the purchase agreement and its
                    associated terms. However, in cross-sell scenarios, End
                    Users must contract directly with BuildTrust. Regardless of
                    the method of sale or contracting entity, every End User
                    must agree to our Client{" "}
                    <a href="/terms-of-use" target="_blank" rel="noreferrer">
                      Terms of Use{" "}
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://buildtrust.com.au/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policies
                    </a>
                    , and you must ensure compliance with these terms by the End
                    User.
                  </p>
                </li>
              </ol>
              <h3>Revenue Share and Payment</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Eligibility for Payment; Forfeiture of Revenue Share</h5>
                  <p>
                    To be eligible to receive Revenue Share payments under this
                    Agreement, you must meet the following conditions:
                  </p>
                  <ol className="custom-list">
                    <li>
                      {" "}
                      You have agreed to and complied with all terms of this
                      Agreement.{" "}
                    </li>
                    <li>
                      {" "}
                      You or your clients have provided complete and accurate
                      account information, including valid bank details
                      necessary for payment.{" "}
                    </li>
                    <li>
                      {" "}
                      You have submitted all required documents, including GST
                      invoices where applicable, and received approval for these
                      documents from us.{" "}
                    </li>
                  </ol>
                  <p>
                    Revenue Share payments are processed quarterly. You must
                    submit all necessary documentation no later than thirty (30)
                    days after the end of the fiscal quarter in which a
                    Qualified Transaction occurred. Failure to do so may delay
                    your payment until the following quarter.
                  </p>
                  <p>
                    If the required documentation or bank details remain
                    incomplete for six (6) months following the close of a
                    Qualified Transaction, or if a payment attempt is
                    unsuccessful due to incorrect information, you forfeit your
                    right to receive any Revenue Share for that transaction. We
                    will make reasonable efforts to notify you of incomplete or
                    invalid information, but your failure to respond or correct
                    the issue within this six-month period will result in
                    permanent forfeiture.
                  </p>
                </li>
                <li>
                  <h5>Payment Timing and Method</h5>
                  <p>
                    Revenue Share payments will be made within forty-five (45)
                    days after the end of each fiscal quarter, based on the Net
                    Revenue from Qualified Transactions recognized during that
                    quarter. Payments will be made via bank transfer only in
                    AUD. Revenue Share payments will not be processed until the
                    amount due to you exceeds $300 AUD.
                  </p>
                </li>
                <li>
                  <h5>GST</h5>
                  <p>
                    You are responsible for any applicable taxes on Revenue
                    Share payments. Unless you provide valid documentation
                    indicating otherwise (GST Exemption), we will assess and add
                    GST or any other applicable taxes on the Revenue Share
                    amount.
                  </p>
                </li>
                <li>
                  <h5>Payment Obligations to BuildTrust</h5>
                  <p>
                    In cases where you have placed an order and contracted
                    directly with BuildTrust on behalf of an End User, you are
                    responsible for providing valid payment information,
                    including credit card or bank account details. You authorize
                    BuildTrust to charge the provided account for all fees due
                    under this Agreement, and we may use a third-party payment
                    processor to facilitate these payments.
                  </p>
                  <p>
                    Invoices are payable within thirty (30) days of issuance. If
                    payments become delinquent, BuildTrust reserves the right to
                    suspend access to the BuildTrust Products, charge
                    reactivation fees, or directly communicate with the End User
                    to collect payment. You remain fully responsible for all
                    fees due, regardless of your ability to collect payments
                    from the End User.
                  </p>
                </li>
              </ol>
              <h3>Training and Support</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Partner Training and Support</h5>
                  <p>
                    We will offer you access to various training resources and
                    support tools without charge. These resources may include
                    webinars and instructional materials made available through
                    your BuildTrust portal or youtube or other channels or via
                    email. However, we reserve the right to modify or
                    discontinue these tools at any time without notice.
                  </p>
                </li>
                <li>
                  <h5>End User Training and Support</h5>
                  <p>
                    In some cases, End Users may be required to undergo
                    BuildTrust’s onboarding services, including paid user
                    training (white glove onboarding). BuildTrust will provide
                    such training under terms mutually agreed upon with the End
                    User.
                  </p>
                  <p>
                    We may also communicate directly with End Users to help with
                    their use of BuildTrust Products and to address support
                    issues.
                  </p>
                </li>
                <li>
                  <h5> BuildTrust Demo Account</h5>
                  <p>
                    If you are provided with a BuildTrust Demo Account, it is
                    for your personal use in demonstrating and evaluating
                    BuildTrust Products. You may not lease, license, or
                    otherwise commercially exploit this account and not show it
                    to other software providers. Use of the BuildTrust Demo
                    Account is subject to the terms outlined in the Client Terms
                    of Use and is limited to either your own data or demo data
                    provided by BuildTrust for demonstration purposes.
                  </p>
                  <p>
                    We reserve the right to modify, suspend, or discontinue
                    access to the BuildTrust Demo Account at any time without
                    notice.
                  </p>
                </li>
              </ol>
              <h3>Optional Beta Programs</h3>
              <p>
                From time to time, we may offer you access to additional tools,
                beta testing opportunities, or special promotions ("Optional
                Programs"). Participation in these programs is entirely
                voluntary, and by opting in, you grant BuildTrust the necessary
                permissions to carry out activities related to the Optional
                Programs.
              </p>
              <p>
                If an Optional Program involves the promotion of BuildTrust
                products or services, you agree to:
              </p>
              <ol className="custom-list">
                <li>
                  Promote the program only to your legitimate and registered
                  prospects.
                </li>
                <li>
                  Conduct individual, targeted promotions, without engaging in
                  mass marketing or spamming.{" "}
                </li>
                <li>
                  Comply with all other terms and conditions associated with the
                  specific Optional Program as communicated by BuildTrust.{" "}
                </li>
              </ol>
              <p>
                BuildTrust reserves the right to modify or discontinue any
                Optional Program at its discretion and may impose additional
                terms on your participation, which will be provided for your
                review when applicable.
              </p>
              <h3>Trademarks</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Your Use of BuildTrust Trademarks</h5>
                  <p>
                    During the term of this Agreement, we grant you a limited,
                    non-transferable, non-exclusive right to use Myna Australia
                    Pty Ltd or BuildTrust trademarks and logos (collectively,
                    “BuildTrust IP”), subject to the following conditions:
                  </p>
                  <ol className="custom-list">
                    <li>
                      You may only use the images of BuildTrust Marks that we
                      provide to you through the Program, without altering them
                      in any way.
                    </li>
                    <li>
                      Your use of BuildTrust Marks must be strictly in
                      connection with your participation in the Program and in
                      compliance with this Agreement.
                    </li>
                    <li>
                      You must immediately discontinue the use of BuildTrust
                      Marks if we request it.
                    </li>
                  </ol>
                  <p>
                    You are prohibited from using any of BuildTrust’s
                    trademarks:
                  </p>
                  <ul className="custom-list">
                    <li>In a misleading, disparaging, or defamatory manner.</li>
                    <li>
                      Outside the scope of the Program or in a way that implies
                      BuildTrust endorses, sponsors, or approves of your
                      services, unless expressly permitted.
                    </li>
                    <li>In violation of any applicable law or regulation.</li>
                  </ul>
                </li>
                <li>
                  <h5>Ownership of Trademarks</h5>
                  <p>
                    We retain all rights, title, and interest in the BuildTrust
                    Marks. You do not acquire any ownership or additional rights
                    through your participation in this Agreement, and you agree
                    not to challenge the validity or ownership of BuildTrust’s
                    intellectual property (IP).
                  </p>
                </li>
              </ol>
              <h3>Intellectual Property (IP) Rights</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>BuildTrust’s Proprietary Rights</h5>
                  <p>
                    No license to any BuildTrust software is granted under this
                    Agreement, except for the limited rights provided herein.
                    The BuildTrust Products, along with all associated
                    intellectual property, remain the exclusive property of
                    BuildTrust and/or its licensors.
                  </p>
                  <p>
                    You agree that you will not copy, modify, distribute, sell,
                    or create derivative works from any BuildTrust content or
                    services, except as explicitly allowed in writing by us.
                    Unauthorized use or distribution of BuildTrust Products
                    constitutes a violation of this Agreement and applicable
                    intellectual property laws.
                  </p>
                </li>
                <li>
                  <h5>End User Proprietary Rights</h5>
                  <p>
                    The End User retains ownership of all data submitted or
                    generated within their portal as part of using BuildTrust
                    Products. BuildTrust acknowledges that such data remains the
                    sole property of the End User, and we may, in certain
                    circumstances, transfer ownership of the BuildTrust portal
                    back to the End User if deemed necessary by BuildTrust or
                    QBCC in exceptional circumstances like disciplinary actions.
                  </p>
                  <p>
                    You are responsible for ensuring the proper use of End User
                    data, and you must ensure that all participants in the End
                    User portal adhere to the applicable user permissions, as
                    determined by the End User.
                  </p>
                </li>
                <li>
                  <h5>User Participation Disclosure</h5>
                  <p>
                    BuildTrust portals may involve multiple Partners or
                    Providers. Information about each participant may be visible
                    to other users within the same portal, and such data will
                    not be considered confidential between participants. It is
                    the End User’s responsibility to control data visibility and
                    access within their BuildTrust portal.
                  </p>
                </li>
              </ol>
              <h3>Confidentiality</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Obligations of the Receiving Party</h5>
                  <p>
                    The Receiving Party (the party receiving confidential
                    information) agrees to:
                  </p>
                  <ol className="custom-list">
                    <li>
                      Exercise the same level of care in protecting the
                      Confidential Information of the Disclosing Party (the
                      party providing confidential information) as it uses for
                      its own confidential information, but no less than a
                      reasonable standard of care.
                    </li>
                    <li>
                      Only use Confidential Information for purposes related to
                      this Agreement.
                    </li>
                    <li>
                      Not disclose Confidential Information to third parties
                      without the prior written consent of the Disclosing Party.
                    </li>
                    <li>
                      Limit access to Confidential Information to employees,
                      agents, or contractors who need such access to fulfill the
                      obligations under this Agreement, and who are bound by
                      confidentiality obligations no less stringent than those
                      contained herein.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>Permitted Disclosures</h5>
                  <p>
                    The Receiving Party may disclose Confidential Information if
                    required by law, court order, or government regulation,
                    provided that:
                  </p>
                  <ol className="custom-list">
                    <li>
                      The Receiving Party promptly notifies the Disclosing Party
                      of such requirement, allowing the Disclosing Party an
                      opportunity to seek a protective order, unless prohibited
                      by law.
                    </li>
                    <li>
                      The disclosure is limited to the minimum amount of
                      information required to comply with the legal mandate.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>Injunctive Relief</h5>
                  <p>
                    Both parties acknowledge that unauthorized disclosure or use
                    of Confidential Information may cause irreparable harm. In
                    such cases, the injured party is entitled to seek immediate
                    injunctive relief, in addition to any other legal remedies
                    available.
                  </p>
                </li>
              </ol>
              <h3>Termination</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Term</h5>
                  <p>
                    This Agreement remains in effect for as long as you
                    participate in the Program and meet all eligibility
                    requirements.
                  </p>
                </li>
                <li>
                  <h5>Termination Without Cause</h5>
                  <p>
                    Either party may terminate this Agreement by providing
                    thirty (30) days’ written notice to the other party, without
                    the need to provide a reason.
                  </p>
                </li>
                <li>
                  <h5>Termination Due to Agreement Changes</h5>
                  <p>
                    If BuildTrust makes changes to the terms of this Agreement,
                    you may terminate the Agreement within seven (7) days by
                    providing written notice to BuildTrust. Failure to provide
                    notice within this period will be deemed acceptance of the
                    new terms.
                  </p>
                </li>
                <li>
                  <h5>Termination for Cause</h5>
                  <p>
                    BuildTrust reserves the right to terminate this Agreement or
                    suspend your access to BuildTrust Products immediately under
                    the following circumstances:
                  </p>
                  <ol className="custom-list">
                    <li>
                      You fail to remedy a material breach within thirty (30)
                      days after receiving notice.
                    </li>
                    <li>
                      You do not meet the Program eligibility criteria within
                      thirty (30) days of being notified of non-compliance.
                    </li>
                    <li>
                      Non-payment of fees, if not resolved within fifteen (15)
                      days after receiving notice of non-payment.
                    </li>
                    <li>
                      You become subject to bankruptcy, insolvency, or any
                      similar legal proceedings.
                    </li>
                    <li>
                      You or your End User violates BuildTrust’s Terms of Use or
                      applicable laws.
                    </li>
                    <li>
                      You breach your confidentiality obligations or infringe
                      upon BuildTrust’s intellectual property rights.
                    </li>
                    <li>
                      BuildTrust determines that your conduct negatively impacts
                      its reputation, prospects, or Clients or Myna Australia
                      Pty Ltd business.
                    </li>
                  </ol>
                </li>
                <li>
                  <h5>Effects of Termination</h5>
                  <p>
                    Termination of this Agreement does not affect any
                    Subscription Services already purchased by an End User, nor
                    does it impact your obligation to pay outstanding fees to
                    BuildTrust.
                  </p>
                  <p>If this Agreement is terminated:</p>
                  <ul className="custom-list">
                    <li>
                      Without cause by BuildTrust or with cause by you, you may
                      still receive Revenue Share for transactions completed
                      before termination, provided the End User’s payment is
                      recognized within thirty (30) days post-termination.
                    </li>
                    <li>
                      With cause by BuildTrust or without cause by you, any
                      outstanding Revenue Share entitlements will be forfeited.
                    </li>
                  </ul>
                  <p>
                    Upon termination, you must cease all use of BuildTrust
                    trademarks and delete all BuildTrust-related data, including
                    leads and shared leads, unless you have obtained proper
                    consent to retain such data.
                  </p>
                </li>
              </ol>
              <h3>Security Requirements</h3>
              <p>
                As a BuildTrust Software Partner, you have a responsibility to
                effectively secure access to your BuildTrust accounts and
                protect your clients’ accounts from unauthorized access. Your
                Clients trust that you will act with care to protect their data
                and their Clients’ data. It is critical that as a partner,
                effective security measures are top of mind and adequately
                enforced in order to build and maintain Client trust, and also,
                your reputation as a partner. BuildTrust has and will continue
                to add enforcement measures on security features so that all
                partners are required to adhere to and prioritize the security
                of their Clients. By keeping security top of mind and leaning
                into enforcement, we can all do our part in protecting Clients'
                data from potential security risks, such as account takeovers,
                fraud, and others. Below we’ve outlined some core tenets for
                partners to aid in securing your work in both your partner
                account and clients’ accounts.
              </p>
              <h3>Core Principles</h3>
              <ul className="custom-list">
                <li>
                  In partner and client accounts, create and maintain unique
                  accounts for each user within your organization (no account
                  sharing, please!).
                </li>
                <li>
                  Enable Two Factor Authentication (2FA) for all user accounts
                  in your BuildTrust Account(s).
                </li>
                <li>
                  When adding users to client accounts, obtain written approval
                  from the client on new accounts and access to their account.
                </li>
                <li>
                  In partner and client accounts, implement the principle of
                  “least privilege” access for partner user accounts in Client
                  accounts.
                </li>
                <li>
                  When individuals leave your organization or change roles,
                  remove their access from your partner account and client
                  accounts.
                </li>
                <li>
                  When your agreement with the client concludes, remove your
                  access from client accounts.
                </li>
                <li>
                  Respect your clients’ (e.g. Builders / Head Contractors) and
                  their Clients’ (e.g. subcontractors) data. Always comply with
                  data protection laws, and never share or use their data for
                  your own benefit.
                </li>
              </ul>
              <h3>General Provisions</h3>
              <ol className="custom-list sub-heading" type="a">
                <li>
                  <h5>Amendments and Waivers</h5>
                  <p>
                    BuildTrust may update or amend this Agreement at any time by
                    posting the revised version on its website. The changes will
                    take effect on the next business day following the posting.
                    If you disagree with the changes, you have the option to
                    terminate the Agreement, as outlined above. A delay or
                    failure to exercise any right or remedy does not constitute
                    a waiver. Any waiver must be explicitly stated in writing.
                  </p>
                </li>
                <li>
                  <h5>Governing Law</h5>
                  <p>
                    This Agreement is governed by the laws of New South Wales,
                    Australia, without regard to conflicts of law principles.
                    Any disputes arising from or relating to this Agreement will
                    be subject to the exclusive jurisdiction of the courts of
                    New South Wales.
                  </p>
                </li>
                <li>
                  <h5>Force Majeure</h5>
                  <p>
                    Neither party will be liable for any failure or delay in
                    performance due to causes beyond their reasonable control,
                    including natural disasters, war, sabotage,
                    telecommunications failures, or governmental restrictions.
                    The affected party will make reasonable efforts to mitigate
                    the impact of such events.
                  </p>
                </li>
                <li>
                  <h5>Assignment</h5>
                  <p>
                    You may not assign or transfer this Agreement, including by
                    merger, acquisition, or operation of law, without
                    BuildTrust’s prior written consent. BuildTrust reserves the
                    right to assign this Agreement to any Affiliate or
                    successor.
                  </p>
                </li>
                <li>
                  <h5>Severance</h5>
                  <p>
                    If any provision of this Agreement is found to be invalid or
                    unenforceable, the remaining provisions will continue in
                    full force and effect. The invalid provision will be
                    replaced with a valid provision that closely approximates
                    the intent of the original.
                  </p>
                </li>
                <li>
                  <h5>Notices</h5>
                  <p>
                    Notices under this Agreement must be in writing and sent to
                    the designated addresses of the parties. Notices may be sent
                    electronically, via email, or through in-app notifications
                    for convenience, and will be considered delivered upon
                    receipt.
                  </p>
                </li>
                <li>
                  <h5>Entire Agreement</h5>
                  <p>
                    This Agreement, including all policies incorporated by
                    reference, constitutes the entire agreement between you and
                    BuildTrust regarding the subject matter hereof and
                    supersedes all prior agreements, understandings, or
                    communications, whether written or oral.
                  </p>
                </li>
                <li>
                  <h5>Non-Solicitation</h5>
                  <p>
                    For the duration of this Agreement, and for twelve (12)
                    months after its termination, neither party will actively
                    solicit the employees or contractors of the other. Public
                    job postings or general recruitment efforts that are not
                    targeted will not be considered a violation of this
                    provision.
                  </p>
                </li>
                <li>
                  <h5>Survival</h5>
                  <p>
                    Provisions concerning confidentiality, proprietary rights,
                    indemnification, limitations of liability, and payment
                    obligations shall survive the termination of this Agreement.
                  </p>
                </li>
              </ol>
              <h3>Annexures</h3>
              <h3>Annexure 1: Revenue Share</h3>
              <table>
                <thead>
                  <tr>
                    <th>Tier</th>
                    <th>Concurrent Subscriptions</th>
                    <th>Revenue Share</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Silver</td>
                    <td>Up to 5</td>
                    <td>
                      <Link to="https://buildtrust.com.au/contact-us/" target="_blank">
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Gold</td>
                    <td>6 to 10</td>
                    <td>
                      {" "}
                      <Link to="https://buildtrust.com.au/contact-us/" target="_blank">
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Platinum</td>
                    <td>Above 10</td>
                    <td>
                      {" "}
                      <Link to="https://buildtrust.com.au/contact-us/" target="_blank">
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerAgreement;
