import { API, get } from "../../../../config";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";

export const getContractorDetail = async (contractorId) => {
  try {
    const { data } = await get(`${API.CONTRACTOR_DETAILS}/` + contractorId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const searchContractorPTADetail = async (type, projectID) => {
  try {
    const { data } = await get(
      `${
        type === "withdrawal"
          ? API.GET_WITHDRAWAL_DETAILS +
            "/withdrawal?builder_project_id=" +
            projectID
          : type === "principal"
          ? API.PRINCIPAL + "/" + projectID
          : ""
      }`
    );
    return type === "withdrawal"
      ? data.data
      : type === "principal"
      ? data.data.principal_contractor
      : null;
  } catch (e) {
    return null;
  }
};

export const getContractorPopupDetail = async (
  contractorId,
  isRecentActivity
) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.CONTRACTOR_DETAILS}/${contractorId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.CONTRACTOR_DETAILS}/${contractorId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useSuburbList = (search) => {
  const [suburbList, setSuburbList] = useState([]);
  const [suburbLoading, setSuburbLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      if (search) {
        setSuburbLoading(true);
        const { data } = await get(
          `${API.GET_SUBURB_LIST}?search=${search ? search : ""}`
        );
        setSuburbLoading(false);
        setSuburbList(data.data.data);
      }
    } catch (e) {
      setSuburbList([]);
      setSuburbLoading(false);
    }
  }, [search]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    suburbList,
    suburbLoading,
  };
};

export const useBankList = () => {
  const [bankList, setBankList] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setBankLoading(true);
      const { data } = await get(`${API.GET_BANK_LIST}?all=1`);
      setBankLoading(false);
      const accountList =
        data?.data?.data && data?.data?.data?.length > 0
          ? data?.data?.data?.map((item) => {
              const list = {
                label: item?.abbreviation + "-" + item?.name,
                value: item?.id,
                rest: item,
              };
              return list;
            })
          : [];
      setBankList(accountList);
    } catch (e) {
      setBankList([]);
      setBankLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    bankList,
    bankLoading,
  };
};
