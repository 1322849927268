import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Sitelogo from "../../components/Header/Logo";
import { API, post } from "../../config";
import { toast } from "react-toastify";
import { ForgotPasswordSchema } from "../common/Validation";
import Spin from "../common/Spin";
import SlideToggle from "react-slide-toggle";
import { useEffect } from "react";
import wFavicon from "../../assets/images/myna-white-favicon.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ success: "", error: "" });

  const userId = localStorage.getItem("userId");
  const EmailVerification = localStorage.getItem("EmailVerification");
  const hasToken = localStorage.getItem("bt-auth-token");
  const clientId = localStorage.getItem("clientId");

  // *API Call for ForgotPassword - Start
  const handleFormSubmit = async (value) => {
    try {
      setMessage({});
      setLoading(true);
      const { data } = await post(API.FORGOT_PASSWORD, value);
      if (data.status === 200) {
        setMessage({ success: data.message });
        setLoading(false);
      } else {
        setLoading(false);
        setMessage({ error: data.errors?.bt_error[0] });
      }
    } catch (e) {
      setLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  useEffect(() => {
    userId &&
      EmailVerification === "1" &&
      hasToken &&
      (clientId ? navigate("/businesses") : navigate("/builders"));
  }, [userId, EmailVerification, hasToken, clientId, navigate]);
  // *API Call for ForgotPassword - End

  return (
    <>
      <div className="login-screen">
        <div className="welcome-block">
          <Sitelogo />
          <div
            className="footer-link"
            style={{ maxWidth: "100%", color: "#CCC", padding: "0 30px" }}
          >
            <p style={{ fontSize: "12px" }}>
              <img className="small-white-favicon" src={wFavicon} alt="" />{" "}
              BuildTrust acknowledges the Traditional Custodians of country
              throughout Australia and their connections to land, sea and
              community. We pay our respect to their Elders past and present and
              extend that respect to all Aboriginal and Torres Strait Islander
              peoples today.
            </p>
          </div>
        </div>
        <div className="form-block">
          <Formik
            initialValues={{
              email: "",
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validationSchema={ForgotPasswordSchema}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-title-para">
                  Enter email address to receive an email to reset your password
                </div>
                <div className="form-group">
                  <input
                    placeholder="Email Address"
                    type="text"
                    name="email"
                    value={values?.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="form-control"
                    required
                  />
                </div>
                {loading && <Spin />}
                <div className="text-danger" style={{ paddingTop: "10px" }}>
                  {errors?.email}
                </div>
                {message.error && (
                  <p className="text-danger">{message.error}</p>
                )}
                {message.success && (
                  <p className="text-success">{message.success}</p>
                )}
                <div>
                  <button
                    type="submit"
                    className="button"
                    style={{ marginTop: "20px", marginRight: "10px" }}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn-nolink"
                    style={{ width: "100%", marginTop: "10px" }}
                    onClick={() => navigate("/")}
                  >
                    Back to Login
                  </button>
                </div>
              </form>
            )}
          </Formik>

          <div className="footer-link">
            <p>
              Trouble Logging In?{" "}
              <a
                href="https://buildtrust.com.au/contact-us/"
                rel="noreferrer"
                target="_blank"
              >
                Contact Us
              </a>
            </p>
            <p>
              By selecting Login, you agree to our{" "}
              <a href="/terms-of-use" rel="noreferrer" target="_blank1">
                Terms of Use
              </a>{" "}
              and have read and acknowledge our{" "}
              <a
                href="https://buildtrust.com.au/privacy-policy/"
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <SlideToggle>
            {({ toggle, setCollapsibleElement }) => (
              <div className="my-collapsible">
                <i
                  className="fa fa-info my-collapsible__toggle"
                  onClick={toggle}
                ></i>
                <div
                  className="my-collapsible__content"
                  ref={setCollapsibleElement}
                >
                  <div className="my-collapsible__content-inner">
                    <p style={{ fontSize: "12px" }}>
                      <img
                        className="small-white-favicon"
                        src={wFavicon}
                        alt=""
                      />{" "}
                      BuildTrust acknowledges the Traditional Custodians of
                      country throughout Australia and their connections to
                      land, sea and community. We pay our respect to their
                      Elders past and present and extend that respect to all
                      Aboriginal and Torres Strait Islander peoples today.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </SlideToggle>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
