import React, { useCallback, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import Spin from "../../common/Spin";
import { MonthRangePicker } from "@semcore/date-picker";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  checkIfFileExists,
  formatNumber,
  pastFinancialdate,
} from "../../common/Misc";
import { Dropdown } from "react-bootstrap";
import ExpandedTrialBalance from "./components/ExpandedTrialBalance";
import { getProjectData } from "../components/ProjectHelper";
import BackButton from "../../../components/Form/BackButton";
import ReactSelect from "react-select";

const COATrialBalanceReport = ({
  dateRangeReport,
  setDateRangeReport,
  setAccountingType,
  accountingOptions,
  accountingType,
}) => {
  const params = useParams();
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const projectId = params?.project_id;
  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);
  const [clientsReportData, setClientsReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [exportTrialBalanceReportLoading, setExportTrialBalanceReportLoading] =
    useState(false);
  const [valueRange, setValueRange] = useState(
    dateRangeReport ? dateRangeReport : pastFinancialdate
  );

  const monthRangeDisplay =
    moment(valueRange[0]).format("yyyy") ===
    moment(valueRange[1]).format("yyyy")
      ? moment(valueRange[0]).format("MMM") ===
        moment(valueRange[1]).format("MMM")
        ? `${moment(valueRange[0]).format("MMM")} ${moment(
            valueRange[0]
          ).format("yyyy")}`
        : `${moment(valueRange[0]).format("MMM")}-${moment(
            valueRange[1]
          ).format("MMM")}, ${moment(valueRange[0]).format("yyyy")}`
      : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
          "yyyy"
        )} - ${moment(valueRange[1]).format("MMM")} ${moment(
          valueRange[1]
        ).format("yyyy")}`;

  const getClientsReportData = useCallback(async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        "start_month",
        moment(valueRange[0]).format("MM-YYYY")
          ? moment(valueRange[0]).format("MM-YYYY")
          : ""
      );
      formData.append(
        "end_month",
        moment(valueRange[1]).format("MM-YYYY")
          ? moment(valueRange[1]).format("MM-YYYY")
          : ""
      );
      formData.append("export", 0);
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);

      trust_type === 1 && formData.append("accounting", accountingType);

      const { data } = await fileUpload(
        `${API.GET_COA_TRIAL_BALANCE_DATA}`,
        formData
      );
      let totalDebit = 0;
      let totalCredit = 0;
      let customerReportData = [];
      if (data?.data && data?.data?.length > 0) {
        customerReportData = data?.data?.map((item) => {
          let obj = {
            ...item,
          };
          if (item?.transaction_amount > 0) {
            totalDebit += Number(item?.transaction_amount);
          } else {
            totalCredit += Number(item?.transaction_amount * -1);
          }
          return obj;
        });
      }
      let totalData = {
        total_name: "Net Movement",
        total_debit_amount: totalDebit,
        total_credit_amount: totalCredit,
      };
      setClientsReportData([...[].concat(customerReportData), totalData]);
      setIsLoading(false);
    } catch (error) {
      const { data } = error.response;
      setClientsReportData([]);
      setIsLoading(false);
      toast.error(
        data &&
          data.errors &&
          data.errors.myna_error &&
          data.errors.myna_error[0]
      );
    }
  }, [valueRange, projectId, trust_type, accountingType]);

  useEffect(() => {
    getClientsReportData();
  }, [getClientsReportData]);

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const trialBalanceDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: clientsReportData[i]?.total_name
          ? clientsReportData[i]?.total_name
          : clientsReportData[i]?.net_name
          ? clientsReportData[i]?.net_name
          : clientsReportData[i]?.chart_of_account?.id
          ? clientsReportData[i]?.chart_of_account?.id +
            String(clientsReportData[i]?.type)
          : "not-expand-row",
        batch_id: clientsReportData[i]?.total_name ? (
          <div>2</div>
        ) : clientsReportData[i]?.chart_of_account?.id ? (
          clientsReportData[i]?.chart_of_account?.id
        ) : (
          ""
        ),
        // is_bank_reconciliation: clientsReportData[i]?.is_bank_reconciliation,
        // is_cash_transaction: clientsReportData[i]?.is_cash_transaction,
        code: clientsReportData[i]?.net_name
          ? clientsReportData[i]?.net_name
          : clientsReportData[i]?.total_name
          ? clientsReportData[i]?.total_name
          : clientsReportData[i]?.chart_of_account?.code
          ? `${
              clientsReportData[i]?.chart_of_account?.code?.length < 4
                ? padWithLeadingZeros(
                    Number(clientsReportData[i]?.chart_of_account?.code),
                    4
                  )
                : clientsReportData[i]?.chart_of_account?.code
            }${
              clientsReportData[i]?.chart_of_account?.sub_code
                ? `.${clientsReportData[i]?.chart_of_account?.sub_code}`
                : ""
            }`
          : "",
        account: clientsReportData[i]?.chart_of_account?.name
          ? // clientsReportData[i]?.is_bank_reconciliation === 0 ? (
            //   <>
            //     {clientsReportData[i]?.name}(<span className="required">*</span>)
            //   </>
            // ) : (
            clientsReportData[i]?.chart_of_account?.name
          : // )
            "",
        account_type: clientsReportData[i]?.chart_of_account?.account_type?.name
          ? clientsReportData[i]?.chart_of_account?.account_type?.name
          : "",
        type: clientsReportData[i]?.type,
        credit: clientsReportData[i]?.total_credit_amount ? (
          `$${formatNumber(clientsReportData[i]?.total_credit_amount)}`
        ) : clientsReportData[i]?.total_credit_amount === 0 ? (
          "$0.00"
        ) : clientsReportData[i]?.transaction_amount < 0 ? (
          clientsReportData[i]?.transaction_amount ? (
            <span>
              {`$${formatNumber(
                Number(clientsReportData[i]?.transaction_amount * -1)
              )}`}
            </span>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
        debit: clientsReportData[i]?.total_debit_amount ? (
          `$${formatNumber(clientsReportData[i]?.total_debit_amount)}`
        ) : clientsReportData[i]?.total_debit_amount === 0 ? (
          "$0.00"
        ) : clientsReportData[i]?.transaction_amount > 0 ? (
          clientsReportData[i]?.transaction_amount ? (
            <span>
              {`$${formatNumber(
                Number(clientsReportData[i]?.transaction_amount)
              )}`}
            </span>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
      });
    }
    return items;
  };
  const trialBalanceData = trialBalanceDataGenerator(clientsReportData?.length);

  const columns = [
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "account",
      text: "Account",
    },
    {
      dataField: "account_type",
      text: "Account Type",
      classes: "trial-balance-account-cell",
    },
    {
      dataField: "debit",
      text: "Debit",
      align: "right",
      classes: "trial-balance-debit-cell",
      headerAlign: (column, colIndex) => "right",
    },
    {
      dataField: "credit",
      text: "Credit",
      align: "right",
      classes: "trial-balance-credit-cell",
      headerAlign: (column, colIndex) => "right",
    },
  ];

  let currentDate = new Date();
  function getQuarter(d) {
    d = d || new Date();
    var m = Math.floor(d.getMonth() / 3) + 2;
    return m > 4 ? m - 4 : m;
  }
  let quarter = getQuarter(currentDate);
  let quarterRange = [];
  if (quarter === 1) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 2) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else if (quarter === 3) {
    quarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  } else {
    quarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  }

  let perviousQuarterRange = [];
  if (quarter === 1) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 6, 1),
      new Date(currentDate.getFullYear(), 9, 0),
    ];
  } else if (quarter === 2) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 9, 1),
      new Date(currentDate.getFullYear(), 12, 0),
    ];
  } else if (quarter === 3) {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 0, 1),
      new Date(currentDate.getFullYear(), 3, 0),
    ];
  } else {
    perviousQuarterRange = [
      new Date(currentDate.getFullYear(), 3, 1),
      new Date(currentDate.getFullYear(), 6, 0),
    ];
  }

  const dateFilterRange = [
    {
      children: "This Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      ],
    },
    {
      children: "This Quarter",
      value: quarterRange,
    },
    {
      children: "This Financial Year",
      value: [
        new Date(currentDate.getFullYear(), 6, 1),
        new Date(currentDate.getFullYear() + 1, 6, 0),
      ],
    },
    {
      children: "Last Month",
      value: [
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
      ],
    },
    {
      children: "Last Quarter",
      value: perviousQuarterRange,
    },
    {
      children: "Last Financial Year",
      value: [
        new Date(currentDate.getFullYear() - 1, 6, 1),
        new Date(currentDate.getFullYear(), 6, 0),
      ],
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <>
        {row.code === "3380" ? (
          <ExpandedTrialBalance
            id={row?.batch_id}
            projectId={projectId}
            trust_type={trust_type}
            accountingType={accountingType}
            startMonth={
              moment(valueRange[0]).format("MM-YYYY")
                ? moment(valueRange[0]).format("MM-YYYY")
                : ""
            }
            endMonth={
              moment(valueRange[1]).format("MM-YYYY")
                ? moment(valueRange[1]).format("MM-YYYY")
                : ""
            }
            code="3380"
            getClientsReportData={getClientsReportData}
            valueRange={valueRange}
            baseType={row.type === "debit" ? "0" : "1"}
            is_bank_reconciliation={row?.is_bank_reconciliation}
            is_cash_transaction={row?.is_cash_transaction}
          />
        ) : (
          <ExpandedTrialBalance
            id={row?.batch_id}
            projectId={projectId}
            trust_type={trust_type}
            accountingType={accountingType}
            startMonth={
              moment(valueRange[0]).format("MM-YYYY")
                ? moment(valueRange[0]).format("MM-YYYY")
                : ""
            }
            endMonth={
              moment(valueRange[1]).format("MM-YYYY")
                ? moment(valueRange[1]).format("MM-YYYY")
                : ""
            }
            getClientsReportData={getClientsReportData}
            valueRange={valueRange}
            code={row.code}
            // baseType={row.type}
            is_bank_reconciliation={row?.is_bank_reconciliation}
            is_cash_transaction={row?.is_cash_transaction}
          />
        )}
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: ["Net Movement", "Net Profit", "not-expand-row"],
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <i className="fa fa-caret-down"></i>
          </b>
        );
      }
      return (
        <b>
          <i className="fa fa-caret-right"></i>
        </b>
      );
    },
  };

  const handleExportTrialBalanceReport = async (format, reportType) => {
    try {
      setExportTrialBalanceReportLoading(true);
      const formData = new FormData();
      formData.append(
        "start_month",
        moment(valueRange[0]).format("MM-YYYY")
          ? moment(valueRange[0]).format("MM-YYYY")
          : ""
      );
      formData.append(
        "end_month",
        moment(valueRange[1]).format("MM-YYYY")
          ? moment(valueRange[1]).format("MM-YYYY")
          : ""
      );
      formData.append("export", 1);
      formData.append("format", format);
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);

      trust_type === 1 && formData.append("accounting", accountingType);

      const { data } = await fileUpload(
        `${API.GET_COA_TRIAL_BALANCE_DATA}`,
        formData
      );
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute(
          "download",
          file[file.length - 1] || "trial_balance_report.pdf"
        );
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setExportTrialBalanceReportLoading(false);
      }
    } catch (e) {
      setExportTrialBalanceReportLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trust_type === 0
              ? "Financial Reports (RTA)"
              : "Financial Reports (PTA)"}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          <div className="content-details trial-balance-report-details">
            <div className="table-top-btn trial-btn report-header-row">
              <div className="report-name report-period-name">
                <span>
                  {" "}
                  Trial Balance For the period {monthRangeDisplay} (Net
                  Movement)
                </span>
              </div>
              <div className="table-btn">
                <Dropdown autoClose="outside" style={{ marginRight: "15px" }}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {exportTrialBalanceReportLoading
                      ? "Loading…"
                      : "Export Trial Balance"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleExportTrialBalanceReport("pdf")}
                    >
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleExportTrialBalanceReport("xlsx")}
                    >
                      Excel
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>HandiLedger</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "handiLedger"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "handiLedger"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Dropdown
                        variant="primary"
                        drop="end"
                        autoClose="outside"
                      >
                        <Dropdown.Toggle>Final Accounts</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "pdf",
                                "finalAccounts"
                              )
                            }
                          >
                            PDF
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleExportTrialBalanceReport(
                                "xlsx",
                                "finalAccounts"
                              )
                            }
                          >
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="summary-filter"
                style={{
                  padding: "0",
                  border: "none",
                  marginTop: "0px",
                  marginLeft: 20,
                }}
              >
                <MonthRangePicker
                  value={valueRange}
                  onChange={(date) => {
                    setClientsReportData([]);
                    if (date) {
                      setValueRange(date);
                      if (date[0]?.getMonth() === date[1]?.getMonth()) {
                        setDateRangeReport([
                          date[0],
                          new Date(
                            date[1]?.getUTCFullYear(),
                            date[1]?.getMonth() + 1,
                            0
                          ),
                        ]);
                      } else {
                        setDateRangeReport([
                          date[0],
                          new Date(
                            date[1]?.getUTCFullYear(),
                            date[1]?.getMonth() + 1,
                            0
                          ),
                        ]);
                      }
                    }
                  }}
                  periods={dateFilterRange}
                  unclearable={true}
                  className="datefilter"
                  // disabled={disabledFutureDate}
                />
              </div>
              {trust_type === 1 && (
                <div
                  style={{
                    marginLeft: 15,
                  }}
                >
                  <ReactSelect
                    isSearchable={false}
                    placeholder="Select Accounting Type"
                    loading={accountingOptions.length === 0 ? true : false}
                    value={
                      accountingOptions?.find(
                        (item) => item?.value === parseInt(accountingType)
                      )
                        ? accountingOptions?.find(
                            (item) => item?.value === parseInt(accountingType)
                          )
                        : ""
                    }
                    onChange={(id) => {
                      if (id?.value !== accountingType) {
                        setClientsReportData([]);
                      }
                      setAccountingType(id?.value);
                    }}
                    className="selectpicker contactname-select"
                    classNamePrefix="chartofselect"
                    options={accountingOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "5px",
                      colors: {
                        ...theme.colors,
                        primary: "grey",
                      },
                    })}
                  />
                </div>
              )}
            </div>

            <div className="table-wrapper trial-balance-table">
              <div className="custom-table">
                {isLoading && <Spin />}
                <BootstrapTable
                  keyField="key"
                  remote
                  columns={columns}
                  expandRow={expandRow}
                  data={trialBalanceData}
                  noDataIndication="No Data Found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COATrialBalanceReport;
