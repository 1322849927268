import React, { useCallback, useEffect, useState } from "react";
import Spin from "../common/Spin";
import { Button, Modal } from "react-bootstrap";
import proCoreLogo from "../../assets/images/procore.svg";
import {
  disconnectProCore,
  disconnectXero,
  fetchAccountConnectionData,
} from "../Project/settings/integrations/components/IntegrationsHelper";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import XeroLogo from "../../assets/images/xero-logo.png";
import { API, fileUpload } from "../../config";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProCoreVerfication = ({
  projectSection = false,
  ProjectReadOnly,
  projectData,
}) => {
  const [procoreData, setProcoreData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");

  const [xeroData, setXeroData] = useState(null);
  const [xeroShow, setXeroShow] = useState(false);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const getCode = query.get("code") || "";
  const builderId = localStorage.getItem("builderId")
    ? localStorage.getItem("builderId")
    : projectData?.builder_id
    ? projectData?.builder_id
    : "";
  const crmType = localStorage.getItem("platform");

  const toggleProCoreDisconnectModal = () => {
    setShow(!show);
  };

  const toggleXeroDisconnectModal = () => {
    setXeroShow(!xeroShow);
  };

  const fetchProCoreConnectionData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchAccountConnectionData(builderId);
      setProcoreData(
        response?.procore_integration_data
          ? response?.procore_integration_data
          : ""
      );
      setXeroData(
        response?.xero_integration_data ? response?.xero_integration_data : ""
      );
      setIsLoading(false);
    } catch (e) {
      setProcoreData(null);
      setIsLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  }, [builderId]);

  const ProCoreOAuth = useCallback(async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("code", getCode);
      formData.append("builder_id", builderId);
      const { data } = await fileUpload(API.PROCORE_OAUTH, formData);
      if (data?.status === 200) {
        setIsLoading(false);
        toast.success(data?.message);
        navigate("?tab=integrations");
        fetchProCoreConnectionData();
      }
    } catch (e) {
      setIsLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCode]);

  const xeroOAuth = useCallback(async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("code", getCode);
      formData.append("builder_id", builderId);
      const { data } = await fileUpload(API.XERO_OAUTHREDIRECT, formData);
      if (data?.status === 200) {
        setIsLoading(false);
        toast.success(data?.message);
        navigate("?tab=integrations");
        fetchProCoreConnectionData();
      }
    } catch (e) {
      setIsLoading(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCode]);

  useEffect(() => {
    fetchProCoreConnectionData();
  }, [fetchProCoreConnectionData]);

  useEffect(() => {
    if (projectSection) {
      navigate("?tab=integrations");
    } else {
      navigate(
        `/general-settings?tab=integrations${getCode && "&code=" + getCode}`,
        {
          replace: true,
        }
      );
    }

    if (getCode) {
      if (crmType === "procore" && procoreData?.connection_status === 0) {
        ProCoreOAuth();
      }
      if (crmType === "xero") {
        xeroOAuth();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    getCode,
    ProCoreOAuth,
    projectSection,
    xeroOAuth,
    crmType,
    procoreData?.connection_status,
    xeroData?.connection_status,
  ]);

  const handleProCoreConnection = async () => {
    if (procoreData?.connection_status === 0) {
      if (procoreData?.oauth_url) {
        window.location.href = procoreData?.oauth_url;
        localStorage.setItem(
          "refresh_token_procore",
          procoreData?.refresh_token_procore
        );
      }
    } else {
      toggleProCoreDisconnectModal();
    }
  };

  const handleProCoreDisconnect = async () => {
    setLoading(true);
    toggleProCoreDisconnectModal();
    const procoreDisconnect = await disconnectProCore();
    setLoading(false);
    procoreDisconnect?.message && toast.success(procoreDisconnect?.message);
    fetchProCoreConnectionData();
  };

  const handleXeroDisconnect = async () => {
    setLoading(true);
    toggleXeroDisconnectModal();
    const xeroDisconnect = await disconnectXero(builderId);
    setLoading(false);
    xeroDisconnect?.message && toast.success(xeroDisconnect?.message);
    fetchProCoreConnectionData();
  };

  const handleXeroEdit = async () => {
    if (xeroData?.oauth_url) {
      window.location.href = xeroData?.oauth_url;
    }
  };

  const handleXeroConnection = async () => {
    if (xeroData?.connection_status === 0) {
      if (xeroData?.oauth_url) {
        window.location.href = xeroData?.oauth_url;
      }
    } else {
      toggleXeroDisconnectModal();
    }
  };
  return (
    <>
      {isLoading && <Spin />}
      <div className="integration-card">
        <h3 className="crm-heading">CRM &amp; Other Systems</h3>
        <div className="card">
          <div className="card-body">
            <div className="crm-img">
              <img src={proCoreLogo} alt="procore_image" />
            </div>
            <p>Procore</p>
            <Button
              className="btn"
              onClick={() => {
                handleProCoreConnection();
                // procoreData?.connection_status === 0 &&
                localStorage.setItem("platform", "procore");
              }}
              disabled={
                ProjectReadOnly ||
                (role && roleType === "Builder" ? false : true)
              }
            >
              {procoreData?.connection_status === 0 ? "Connect" : "Disconnect"}
            </Button>
          </div>
        </div>
        <div className="card xero-card">
          <div className="card-body">
            <div className="crm-img">
              <img src={XeroLogo} alt="procore_image" />
            </div>
            <p>Xero</p>
            {xeroData?.connection_status === 1 && xeroData?.tenant ? (
              <p className="xero-org">
                <b>ORG</b>: {xeroData?.tenant}
              </p>
            ) : (
              <></>
            )}
            <div className="xero-action">
              {xeroData?.connection_status === 1 &&
                role &&
                roleType === "Builder" && (
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="xero-edit"
                    onClick={async () => {
                      handleXeroEdit();
                      localStorage.setItem("platform", "xero");
                    }}
                    disabled={ProjectReadOnly}
                  />
                )}
              <Button
                className="btn"
                onClick={() => {
                  handleXeroConnection();
                  // xeroData?.connection_status === 0 &&
                  localStorage.setItem("platform", "xero");
                }}
                disabled={
                  ProjectReadOnly ||
                  (role && roleType === "Builder" ? false : true)
                }
              >
                {xeroData?.connection_status === 0 ? "Connect" : "Disconnect"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={toggleProCoreDisconnectModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
        >
          <Modal.Header className="mb-0" closeButton>
            REQUEST
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <p>
                Do you want to disconnect <b>{procoreData?.tenant}</b>?
              </p>
              <div className="modal-footer">
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => {
                    toggleProCoreDisconnectModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={handleProCoreDisconnect}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {xeroShow && (
        <Modal
          size="lg"
          show={xeroShow}
          onHide={toggleXeroDisconnectModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
        >
          <Modal.Header className="mb-0" closeButton>
            REQUEST
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              <p>
                Do you want to disconnect <b>{xeroData?.tenant}</b>?
              </p>
              <div className="modal-footer">
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => {
                    toggleXeroDisconnectModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={handleXeroDisconnect}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ProCoreVerfication;
