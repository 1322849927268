import { toast } from "react-toastify";
import { API, get } from "../../../../config";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

export const getUserPopupDetail = async (userId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_BUILDER_USER}/${userId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_BUILDER_USER}/${userId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const useOfficeList = (builderId) => {
  const [officeList, setOfficeList] = useState([]);
  const [officeLoading, setOfficeLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setOfficeLoading(true);
      const { data } = await get(
        `${API.GET_BUILDER_OFFICE}?page=1&limit=-1${
          builderId ? "&builder_id=" + builderId : ""
        }`
      );
      setOfficeLoading(false);
      setOfficeList(data.data.data);
    } catch (e) {
      setOfficeList([]);
      setOfficeLoading(false);
    }
  }, [builderId]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    officeList,
    officeLoading,
  };
};

export const useRoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [roleLoading, setRoleLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setRoleLoading(true);
      const { data } = await get(`${API.GET_ROLE_LIST}?page=1&limit=-1&type=Builder&status=1`);
      setRoleLoading(false);
      setRoleList(data.data.data);
    } catch (e) {
      setRoleList([]);
      setRoleLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    roleList,
    roleLoading,
  };
};

export const getUserDetail = async (userId) => {
  try {
    const { data } = await get(`${API.BUILDER_USER}/` + userId);
    return data.data;
  } catch (e) {
    return null;
  }
};


export const useProjectList = (builderId) => {
  const [projectList, setProjectList] = useState([]);
  const [projectLoading, setProjectLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setProjectLoading(true);
      const { data } = await get(
        `${API.GET_PROJECT}?page=1&limit=-1${
          builderId ? "&builder_id=" + builderId : ""
        }`
      );
      setProjectLoading(false);
      setProjectList(data.data.data);
    } catch (e) {
      setProjectList([]);
      setProjectLoading(false);
    }
  }, [builderId]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    projectList,
    projectLoading,
  };
};