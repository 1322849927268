import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { API, get } from "../../../../config";

export const getOfficeDetail = async (officeId) => {
  try {
    const { data } = await get(`${API.BUILDER_OFFICE}/` + officeId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const getOfficePopupDetail = async (officeId, isRecentActivity) => {
  try {
    if (isRecentActivity) {
      const { data } = await get(`${API.GET_BUILDER_OFFICE}/${officeId}`);
      return data.data;
    } else {
      const { data } = await get(`${API.GET_BUILDER_OFFICE}/${officeId}`);
      return data.data;
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};

export const useUserList = (builderId) => {
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);

  const init = useCallback(async () => {
    try {
      setUserLoading(true);
      const { data } = await get(
        `${API.GET_BUILDER_USER}?page=1&limit=-1${
          builderId ? "&builder_id=" + builderId : ""
        }`
      );
      setUserLoading(false);
      setUserList(data.data.data);
    } catch (e) {
      setUserList([]);
      setUserLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    userList,
    userLoading,
  };
};

export const useProjectList = (builderId) => {
  const [projectList, setProjectList] = useState([]);
  const [projectLoading, setProjectLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setProjectLoading(true);
      const { data } = await get(
        `${API.GET_PROJECT}?page=1&limit=-1${
          builderId ? "&builder_id=" + builderId : ""
        }`
      );
      setProjectLoading(false);
      setProjectList(data.data.data);
    } catch (e) {
      setProjectList([]);
      setProjectLoading(false);
    }
  }, [builderId]);

  useEffect(() => {
    init();
  }, [init]);

  return {
    projectList,
    projectLoading,
  };
};