import {
  // faEdit,
  faTrash,
  faDownload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteDocumentModal from "./DeleteDocumentModal";

import React, { useState } from "react";
import CustomTooltip from "../../../../common/CustomTooltip";
// import { useParams } from "react-router-dom";

const DocumentListActionButton = ({
  id,
  handleDownload,
  handleView,
  handleDelete,
}) => {
  const [showDelete, setShowDelete] = useState(false);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  return (
    <>
      <span>
        <CustomTooltip message={"View"}>
          <span>
            <FontAwesomeIcon
              icon={faEye}
              size="1x"
              onClick={() => handleView()}
              data-toggle="modal"
              data-target="#account"
            />
          </span>
        </CustomTooltip>
        <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
        <CustomTooltip message={"Download"}>
          <span>
            <FontAwesomeIcon
              onClick={() => handleDownload()}
              icon={faDownload}
              size="1x"
              data-toggle="modal"
              data-target="#deleteAccount"
            />
          </span>
        </CustomTooltip>
      </span>

      {handleDelete ? (
        <>
          &nbsp;&nbsp;/&nbsp;&nbsp;
          <span>
            <CustomTooltip message={"Delete"}>
              <span>
                <FontAwesomeIcon
                  onClick={toggleDeleteModal}
                  icon={faTrash}
                  size="1x"
                  data-toggle="modal"
                  data-target="#deleteAccount"
                />
              </span>
            </CustomTooltip>
          </span>
        </>
      ) : (
        ""
      )}
      {showDelete && (
        <DeleteDocumentModal
          DocumentID={id}
          toggleDeleteModal={toggleDeleteModal}
          handleDelete={handleDelete}
          showDelete={showDelete}
        />
      )}
      {/* {showAccount && (
        <EditContractorTypes
          fetchContractorTypes={fetchContractorTypes}
          ContractorTypeId={id}
          toggleContractorTypeModal={toggleContractorTypeModal}
          showAccount={showAccount}
        />
      )} */}
    </>
  );
};

export default DocumentListActionButton;
