import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload, get } from "../../config";
import Spin from "../common/Spin";
import { useNavigate } from "react-router-dom";
import { encrypt, formatNumber, formatPhone } from "../common/Misc";
import ProjectSubscriptionStatus from "../Builders/Projects/components/ProjectSubscriptionStatus";
import { debounce } from "underscore";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../common/Pagination";

const SubscriptionDetails = ({ setActiveTab, project, projectId, builder }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [searchParam, setSearchParam] = useState("");
  const [searchParamData, setSearchParamData] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] =
    useState(false);

  const fetchSubscriptionDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(
        `${API.GET_SUBSCRIPTION_DETAILS}${
          projectId ? "?builder_project_id=" + projectId : ""
        }`
      );
      setIsLoading(false);
      localStorage.setItem("bt-subscription", encrypt("1"));
      if(data?.data?.supportPlan?.support_plan){
        data.data.supportPlan = [data?.data?.supportPlan];
      }
      setSubscriptionDetail(data?.data ? data?.data : {});
      return data.data;
    } catch (error) {
      setSubscriptionDetail();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsLoading(false);
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    }
  };

  const fetchSubscriptionListDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await get(
        `${API.GET_SUBSCRIPTION_LIST_DETAILS}?limit=${
          limit ? limit : 10
        }&page=${page ? page : 1}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }${builder ? "&builder_id=" + localStorage.getItem("builderId") : ""}`
      );
      setIsLoading(false);
      localStorage.setItem("bt-subscription", encrypt("1"));
      setSubscriptionDetail(data?.data ? data?.data : {});
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      return data.data;
    } catch (error) {
      setSubscriptionDetail();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setIsLoading(false);
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (project) {
      fetchSubscriptionDetails();
    } else {
      fetchSubscriptionListDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelSubscription = async () => {
    setCancelSubscriptionLoading(true);

    const formData = new FormData();
    if (projectId) {
      formData.append("builder_project_id", projectId);
    }

    await fileUpload(API.CANCEL_SUBSCRIPTION, formData)
      .then(() => {
        setShowModal(false);
        setCancelSubscriptionLoading(false);
        fetchSubscriptionDetails();
      })
      .catch(() => {
        setShowModal(false);
        setCancelSubscriptionLoading(false);
        toast.error("Something went wrong");
      });
  };

  const handleonRedirect = () => {
    setActiveTab("more-packages");
    if (builder) {
      navigate(`/general-settings?tab=subscriptions&childTab=more-packages`, {
        replace: true,
      });
    } else if (project) {
      navigate(
        `/projects/${projectId}/settings?tab=subscriptions&childTab=more-packages`,
        {
          replace: true,
        }
      );
    } else {
      navigate("/user-settings?tab=subscriptions&childTab=more-packages", {
        replace: true,
      });
    }
  };

  // *Projects Table Row and Column Generation, Filtering and Sorting of Record - Start
  const projectGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        builder_id: subscriptionDetail[i]?.builder_id,
        key: subscriptionDetail[i]?.id,
        builder_name: subscriptionDetail[i]?.builder_name,
        project_id: subscriptionDetail[i]?.id,
        project_name: subscriptionDetail[i]?.project_name,
        email: subscriptionDetail[i]?.email
          ? subscriptionDetail[i]?.email
          : "-",
        phone: subscriptionDetail[i]?.phone
          ? formatPhone(subscriptionDetail[i]?.phone)
          : "-",
        subscribe: (
          <ProjectSubscriptionStatus
            id={subscriptionDetail[i]?.id}
            builderId={subscriptionDetail[i]?.builder_id}
            isSubscribe={subscriptionDetail[i]?.subscription?.status}
            fetchProjects={fetchSubscriptionListDetails}
            projectName={subscriptionDetail[i]?.project_name}
          />
        ),
      });
    }
    return items;
  };

  const subData = projectGenerator(subscriptionDetail?.length);

  const SubscriptionListColumns = localStorage.getItem("builderId")
    ? [
        {
          dataField: "project_name",
          text: "Project Name",
          sort: true,
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "phone",
          text: "Phone No.",
          sort: true,
        },
        {
          dataField: "subscribe",
          text: "Subscription",
          align: "center",
          headerAlign: "center",
          style: { width: "127px" },
        },
      ]
    : [
        {
          dataField: "builder_name",
          text: "Builder Name",
          sort: true,
        },
        {
          dataField: "project_name",
          text: "Project Name",
          sort: true,
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "phone",
          text: "Phone No.",
          sort: true,
        },
        {
          dataField: "subscribe",
          text: "Subscription",
          align: "center",
          headerAlign: "center",
          style: { width: "127px" },
        },
      ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

// eslint-disable-next-line react-hooks/exhaustive-deps
const debouncedSave = useCallback(
  debounce((nextValue) => {
    setSearchParam(nextValue);
    setPage(1);
  }, 300),
  []
);

const handleSearchChange = (event) => {
  const { value: nextValue } = event.target;
  setSearchParamData(nextValue);
  debouncedSave(nextValue);
};

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Projects Table Row and Column Generation, Filtering and Sorting of Record - End

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : project ? (
        <>
          {subscriptionDetail?.subscriptions?.length === 0 || !subscriptionDetail ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                padding: "0px 15px",
              }}
            >
              <h5>You have no Subscription</h5>
              <Button
                type="primary"
                className="reset"
                onClick={handleonRedirect}
              >
                Subscribe Now
              </Button>
            </div>
          ) : (
            <>
              {subscriptionDetail?.subscriptions?.map(
                (subscriptionDetail, index) => (
                  <div
                    className="row border-box subcription-detail-box"
                    key={index}
                  >
                    <>
                      <h5 className="subscription-detail-body-title">
                        Subscription Details{" "}
                        <span className="sub-status">
                          Status:{" "}
                          {subscriptionDetail?.status && (
                            <i className="active">
                              {subscriptionDetail?.status}
                            </i>
                          )}
                        </span>
                      </h5>
                      <div
                        className="subscription-main-body"
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        {subscriptionDetail?.status === "canceled" && (
                          <>
                            <div className="col-md-4">
                              <p>
                                <label>Last day to access:</label>{" "}
                                {subscriptionDetail?.next_payment_date}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <Button
                                type="primary"
                                className="reset"
                                onClick={handleonRedirect}
                              >
                                Subscribe Now
                              </Button>
                            </div>
                          </>
                        )}
                        {subscriptionDetail?.status === "trialing" && (
                          <>
                            <div className="col-lg-2 col-md-3 ml-5 detail-box">
                              <p style={{ margin: "0" }}>
                                <label>Plan: </label>{" "}
                                {subscriptionDetail?.plan_type}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>Subtotal :</label> $
                                {/* {formatNumber(
                            Number(subscriptionDetail?.current_plan_price) -
                              Number(
                                subscriptionDetail?.tax_behavior === 1
                                  ? subscriptionDetail?.current_plan_price * 0.1
                                  : (subscriptionDetail?.current_plan_price /
                                      1.1) *
                                      0.1
                              )
                          ) || subscriptionDetail?.currency} */}
                                {Number(subscriptionDetail?.price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.price)
                                    )
                                  : "0.00"}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>GST: </label>{" "}
                                {/* {subscriptionDetail?.tax_behavior === 1
                            ? `$${(
                                subscriptionDetail?.current_plan_price * 0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : `$${(
                                (subscriptionDetail?.current_plan_price / 1.1) *
                                0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`} */}
                                $
                                {Number(subscriptionDetail?.tax_price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.tax_price)
                                    )
                                  : "0.00"}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>
                                  Total{" "}
                                  {subscriptionDetail?.plan_time !== ""
                                    ? "per " + subscriptionDetail?.plan_time
                                    : ""}
                                  :
                                </label>{" "}
                                $
                                {/* {formatNumber(
                            Number(
                              subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency
                            ) +
                              Number(
                                subscriptionDetail?.tax_behavior === 1
                                  ? subscriptionDetail?.current_plan_price * 0.1
                                  : 0
                              )
                          )} */}
                                {Number(subscriptionDetail?.total_price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.total_price)
                                    )
                                  : "0.00"}
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 ml-5">
                              <h6>Subscription Period</h6>
                              <p>
                                <label>Start Date: </label>{" "}
                                {subscriptionDetail?.trial_start}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>End Date: </label>{" "}
                                {subscriptionDetail?.trial_end}
                              </p>
                            </div>
                            <div
                              className="col-lg-6 col-md-5 subscription-btn"
                              style={{
                                textAlign: "end",
                              }}
                            >
                              <div>
                                <p>
                                  <label>Subscription Renewal Date:</label>{" "}
                                  {subscriptionDetail?.next_payment_date}
                                </p>
                                <Button
                                  size="small"
                                  type="primary"
                                  className="subscription-primary-btn"
                                  onClick={handleonRedirect}
                                >
                                  Change Plan
                                </Button>
                                <Button
                                  size="small"
                                  type="primary"
                                  className="cancel-subscription"
                                  onClick={() => setShowModal(true)}
                                >
                                  Cancel Plan
                                </Button>
                              </div>
                            </div>
                          </>
                        )}
                        {/* {subscriptionDetail?.status} */}
                        {subscriptionDetail?.status === "active" && (
                          <>
                            <div className="col-lg-2 col-md-3 ml-5 detail-box">
                              <p style={{ margin: "0" }}>
                                <label>Plan: </label>{" "}
                                {subscriptionDetail?.plan_type}
                              </p>

                              <p style={{ margin: "0" }}>
                                <label>
                                  Subtotal
                                  {/* {`(${
                              subscriptionDetail?.tax_behavior === 1
                                ? "Exc. GST"
                                : "Inc. GST"
                            })`} */}
                                  :
                                </label>{" "}
                                $
                                {/* {formatNumber(
                            Number(subscriptionDetail?.current_plan_price) -
                              Number(
                                subscriptionDetail?.tax_behavior === 1
                                  ? subscriptionDetail?.current_plan_price * 0.1
                                  : (subscriptionDetail?.current_plan_price /
                                      1.1) *
                                      0.1
                              )
                          ) || subscriptionDetail?.currency} */}
                                {Number(subscriptionDetail?.price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.price)
                                    )
                                  : "0.00"}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>GST: </label>{" "}
                                {/* {subscriptionDetail?.tax_behavior === 1
                            ? `$${(
                                subscriptionDetail?.current_plan_price * 0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : `$${(
                                (subscriptionDetail?.current_plan_price / 1.1) *
                                0.1
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`} */}
                                $
                                {Number(subscriptionDetail?.tax_price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.tax_price)
                                    )
                                  : "0.00"}
                              </p>
                              <p style={{ margin: "0" }}>
                                <label>
                                  {" "}
                                  Total{" "}
                                  {subscriptionDetail?.plan_time !== ""
                                    ? "per " + subscriptionDetail?.plan_time
                                    : ""}
                                  :
                                </label>{" "}
                                $
                                {/* {formatNumber(
                            Number(
                              subscriptionDetail?.current_plan_price ||
                                subscriptionDetail?.currency
                            ) +
                              Number(
                                subscriptionDetail?.metadata?.tax_behavior === 1
                                  ? subscriptionDetail?.current_plan_price * 0.1
                                  : 0
                              )
                          )}{" "} */}
                                {Number(subscriptionDetail?.total_price)
                                  ? formatNumber(
                                      Number(subscriptionDetail?.total_price)
                                    )
                                  : "0.00"}
                              </p>
                            </div>
                            <div
                              className="col-lg-10 col-md-9 subscription-btn"
                              style={{
                                textAlign: "end",
                              }}
                            >
                              <p>
                                <label>Next Payment Date:</label>{" "}
                                {subscriptionDetail?.next_payment_date}
                              </p>

                              <Button
                                size="small"
                                type="primary"
                                className="subscription-primary-btn"
                                onClick={handleonRedirect}
                              >
                                Change Plan
                              </Button>
                              <Button
                                size="small"
                                type="primary"
                                className="reset"
                                loading={cancelSubscriptionLoading}
                                onClick={() => setShowModal(true)}
                              >
                                Cancel Plan
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                )
              )}
              {subscriptionDetail?.supportPlan?.map(
                (subscriptionDetail, index) => (
                  <div
                    className="row border-box subscription-detail-body"
                    key={index}
                    style={{
                      padding: "15px 5px",
                      marginTop: "10px",
                    }}
                  >
                    <h5 className="subscription-detail-body-title">
                      Support Plan ({subscriptionDetail?.support_plan}){/* {firmStatus} */}
                      {/* {subscriptionDetail?.status === 'canceled' ? 'canceled' : subscriptionDetail?.status === 'trialing' ? 'Free Trial' : 'Active'} */}
                      <span className="sub-status">
                        Status: <i className="active">Active</i>
                      </span>
                    </h5>
                    <div className="row col-md-2">
                      <>
                        <div
                          className="col-md-12 ml-5 detail-box"
                          style={{
                            padding: "0px",
                          }}
                        >
                          <div
                            style={{
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Plan: </label>{" "}
                            One Time
                          </div>
                          {/* <div className="col-md-4 ml-5">
                    <div style={{ margin: "0" }}>
                      <label>Amount: </label> $
                      {subscriptionDetail?.support_price}
                    </div>
                  </div> */}
                          <p
                            style={{
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>
                              {/* Amount{" "}
                              {`(${
                                subscriptionDetail?.support_tax_behavior === 1
                                  ? "Exc. GST"
                                  : "Inc. GST"
                              })`} */}
                              SubTotal:
                            </label>{" "}
                            $
                            {formatNumber(
                              Number(subscriptionDetail?.support_price)
                            ) || subscriptionDetail?.currency}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>GST: </label>{" "}
                            {subscriptionDetail?.support_tax_behavior === 1
                              ? `$${(
                                  subscriptionDetail?.support_price * 0.1
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`
                              : `$${(
                                  (subscriptionDetail?.support_price / 1.1) *
                                  0.1
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                          </p>
                          <p
                            style={{
                              margin: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <label>Total Amount:</label> $
                            {formatNumber(
                              Number(
                                subscriptionDetail?.support_price ||
                                  subscriptionDetail?.currency
                              ) +
                                Number(
                                  subscriptionDetail?.support_tax_behavior === 1
                                    ? subscriptionDetail?.support_price * 0.1
                                    : 0
                                )
                            )}
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div className="table-top-btn">
            <div className="search-section">
              <Form>
                <FormControl
                  onChange={(e) => handleSearchChange(e)}
                  type="text"
                  value={searchParamData}
                  placeholder="Search Project"
                />
                <Button className="fa fa-search">Search</Button>
              </Form>
            </div>
          </div>
          <div className="custom-table">
            {isLoading && <Spin />}
            <BootstrapTable
              keyField="project_id"
              remote
              data={subData}
              columns={SubscriptionListColumns}
              onTableChange={handleTableChange}
              noDataIndication="No Data Found"
            />

            <Pagination
              total={total}
              limit={parseInt(limit)}
              currentPage={page}
              updateLimit={handleUpdateLimit}
              updatePage={handleChangePage}
              from={from}
              to={to}
            />
          </div>
        </>
      )}

      {showModal && (
        <Modal
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {cancelSubscriptionLoading && <Spin />}
          <Modal.Header className="mb-0" closeButton>
            Cancel Subscription
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body">
              Are you sure you want to cancel subscription?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                No
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => cancelSubscription()}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SubscriptionDetails;
