import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./components/Form";
import { API, fileUpload, get } from "../../../../config";
import {
  useBankList,
  useAllBankList,
} from "../../../Builders/Projects/components/ProjectsHelper";
import { AddWithdrawalSchema } from "../../../common/Validation";
import Spin from "../../../common/Spin";
import { useCallback } from "react";

const Withdrawal = ({
  setProjectLogo,
  setProjectName,
  getProjectData,
  ProjectReadOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const [projectData, setProjectData] = useState();
  const [id, setId] = useState(null);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const params = useParams();
  const projectId = params?.project_id;
  const navigate = useNavigate();

  const fetchProjectData = useCallback(
    async (projectID) => {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.GET_WITHDRAWAL_DETAILS}/withdrawal?builder_project_id=${projectID}`
        );
        setProjectData(data?.data);
        if (data?.data?.id) {
          setId(data?.data?.id);
        } else {
          setId(null);
        }
        navigate(
          `/projects/${projectID}/settings?tab=withdrawal-general-account`,
          {
            replace: true,
          }
        );
        setLoading(false);
      } catch (e) {
        setProjectData(null);
        setLoading(false);
      }
    },
    [navigate]
  );

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    // value.withdrawal_opening_balance = Number(
    //   `${value?.withdrawal_opening_balance}`.replace(/,/g, "")
    // );

    formData.append(
      "last_name",
      value?.withdrawal_account_name ? value?.withdrawal_account_name : ""
    );
    formData.append(
      "business_name",
      value?.withdrawal_account_name ? value?.withdrawal_account_name : ""
    );
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "account_name",
      value?.withdrawal_account_name ? value?.withdrawal_account_name : ""
    );
    formData.append("bsb", value?.withdrawal_bsb ? value?.withdrawal_bsb : "");
    formData.append(
      "account_number",
      value?.withdrawal_account_number ? value?.withdrawal_account_number : ""
    );
    formData.append(
      "bank_id",
      value?.withdrawal_bank_id ? value?.withdrawal_bank_id?.value : ""
    );
    // formData.append(
    //   "apca_id",
    //   value?.withdrawal_apca_id ? value?.withdrawal_apca_id : ""
    // );
    // formData.append(
    //   "opening_balance",
    //   value?.withdrawal_opening_balance ? value?.withdrawal_opening_balance : 0
    // );
    formData.append("builder_project_id", projectId);
    // if (id === null) {
    formData.append("is_pta", "2");
    // }
    if (id !== null) {
      formData.append("_method", "put");
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.WITHDRAWAL}${id !== null ? `/${id}` : ""}`,
        formData
      );
      toast.success(data?.message);

      setLoading(false);
      if (data) {
        if (projectId) {
          setProjectData(null);
          // navigate(`/projects/${projectId}/settings?tab=project-settings`);
          fetchProjectData(projectId);
          getProjectData(projectId, setProjectName, setProjectLogo);
        }
      }
      return data?.data;
    } catch (error) {
      setProjectData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProjectData(projectId);
  }, [projectId, fetchProjectData]);

  //Bank
  const { bankLoading, bankList = [] } = useBankList();
  const { allBankLoading, allBankList = [] } = useAllBankList();
  // const bankListing = bankList?.map((item) => {
  //   const { name: label, id: value, ...rest } = item;
  //   return { label, value, ...rest };
  // });
  const bankListing = bankList ? bankList : [];
  const allBanks = allBankList ? allBankList : [];

  const withdrawalBank = allBanks?.find(
    (item) => item?.value === projectData?.bank_id
  );

  return (
    <>
      <Formik
        initialValues={{
          email: projectData?.email ? projectData?.email : "",
          withdrawal_account_name: projectData?.account_name
            ? projectData?.account_name
            : "",
          withdrawal_bsb: projectData?.bsb ? projectData?.bsb : "",
          withdrawal_account_number: projectData?.account_number
            ? projectData?.account_number
            : "",
          withdrawal_bank_id: withdrawalBank ? withdrawalBank : "",
          // email: projectData?.email ? projectData?.email : "",
          // withdrawal_apca_id: projectData?.apca_id ? projectData?.apca_id : "",
          // withdrawal_opening_balance: projectData?.opening_balance
          //   ? projectData?.opening_balance
          //   : 0
        }}
        enableReinitialize
        innerRef={formValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={AddWithdrawalSchema}
      >
        {({
          values,
          setErrors,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              {loading && <Spin />}
              <Form
                values={values}
                errors={errors}
                setErrors={setErrors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                bankListing={bankListing}
                allBanks={allBanks}
                bankLoading={bankLoading}
                allBankLoading={allBankLoading}
                projectData={projectData}
                projectId={projectId}
                setLoading={setLoading}
                ProjectReadOnly={ProjectReadOnly}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Withdrawal;
