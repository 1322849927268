import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { imageDefaultPrefixPath, ProjectStatus } from "../../../../../config";
import ImageCropModal from "../../../../common/ImageCropModal";
// import { DatePicker } from "@semcore/date-picker";
import SuburbSelect from "../../../../common/SuburbSelect";
import ProjectSelect from "../../../../common/ProjectSelect";
import { getProjectPopupDetail } from "../../../../Builders/Projects/components/ProjectsHelper";
import NumberFormat from "react-number-format";
import { MaxLimit } from "../../../../common/Misc";
import moment from "moment";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "rsuite";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  stateListing,
  stateLoading,
  bankListing,
  bankLoading,
  projectData,
  builderId,
  projectId,
  setLoading,
  allBanks,
  allBankLoading,
  officeListing,
  officeLoading,
  selectedOffices,
  setSelectedOffices,
  userListing,
  userLoading,
  setSelectedUsers,
  selectedUsers,
  ProjectReadOnly,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
    bankListing: PropTypes.any,
    allBanks: PropTypes.any,
    allBankLoading: PropTypes.any,
    bankLoading: PropTypes.any,
    projectData: PropTypes.any,
    builderId: PropTypes.any,
    projectId: PropTypes.any,
    setLoading: PropTypes.any,
  };

  const [RTADisable, setRTADisable] = useState(false);

  const [startDate, setStartDate] = useState(values?.start_date);
  const [endDate, setEndDate] = useState(values?.end_date);

  const imageInputRef = React.useRef();
  const logo = values?.logo ? values?.logo : "";

  const [selectedFile, setSelectedFile] = useState(logo);
  const [imageType, setImageType] = useState();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    values?.logo &&
      typeof values?.logo === "string" &&
      setSelectedFile(values?.logo);
  }, [values]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  const handleRemoveIcon = () => {
    setSelectedFile("");
    imageInputRef.current.value = "";
  };

  const handleRTAChange = async (project) => {
    if (project) {
      setRTADisable(true);
      setFieldValue("rta_project", project);
      setLoading(true);
      const projectDetailData = await getProjectPopupDetail(project?.id, true);
      setFieldValue(
        "retention_transaction_start_date",
        projectDetailData?.rta_contractor?.transaction_start_date
          ? projectDetailData?.rta_contractor?.transaction_start_date
          : ""
      );
      setFieldValue(
        "retention_apca_id",
        projectDetailData?.rta_contractor?.apca_id
          ? projectDetailData?.rta_contractor?.apca_id
          : "000000"
      );
      setFieldValue(
        "retention_opening_balance",
        projectDetailData?.rta_contractor?.opening_balance
          ? projectDetailData?.rta_contractor?.opening_balance
          : 0
      );
      setFieldValue(
        "retention_account_name",
        projectDetailData?.rta_contractor?.account_name
          ? projectDetailData?.rta_contractor?.account_name
          : ""
      );
      setFieldValue(
        "retention_bsb",
        projectDetailData?.rta_contractor?.bsb
          ? projectDetailData?.rta_contractor?.bsb
          : ""
      );
      setFieldValue(
        "retention_account_number",
        projectDetailData?.rta_contractor?.account_number
          ? projectDetailData?.rta_contractor?.account_number
          : ""
      );
      setFieldValue(
        "retention_bank_id",
        projectDetailData?.rta_contractor?.bank
          ? {
              label:
                projectDetailData?.rta_contractor?.bank?.abbreviation +
                "-" +
                projectDetailData?.rta_contractor?.bank?.name,
              value: projectDetailData?.rta_contractor?.bank?.id,
              rest: projectDetailData?.rta_contractor?.bank,
            }
          : null
      );
      setLoading(false);
    } else {
      setRTADisable(false);
      setFieldValue("retention_transaction_start_date", "");
      setFieldValue("retention_apca_id", "");
      setFieldValue("retention_opening_balance", "");
      setFieldValue("rta_project", null);
      setFieldValue("retention_account_name", "");
      setFieldValue("retention_bsb", "");
      setFieldValue("retention_account_number", "");
      setFieldValue("retention_bank_id", null);
    }
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleOfficeChange = (e, setFieldValue) => {
    setFieldValue("office_ids", e.value);
    setSelectedOffices(e.value);
  };

  const filterOfficeInputRef = useRef();

  const handleOfficeApplyFilter = () => {
    filterOfficeInputRef.current.hide();
  };

  const officeResetFunction = () => {
    setSelectedOffices([]);
    filterOfficeInputRef && filterOfficeInputRef.current.focus();
    filterOfficeInputRef.current.hide();
  };

  const filterUserInputRef = useRef();

  const handleUserApplyFilter = () => {
    filterUserInputRef.current.hide();
  };

  const userResetFunction = () => {
    setSelectedUsers([]);
    filterUserInputRef && filterUserInputRef.current.focus();
    filterUserInputRef.current.hide();
  };

  const handleUserChange = (e, setFieldValue) => {
    setFieldValue("user_ids", e.value);
    setSelectedUsers(e.value);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card myprofile-card">
        <div className="form-box">
          <div
            className="form-group half-width profile-photo logo"
            style={{ display: "block", textAlign: "center" }}
          >
            <input
              accept="image/*"
              type="file"
              name="logo"
              className="form-control"
              id="file"
              ref={imageInputRef}
              onChange={(e) => {
                setImageType(e.currentTarget.files[0].type);
                e.currentTarget.files[0] && setShowModal(true);
                setSelectedFile(URL.createObjectURL(e.currentTarget.files[0]));
              }}
              onBlur={handleBlur}
            />
            {selectedFile &&
              (selectedFile !== `${imageDefaultPrefixPath}null` ||
                selectedFile !== `${imageDefaultPrefixPath}undefined` ||
                selectedFile !== `${imageDefaultPrefixPath}`) && (
                <div className="previewImage d-flex">
                  <img
                    className="display-image"
                    src={selectedFile}
                    alt="selectedFile"
                  />
                  {/* <span
                    className="remove-image"
                    onClick={() => {
                      handleRemoveIcon(setFieldValue);
                      setFieldValue("logo", null);
                    }}
                  >
                    Remove Logo
                    <i className="fa fa-times" aria-hidden="true" />
                  </span> */}
                  <div className="photo-upload-action d-flex">
                    <div className="edit-photo-input">
                      <input
                        accept="image/*"
                        type="file"
                        name="profile_pic"
                        className="form-control"
                        id="file"
                        ref={imageInputRef}
                        onChange={(e) => {
                          e.currentTarget.files[0].type &&
                            setImageType(e.currentTarget.files[0].type);
                          e.currentTarget.files[0] && setShowModal(true);
                          setSelectedFile(
                            URL.createObjectURL(e.currentTarget.files[0])
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      onClick={() => {
                        handleRemoveIcon(setFieldValue);
                        setFieldValue("logo", null);
                      }}
                    />
                  </div>
                </div>
              )}
            {!selectedFile && <span className="guide_leb">Add your Logo</span>}
          </div>
        </div>
        <div className="form-box">
          <div className="form-group tetra-width">
            <label>
              <span className="required">*</span>Project Name
            </label>
            <input
              type="text"
              name="project_name"
              value={values?.project_name ? values?.project_name : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="project_name" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={values?.email ? values?.email : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="email" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Phone No.</label>
            <InputMask
              type="text"
              name="phone"
              value={values?.phone ? values?.phone : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 9999 9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="phone" />
            </span>
          </div>
          <div className="form-group tetra-width">
            <label>Mobile No.</label>
            <InputMask
              type="text"
              name="mobile"
              value={values?.mobile ? values?.mobile : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 9999 9999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="mobile" />
            </span>
          </div>
          <div className="form-group tetra-width aba-input-field">
            <label>ABN</label>
            <InputMask
              type="text"
              name="abn"
              value={values?.abn ? values?.abn : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              mask="99 999 999 999"
              maskChar={null}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <div className="icon">
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="aba-tooltip"
                onClick={() =>
                  window.open(
                    values?.abn
                      ? `https://abr.business.gov.au/ABN/View?id=${values?.abn.replace(
                          / /g,
                          ""
                        )}`
                      : `https://abr.business.gov.au`,
                    "_blank"
                  )
                }
              />
            </div>
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="abn" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>Office</label>
            <MultiSelect
              value={selectedOffices}
              selectedItemsLabel={`${selectedOffices?.length} Office(s) Selected`}
              name="office_ids"
              onChange={(e) => {
                handleOfficeChange(e, setFieldValue);
              }}
              options={
                officeListing
                  ? officeListing
                  : { label: "Add New Office", value: "new" }
              }
              optionLabel="label"
              // filter
              placeholder="Select Office"
              maxSelectedLabels={0}
              ref={filterOfficeInputRef}
              panelFooterTemplate={
                <>
                  <hr />
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      handleOfficeApplyFilter();
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      officeResetFunction();
                    }}
                  >
                    Reset
                  </Button>
                </>
              }
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="office_ids" />
            </span>
          </div>

          <div className="form-group tetra-width">
            <label>User</label>
            <MultiSelect
              value={selectedUsers}
              selectedItemsLabel={`${selectedUsers?.length} User(s) Selected`}
              name="user_ids"
              onChange={(e) => {
                handleUserChange(e, setFieldValue);
              }}
              options={userListing}
              optionLabel="label"
              // filter
              placeholder="Select User"
              maxSelectedLabels={0}
              ref={filterUserInputRef}
              panelFooterTemplate={
                <>
                  <hr />
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      handleUserApplyFilter();
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    className="multiSelect-button"
                    onClick={() => {
                      userResetFunction();
                    }}
                  >
                    Reset
                  </Button>
                </>
              }
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="office_ids" />
            </span>
          </div>

          <div className="address-box">
            <h5>Project Location</h5>
            <div className="form-group tetra-width">
              <label>Address</label>
              <input
                type="text"
                name="street"
                value={values?.street ? values?.street : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="street" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Select State</label>

              <Select
                name="state_id"
                value={values?.state_id ? values?.state_id : ""}
                onChange={(e) => {
                  setFieldValue(`state_id`, e ? e : "");
                  setFieldValue(`suburb_id`, "");
                }}
                onBlur={handleBlur}
                options={stateListing}
                placeholder="Select State"
                classNamePrefix="selectbox"
                isClearable
                isLoading={stateLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="state_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Select Suburb</label>

              <SuburbSelect
                name="suburb_id"
                regionName={values?.state_id}
                onChange={(e) => {
                  setFieldValue("suburb_id", e ? e : "");
                }}
                value={
                  typeof values?.suburb_id === "object"
                    ? values?.suburb_id
                    : null
                }
                placeholder={"Select Suburb"}
                isClearable={true}
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="suburb_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Postal Code</label>
              <InputMask
                type="text"
                name="postal_code"
                value={values?.postal_code ? values?.postal_code : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="9999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="postal_code" />
              </span>
            </div>
          </div>

          <div className="project-bank-details-box">
            <h5>Bank Account Details</h5>

            {/* <h6>Trustee's Business/Withdrawal Bank Account</h6>

            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Account Name
              </label>
              <input
                type="text"
                name="trustee_account_name"
                value={
                  values?.trustee_account_name
                    ? values?.trustee_account_name
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_account_name" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>BSB
              </label>
              <InputMask
                type="text"
                name="trustee_bsb"
                value={values?.trustee_bsb ? values?.trustee_bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_bsb" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Account Number
              </label>
              <InputMask
                type="text"
                name="trustee_account_number"
                value={
                  values?.trustee_account_number
                    ? values?.trustee_account_number
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_account_number" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Name
              </label>
              <Select
                name="trustee_bank_id"
                value={values?.trustee_bank_id ? values?.trustee_bank_id : ""}
                onChange={(e) => {
                  setFieldValue(`trustee_bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={allBanks}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={allBankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_bank_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>APCA ID
                <div className="apca-info">
                <DropdownButton className="info-icon">
                  <Dropdown.Item>
                    <p>ANZ - 000000</p>
                    <p>Westpac - 000000</p>
                    <p>NAB - 000000</p>
                    <p>CBA - 301500</p>
                    <p>BBL - 651966</p>
                  </Dropdown.Item>
                </DropdownButton>
                </div>
              </label>
              <InputMask
                mask="999999"
                name="trustee_apca_id"
                value={values?.trustee_apca_id ? values?.trustee_apca_id : ""}
                placeholder="APCA ID"
                maskChar=""
                onChange={(e) => {
                  setFieldValue(`trustee_apca_id`, e?.target?.value ? e?.target?.value : "");
                }}
                onBlur={(e) => {
                  setFieldValue(`trustee_apca_id`, e?.target?.value ? padWithLeadingZeros(e?.target?.value, 6) : "");
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_apca_id" />
              </span>
            </div>
            
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Opening Balance($)
              </label>

              <NumberFormat
                type="text"
                name="trustee_opening_balance"
                value={values?.trustee_opening_balance}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={true}
                isAllowed={(values) => MaxLimit(values)}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="trustee_opening_balance" />
              </span>
            </div> */}

            {/* <hr className="hr-content" /> */}
            <h6>
              Project Trust Bank Account
              {projectData?.is_pta_connected === 0 ? (
                <span>
                  <Button
                    className="btn-connect"
                    onClick={() => {
                      navigate(
                        `/projects/${projectId}/settings?tab=integrations`
                      );
                    }}
                    disabled={ProjectReadOnly}
                  >
                    Connect
                  </Button>
                </span>
              ) : (
                <></>
              )}
            </h6>

            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Name
              </label>
              <Select
                name="project_bank_id"
                value={values?.project_bank_id ? values?.project_bank_id : ""}
                onChange={(e) => {
                  setFieldValue(`project_bank_id`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={
                  bankListing?.length > 0
                    ? bankListing?.filter((item) => item.value !== "")
                    : []
                }
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={bankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                isDisabled={ProjectReadOnly}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_bank_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Account Name
              </label>
              <input
                type="text"
                name="project_account_name"
                value={
                  values?.project_account_name
                    ? values?.project_account_name
                    : ""
                }
                disabled={ProjectReadOnly}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_account_name" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>BSB
              </label>
              <InputMask
                type="text"
                name="project_bsb"
                value={values?.project_bsb ? values?.project_bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                disabled={ProjectReadOnly}
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_bsb" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Account Number
              </label>
              <InputMask
                type="text"
                name="project_account_number"
                value={
                  values?.project_account_number
                    ? values?.project_account_number
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                disabled={ProjectReadOnly}
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_account_number" />
              </span>
            </div>

            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>APCA ID
                <div className="apca-info">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`project_apca_id`, "000000");
                        }}
                      >
                        ANZ - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`project_apca_id`, "000000");
                        }}
                      >
                        Westpac - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`project_apca_id`, "000000");
                        }}
                      >
                        NAB - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`project_apca_id`, "301500");
                        }}
                      >
                        CBA - 301500
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`project_apca_id`, "651966");
                        }}
                      >
                        BBL - 651966
                      </p>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </label>
              <InputMask
                mask="999999"
                name="project_apca_id"
                value={values?.project_apca_id ? values?.project_apca_id : ""}
                placeholder="APCA ID"
                maskChar=""
                disabled={ProjectReadOnly}
                onChange={(e) => {
                  setFieldValue(
                    `project_apca_id`,
                    e?.target?.value ? e?.target?.value : ""
                  );
                }}
                onBlur={(e) => {
                  setFieldValue(
                    `project_apca_id`,
                    e?.target?.value
                      ? padWithLeadingZeros(e?.target?.value, 6)
                      : ""
                  );
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_apca_id" />
              </span>
            </div>

            <div className="form-group tetra-width">
              <label>
                {/* <span className="required">*</span> */}
                Opening Balance($)
              </label>

              <NumberFormat
                type="text"
                name="project_opening_balance"
                value={values?.project_opening_balance}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                allowNegative={false}
                disabled={ProjectReadOnly}
                thousandSeparator={true}
                isAllowed={(values) => MaxLimit(values)}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_opening_balance" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Transaction Start Date</label>
              {/* <DatePicker
                name="project_transaction_start_date"
                value={
                  values?.project_transaction_start_date
                    ? moment(
                        values?.project_transaction_start_date,
                        "DD-MM-YYYY"
                      ).toDate()
                    : ""
                }
                onChange={(e) => {
                  // handleChange();
                  setFieldValue("project_transaction_start_date", e);
                }}
                placement="top"
              /> */}
              <DatePicker
                cleanable={false}
                onChange={(e) => {
                  setFieldValue(
                    "project_transaction_start_date",
                    moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                }}
                format="dd-MM-yyyy"
                value={
                  values?.project_transaction_start_date
                    ? String(values?.project_transaction_start_date)?.includes(
                        "-"
                      )
                      ? new Date(
                          String(values?.project_transaction_start_date)?.split(
                            "-"
                          )[2],
                          String(values?.project_transaction_start_date)?.split(
                            "-"
                          )[1] - 1,
                          String(values?.project_transaction_start_date)?.split(
                            "-"
                          )[0]
                        )
                      : new Date(values?.project_transaction_start_date)
                    : null
                }
                className="rs-date-body"
                placeholder="Select Start Date"
                placement="autoVerticalStart"
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_transaction_start_date" />
              </span>
            </div>

            <hr className="hr-content" />
            <h6>
              Retention Trust Bank Account{" "}
              {projectData?.is_pta_connected === 0 ? (
                <span>
                  <Button
                    className="btn-connect"
                    onClick={() => {
                      navigate(
                        `/projects/${projectId}/settings?tab=integrations`
                      );
                    }}
                    disabled={ProjectReadOnly}
                  >
                    Connect
                  </Button>
                </span>
              ) : (
                <></>
              )}
            </h6>

            <div className="form-group penta-width">
              <label>Select existing project's RTA</label>
              <ProjectSelect
                name="rta_project"
                regionName={builderId ? builderId : "0"}
                onChange={(project) => {
                  handleRTAChange(project);
                }}
                value={values?.rta_project ? values?.rta_project : null}
                placeholder={"Select Project"}
                className="selectbox"
                builderId={builderId}
                isClearable={true}
                rta_project={1}
                projectId={projectId}
                isDisabled={ProjectReadOnly}
              />
            </div>
            <hr className="hr-text" data-content="OR" />
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Name
              </label>
              <Select
                name="retention_bank_id"
                value={
                  values?.retention_bank_id ? values?.retention_bank_id : ""
                }
                onChange={(e) => {
                  setFieldValue(`retention_bank_id`, e ? e : "");
                  if (e?.label === "Don't have a Retention Trust A/C") {
                    setFieldValue(
                      `retention_account_name`,
                      "Retention Trust Account"
                    );
                    setFieldValue(`retention_bsb`, "000000");
                    setFieldValue(`retention_account_number`, "000000001");
                    setFieldValue(`retention_apca_id`, "000000");
                    setFieldValue(`retention_opening_balance`, "0");
                  }
                }}
                onBlur={handleBlur}
                options={bankListing}
                placeholder="Select Bank"
                classNamePrefix="selectbox"
                isLoading={bankLoading}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "grey",
                  },
                })}
                isDisabled={
                  RTADisable || values?.rta_project || ProjectReadOnly
                    ? true
                    : false
                }
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_bank_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Bank Account Name
              </label>
              <input
                type="text"
                name="retention_account_name"
                value={
                  values?.retention_account_name
                    ? values?.retention_account_name
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={
                  RTADisable || values?.rta_project || ProjectReadOnly
                    ? true
                    : false
                }
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_account_name" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>BSB
              </label>
              <InputMask
                type="text"
                name="retention_bsb"
                value={values?.retention_bsb ? values?.retention_bsb : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999-999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={
                  RTADisable || values?.rta_project || ProjectReadOnly
                    ? true
                    : false
                }
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_bsb" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Account Number
              </label>
              <InputMask
                type="text"
                name="retention_account_number"
                value={
                  values?.retention_account_number
                    ? values?.retention_account_number
                    : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
                mask="999999999"
                maskChar={null}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                disabled={
                  RTADisable || values?.rta_project || ProjectReadOnly
                    ? true
                    : false
                }
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_account_number" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>APCA ID
                <div className="apca-info">
                  <DropdownButton className="info-icon">
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`retention_apca_id`, "000000");
                        }}
                      >
                        ANZ - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`retention_apca_id`, "000000");
                        }}
                      >
                        Westpac - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`retention_apca_id`, "000000");
                        }}
                      >
                        NAB - 000000
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`retention_apca_id`, "301500");
                        }}
                      >
                        CBA - 301500
                      </p>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <p
                        onClick={() => {
                          setFieldValue(`retention_apca_id`, "651966");
                        }}
                      >
                        BBL - 651966
                      </p>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </label>
              <InputMask
                mask="999999"
                name="retention_apca_id"
                value={
                  values?.retention_apca_id ? values?.retention_apca_id : ""
                }
                placeholder="APCA ID"
                maskChar=""
                onChange={(e) => {
                  setFieldValue(
                    `retention_apca_id`,
                    e?.target?.value ? e?.target?.value : ""
                  );
                }}
                disabled={
                  RTADisable || values?.rta_project || ProjectReadOnly
                    ? true
                    : false
                }
                onBlur={(e) => {
                  setFieldValue(
                    `retention_apca_id`,
                    e?.target?.value
                      ? padWithLeadingZeros(e?.target?.value, 6)
                      : ""
                  );
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_apca_id" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                {/* <span className="required">*</span> */}
                Opening Balance($)
              </label>
              <NumberFormat
                type="text"
                name="retention_opening_balance"
                value={values?.retention_opening_balance}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                allowNegative={false}
                // disabled={RTADisable || values?.rta_project ? true : false}
                thousandSeparator={true}
                disabled={ProjectReadOnly}
                isAllowed={(values) => MaxLimit(values)}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_opening_balance" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Transaction Start Date</label>
              {/* <DatePicker
                name="retention_transaction_start_date"
                value={
                  values?.retention_transaction_start_date
                    ? moment(
                        values?.retention_transaction_start_date,
                        "DD-MM-YYYY"
                      ).toDate()
                    : ""
                }
                onChange={(e) => {
                  // handleChange();
                  setFieldValue("retention_transaction_start_date", e);
                }}
                placement="top"
              /> */}
              <DatePicker
                cleanable={false}
                onChange={(e) => {
                  setFieldValue(
                    "retention_transaction_start_date",
                    moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                }}
                format="dd-MM-yyyy"
                value={
                  values?.retention_transaction_start_date
                    ? String(
                        values?.retention_transaction_start_date
                      )?.includes("-")
                      ? new Date(
                          String(
                            values?.retention_transaction_start_date
                          )?.split("-")[2],
                          String(
                            values?.retention_transaction_start_date
                          )?.split("-")[1] - 1,
                          String(
                            values?.retention_transaction_start_date
                          )?.split("-")[0]
                        )
                      : new Date(values?.retention_transaction_start_date)
                    : null
                }
                className="rs-date-body"
                placeholder="Select Start Date"
                placement="autoVerticalStart"
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="retention_transaction_start_date" />
              </span>
            </div>
          </div>

          <div className="key-metrics-box">
            <h5>Key Metrics</h5>
            <div className="form-group tetra-width">
              <label>Project Start Date</label>
              {/* <DatePicker
                name="start_date"
                value={
                  startDate
                    ? startDate
                    : values?.start_date
                    ? values?.start_date
                    : ""
                }
                onChange={(e) => {
                  // handleChange();
                  setFieldValue("start_date", e);
                  setStartDate(e);
                }}
                placement="top"
              /> */}
              <DatePicker
                cleanable={false}
                onClean={() => {
                  setFieldValue("start_date", "");
                  setStartDate("");
                }}
                onChange={(e) => {
                  setStartDate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
                  setFieldValue(
                    "start_date",
                    moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                  // setStartDate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
                }}
                format="dd-MM-yyyy"
                value={
                  startDate
                    ? String(startDate)?.includes("-")
                      ? new Date(
                          String(startDate)?.split("-")[2],
                          String(startDate)?.split("-")[1] - 1,
                          String(startDate)?.split("-")[0]
                        )
                      : new Date(startDate)
                    : values?.start_date
                    ? String(values?.start_date)?.includes("-")
                      ? new Date(
                          String(values?.start_date)?.split("-")[2],
                          String(values?.start_date)?.split("-")[1] - 1,
                          String(values?.start_date)?.split("-")[0]
                        )
                      : new Date(values?.start_date)
                    : null
                }
                className="rs-date-body"
                placeholder="Select Start Date"
                placement="autoVerticalStart"
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="start_date" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>Project End Date</label>
              {/* <DatePicker
                name="end_date"
                value={
                  endDate ? endDate : values?.end_date ? values?.end_date : ""
                }
                disabled={[[startDate, true]]}
                onChange={(e) => {
                  // handleChange();
                  setFieldValue("end_date", e);
                  setEndDate(e);
                }}
                placement="top"
              /> */}
              <DatePicker
                cleanable={false}
                onClean={() => {
                  setFieldValue("end_date", "");
                  setEndDate();
                }}
                onChange={(e) => {
                  setEndDate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
                  setFieldValue(
                    "end_date",
                    moment(e, "DD-MM-YYYY").format("DD-MM-YYYY")
                  );
                  // setStartDate(moment(e, "DD-MM-YYYY").format("DD-MM-YYYY"));
                }}
                format="dd-MM-yyyy"
                value={
                  endDate
                    ? String(endDate)?.includes("-")
                      ? new Date(
                          String(endDate)?.split("-")[2],
                          String(endDate)?.split("-")[1] - 1,
                          String(endDate)?.split("-")[0]
                        )
                      : new Date(endDate)
                    : values?.end_date
                    ? String(values?.end_date)?.includes("-")
                      ? new Date(
                          String(values?.end_date)?.split("-")[2],
                          String(values?.end_date)?.split("-")[1] - 1,
                          String(values?.end_date)?.split("-")[0]
                        )
                      : new Date(values?.end_date)
                    : null
                }
                className="rs-date-body"
                placeholder="Select End Date"
                placement="autoVerticalStart"
                disabledDate={(date) =>
                  moment(date).isBefore(
                    moment(values?.start_date, "DD-MM-YYYY")
                  )
                }
              />

              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="end_date" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Estimated Project Cost($)
              </label>
              <NumberFormat
                type="text"
                name="estimated_cost"
                value={values?.estimated_cost}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={true}
                isAllowed={(values) => MaxLimit(values)}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="estimated_cost" />
              </span>
            </div>
            <div className="form-group tetra-width">
              <label>
                <span className="required">*</span>Project Status
              </label>
              <Select
                name="project_status"
                value={values?.project_status ? values?.project_status : ""}
                onChange={(e) => {
                  setFieldValue(`project_status`, e ? e : "");
                }}
                onBlur={handleBlur}
                options={ProjectStatus}
                placeholder="Select Project Status"
                classNamePrefix="selectbox"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
              />
              <span
                className="text-danger"
                style={{ color: "red", fontSize: "20px" }}
              >
                <ErrorMessage name="project_status" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="entry-card full-card"
        style={{ clear: "left", float: "left", margin: 0 }}
      >
        <div className="action-box">
          <button type="submit">Save</button>
        </div>
      </div>
      {showModal && (
        <ImageCropModal
          imageType={imageType}
          selectedFile={selectedFile}
          setShowModal={setShowModal}
          setSelectedFile={setSelectedFile}
          showModal={showModal}
          setFieldValue={setFieldValue}
        />
      )}
    </form>
  );
};

export default Form;
