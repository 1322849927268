import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";

const ContractorTypeSelect = (props) => {
  const [regionName, setRegionName] = useState(null);

  useEffect(() => {
    setRegionName(props.regionName);
  }, [props.regionName]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const search = searchQuery?.length >= 3 ? searchQuery : "";

    const response = await get(
      `${API.GET_CONTRACTOR_TYPE}?builder_project_id=${
        props.projectId ? props.projectId : ""
      }&search=${search ? search : ""}&limit=${defaultLimitPagination}&page=${
        page && !search ? page : 1
      }&sort_column=name&sort_order=ASC`
    );
    let account =
      response?.data?.data?.data && response?.data?.data?.data?.length > 0
        ? response?.data?.data?.data.map((element) => {
            return {
              id: element.id,
              label: element?.name,
            };
          })
        : [];
    const responseJSON = account;

    const totalLength = loadedOptions?.length
      ? loadedOptions?.length + responseJSON?.length
      : responseJSON?.length;

    return {
      options: responseJSON,
      hasMore: totalLength < response?.data?.data?.total,
      additional: {
        page: search ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(regionName)}
      name={props.name}
      value={props.value || props.defaultValue}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => option?.label}
      classNamePrefix={props?.className || "selectbox"}
      onChange={onChange}
      isSearchable={true}
      placeholder={props?.placeholder || "Select Contractor Type"}
      additional={{
        page: 1,
      }}
      isClearable={props?.isClearable}
    />
  );
};

ContractorTypeSelect.propTypes = {
  regionName: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContractorTypeSelect;
