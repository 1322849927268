import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API, get } from "../../../../../config";

export const getPrincipalDetail = async (projectId) => {
  try {
    const { data } = await get(`${API.GET_PRINCIPAL_DETAILS}/` + projectId);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const useStateList = () => {
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);
  const init = useCallback(async () => {
    try {
      setStateLoading(true);
      const { data } = await get(API.GET_STATE_LIST);
      setStateLoading(false);
      setStateList(data.data);
    } catch (e) {
      setStateList([]);
      setStateLoading(false);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    stateList,
    stateLoading,
  };
};
