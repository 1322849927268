import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Subscription from "../../firm/Subscription";
import SubscriptionDetails from "../../firm/SubscriptionDetails";

const SubscriptionAfterLogin = ({ builder = false, project = false }) => {
  const tabsKey = {
    "current-subscription": 1,
    "more-packages": 2,
  };
  const location = useLocation();
  const params = useParams();
  const projectId = params?.project_id;
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("childTab") || "current-subscription";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1 ? "current-subscription" : "more-packages"
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (builder) {
      navigate(`/general-settings?tab=subscriptions&childTab=${activeTab}`, {
        replace: true,
      });
    } else if (project) {
      navigate(`/projects/${projectId}/settings?tab=subscriptions&childTab=${activeTab}`, {
        replace: true,
      });
    } else {
      navigate(`/user-settings?tab=subscriptions&childTab=${activeTab}`, {
        replace: true,
      });
    }
  }, [activeTab, navigate, builder, project, projectId]);
  return (
    <>
      <div className="table-top-btn">
        <ul
          className="nav nav-tabs"
          onClick={(event) => {
            setActiveTab(
              event?.target?.innerText.replace(/ /g, "-").toLowerCase()
            );
          }}
        >
          <li className={activeTab === "current-subscription" ? "active" : ""}>
            <Link key={1}>Current Subscription</Link>
          </li>

          <li className={activeTab === "more-packages" ? "active" : ""}>
            <Link key={2}>More Packages</Link>
          </li>
        </ul>
      </div>
      <div className="custom-table tab-table">
        {activeTab === "more-packages" && (
          <Subscription login={true} project={project} />
        )}
        {activeTab === "current-subscription" && (
          <SubscriptionDetails setActiveTab={setActiveTab} project={project} projectId={projectId} builder={builder}/>
        )}
      </div>
    </>
  );
};

export default SubscriptionAfterLogin;
