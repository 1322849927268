import { ErrorMessage, Formik } from "formik";
import { useCallback, useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, defaultLimitPagination, fileUpload, get } from "../../../../config";
import Spin from "../../../common/Spin";
import ContractorTypeActionButton from "./components/ContractorTypeActionButton";
import Pagination from "../../../common/Pagination";
import { AddContractorTypeSchema } from "../../../common/Validation";
import ChartOfAccountSelect from "../../../common/ChartOfAccountSelect";
const ContractorType = ({ ProjectReadOnly }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = useParams();
  const projectId = params?.project_id;
  const [showAccount, setShowAccount] = useState(false);
  const [contractorTypeData, setContractorTypeData] = useState();
  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  const searchParam = query.get("search");
  // const [searchParam, setSearchParam] = useState(search || "");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const fetchContractorTypes = useCallback(async () => {
    if (projectId) {
      try {
        const { data } = await get(
          `${API.GET_CONTRACTOR_TYPE}?builder_project_id=${
            projectId ? projectId : ""
          }&search=${searchParam ? searchParam : ""}&limit=${
            limit ? limit : defaultLimitPagination
          }&page=${page ? page : 1}&sort_column=${
            sortField ? sortField : ""
          }&sort_order=${sortOrder ? sortOrder : ""}`
        );
        setLimit(data?.data?.per_page);
        setPage(data?.data?.current_page);
        setTotal(data?.data?.total);
        setFrom(data?.data?.from);
        setTo(data?.data?.to);
        setContractorTypeData(data?.data?.data);
      } catch (error) {
        setContractorTypeData([]);

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [projectId, searchParam, limit, page, sortOrder, sortField]);
  const handleDelete = () => {
    fetchContractorTypes();
  };

  const accountsDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: contractorTypeData[i]?.id,
        name: contractorTypeData[i]?.name,
        account: contractorTypeData[i]?.chart_of_account
          ? contractorTypeData[i]?.chart_of_account?.name +
            ` - ${
              contractorTypeData[i]?.chart_of_account?.sub_code
                ? contractorTypeData[i]?.chart_of_account?.code +
                  "." +
                  contractorTypeData[i]?.chart_of_account?.sub_code
                : contractorTypeData[i]?.chart_of_account?.code
            }`
          : "-",
        action: (
          <>
            <ContractorTypeActionButton
              id={contractorTypeData[i]?.id}
              handleDelete={handleDelete}
              fetchContractorTypes={fetchContractorTypes}
            />
          </>
        ),
      });
    }
    return items;
  };
  const accountsData = accountsDataGenerator(contractorTypeData?.length);
  const columns = ProjectReadOnly
    ? [
        {
          dataField: "name",
          text: "Contractor Types",
          sort: true,
        },
        {
          dataField: "account",
          text: "Account",
        },
      ]
    : [
        {
          dataField: "name",
          text: "Contractor Types",
          sort: true,
        },
        {
          dataField: "account",
          text: "Account",
        },
        {
          dataField: "action",
          text: "Action",
          align: "center",
          headerAlign: "center",
          style: { width: "97px" },
        },
      ];
  const toggleAccountModal = () => {
    setShowAccount(!showAccount);
  };
  const handleImportClose = () => {
    setShowAccount(false);
    // setIsImportLoading(false);
    // setFiles();
  };
  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    formData.append("name", value.contractor_type ? value.contractor_type : "");
    formData.append(
      "chart_of_account_id",
      value.chart_of_account_id ? value?.chart_of_account_id?.id : ""
    );
    formData.append("description", value.description ? value.description : "");
    formData.append("builder_project_id", projectId ? projectId : "");
    // formData.append("_method", "post");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.CONTRACTOR_TYPE_Add}`, formData);
      setLoading(false);
      //   if (data) {
      //     toast.success(data?.message);
      //     toggleProjectModal();
      //   }
      setShowAccount(false);
      fetchContractorTypes();
      return data?.data;
    } catch (error) {
      //   setProjectData(value);
      setShowAccount(false);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };
  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    navigate(
      `/projects/${projectId}/settings?tab=contractor-types&limit=${
        limit ? limit : defaultLimitPagination
      }&page=${page ? page : 1}&search=${
        searchParam ? searchParam : ""
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [projectId, navigate, limit, page, searchParam, sortField, sortOrder]);
  useEffect(() => {
    fetchContractorTypes();
  }, [fetchContractorTypes]);

  const handleSyncContractorType = async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.CONTRACTOR_TYPE_SYNC}?builder_project_id=${
          projectId ? projectId : ""
        }`
      );
      toast.success(data?.message);
      setLoading(false);
      fetchContractorTypes();
      return data?.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      {!ProjectReadOnly && <div className="table-top-btn">
        <div className="table-btn">
          <Button
            variant="primary"
            onClick={() => handleSyncContractorType()}
            data-toggle="modal"
            data-target="#account"
          >
            Sync Contractor types
          </Button>
          <Button
            variant="primary"
            onClick={toggleAccountModal}
            data-toggle="modal"
            data-target="#account"
            style={{ marginLeft: "15px" }}
          >
            Add New
          </Button>
        </div>
      </div>}
      <div className="custom-table">
        {/* {accountLoading && <Spin />} */}
        <BootstrapTable
          keyField="key"
          remote
          data={accountsData}
          columns={columns}
          onTableChange={handleTableChange}
          noDataIndication="No Data Found"
        />
        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={page}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
      {showAccount && (
        <Modal
          size="lg"
          show={showAccount}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            ADD CONTRACTOR TYPES
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                contractor_type: "",
                chart_of_account_id: "",
                description: "",
              }}
              enableReinitialize
              innerRef={formValues}
              onSubmit={handleFormSubmit}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={AddContractorTypeSchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <>
                    {loading && <Spin />}
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      className="entry-section"
                    >
                      <div className="form-box">
                        <div className="form-group full-width">
                          <label>Contractor Type </label>
                          <input
                            type="text"
                            name="contractor_type"
                            value={
                              values?.contractor_type
                                ? values?.contractor_type
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="contractor_type" />
                          </span>
                        </div>
                        <div className="form-group full-width">
                          <label>Account</label>
                          <ChartOfAccountSelect
                            name="chart_of_account_id"
                            onChange={(e) => {
                              setFieldValue("chart_of_account_id", e);
                            }}
                            className="contactname-select contractor-type-text-left"
                            projectId={projectId}
                            value={
                              values?.chart_of_account_id
                                ? values?.chart_of_account_id
                                : null
                            }
                            placeholder="Select Account"
                            isClearable={false}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="chart_of_account_id" />
                          </span>
                        </div>
                        <div className="form-group full-width">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            value={
                              values?.description ? values?.description : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                            style={{
                              borderRadius: "6px",
                              margin: "0 0 15px",
                              minHeight: "70px",
                            }}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="description" />
                          </span>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <Button
                          type="Button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            toggleAccountModal();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default ContractorType;
