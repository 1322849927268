import { ErrorMessage, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../config";
import Spin from "../../../common/Spin";
import { InviteAccountingFirmSchema } from "../../../common/Validation";

const InviteAccountingFirmModal = ({
  showInviteAccountingFirm,
  handleInviteAccountingFirm,
}) => {
  const builderId = localStorage.getItem("builderId");

  const [loading, setLoading] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.builder_id = builderId ? builderId : "";

    formData.append("email", value?.email ? value?.email : "");

    formData.append("builder_id", value?.builder_id ? value?.builder_id : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.INVITE_ACCOUNTING_FIRM}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        handleInviteAccountingFirm();
      }
      return data?.data;
    } catch (error) {
      setDefaultData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={showInviteAccountingFirm}
        onHide={handleInviteAccountingFirm}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        // className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Invite Accounting Firm
        </Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <Formik
            initialValues={{
              email: defaultData?.email,
            }}
            enableReinitialize
            validationSchema={InviteAccountingFirmSchema}
            validateOnBlur={true}
            validateOnChange={true}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="entry-section"
              >
                <div className="form-box">
                  <div className="form-group full-width">
                    <label style={{ marginRight: "100%" }}>
                      <span className="required">*</span>Email
                    </label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={values?.email ? values?.email : ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                    />
                    <span
                      className="text-danger"
                      style={{ color: "red", fontSize: "20px" }}
                    >
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                </div>

                <div className="modal-footer">
                  <Button type="submit">Invite</Button>
                  <Button
                    className="reset"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handleInviteAccountingFirm();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteAccountingFirmModal;
