import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import CancelButton from "../../../../../components/Form/CancleButton";
import { Button } from "react-bootstrap";
import ChartOfAccountSelect from "../../../../common/ChartOfAccountSelect";
import ContractorSelectWithTA from "../../../../common/ContractorSelectWithTA";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  projectId,
  handleContractorSelect,
  disableState,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    projectId: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="entry-card full-card add-business-client">
        <h3>Term Details</h3>
        <div className="form-box">
          {/* <div class="form-box-half"> */}
          <div className="form-group half-width" style={{ width: "32.5%" }}>
            <span className="required">*</span>
            <label>Term</label>
            <input
              type="text"
              name="term"
              value={values?.term ? values?.term : ""}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="term" />
            </span>
          </div>
          <div className="form-group half-width" style={{ width: "32.5%" }}>
            <label>
              <span className="required">*</span>Chart of Account Name
            </label>

            <ChartOfAccountSelect
              name="chart_of_account_id"
              onChange={(e) => {
                setFieldValue("chart_of_account_id", e);
              }}
              projectId={projectId}
              value={
                values?.chart_of_account_id ? values?.chart_of_account_id : null
              }
              placeholder={"Select Account"}
              isClearable={false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="chart_of_account_id" />
            </span>
          </div>

          <div className="form-group half-width" style={{ width: "32.5%" }}>
            <label>
              <span className="required">*</span>Sub Contactor
            </label>
            <ContractorSelectWithTA
              regionName={"0"}
              business={true}
              projectId={projectId}
              value={values?.contact_id ? values?.contact_id : null}
              onChange={(contractor) => {
                handleContractorSelect(contractor);
              }}
              className="contactname-select"
              placeholder="Select Subcontractor"
              isClearable={false}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="contact_id" />
            </span>
          </div>

          <div className="form-group full-width">
            <label>Description</label>
            <textarea
              type="text"
              name="description"
              value={values?.description ? values?.description : ""}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              onBlur={handleBlur}
              autoComplete="off"
              onKeyPress={(e) => {
                e.which === 13 && e.preventDefault();
              }}
              style={{
                borderRadius: "10px",
                margin: "0 0 15px",
                minHeight: "70px",
              }}
            />
            <span
              className="text-danger"
              style={{ color: "red", fontSize: "20px" }}
            >
              <ErrorMessage name="description" />
            </span>
          </div>

          {/* </div> */}
        </div>
      </div>

      <div className="action-box">
        <Button type="submit" disabled={disableState}>
          Save
        </Button>
        <CancelButton />
      </div>
    </form>
  );
};

export default Form;
