import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ImportedLogs from "./ImportedLogs";
import NotImportedLogs from "./NotImportedLogs";
import BackButton from "../../../components/Form/BackButton";
import { useProjectBar } from "../../common/useProjectBar";
import { imageDefaultPrefixPath } from "../../../config";

const ContactImportLogs = () => {
  const tabsKey = {
    imported: 1,
    "not-imported": 2,
  };
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "imported";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1 ? "Imported" : "Not Imported"
  );

  const { ProjectLogo, ProjectName, ProjectReadOnly } = useProjectBar();
  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              Contact Import Logs
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>

            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li className={activeTab === "Imported" ? "active" : ""}>
                    <Link key={1}>Imported</Link>
                  </li>
                  <li className={activeTab === "Not Imported" ? "active" : ""}>
                    <Link key={2}>Not Imported</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Imported" && (
                  <ImportedLogs ProjectReadOnly={ProjectReadOnly} />
                )}
                {activeTab === "Not Imported" && (
                  <NotImportedLogs ProjectReadOnly={ProjectReadOnly} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactImportLogs;
