import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";

const FilterCardPopup = ({
  filter,
  setFilter,
  sort,
  setSort,
  sortOptions,
  handleSort,
  filterOptions,
  handleFilter,
  isPopupVisible,
  setIsPopupVisible,
}) => {
  const popupRef = useRef(null);
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const clearFilter = () => {
    setFilter();
    setSort();
    setIsPopupVisible(false);
  };

  const handleOutsideClick = (event) => {
    // Close popup if the click is outside the popup
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupVisible]);

  return (
    <div className="filter-sort-body">
      {/* Filter Icon */}
      <button onClick={togglePopup} className="filter-btn" title="Filter and Sorting">
        <FontAwesomeIcon icon={faSliders} />
      </button>

      {/* Popup Card */}
      {isPopupVisible && (
        <div
          ref={popupRef}
          className="filter-card-body"
        >
          <div className="filter-sort">
            <div className="form-group refund-card-content">
              <label>Filter By</label>
              <ReactSelect
                name="project_status"
                value={
                  filter
                    ? filterOptions?.find((item) => item?.value === filter)
                    : ""
                }
                onChange={(e) => {
                  handleFilter(e);
                }}
                options={filterOptions}
                placeholder="Select Filter By "
                classNamePrefix="selectbox"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                isSearchable={false}
              />
            </div>
            <div className="form-group refund-card-content">
              <label>Sort By</label>
              <ReactSelect
                name="project_status"
                value={
                  sort ? sortOptions?.find((item) => item?.value === sort) : ""
                }
                onChange={(e) => {
                  handleSort(e);
                }}
                options={sortOptions}
                placeholder="Select Sort By "
                classNamePrefix="selectbox"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.which === 13 && e.preventDefault();
                }}
                isSearchable={false}
              />
            </div>
            <button className="clear-btn" onClick={() => clearFilter()}>
                Clear
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterCardPopup;
