import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button } from "react-bootstrap";
import SuburbSelect from "../../common/SuburbSelect";

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleOfficeModal,
  stateListing,
  stateLoading,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleOfficeModal: PropTypes.any,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Address
          </label>
          <input
            type="text"
            name="street"
            value={values?.street ? values?.street : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="street" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select State
          </label>
          <Select
            name="state_id"
            value={values?.state_id ? values?.state_id : ""}
            onChange={(e) => {
              setFieldValue(`state_id`, e ? e : "");
              setFieldValue(`suburb_id`, "");
            }}
            onBlur={handleBlur}
            options={stateListing}
            placeholder="Select State"
            classNamePrefix="selectbox"
            isClearable
            isLoading={stateLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="state_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select Suburb
          </label>

          <SuburbSelect
            name="suburb_id"
            regionName={values?.state_id}
            onChange={(e) => {
              setFieldValue("suburb_id", e ? e : "");
            }}
            value={values?.suburb_id ? values?.suburb_id : null}
            placeholder={"Select Suburb"}
            isClearable={true}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="suburb_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Postal Code
          </label>
          <InputMask
            type="text"
            name="postal_code"
            value={values?.postal_code ? values?.postal_code : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="postal_code" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
          type="Button"
          onClick={() => {
            toggleOfficeModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
