import { toast } from "react-toastify";
import { API, defaultLimitPagination, get, post } from "../../../../config";

export const getUserDetail = async (id) => {
  try {
    const { data } = await get(`${API.GET_USER}/` + id);
    return data.data;
  } catch (e) {
    return null;
  }
};

export const refreshBankTransactions = async (
  projectId,
  trust_type,
  startDate,
  endDate
) => {
  try {
    const { data } = await post(`${API.REFRESH_BANK_TRANSACTION}`, {
      builder_project_id: projectId,
      trust_type: trust_type,
      start_date: startDate,
      end_date: endDate,
    });
    return data;
  } catch (error) {
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((key) => {
        toast.error(error?.response?.data?.errors[key][0]);
      });
    } else {
      toast.error(error?.message);
    }
  }
};

export const getNextPageData = async (
  page,
  projectId,
  trust_type,
  sortField,
  sortOrder
) => {
  try {
    const { data } = await get(
      `${
        API.BANK_RECONCILIATION_LIST
      }?builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}&limit=${defaultLimitPagination}&page=${page}`
    );
    return data?.data;
  } catch (e) {
    throw e;
  }
};

export const getUnderReviewedNextPageData = async (
  page,
  projectId,
  trust_type,
  sortField,
  sortOrder
) => {
  try {
    const { data } = await get(
      `${
        API.BANK_RECONCILIATION_LIST
      }?builder_project_id=${projectId}&trust_type=${trust_type}&sort_column=${
        sortField ? sortField : ""
      }&sort_order=${sortOrder ? sortOrder : ""}&limit=${defaultLimitPagination}&page=${page}`
    );
    return data?.data;
  } catch (e) {
    throw e;
  }
};
