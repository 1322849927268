import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../../config";
import Spin from "../../../../common/Spin";
import { AddContractorTypeSchema } from "../../../../common/Validation";
import { getContractorTypeDetails } from "./ContractorTypeHelper";
import ChartOfAccountSelect from "../../../../common/ChartOfAccountSelect";

const EditContractorTypes = ({
  fetchContractorTypes,
  ContractorTypeId,
  toggleContractorTypeModal,
  showAccount,
}) => {
  const params = useParams();
  const projectId = params?.project_id;
  const [loading, setLoading] = useState(false);
  const formValues = useRef();
  const [contractorTypeData, setContractorTypeData] = useState();
  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    formData.append("name", value.contractor_type ? value.contractor_type : "");
    formData.append("description", value.description ? value.description : "");
    formData.append("chart_of_account_id", value.chart_of_account_id ? value?.chart_of_account_id?.id : "");
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append("_method", "PUT");

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.CONTRACTOR_TYPE_Add}/${ContractorTypeId}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleContractorTypeModal();
      }
      fetchContractorTypes();
      return data?.data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const data = await getContractorTypeDetails(ContractorTypeId);
        setContractorTypeData(data);
        setLoading(false);
      } catch (e) {
        setContractorTypeData(null);
      }
    }
    fetchData();
  }, [ContractorTypeId]);

  const accountName = contractorTypeData?.chart_of_account
    ? {
        id: contractorTypeData?.chart_of_account?.id
          ? contractorTypeData?.chart_of_account?.id
          : "",
        label:
          contractorTypeData?.chart_of_account?.name +
          (contractorTypeData?.chart_of_account?.account_type?.name
            ? " (" +
              contractorTypeData?.chart_of_account?.account_type?.name +
              ")"
            : "") +
          (contractorTypeData?.chart_of_account?.code
            ? " - " + contractorTypeData?.chart_of_account?.code.toString()
            : "") +
          (contractorTypeData?.chart_of_account?.sub_code
            ? "." + contractorTypeData?.chart_of_account?.sub_code.toString()
            : ""),
        tax: contractorTypeData?.chart_of_account?.tax,
      }
    : {};
  return (
    <>
      {showAccount && contractorTypeData && (
        <Modal
          size="lg"
          show={showAccount}
          onHide={toggleContractorTypeModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Edit CONTRACTOR TYPES
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                contractor_type: contractorTypeData.name
                  ? contractorTypeData.name
                  : "",
                description: contractorTypeData.description
                  ? contractorTypeData.description
                  : "",
                chart_of_account_id: accountName ? accountName : "",
              }}
              enableReinitialize
              innerRef={formValues}
              onSubmit={handleFormSubmit}
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={AddContractorTypeSchema}
            >
              {({
                values,
                setErrors,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <>
                    {loading && <Spin />}
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      className="entry-section"
                    >
                      <div className="form-box">
                        <div className="form-group full-width">
                          <label>Contractor Type </label>
                          <input
                            type="text"
                            name="contractor_type"
                            value={
                              values?.contractor_type
                                ? values?.contractor_type
                                : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="contractor_type" />
                          </span>
                        </div>
                        <div className="form-group full-width">
                          <label>Account</label>
                          <ChartOfAccountSelect
                            name="chart_of_account_id"
                            onChange={(e) => {
                             setFieldValue("chart_of_account_id", e) 
                            }}
                            className="contactname-select contractor-type-text-left"
                            projectId={projectId}
                            value={
                              values?.chart_of_account_id
                                ? values?.chart_of_account_id
                                : null
                            }
                            placeholder="Select Account"
                            isClearable={false}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="chart_of_account_id" />
                          </span>
                        </div>
                        <div className="form-group full-width">
                          <label>Description</label>
                          <textarea
                            type="text"
                            name="description"
                            value={
                              values?.description ? values?.description : ""
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            onKeyPress={(e) => {
                              e.which === 13 && e.preventDefault();
                            }}
                            style={{
                              borderRadius: "10px",
                              margin: "0 0 15px",
                              minHeight: "70px",
                            }}
                          />
                          <span
                            className="text-danger"
                            style={{ color: "red", fontSize: "20px" }}
                          >
                            <ErrorMessage name="description" />
                          </span>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <Button
                          type="Button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            toggleContractorTypeModal();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit">Save</Button>
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default EditContractorTypes;
