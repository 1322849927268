import React, { useRef } from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button } from "react-bootstrap";
import SuburbSelect from "../../../common/SuburbSelect";
import { MultiSelect } from 'primereact/multiselect';

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  toggleOfficeModal,
  stateListing,
  stateLoading,
  projectLoading,
  projectListing,
  selectedProjects,
  setSelectedProjects,
  userListing,
  userLoading,
  setSelectedUsers,
  selectedUsers,
}) => {
  Form.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.any,
    handleChange: PropTypes.any,
    handleBlur: PropTypes.any,
    setFieldValue: PropTypes.any,
    handleSubmit: PropTypes.any,
    overwrittenFields: PropTypes.array,
    existingValues: PropTypes.array,
    toggleOfficeModal: PropTypes.any,
    stateListing: PropTypes.any,
    stateLoading: PropTypes.any,
  };

  const handleProjectChange = (e, setFieldValue) => {
    setFieldValue("builder_project_ids", e.value);
    setSelectedProjects(e.value);
  };

  const filterProjectInputRef = useRef();

  const handleProjectApplyFilter = () => {
    filterProjectInputRef.current.hide();
  };

  const ProjectResetFunction = () => {
    setSelectedProjects([]);
    filterProjectInputRef && filterProjectInputRef.current.focus();
    filterProjectInputRef.current.hide();
  };

  const filterUserInputRef = useRef();

  const handleUserApplyFilter = () => {
    filterUserInputRef.current.hide();
  };

  const userResetFunction = () => {
    setSelectedUsers([]);
    filterUserInputRef && filterUserInputRef.current.focus();
    filterUserInputRef.current.hide();
  };

  const handleUserChange = (e, setFieldValue) => {
    setFieldValue("user_ids", e.value);
    setSelectedUsers(e.value);
  };

  return (
    <form onSubmit={handleSubmit} noValidate className="entry-section">
      <div className="form-box">
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Address
          </label>
          <input
            type="text"
            name="street"
            value={values?.street ? values?.street : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="street" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select State
          </label>
          <Select
            name="state_id"
            value={values?.state_id ? values?.state_id : ""}
            onChange={(e) => {
              setFieldValue(`state_id`, e ? e : "");
              setFieldValue(`suburb_id`, "");
            }}
            onBlur={handleBlur}
            options={stateListing}
            placeholder="Select State"
            classNamePrefix="selectbox"
            isClearable
            isLoading={stateLoading}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="state_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Select Suburb
          </label>

          <SuburbSelect
            name="suburb_id"
            regionName={values?.state_id}
            onChange={(e) => {
              setFieldValue("suburb_id", e ? e : "");
            }}
            value={values?.suburb_id ? values?.suburb_id : null}
            placeholder={"Select Suburb"}
            isClearable={true}
          />

          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="suburb_id" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>
            <span className="required">*</span>Postal Code
          </label>
          <InputMask
            type="text"
            name="postal_code"
            value={values?.postal_code ? values?.postal_code : ""}
            onChange={handleChange}
            onBlur={handleBlur}
            mask="9999"
            maskChar={null}
            autoComplete="off"
            onKeyPress={(e) => {
              e.which === 13 && e.preventDefault();
            }}
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="postal_code" />
          </span>
        </div>
        <div className="form-group half-width">
          <label>Projects</label>
          <MultiSelect
            value={selectedProjects}
            selectedItemsLabel={`${selectedProjects?.length} Project(s) Selected`}
            name="builder_project_ids"
            onChange={(e) => {
              handleProjectChange(e, setFieldValue);
            }}
            options={projectListing}
            optionLabel="label"
            // filter
            placeholder="Select Projects"
            maxSelectedLabels={0}
            ref={filterProjectInputRef}
            panelFooterTemplate={
              <>
                <hr />
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    handleProjectApplyFilter();
                  }}
                >
                  OK
                </Button>
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    ProjectResetFunction();
                  }}
                >
                  Reset
                </Button>
              </>
            }
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="builder_project_ids" />
          </span>
        </div>

        <div className="form-group half-width">
          <label>User</label>
          <MultiSelect
            value={selectedUsers}
            selectedItemsLabel={`${selectedUsers?.length} User(s) Selected`}
            name="user_ids"
            onChange={(e) => {
              handleUserChange(e, setFieldValue);
            }}
            options={userListing}
            optionLabel="label"
            // filter
            placeholder="Select User"
            maxSelectedLabels={0}
            ref={filterUserInputRef}
            panelFooterTemplate={
              <>
                <hr />
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    handleUserApplyFilter();
                  }}
                >
                  OK
                </Button>
                <Button
                  className="multiSelect-button"
                  onClick={() => {
                    userResetFunction();
                  }}
                >
                  Reset
                </Button>
              </>
            }
          />
          <span
            className="text-danger"
            style={{ color: "red", fontSize: "20px" }}
          >
            <ErrorMessage name="office_ids" />
          </span>
        </div>
      </div>

      <div className="modal-footer">
        <Button type="submit">Save</Button>
        <Button
          className="reset"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            toggleOfficeModal();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default Form;
