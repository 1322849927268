import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, deleteCall } from "../../../../../config";
import Spin from "../../../../common/Spin";

const DeleteContractorType = ({
  ContractorTypeId,
  toggleDeleteModal,
  handleDelete,
  showDelete,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteContractorType = async () => {
    try {
      setLoading(true);
      const { data } = await deleteCall(
        `${API.CONTRACTOR_TYPE_DELETE}/${ContractorTypeId}`
      );
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      return null;
    }
  };
  return (
    <Modal
      size="lg"
      show={showDelete}
      onHide={toggleDeleteModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="project-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Delete Contractor Type
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are your sure you want to delete this Contractor Type?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleDeleteModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleDeleteContractorType();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
    // <div
    //   className="modal fade"
    //   id="deleteAccount"
    //   tabindex="-1"
    //   role="dialog"
    //   aria-labelledby="exampleModalCenterTitle"
    //   aria-hidden="true"
    //   onClick={() => {
    //     toggleDeleteModal();
    //   }}
    // >
    //   <div className="modal-dialog modal-dialog-centered" role="document">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title" id="exampleModalLongTitle">
    //           Delete Account
    //         </h5>
    //         <button
    //           type="button"
    //           className="close"
    //           data-dismiss="modal"
    //           aria-label="Close"
    //           onClick={() => {
    //             toggleDeleteModal();
    //           }}
    //         >
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">
    //         Are your sure you want to delete this account?
    //       </div>
    //       <div className="modal-footer">
    //         <button
    //           type="button"
    //           className="btn btn-secondary"
    //           data-dismiss="modal"
    //         >
    //           Cancel
    //         </button>
    //         <button
    //           type="submit"
    //           className="btn btn-primary"
    //           data-dismiss="modal"
    //           onClick={() => {
    //             handleDeleteContractorType();
    //           }}
    //         >
    //           OK
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default DeleteContractorType;
