import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";
import { formatName } from "./Misc";

const ContractorSelect = (props) => {
  const [regionName, setRegionName] = useState(null);

  useEffect(() => {
    props?.regionName !== undefined
      ? setRegionName(props?.regionName)
      : setRegionName(null);
  }, [props]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const search = searchQuery?.length >= 3 ? searchQuery : "";
    const response = await get(
      `${API.GET_CONTRACTORS}?builder_project_id=${
        props.projectId ? props.projectId : ""
      }&limit=${defaultLimitPagination}&page=${page && !search ? page : 1}&search=${
        search ? search : ""
      }&sort_column=name&sort_order=ASC`
    );
    const responseJSON = response?.data?.data?.data
      ? response?.data?.data?.data
      : [];

    const totalLength = loadedOptions?.length
      ? loadedOptions?.length + responseJSON?.length
      : responseJSON?.length;

    return {
      options: responseJSON,
      hasMore: totalLength < response?.data?.data?.total,
      additional: {
        page: search ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={regionName !== null ? JSON.stringify(regionName) : 0}
      value={props.value || props.defaultValue}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) =>
        formatName(option.first_name, option?.last_name)
      }
      classNamePrefix={props?.className || "selectbox"}
      onChange={onChange}
      isSearchable={true}
      placeholder={props?.placeholder || "Select Contractor"}
      additional={{
        page: 1,
      }}
      isClearable={props?.isClearable}
      isMulti={props?.isMulti ? props?.isMulti : false}
    />
  );
};

ContractorSelect.propTypes = {
  regionName: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default ContractorSelect;
