import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loginimg from "../../assets/images/Loginimg";
import Sitelogo from "../../components/Header/Logo";
import { Formik, ErrorMessage } from "formik";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useStateList } from "./FirmHelper";
import {
  FirmSignupAccountingFirmSchema,
  FirmSignupSchema,
} from "../common/Validation";
import { API, fileUpload, IV, SECRETKEY } from "../../config";
import { toast } from "react-toastify";
import Spin from "../common/Spin";
import { formatName } from "../common/Misc";
import SuburbSelect from "../common/SuburbSelect";

const FirmSignup = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const EmailVerification = localStorage.getItem("EmailVerification");
  const hasToken = localStorage.getItem("bt-auth-token");
  const clientId = localStorage.getItem("clientId");
  const [authentication] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultEmail = localStorage.getItem("invite-accountingFirmEmail");

  const blankData = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    password_confirmation: "",
    business_name: "",
    business_email: defaultEmail ? defaultEmail : "",
    street: "",
    state_id: "",
    suburb_id: "",
    postal_code: "",
  };

  const [defaultData, setDefaultData] = useState(blankData);

  const [activeTab, setActiveTab] = useState("Builder");

  const [eye1, seteye1] = useState(true);
  const [password1, setpassword1] = useState("password");
  const [eye2, seteye2] = useState(true);
  const [password2, setpassword2] = useState("password");

  const Eye1 = () => {
    if (password1 === "password") {
      setpassword1("text");
      seteye1(false);
    } else {
      setpassword1("password");
      seteye1(true);
    }
  };

  const Eye2 = () => {
    if (password2 === "password") {
      setpassword2("text");
      seteye2(false);
    } else {
      setpassword2("password");
      seteye2(true);
    }
  };

  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === defaultData?.state_id
  );

  var CryptoJS = require("crypto-js");

  const iv = IV;
  const key = SECRETKEY;

  const fkey = CryptoJS.enc.Utf8.parse(key);
  const fiv = CryptoJS.enc.Utf8.parse(iv);

  const handleFormSubmit = async (value, { resetForm }) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.state_id = value?.state_id ? value?.state_id?.value : "";
    value.suburb_id = value?.suburb_id ? value?.suburb_id?.id : "";

    const encPassword = CryptoJS.AES.encrypt(value?.password, fkey, {
      iv: fiv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const finalEncryptedPassword = encPassword.ciphertext.toString(
      CryptoJS.enc.Base64
    );

    const encConfirmPassword = CryptoJS.AES.encrypt(
      value?.password_confirmation,
      fkey,
      {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    const finalEncryptedConfirmPassword =
      encConfirmPassword.ciphertext.toString(CryptoJS.enc.Base64);

    formData.append("first_name", value?.first_name ? value?.first_name : "");
    formData.append("last_name", value?.last_name ? value?.last_name : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append(
      "password",
      finalEncryptedPassword ? finalEncryptedPassword : ""
    );
    formData.append(
      "password_confirmation",
      finalEncryptedConfirmPassword ? finalEncryptedConfirmPassword : ""
    );

    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "business_email",
      value?.business_email ? value?.business_email : ""
    );
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );

    formData.append(
      "user_type",
      activeTab === "Partner / Accounting Firm"
        ? "accounting_firm"
        : activeTab === "Builder"
        ? "builder"
        : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(API.FIRM_SIGNUP, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      if (data?.status === 200 && data?.message) {
        setDefaultData(blankData);
        toast.success(data?.message);
        resetForm();
        localStorage.setItem(
          "FullName",
          formatName(data?.data?.first_name, data?.data?.last_name)
        );
        localStorage.setItem(
          "bt-auth-email",
          data?.data?.email ? data?.data?.email : ""
        );

        localStorage.setItem("userId", data?.data?.id ? data?.data?.id : "");

        // navigate(`/subscription`);
      }
    } catch (error) {
      setDefaultData(value);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    EmailVerification &&
      userId &&
      hasToken &&
      // (clientId ? navigate("/businesses") : navigate("/clients"));
      (clientId ? navigate("/businesses") : navigate("/builders"));
  }, [EmailVerification, userId, clientId, hasToken, navigate]);

  return (
    <div className="register-cms-page">
      <div className="login-pages firm-login">
        {!authentication ? (
          <>
            {loading && <Spin />}
            <div className="img-block">
              <div className="login-img">
                <Loginimg />
              </div>
            </div>
            <div className="login-form-block" style={{ flex: "1 0" }}>
              <div className="login-form firm-form">
                <Sitelogo />

                <div className="table-top-btn">
                  <ul
                    className="nav nav-tabs"
                    onClick={(event) => {
                      setActiveTab(event?.target?.innerText);
                    }}
                  >
                    <li
                      className={
                        activeTab === "Partner / Accounting Firm"
                          ? "active"
                          : ""
                      }
                    >
                      <Link key={1}>Partner / Accounting Firm</Link>
                    </li>
                    <li className={activeTab === "Builder" ? "active" : ""}>
                      <Link key={2}>Builder</Link>
                    </li>
                  </ul>
                </div>

                <div className="content-details">
                  <section>
                    {activeTab === "Builder" && (
                      <Formik
                        initialValues={{
                          first_name: defaultData?.first_name,
                          last_name: defaultData?.last_name,
                          phone: defaultData?.phone,
                          email: defaultData?.email,
                          password: defaultData?.password,
                          password_confirmation:
                            defaultData?.password_confirmation,
                          business_name: defaultData?.business_name,
                          business_email: defaultData?.business_email,
                          street: defaultData?.street,
                          state_id: state,
                          suburb_id: defaultData?.suburb_id,
                          postal_code: defaultData?.postal_code,
                        }}
                        enableReinitialize
                        validationSchema={FirmSignupSchema}
                        validateOnBlur={true}
                        validateOnChange={true}
                        onSubmit={(value, { resetForm }) => {
                          handleFormSubmit(value, { resetForm });
                        }}
                      >
                        {({
                          values,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            noValidate
                            className="entry-section"
                          >
                            <small>
                              You are registering as a Head Contractor /
                              Builder.
                            </small>
                            <div className="entry-card">
                              <h3>User Details of Head Contractor / Builder</h3>
                              <div className="form-box">
                                {" "}
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="first_name"
                                    value={
                                      values?.first_name
                                        ? values?.first_name
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="first_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Last Name
                                  </label>
                                  <input
                                    type="text"
                                    name="last_name"
                                    value={
                                      values?.last_name ? values?.last_name : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="last_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Phone No.
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="phone"
                                    value={values?.phone ? values?.phone : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    mask="99 9999 9999"
                                    maskChar={null}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="phone" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Email
                                    (For Login)
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    value={values?.email ? values?.email : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="email" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Password
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type={password1}
                                      name="password"
                                      value={
                                        values?.password ? values?.password : ""
                                      }
                                      autoComplete="off"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="signupPassword"
                                    />
                                    <i
                                      onClick={Eye1}
                                      style={{
                                        position: "absolute",
                                        right: "13px",
                                        cursor: "pointer",
                                        top: "18px",
                                        transform: "translateY(-50%)",
                                        marginTop: "0px",
                                      }}
                                      className={`icon ${
                                        eye1
                                          ? "icon-eye-close"
                                          : "icon-eye-open"
                                      }`}
                                    ></i>
                                  </div>
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="password" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Re-type
                                    Password
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type={password2}
                                      name="password_confirmation"
                                      value={
                                        values?.password_confirmation
                                          ? values?.password_confirmation
                                          : ""
                                      }
                                      className="signupPassword"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i
                                      onClick={Eye2}
                                      style={{
                                        position: "absolute",
                                        right: "13px",
                                        cursor: "pointer",
                                        top: "18px",
                                        transform: "translateY(-50%)",
                                        marginTop: "0px",
                                      }}
                                      className={`icon ${
                                        eye2
                                          ? "icon-eye-close"
                                          : "icon-eye-open"
                                      }`}
                                    ></i>
                                  </div>
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="password_confirmation" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="entry-card">
                              <h3>
                                Business Details of Head Contractor / Builder
                              </h3>
                              <div className="form-box">
                                {" "}
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Business
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="business_name"
                                    value={
                                      values?.business_name
                                        ? values?.business_name
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="business_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Business
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    name="business_email"
                                    value={
                                      values?.business_email
                                        ? values?.business_email
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="business_email" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Street
                                  </label>
                                  <input
                                    type="text"
                                    name="street"
                                    value={values?.street ? values?.street : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="street" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Select
                                    State
                                  </label>
                                  <Select
                                    type="text"
                                    name="state_id"
                                    value={
                                      values?.state_id ? values?.state_id : ""
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`state_id`, e ? e : "");
                                      setFieldValue(`suburb_id`, "");
                                    }}
                                    classNamePrefix="selectbox"
                                    options={stateListing}
                                    isLoading={
                                      stateLoading ? stateLoading : false
                                    }
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="state_id" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Select
                                    Suburb
                                  </label>
                                  <SuburbSelect
                                    name="suburb_id"
                                    regionName={values?.state_id}
                                    onChange={(e) => {
                                      setFieldValue("suburb_id", e ? e : "");
                                    }}
                                    value={
                                      values?.suburb_id
                                        ? values?.suburb_id
                                        : null
                                    }
                                    placeholder={"Select Suburb"}
                                    isClearable={true}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="suburb_id" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Post Code
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="postal_code"
                                    value={
                                      values?.postal_code
                                        ? values?.postal_code
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                    mask="9999"
                                    maskChar={null}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="postal_code" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="action-box">
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsAgree(e.target.checked);
                                  }}
                                ></input>{" "}
                                I agree to the{" "}
                                <Link
                                  target="_blank"
                                  to={{ pathname: "/terms-of-use" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  {" "}
                                  Terms of Use
                                </Link>
                                {/* &
                                <Link
                                  target="_blank"
                                  to={{ pathname: "/partner-agreement" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  {" "}
                                  Partner Agreement
                                </Link> */}
                              </p>
                              <p>
                                Already have an account?{" "}
                                <Link
                                  to={{ pathname: "/" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                  // className="btn-nolink"
                                >
                                  Sign In
                                </Link>
                              </p>
                              <div>
                                <Button
                                  type="primary"
                                  htmltype="submit"
                                  disabled={!isAgree}
                                  className="createdbtn"
                                >
                                  Create{" "}
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                              </div>
                              <p
                                style={{
                                  width: "100%",
                                  color: "#FFF",
                                  fontSize: "14px",
                                }}
                              >
                                If you face any difficulties in the signup
                                process, please contact our{" "}
                                <Link
                                  // href={true}
                                  // href="https://trusteasy.com.au/contact-us/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  support team
                                </Link>
                              </p>
                            </div>
                          </form>
                        )}
                      </Formik>
                    )}

                    {activeTab === "Partner / Accounting Firm" && (
                      <Formik
                        initialValues={{
                          first_name: defaultData?.first_name,
                          last_name: defaultData?.last_name,
                          phone: defaultData?.phone,
                          email: defaultData?.email,
                          password: defaultData?.password,
                          password_confirmation:
                            defaultData?.password_confirmation,
                          business_name: defaultData?.business_name,
                          business_email: defaultData?.business_email,
                          street: defaultData?.street,
                          state_id: state,
                          suburb_id: defaultData?.suburb_id,
                          postal_code: defaultData?.postal_code,
                        }}
                        enableReinitialize
                        validationSchema={FirmSignupAccountingFirmSchema}
                        validateOnBlur={true}
                        validateOnChange={true}
                        onSubmit={(value, { resetForm }) => {
                          handleFormSubmit(value, { resetForm });
                        }}
                      >
                        {({
                          values,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <form
                            onSubmit={handleSubmit}
                            noValidate
                            className="entry-section"
                            autoComplete="off"
                          >
                            <small>
                              You are registering as a Partner / Accounting
                              Firm.
                            </small>
                            <div className="entry-card">
                              <h3>User Details of Partner / Accounting Firm</h3>
                              <div className="form-box">
                                {" "}
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>First
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="first_name"
                                    value={
                                      values?.first_name
                                        ? values?.first_name
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="first_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Last Name
                                  </label>
                                  <input
                                    type="text"
                                    name="last_name"
                                    value={
                                      values?.last_name ? values?.last_name : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="last_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Phone No.
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="phone"
                                    value={values?.phone ? values?.phone : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    mask="99 9999 9999"
                                    maskChar={null}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="phone" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Email
                                    (For Login)
                                  </label>
                                  <input
                                    type="text"
                                    name="email"
                                    value={values?.email ? values?.email : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="email" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Password
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type={password1}
                                      name="password"
                                      value={
                                        values?.password ? values?.password : ""
                                      }
                                      className="signupPassword"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i
                                      onClick={Eye1}
                                      style={{
                                        position: "absolute",
                                        right: "13px",
                                        cursor: "pointer",
                                        top: "18px",
                                        transform: "translateY(-50%)",
                                        marginTop: "0px",
                                      }}
                                      className={`icon ${
                                        eye1
                                          ? "icon-eye-close"
                                          : "icon-eye-open"
                                      }`}
                                    ></i>
                                  </div>
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="password" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Re-type
                                    Password
                                  </label>
                                  <div style={{ position: "relative" }}>
                                    <input
                                      type={password2}
                                      name="password_confirmation"
                                      value={
                                        values?.password_confirmation
                                          ? values?.password_confirmation
                                          : ""
                                      }
                                      className="signupPassword"
                                      autoComplete="off"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <i
                                      onClick={Eye2}
                                      style={{
                                        position: "absolute",
                                        right: "13px",
                                        cursor: "pointer",
                                        top: "18px",
                                        transform: "translateY(-50%)",
                                        marginTop: "0px",
                                      }}
                                      className={`icon ${
                                        eye2
                                          ? "icon-eye-close"
                                          : "icon-eye-open"
                                      }`}
                                    ></i>
                                  </div>
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="password_confirmation" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="entry-card">
                              <h3>Partner / Accounting Firm Details</h3>
                              <div className="form-box">
                                {" "}
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Name of
                                    the Practice / Partner
                                  </label>
                                  <input
                                    type="text"
                                    name="business_name"
                                    value={
                                      values?.business_name
                                        ? values?.business_name
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="business_name" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Business
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    name="business_email"
                                    value={
                                      values?.business_email
                                        ? values?.business_email
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="business_email" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Street
                                  </label>
                                  <input
                                    type="text"
                                    name="street"
                                    value={values?.street ? values?.street : ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="street" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Select
                                    State
                                  </label>
                                  <Select
                                    type="text"
                                    name="state_id"
                                    value={
                                      values?.state_id ? values?.state_id : ""
                                    }
                                    onChange={(e) => {
                                      setFieldValue(`state_id`, e ? e : "");
                                      setFieldValue(`suburb_id`, "");
                                    }}
                                    classNamePrefix="selectbox"
                                    options={stateListing}
                                    isLoading={
                                      stateLoading ? stateLoading : false
                                    }
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="state_id" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Select
                                    Suburb
                                  </label>
                                  <SuburbSelect
                                    name="suburb_id"
                                    regionName={values?.state_id}
                                    onChange={(e) => {
                                      setFieldValue("suburb_id", e ? e : "");
                                    }}
                                    value={
                                      values?.suburb_id
                                        ? values?.suburb_id
                                        : null
                                    }
                                    placeholder={"Select Suburb"}
                                    isClearable={true}
                                  />

                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="suburb_id" />
                                  </span>
                                </div>
                                <div className="form-group half-width">
                                  <label>
                                    <span className="required">*</span>Post Code
                                  </label>
                                  <InputMask
                                    type="text"
                                    name="postal_code"
                                    value={
                                      values?.postal_code
                                        ? values?.postal_code
                                        : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                    mask="9999"
                                    maskChar={null}
                                  />
                                  <span
                                    className="text-danger"
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    <ErrorMessage name="postal_code" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="action-box">
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setIsAgree(e.target.checked);
                                  }}
                                ></input>{" "}
                                I agree to the{" "}
                                <Link
                                  target="_blank"
                                  to={{ pathname: "/terms-of-use" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  {" "}
                                  Terms of Use
                                </Link>{" "}
                                &
                                <Link
                                  target="_blank"
                                  to={{ pathname: "/partners-agreement" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  {" "}
                                  Partners Agreement
                                </Link>
                              </p>
                              <p>
                                Already have an account?{" "}
                                <Link
                                  to={{ pathname: "/" }}
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                  // className="btn-nolink"
                                >
                                  Sign In
                                </Link>
                              </p>
                              <div>
                                <Button
                                  type="primary"
                                  htmltype="submit"
                                  disabled={!isAgree}
                                >
                                  Create{" "}
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </Button>
                              </div>
                              <p
                                style={{
                                  width: "100%",
                                  color: "#FFF",
                                  fontSize: "14px",
                                }}
                              >
                                If you face any difficulties in the signup
                                process, please contact our{" "}
                                <Link
                                  // href={true}
                                  // href="https://trusteasy.com.au/contact-us/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ borderBottom: "1px dotted #FFF" }}
                                >
                                  support team
                                </Link>
                              </p>
                            </div>
                          </form>
                        )}
                      </Formik>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </>
        ) : (
          {
            /* <Authentication /> */
          }
        )}
        {/* {hasToken && tfa === "2" ? <Navigate to={"/clients"} /> : <></>} */}
      </div>
    </div>
  );
};

export default FirmSignup;
