import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Spin from "../../common/Spin";

const PaymentScheduledApprovalModal = ({
  showModal,
  handleModalClose,
  handleSubmit,
  loading,
  id,
}) => {
  const [emailModal, setEmailModal] = useState(false);
  const toggleEmailModal = () => {
    setEmailModal(!emailModal);
  };
  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => {
          handleModalClose();
        }}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Payment Scheduled Approval</Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {loading && <Spin />}
            Do you want to approve these payment claims ?
          </div>
          <div className="modal-footer">
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => {
                handleModalClose();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => {
                toggleEmailModal(id);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {emailModal && (
        <Modal
          size="lg"
          show={emailModal}
          onHide={toggleEmailModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header closeButton>Send Email Confirmation</Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div
              className="content-details"
              style={{ paddingBottom: "10px", paddingTop: "10px" }}
            >
              <p>
                Would you like BuildTrust to send payment schedules via email
                now?
              </p>
            </div>
            <div className="modal-footer">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleSubmit(id, 1)}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => handleSubmit(id, 0)}
              >
                No
              </Button>
              {/* </div> */}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default PaymentScheduledApprovalModal;
