/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { getImportLogDetail } from "./ImportLogsHelper";
import moment from "moment";
import {
  formatABN,
  formatedAddress,
  formatMobile,
  formatPhone,
} from "../../common/Misc";
import { Modal } from "react-bootstrap";
import Spin from "../../common/Spin";

const ImportLogDetail = ({
  //data,
  importLogId,
  toggleShowModal,
  importLogModal,
  projectId,
}) => {
  const [importLogDetail, setImportLogDetail] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  //   const [verifiedByUser, setVerifiedByUser] = useState(null);
  //   const [statementData, setStatementData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const importLogRes = await getImportLogDetail(importLogId, projectId);
      let contactDetails = JSON.parse(importLogRes?.contact_details);
      setContactDetail(contactDetails);
      setImportLogDetail(importLogRes);

      setLoading(false);
    }
    fetchData();
  }, [importLogId, projectId]);

  const Status = (id, description) => {
    if (id === 0 && description) {
      return `Contact data not imported because ${description}`;
    } else {
      if (id === 1) {
        return "Contact Created";
      } else {
        return "Contact Updated";
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={importLogModal}
        onHide={toggleShowModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          Subcontractor(Beneficiary) Details
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Spin />
          ) : (
            <>
              <div className="modal-content-details justify-center">
                <div className="profile full">
                  <span>
                    {contactDetail?.Name
                      ? `${contactDetail?.Name?.charAt(0)}`
                      : contactDetail?.FirstName
                      ? contactDetail?.FirstName?.charAt(0)
                      : !contactDetail?.FirstName && contactDetail?.LastName
                      ? contactDetail?.LastName?.charAt(0)
                      : ""}
                  </span>
                  <h5>
                    {contactDetail?.Name
                      ? `${contactDetail?.Name} ${
                          contactDetail?.FirstName && contactDetail?.LastName
                            ? "(" +
                              contactDetail?.FirstName +
                              " " +
                              contactDetail?.LastName +
                              ")"
                            : !contactDetail?.FirstName &&
                              contactDetail?.LastName
                            ? "(" + contactDetail?.LastName + ")"
                            : ""
                        }`
                      : contactDetail?.FirstName && contactDetail?.LastName
                      ? contactDetail?.FirstName + " " + contactDetail?.LastName
                      : !contactDetail?.FirstName && contactDetail?.LastName
                      ? contactDetail?.LastName
                      : ""}
                  </h5>
                </div>
                {/* <div className="customer-account">
                  <div className="action-block">
                    <span className="import-status">
                      <strong>Import Date:</strong>
                      {importLogDetail?.created_at
                        ? moment(
                            importLogDetail?.created_at,
                            "DD-MM-YYYY HH:mm:ss"
                          ).format("DD-MM-YYYY")
                        : "-"}
                      <br />
                      <strong>Note:</strong>
                      {importLogDetail?.status == null ||
                      importLogDetail?.status == ""
                        ? "-"
                        : Status(
                            parseInt(importLogDetail?.status),
                            importLogDetail?.description
                          )}
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="modal-content">
                <h3>Personal Details</h3>
                <div className="modal-content-details">
                  {/* <div className="modal-content-left"> */}
                  <p>
                    <strong>First Name:</strong>{" "}
                    {contactDetail?.FirstName ? contactDetail?.FirstName : "-"}
                  </p>
                  <p>
                    <strong>Last Name:</strong>{" "}
                    {contactDetail?.LastName ? contactDetail?.LastName : "-"}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {contactDetail?.Phones?.find(
                      (item) => item?.PhoneType === "DEFAULT"
                    )?.PhoneNumber == null ||
                    // eslint-disable-next-line eqeqeq
                    contactDetail?.Phones?.find(
                      (item) => item?.PhoneType === "DEFAULT"
                    )?.PhoneNumber == ""
                      ? "-"
                      : formatPhone(
                          contactDetail?.Phones?.find(
                            (item) => item?.PhoneType === "DEFAULT"
                          )?.PhoneNumber
                        )}
                  </p>
                  <p>
                    <strong>Mobile Number:</strong>
                    {contactDetail?.Phones?.find(
                      (item) => item?.PhoneType === "MOBILE"
                    )?.PhoneNumber == null ||
                    // eslint-disable-next-line eqeqeq
                    contactDetail?.Phones?.find(
                      (item) => item?.PhoneType === "MOBILE"
                    )?.PhoneNumber == ""
                      ? "-"
                      : formatMobile(
                          contactDetail?.Phones?.find(
                            (item) => item?.PhoneType === "MOBILE"
                          )?.PhoneNumber
                        )}
                  </p>

                  <>
                    {contactDetail?.Addresses?.length > 0
                      ? contactDetail?.Addresses?.map((item) => (
                          <p>
                            <strong>
                              {item?.AddressType === "POBOX"
                                ? "Postal"
                                : "Street"}{" "}
                              Address:
                            </strong>{" "}
                            {formatedAddress(
                              item?.AddressLine1,
                              item?.City,
                              item?.Region,
                              item?.PostalCode
                            )}
                          </p>
                        ))
                      : ""}
                  </>
                  {/* </div> */}
                  {/* <div className="modal-content-right"> */}
                  <p>
                    <strong>Business:</strong>{" "}
                    {contactDetail?.Name == null || contactDetail?.Name == ""
                      ? "-"
                      : contactDetail?.Name}
                  </p>
                  <p>
                    <strong>ABN:</strong>{" "}
                    {contactDetail?.TaxNumber == null ||
                    contactDetail?.TaxNumber == ""
                      ? "-"
                      : formatABN(contactDetail?.TaxNumber)}
                  </p>
                  <p>
                    <strong>Email Address:</strong>{" "}
                    {contactDetail?.EmailAddress
                      ? contactDetail?.EmailAddress
                      : ""}
                  </p>
                </div>
              </div>

              <div className="modal-content">
                <h3>Bank Details</h3>
                <div className="modal-content-details">
                  {/* <div className="modal-content-left"> */}
                  <p>
                    <strong>Account Name:</strong>{" "}
                    {contactDetail?.BatchPayments?.BankAccountName == null ||
                    contactDetail?.BatchPayments?.BankAccountName == ""
                      ? "-"
                      : contactDetail?.BatchPayments?.BankAccountName}
                  </p>
                  <p>
                    <strong>Account Number:</strong>{" "}
                    {contactDetail?.BatchPayments?.BankAccountNumber == null ||
                    contactDetail?.BatchPayments?.BankAccountNumber == "" ||
                    contactDetail?.BatchPayments?.BankAccountNumber <= 6
                      ? "-"
                      : // : 'xxxx xxxx ' + contactDetail?.BatchPayments?.BankAccountNumber?.toString().slice(-4)}
                        contactDetail?.BatchPayments?.BankAccountNumber?.toString()?.slice(
                          6
                        )}
                  </p>
                  <p>
                    <strong>BSB:</strong>{" "}
                    {contactDetail?.BatchPayments?.BankAccountNumber == null ||
                    contactDetail?.BatchPayments?.BankAccountNumber == "" ||
                    contactDetail?.BatchPayments?.BankAccountNumber?.length < 6
                      ? "-"
                      : contactDetail?.BatchPayments?.BankAccountNumber?.toString()?.slice(
                          0,
                          6
                        )}
                  </p>
                  {/* </div> */}
                </div>
              </div>
              <div className="modal-content">
                <h3>Sync</h3>
                <div className="modal-content-details">
                  <p>
                    <strong>Import From:</strong>
                    {importLogDetail?.import_from
                      ? importLogDetail?.xero_tenant_name
                        ? importLogDetail?.import_from +
                          `(${importLogDetail?.xero_tenant_name})`
                        : importLogDetail?.import_from
                      : ""}
                  </p>
                  <p>
                    <strong>Import Date:</strong>
                    {importLogDetail?.created_at
                      ? moment(
                          importLogDetail?.created_at,
                          "DD-MM-YYYY HH:mm:ss"
                        ).format("DD-MM-YYYY")
                      : "-"}
                  </p>
                  <p>
                    <strong>Note:</strong>
                    {importLogDetail?.status == null ||
                    importLogDetail?.status == ""
                      ? "-"
                      : Status(
                          parseInt(importLogDetail?.status),
                          importLogDetail?.description
                        )}
                  </p>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportLogDetail;
