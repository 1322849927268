import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../../components/Form/BackButton";
import {
  API,
  defaultLimitPagination,
  get,
  imageDefaultPrefixPath,
} from "../../../config";
import {
  checkIfFileExists,
  // disabledFutureDates,
  formatAccountNumber,
  formatBSBNumber,
  formatNumber,
  NegativeAmountConverter,
  pastFinancialdate,
} from "../../common/Misc";
import { getProjectData } from "../components/ProjectHelper";
import ContractorSelect from "../../common/ContractorSelect";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import moment from "moment";
import { MonthRangePicker } from "@semcore/date-picker";
import Pagination from "../../common/Pagination";

const TransactionsReport = () => {
  const params = useParams();
  const projectId = params?.project_id;
  //   const trustType = params?.trust_type;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const location = useLocation();
  const contractorData = location?.state?.contractorData
    ? location?.state?.contractorData
    : null;
  const [contractorDetails, setContractorDetails] = useState(contractorData);
  const [exportLoading, setExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allReportData, setAllReportData] = useState({});
  const [projectData, setProjectData] = useState({});
  const [reportData, setReportData] = useState([]);
  const [valueRange, setValueRange] = useState(pastFinancialdate);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [ProjectName, setProjectName] = useState();
  const [ProjectLogo, setProjectLogo] = useState();
  useEffect(() => {
    getProjectData(projectId, setProjectName, setProjectLogo);
  }, [projectId]);

  useEffect(() => {
    if (ProjectLogo && typeof ProjectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${ProjectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(ProjectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [ProjectLogo]);

  const handleContractorSelect = (contractor) => {
    setContractorDetails(contractor);
  };

  const fetchTransactionsReportData = useCallback(async () => {
    if (projectId) {
      try {
        setLoading(true);
        const { data } = await get(
          `${API.TRANSACTION_REPORT}?builder_project_id=${
            projectId ? projectId : ""
          }&contact_id=${
            contractorDetails?.id ? contractorDetails?.id : ""
          }&trust_type=${trust_type}&start_month=${
            moment(valueRange[0]).format("MM-YYYY")
              ? moment(valueRange[0]).format("MM-YYYY")
              : ""
          }&end_month=${
            moment(valueRange[1]).format("MM-YYYY")
              ? moment(valueRange[1]).format("MM-YYYY")
              : ""
          }&export=0&limit=${limit ? limit : defaultLimitPagination}&page=${
            page ? page : 1
          }`
        );

        setLoading(false);
        setLimit(data?.data?.reports?.per_page);
        setPage(data?.data?.reports?.current_page);
        setTotal(data?.data?.reports?.total);
        setFrom(data?.data?.reports?.from);
        setTo(data?.data?.reports?.to);
        const projectData = data?.data?.project ? data?.data?.project : {};
        const reportData = data?.data?.reports?.data
          ? Object.values(data?.data?.reports?.data)
          : [];

        setAllReportData(data?.data ? data?.data : "");
        setProjectData(projectData);
        setReportData(reportData);
      } catch (error) {
        setLoading(false);
        setAllReportData({});
        setProjectData();
        setReportData([]);

        if (error?.response?.data?.errors) {
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          toast.error(error?.message);
        }
      }
    }
  }, [projectId, trust_type, valueRange, contractorDetails, limit, page]);

  const handleExport = async (format) => {
    if (projectId) {
      try {
        setExportLoading(true);
        const { data } = await get(
          `${API.TRANSACTION_REPORT}?builder_project_id=${
            projectId ? projectId : ""
          }&contact_id=${
            contractorDetails?.id ? contractorDetails?.id : ""
          }&trust_type=${trust_type}&start_month=${
            moment(valueRange[0]).format("MM-YYYY")
              ? moment(valueRange[0]).format("MM-YYYY")
              : ""
          }&end_month=${
            moment(valueRange[1]).format("MM-YYYY")
              ? moment(valueRange[1]).format("MM-YYYY")
              : ""
          }&export=1&format=${format}`
        );
        const export_report_path = data?.data?.file;
        if (export_report_path) {
          var a = document.createElement("a");
          a.href = export_report_path;
          var file = export_report_path.split("/");
          a.setAttribute(
            "download",
            file[file.length - 1] || "transactionsreport.pdf"
          );
          a.setAttribute("target", "_blank");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          setExportLoading(false);
        }
      } catch (e) {
        setExportLoading(false);
        const errors = e.response?.data?.errors;
        Object.keys(errors).forEach((key) => {
          toast.error(errors[key][0]);
        });
      }
    }
  };
  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTransactionsReportData();
  }, [fetchTransactionsReportData]);

  let tomorrow = new Date();
  tomorrow.setMonth(tomorrow.getMonth() + 1);

  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <h1 className="page-title">
            <BackButton />
            {trust_type === 1
              ? `PTA Transactions Report (Cashbook)`
              : `RTA Transactions Report (Cashbook)`}
            <div className="page-title-right">
              {ProjectLogo && (
                <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
              )}{" "}
              {ProjectName}
            </div>
          </h1>
          {trust_type === 1 ? (
            <>
              {(projectData?.pta_contractor?.account_name ||
                projectData?.pta_contractor?.bsb ||
                projectData?.pta_contractor?.account_number) && (
                <div className="header-content-details">
                  <div className="firm-details">
                    <div className="firm-summary">
                      <div className="row">
                        <div className="col-md-4 text-left">
                          <strong>Account Name: </strong>
                          {projectData?.pta_contractor?.account_name
                            ? projectData?.pta_contractor?.account_name
                            : ""}
                        </div>

                        <div className="col-md-4 text-center">
                          <strong>BSB: </strong>
                          {projectData?.pta_contractor?.bsb
                            ? formatBSBNumber(projectData?.pta_contractor?.bsb)
                            : ""}
                        </div>

                        <div className="col-md-4 text-right">
                          <strong>Account Number: </strong>
                          {projectData?.pta_contractor?.account_number
                            ? formatAccountNumber(
                                projectData?.pta_contractor?.account_number
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {projectData?.rta_project_id ? (
                <>
                  {(projectData?.rta_project?.rta_contractor?.account_name ||
                    projectData?.rta_project?.rta_contractor?.bsb ||
                    projectData?.rta_project?.rta_contractor
                      ?.account_number) && (
                    <div className="header-content-details">
                      <div className="firm-details">
                        <div className="firm-summary">
                          <div className="row">
                            <div className="col-md-4 text-left">
                              <strong>Account Name: </strong>
                              {projectData?.rta_project?.rta_contractor
                                ?.account_name
                                ? projectData?.rta_project?.rta_contractor
                                    ?.account_name
                                : ""}
                            </div>

                            <div className="col-md-4 text-center">
                              <strong>BSB: </strong>
                              {projectData?.rta_project?.rta_contractor?.bsb
                                ? formatBSBNumber(
                                    projectData?.rta_project?.rta_contractor
                                      ?.bsb
                                  )
                                : ""}
                            </div>

                            <div className="col-md-4 text-right">
                              <strong>Account Number: </strong>
                              {projectData?.rta_project?.rta_contractor
                                ?.account_number
                                ? formatAccountNumber(
                                    projectData?.rta_project?.rta_contractor
                                      ?.account_number
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {(projectData?.rta_contractor?.account_name ||
                    projectData?.rta_contractor?.bsb ||
                    projectData?.rta_contractor?.account_number) && (
                    <div className="header-content-details">
                      <div className="firm-details">
                        <div className="firm-summary">
                          <div className="row">
                            <div className="col-md-4 text-left">
                              <strong>Account Name: </strong>
                              {projectData?.rta_contractor?.account_name
                                ? projectData?.rta_contractor?.account_name
                                : ""}
                            </div>

                            <div className="col-md-4 text-center">
                              <strong>BSB: </strong>
                              {projectData?.rta_contractor?.bsb
                                ? formatBSBNumber(
                                    projectData?.rta_contractor?.bsb
                                  )
                                : ""}
                            </div>

                            <div className="col-md-4 text-right">
                              <strong>Account Number: </strong>
                              {projectData?.rta_contractor?.account_number
                                ? formatAccountNumber(
                                    projectData?.rta_contractor?.account_number
                                  )
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="content-details">
            <div>
              <div className="table-top-btn">
                <div className="search-section">
                  <ContractorSelect
                    regionName={"0"}
                    projectId={projectId}
                    value={contractorDetails ? contractorDetails : null}
                    onChange={(contractor) => {
                      handleContractorSelect(contractor);
                    }}
                    className="contactname-select"
                    placeholder="Select Subcontractor"
                    isClearable={true}
                    isMulti={false}
                  />
                </div>
                <div className="table-btn">
                  <div
                    className="table-top-picker"
                    style={{ marginBottom: "0px" }}
                  >
                    <MonthRangePicker
                      value={valueRange}
                      onChange={(date) => {
                        setValueRange(date);
                      }}
                      className="datefilter"
                      disabled={[[tomorrow, false]]}
                    />
                  </div>
                  <Button onClick={() => handleExport("pdf")}>
                    {exportLoading ? "Loading…" : "Export"}
                  </Button>
                  {/* <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      // disabled={contractorDetails ? false : true}
                    >
                      {exportLoading ? "Loading…" : "Export"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleExport("csv")}>
                        CSV
                      </Dropdown.Item> 
                      <Dropdown.Item onClick={() => handleExport("pdf")}>
                        PDF
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleExport("xlsx")}>
                        Excel
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
              </div>
            </div>
            <div className="custom-table react-bootstrap-table">
              {loading && <Spin />}
              <table className="table table-bordered transaction-report">
                <thead>
                  <tr className="bold-row header">
                    <th style={{ width: "130px" }}>Date</th>
                    <th style={{ width: "120px" }}>Trans no.</th>
                    <th>Reason</th>
                    <th style={{ width: "140px" }}>Value</th>
                    <th>Deposit from / Withdrawal for</th>
                    <th style={{ width: "140px" }}>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bold-row">
                    <td>
                      {allReportData?.opening_balance_date
                        ? moment(
                            allReportData?.opening_balance_date,
                            "YYYY-MM-DD"
                          ).format("DD-MM-YYYY")
                        : ""}
                    </td>
                    <td colSpan={4}>Opening Balance</td>

                    <td>
                      {allReportData?.opening_balance
                        ? "$" +
                          formatNumber(Number(allReportData?.opening_balance))
                        : "$0.00"}
                    </td>
                  </tr>

                  {reportData &&
                    reportData?.length > 0 &&
                    reportData?.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          {item?.payment_data &&
                            item?.payment_data?.length > 0 &&
                            item?.payment_data?.map(
                              (paymentItem, paymentIndex) => {
                                return (
                                  <tr key={paymentIndex + index}>
                                    {paymentIndex === 0 && (
                                      <td
                                        rowSpan={item?.payment_data?.length}
                                        className="align-top"
                                      >
                                        {item?.transaction_date
                                          ? moment(
                                              item?.transaction_date,
                                              "YYYY-MM-DD"
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </td>
                                    )}
                                    <td className="align-top">
                                      {item?.transaction_id
                                        ? item?.transaction_id
                                        : ""}
                                    </td>
                                    <td className="align-top">
                                      {paymentItem?.description
                                        ? paymentItem?.description
                                        : ""}
                                    </td>
                                    <td
                                      className={`align-top ${
                                        item?.transaction_type
                                          ? "rag-green"
                                          : "rag-red"
                                      }`}
                                      style={
                                        item?.transaction_type
                                          ? { color: "#0DA500" }
                                          : { color: "#F00" }
                                      }
                                    >
                                      {paymentItem?.amount
                                        ? "$" +
                                          formatNumber(
                                            Number(paymentItem?.amount)
                                          )
                                        : "$0.00"}
                                    </td>
                                    <td className="align-top">
                                      {paymentItem?.contact_name
                                        ? paymentItem?.contact_name
                                        : ""}
                                      {item?.transaction_type === 0 && (
                                        <>
                                          <br />
                                          <strong>Account Name: </strong>
                                          {paymentItem?.account_name
                                            ? paymentItem?.account_name
                                            : ""}
                                          <br />
                                          <strong>BSB: </strong>
                                          {paymentItem?.bsb
                                            ? formatBSBNumber(paymentItem?.bsb)
                                            : ""}
                                          <br />
                                          <strong>Account Number: </strong>
                                          {paymentItem?.account_number
                                            ? formatAccountNumber(
                                                paymentItem?.account_number
                                              )
                                            : ""}
                                        </>
                                      )}
                                    </td>
                                    {paymentIndex === 0 && (
                                      <td
                                        rowSpan={item?.payment_data?.length}
                                        className="align-top"
                                      >
                                        {item?.closing_balance
                                          ? NegativeAmountConverter(
                                              item?.closing_balance
                                            )
                                          : "$0.00"}
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            )}
                        </React.Fragment>
                      );
                    })}
                  <tr className="bold-row">
                    <td>
                      {allReportData?.filter?.end_month
                        ? moment(
                            allReportData?.filter?.end_month,
                            "YYYY-MM-DD"
                          ).format("DD-MM-YYYY")
                        : ""}
                    </td>
                    <td colSpan={4}>Closing Balance</td>

                    <td>
                      {allReportData?.closing_balance
                        ? NegativeAmountConverter(
                            allReportData?.closing_balance
                          )
                        : "$0.00"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <Pagination
                total={total}
                limit={parseInt(limit)}
                currentPage={page}
                updateLimit={handleUpdateLimit}
                updatePage={handleChangePage}
                from={from}
                to={to}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsReport;
