import React, { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API, fileUpload } from "../../../../config";
import Spin from "../../../common/Spin";

const ReconcileItemUndo = ({
  itemId,
  toggleUndoModal,
  handleUndo,
  showUndo,
  projectId,
}) => {
  const [loading, setLoading] = useState(false);
  const handleReconcileItemUndo = useCallback(async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);

      const response = await fileUpload(
        `${API.UPDATE_BANK_RECONCILIATION}/undo/${itemId}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        handleUndo();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [itemId, handleUndo, projectId]);

  return (
    <Modal
      size="lg"
      show={showUndo}
      onHide={toggleUndoModal}
      dialogClassName="modal-50w small-popup review-popup small-review"
      aria-labelledby="contained-modal-title-vcenter"
      className="project-section"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Undo Reconciled Item
      </Modal.Header>
      <Modal.Body>
        {loading && <Spin />}
        <div className="modal-body">
          Are your sure you want to undo this reconciled item?
        </div>
        <div className="modal-footer">
          <Button
            type="Button"
            className="btn btn-secondary"
            onClick={() => {
              toggleUndoModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={() => {
              handleReconcileItemUndo();
            }}
          >
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReconcileItemUndo;
