import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
// import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./component/Form";
// import { API, fileUpload, imageDefaultPrefixPath } from "../../../../config";

import { AddPrincipalSchema } from "../../../common/Validation";
import Spin from "../../../common/Spin";
import { useCallback } from "react";
import { getPrincipalDetail, useStateList } from "./component/PrincipalHelper";
import { API, fileUpload } from "../../../../config";
import { toast } from "react-toastify";
import { useAllBankList } from "../../../Builders/Projects/components/ProjectsHelper";

const Principal = ({
  setProjectLogo,
  setProjectName,
  getProjectData,
  ProjectReadOnly,
}) => {
  const [loading, setLoading] = useState(false);
  const [principalData, setPrincipalData] = useState();
  const [principalId, setPrincipalId] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const params = useParams();
  const projectId = params?.project_id;

  const navigate = useNavigate();

  const fetchPrincipalData = useCallback(
    async (projectID) => {
      try {
        setLoading(true);
        const data = await getPrincipalDetail(projectID);
        setPrincipalData(data);
        setPrincipalId(data?.id);
        navigate(`/projects/${projectId}/settings?tab=principal`, {
          replace: true,
        });
        setLoading(false);
      } catch (e) {
        setPrincipalData(null);
      }
    },
    [navigate, projectId]
  );

  useEffect(() => {
    fetchPrincipalData(projectId);
  }, [projectId, fetchPrincipalData]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
    value.abn = value?.abn ? `${value?.abn}`.replace(/ /g, "") : "";

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append("name", value?.name ? value?.name : "");
    formData.append("email", value?.email ? value?.email : "");
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append("abn", value?.abn ? value?.abn : "");
    formData.append(
      "bank_id",
      value?.bank_id
        ? value?.bank_id?.value === "principal"
          ? ""
          : value?.bank_id?.value
        : ""
    );
    formData.append("bsb", value?.bsb ? value?.bsb : "000-000");
    formData.append("account_name", value?.name ? value?.name : "");
    formData.append(
      "account_number",
      value?.account_number ? value?.account_number : "000000001"
    );
    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );

    formData.append(
      "description",
      value?.description ? value?.description : ""
    );
    principalId && formData.append("_method", "put");

    try {
      setLoading(true);
      const { data } = principalId
        ? await fileUpload(`${API.PRINCIPAL}/${principalId}`, formData)
        : await fileUpload(`${API.PRINCIPAL}`, formData);

      toast.success(data?.message);

      setLoading(false);
      if (data) {
        if (projectId) {
          // navigate(`/projects/${projectId}/settings?tab=project-settings`);
          fetchPrincipalData(projectId);
          getProjectData(projectId, setProjectName, setProjectLogo);
        }
      }
      return data?.data;
    } catch (error) {
      setPrincipalData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // Bank
  const { allBankLoading, allBankList = [] } = useAllBankList();
  const allBanks = allBankList ? allBankList : [];
  // const bank = allBanks?.find(
  //   (item) => item?.value === principalData?.principal_contractor?.bank_id
  // );

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === principalData?.state_id
  );

  return (
    <>
      <Formik
        initialValues={{
          name: principalData?.name ? principalData?.name : "",
          email: principalData?.email ? principalData?.email : "",
          phone: principalData?.phone ? principalData?.phone : "",
          business_name: principalData?.business_name
            ? principalData?.business_name
            : "",
          abn: principalData?.abn ? principalData?.abn : "",

          // bank_id: bank ? bank : "",
          /*  bsb: principalData?.principal_contractor?.bsb
            ? principalData?.principal_contractor?.bsb
            : "",
          account_name: principalData?.principal_contractor?.account_name
            ? principalData?.principal_contractor?.account_name
            : "",
          account_number: principalData?.principal_contractor?.account_number
            ? principalData?.principal_contractor?.account_number
            : "", */
          street: principalData?.street ? principalData?.street : "",
          state_id: state ? state : "",
          suburb_id: principalData?.suburb ? principalData?.suburb : "",
          postal_code: principalData?.postal_code
            ? principalData?.postal_code
            : "",
          description: principalData?.description
            ? principalData?.description
            : "",
        }}
        enableReinitialize
        innerRef={formValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={AddPrincipalSchema}
      >
        {({
          values,
          setErrors,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <>
              {loading && <Spin />}
              <Form
                values={values}
                errors={errors}
                setErrors={setErrors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                formValues={formValues}
                overwrittenFields={overwrittenFields}
                existingValues={existingValues}
                stateListing={stateListing}
                stateLoading={stateLoading}
                allBanks={allBanks}
                allBankLoading={allBankLoading}
                ProjectReadOnly={ProjectReadOnly}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Principal;
