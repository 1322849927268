import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { API, post } from "../../config";

import "./authentication.css";
import Spin from "../../pages/common/Spin";

const AddBackupEmail = (props) => {
  const { backupEmail, setBackupEmail, submitEmail } = props;
  const [loading, setLoading] = useState(false);

  // *Add Backup email for 2FA of User using API - Start
  const changeBackupEmail = (event) => {
    setBackupEmail(event.target.value);
  };

  const addBackupEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    post(API.ADD_BACKUP_EMAIL, { backup_email: backupEmail })
      .then(() => {
        submitEmail();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  // *Add Backup email for 2FA of User using API - End

  return (
    <div className="form-block backupemail">
      <div className="login-form">
        <div className="AntContent">
          <form onSubmit={addBackupEmail}>
            {loading && <Spin />}
            <h3>Add a backup email</h3>
            <p>
              This email is only used to log into your account if you&apos;re
              locked out or don&apos;t have your phone.
            </p>
            <p>It must be different to the email you log in with.</p>
            <input
              autoFocus
              placeholder="Backup email address"
              defaultValue={backupEmail}
              value={backupEmail}
              onChange={changeBackupEmail}
            />
            <p className="topgap">
              <Button type="submit">Confirm</Button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBackupEmail;
