import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";
import { formatName } from "./Misc";

const BuilderSelect = (props) => {
  const [regionName, setRegionName] = useState(null);

  useEffect(() => {
    setRegionName(props.regionName);
  }, [props.regionName]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const search = searchQuery?.length >= 3 ? searchQuery : "";
    const response = await get(
      `${API.GET_BUILDERS}?limit=${defaultLimitPagination}&page=${page && !search ? page : 1}&search=${
        search ? search : ""
      }`
    );
    const responseJSON = response?.data?.data?.data
      ? response?.data?.data?.data
      : [];

    const totalLength = loadedOptions?.length
      ? loadedOptions?.length + responseJSON?.length
      : responseJSON?.length;

    return {
      options: responseJSON,
      hasMore: totalLength < response?.data?.data?.total,
      additional: {
        page: search ? 2 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(regionName)}
      value={props.value || props.defaultValue}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.builder?.id}
      getOptionLabel={(option) =>
        formatName(option.first_name, option?.last_name)
      }
      classNamePrefix="builder-select"
      onChange={onChange}
      isSearchable={true}
      placeholder={props.placeholder}
      additional={{
        page: 1,
      }}
      isClearable={props?.isClearable}
    />
  );
};

BuilderSelect.propTypes = {
  regionName: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default BuilderSelect;
