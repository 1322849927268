/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Spin from "../common/Spin";
import { MonthRangePicker } from "@semcore/date-picker";
import {
  checkIfFileExists,
  formatNumber,
  pastFinancialdate,
} from "../common/Misc";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, ProgressBar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API, fileUpload, get, imageDefaultPrefixPath } from "../../config";
import { toast } from "react-toastify";
import BuilderSelect from "../common/BuilderSelect";
import ProjectSelect from "../common/ProjectSelect";
// import Workinprogrees from "../../components/Workinprogrees";

const ProjectDashboard = () => {
  // const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [valueRange, setValueRange] = useState(pastFinancialdate);
  // const [builderLoading, setBuilderLoading] = useState(false);
  // const [builderList, setBuilderList] = useState([]);
  // const [projectValue, setProjectValue] = useState();
  const navigate = useNavigate();
  const params = useParams();
  const projectID = params?.project_id;
  const [projectId, setProjectId] = useState(projectID);

  const [projectLogo, setProjectLogo] = useState();
  const [projectName, setProjectName] = useState();

  const [builderId, setBuilderId] = useState();
  const [contractorData, setContractorData] = useState();
  const [builderData, setBuilderData] = useState();
  const [projectData, setProjectData] = useState();

  const [proCostData, setProCostData] = useState([]);
  const [ptaRtaChartData, setPtaRtaChartData] = useState([]);
  const [cashFlowData, setCashFlowData] = useState();

  const defaultPiChartData = [{ value: 100, name: "No Data", total: 0 }];
  const [subContractorPieChartData, setSubContractorPieChartData] =
    useState(defaultPiChartData);

  useEffect(() => {
    if (projectLogo && typeof projectLogo === "string") {
      checkIfFileExists(`${imageDefaultPrefixPath}${projectLogo}`, (exists) => {
        if (exists) {
          setProjectLogo(projectLogo);
        } else {
          setProjectLogo("");
        }
      });
    } else {
      setProjectLogo("");
    }
  }, [projectLogo]);

  const fetchDashboardDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_PROJECT_DASHBOARD}?builder_project_id=${projectId}`
      );

      setBuilderId(data?.data?.builder?.builder?.id);

      setBuilderData(data?.data?.builder);
      setProjectData(data?.data?.project);
      setContractorData(data?.data?.contractors);
      setProjectLogo(data?.data?.project?.logo);
      setProjectName(data?.data?.project?.project_name);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setBuilderId();

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [projectId]);

  const fetchGraphData = useCallback(async () => {
    if (projectId) {
      try {
        setLoading(true);
        const { data } = await fileUpload(
          `${API.GET_PROJECT_DASHBOARD_GRAPH}?builder_project_id=${
            projectId ? projectId : ""
          }&from_month=${
            moment(valueRange[0]).format("MM-YYYY")
              ? moment(valueRange[0]).format("MM-YYYY")
              : ""
          }&to_month=${
            moment(valueRange[1]).format("MM-YYYY")
              ? moment(valueRange[1]).format("MM-YYYY")
              : ""
          }`
          // formData
        );
        setLoading(false);

        setCashFlowData(data?.data?.cashFlow);
        const proData = data?.data?.proCostChart
          ? data?.data?.proCostChart
          : "";
        setProCostData(proData);

        const ptaRtaData = data?.data?.rtaPtaChart
          ? data?.data?.rtaPtaChart
          : [];
        setPtaRtaChartData(ptaRtaData);

        const expData =
          data?.data?.subContChart && data?.data?.subContChart?.length > 0
            ? data?.data?.subContChart?.map((item) => {
                const { percentage: value, ...rest } = item;
                return { value, ...rest };
              })
            : [];

        if (expData?.length > 0) {
          setSubContractorPieChartData(expData);
        } else {
          setSubContractorPieChartData(defaultPiChartData);
        }

        return data.data;
      } catch (e) {
        setLoading(false);
        const { data } = e;
        toast.error(
          data &&
            data.errors &&
            data.errors.myna_error &&
            data.errors.myna_error[0]
        );
      }
    }
    // eslint-disable-next-line
  }, [projectID, valueRange]);

  useEffect(() => {
    projectId && fetchGraphData();
  }, [projectId, fetchGraphData]);

  useEffect(() => {
    projectId && fetchDashboardDetails();
  }, [fetchDashboardDetails, projectId]);

  useEffect(() => {
    navigate(`/projects/${projectId}`);
    // setProjectValue(projectId);
  }, [projectId, navigate]);

  const CustomExpRevTooltip = ({ active, payload, label }) => {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label>
          {payload && payload?.length > 0
            ? `${payload[0]?.payload?.month}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `PTA : $${payload[0]?.payload?.PTA?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : ""}
        </label>
        <br />
        <label>
          {payload && payload?.length > 0
            ? `RTA : $${payload[0]?.payload?.RTA?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : ""}
        </label>
      </div>
    );
  };
  const CustomTooltipForExp = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <label>
            {payload && payload?.length > 0
              ? `${payload[0].name} (${payload[0].value}%)`
              : ""}
          </label>
          <br />
          <label>
            {payload && payload?.length > 0
              ? payload[0]?.payload?.amount
                ? `$${payload[0]?.payload?.amount?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`
                : "$0.00"
              : "$0.00"}
          </label>
        </div>
      );
    }

    return null;
  };

  const monthRangeDisplay =
    moment(valueRange[0]).format("yyyy") ===
    moment(valueRange[1]).format("yyyy")
      ? moment(valueRange[0]).format("MMM") ===
        moment(valueRange[1]).format("MMM")
        ? `${moment(valueRange[0]).format("MMM")}, ${moment(
            valueRange[0]
          ).format("yyyy")}`
        : `${moment(valueRange[0]).format("MMM")}-${moment(
            valueRange[1]
          ).format("MMM")}, ${moment(valueRange[0]).format("yyyy")}`
      : `${moment(valueRange[0]).format("MMM")} ${moment(valueRange[0]).format(
          "yyyy"
        )} - ${moment(valueRange[1]).format("MMM")} ${moment(
          valueRange[1]
        ).format("yyyy")}`;

  const today = new Date();

  var disabledFutureDate = [];
  for (let i = 1; i < 1000; i++) {
    disabledFutureDate.push(
      new Date(today.getFullYear(), today.getMonth() + i, 1)
    );
  }

  const COLORS = [
    "#1F78B4",
    "#1B9BDF",
    "#034262",
    "#C7DAE3",
    "#A6CEE3",
    "#B2DF8A",
    "#d3f7b2",
    "#2b6a8a",
    "#6a9aba",
    "#0f71a3",
  ];

  // const fetchBuilders = useCallback(async () => {
  //   try {
  //     // setBuilderLoading(true);
  //     const { data } = await get(`${API.GET_BUILDERS}?limit=-1`);
  //     // setBuilderLoading(false);
  //     const builderListing =
  //       data &&
  //       data?.data?.data?.length > 0 &&
  //       data?.data?.data?.map((item) => {
  //         const list = {
  //           label: formatName(item?.first_name, item?.last_name),
  //           value: item?.builder?.id,
  //         };
  //         return list;
  //       });
  //     // setBuilderList(builderListing ? builderListing : []);
  //     return data.data;
  //   } catch (error) {
  //     // setBuilderLoading(false);
  //     // setBuilderList([]);

  //     if (error?.response?.data?.errors) {
  //       Object.keys(error?.response?.data?.errors).forEach((key) => {
  //         toast.error(error?.response?.data?.errors[key][0]);
  //       });
  //     } else {
  //       toast.error(error?.message);
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchBuilders();
  // }, [fetchBuilders]);

  var estimatedCost = proCostData?.estimated_cost
    ? proCostData?.estimated_cost
    : 0;
  var spentAmount = proCostData?.total ? proCostData?.total : 0;
  var diff = estimatedCost - spentAmount;
  diff = Math.abs(diff);

  const percentage = estimatedCost
    ? estimatedCost >= spentAmount
      ? Math.abs((100 * diff) / estimatedCost).toFixed(2)
      : Math.abs((spentAmount * 100) / estimatedCost).toFixed(2)
    : 0;

  const [projectValue1, setProjectValue1] = useState();
  const [builderValue1, setBuilderValue1] = useState();

  const totalSub =
    subContractorPieChartData && subContractorPieChartData?.length > 0
      ? subContractorPieChartData?.map((item) => {
          return item?.amount ? parseFloat(item?.amount) : 0;
        })
      : [];
  const totalSubContractorPieChartData =
    totalSub && totalSub?.length > 0
      ? totalSub.reduce((partialSum, a) => partialSum + a, 0)
      : 0;

  const BARCOLORS = [
    "rgb(90 195 140)",
    estimatedCost !== 0 && estimatedCost >= spentAmount
      ? "rgb(233 207 110)"
      : "rgb(243 85 85)",
  ];

  const expBarData = [
    {
      name: "Money Spent",
      amount: proCostData?.total ? proCostData?.total : 0,
    },
    // { name: "Money to be spentAmount", amount: 110 },
    {
      name:
        estimatedCost !== 0 && estimatedCost >= spentAmount
          ? "Remaining Budget"
          : "Over Budget",
      // amount: proCostData?.estimated_cost ? proCostData?.estimated_cost : 0,
      amount: diff ? diff : 0,
    },
  ];
  return (
    <div className="cms-page">
      <div className="page-content-block">
        <div className="full-content-block">
          <div className="page-title">
            {roleType && roleType === "Builder" ? (
              <>
                <ProjectSelect
                  regionName={builderId ? builderId : "0"}
                  onChange={(e) => {
                    setProjectId(e?.id);
                    setProjectValue1(e);
                  }}
                  value={projectValue1 ? projectValue1 : null}
                  placeholder={"Select Project"}
                  builderId={builderId}
                  isClearable={false}
                  defaultValue={
                    builderId === projectData?.builder_id && {
                      project_name: projectName,
                      id: projectId,
                    }
                  }
                />

                {/* <Button
                  className="small headerbtn"
                  // onClick={() => {
                  //   handleInviteAccountingFirm();
                  // }}
                  // disabled={accountingFirmId ? true : false}
                >
                  Invite Accounting Firm
                </Button> */}
              </>
            ) : (
              <>
                <BuilderSelect
                  regionName={"0"}
                  onChange={(e) => {
                    setBuilderId(e?.builder?.id);
                    setBuilderValue1(e);
                    setProjectValue1("");
                  }}
                  value={builderValue1 ? builderValue1 : null}
                  placeholder={"Select Builder"}
                  isClearable={false}
                  defaultValue={{
                    first_name: builderData?.first_name,
                    last_name: builderData?.last_name,
                    builder: { id: builderId },
                  }}
                />

                <ProjectSelect
                  regionName={builderId ? builderId : "0"}
                  onChange={(e) => {
                    setProjectId(e?.id);
                    setProjectValue1(e);
                  }}
                  value={projectValue1 ? projectValue1 : null}
                  placeholder={"Select Project"}
                  builderId={builderId}
                  isClearable={false}
                  defaultValue={
                    builderId === projectData?.builder_id && {
                      project_name: projectName,
                      id: projectId,
                    }
                  }
                />
              </>
            )}
            <div className="page-title-right">
              {projectLogo && (
                <img src={`${imageDefaultPrefixPath}${projectLogo}`} alt="" />
              )}{" "}
              {projectName}
            </div>
          </div>
          <div className="dashboard-container">
            {loading && <Spin />}

            <div className="row">
              {/* <Workinprogrees /> */}
              <div className="card col-md-12">
                <>
                  <div className="summary-filter">
                    <MonthRangePicker
                      value={valueRange}
                      onChange={(date) => {
                        setValueRange(date);
                      }}
                      className="datefilter"
                      disabled={disabledFutureDate}
                    />
                  </div>
                </>
              </div>

              <div className="col-md-12 active-cards">
                <div className="row">
                  <div
                    className="card col-md-3 card-width"
                    onClick={() => {
                      navigate(`/projects/${projectID}/sub-contractors`);
                    }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          <i className="icon"></i>
                          <h6>Subcontractors</h6>
                          <h4>
                            {contractorData?.count
                              ? contractorData?.count
                              : "0"}
                          </h4>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}{" "}
                          {contractorData?.last_added
                            ? moment(
                                contractorData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card col-md-3 card-width">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          <h6>PTA Cash Flow Forecast</h6>
                        </div>
                        <div className="card-body">
                          {}
                          <p>
                            Currrent:{" "}
                            {cashFlowData?.current
                              ? Number(cashFlowData?.current) < 0
                                ? `($${formatNumber(
                                    Number(cashFlowData?.current) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(cashFlowData?.current) 
                                  )}`
                              : "$0.00"}
                          </p>
                          <p>
                            Balance After Payment:{" "}
                            {cashFlowData?.balance_after_payment
                              ? Number(cashFlowData?.balance_after_payment) < 0
                                ? `($${formatNumber(
                                    Number(cashFlowData?.balance_after_payment) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(
                                      cashFlowData?.balance_after_payment
                                    )
                                  )}`
                              : "$0.00"}
                          </p>
                        </div>
                        <p className="active-date">
                          {"Forecast Date: "}{" "}
                          {cashFlowData?.date
                            ? moment(cashFlowData?.date, "DD-MM-YYYY").format(
                                "DD MMM YYYY"
                              )
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card col-md-3 card-width"
                    // onClick={() => {
                    //   navigate(`/projects/${businessId}/suppliers`);
                    // }}
                  >
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          <h6>Time Line</h6>
                          {/* <h4>
                            {supplierData?.count ? supplierData?.count : "0"}
                          </h4> */}
                        </div>
                        <div className="card-body">
                          <p>
                            Project Start Date:{" "}
                            {projectData?.start_date
                              ? projectData?.start_date
                              : ""}
                          </p>
                          <p>
                            Project End Date:{" "}
                            {projectData?.end_date ? projectData?.end_date : ""}
                          </p>
                        </div>
                        <p className="active-date">
                          {"Last Added: "}{" "}
                          {projectData?.updated_at
                            ? moment(
                                projectData?.updated_at.split(" ")[0],
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : moment(
                                projectData?.created_at.split(" ")[0],
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")}
                          {/* {supplierData?.last_added
                            ? moment(
                                supplierData?.last_added,
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : "-"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card col-md-3 card-width">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          <h6>PTA Bank Balance</h6>
                        </div>
                        <div className="card-body">
                          {}
                          <p>
                            Statement:{" "}
                            {projectData?.pta_balance?.statement
                              ? Number(projectData?.pta_balance?.statement) < 0
                                ? `($${formatNumber(
                                    Number(projectData?.pta_balance?.statement) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(
                                      projectData?.pta_balance?.statement
                                    )
                                  )}`
                              : "$0.00"}
                          </p>
                          <p>
                            BuildTrust:{" "}
                            {projectData?.pta_balance?.buildtrust
                              ? Number(projectData?.pta_balance?.buildtrust) < 0
                                ? `($${formatNumber(
                                    Number(projectData?.pta_balance?.buildtrust) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(
                                      projectData?.pta_balance?.buildtrust
                                    )
                                  )}`
                              : "$0.00"}
                          </p>
                        </div>
                        <p className="active-date">
                          {"Imported Date: "}
                          {projectData?.pta_balance?.date
                            ? moment(
                                projectData?.pta_balance?.date.split(" ")[0],
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card col-md-3 card-width">
                    <div className="card-content">
                      <div className="card-body">
                        <div className="card-header mb-2">
                          {/* <i className="icon"></i> */}
                          <h6>RTA Bank Balance</h6>
                          {/* <h4>
                            {contactData?.count ? contactData?.count : "0"}
                          </h4> */}
                        </div>
                        <div className="card-body">
                          <p>
                            Statement:{" "}
                            {projectData?.rta_balance?.statement
                              ? Number(projectData?.rta_balance?.statement) < 0
                                ? `($${formatNumber(
                                    Number(
                                      projectData?.rta_balance?.statement
                                    ) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(projectData?.rta_balance?.statement)
                                  )}`
                              : "$0.00"}
                          </p>
                          <p>
                            BuildTrust:{" "}
                            {projectData?.rta_balance?.buildtrust
                              ? Number(projectData?.rta_balance?.buildtrust) < 0
                                ? `($${formatNumber(
                                    Number(projectData?.rta_balance?.buildtrust) * -1
                                  )})`
                                : `$${formatNumber(
                                    Number(
                                      projectData?.rta_balance?.buildtrust
                                    )
                                  )}`
                              : "$0.00"}
                          </p>
                        </div>
                        <p className="active-date">
                          {"Imported Date: "}
                          {projectData?.rta_balance?.date
                            ? moment(
                                projectData?.rta_balance?.date.split(" ")[0],
                                "DD-MM-YYYY"
                              ).format("DD MMM YYYY")
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <div className="verticle-barchart">
                        <h5>
                          Total Project Cost: $
                          {estimatedCost
                            ? formatNumber(Number(estimatedCost))
                            : "0.00"}
                        </h5>
                      </div>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period: </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    {
                      estimatedCost < spentAmount || estimatedCost === 0 ? (
                        <ProgressBar>
                          <ProgressBar
                            max={spentAmount}
                            style={{ backgroundColor: "rgb(90 195 140)" }}
                            variant="success"
                            now={spentAmount === diff ? diff : estimatedCost}
                            key={1}
                            label={`${
                              spentAmount === 0 && estimatedCost === 0 ? 0 : 100
                            }%`}
                            title={`${
                              spentAmount === 0 && estimatedCost === 0 ? 0 : 100
                            }%`}
                          />

                          <ProgressBar
                            max={spentAmount}
                            style={{ backgroundColor: "rgb(243 85 85)" }}
                            variant="warning"
                            now={diff}
                            key={2}
                            label={`${
                              spentAmount === 0 && estimatedCost === 0
                                ? 0
                                : spentAmount !== estimatedCost
                                ? Number(
                                    Math.abs(Number(percentage) - 100)
                                  ).toFixed(2)
                                : 100
                            }%`}
                            title={`${
                              spentAmount === 0 && estimatedCost === 0
                                ? 0
                                : spentAmount !== estimatedCost
                                ? Number(
                                    Math.abs(Number(percentage) - 100)
                                  ).toFixed(2)
                                : 100
                            }%`}
                          />
                        </ProgressBar>
                      ) : (
                        // amount >= spentAmount ?
                        <ProgressBar>
                          <ProgressBar
                            max={estimatedCost}
                            style={{ backgroundColor: "rgb(90 195 140)" }}
                            variant="success"
                            now={spentAmount}
                            key={1}
                            label={`${Number(100 - Number(percentage)).toFixed(
                              2
                            )}%`}
                            title={`${Number(100 - Number(percentage)).toFixed(
                              2
                            )}%`}
                          />

                          <ProgressBar
                            max={estimatedCost}
                            style={{ backgroundColor: "rgb(233 207 110)" }}
                            variant="warning"
                            now={diff}
                            key={2}
                            label={`${
                              percentage ? Number(percentage) : "0.00"
                            }%`}
                            title={`${
                              percentage ? Number(percentage) : "0.00"
                            }%`}
                          />
                        </ProgressBar>
                      )
                    }
                    <ul
                      className="chart-legends"
                      style={{
                        display: "flex",
                        alignTtems: "center",
                        gap: "15px",
                      }}
                    >
                      {expBarData &&
                        expBarData?.length > 0 &&
                        expBarData?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <span>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: BARCOLORS[index % BARCOLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {`${item?.name}: $${formatNumber(
                                  Number(item.amount)
                                )}`}
                              </span>{" "}
                            </React.Fragment>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <div className="first-chart">
                        <h5>
                          Payment Made From Project Trust and to Retention Trust
                        </h5>
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer
                        width={"100%"}
                        height={280}
                        debounce={50}
                      >
                        <BarChart
                          width={800}
                          height={400}
                          data={ptaRtaChartData}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 45,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="month"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-40"
                            // stroke="#8884d8"
                          />
                          <YAxis />
                          <Tooltip content={<CustomExpRevTooltip />} />
                          <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            wrapperStyle={{
                              top: 250,
                            }}
                          />
                          <Bar dataKey="PTA" barSize={20} fill="#012F4E" />
                          <Bar dataKey="RTA" barSize={20} fill="#0DCAF0" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card col-md-6">
                <div className="card-content">
                  <div className="card-body first-chart">
                    <div className="card-header mb-2">
                      <h5>Payment to Subcontractors</h5>
                      <div style={{ marginLeft: "auto" }}>
                        <strong>Period: </strong>
                        {monthRangeDisplay}
                      </div>
                    </div>
                    <div className="coa-charts">
                      <ResponsiveContainer
                        width={"73%"}
                        height={400}
                        debounce={50}
                      >
                        <PieChart>
                          <Pie
                            data={subContractorPieChartData}
                            isAnimationActive={true}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={3}
                            outerRadius={150}
                            fill="#8884d8"
                            labelLine={true}
                            // label={renderExpCustomizedLabel}
                            label={({
                              cx,
                              cy,
                              midAngle,
                              innerRadius,
                              outerRadius,
                              value,
                              index,
                            }) => {
                              const RADIAN = Math.PI / 180;
                              // eslint-disable-next-line
                              const radius =
                                25 + innerRadius + (outerRadius - innerRadius);
                              // eslint-disable-next-line
                              const x =
                                cx + radius * Math.cos(-midAngle * RADIAN);
                              // eslint-disable-next-line
                              const y =
                                cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                <text
                                  x={x}
                                  y={y}
                                  key={`cell-${index}`}
                                  fill={"#000000"}
                                  textAnchor={x > cx ? "start" : "end"}
                                  dominantBaseline="central"
                                  // style={{f}}
                                >
                                  {value ? `${value}%` : "0.00"}
                                </text>
                              );
                            }}
                          >
                            {subContractorPieChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltipForExp />} />
                        </PieChart>
                      </ResponsiveContainer>

                      <ul className="chart-legends">
                        {subContractorPieChartData &&
                          subContractorPieChartData?.length > 0 &&
                          subContractorPieChartData?.map((item, index) => {
                            return (
                              <p key={index}>
                                <FontAwesomeIcon
                                  icon={faSquare}
                                  style={{
                                    color: COLORS[index % COLORS.length],
                                  }}
                                ></FontAwesomeIcon>{" "}
                                {item?.name}
                              </p>
                            );
                          })}
                      </ul>
                    </div>
                    <div
                      style={{
                        marginTop: "-17px",
                        float: "left",
                        width: "73%",
                        textAlign: "center",
                      }}
                    >
                      <p>
                        <strong>Total : </strong>$
                        {totalSubContractorPieChartData?.toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDashboard;
