import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Spin from "../../common/Spin";
// import Spin from "../../common/Spin";

const GenerateAbaApprovalModal = (props) => {
  const {
    showModal,
    handleModalClose,
    handleSubmit,
    loading,
    // toggleGenerateModal,
    // generateModal,
  } = props;
  const [fileNote, setFileNote] = useState("");
  return (
    <>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => {
          handleModalClose();
          setFileNote("");
        }}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Note</Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div className="paymentNote" style={{ marginBottom: "10px" }}>
            <p>
              <span className="required">*</span>Payment Note
            </p>
            <Form.Control
              type="text"
              className="left-text-align"
              required
              maxLength={150}
              value={fileNote ? fileNote : ""}
              rules={[
                {
                  pattern: new RegExp(/^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/),
                  message:
                    "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                },
              ]}
              onChange={(e) => {
                setFileNote(e.target.value);
              }}
            ></Form.Control>
          </div>
          <div className="modal-footer">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleSubmit(0, fileNote)}
              disabled={!fileNote}
            >
              Post
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal
        size="lg"
        show={generateModal}
        onHide={toggleGenerateModal}
        dialogClassName="modal-50w small-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header closeButton>Send Email Confirmation!</Modal.Header>
        <Modal.Body>
          {loading && <Spin />}
          <div
            className="content-details"
            style={{ paddingBottom: "10px", paddingTop: "10px" }}
          >
            <p>Do you want to send receipt via email to client(s)?</p>
          </div>
          <div className="modal-footer">
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => handleSubmit(1, fileNote)}
              disabled={!fileNote}
            >
              Yes
            </Button>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={() => handleSubmit(0, fileNote)}
              disabled={!fileNote}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default GenerateAbaApprovalModal;
