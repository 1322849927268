import {
  faCircleCheck,
  faClone,
  faDownload,
  faEnvelope,
  faEye,
  faScrewdriverWrench,
  faTriangleExclamation,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Spin from "../../common/Spin";
import {
  API,
  defaultLimitPagination,
  deleteCall,
  fileUpload,
  get,
  imageDefaultPrefixPath,
  // s3DefaultPath,
} from "../../../config";
import { toast } from "react-toastify";
// import BootstrapTable from "react-bootstrap-table-next";
import {
  DDMMYYYYFormat,
  MaxLimit,
  formatABN,
  formatName,
  formatNumber,
  formatPhone,
  formatedAddress,
} from "../../common/Misc";
import axios from "axios";
import NumberFormat from "react-number-format";
import moment from "moment";
import PaymentScheduledApprovalModal from "./PaymentScheduledApprovalModal";
import VariationPaymentSchedule from "./VariationPaymentSchedule";
import { useLocation } from "react-router-dom";
import { Switch } from "@material-ui/core";
import CustomTooltip from "../../common/CustomTooltip";
// import ReactSelect from "react-select";

const PaymentSchduleActionButton = ({
  dataView,
  id,
  handleDelete,
  file,
  projectId,
  trust_type,
  fetchPaymentSchedule,
  projectData,
  ProjectReadOnly,
  setMainSelectedRow,
  info,
  verify = false,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [adjustmentModal, setAdjustmentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentScheduleData, setPaymentScheduleData] = useState(null);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [adjustmentEmail, setAdjustmentEmail] = useState(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultPage = query.get("page");
  const defaultLimit = query.get("limit");
  const [page, setPage] = useState(defaultPage || 1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [showUndo, setShowUndo] = useState(false);

  const [mailLoading, setMailLoading] = useState(false);

  const [entryData, setEntryData] = useState();
  const [itemData, setItemData] = useState();
  const [approvedAmount, setApprovedAmount] = useState(
    itemData?.approved_amount
  );
  const [adjustmentAmount, setAdjustmentAmount] = useState(0);
  const [adjustmenType, setAdjustmentType] = useState("1");
  const [adjustmentDescription, setAdjustmentDescription] = useState();
  const [oldScheduledAmount, setOldScheduledAmount] = useState();

  const [approvalModal, setApprovalModal] = useState(false);
  const [showVariation, setShowVariation] = useState(false);
  const [variationData, setVariationData] = useState([]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const toggleCloseModal = () => {
    if (!showVariation) {
      fetchPaymentScheduleVariationData(dataView?.id);
    }
    setShowVariation(!showVariation);
  };

  const defaulDescriptionForContractor =
    "Decrease beneficial interest to contractors from the trust account - Payment Claim #";

  const defaulDescriptionForRetention =
    "To take up the payments of retentions to the retention trust account (retained amount) - Payment Claim #";

  // const adjustmentTypeOption = [
  //   {
  //     label: "Deduct",
  //     value: "0",
  //   },
  //   {
  //     label: "Add",
  //     value: "1",
  //   },
  // ];

  const [errors, setErrors] = useState([
    {
      scheduled_amount: "",
      description: "",
      amount_withheld: "",
      withheld_description: "",
    },
  ]);

  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  const toggleUndoModal = () => {
    setShowUndo(!showUndo);
  };

  const toggleShowModal = (data) => {
    setShow(!show);
  };

  const toggleAdjustmentModal = () => {
    if (adjustmentModal) {
      setEntryData();
      setErrors([
        {
          scheduled_amount: "",
          description: "",
          amount_withheld: "",
          withheld_description: "",
        },
      ]);
      setAdjustmentAmount();
      setAdjustmentDescription();
      setAdjustmentType("1");
    }
    setAdjustmentModal(!adjustmentModal);
  };

  const handleSendEmail = () => {
    setShowSendEmail(!showSendEmail);
  };

  const toggleAdjustmentEmail = () => {
    setAdjustmentEmail(!adjustmentEmail);
  };

  const variationColumns = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "15%" },
    },
    {
      dataField: "approved_amount",
      text: "Approved Amount",
    },
    {
      dataField: "date",
      text: "Variation Date",
    },
    {
      dataField: "amount",
      text: "Variation Amount",
    },
    {
      dataField: "reason",
      text: "Variation Reason",
    },
  ];

  const fetchPaymentScheduleData = useCallback(async (id) => {
    if (id) {
      try {
        setLoading(true);
        const { data } = await get(`${API.PAYMENT_SCHEDULES}/${id}`);
        const expand = data?.data;
        let errorObj = [];
        for (let i = 0; i < data?.data?.payment_schedule_items?.length; i++) {
          if (i === 0) {
            setOldScheduledAmount(
              data?.data?.payment_schedule_items[i]?.scheduled_amount
            );
          }
          errorObj.push({
            scheduled_amount: "",
            description: "",
            amount_withheld: "",
            withheld_description: "",
          });
        }
        setErrors(errorObj);
        setEntryData(expand?.payment_schedule_items);
        setPaymentScheduleData(data?.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.data?.errors) {
          setLoading(false);
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          setLoading(false);
          toast.error(error?.message);
        }
      }
    }
  }, []);

  const fetchPaymentScheduleVariationData = useCallback(
    async (id, viewlimit) => {
      if (id) {
        try {
          setLoading(true);
          const { data } = await get(
            `${
              API.PAYMENT_SCHEDULES_VARIATION
            }/${id}?builder_project_id=${projectId}&limit=${
              viewlimit ? viewlimit : limit ? limit : defaultLimitPagination
            }&page=${page ? page : 1}&trust_type=${trust_type}`
          );
          setLimit(data?.data?.per_page);
          setPage(data?.data?.current_page);
          setTotal(data?.data?.total);
          setFrom(data?.data?.from);
          setTo(data?.data?.to);
          setVariationData(data?.data?.data);
          setLoading(false);
        } catch (error) {
          if (error?.response?.data?.errors) {
            setLoading(false);
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
          } else {
            setLoading(false);
            toast.error(error?.message);
          }
        }
      }
    },
    [limit, page, projectId, trust_type]
  );

  const handlePaymentScheduleDelete = async (id) => {
    try {
      setLoading(true);
      const { data } = await deleteCall(`${API.PAYMENT_SCHEDULES}/${id}`);
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleDeleteModal();
    } catch (e) {
      setLoading(false);
      return null;
    }
  };

  // *Approve Payment Scheduled - Start
  const handleApprove = async (id, email) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);
      formData.append("send_email", email);
      formData.append("approve", 1);
      formData.append(`ids[0]`, id);

      const { data } = await fileUpload(
        API.PAYMENT_SCHEDULE_APPROVE,
        formData,
        {}
      );

      setApprovalModal(false);

      setLoading(false);
      if (data) {
        setMainSelectedRow([]);
        toast.success(data.message);
        fetchPaymentSchedule();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const toggleApprovalModal = async () => {
    setApprovalModal(true);
  };
  const handleModalClose = () => {
    setApprovalModal(!approvalModal);
  };
  // *Approve Payment Scheduled - End

  const fetchPaymentScheduleMail = useCallback(async (id, action) => {
    if (id) {
      try {
        setMailLoading(true);
        const { data } = await get(`${API.PAYMENT_SCHEDULES}/mail?id=${id}`);
        setShowSendEmail(false);
        fetchPaymentSchedule();
        data?.message && toast.success(data?.message);
        setMailLoading(false);
      } catch (error) {
        if (error?.response?.data?.errors) {
          setMailLoading(false);
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          setMailLoading(false);
          toast.error(error?.message);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  // const viewPaymentScheduleDataGenerator = (quantity) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       key: paymentScheduleData?.payment_schedule_items[i]?.id,
  //       description: paymentScheduleData?.payment_schedule_items[i]?.description
  //         ? paymentScheduleData?.payment_schedule_items[i]?.description
  //         : "",
  //       // claimed_amount: paymentScheduleData?.payment_schedule_items[i]
  //       //   ?.claimed_amount
  //       //   ? `$${Number(paymentScheduleData?.payment_schedule_items[i]?.claimed_amount).toFixed(2)}`
  //       //   : "",
  //       scheduled_date: paymentScheduleData?.payment_schedule_items[i]
  //         ?.scheduled_date
  //         ? DDMMYYYYFormat(
  //             paymentScheduleData?.payment_schedule_items[
  //               i
  //             ]?.scheduled_date?.split("-")
  //           )
  //         : "",
  //       scheduled_amount: paymentScheduleData?.payment_schedule_items[i]
  //         ?.scheduled_amount
  //         ? `$${formatNumber(
  //             Number(
  //               paymentScheduleData?.payment_schedule_items[i]?.scheduled_amount
  //             )
  //           )}`
  //         : "",
  //       amount_withheld: paymentScheduleData?.payment_schedule_items[i]
  //         ?.amount_withheld
  //         ? `$${formatNumber(
  //             Number(
  //               paymentScheduleData?.payment_schedule_items[i]?.amount_withheld
  //             )
  //           )}`
  //         : "",
  //       reason: paymentScheduleData?.payment_schedule_items[i]
  //         ?.withheld_description
  //         ? paymentScheduleData?.payment_schedule_items[i]?.withheld_description
  //         : "",
  //     });
  //   }
  //   return items;
  // };
  // const viewPaymentScheduleData = viewPaymentScheduleDataGenerator(
  //   paymentScheduleData?.payment_schedule_items?.length
  // );

  // const viewColumns = [
  //   {
  //     dataField: "description",
  //     text: "Description",
  //     headerStyle: { width: "25%" },
  //   },
  //   // {
  //   //   dataField: "claimed_amount",
  //   //   text: "Claimed Amount",
  //   // },
  //   {
  //     dataField: "scheduled_date",
  //     text: "Schedule Date",
  //     headerStyle: { width: "10%" },
  //   },
  //   {
  //     dataField: "scheduled_amount",
  //     text: "Schedule Amount",
  //     headerStyle: { width: "15%" },
  //   },
  //   {
  //     dataField: "amount_withheld",
  //     text: "Amount Withheld",
  //     headerStyle: { width: "15%" },
  //   },
  //   {
  //     dataField: "reason",
  //     text: "Reason",
  //     headerStyle: { width: "25%" },
  //   },
  // ];

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  // *API Call for Edit PaymentScheduled - Start

  const handleReasonChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, withheld_description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleDescriptionChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        return { ...obj, description: e.target.value };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleScheduleChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = e.target.value
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? 0
          : "";
        if (approvedAmount) {
          if (
            Number(approvedAmount.toString().replace(/,/g, "")) < Number(value)
          ) {
            errors[index].scheduled_amount =
              "Enter amount less than approved amount.";
          } else {
            errors[index].scheduled_amount = "";
          }
        } else {
          errors[index].scheduled_amount =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          scheduled_amount: value,
          description: value
            ? obj?.description
              ? `${obj?.description?.split("#")[0]}#${
                  itemData?.invoice_number ? itemData?.invoice_number : ""
                }`
              : `${defaulDescriptionForContractor?.split("#")[0]}#${
                  itemData?.invoice_number ? itemData?.invoice_number : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleScheduledBlur = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""))
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? obj?.payment_type !== 1
            ? 0
            : ""
          : "";

        if (approvedAmount) {
          if (
            Number(approvedAmount.toString().replace(/,/g, "")) < Number(value)
          ) {
            errors[index].scheduled_amount =
              "Enter amount less than approved amount.";
          } else {
            errors[index].scheduled_amount = "";
          }
        } else {
          errors[index].scheduled_amount =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          scheduled_amount: value ? value : 0,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAmountWithheldChange = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = e.target.value
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? 0
          : "";
        if (approvedAmount) {
          if (
            Number(approvedAmount.toString().replace(/,/g, "")) < Number(value)
          ) {
            errors[index].amount_withheld =
              "Enter amount less than approved amount.";
          } else {
            errors[index].amount_withheld = "";
          }
        } else {
          errors[index].amount_withheld =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          amount_withheld: value,
          withheld_description: value
            ? obj?.reason
              ? `${obj?.reason?.split("#")[0]}#${
                  itemData?.invoice_number ? itemData?.invoice_number : ""
                }`
              : `${defaulDescriptionForRetention?.split("#")[0]}#${
                  itemData?.invoice_number ? itemData?.invoice_number : ""
                }`
            : "",
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleAmountWithheldBlur = async (data, index, e) => {
    const newArr = data.map((obj, i) => {
      if (i === index) {
        const value = Number(`${e.target.value}`.replace(/,/g, ""))
          ? Number(`${e.target.value}`.replace(/,/g, ""))
          : e.target.value?.toString()?.includes("0")
          ? obj?.payment_type !== 1
            ? 0
            : ""
          : "";

        if (approvedAmount) {
          if (
            Number(approvedAmount.toString().replace(/,/g, "")) < Number(value)
          ) {
            errors[index].amount_withheld =
              "Enter amount less than approved amount.";
          } else {
            errors[index].amount_withheld = "";
          }
        } else {
          errors[index].amount_withheld =
            "Enter amount less than approved amount.";
        }
        return {
          ...obj,
          amount_withheld: value ? value : 0,
        };
      }
      return obj;
    });
    setEntryData(newArr);
  };

  const handleEditPaymentScheduled = async (confirm) => {
    const formData = new FormData();
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append(`trust_type`, trust_type);
    formData.append("_method", "put");
    formData.append("confirm", confirm ? "1" : "0");
    formData.append(
      "contact_id",
      itemData?.contact_id ? itemData?.contact_id : ""
    );
    formData.append(
      "adjustment_amount",
      adjustmentAmount ? adjustmentAmount : 0
    );
    formData.append("adjustment_type", adjustmentAmount ? adjustmenType : "2");
    formData.append(
      "adjustment_description",
      adjustmentDescription ? adjustmentDescription : ""
    );
    formData.append("approved_amount", approvedAmount ? approvedAmount : 0);
    formData.append(
      "total_scheduled_amount",
      calculateAmount(entryData, "scheduled_amount")
    );
    formData.append(
      "total_amount_withheld",
      calculateAmount(entryData, "amount_withheld")
    );
    entryData?.forEach((item, index) => {
      formData.append(
        `paymentScheduleItems[${index}][claimed_amount]`,
        item?.scheduled_amount || item?.amount_withheld
          ? Number(
              Number(
                item?.scheduled_amount
                  ? Number(`${item?.scheduled_amount}`.replace(/,/g, ""))
                  : 0
              ) +
                Number(
                  item?.amount_withheld
                    ? Number(`${item?.amount_withheld}`.replace(/,/g, ""))
                    : 0
                )
            )
          : 0
      );
      formData.append(
        `paymentScheduleItems[${index}][scheduled_amount]`,
        item?.scheduled_amount
          ? Number(`${item?.scheduled_amount}`.replace(/,/g, ""))
          : 0
      );
      formData.append(
        `paymentScheduleItems[${index}][description]`,
        item?.description ? item?.description : ""
      );
      formData.append(
        `paymentScheduleItems[${index}][amount_withheld]`,
        item?.amount_withheld
          ? Number(`${item?.amount_withheld}`.replace(/,/g, ""))
          : 0
      );
      formData.append(
        `paymentScheduleItems[${index}][withheld_description]`,
        item?.withheld_description ? item?.withheld_description : ""
      );
      formData.append(
        `paymentScheduleItems[${index}][id]`,
        item?.id ? item?.id : ""
      );
    });

    try {
      setLoading(true);
      const response = await fileUpload(
        `${API.PAYMENT_SCHEDULES}/${itemData?.id}`,
        formData,
        {}
      );
      if (response?.data?.message) {
        toast.success(response?.data?.message);
        fetchPaymentSchedule();
        if (dataView?.contact?.email) {
          toggleAdjustmentEmail();
        }
        // toggleAdjustmentEmail();
        toggleAdjustmentModal();
        setEntryData();
        setItemData();
      }
      setLoading(false);
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const handleEdit = async (item) => {
    setItemData(item);
    setApprovedAmount(item?.approved_amount);
    setAdjustmentDescription(`Variation as agreed ${item?.invoice_number}`);
    if (adjustmentModal) {
      setEntryData();
      setItemData();
    } else {
      fetchPaymentScheduleData(item?.id);
    }
    toggleAdjustmentModal();
  };

  const calculateAmount = (data, amount) => {
    let sum = 0;
    for (let i = 0; i < data?.length; i++) {
      sum = sum + data[i]?.[amount];
    }
    return sum ? Number(sum) : 0;
  };

  const checkDisable = () => {
    for (let checkIndex = 0; checkIndex < entryData?.length; checkIndex++) {
      if (
        entryData?.[checkIndex]?.scheduled_amount &&
        entryData?.[checkIndex]?.amount_withheld
      ) {
        if (
          !entryData?.[checkIndex]?.scheduled_amount ||
          !entryData?.[checkIndex]?.amount_withheld ||
          !entryData?.[checkIndex]?.description ||
          !entryData?.[checkIndex]?.withheld_description ||
          !approvedAmount ||
          // !adjustmentAmount ||
          !adjustmentDescription ||
          approvedAmount !==
            calculateAmount(entryData, "scheduled_amount") +
              calculateAmount(entryData, "amount_withheld")
        ) {
          return true;
        }
      } else if (entryData?.[checkIndex]?.scheduled_amount) {
        if (
          !entryData?.[checkIndex]?.scheduled_amount ||
          !entryData?.[checkIndex]?.description ||
          !approvedAmount ||
          // !adjustmentAmount ||
          !adjustmentDescription ||
          approvedAmount !==
            calculateAmount(entryData, "scheduled_amount") +
              calculateAmount(entryData, "amount_withheld")
        ) {
          return true;
        }
      } else if (entryData?.[checkIndex]?.amount_withheld) {
        if (
          !entryData?.[checkIndex]?.amount_withheld ||
          !entryData?.[checkIndex]?.withheld_description ||
          !approvedAmount ||
          // !adjustmentAmount ||
          !adjustmentDescription ||
          approvedAmount !==
            calculateAmount(entryData, "scheduled_amount") +
              calculateAmount(entryData, "amount_withheld")
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // *API Call for Edit PaymentScheduled - End

  const handlePaymentScheduleUndo = async (id) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("module", "payment_schedule");
      formData.append("builder_project_id", projectId ? projectId : "");
      formData.append(`trust_type`, trust_type);
      formData.append("ids[0]", id);
      const { data } = await fileUpload(
        `${API.JOURNAL_ENTRIES_UNDO}`,
        formData
      );
      setLoading(false);
      toast.success(data?.message);
      handleDelete();
      toggleUndoModal();
    } catch (error) {
      if (error?.response?.data?.errors) {
        setLoading(false);
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        setLoading(false);
        toast.error(error?.message);
      }
    }
  };

  const varationDataGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: variationData[i]?.id,
        id: variationData[i]?.id,

        name: variationData[i]?.name ? variationData[i]?.name : "-",

        approved_amount: variationData[i]?.approved_amount
          ? `$${formatNumber(Number(variationData[i]?.approved_amount))}`
          : "$0.00",
        date: variationData[i]?.date
          ? DDMMYYYYFormat(variationData[i]?.date?.split("-"))
          : "-",
        amount: variationData[i]?.amount ? (
          <span
            className={
              variationData[i]?.transaction_type ? "rag-green" : "rag-red"
            }
          >
            {`$${formatNumber(Number(variationData[i]?.amount))}
            `}
          </span>
        ) : (
          "$0.00"
        ),
        reason: variationData[i]?.reason ? variationData[i]?.reason : "-",
      });
    }
    return items;
  };
  const variationDatas = varationDataGenerator(variationData?.length);

  const handlePaymentScheduleVerfiy = async (id, is_verified) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("builder_project_id", projectId);
      formData.append("trust_type", trust_type);
      formData.append("is_verified", is_verified ? 0 : 1);
      formData.append(`ids[0]`, id);

      const { data } = await fileUpload(
        API.PAYMENT_SCHEDULE_APPROVE,
        formData,
        {}
      );

      setApprovalModal(false);

      setLoading(false);
      if (data) {
        setMainSelectedRow([]);
        toast.success(data.message);
        fetchPaymentSchedule();
      }
    } catch (e) {
      setLoading(false);
      const errors = e?.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  return (
    <>
      {verify ? (
        dataView?.is_reversed === 0 && (
          <span className="verify-ps">
            {loading && <Spin />}
            <Switch
              defaultChecked={dataView?.is_verified ? true : false}
              onChange={() => {
                handlePaymentScheduleVerfiy(id, dataView?.is_verified);
              }}
            />
          </span>
        )
      ) : (
        <span>
          {info ? (
            <>
              <CustomTooltip message={"View"}>
                <span>
                  <FontAwesomeIcon
                    icon={faEye}
                    size="1x"
                    onClick={() => {
                      toggleShowModal(dataView);
                      fetchPaymentScheduleData(id);
                      fetchPaymentScheduleVariationData(id, -1);
                    }}
                    disabled={dataView?.is_reversed ? true : false}
                    data-toggle="modal"
                    data-target="#account"
                  />
                </span>
              </CustomTooltip>
              {file && (
                <>
                  <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                  <CustomTooltip message={"Download"}>
                    <span>
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="1x"
                        onClick={() => {
                          downloadLetter(file);
                        }}
                        disabled={dataView?.is_reversed ? true : false}
                      />
                    </span>
                  </CustomTooltip>
                </>
              )}
              {/* Email */}
              <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
              {dataView?.contact?.email !== null ? (
                <CustomTooltip message={"Email"}>
                  <span>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      onClick={() => {
                        handleSendEmail(dataView?.id);
                      }}
                      className={dataView?.mail_sent ? "email-sent" : ""}
                      disabled={dataView?.is_reversed ? true : false}
                    />
                  </span>
                </CustomTooltip>
              ) : (
                <CustomTooltip message={"Email address missing"}>
                  <span>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="1x"
                      disabled
                      // style={{ pointerEvents: "none" }}
                      className="disabled-font-awesome"
                    />
                  </span>
                </CustomTooltip>
              )}
              {/* <br /> */}
              <>
                <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                <CustomTooltip message={"Variation List"}>
                  <span>
                    <FontAwesomeIcon
                      icon={faClone}
                      size="1x"
                      onClick={() => {
                        toggleCloseModal();
                      }}
                    />
                  </span>
                </CustomTooltip>
              </>
              {dataView?.delayed_entry ? (
                <>
                  <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    size="1x"
                    title="The record is not within 3 business days."
                    color="orange"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {dataView?.is_reversed === 0 &&
                dataView?.is_reconciled === 0 &&
                !ProjectReadOnly && (
                  <>
                    {/* <span></span>&nbsp;&nbsp;/&nbsp;&nbsp; */}
                    <CustomTooltip message={"Undo"}>
                      <span>
                        {dataView?.is_verified ? (
                          <FontAwesomeIcon
                            icon={faUndo}
                            size="1x"
                            disabled
                            className="disabled-font-awesome"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faUndo}
                            size="1x"
                            onClick={() => {
                              toggleUndoModal();
                            }}
                          />
                        )}
                      </span>
                    </CustomTooltip>
                  </>
                )}
              {dataView?.is_reversed === 0 &&
                dataView?.is_aba === 0 &&
                !ProjectReadOnly && (
                  <>
                    <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                    <CustomTooltip message={"Make Variation"}>
                      <span>
                        {dataView?.is_verified ? (
                          <FontAwesomeIcon
                            icon={faScrewdriverWrench}
                            size="1x"
                            disabled
                            className="disabled-font-awesome"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faScrewdriverWrench}
                            size="1x"
                            onClick={() => {
                              handleEdit(dataView);
                            }}
                          />
                        )}
                      </span>
                    </CustomTooltip>
                  </>
                )}
              {dataView?.is_reversed === 0 &&
                dataView?.is_aba === 0 &&
                !dataView?.approved_date &&
                !ProjectReadOnly && (
                  <>
                    <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
                    <CustomTooltip message={"Approve"}>
                      <span>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          size="1x"
                          onClick={() => toggleApprovalModal()}
                        />
                      </span>
                    </CustomTooltip>
                  </>
                )}
              {dataView?.is_reversed === 1 && <span>Reversed</span>}
            </>
          )}
        </span>
      )}
      {showSendEmail && (
        <Modal
          size="lg"
          show={showSendEmail}
          onHide={() => {
            handleSendEmail();
          }}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Resend E-mail
          </Modal.Header>
          <Modal.Body>
            {mailLoading && <Spin />}
            <div className="modal-body">
              {/* Are you sure you want to resend email of payment schedule? */}
              Do you want to resend the payment schedule via automated email?
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  fetchPaymentScheduleMail(id, "email");
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleSendEmail();
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {show && (
        <Modal
          size="lg"
          show={show}
          onHide={toggleShowModal}
          dialogClassName="modal-50w larg-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          // className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Payment Schedule Details
          </Modal.Header>
          <Modal.Body style={{ padding: "15px 15px 15px 15px" }}>
            {/* <>
              {loading && <Spin />}
              <div
                className="journal-entry-status"
                style={{ justifyContent: "space-between", flexWrap: "wrap" }}
              >
                <div className="journal-header-modal-content">
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Payment Schedule To:
                    </span>{" "}
                    {paymentScheduleData?.contact?.first_name
                      ? paymentScheduleData?.contact?.account_name ===
                        formatName(
                          paymentScheduleData?.contact?.first_name,
                          paymentScheduleData?.contact?.last_name
                        )
                        ? formatName(
                            paymentScheduleData?.contact?.first_name,
                            paymentScheduleData?.contact?.last_name
                          )
                        : `${formatName(
                            paymentScheduleData?.contact?.first_name,
                            paymentScheduleData?.contact?.last_name
                          )} (${paymentScheduleData?.contact?.account_name})`
                      : paymentScheduleData?.contact?.business_name
                      ? paymentScheduleData?.contact?.account_name ===
                        paymentScheduleData?.contact?.business_name
                        ? paymentScheduleData?.contact?.business_name
                        : `${paymentScheduleData?.contact?.business_name} (${paymentScheduleData?.contact?.account_name})`
                      : paymentScheduleData?.contact?.account_name ===
                        formatName(
                          paymentScheduleData?.contact?.first_name,
                          paymentScheduleData?.contact?.last_name
                        )
                      ? formatName(
                          paymentScheduleData?.contact?.first_name,
                          paymentScheduleData?.contact?.last_name
                        )
                      : `${formatName(
                          paymentScheduleData?.contact?.first_name,
                          paymentScheduleData?.contact?.last_name
                        )} (${paymentScheduleData?.contact?.account_name})`}
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Claim/Invoice Number:
                    </span>{" "}
                    {paymentScheduleData?.invoice_number
                      ? paymentScheduleData?.invoice_number
                      : "-"}
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      Claim/Invoice Date:
                    </span>{" "}
                    {paymentScheduleData?.invoice_date
                      ? DDMMYYYYFormat(
                          paymentScheduleData?.invoice_date?.split("-")
                        )
                      : "-"}
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>Due Date:</span>{" "}
                    {paymentScheduleData?.due_date
                      ? DDMMYYYYFormat(
                          paymentScheduleData?.due_date?.split("-")
                        )
                      : "-"}
                  </p>
                </div>
                <div className="journal-entry">
                  <p>
                    Claimed Amount
                    <i>
                      {paymentScheduleData?.total_claimed_amount
                        ? `$${formatNumber(
                            Number(paymentScheduleData?.total_claimed_amount)
                          )}`
                        : "$0.00"}
                    </i>
                  </p>
                  <p>
                    Scheduled Amount
                    <i>
                      {paymentScheduleData?.total_scheduled_amount
                        ? `$${formatNumber(
                            Number(paymentScheduleData?.total_scheduled_amount)
                          )}`
                        : "$0.00"}
                    </i>
                  </p>
                  <p>
                    Amount Withheld
                    <i>
                      {paymentScheduleData?.total_amount_withheld
                        ? `$${formatNumber(
                            Number(paymentScheduleData?.total_amount_withheld)
                          )}`
                        : "$0.00"}
                    </i>
                  </p>
                </div>
              </div>
              <BootstrapTable
                keyField="key"
                data={viewPaymentScheduleData}
                columns={viewColumns}
                noDataIndication="No Data Found"
              />
            </> */}
            <>
              {loading && <Spin />}
              <div
                className="payment-schedule-view"
                style={{ height: "90vh", overflow: "auto" }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    // margin: "50px 30px",
                    margin: "30px",
                  }}
                >
                  <table cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td colspan="3" style={{ textAlign: "center" }}>
                        <h2
                          style={{
                            fontSize: "25px",
                            fontWeight: "normal",
                            marginBottom: "20px",
                            marginTop: "10px",
                          }}
                        >
                          PAYMENT SCHEDULE / RCTI
                        </h2>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "40%", paddingBottom: "10px" }}>
                        {/* {paymentScheduleData?.builder_project?.builder?.logo ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              paymentScheduleData?.builder_project?.builder
                                ?.logo
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : paymentScheduleData?.builder_project?.builder
                            ?.builder_details?.profile_pic ? (
                          <img
                            src={
                              imageDefaultPrefixPath +
                              paymentScheduleData?.builder_project?.builder
                                ?.builder_details?.profile_pic
                            }
                            style={{ height: "100px", width: "auto" }}
                            alt=""
                          />
                        ) : (
                          <></>
                        )} */}
                      </td>
                      {/* <td
                        style={{
                          width: "10%",
                          textAlign: "center",
                          verticalAlign: "baseline",
                        }}
                      >
                        From
                      </td> */}
                      <td
                        style={{
                          width: "50%",
                          textAlign: "right",
                          paddingBottom: "20px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          {paymentScheduleData?.builder_project?.builder
                            ?.logo ? (
                            <img
                              src={
                                imageDefaultPrefixPath +
                                paymentScheduleData?.builder_project?.builder
                                  ?.logo
                              }
                              style={{ height: "100px", width: "auto" }}
                              alt=""
                            />
                          ) : paymentScheduleData?.builder_project?.builder
                              ?.builder_details?.profile_pic ? (
                            <img
                              src={
                                imageDefaultPrefixPath +
                                paymentScheduleData?.builder_project?.builder
                                  ?.builder_details?.profile_pic
                              }
                              style={{ height: "100px", width: "auto" }}
                              alt=""
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <p>
                          {paymentScheduleData?.builder_project?.builder
                            ?.builder_details
                            ? formatName(
                                paymentScheduleData?.builder_project?.builder
                                  ?.builder_details?.first_name,
                                paymentScheduleData?.builder_project?.builder
                                  ?.builder_details?.last_name
                              )
                            : "NA"}
                        </p>
                        {paymentScheduleData?.builder_project?.builder
                          ?.business_name && (
                          <p>
                            {paymentScheduleData?.builder_project?.builder
                              ?.business_name
                              ? paymentScheduleData?.builder_project?.builder
                                  ?.business_name
                              : "NA"}
                          </p>
                        )}
                        {paymentScheduleData?.builder_project?.builder?.abn && (
                          <p>
                            ABN/ACN:{" "}
                            {paymentScheduleData?.builder_project?.builder?.abn
                              ? formatABN(
                                  paymentScheduleData?.builder_project?.builder
                                    ?.abn
                                )
                              : "NA"}
                          </p>
                        )}
                        {paymentScheduleData?.builder_project?.builder
                          ?.street && (
                          <p>
                            {formatedAddress(
                              paymentScheduleData?.builder_project?.builder
                                ?.street,
                              paymentScheduleData?.builder_project?.builder
                                ?.suburb?.name,
                              paymentScheduleData?.builder_project?.builder
                                ?.state?.short_name,
                              paymentScheduleData?.builder_project?.builder
                                ?.postal_code
                            )}
                          </p>
                        )}
                        {paymentScheduleData?.builder_project?.builder
                          ?.builder_details?.phone && (
                          <p>
                            Phone:{" "}
                            {paymentScheduleData?.builder_project?.builder
                              ?.builder_details?.phone
                              ? formatPhone(
                                  paymentScheduleData?.builder_project?.builder
                                    ?.builder_details?.phone
                                )
                              : "NA"}
                          </p>
                        )}
                      </td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "45px" }}
                  >
                    <tr>
                      {/* <td style={{ width: "40%" }}></td> */}
                      {/* <td
                        style={{
                          width: "10%",
                          textAlign: "center",
                          verticalAlign: "baseline",
                        }}
                      >
                        To
                      </td> */}
                      <td style={{ width: "50%", textAlign: "left" }}>
                        <p>
                          {" "}
                          {paymentScheduleData?.contact
                            ? formatName(
                                paymentScheduleData?.contact?.first_name,
                                paymentScheduleData?.contact?.last_name
                              )
                            : "NA"}
                        </p>
                        {paymentScheduleData?.contact?.business_name && (
                          <p>
                            {paymentScheduleData?.contact?.business_name
                              ? paymentScheduleData?.contact?.business_name
                              : "NA"}
                          </p>
                        )}
                        {paymentScheduleData?.contact?.abn && (
                          <p>
                            ABN/ACN:{" "}
                            {paymentScheduleData?.contact?.abn
                              ? formatABN(paymentScheduleData?.contact?.abn)
                              : "NA"}
                          </p>
                        )}
                        {paymentScheduleData?.contact?.street && (
                          <p>
                            {formatedAddress(
                              paymentScheduleData?.contact?.street,
                              paymentScheduleData?.contact?.suburb?.name,
                              paymentScheduleData?.contact?.state?.short_name,
                              paymentScheduleData?.contact?.postal_code
                            )}
                          </p>
                        )}
                        {paymentScheduleData?.contact?.phone && (
                          <p>
                            Phone:{" "}
                            {paymentScheduleData?.contact?.phone
                              ? formatPhone(paymentScheduleData?.contact?.phone)
                              : "NA"}
                          </p>
                        )}
                      </td>
                      <td style={{ width: "40%" }}></td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      border: "1px solid #ccc",
                      fontSize: "13px",
                    }}
                  >
                    <tr style={{ verticalAlign: "baseline" }}>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "18%",
                        }}
                      >
                        Payment Claim / Invoice #
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "20%",
                        }}
                      >
                        Payment Claim / Invoice Date
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                          width: "14%",
                        }}
                      >
                        Due Date
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Total Approved Amount
                      </td>
                      <td style={{ textAlign: "right", padding: "5px" }}>
                        Payment Amount
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentScheduleData?.invoice_number}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentScheduleData?.invoice_date
                          ? DDMMYYYYFormat(
                              paymentScheduleData?.invoice_date?.split("-")
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        {paymentScheduleData?.due_date
                          ? DDMMYYYYFormat(
                              paymentScheduleData?.due_date?.split("-")
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                          textAlign: "right",
                        }}
                      >
                        {paymentScheduleData?.approved_amount
                          ? "$" +
                            formatNumber(paymentScheduleData?.approved_amount)
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                        }}
                      >
                        {paymentScheduleData?.total_scheduled_amount
                          ? "$" +
                            formatNumber(
                              paymentScheduleData?.total_scheduled_amount
                            )
                          : "-"}
                      </td>
                    </tr>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      border: "1px solid #ccc",
                      fontSize: "13px",
                    }}
                  >
                    <tr style={{ verticalAlign: "baseline" }}>
                      <td
                        style={{
                          width: "10%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Payment Date
                      </td>
                      <td
                        style={{
                          width: "34%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Description
                      </td>
                      <td
                        style={{
                          width: "15%",
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Approved Amount
                      </td>
                      <td
                        style={{
                          width: "15%",
                          textAlign: "right",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Payment Amount
                      </td>
                      <td
                        style={{
                          width: "14%",
                          textAlign: "right",
                          padding: "5px",
                        }}
                      >
                        Amount withheld in RTA(if any)
                      </td>
                    </tr>
                    {paymentScheduleData?.payment_schedule_items?.map(
                      (item, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item?.scheduled_date
                              ? DDMMYYYYFormat(item?.scheduled_date?.split("-"))
                              : "-"}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item.description ? item.description : "-"}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item?.claimed_amount
                              ? "$" + formatNumber(item?.claimed_amount)
                              : "$" +
                                formatNumber(
                                  item?.scheduled_amount + item?.amount_withheld
                                )}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                              borderRight: "1px solid #ccc",
                            }}
                          >
                            {item?.scheduled_amount
                              ? "$" + formatNumber(item?.scheduled_amount)
                              : "-"}
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              textAlign: "right",
                              borderTop: "1px solid #ccc",
                            }}
                          >
                            {item?.amount_withheld
                              ? "$" + formatNumber(item?.amount_withheld)
                              : "-"}
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                          fontWeight: "bold",
                        }}
                      >
                        {paymentScheduleData?.approved_amount
                          ? "$" +
                            formatNumber(paymentScheduleData?.approved_amount)
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                          borderRight: "1px solid #ccc",
                          fontWeight: "bold",
                        }}
                      >
                        {paymentScheduleData?.total_scheduled_amount
                          ? "$" +
                            formatNumber(
                              paymentScheduleData?.total_scheduled_amount
                            )
                          : "-"}
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          textAlign: "right",
                          borderTop: "1px solid #ccc",
                          fontWeight: "bold",
                        }}
                      >
                        {paymentScheduleData?.total_amount_withheld
                          ? "$" +
                            formatNumber(
                              paymentScheduleData?.total_amount_withheld
                            )
                          : "-"}
                      </td>
                    </tr>
                  </table>
                  {paymentScheduleData?.payment_schedule_items.filter(
                    (item) => Number(item?.amount_withheld) > 0
                  )?.length > 0 && (
                    <table
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      style={{
                        marginBottom: "45px",
                        fontSize: "13px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            width: "20%",
                            padding: "5px",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          Amount Withheld in RTA
                        </td>
                        <td style={{ width: "80%", padding: "5px" }}>
                          Reasons for withholding payment
                        </td>
                      </tr>
                      {paymentScheduleData?.payment_schedule_items?.map(
                        (item, index) =>
                          item?.amount_withheld ? (
                            <tr key={index}>
                              <td
                                style={{
                                  padding: "5px",
                                  borderTop: "1px solid #ccc",
                                  borderRight: "1px solid #ccc",
                                }}
                              >
                                {item?.amount_withheld
                                  ? "$" + formatNumber(item?.amount_withheld)
                                  : "-"}
                              </td>
                              <td
                                style={{
                                  padding: "5px",
                                  borderTop: "1px solid #ccc",
                                }}
                              >
                                {item?.withheld_description
                                  ? item?.withheld_description
                                  : "-"}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )
                      )}
                    </table>
                  )}
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{
                      marginBottom: "45px",
                      fontSize: "13px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "10%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Name
                      </td>
                      <td
                        style={{
                          width: "20%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Approved Amount
                      </td>
                      <td
                        style={{
                          width: "10%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Variation Date
                      </td>
                      <td
                        style={{
                          width: "20%",
                          padding: "5px",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Variation Amount
                      </td>

                      <td
                        style={{
                          width: "20%",
                          padding: "5px",
                        }}
                      >
                        Variation Reason
                      </td>
                    </tr>

                    {variationData?.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            padding: "5px",
                            borderTop: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {item?.name ? item?.name : "-"}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderTop: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {item?.approved_amount
                            ? "$" + formatNumber(item?.approved_amount)
                            : "-"}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderTop: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {item?.date
                            ? DDMMYYYYFormat(item?.date?.split("-"))
                            : "-"}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderTop: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {item?.amount ? (
                            <span
                              className={
                                item?.transaction_type ? "rag-green" : "rag-red"
                              }
                            >
                              {`$${formatNumber(Number(item?.amount))}`}
                            </span>
                          ) : (
                            "$0.00"
                          )}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          {item?.reason ? item?.reason : "-"}
                        </td>
                      </tr>
                    ))}
                  </table>

                  <table
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    style={{ marginBottom: "45px" }}
                  >
                    <tr>
                      <td
                        style={{
                          padding: "5px",
                          width: "5%",
                          textAlign: "right",
                        }}
                      >
                        Sign:
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #ccc",
                          width: "45%",
                        }}
                      >
                        <p>
                          {paymentScheduleData?.builder_project?.builder
                            ?.builder_details
                            ? formatName(
                                paymentScheduleData?.builder_project?.builder
                                  ?.builder_details?.first_name,
                                paymentScheduleData?.builder_project?.builder
                                  ?.builder_details?.last_name
                              )
                            : "NA"}
                        </p>
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          width: "5%",
                          textAlign: "right",
                        }}
                      >
                        Date:
                      </td>
                      <td
                        style={{
                          padding: "5px",
                          borderBottom: "1px solid #ccc",
                          width: "45%",
                        }}
                      >
                        {moment(new Date()).format("DD-MM-YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "5px", width: "5%" }}></td>
                      <td style={{ padding: "5px", textAlign: "center" }}>
                        (Signature)
                      </td>
                      <td style={{ padding: "5px", width: "5%" }}></td>
                      <td style={{ padding: "5px" }}></td>
                    </tr>
                  </table>
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}

      {showUndo && (
        <Modal
          size="lg"
          show={showUndo}
          onHide={toggleUndoModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Undo Payment Schedule
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are your sure you want to undo this payment schedule?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleUndoModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handlePaymentScheduleUndo(id);
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showDelete && (
        <Modal
          size="lg"
          show={showDelete}
          onHide={toggleDeleteModal}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Delete Payment Schedule
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Are your sure you want to delete this payment schedule?
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  toggleDeleteModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handlePaymentScheduleDelete();
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {adjustmentModal && (
        <Modal
          size="lg"
          show={adjustmentModal}
          onHide={toggleAdjustmentModal}
          dialogClassName="small-review payment-schedule-calender-modal"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Payment Schedule Variation
          </Modal.Header>
          <Modal.Body>
            <>
              {loading && <Spin />}
              <div
                className={"jounal-entry-card"}
                style={{
                  marginBottom: "0px",
                }}
              >
                <span className="journal-entry-status">
                  <Form>
                    <div className="payment-schedule-header-left-side">
                      {/* <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Adjustment Type
                        </p>
                        <ReactSelect
                          name="adjustment_type"
                          value={
                            adjustmenType
                              ? adjustmentTypeOption?.find(
                                  (item) => item?.value === adjustmenType
                                )
                              : ""
                          }
                          onChange={(e) => {
                            if (adjustmentAmount) {
                              // if (
                              //   Number(
                              //     `${
                              //       e?.value === "1"
                              //         ? adjustmentAmount
                              //         : -adjustmentAmount
                              //     }`?.replace(/,/g, "")
                              //   ) +
                              //     Number(
                              //       `${itemData?.approved_amount}`?.replace(
                              //         /,/g,
                              //         ""
                              //       )
                              //     ) <=
                              //   Number(
                              //     `${itemData?.total_claimed_amount}`?.replace(
                              //       /,/g,
                              //       ""
                              //     )
                              //   )
                              // ) {
                              if (
                                Number(itemData?.approved_amount) +
                                  (e?.value === "1"
                                    ? Number(adjustmentAmount)
                                    : -Number(adjustmentAmount)) >
                                0
                              ) {
                                if (e?.value === "1") {
                                  setApprovedAmount(
                                    Number(itemData?.approved_amount) +
                                      Number(adjustmentAmount)
                                  );
                                  entryData[0].scheduled_amount =
                                    Number(
                                      `${oldScheduledAmount}`.replace(/,/g, "")
                                    ) + adjustmentAmount;
                                } else {
                                  setApprovedAmount(
                                    Number(itemData?.approved_amount) +
                                      -Number(adjustmentAmount)
                                  );
                                  entryData[0].scheduled_amount =
                                    Number(
                                      `${oldScheduledAmount}`.replace(/,/g, "")
                                    ) + -adjustmentAmount;
                                }
                              } else {
                                setAdjustmentAmount(0);
                                entryData[0].scheduled_amount =
                                  oldScheduledAmount;
                                setApprovedAmount(itemData.approved_amount);
                                toast.error(
                                  "Approved amount should not be negative."
                                );
                              }
                            }

                            setAdjustmentType(e?.value);
                          }}
                          options={adjustmentTypeOption}
                          placeholder="Select Type"
                          className="bankdropdown"
                          classNamePrefix="selectbox"
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "grey",
                            },
                          })}
                        />
                      </div>
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>Adjustment Amount
                        </p>
                        <NumberFormat
                          type="text"
                          name="approved_amount"
                          value={adjustmentAmount}
                          onChange={(e) => {
                            setAdjustmentAmount(
                              Number(`${e.target.value}`?.replace(/,/g, ""))
                            );
                          }}
                          onBlur={(e) => {
                            if (
                              // eslint-disable-next-line eqeqeq
                              Number(`${e.target.value}`?.replace(/,/g, "")) ==
                              0
                            ) {
                              setAdjustmentAmount();
                              entryData[0].scheduled_amount =
                                oldScheduledAmount;
                              setApprovedAmount(itemData.approved_amount);
                              toast.error(
                                "Adjustment amount should not be zero."
                              );
                            } else if (
                              Number(
                                `${
                                  adjustmenType === "1"
                                    ? Number(
                                        `${e.target.value}`?.replace(/,/g, "")
                                      )
                                    : -Number(
                                        `${e.target.value}`?.replace(/,/g, "")
                                      )
                                }`?.replace(/,/g, "")
                              ) +
                                Number(
                                  `${itemData?.approved_amount}`?.replace(
                                    /,/g,
                                    ""
                                  )
                                ) >
                              0
                            ) {
                              setAdjustmentAmount(
                                Number(`${e.target.value}`?.replace(/,/g, ""))
                              );
                              entryData[0].scheduled_amount =
                                Number(
                                  `${entryData[0].scheduled_amount}`.replace(
                                    /,/g,
                                    ""
                                  )
                                ) +
                                Number(
                                  adjustmenType === "1"
                                    ? Number(
                                        `${e.target.value}`?.replace(/,/g, "")
                                      )
                                    : -Number(
                                        `${e.target.value}`?.replace(/,/g, "")
                                      )
                                );
                              setApprovedAmount(
                                Number(itemData?.approved_amount) +
                                  Number(
                                    adjustmenType === "1"
                                      ? Number(
                                          `${e.target.value}`?.replace(/,/g, "")
                                        )
                                      : -Number(
                                          `${e.target.value}`?.replace(/,/g, "")
                                        )
                                  )
                              );
                            } else {
                              setAdjustmentAmount(0);
                              entryData[0].scheduled_amount =
                                oldScheduledAmount;
                              setApprovedAmount(itemData.approved_amount);
                              toast.error(
                                "Adjustment amount should not be negative."
                              );
                            }
                          }}
                          placeholder="Enter Amount"
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          isAllowed={(values) => MaxLimit(values)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div> */}
                      <div className="payment-schedule-header-item">
                        <p>
                          <span className="required">*</span>New Approved Amount
                          (Inc. GST)
                        </p>
                        <NumberFormat
                          type="text"
                          name="approved_amount"
                          value={approvedAmount}
                          onChange={(e) => {
                            setApprovedAmount(
                              Number(`${e.target.value}`?.replace(/,/g, ""))
                            );
                          }}
                          onBlur={(e) => {
                            if (
                              // eslint-disable-next-line eqeqeq
                              Number(`${e.target.value}`?.replace(/,/g, "")) ==
                              0
                            ) {
                              setAdjustmentAmount();
                              entryData[0].scheduled_amount =
                                oldScheduledAmount;
                              setApprovedAmount(itemData.approved_amount);
                              toast.error(
                                "Approved amount should not be zero."
                              );
                            } else if (
                              Number(`${e.target.value}`?.replace(/,/g, "")) > 0
                            ) {
                              if (
                                Number(`${e.target.value}`?.replace(/,/g, "")) <
                                Number(itemData?.approved_amount)
                              ) {
                                let adjustmentAmount =
                                  Number(itemData?.approved_amount) -
                                  Number(
                                    `${e.target.value}`?.replace(/,/g, "")
                                  );
                                setAdjustmentAmount(adjustmentAmount);
                                entryData[0].scheduled_amount =
                                  Number(
                                    `${oldScheduledAmount}`.replace(/,/g, "")
                                  ) + -Number(adjustmentAmount);
                                setApprovedAmount(
                                  Number(`${e.target.value}`?.replace(/,/g, ""))
                                );
                                setAdjustmentType("0");
                              } else if (
                                Number(`${e.target.value}`?.replace(/,/g, "")) >
                                Number(itemData?.approved_amount)
                              ) {
                                let adjustmentAmount =
                                  Number(
                                    `${e.target.value}`?.replace(/,/g, "")
                                  ) - Number(itemData?.approved_amount);

                                setAdjustmentAmount(adjustmentAmount);
                                entryData[0].scheduled_amount =
                                  Number(
                                    `${oldScheduledAmount}`.replace(/,/g, "")
                                  ) + Number(adjustmentAmount);
                                setApprovedAmount(
                                  Number(`${e.target.value}`?.replace(/,/g, ""))
                                );
                                setAdjustmentType("1");
                              } else if (
                                // eslint-disable-next-line eqeqeq
                                Number(
                                  `${e.target.value}`?.replace(/,/g, "")
                                ) === Number(itemData?.approved_amount)
                              ) {
                                setAdjustmentAmount(0);
                                entryData[0].scheduled_amount =
                                  oldScheduledAmount;
                                setApprovedAmount(itemData.approved_amount);
                                setAdjustmentType();
                              }
                            } else {
                              setAdjustmentAmount(0);
                              entryData[0].scheduled_amount =
                                oldScheduledAmount;
                              setApprovedAmount(itemData.approved_amount);
                              toast.error(
                                "Adjustment amount should not be negative."
                              );
                            }
                          }}
                          placeholder="Enter Amount"
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          isAllowed={(values) => MaxLimit(values)}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div>
                      <p>
                        {/* Adjustment Amount */}
                        Variation Amount
                        <i
                          className={
                            adjustmenType === "1"
                              ? "rag-green"
                              : adjustmenType === "0"
                              ? "rag-red"
                              : ""
                          }
                        >
                          {adjustmentAmount
                            ? formatNumber(Number(adjustmentAmount))
                            : 0.0}
                        </i>
                      </p>
                      <div className="payment-schedule-header-item">
                        {/* <p>Adjustment Reason</p> */}
                        <p>
                          <span className="required">*</span>Variation Reason
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Variation Reason"
                          name={`adjustment_description`}
                          value={
                            adjustmentDescription ? adjustmentDescription : ""
                          }
                          onChange={(e) => {
                            setAdjustmentDescription(e?.target?.value);
                          }}
                          style={{
                            width: "350px",
                          }}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            e.which === 13 && e.preventDefault();
                          }}
                        />
                      </div>
                    </div>
                  </Form>
                </span>
                <span className="journal-entry-status">
                  <div className="journal-entry">
                    {/* <p>
                      Claimed Amount
                      <i>
                        {itemData?.total_claimed_amount
                          ? formatNumber(Number(itemData?.total_claimed_amount))
                          : 0.0}
                      </i>
                    </p> */}
                    <p>
                      Old Approved Amount
                      <i style={{ color: "#9b9797" }}>
                        {itemData?.approved_amount
                          ? formatNumber(Number(itemData?.approved_amount))
                          : 0.0}
                      </i>
                    </p>
                    {/* <p>
                      Adjustment Amount
                      <i
                        className={
                          adjustmenType === "1"
                            ? "rag-green"
                            : adjustmenType === "0"
                            ? "rag-red"
                            : ""
                        }
                      >
                        {adjustmentAmount
                          ? formatNumber(Number(adjustmentAmount))
                          : 0.0}
                      </i>
                    </p> */}
                    <p>
                      New Approved Amount
                      <i>
                        {approvedAmount
                          ? formatNumber(Number(approvedAmount))
                          : 0.0}
                      </i>
                    </p>
                    <p>
                      <i>=</i>
                    </p>
                    {/* <p>
                      Difference Amount
                      <i>
                        {approvedAmount && itemData?.total_claimed_amount
                          ? formatNumber(
                              Number(itemData?.total_claimed_amount) -
                                Number(approvedAmount)
                            )
                          : 0.0}
                      </i>
                    </p> */}
                    <p>
                      Payment from PTA
                      <i
                        className={
                          approvedAmount !==
                          calculateAmount(entryData, "scheduled_amount") +
                            calculateAmount(entryData, "amount_withheld")
                            ? "rag-red"
                            : "rag-green"
                        }
                      >
                        {entryData?.length > 0
                          ? formatNumber(
                              calculateAmount(entryData, "scheduled_amount")
                            )
                          : "0.00"}
                      </i>
                    </p>
                    <p>
                      <i>+</i>
                    </p>
                    <p>
                      Retented in RTA
                      <i
                        className={
                          approvedAmount !==
                          calculateAmount(entryData, "scheduled_amount") +
                            calculateAmount(entryData, "amount_withheld")
                            ? "rag-red"
                            : "rag-green"
                        }
                      >
                        {entryData?.length > 0
                          ? formatNumber(
                              calculateAmount(entryData, "amount_withheld")
                            )
                          : "0.00"}
                      </i>
                    </p>
                  </div>
                </span>
                <div className="table table-bordered journal-table">
                  <div className="react-bootstrap-table journal-entries-table">
                    <table
                      className="table-content"
                      style={{
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Payment Amount</th>
                          <th scope="col" style={{ width: "35%" }}>
                            Description
                          </th>
                          <th scope="col">Amount Withheld in RTA</th>
                          <th scope="col" style={{ width: "35%" }}>
                            Reason for Withholding
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {entryData &&
                          entryData?.length > 0 &&
                          entryData?.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                className="payment-schedule-add-row"
                              >
                                <td>
                                  <NumberFormat
                                    type="text"
                                    name="amount"
                                    value={item?.scheduled_amount}
                                    onChange={(e) => {
                                      handleScheduleChange(entryData, index, e);
                                    }}
                                    onBlur={(e) => {
                                      handleScheduledBlur(entryData, index, e);
                                    }}
                                    className={`${
                                      !item?.scheduled_amount &&
                                      item?.payment_type === 1
                                        ? "error-input-cell"
                                        : ""
                                    }`}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <div className="text-danger">
                                    {errors?.[index]?.scheduled_amount
                                      ? errors?.[index]?.scheduled_amount
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <textarea
                                    type="text"
                                    name={`description`}
                                    value={
                                      item?.description ? item?.description : ""
                                    }
                                    onChange={(e) => {
                                      handleDescriptionChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    type="text"
                                    name="amount"
                                    value={item?.amount_withheld}
                                    onChange={(e) => {
                                      handleAmountWithheldChange(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleAmountWithheldBlur(
                                        entryData,
                                        index,
                                        e
                                      );
                                    }}
                                    className={`${
                                      !item?.amount_withheld &&
                                      item?.payment_type === 1
                                        ? "error-input-cell"
                                        : ""
                                    }`}
                                    decimalScale={2}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    isAllowed={(values) => MaxLimit(values)}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <div className="text-danger">
                                    {errors?.[index]?.amount_withheld
                                      ? errors?.[index]?.amount_withheld
                                      : ""}
                                  </div>
                                </td>
                                <td>
                                  <textarea
                                    type="text"
                                    name={`reason`}
                                    value={
                                      item?.withheld_description
                                        ? item?.withheld_description
                                        : ""
                                    }
                                    disabled={
                                      item?.amount_withheld ? false : true
                                    }
                                    onChange={(e) => {
                                      handleReasonChange(entryData, index, e);
                                    }}
                                    autoComplete="off"
                                    onKeyPress={(e) => {
                                      e.which === 13 && e.preventDefault();
                                    }}
                                  />
                                  <span className="text-danger">
                                    {errors[index]?.withheld_description
                                      ? errors[index]?.withheld_description
                                      : ""}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    if (dataView?.contact?.email) {
                      toggleAdjustmentEmail();
                    } else {
                      handleEditPaymentScheduled(false);
                    }
                  }}
                  disabled={checkDisable()}
                >
                  Save
                </Button>
                <Button
                  type="Button"
                  className="btn btn-secondary"
                  onClick={() => toggleAdjustmentModal()}
                >
                  Cancel
                </Button>
              </div>
            </>
          </Modal.Body>
        </Modal>
      )}
      {adjustmentEmail && (
        <Modal
          size="lg"
          show={adjustmentEmail}
          onHide={() => {
            toggleAdjustmentEmail();
          }}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="project-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Send E-mail
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              Do you want to email this payment scheduled to{" "}
              {dataView?.contact?.email}?
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  handleEditPaymentScheduled(true);
                }}
              >
                Yes
              </Button>
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleEditPaymentScheduled(false);
                }}
              >
                No
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {approvalModal && (
        <PaymentScheduledApprovalModal
          showModal={approvalModal}
          handleModalClose={handleModalClose}
          handleSubmit={handleApprove}
          loading={loading}
          id={id}
        />
      )}
      {showVariation && (
        <VariationPaymentSchedule
          showVariation={showVariation}
          toggleCloseModal={toggleCloseModal}
          variationColumns={variationColumns}
          variationData={variationDatas}
          invoiceNumber={dataView?.invoice_number}
          loading={loading}
          total={total}
          limit={limit}
          page={page}
          handleUpdateLimit={handleUpdateLimit}
          handleChangePage={handleChangePage}
          from={from}
          to={to}
        />
      )}
    </>
  );
};

export default PaymentSchduleActionButton;
