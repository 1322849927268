/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Formik, ErrorMessage } from "formik";
import { getUserDetail } from "./components/UserHelper";
import Spin from "../common/Spin";
import { API, fileUpload, imageDefaultPrefixPath } from "../../config";
import { MyProfileSchema } from "../common/Validation";
import { toast } from "react-toastify";
import ImageCropModal from "../common/ImageCropModal";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const MyProfile = () => {
  const [userData, setUserData] = useState();
  const [showModal, setShowModal] = useState(false);
  const imageInputRef = React.useRef();
  const navigate = useNavigate();

  const profilePic = userData?.profile_pic
    ? imageDefaultPrefixPath + userData?.profile_pic
    : "";
  const [selectedFile, setSelectedFile] = useState(profilePic);
  const [imageType, setImageType] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    setUserId(userData?.id);
    userData?.profile_pic &&
      typeof userData?.profile_pic === "string" &&
      setSelectedFile(imageDefaultPrefixPath + userData?.profile_pic);
  }, [userData]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    async function fetchData() {
      try {
        // await getUsers();
        setLoading(true);
        const data = await getUserDetail();
        setUserData(data);
        setLoading(false);
      } catch (e) {
        setUserData(null);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleFormSubmit = async (value) => {
    try {
      setLoading(true);
      const formData = new FormData();
      value.profile_pic = value?.image
        ? value?.image
        : value?.profile_pic
        ? value?.profile_pic
        : "";

      value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";
      value.mobile = value?.mobile ? `${value?.mobile}`.replace(/ /g, "") : "";

      value.profile_pic =
        value?.profile_pic && typeof value?.profile_pic === "string"
          ? value?.profile_pic - imageDefaultPrefixPath
          : value?.profile_pic;

      formData.append("first_name", value?.first_name ? value?.first_name : "");
      formData.append("last_name", value?.last_name ? value?.last_name : "");
      formData.append("phone", value?.phone ? value?.phone : "");
      formData.append("mobile", value?.mobile ? value?.mobile : "");
      formData.append("email", value?.email ? value?.email : "");
      formData.append("_method", "put");
      formData.append(
        "profile_pic",
        value?.profile_pic ? value?.profile_pic : ""
      );

      const { data } = await fileUpload(
        `${API.UPDATE_PROFILE}/${userId}`,
        formData
      );
      if (data.status === 200) {
        setLoading(false);
        const userDetailsData = await getUserDetail();
        setUserData(userDetailsData);
        localStorage.setItem("ProfilePhoto", userDetailsData?.profile_pic);
        localStorage.setItem(
          "Name",
          userDetailsData?.first_name
            ? userDetailsData?.first_name
            : userDetailsData?.last_name
        );
        navigate("/user-settings?tab=my-profile", { replace: true });
        toast.success(data.message);
      }
      return data;
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  const handleRemoveIcon = () => {
    setSelectedFile("");
    imageInputRef.current.value = "";
  };
  const changeEmail = (e) => {
    e.preventDefault();
    navigate("/2fa/change?item=email");
  };
  const handleAuthentication = () => {
    // if (userData?.google2fa_enable == 1) {
    //   navigate("/2fa/disable");
    // } else {
    navigate("/2fa/register?page=back", { replace: true });
    // }
  };
  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Formik
            initialValues={{
              first_name: userData?.first_name ? userData?.first_name : "",
              last_name: userData?.last_name ? userData?.last_name : "",
              phone: userData?.phone ? userData?.phone : "",
              mobile: userData?.mobile ? userData?.mobile : "",
              email: userData?.email ? userData?.email : "",
              profile_pic: userData?.profile_pic
                ? imageDefaultPrefixPath + userData?.profile_pic
                : "",
              backup_email: userData?.backup_email
                ? userData?.backup_email
                : "",
            }}
            enableReinitialize
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={MyProfileSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className="entry-section"
              >
                <div className="entry-card full-card myprofile-card">
                  <div className="form-box">
                    <div
                      className="form-group half-width profile-photo"
                      style={{ display: "block", textAlign: "center" }}
                    >
                      <input
                        accept="image/*"
                        type="file"
                        name="profile_pic"
                        className="form-control"
                        id="file"
                        ref={imageInputRef}
                        onChange={(e) => {
                          e.currentTarget.files[0].type &&
                            setImageType(e.currentTarget.files[0].type);
                          e.currentTarget.files[0] && setShowModal(true);
                          setSelectedFile(
                            URL.createObjectURL(e.currentTarget.files[0])
                          );
                        }}
                        onBlur={handleBlur}
                      />

                      {selectedFile &&
                        (selectedFile === `${imageDefaultPrefixPath}null` ? (
                          <></>
                        ) : selectedFile ===
                          `${imageDefaultPrefixPath}undefined` ? (
                          <></>
                        ) : selectedFile === `${imageDefaultPrefixPath}` ? (
                          <></>
                        ) : (
                          <div className="previewImage d-flex">
                            <img
                              className="display-image"
                              src={selectedFile}
                              alt="selectedFile"
                            />
                            {/* <span
                              className="remove-image"
                              onClick={() => {
                                handleRemoveIcon(setFieldValue);
                                setFieldValue("profile_pic", null);
                              }}
                            >
                              Remove Profile
                              <i className="fa fa-times" aria-hidden="true" />
                            </span> */}
                            <div className="photo-upload-action d-flex">
                              <div className="edit-photo-input">
                                <input
                                  accept="image/*"
                                  type="file"
                                  name="profile_pic"
                                  className="form-control"
                                  id="file"
                                  ref={imageInputRef}
                                  onChange={(e) => {
                                    e.currentTarget.files[0].type &&
                                      setImageType(
                                        e.currentTarget.files[0].type
                                      );
                                    e.currentTarget.files[0] &&
                                      setShowModal(true);
                                    setSelectedFile(
                                      URL.createObjectURL(
                                        e.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                                <FontAwesomeIcon icon={faEdit} />
                              </div>
                              <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => {
                                  handleRemoveIcon(setFieldValue);
                                  setFieldValue("profile_pic", null);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      {!selectedFile && (
                        <span className="guide_leb">Add your Profile</span>
                      )}
                    </div>
                  </div>
                  <div className="form-box" style={{ width: "100%" }}>
                    <div className="form-group tetra-width">
                      <label>
                        <span className="required">*</span>First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        value={values?.first_name ? values?.first_name : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="first_name" />
                      </span>
                    </div>
                    <div className="form-group tetra-width">
                      <label>
                        <span className="required">*</span>Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        value={values?.last_name ? values?.last_name : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="last_name" />
                      </span>
                    </div>
                    <div className="form-group tetra-width">
                      <label>Phone No.</label>
                      <InputMask
                        type="text"
                        name="phone"
                        value={values?.phone ? values?.phone : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mask="99 9999 9999"
                        maskChar={null}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="phone" />
                      </span>
                    </div>
                    <div className="form-group tetra-width">
                      <label>Mobile No.</label>
                      <InputMask
                        type="text"
                        name="mobile"
                        value={values?.mobile ? values?.mobile : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mask="99 9999 9999"
                        maskChar={null}
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="mobile" />
                      </span>
                    </div>
                    <div className="form-group tetra-width">
                      <label>
                        <span className="required">*</span>Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={values?.email ? values?.email : ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                      <span
                        className="text-danger"
                        style={{ color: "red", fontSize: "20px" }}
                      >
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                    <div className="auth-form mfa-section full-width">
                      <div className="auth-title">
                        <h2>Two-Factor Authentication</h2>
                        <Button className="btn" onClick={handleAuthentication}>
                          {userData?.google2fa_enable == 1 ? "Reset" : "Set up"}
                        </Button>
                      </div>
                      {userData?.backup_email ? (
                        <>
                          <div className="backup-email-body">
                            <div className="form-group half-width">
                              <label>Backup Email Address</label>
                              <input
                                type="text"
                                name="backup_email"
                                value={
                                  values?.backup_email
                                    ? values?.backup_email
                                    : ""
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled
                              />
                            </div>
                            <div className="form-group tetra-width">
                              <Button
                                name="changeEmail"
                                className="btn"
                                onClick={changeEmail}
                              >
                                change
                              </Button>
                            </div>
                          </div>
                          <div className="form-group authentication-method-body half-width">
                            <label>Authentication Method</label>
                            <h4>Authenticator App</h4>
                          </div>
                        </>
                      ) : (
                        <p>
                          Multi-factor authentication adds a second layer of
                          security to your account to help prevent unauthorised
                          access.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="entry-card full-card"
                  style={{ clear: "left", float: "left", margin: 0 }}
                >
                  <div className="action-box">
                    <button type="submit">Save</button>
                  </div>
                </div>
                {showModal && (
                  <ImageCropModal
                    imageType={imageType}
                    selectedFile={selectedFile}
                    setShowModal={setShowModal}
                    setSelectedFile={setSelectedFile}
                    showModal={showModal}
                    setFieldValue={setFieldValue}
                  />
                )}
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};

export default MyProfile;
