import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Spin from "../../common/Spin";
import {
  useProjectList,
  useStateList,
  useUserList,
} from "./components/BuilderOfficeHelper";
import { API, fileUpload } from "../../../config";
import { AddOfficeSchema } from "../../common/Validation";
import { formatName } from "../../common/Misc";

const AddBuilderOffice = ({ toggleOfficeModal, showOffice, builderId }) => {
  const [loading, setLoading] = useState(false);
  const [officeData, setOfficeData] = useState();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );

    builderId && formData.append("builder_id", builderId);
    
    if (selectedProjects) {
      if (selectedProjects.length > 0) {
        for (let i = 0; i < selectedProjects.length; i++) {
          formData.append(`builder_project_ids[${i}]`, selectedProjects[i]);
        }
      }
    }

    if (selectedUsers) {
      if (selectedUsers.length > 0) {
        for (let i = 0; i < selectedUsers.length; i++) {
          formData.append(`user_ids[${i}]`, selectedUsers[i]);
        }
      }
    }

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.BUILDER_OFFICE}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleOfficeModal();
        setSelectedProjects([]);
        setSelectedUsers([]);
      }
      return data?.data;
    } catch (error) {
      setOfficeData(value);
      setLoading(false);
      setSelectedProjects([]);
      setSelectedUsers([]);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });
  const state = stateListing?.find(
    (item) => item?.value === officeData?.state_id
  );

  //Project List
  const { projectLoading, projectList = [] } = useProjectList(builderId);
  const projectListing = projectList?.map((item) => {
    const name = item?.project_name;
    const id = item?.id;
    const items = { name, id, ...item };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  //User List
  const { userLoading, userList = [] } = useUserList(builderId);
  const userListing = userList?.map((item) => {
    const name = formatName(item?.first_name, item?.last_name);
    const id = item?.id;
    const items = { name, id, ...item };
    const { name: label, id: value, ...rest } = items;
    return { label, value, ...rest };
  });

  return (
    <>
      <Modal
        size="lg"
        show={showOffice}
        onHide={toggleOfficeModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Office
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              street: officeData?.street,
              postal_code: officeData?.postal_code,
              state_id: state,
              suburb_id: officeData?.suburb_id,
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddOfficeSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {(loading || userLoading || projectLoading) && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleOfficeModal={toggleOfficeModal}
                    stateListing={stateListing}
                    stateLoading={stateLoading}
                    projectLoading={projectLoading}
                    projectListing={projectListing}
                    selectedProjects={selectedProjects}
                    setSelectedProjects={setSelectedProjects}
                    userListing={userListing}
                    userLoading={userLoading}
                    setSelectedUsers={setSelectedUsers}
                    selectedUsers={selectedUsers}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBuilderOffice;
