/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { API, defaultLimitPagination, get, ProjectStatus } from "../../../config";
import Pagination from "../../common/Pagination";
import Spin from "../../common/Spin";
import ProjectActionButton from "./components/ProjectActionButton";
import AddProject from "./AddProject";
import { formatPhone, formatedAddress } from "../../common/Misc";
import ProjectSubscriptionStatus from "./components/ProjectSubscriptionStatus";

const Projects = ({ id }) => {
  const [projectLoading, setProjectLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const [searchParam, setSearchParam] = useState("");
  const [searchParamData, setSearchParamData] = useState("");
  const [showProject, setShowProject] = useState(false);
  const [headProject, setHeadProject] = useState(null);

  var today = new Date();
  today.setHours(0, 0, 0, 0);

  // *API Call of Subscription Products - Start
  // const [setsubscription, setsubscriptionlist] = useState([]);
  // const [supportPlan, setSupportPlan] = useState([]);
  // const [promoCode, setPromoCode] = useState(false);
  // const [defaultPromoCode, setDefaultPromoCode] = useState("");
  // const [isSubLoading, setIsSubLoading] = useState(true);
  // const [selectedPeriodPlan, setSelectedPeriodPlan] = useState("month");

  // let login = true;

  // const setsubscriptiondata = useCallback(async () => {
  //   try {
  //     setIsSubLoading(true);
  //     const { data } = await get(API.SUBSCRIPTION);
  //     let array = login
  //       ? [
  //           { type: "supportPlan", plan: [] },
  //           { type: "month", plan: [] },
  //           // { type: "year", plan: [] },
  //         ]
  //       : [
  //           { type: "month", plan: [] },
  //           // { type: "year", plan: [] },
  //         ];
  //     data.data.map((item) => {
  //       if (item?.prices?.data && item?.prices?.data?.length > 0) {
  //         item?.prices?.data?.map((pricePlan) => {
  //           if (
  //             pricePlan?.recurring?.interval === "month" &&
  //             pricePlan?.active === true
  //           ) {
  //             const nameObj = { name: item?.name, unitLabel: item?.unit_label };
  //             login
  //               ? array[1].plan.push({ ...pricePlan, ...nameObj })
  //               : array[0].plan.push({ ...pricePlan, ...nameObj });
  //           }
  //           if (
  //             pricePlan?.recurring?.interval === "year" &&
  //             pricePlan?.active === true
  //           ) {
  //             const nameObj = { name: item?.name, unitLabel: item?.unit_label };
  //             login
  //               ? array[2].plan.push({ ...pricePlan, ...nameObj })
  //               : array[1].plan.push({ ...pricePlan, ...nameObj });
  //           }
  //           return 0;
  //         });
  //       }
  //       return 0;
  //     });
  //     if (login && data?.supportPlans?.metadata?.type === "support_plan") {
  //       data?.supportPlans?.prices?.data?.map((pricePlan) => {
  //         if (pricePlan?.active === true) {
  //           const nameObj = {
  //             name: data?.supportPlans?.name,
  //             unitLabel: data?.supportPlans?.unit_label,
  //           };
  //           array[0].plan.push({ ...pricePlan, ...nameObj });
  //         }
  //         return 0;
  //       });
  //     }
  //     setsubscriptionlist(array);
  //     setSupportPlan(data?.supportPlans);
  //     setSelectedPeriodPlan(login ? array[1] : array[0]);
  //     setPromoCode(data.coupons);
  //     data.coupons &&
  //       data.coupons?.length > 0 &&
  //       data.coupons?.map((el) => {
  //         el?.id === "FIRST50" && setDefaultPromoCode(el?.id);
  //         return 0;
  //       });

  //     setIsSubLoading(false);
  //   } catch (error) {
  //     setIsSubLoading(false);
  //   }
  // }, [login]);

  // useEffect(() => {
  //   setsubscriptiondata();
  // }, [setsubscriptiondata]);
  // *API Call of Subscription Products - End

  const handleDelete = () => {
    fetchProjects();
  };

  // *API Call for Geting Projects - Start
  const fetchProjects = useCallback(async () => {
    try {
      setProjectLoading(true);
      const { data } = await get(
        `${API.GET_PROJECT}?limit=${limit ? limit : defaultLimitPagination}&page=${
          page ? page : 1
        }&search=${searchParam ? searchParam : ""}&sort_column=${
          sortField ? sortField : ""
        }&sort_order=${sortOrder ? sortOrder : ""}&builder_id=${id ? id : ""}`
      );
      setProjectLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      setHeadProject(data?.head_project);
      const projectList = data.data.data;
      setProjectList(projectList);
      return data.data;
    } catch (error) {
      setProjectLoading(false);
      setHeadProject(null);
      setProjectList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [id, limit, page, searchParam, sortOrder, sortField]);

  const projectModal = () => {
    fetchProjects();
  };

  const toggleProjectModal = () => {
    showProject && projectModal();
    setShowProject(!showProject);
  };
  // *API Call for Geting Projects - End

  // *Projects Table Row and Column Generation, Filtering and Sorting of Record - Start
  const projectGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        builder_id: projectList[i]?.builder_id,
        key: projectList[i]?.id,
        project_id: projectList[i]?.id,
        project_name:
          projectList[i]?.subscription?.status === 0 ||
          projectList[i]?.subscription?.status === 1 ? (
            <Link
              to={{
                pathname: `/projects/${projectList[i]?.id}`,
              }}
              className="project-name-link active"
            >
              {projectList[i]?.project_name}
            </Link>
          ) : (
            projectList[i]?.project_name
          ),
        email: projectList[i]?.email ? projectList[i]?.email : "-",
        phone: projectList[i]?.phone ? formatPhone(projectList[i]?.phone) : "-",
        end_date: projectList[i]?.end_date ? projectList[i]?.end_date : "-",
        address: formatedAddress(
          projectList[i]?.street,
          projectList[i]?.suburb?.name,
          projectList[i]?.state?.short_name,
          projectList[i]?.postal_code
        ),
        reconciled_item: projectList[i]?.reconciled_item
          ? projectList[i]?.reconciled_item
          : "0",
        total_transactions: projectList[i]?.total_transactions
          ? projectList[i]?.total_transactions
          : "0",
        status: projectList[i]?.project_status
          ? projectList[i]?.project_status == 2 &&
            projectList[i]?.end_date &&
            new Date(
              projectList[i]?.end_date?.split("-")?.reverse()?.join("-")
            ) < today
            ? "Delayed"
            : ProjectStatus?.find(
                (item) => item?.value === Number(projectList[i]?.project_status)
              )?.label
          : "-",
        action: (
          <ProjectActionButton
            id={projectList[i]?.id}
            builderId={projectList[i]?.builder_id}
            handleDelete={handleDelete}
            projectModal={projectModal}
            headProject={headProject}
            totalProject={projectList?.length ? projectList?.length : 0}
          />
        ),
        subscribe: (
          <ProjectSubscriptionStatus
            id={projectList[i]?.id}
            builderId={projectList[i]?.builder_id}
            isSubscribe={projectList[i]?.subscription?.status}
            fetchProjects={fetchProjects}
            projectName={projectList[i]?.project_name}
          />
        ),
      });
    }
    return items;
  };

  const project = projectGenerator(projectList?.length);

  const ProjectColumns = [
    {
      dataField: "project_name",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
    },
    {
      dataField: "address",
      text: "Project Address",
      // sort: true,
    },
    {
      dataField: "end_date",
      text: "End Date",
      // sort: true,
    },
    {
      dataField: "reconciled_item",
      text: "Reconciled Transactions",
    },
    {
      dataField: "total_transactions",
      text: "Total Transactions",
    },
    {
      dataField: "status",
      text: "Project Status",
    },
    {
      dataField: "subscribe",
      text: "Manage",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
    {
      dataField: "action",
      text: "Action",
      align: "center",
      headerAlign: "center",
      style: { width: "127px" },
    },
  ];

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };
  // *Projects Table Row and Column Generation, Filtering and Sorting of Record - End

  return (
    <>
      <div className="content-details">
        <div className="table-top-btn">
          <div className="search-section project-list">
            <Form>
              <FormControl
                onChange={(e) => handleSearchChange(e)}
                type="text"
                value={searchParamData}
                placeholder="Search Project"
              />
              <Button className="fa fa-search">Search</Button>
            </Form>
          </div>
          <div className="table-btn">
            <Button
              variant="primary"
              onClick={toggleProjectModal}
              data-toggle="modal"
              data-target="#business"
            >
              Add Project
            </Button>

            {/* <Button variant="primary">Import</Button> */}
          </div>
        </div>
        <div className="custom-table">
          {projectLoading && <Spin />}
          <BootstrapTable
            keyField="project_id"
            remote
            data={project}
            columns={ProjectColumns}
            onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          />
        </div>
      </div>
      {showProject && (
        <AddProject
          toggleProjectModal={toggleProjectModal}
          builderId={id}
          projectModal={projectModal}
          showProject={showProject}
          headProject={headProject}
          totalProject={
            projectList && projectList?.length > 0 ? projectList?.length : 0
          }
        />
      )}
    </>
  );
};
export default Projects;
