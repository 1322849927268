import React from "react";
import "../../../assets/css/style.css";

const AdminDashboard = () => {
  return (
    <>
      <h1>Welcome to Admin Dashboard</h1>
    </>
  );
};

export default AdminDashboard;
