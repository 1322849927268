import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import moment from "moment";
import { API, fileUpload, get } from "../../../config";
import { toast } from "react-toastify";
import Spin from "../../common/Spin";
import { AddBankTransactionSchema } from "../../common/Validation";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useCallback } from "react";

const transactionType = [
  { label: "Credit", value: 1 },
  { label: "Debit", value: 0 },
];

const AddBankTransaction = ({
  projectId,
  showBank,
  trust_type,
  toggleBankModal,
  bankModal
}) => {
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  const [loading, setLoading] = useState(false);
  const [defaultData, setDefaultData] = useState({});

  const [accountData, setAccountData] = useState([]);

  const getAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_ACCOUNTS}?builder_project_id=${
          projectId ? projectId : ""
        }&trust_type=${trust_type}`
      );
      const account =
        data?.data &&
        data?.data?.length > 0 &&
        data?.data?.map((item) => {
          return {
            label: `${item?.provider?.providerName} (${item?.accountNumber}) - ${item?.container}`,
            value: item?.id,
          };
        });
        
      setAccountData(account ? account : []);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [projectId, trust_type]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    value.account = value?.account?.value;
    value.baseType = value?.baseType?.value;
    // value.transactionDate = moment(value?.transactionDate).format("YYYY-MM-DD");
    value.amount = Number(`${value?.amount}`.replace(/,/g, ""));

    formData.append("yodlee_account_id", value?.account ? value?.account : "");
    formData.append("amount", value?.amount ? value?.amount : "");
    formData.append("baseType", value?.baseType);
    // formData.append(
    //   "transactionDate",
    //   value?.transactionDate
    //     ? moment(value?.transactionDate, "YYYY-MM-DD").format("DD-MM-YYYY")
    //     : ""
    // );
    formData.append(
      "transactionDate",
      value?.transactionDate ? value?.transactionDate : ""
    );
    formData.append("builder_project_id", projectId ? projectId : "");
    formData.append("trust_type", trust_type);
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.ADD_BANK_TRANSACTION}`,
        formData
      );
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleBankModal();
        bankModal();
      }
      return data?.data;
    } catch (error) {
      setDefaultData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showBank}
        onHide={toggleBankModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        className="project-section"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New bank Transaction
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              account: defaultData?.account
                ? accountData?.find(
                    (item) => item?.value === defaultData?.account
                  )
                : "",
              transactionDate: defaultData?.transactionDate
                ? moment(defaultData?.transactionDate, "DD-MM-YYYY")
                : "",
              amount: defaultData?.amount ? defaultData?.amount : "",
              baseType:
                defaultData?.baseType === 1 || defaultData?.baseType === 0
                  ? transactionType?.find(
                      (item) => item?.value === defaultData?.baseType
                    )
                  : "",
              description: defaultData?.description
                ? defaultData?.description
                : "",
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddBankTransactionSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    projectId={projectId}
                    toggleBankModal={toggleBankModal}
                    accountData={accountData}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBankTransaction;
