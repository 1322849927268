import React, { useRef } from "react";
import { Formik } from "formik";
import Form from "./components/Form";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Spin from "../../common/Spin";
import { API, fileUpload } from "../../../config";
import { toast } from "react-toastify";
import { AddHolidaysSchema } from "../../common/Validation";

const AddHolidays = ({ toggleHolidaysModal, showHolidays }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [loading, setLoading] = useState(false);
  const [holidaysData, setHolidaysData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];

  const handleFormSubmit = async (value) => {
    const formData = new FormData();
    formData.append("name", value?.name ? value?.name : "");
    formData.append(
      "description",
      value?.description ? value?.description : ""
    );
    formData.append("date", value?.date ? value?.date : "");

    try {
      setLoading(true);
      const { data } = await fileUpload(`${API.HOLIDAY}`, formData);
      setLoading(false);
      if (data) {
        toast.success(data?.message);
        toggleHolidaysModal();
      }
      return data?.data;
    } catch (error) {
      setHolidaysData(value);
      setLoading(false);
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showHolidays}
        onHide={toggleHolidaysModal}
        dialogClassName="modal-50w larg-popup review-popup small-review"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-0" closeButton>
          New Holiday
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              date: holidaysData?.date ? holidaysData?.date : "",
              name: holidaysData?.name ? holidaysData?.name : "",
              description: holidaysData?.description
                ? holidaysData?.description
                : "",
            }}
            enableReinitialize
            innerRef={formValues}
            onSubmit={handleFormSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={AddHolidaysSchema}
          >
            {({
              values,
              setErrors,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <>
                  {loading && <Spin />}
                  <Form
                    values={values}
                    errors={errors}
                    setErrors={setErrors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    formValues={formValues}
                    overwrittenFields={overwrittenFields}
                    existingValues={existingValues}
                    toggleHolidaysModal={toggleHolidaysModal}
                    tomorrow={tomorrow}
                  />
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddHolidays;
