import React from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Spin from "../../common/Spin";
import cellEditFactory from "react-bootstrap-table2-editor";

const ExcelEditorAdjustment = ({
  handleExcelModal,
  selectRow,
  ExcelBulkColumns,
  handleExcelTableChange,
  excelData,
  handleExcelClose,
  ref,
  showExcelModal,
  isImportLoading,
  selectedRow,
  handleUpload,
}) => {
  return (
    <Modal
      // size="xl"
      show={showExcelModal}
      onHide={handleExcelClose}
      dialogClassName="full-popup large-popup project-section"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="mb-0" closeButton>
        Import Bulk Adjustment
      </Modal.Header>
      <Modal.Body>
        <div className="excel-table-list" style={{ padding: "20px" }}>
          {isImportLoading && <Spin />}
          <BootstrapTable
            keyField="key"
            selectRow={selectRow}
            ref={ref}
            remote={{ cellEdit: true }}
            data={excelData ? excelData : []}
            columns={ExcelBulkColumns}
            noDataIndication="No Data Found"
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              timeToCloseMessage: 30000,
            })}
            onTableChange={handleExcelTableChange}
          />
          <div className="excel-list-footer">
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Button
                variant="primary"
                onClick={handleUpload}
                data-toggle="modal"
                data-target="#business"
                disabled={selectedRow.length === 0 ? true : false}
              >
                Import
              </Button>
              <div>Selected Records: {selectedRow?.length}</div>
            </div>
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div>
                  If amounts are in red (Check that the New approved Amount =
                  Payment Amount + Amount Withheld in RTA)
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExcelEditorAdjustment;
