import React from "react";
import ProjectHeaderNav from "./ProjectHeaderNav";
import Sitelogo from "./Logo";
import UserProfile from "./UserProfile";

const ProjectHeader = () => {
  return (
    <div className="header-blocks">
      <header>
        <Sitelogo />
        <ProjectHeaderNav />
        <UserProfile />
      </header>
      <div className="header-bottom-block">
        {/* <SearchBar
          setIsVisible={setIsVisible}
          setSelectedCustomer={setSelectedCustomer}
        /> */}
      </div>
    </div>
  );
};

export default ProjectHeader;
