import { Formik } from "formik";
import React from "react";
import { useState } from "react";
import {
  getFirmSettingDetail,
  useStateList,
} from "./components/FirmSettingHelper";
import { useEffect } from "react";
import Spin from "../../common/Spin";
import { useRef } from "react";
import Form from "./components/Form";
import { API, fileUpload, imageDefaultPrefixPath } from "../../../config";
import { toast } from "react-toastify";
import { FirmSettingSchema } from "../../common/Validation";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const FirmSetting = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [firmSettingData, setFirmSetingData] = useState();
  const formValues = useRef();
  let existingValues = [];
  let overwrittenFields = [];
  // const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getFirmSettingDetail();
      setFirmSetingData(data?.accountingFirm);
      if (roleType !== "Client" && roleType !== "Admin") {
        const firmData = await getFirmSettingDetail();
        localStorage.setItem(
          "bt-accounting-firm-logo",
          firmData?.accountingFirm?.logo ? firmData?.accountingFirm?.logo : ""
        );
        navigate("/general-settings?tab=firm-settings", { replace: true });
      }
      setLoading(false);
    } catch (e) {
      setFirmSetingData(null);
      setLoading(false);
    }
  }, [navigate, roleType]);

  const handleFormSubmit = async (value) => {
    const formData = new FormData();

    value.logo = value?.image ? value?.image : value?.logo ? value?.logo : "";

    value.phone = value?.phone ? `${value?.phone}`.replace(/ /g, "") : "";

    value.logo =
      value?.logo && typeof value?.logo === "string"
        ? value?.logo - imageDefaultPrefixPath
        : value?.logo;

    value.state_id = value?.state_id?.value;
    value.suburb_id = value?.suburb_id?.id;

    formData.append("_method", "put");
    formData.append("logo", value?.logo ? value?.logo : "");
    formData.append(
      "business_name",
      value?.business_name ? value?.business_name : ""
    );
    formData.append(
      "business_email",
      value?.business_email ? value?.business_email : ""
    );
    formData.append("phone", value?.phone ? value?.phone : "");
    formData.append("tan", value?.tan ? value?.tan : "");

    formData.append("street", value?.street ? value?.street : "");
    formData.append("state_id", value?.state_id ? value?.state_id : "");
    formData.append("suburb_id", value?.suburb_id ? value?.suburb_id : "");
    formData.append(
      "postal_code",
      value?.postal_code ? value?.postal_code : ""
    );

    try {
      setLoading(true);
      const { data } = await fileUpload(
        `${API.ACCOUNTING_FIRM_SETTINGS}`,
        formData
      );
      setLoading(false);
      if (data) {
        fetchData();
        if (roleType !== "Client" && roleType !== "Admin") {
          navigate("/general-settings?tab=firm-settings", { replace: true });
        }
        toast.success(data?.message);
      }
      return data?.data;
    } catch (error) {
      fetchData();
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
        setLoading(false);
      } else {
        toast.error(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // State
  const { stateLoading, stateList = [] } = useStateList();
  const stateListing = stateList?.map((item) => {
    const { id: value, name, short_name, ...rest } = item;
    return { label: name + ` (${short_name})`, value, ...rest };
  });
  const state = stateListing?.find(
    (item) =>
      item?.value ===
      (firmSettingData?.state_id
        ? firmSettingData?.state_id
        : firmSettingData?.state_id)
  );

  return (
    <>
      <div>
        {loading && <Spin />}
        <Formik
          initialValues={{
            business_name: firmSettingData?.business_name
              ? firmSettingData?.business_name
              : "",
            business_email: firmSettingData?.business_email
              ? firmSettingData?.business_email
              : "",
            phone: firmSettingData?.phone ? firmSettingData?.phone : "",
            logo: firmSettingData?.logo
              ? imageDefaultPrefixPath + firmSettingData?.logo
              : "",
            tan: firmSettingData?.tan ? firmSettingData?.tan : "",
            street: firmSettingData?.street ? firmSettingData?.street : "",
            state_id: state,
            suburb_id: firmSettingData?.suburb ? firmSettingData?.suburb : "",
            postal_code: firmSettingData?.postal_code
              ? firmSettingData?.postal_code
              : "",
          }}
          enableReinitialize
          validateOnBlur={true}
          validateOnChange={true}
          validationSchema={FirmSettingSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            setErrors,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form
              values={values}
              errors={errors}
              setErrors={setErrors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              formValues={formValues}
              overwrittenFields={overwrittenFields}
              existingValues={existingValues}
              stateListing={stateListing}
              stateLoading={stateLoading}
            />
          )}
        </Formik>
      </div>
    </>
  );
};

export default FirmSetting;
