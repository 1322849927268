import React, { useLayoutEffect, useState } from "react";
import { API, get, post } from "../../config";
import "../../components/Authentication/authentication.css";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import Spin from "../../pages/common/Spin";

const ConfirmBackupEmail = (props) => {
  const { submitButton, verifyUrl, backupEmail } = props;
  const [validator, setValidator] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerifyCode, setEmailVerifyCode] = useState("");
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const test = async () => {
      const { data } = await get(API.GET_USER_PROFILE);
      if (data?.backup_email) setEmail(data.backup_email);
    };
    if (!backupEmail) {
      test();
    } else {
      setEmail(backupEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *Verfiy Email Code using API - Start
  const changeEmailCode = (event) => {
    if (validator) {
      setValidator(false);
    }
    setEmailVerifyCode(event.target.value);
  };

  const confirmEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    let params = { code: emailVerifyCode };
    if (verifyUrl === API.DELETE_2FA) {
      params.id = 2;
    }
    post(verifyUrl, { code: emailVerifyCode })
      .then(async () => {
        await submitButton();
        setValidator(false);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Authentication code is invalid");
        setLoading(false);
        setValidator(true);
      });
  };
  // *Verfiy Email Code using API - End

  return (
    <form onSubmit={confirmEmail}>
      {loading && <Spin />}
      <h3>Check your backup email and enter the code</h3>
      <p>{`Enter the code sent to ${email} to confirm your email address`}</p>

      <input
        autoFocus
        placeholder="Confirmation Code"
        onChange={changeEmailCode}
      />
      <Button type="submit">Confirm</Button>
    </form>
  );
};

export default ConfirmBackupEmail;
