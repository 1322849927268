/* eslint-disable eqeqeq */
import { useState, useCallback, useEffect, useRef } from "react";
import Pagination from "../../common/Pagination";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { API, defaultLimitPagination, fileUpload, get } from "../../../config";
import { toast } from "react-toastify";
import {
  DDMMYYYYFormat,
  ExcelDateFormat,
  formatNumber,
  formatSubcontractorName,
} from "../../common/Misc";
import PaymentClaimListActionButton from "./components/PaymentClaimListActionButton";
import Spin from "../../common/Spin";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Modal,
} from "react-bootstrap";
import { useProjectBar } from "../../common/useProjectBar";
import * as XLSX from "xlsx";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Type } from "react-bootstrap-table2-editor";
import UploadFile from "../../common/UploadFile";
import moment from "moment";
import PaymentClaimStatus from "./components/PaymentClaimStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "rsuite";
import axios from "axios";
import paymentSchedule from "../../../assets/images/payment-schedule.png";
import { debounce } from "lodash";
import CustomTooltip from "../../common/CustomTooltip";

const PaymentClaimList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "retention" ? 0 : 1;

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [notSelectedRowIds, setNotSelectedRowIds] = useState([]);
  const [disapprovedReason, setDisapprovedReason] = useState();
  const [receiveDate, setReceiveDate] = useState(today);

  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const query = new URLSearchParams(location.search);
  const defaultLimit = query.get("limit");
  const search = query.get("search");
  const [searchParam, setSearchParam] = useState(search || "");
  const [searchParamData, setSearchParamData] = useState(search || "");
  const [paymentClaimList, setPaymentClaimList] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimit || defaultLimitPagination);
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [loading, setLoading] = useState(false);
  // const searchParam = query.get("search");
  const [approve, setApprove] = useState();
  const [showApprove, setShowApprove] = useState(false);

  const { ProjectReadOnly } = useProjectBar();

  const handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === "sort") {
      setPage(1);
      setSortField(sortField);
      setSortOrder(
        sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
      );
    }
  };

  const handleApprove = () => {
    if (showApprove) {
      setDisapprovedReason();
    }
    setShowApprove(!showApprove);
  };

  const fetchPaymnetClaims = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await get(
        `${API.GET_PAYMENT_CLAIM_LIST}?builder_project_id=${projectId}&limit=${
          limit ? limit : defaultLimitPagination
        }&page=${page ? page : 1}&trust_type=${trust_type}&search=${
          searchParam ? searchParam : ""
        }&sort_column=${sortField ? sortField : ""}&sort_order=${
          sortOrder ? sortOrder : ""
        }`
      );
      setLoading(false);
      setLimit(data?.data?.per_page);
      setPage(data?.data?.current_page);
      setTotal(data?.data?.total);
      setFrom(data?.data?.from);
      setTo(data?.data?.to);
      const paymentClaimList = data.data.data;
      const notSelected = [];
      for (let i = 0; i < paymentClaimList?.length; i++) {
        if (paymentClaimList[i]?.status != 0) {
          notSelected.push(paymentClaimList[i]?.id);
        }
      }
      setNotSelectedRowIds(notSelected);
      setSelectedRowIds([]);

      setPaymentClaimList(paymentClaimList);
      return data.data;
    } catch (error) {
      setLoading(false);
      setPaymentClaimList([]);

      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((key) => {
          toast.error(error?.response?.data?.errors[key][0]);
        });
      } else {
        toast.error(error?.message);
      }
    }
  }, [projectId, limit, page, trust_type, searchParam, sortField, sortOrder]);

  const handleDelete = () => {
    fetchPaymnetClaims();
  };

  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const paymentClaimGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        key: paymentClaimList[i]?.id,
        expandData: paymentClaimList[i]?.payment_claim_items
          ? paymentClaimList[i]?.payment_claim_items
          : [],
        payment_claim_to: formatSubcontractorName(paymentClaimList[i]?.contact),
        invoice_number: paymentClaimList[i]?.invoice_number ? (
          paymentClaimList[i]?.file ? (
            <Link
              onClick={() => downloadLetter(paymentClaimList[i]?.file)}
              style={{
                textDecoration: "underline",
              }}
            >
              {paymentClaimList[i]?.invoice_number}
            </Link>
          ) : (
            paymentClaimList[i]?.invoice_number
          )
        ) : (
          "-"
        ),
        invoice_date: paymentClaimList[i]?.invoice_date
          ? DDMMYYYYFormat(paymentClaimList[i]?.invoice_date?.split("-"))
          : "",
        due_date: paymentClaimList[i]?.due_date
          ? DDMMYYYYFormat(paymentClaimList[i]?.due_date?.split("-"))
          : "",
        claimed_amount: paymentClaimList[i]?.total_claimed_amount
          ? `$${formatNumber(
              Number(paymentClaimList[i]?.total_claimed_amount)
            )}`
          : "$0.00",
        post_date: paymentClaimList[i]?.post_date
          ? DDMMYYYYFormat(paymentClaimList[i]?.post_date?.split("-"))
          : "-",
        status: (
          <>
            <PaymentClaimStatus
              id={paymentClaimList[i]?.id}
              approve={paymentClaimList[i]?.status}
              email={
                paymentClaimList[i]?.contact?.email
                  ? paymentClaimList[i]?.contact?.email
                  : ""
              }
              fetchPaymnetClaims={fetchPaymnetClaims}
              projectId={projectId}
              trust_type={trust_type}
              setSelectedRowIds={setSelectedRowIds}
            />
          </>
        ),
        approve_status: `${paymentClaimList[i]?.status}`,
        action: (
          <>
            <PaymentClaimListActionButton
              id={paymentClaimList[i]?.id}
              handleDelete={handleDelete}
              file={paymentClaimList[i]?.file}
              dataView={paymentClaimList[i]}
              approve={paymentClaimList[i]?.status}
              email={
                paymentClaimList[i]?.contact?.email
                  ? paymentClaimList[i]?.contact?.email
                  : ""
              }
              fetchPaymnetClaims={fetchPaymnetClaims}
              projectId={projectId}
              trust_type={trust_type}
            />
          </>
        ),
      });
    }
    return items;
  };
  const paymentClaims = paymentClaimGenerator(paymentClaimList?.length);

  let singleSelectedRows = [];
  let allSelectedRows = [];
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    selected: selectedRowIds,
    nonSelectable: notSelectedRowIds,
    nonSelectableClasses: (row) => {
      if (row?.approve_status === "2") {
        return "parent-approved-status";
      } else if (row?.approve_status === "1") {
        return "parent-in-progress-status";
      } else if (row?.approve_status === "-1") {
        return "parent-disapproved-status";
      } else {
        return "parent-pending-status";
      }
    },
    classes: (row) => {
      if (row?.approve_status === "2") {
        return "parent-approved-status";
      } else if (row?.approve_status === "1") {
        return "parent-in-progress-status";
      } else if (row?.approve_status === "-1") {
        return "parent-disapproved-status";
      } else {
        return "parent-pending-status";
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      singleSelectedRows = [...selectedRowIds];
      if (isSelect) {
        singleSelectedRows = [...selectedRowIds, row?.key];
      } else {
        singleSelectedRows.splice(selectedRowIds.indexOf(row?.key), 1);
      }
      setSelectedRowIds(singleSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRowIds.includes(rows[i].key)) {
          } else {
            allSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRowIds.includes(rows[i].key)) {
            selectedRowIds.splice(selectedRowIds.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRowIds([...selectedRowIds, ...allSelectedRows]);
    },
  };

  useEffect(() => {
    fetchPaymnetClaims();
  }, [fetchPaymnetClaims]);

  const handleUpdateLimit = (e) => {
    const limit = e.target.value;
    setLimit(limit);
    setPage(1);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  const columns = [
    {
      dataField: "post_date",
      text: "Posted Date",
      // sort: true,
    },
    {
      dataField: "payment_claim_to",
      text: "Payment Claim By ",
      // sort: true,
    },
    {
      dataField: "invoice_number",
      text: "Invoice/Claim Number",
      // sort: true,
    },
    {
      dataField: "invoice_date",
      text: "Invoice/Claim Date",
      // sort: true,
    },
    {
      dataField: "due_date",
      text: "Due Date",
      // sort: true,
    },
    {
      dataField: "claimed_amount",
      text: "Claimed Amount (Inc. GST)",
      // sort: true,
    },
    {
      dataField: "status",
      text: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <div className="apca-info info-text claim-status">
            <DropdownButton className="info-icon">
              <Dropdown.Item>
                <div className="payment-claim-list">
                  <div className="payment-claim-status-text">
                    <FontAwesomeIcon
                      className="pending-status"
                      icon={faCircle}
                    />
                    <span>
                      Pending (Payment claim made but no action taken)
                    </span>
                  </div>
                  <div className="payment-claim-status-text">
                    <FontAwesomeIcon
                      className="in-progress-status"
                      icon={faClock}
                    />
                    <span>In Progress (Posted but not paid)</span>
                  </div>
                  <div className="payment-claim-status-text">
                    <FontAwesomeIcon
                      className="approved-status"
                      icon={faCircleCheck}
                    />
                    <span>Completed (Posted & Paid)</span>
                  </div>
                  <div className="payment-claim-status-text">
                    <FontAwesomeIcon
                      className="disapproved-status"
                      icon={faCircleXmark}
                    />
                    <span>Rejected (Payment Claim Rejected)</span>
                  </div>
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
          Status
        </div>
      ),
      // headerStyle: { width: "10%" },
      headerStyle: { width: "105px" },
    },

    {
      dataField: "action",
      text: "Action",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "127px" },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setSearchParam(nextValue);
      setPage(1);
    }, 500),
    []
  );

  const handleSearchChange = (event) => {
    const { value: nextValue } = event.target;
    setSearchParamData(nextValue);
    debouncedSave(nextValue);
  };

  useEffect(() => {
    navigate(
      `/projects/${projectId}/payment-claims?tab=list&search=${
        searchParam ? searchParam : ""
      }&limit=${limit ? limit : defaultLimitPagination}&page=${
        page ? page : 1
      }&sort_column=${sortField ? sortField : ""}&sort_order=${
        sortOrder ? sortOrder : ""
      }`,
      {
        replace: true,
      }
    );
  }, [projectId, navigate, searchParam, limit, page, sortField, sortOrder]);

  const fetchPaymentClaimApproveReject = useCallback(
    async (status, selectedRowIds, disapprovedReason, receiveDate) => {
      try {
        setLoading(true);
        const formData = new FormData();
        if (selectedRowIds?.length > 0) {
          for (let i = 0; i < selectedRowIds?.length; i++) {
            formData.append(`ids[${i}]`, selectedRowIds[i]);
          }
        }
        formData.append("builder_project_id", projectId);
        formData.append("status", status);
        if (status === "-1") {
          formData.append("disapprove_reason", disapprovedReason);
        }
        if (receiveDate) {
          // formData.append(
          //   "receive_date",
          //   receiveDate ? moment(receiveDate).format("DD-MM-YYYY") : ""
          // );
          formData.append(
            "receive_date",
            receiveDate ? moment(receiveDate).format("DD-MM-YYYY") : ""
          );
        }
        formData.append("trust_type", trust_type);
        const { data } = await fileUpload(
          `${API.GET_PAYMENT_CLAIM_LIST}/approve-reject`,
          formData
        );
        setShowApprove(false);
        fetchPaymnetClaims();
        setDisapprovedReason();
        setReceiveDate();
        data?.message && toast.success(data?.message);
        setLoading(false);
        setSelectedRowIds([]);
      } catch (error) {
        setShowApprove(false);
        setSelectedRowIds([]);
        setDisapprovedReason();
        setReceiveDate();
        if (error?.response?.data?.errors) {
          setLoading(false);
          Object.keys(error?.response?.data?.errors).forEach((key) => {
            toast.error(error?.response?.data?.errors[key][0]);
          });
        } else {
          setLoading(false);
          toast.error(error?.message);
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  // *Excel Import Payment Claim - Start
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [excelContactData, setExcelContactData] = useState();
  const [selectedRow, setSelectedRow] = useState([]);

  const handleDownload = async () => {
    try {
      setIsLoadingDownload(true);
      const { data } = await get(API.DOWNLOAD_PAYMENT_CLAIM_TEMPLATE);
      const export_report_path = data?.data?.file;
      if (export_report_path) {
        var a = document.createElement("a");
        a.href = export_report_path;
        var file = export_report_path.split("/");
        a.setAttribute("download", file[file.length - 1] || "contractor.xls");
        a.setAttribute("target", "_blank");
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        setIsLoadingDownload(false);
      }
      setIsLoadingDownload(false);
    } catch (e) {
      setIsLoadingDownload(false);
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const handleImportClose = () => {
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const handleExcelClose = () => {
    setSelectedRow([]);
    setShowExcelModal(false);
    setShowImport(false);
    setIsImportLoading(false);
    setFiles();
  };

  const ExcelContactColumns = [
    {
      dataField: "name",
      text: (
        <div>
          <span className="required">*</span>Business Name
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
    },
    {
      dataField: "abn",
      text: (
        <div>
          <span className="required">*</span>ABN
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (
            !String(newValue)
              .split(" ")
              .join("")
              .match(/^[\d]{11}$/)
          ) {
            return {
              valid: false,
              message: "Enter 11 Digit ABN Number",
            };
          }
          if (newValue.split(" ").join("").length === 11) {
            let newNumber = newValue.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return {
                valid: false,
                message: "Enter Valid ABN Number",
              };
            }
          }
        } else {
          return true;
        }
      },
      classes: (cell) => {
        if (cell) {
          let weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
          if (cell.split(" ").join("").length !== 11) {
            // toast.error("Enter 11 Digit ABN Number")
            return "error-cell";
          }
          if (cell.split(" ").join("").length === 11) {
            let newNumber = cell.split(" ").join("");
            let sum = 0;
            weights.forEach((item, index) => {
              let digit = newNumber[index] - (index ? 0 : 1);
              sum += item * digit;
            });
            if (sum % 89 !== 0) {
              return "error-cell";
            }
          }
        }
      },
    },
    {
      dataField: "email",
      text: "Email",
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        // if (newValue === "" || newValue === undefined || newValue === null) {
        //   return {
        //     valid: false,
        //     message: "Email is Required",
        //   };
        // }
        if (newValue) {
          if (
            !newValue
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return {
              valid: false,
              message: "Please enter a valid email address",
            };
          }
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          if (
            !String(cell)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
          ) {
            return "error-cell";
          }
        }
      },
    },
    {
      dataField: "invoice_number",
      text: (
        <div>
          <span className="required">*</span>Claim/Invoice Number
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Invoice Number is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "invoice_number";
        }
      },
    },
    {
      dataField: "invoice_date",
      text: (
        <div>
          <span className="required">*</span>Claim/Invoice Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Invoice Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "invoice_date";
        }
      },
    },
    {
      dataField: "due_date",
      text: (
        <div>
          <span className="required">*</span>Due Date
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell === "string" && cell?.includes("-") && cell) {
          dateObj = cell?.split("-");
          if (dateObj[2]?.length > 2) {
            return `${dateObj[0]}-${dateObj[1]}-${dateObj[2]}`;
          } else {
            return `${dateObj[2]}-${dateObj[1]}-${dateObj[0]}`;
          }
        }
        if (typeof cell !== "object" && cell) {
          var utc_days = Math.floor(cell - 25569);
          var utc_value = utc_days * 86400;
          dateObj = new Date(utc_value * 1000);
        }
        if (cell) {
          return `${("0" + dateObj?.getUTCDate()).slice(-2)}-${(
            "0" +
            (dateObj?.getUTCMonth() + 1)
          ).slice(-2)}-${dateObj?.getUTCFullYear()}`;
        }
      },
      editor: {
        type: Type.DATE,
      },
      validator: (newValue) => {
        if (newValue === "" || newValue === undefined || newValue === null) {
          return {
            valid: false,
            message: "Due Date is Required",
          };
        }
        return true;
      },
      classes: (cell) => {
        if (cell) {
          return "due_date";
        }
      },
    },
    {
      dataField: "claimed_amount",
      text: (
        <div>
          <span className="required">*</span>Claimed Amount
        </div>
      ),
      editCellClasses: "edit-cell-excel-class",
      validator: (newValue, row) => {
        if (
          (newValue === "" || newValue === undefined || newValue === null) &&
          row?.schedule_group === 0
        ) {
          return {
            valid: false,
            message: "Claimed Amount is Required",
          };
        }
        return true;
      },
    },
  ];

  const dateFormatReader = (value) => {
    var utc_days = Math.floor(value - 25569);
    var utc_value = utc_days * 86400;
    const date = new Date(utc_value * 1000);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleImportModal = async () => {
    setShowImport(true);
  };

  let singleExcelSelectedRows = [];
  let allExcelSelectedRows = [];

  const excelSelectRow = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row",
    nonSelectable: ["not-select"],
    selected: selectedRow,
    nonSelectableClasses: "not-selected-class",
    onSelect: (row, isSelect, rowIndex, e) => {
      singleExcelSelectedRows = [...selectedRow];
      if (isSelect) {
        singleExcelSelectedRows = [...selectedRow, row?.key];
      } else {
        singleExcelSelectedRows.splice(selectedRow.indexOf(row?.key), 1);
      }
      setSelectedRow(singleExcelSelectedRows);
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
          } else {
            allExcelSelectedRows.push(rows[i].key);
          }
        }
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (selectedRow.includes(rows[i].key)) {
            selectedRow.splice(selectedRow.indexOf(rows[i].key), 1);
          }
        }
      }
      setSelectedRow([...selectedRow, ...allExcelSelectedRows]);
    },
  };

  const ref = useRef();

  const ExcelReader = () => {
    let f = files;
    const reader = new FileReader();
    let data = null;
    reader.onload = async (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      data = await XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      let selectItem = [];
      const businessExcelGenerator = (quantity) => {
        const items = [];
        if (
          String(data[0][0]).replace("*", "") !== "Business Name" ||
          String(data[0][1]).replace("*", "") !== "ABN" ||
          String(data[0][2]).replace("*", "") !== "Email" ||
          String(data[0][3]).replace("*", "") !== "Claim/Invoice Number" ||
          String(data[0][4]).replace("*", "") !== "Claim/Invoice Date" ||
          String(data[0][5]).replace("*", "") !== "Due Date" ||
          String(data[0][6]).replace("*", "") !== "Claimed Amount"
        ) {
          setShowExcelModal(false);
          setFiles();
          setIsImportLoading(false);
          toast.error(
            "Sorry, File is not in the given format, please download the latest format again"
          );
        } else {
          for (let i = 1; i < quantity; i++) {
            if (data[i].length > 0) {
              let invoice_date =
                typeof data[i][4] === "number"
                  ? dateFormatReader(data[i][4])
                  : data[i][4]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][4])
                  : ExcelDateFormat(
                      data[i][4]?.split("-")?.reverse()?.join("-")
                    );
              let due_date =
                typeof data[i][5] === "number"
                  ? dateFormatReader(data[i][5])
                  : data[i][5]?.split("-")[2] > 2
                  ? ExcelDateFormat(data[i][5])
                  : ExcelDateFormat(
                      data[i][5]?.split("-")?.reverse()?.join("-")
                    );
              selectItem.push(i);
              items.push({
                key: i,
                name: data[i][0] ? data[i][0] : "",
                abn: data[i][1]
                  ? `${Number(String(data[i][1])?.split(" ")?.join(""))
                      .toLocaleString("en-US")
                      .replaceAll(",", " ")}`
                  : "",
                email: data[i][2] ? data[i][2] : "",
                invoice_number: data[i][3] ? data[i][3] : "",
                invoice_date:
                  typeof data[i][4] === "string"
                    ? invoice_date?.split("-")?.reverse()?.join("-")
                    : invoice_date
                    ? invoice_date
                    : "",
                due_date:
                  typeof data[i][5] === "string"
                    ? due_date?.split("-")?.reverse()?.join("-")
                    : due_date
                    ? due_date
                    : "",
                claimed_amount: data[i][6] ? data[i][6] : "",
              });
            }
          }
        }
        return items;
      };
      setSelectedRow(selectItem);
      setExcelContactData(businessExcelGenerator(data?.length));
    };
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    showExcelModal && ExcelReader();
    // eslint-disable-next-line
  }, [showExcelModal]);

  const handleExcelModal = () => {
    setShowExcelModal(true);
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  const handleUpload = async () => {
    if (selectedRow.length > 1000) {
      toast.warn("Maximum limit to import payment Claim is 1000");
    } else {
      if (files) {
        setShowExcelModal(true);
        const formData = new FormData();

        formData.append("builder_project_id", projectId);
        formData.append("trust_type", trust_type);

        for (let i = 0; i < selectedRow.length; i++) {
          let row = excelContactData.find(
            (item) => item.key === selectedRow[i]
          );
          row.abn = row.abn.split(" ").join("");
          if (typeof row.invoice_date !== "object") {
            let invoicedateTemp = String(row.invoice_date)
              ?.split("T")[0]
              ?.split("-");
            // row.invoice_date = `${invoicedateTemp[2]}-${invoicedateTemp[1]}-${invoicedateTemp[0]}`;
            if (invoicedateTemp[2]?.length === 4) {
              row.invoice_date = `${invoicedateTemp[0]}-${invoicedateTemp[1]}-${invoicedateTemp[2]}`;
            } else {
              row.invoice_date = `${invoicedateTemp[2]}-${invoicedateTemp[1]}-${invoicedateTemp[0]}`;
            }
          } else {
            row.invoice_date = moment(row?.invoice_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            );
          }
          if (typeof row.due_date !== "object") {
            let dueDateTemp = String(row.due_date)?.split("T")[0]?.split("-");
            // row.due_date = `${dueDateTemp[2]}-${dueDateTemp[1]}-${dueDateTemp[0]}`;
            if (dueDateTemp[2]?.length === 4) {
              row.due_date = `${dueDateTemp[0]}-${dueDateTemp[1]}-${dueDateTemp[2]}`;
            } else {
              row.due_date = `${dueDateTemp[2]}-${dueDateTemp[1]}-${dueDateTemp[0]}`;
            }
          } else {
            row.due_date = moment(row?.due_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            );
          }

          // formData.append(`contractors[${i}]`, JSON.stringify(row));
          Object.keys(row).forEach((key) => {
            if (key === "claimed_amount") {
              formData.append(
                `paymentClaims[${i}][total_claimed_amount]`,
                row[key]
              );
            } else {
              formData.append(`paymentClaims[${i}][${key}]`, row[key]);
            }
          });
        }

        const config = { headers: { "Content-Type": "multipart/form-data" } };
        try {
          setIsImportLoading(true);
          const { data } = await fileUpload(
            `${API.IMPORT_PAYMENT_CLAIM}`,
            formData,
            config
          );
          setIsImportLoading(false);
          if (data) {
            toast.success(data?.message);
            setSelectedRow([]);
            handleExcelClose();
            fetchPaymnetClaims();
            handleImportClose();
            if (data?.data?.file) {
              const export_report_path = data?.data?.file;
              if (export_report_path) {
                var a = document.createElement("a");
                a.href = export_report_path;
                var file = export_report_path.split("/");
                a.setAttribute(
                  "download",
                  file[file.length - 1] || "error-subcontractor.xls"
                );
                a.setAttribute("target", "_blank");
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
          }
          return data?.data;
        } catch (error) {
          if (error?.response?.data?.errors) {
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            if (error?.response?.data?.data?.failedData.length > 0) {
              let failData = [];
              for (
                let i = 0;
                i < error?.response?.data?.data?.failedData.length;
                i++
              ) {
                let contactItem =
                  error?.response?.data?.data?.failedData[i]?.data;
                if (contactItem?.phone) {
                  contactItem.phone = `+${Number(contactItem?.phone)
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`;
                } else {
                  contactItem.phone = "";
                }
                if (contactItem?.mobile) {
                  contactItem.mobile = `+${Number(contactItem?.mobile)
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`;
                } else {
                  contactItem.mobile = "";
                }
                if (contactItem?.abn) {
                  contactItem.abn = `${Number(contactItem?.abn)
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`;
                } else {
                  contactItem.abn = "";
                }
                failData.push(contactItem);
              }
              setSelectedRow([]);
              setExcelContactData(failData);
            }
            fetchPaymnetClaims();
            setIsImportLoading(false);
          } else {
            toast.error(error?.message);
            setIsImportLoading(false);
          }
          if (error?.response?.data?.errors) {
            if (error?.response?.data?.errors?.data?.file) {
              const export_report_path =
                error?.response?.data?.errors?.data?.file;
              if (export_report_path) {
                // eslint-disable-next-line no-redeclare
                var a = document.createElement("a");
                a.href = export_report_path;
                // eslint-disable-next-line no-redeclare
                var file = export_report_path.split("/");
                a.setAttribute(
                  "download",
                  file[file.length - 1] || "error-sub-contractor.xls"
                );
                a.setAttribute("target", "_blank");
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                setIsLoadingDownload(false);
              }
            }
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
            fetchPaymnetClaims();
            setIsImportLoading(false);
          } else {
            toast.error(error?.message);
            setIsImportLoading(false);
          }
        }
      }
    }
  };

  const handleExcelTableChange = async (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    const getWithPromiseAll = async () => {
      let temp = await Promise.all(
        data.map(async (row) => {
          if (row?.key === rowId) {
            const newRow = { ...row };
            if (dataField === "account_number") {
              newRow[dataField] = padWithLeadingZeros(Number(newValue), 9);
            } else if (dataField === "abn") {
              newRow[dataField] = newValue
                ? `${Number(newValue.split(" ").join(""))
                    .toLocaleString("en-US")
                    .replaceAll(",", " ")}`
                : "";
            } else if (dataField === "bsb") {
              if (String(newValue).match("-")) {
                newRow[dataField] =
                  padWithLeadingZeros(
                    Number(String(newValue).split("-").join("")),
                    6
                  ).slice(0, 3) +
                  "-" +
                  padWithLeadingZeros(
                    Number(String(newValue).split("-").join("")),
                    6
                  ).slice(3, 6);
              } else {
                newRow[dataField] =
                  padWithLeadingZeros(Number(newValue), 6).slice(0, 3) +
                  "-" +
                  padWithLeadingZeros(Number(newValue), 6).slice(3, 6);
              }
            } else {
              newRow[dataField] = newValue;
            }
            return newRow;
          }
          return row;
        })
      );
      return temp;
    };
    const result = await getWithPromiseAll();
    setExcelContactData(result);
  };
  // *Excel Import Payment Claim - End
  return (
    <>
      <div className="table-top-btn payment-claim-search">
        <div className="search-section">
          <Form>
            <FormControl
              onChange={(e) => handleSearchChange(e)}
              type="text"
              value={searchParamData}
              placeholder="Search Payment Claim"
            />
            <Button className="fa fa-search">Search</Button>
          </Form>
        </div>

        <div className="payment-status-badge">
          <div className="payment-claim-list">
            <div className="payment-claim-status-text">
              <FontAwesomeIcon className="pending-status" icon={faCircle} />
              <span>Pending (Claim made but no action taken)</span>
            </div>
            <div className="payment-claim-status-text">
              <FontAwesomeIcon className="in-progress-status" icon={faClock} />
              <span>In Progress (Posted but not paid)</span>
            </div>
            <div className="payment-claim-status-text">
              <FontAwesomeIcon
                className="approved-status"
                icon={faCircleCheck}
              />
              <span>Completed (Posted & Paid)</span>
            </div>
            <div className="payment-claim-status-text">
              <FontAwesomeIcon
                className="disapproved-status"
                icon={faCircleXmark}
              />
              <span>Rejected</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="table-top-btn archived-transactions-date-range"
        style={{ justifyContent: "end" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              padding: "0px 12px",
              fontSize: "16px",
              height: "28px",
              lineHeight: "28px",
            }}
          >
            Selected Record: {selectedRowIds.length}
          </div>
          {!ProjectReadOnly ? (
            selectedRowIds?.length === 0 ? (
              <CustomTooltip
                message={"(This will record the transaction in General Ledger)"}
              >
                <span>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setApprove(false);
                      handleApprove();
                    }}
                    style={{
                      padding: "10px 15px",
                      marginLeft: "15px",
                    }}
                    disabled={true}
                  >
                    Post
                  </Button>
                </span>
              </CustomTooltip>
            ) : (
              <CustomTooltip
                message={"(This will record the transaction in General Ledger)"}
              >
                <span>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setApprove(false);
                      handleApprove();
                    }}
                    style={{
                      padding: "10px 15px",
                      marginLeft: "15px",
                    }}
                  >
                    Post
                  </Button>
                </span>
              </CustomTooltip>
            )
          ) : (
            <></>
          )}
          {!ProjectReadOnly ? (
            selectedRowIds?.length === 0 ? (
              <Button
                variant="primary"
                onClick={() => {
                  setApprove(true);
                  handleApprove();
                }}
                style={{
                  padding: "10px 15px",
                  marginLeft: "15px",
                }}
                disabled={true}
              >
                Reject
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setApprove(true);
                  handleApprove();
                }}
                style={{
                  padding: "10px 15px",
                  marginLeft: "15px",
                }}
              >
                Reject
              </Button>
            )
          ) : (
            <></>
          )}
        </div>
        {!ProjectReadOnly && (
          <div className="table-btn">
            <Button
              variant="primary"
              onClick={() =>
                navigate(`/projects/${projectId}/payment-claims?tab=input`, {
                  replace: true,
                })
              }
            >
              Input
            </Button>
            <div className="border-btn">
              <DropdownButton className="info-icon">
                <Dropdown.Item>
                  <p>Business Name - Required</p>
                  <p>ABN - Required</p>
                  <p>Claim/Invoice Number - Required</p>
                  <p>Claim/Invoice Date - Required</p>
                  <p>Due Date - Required</p>
                  <p>Claimed Amount - Required</p>
                </Dropdown.Item>
              </DropdownButton>
              <Button
                variant="link"
                disabled={isLoadingDownload}
                onClick={() => handleDownload()}
                className="download-template"
              >
                Download Template
              </Button>
              <Button variant="primary" onClick={handleImportModal}>
                Upload Payment Claims
              </Button>
            </div>
            <CustomTooltip message={"Payment Schedules"}>
              <span>
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate(
                      `/projects/${projectId}/project/payment-schedules`,
                      {
                        replace: true,
                      }
                    )
                  }
                  className="payment-scheduled-icon-btn"
                >
                  {/* Payment Schedules */}
                  <img
                    src={paymentSchedule}
                    alt="Payment Schedule"
                    style={{
                      height: "18px",
                      width: "18px",
                    }}
                  />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        )}
      </div>
      <div className="custom-table tab-table payment-claim-list">
        {loading && <Spin />}
        <BootstrapTable
          keyField="key"
          remote
          data={paymentClaims || []}
          columns={columns}
          onTableChange={handleTableChange}
          // expandRow={expandRow}
          selectRow={selectRow}
          noDataIndication="No Data Found"
        />

        <Pagination
          total={total}
          limit={parseInt(limit)}
          currentPage={page}
          updateLimit={handleUpdateLimit}
          updatePage={handleChangePage}
          from={from}
          to={to}
        />
      </div>
      {showApprove && (
        <Modal
          size="lg"
          show={showApprove}
          onHide={handleApprove}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="project-section"
        >
          <Modal.Header className="mb-0" closeButton>
            {approve ? "Reject" : "Confirm"} Payment Claim
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              {approve ? (
                <div className="paymentNote" style={{ marginBottom: "10px" }}>
                  <p>
                    <span className="required">*</span>Reject Reason
                  </p>
                  <Form.Control
                    type="text"
                    className="left-text-align"
                    required
                    maxLength={150}
                    value={disapprovedReason ? disapprovedReason : ""}
                    rules={[
                      {
                        pattern: new RegExp(
                          /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                        ),
                        message:
                          "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                      },
                    ]}
                    onChange={(e) => {
                      setDisapprovedReason(e.target.value);
                    }}
                  ></Form.Control>
                </div>
              ) : (
                <div className="claim-received-date">
                  <p>
                    <span className="required">*</span>Received Date:
                  </p>
                  {/* <DatePicker
                    name="transaction_date"
                    value={receiveDate ? receiveDate : ""}
                    onChange={(e) => {
                      setReceiveDate(e);
                    }}
                    disabled={[[tomorrow, false]]}
                  /> */}
                  <DatePicker
                    cleanable={false}
                    name="transaction_date"
                    onChange={(e) => setReceiveDate(moment(e, "DD-MM-YYYY"))}
                    format="dd-MM-yyyy"
                    value={
                      receiveDate
                        ? String(receiveDate)?.includes("-")
                          ? new Date(
                              String(receiveDate)?.split("-")[2],
                              String(receiveDate)?.split("-")[1] - 1,
                              String(receiveDate)?.split("-")[0]
                            )
                          : new Date(receiveDate)
                        : null
                    }
                    className="rs-date-body"
                    placeholder="Select Date"
                    placement="autoVerticalStart"
                    disabledDate={(date) => moment(date).isAfter(moment())}
                  />
                </div>
              )}
              <div>
                {approve
                  ? `Are your sure you want to reject this
                payment claim?`
                  : `Are you ok to record the payment claim transaction in General Ledger?`}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleApprove();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  fetchPaymentClaimApproveReject(
                    approve ? "-1" : "1",
                    selectedRowIds,
                    disapprovedReason,
                    receiveDate
                  );
                }}
                disabled={approve ? !disapprovedReason : !receiveDate}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showImport && (
        <Modal
          size="lg"
          show={showImport}
          onHide={handleImportClose}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          className="business-section"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Payment Claims
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UploadFile
                isLoading={isImportLoading}
                setFiles={setFiles}
                files={files}
                handleUpload={handleExcelModal}
                handleCancel={handleImportClose}
                acceptFileFormat=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ErrorMessage="csv, xls and xlsx"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showExcelModal && (
        <Modal
          // size="xl"
          show={showExcelModal}
          onHide={handleExcelClose}
          dialogClassName="full-popup large-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="mb-0" closeButton>
            Upload Payment Claims
          </Modal.Header>
          <Modal.Body>
            <div className="excel-table-list" style={{ padding: "20px" }}>
              {isImportLoading && <Spin />}
              <BootstrapTable
                keyField="key"
                selectRow={excelSelectRow}
                ref={ref}
                remote={{ cellEdit: true }}
                data={excelContactData ? excelContactData : []}
                columns={ExcelContactColumns}
                noDataIndication="No Data Found"
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  timeToCloseMessage: 30000,
                })}
                onTableChange={handleExcelTableChange}
              />
              <div className="excel-list-footer">
                <Button
                  variant="primary"
                  onClick={handleUpload}
                  data-toggle="modal"
                  data-target="#business"
                  disabled={selectedRow.length === 0 ? true : false}
                >
                  Import
                </Button>
                <div>Selected Records: {selectedRow?.length}</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default PaymentClaimList;
