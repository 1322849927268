import React, { useCallback, useState } from "react";
import { API, fileUpload } from "../../../../config";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal } from "react-bootstrap";
import Spin from "../../../common/Spin";
import moment from "moment";
import { DatePicker } from "rsuite";
import CustomTooltip from "../../../common/CustomTooltip";
// import { DatePicker } from "@semcore/date-picker";

const PaymentClaimStatus = ({
  id,
  fetchPaymnetClaims,
  projectId,
  trust_type,
  approve,
  setSelectedRowIds,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [showApprove, setShowApprove] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disapprovedReason, setDisapprovedReason] = useState();
  const [receiveDate, setReceiveDate] = useState(today);

  const handleApprove = () => {
    if (showApprove) {
      setDisapprovedReason();
    }
    setShowApprove(!showApprove);
  };

  const fetchPaymentClaimApproveReject = useCallback(
    async (id, action, status, disapprovedReason, receiveDate) => {
      if (id) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("ids[0]", id);
          formData.append("builder_project_id", projectId);
          formData.append("status", status);
          if (status === "-1") {
            formData.append("disapprove_reason", disapprovedReason);
          }
          if (receiveDate) {
            // formData.append(
            //   "receive_date",
            //   receiveDate ? moment(receiveDate).format("DD-MM-YYYY") : ""
            // );
            formData.append(
              "receive_date",
              receiveDate ? moment(receiveDate).format("DD-MM-YYYY") : ""
            );
          }
          formData.append("trust_type", trust_type);
          const { data } = await fileUpload(
            `${API.GET_PAYMENT_CLAIM_LIST}/${action}`,
            formData
          );
          data?.message && toast.success(data?.message);
          setShowApprove(false);
          setLoading(false);
          fetchPaymnetClaims();
          setDisapprovedReason();
          setReceiveDate();
          setSelectedRowIds([]);
        } catch (error) {
          setShowApprove(false);
          setDisapprovedReason();
          setReceiveDate();
          setSelectedRowIds([]);
          if (error?.response?.data?.errors) {
            setLoading(false);
            Object.keys(error?.response?.data?.errors).forEach((key) => {
              toast.error(error?.response?.data?.errors[key][0]);
            });
          } else {
            setLoading(false);
            toast.error(error?.message);
          }
        }
      }
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div>
      {/* <Button
        className={
          approve === 2
            ? "approved-status"
            : approve === 1
            ? "in-progress-status"
            : approve === 0
            ? "pending-status"
            : "disapproved-status"
        }
        style={{
          marginRight: "10px",
        }}
      >
        {approve === 2
          ? "Completed"
          : approve === 1
          ? "In Progress"
          : approve === 0
          ? "Pending"
          : "Rejected"}
      </Button> */}
      {approve === 2 ? (
        <CustomTooltip message={"Completed"}>
          <span>
            <FontAwesomeIcon
              // style={{ color: "#0DA500" }}
              className="approved-status"
              icon={faCircleCheck}
            />
          </span>
        </CustomTooltip>
      ) : approve === 1 ? (
        <CustomTooltip message={"In Progress"}>
          <span>
            <FontAwesomeIcon
              // style={{ color: "#0077c7" }}
              className="in-progress-status"
              icon={faClock}
            />
          </span>
        </CustomTooltip>
      ) : approve === 0 ? (
        <CustomTooltip message={"Pending"}>
          <span>
            <FontAwesomeIcon
              // style={{ color: "#ffae00" }}
              className="pending-status"
              icon={faCircle}
            />
          </span>
        </CustomTooltip>
      ) : (
        <CustomTooltip message={"Rejected"}>
          <span>
            <FontAwesomeIcon
              // style={{ color: "#F00" }}
              className="disapproved-status"
              icon={faCircleXmark}
            />
          </span>
        </CustomTooltip>
      )}

      {approve === 0 && (
        <>
          <span></span>&nbsp;&nbsp;|&nbsp;&nbsp;
          <CustomTooltip
            message={
              "Post (This will record the transaction in General Ledger)"
            }
          >
            <span>
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="1x"
                onClick={() => {
                  setApproveStatus(false);
                  handleApprove();
                }}
              />
            </span>
          </CustomTooltip>
        </>
      )}
      {approve === 0 && (
        <>
          <span></span>&nbsp;&nbsp;/&nbsp;&nbsp;
          <CustomTooltip message={"Reject"}>
            <span>
              <FontAwesomeIcon
                icon={faXmarkCircle}
                size="1x"
                onClick={() => {
                  setApproveStatus(true);
                  handleApprove();
                }}
              />
            </span>
          </CustomTooltip>
        </>
      )}

      {showApprove && (
        <Modal
          size="lg"
          show={showApprove}
          onHide={handleApprove}
          dialogClassName="modal-50w small-popup review-popup small-review"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="project-section"
        >
          <Modal.Header className="mb-0" closeButton>
            {approveStatus ? "Reject" : "Post"} Payment Claim
          </Modal.Header>
          <Modal.Body>
            {loading && <Spin />}
            <div className="modal-body">
              {approveStatus ? (
                <div className="paymentNote" style={{ marginBottom: "10px" }}>
                  <p>
                    <span className="required">*</span>Reject Reason
                  </p>
                  <Form.Control
                    type="text"
                    className="left-text-align"
                    required
                    maxLength={150}
                    value={disapprovedReason ? disapprovedReason : ""}
                    rules={[
                      {
                        pattern: new RegExp(
                          /^[^!@#)(^%$<>][a-zA-Z\s\d.,/&-]*$/
                        ),
                        message:
                          "Note can contain letters, numbers, ampersand(&), dot(.), comma(,), hyphon(-), slash(/) & spaces.",
                      },
                    ]}
                    onChange={(e) => {
                      setDisapprovedReason(e.target.value);
                    }}
                  ></Form.Control>
                </div>
              ) : (
                <div className="claim-received-date">
                  <p>
                    <span className="required">*</span>Received Date:
                  </p>
                  {/* <DatePicker
                    name="transaction_date"
                    value={receiveDate ? receiveDate : ""}
                    onChange={(e) => {
                      setReceiveDate(e);
                    }}
                    disabled={[[tomorrow, false]]}
                  /> */}
                  <DatePicker
                    cleanable={false}
                    name="transaction_date"
                    onChange={(e) => setReceiveDate(moment(e, "DD-MM-YYYY"))}
                    format="dd-MM-yyyy"
                    value={
                      receiveDate
                        ? String(receiveDate)?.includes("-")
                          ? new Date(
                              String(receiveDate)?.split("-")[2],
                              String(receiveDate)?.split("-")[1] - 1,
                              String(receiveDate)?.split("-")[0]
                            )
                          : new Date(receiveDate)
                        : null
                    }
                    className="rs-date-body"
                    placeholder="Select Date"
                    placement="autoVerticalStart"
                    disabledDate={(date) => moment(date).isAfter(moment())}
                  />
                </div>
              )}
              <div>
                {approveStatus
                  ? `Are your sure you want to reject this payment claim?`
                  : `Are you ok to record the payment claim transaction in General Ledger?`}
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="Button"
                className="btn btn-secondary"
                onClick={() => {
                  handleApprove();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={approveStatus ? !disapprovedReason : !receiveDate}
                onClick={() => {
                  fetchPaymentClaimApproveReject(
                    id,
                    "approve-reject",
                    approveStatus ? "-1" : "1",
                    disapprovedReason,
                    receiveDate
                  );
                }}
              >
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default PaymentClaimStatus;
