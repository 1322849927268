import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackButton from "../../../components/Form/BackButton";
import { imageDefaultPrefixPath } from "../../../config";
import ABA from "./ABA";
import ABATransactions from "./ABATransactions";
import { useProjectBar } from "../../common/useProjectBar";

const tabsKey = {
  "generate-aba": 1,
  "aba-transactions": 2,
};

const ABATabs = () => {
  const location = useLocation();
  const params = useParams();
  const projectId = params?.project_id;
  const trust_type = params?.trust_type === "project" ? 1 : 0;
  const query = new URLSearchParams(location.search);
  const selectedTab = query.get("tab") || "generate-aba";
  const defaultActiveKey = tabsKey[selectedTab];
  const [activeTab, setActiveTab] = useState(
    parseInt(defaultActiveKey) === 1
      ? "Generate .ABA"
      : "Download .ABA file / View History"
  );
  const { ProjectData, ProjectLogo, ProjectName, ProjectReadOnly, builderId } =
    useProjectBar();

  return (
    <>
      <div className="cms-page">
        <div className="page-content-block">
          <div className="full-content-block">
            <h1 className="page-title">
              <BackButton />
              {activeTab}
              {trust_type ? " (PTA)" : " (RTA)"}
              <div className="page-title-right">
                {ProjectLogo && (
                  <img src={`${imageDefaultPrefixPath}${ProjectLogo}`} alt="" />
                )}{" "}
                {ProjectName}
              </div>
            </h1>

            <div className="content-details">
              <div className="table-top-btn">
                <ul
                  className="nav nav-tabs"
                  onClick={(event) => {
                    setActiveTab(event?.target?.innerText);
                  }}
                >
                  <li className={activeTab === "Generate .ABA" ? "active" : ""}>
                    <Link key={1}>Generate .ABA</Link>
                  </li>
                  <li
                    className={
                      activeTab === "Download .ABA file / View History"
                        ? "active"
                        : ""
                    }
                  >
                    <Link key={2}>Download .ABA file / View History</Link>
                  </li>
                </ul>
              </div>
              <div className="custom-table tab-table">
                {activeTab === "Generate .ABA" && (
                  <ABA
                    builderId={builderId}
                    projectId={projectId}
                    trust_type={trust_type}
                    projectData={ProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
                {activeTab === "Download .ABA file / View History" && (
                  <ABATransactions
                    builderId={builderId}
                    trust_type={trust_type}
                    projectId={projectId}
                    projectData={ProjectData}
                    ProjectReadOnly={ProjectReadOnly}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ABATabs;
