import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { API, defaultLimitPagination, get } from "../../config";

const ProjectSelect = (props) => {
  const [regionName, setRegionName] = useState(null);

  useEffect(() => {
    setRegionName(props.regionName);
  }, [props.regionName]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    if (props?.builderId) {
      const search = searchQuery?.length >= 3 ? searchQuery : "";
      const response =
        props?.rta_project === 1
          ? props?.projectId
            ? await get(
                `${API.GET_PROJECT}?limit=10&page=${
                  page && !search ? page : 1
                }&search=${search ? search : ""}&builder_id=${
                  props?.builderId
                }&rta_project=1&builder_project_id=${props?.projectId}`
              )
            : await get(
                `${API.GET_PROJECT}?limit=10&page=${
                  page && !search ? page : 1
                }&search=${search ? search : ""}&builder_id=${
                  props?.builderId
                }&rta_project=1`
              )
          : props?.isNotSubscribe
          ? await get(
              `${API.GET_PROJECT}?limit=${defaultLimitPagination}&page=${
                page && !search ? page : 1
              }&search=${search ? search : ""}&builder_id=${props?.builderId}&is_subscribe=0`
            )
          : await get(
              `${API.GET_PROJECT}?limit=${defaultLimitPagination}&page=${
                page && !search ? page : 1
              }&search=${search ? search : ""}&builder_id=${props?.builderId}`
            );

      const responseJSON = response?.data?.data?.data
        ? response?.data?.data?.data
        : [];

      const totalLength = loadedOptions?.length
        ? loadedOptions?.length + responseJSON?.length
        : responseJSON?.length;

      return {
        options: responseJSON,
        hasMore: totalLength < response?.data?.data?.total,
        additional: {
          page: search ? 2 : page + 1,
        },
      };
    }
  };

  const onChange = (option) => {
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={JSON.stringify(regionName)}
      value={props.value || props.defaultValue}
      loadOptions={loadOptions}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => option?.project_name}
      onChange={onChange}
      classNamePrefix={props?.className || "builder-select"}
      isSearchable={true}
      placeholder={props.placeholder}
      additional={{
        page: 1,
      }}
      isClearable={props.isClearable}
      autoBlur={true}
      isDisabled={props.isDisabled ? props.isDisabled : false}
    />
  );
};

ProjectSelect.propTypes = {
  regionName: PropTypes.any,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default ProjectSelect;
