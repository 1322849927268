import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { DDMMYYYYFormat, formatNumber } from "../../common/Misc";
import axios from "axios";
// import { s3DefaultPath } from "../../../config";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../common/CustomTooltip";
// import { toast } from "react-toastify";
// import { API, get } from "../../../config";
// import Pagination from "../../common/Pagination";
// import Spin from "../../common/Spin";

const ExpandedPaymentScheduled = ({ paymentscheduleList }) => {
  //   const [page, setPage] = useState(1);
  //   const [limit, setLimit] = useState(10);
  //   const [total, setTotal] = useState();
  //   const [from, setFrom] = useState();
  //   const [to, setTo] = useState();
  //   const [sortField, setSortField] = useState();
  //   const [sortOrder, setSortOrder] = useState();
  const downloadLetter = async (path) => {
    try {
      if (path) {
        const response = await axios.get(`${path}`, {
          responseType: "blob",
        });
        const fileData = response.data;
        const url = window.URL.createObjectURL(new Blob([fileData]));
        var a = document.createElement("a");
        a.href = url;
        var file = path.split("/");
        a.setAttribute("download", file[file.length - 1] || "new_aba.aba");
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        toast.error("File does not exist.");
      }
    } catch (e) {
      const errors = e.response?.data?.errors;
      Object.keys(errors).forEach((key) => {
        toast.error(errors[key][0]);
      });
    }
  };

  const paymentscheduleGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        builder_id: paymentscheduleList[i]?.builder_id,
        key: paymentscheduleList[i]?.id,
        description: paymentscheduleList[i]?.description
          ? paymentscheduleList[i]?.description
          : "-",
        // claimed_amount: paymentscheduleList[i]?.claimed_amount ? `$${formatNumber(Number(paymentscheduleList[i]?.claimed_amount).toFixed(2))}` : "$0.00",
        scheduled_date: paymentscheduleList[i]?.scheduled_date
          ? DDMMYYYYFormat(paymentscheduleList[i]?.scheduled_date?.split("-"))
          : "-",
        scheduled_amount: paymentscheduleList[i]?.scheduled_amount
          ? `$${formatNumber(Number(paymentscheduleList[i]?.scheduled_amount))}`
          : "$0.00",
        amount_withheld: paymentscheduleList[i]?.amount_withheld
          ? `$${formatNumber(Number(paymentscheduleList[i]?.amount_withheld))}`
          : "$0.00",
        withheld_description: paymentscheduleList[i]?.withheld_description
          ? paymentscheduleList[i]?.withheld_description
          : "-",
        file_name: paymentscheduleList[i]?.payment?.aba_file?.file
          ? `${paymentscheduleList[i]?.payment?.aba_file?.file
              ?.split("/")
              .slice(-1)}`
          : "",
        aba: (
          <>
            {paymentscheduleList[i]?.payment?.aba_file?.file ? (
              <CustomTooltip message={"Download ABA File"}>
                <span>
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="1x"
                    onClick={() => {
                      downloadLetter(
                        paymentscheduleList[i]?.payment?.aba_file?.file
                      );
                    }}
                  />
                </span>
              </CustomTooltip>
            ) : (
              <></>
            )}
          </>
        ),
      });
    }
    return items;
  };

  const paymentschedule = paymentscheduleGenerator(paymentscheduleList?.length);

  const PaymentscheduleColumns = [
    {
      dataField: "scheduled_date",
      text: "Payment Date",
      headerStyle: { width: "10%" },
    },

    // {
    //   dataField: "claimed_amount",
    //   text: "Claimed Amount",
    // },
    {
      dataField: "scheduled_amount",
      text: "Payment Amount",
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: { width: "17%" },
    },
    {
      dataField: "amount_withheld",
      text: "Amount Withheld in RTA",
    },
    {
      dataField: "withheld_description",
      text: "Reason for Withholding",
      headerStyle: { width: "17%" },
    },
    {
      dataField: "file_name",
      text: "File Name",
    },
    {
      dataField: "aba",
      text: ".ABA File",
      headerAlign: "center",
      align: "center",
      headerStyle: { width: "120px" },
    },
  ];

  //   const handleTableChange = (type, { page, sortField, sortOrder }) => {
  //     if (type === "sort") {
  //       setPage(1);
  //       setSortField(sortField);
  //       setSortOrder(
  //         sortOrder === "asc" ? "ASC" : sortOrder === "desc" && "DESC"
  //       );
  //     }
  //   };

  //   const handleUpdateLimit = (e) => {
  //     const limit = e.target.value;
  //     setLimit(limit);
  //     setPage(1);
  //   };

  //   const handleChangePage = (page) => {
  //     setPage(page);
  //   };

  return (
    <>
      <div className="content-details">
        <div className="custom-table expand-paymentSchedule">
          <BootstrapTable
            keyField="key"
            remote
            data={paymentschedule}
            columns={PaymentscheduleColumns}
            // onTableChange={handleTableChange}
            noDataIndication="No Data Found"
          />

          {/* <Pagination
            total={total}
            limit={parseInt(limit)}
            currentPage={page}
            updateLimit={handleUpdateLimit}
            updatePage={handleChangePage}
            from={from}
            to={to}
          /> */}
        </div>
      </div>
    </>
  );
};
export default ExpandedPaymentScheduled;
